import {
  faFolderOpen,
  faFileLines,
  faChartSimple,
  faHammer,
  faSquareCheck,
  faListCheck,
  faList12,
  faChartBar
} from '@fortawesome/free-solid-svg-icons';

export const stepsArrayRevizii = [
  {
    // title: 'FlŞA DE EVIDENŢĂ a lucrărilor periodice de revizie tehnică a instalaţiei de utilizare a gazelor naturale',
    title: 'Fișa de evidență',
    icon: faFileLines
  },
  {
    title: 'Revizia tehnică a instalaţiei',
    icon: faSquareCheck
  },
  {
    // title: 'Operaţiunile care s-au realizat în cazul reviziei tehnice a instalaţiei de utilizare a gazelor naturale',
    title: 'Operaţiunile care s-au realizat',
    icon: faHammer
  },
  {
    title: 'Etanșeitate și rezistență la presiune',
    icon: faChartSimple
  },
  {
    title: 'Defectele constatate',
    icon: faListCheck
  },
  {
    title: 'Condiţii tehnice de funcţionare',
    icon: faList12
  },
  {
    title: 'Concluzii',
    icon: faChartBar
  },
  {
    title: 'Incarcă PDF semnat',
    icon: faFolderOpen
  }
];

export const stepsArrayVerificari = [
  {
    // title: 'FIŞA DE EVIDENŢĂ a lucrărilor periodice de verificare tehnică a instalaţiei de utilizare a gazelor naturale',
    title: 'Fișa de evidență',
    icon: faFileLines
  },
  {
    title: 'Verificarea tehnică a instalaţiei',
    icon: faSquareCheck
  },
  {
    // title: 'Operaţiunile care s-au realizat în cazul verificării tehnice a instalaţiei de utilizare a gazelor naturale',
    title: 'Operaţiunile care s-au realizat',
    icon: faHammer
  },
  {
    title: 'Defectele constatate',
    icon: faListCheck
  },
  {
    title: 'Condiţii tehnice de funcţionare',
    icon: faList12
  },
  {
    title: 'Concluzii',
    icon: faChartBar
  },
  {
    title: 'Incarcă PDF semnat',
    icon: faFolderOpen
  }
];
