import {store} from '@lib/store';
import axios from 'axios';
import {Proxy, proxy} from '@api/azureProxy';

export function getConventionList(payload: any) {
    const state = store.getState();

    return axios.get(
        `${state.options.apiHost}/consumer-convention/RequestEE/list?sortBy=${payload.sortBy || ''}&sortType=${payload.sortType || ''}&pageNumber=${payload.pageNumber}&pageSize=${payload.pageSize}&statusId=${payload.status || ''}&keywords=${payload.text || ''}`,
        {
            headers: {
                'Authorization': 'Bearer ' + state.auth.token,
                'Ocp-Apim-Subscription-Key': state.options.subscriptionKey as string
            }
        }
    );
};

export function getConventionDetails(id: any) {
    const state = store.getState();

    return axios.get(
        `${state.options.apiHost}/consumer-convention/RequestEE/view?requestId=${id}`,
        {
            headers: {
                'Authorization': 'Bearer ' + state.auth.token,
                'Ocp-Apim-Subscription-Key': state.options.subscriptionKey as string
            }
        }
    );
};

export function verifyModify(payload: any) {
    const state = store.getState();

    return axios.get(
        `${state.options.apiHost}/consumer-convention/RequestEE/verify_modify?pod=${payload.pod}${
        payload.requestDetailId ? '&requestDetailId=' + payload.requestDetailId : ''
        }`,
        {
            headers: {
                'Authorization': 'Bearer ' + state.auth.token,
                'Ocp-Apim-Subscription-Key': state.options.subscriptionKey as string
            }
        }
    );
};

export function addModifyConvention(payload: any) {
    const state = store.getState();

    return axios.post(
        `${state.options.apiHost}/consumer-convention/RequestEE/add_modify_convention`,
        payload,
        {
            headers: {
                'Authorization': 'Bearer ' + state.auth.token,
                'Ocp-Apim-Subscription-Key': state.options.subscriptionKey as string
            }
        }
    );
};

export function addDetailsModifyConvention(payload: any) {
    const state = store.getState();

    return axios.post(
        `${state.options.apiHost}/consumer-convention/RequestEE/add_details_modify_convention`,
        payload,
        {
            headers: {
                'Authorization': 'Bearer ' + state.auth.token,
                'Ocp-Apim-Subscription-Key': state.options.subscriptionKey as string
            }
        }
    );
};

export function verifyView(pod: string) {
    const state = store.getState();

    return axios.get(
        `${state.options.apiHost}/consumer-convention/RequestEE/verify_view?pod=${pod}`,
        {
            headers: {
                'Authorization': 'Bearer ' + state.auth.token,
                'Ocp-Apim-Subscription-Key': state.options.subscriptionKey as string
            }
        }
    );
};

export function addViewConvention(payload: any) {
    const state = store.getState();

    return axios.post(
        `${state.options.apiHost}/consumer-convention/RequestEE/add_view_convention`,
        payload,
        {
            headers: {
                'Authorization': 'Bearer ' + state.auth.token,
                'Ocp-Apim-Subscription-Key': state.options.subscriptionKey as string
            }
        }
    );
};

export function addDetailsViewConvention(payload: any) {
    const state = store.getState();

    return axios.post(
        `${state.options.apiHost}/consumer-convention/RequestEE/add_details_view_convention`,
        payload,
        {
            headers: {
                'Authorization': 'Bearer ' + state.auth.token,
                'Ocp-Apim-Subscription-Key': state.options.subscriptionKey as string
            }
        }
    );
};

export function deleteRequestConvention(requestId: string) {
    const state = store.getState();

    return axios.delete(
        `${state.options.apiHost}/consumer-convention/RequestEE/delete?requestId=${requestId}`,
        {
            headers: {
                'Authorization': 'Bearer ' + state.auth.token,
                'Ocp-Apim-Subscription-Key': state.options.subscriptionKey as string
            }
        }
    );
};

export function deleteRequestPodFromConvention(requestDetailId: string) {
    const state = store.getState();

    return axios.delete(
        `${state.options.apiHost}/consumer-convention/RequestEE/delete_detail?requestDetailId=${requestDetailId}`,
        {
            headers: {
                'Authorization': 'Bearer ' + state.auth.token,
                'Ocp-Apim-Subscription-Key': state.options.subscriptionKey as string
            }
        }
    );
};

export function finalSendRequest(requestId: string) {
    const state = store.getState();

    return axios.get(
        `${state.options.apiHost}/consumer-convention/RequestEE/send_request?requestId=${requestId}`,
        {
            headers: {
                'Authorization': 'Bearer ' + state.auth.token,
                'Ocp-Apim-Subscription-Key': state.options.subscriptionKey as string
            }
        }
    );
};

export function postValidateBulkImport(payloadFormData: any) {
    const state = store.getState();

    return axios.post(
        `${state.options.apiHost}/consumer-convention/RequestEE/validate_bulk_import`,
        payloadFormData,
        {
            headers: {
                'Authorization': 'Bearer ' + state.auth.token,
                'Ocp-Apim-Subscription-Key': state.options.subscriptionKey as string
            }
        }
    );
};

export function postSaveBulkImport(payload: any) {
    const state = store.getState();

    return axios.post(
        `${state.options.apiHost}/consumer-convention/RequestEE/save_bulk_import`,
        payload,
        {
            headers: {
                'Authorization': 'Bearer ' + state.auth.token,
                'Ocp-Apim-Subscription-Key': state.options.subscriptionKey as string
            }
        }
    );
};

export function exportSupplierConventiiConsum(payload: any) {
    const state = store.getState();
    const proxyData: Proxy = {
        method: 'GET',
        endPoint: '/consumer-convention/RequestEE/export_conventions',
        withAuthBearer: true
    };

    return proxy(proxyData, {
        params: {
                pod: payload.pod,
                clc: payload.clc,
                countyId: payload.countyId,
                localityId: payload.localityId,
        },
        headers: {
            Authorization: 'Bearer ' + state.auth.token,
            'Access-Control-Allow-Headers': 'Authorization',
            'Access-Control-Allow-Credentials': true,
            'access-control-allow-methods': 'GET'
          },
        responseType: 'arraybuffer'
    });
}


