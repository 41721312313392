import { faFileLines } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Fragment } from 'react';
import { Button } from 'react-bootstrap';

const CPTableRow = (props: any) => {
  return (
    <tr>
      {props.isFurnizor && (
        <td key={-2}>
          <div style={{width: '160px', marginTop: '5px'}}>
            <Button
              onClick={() => {

                window.location.href = `inregistrare-petitie?pod=${props.item.pod}&countyCode=${props.item.countyCode}&localityCode=${props.item.localityCode}`;
              }}
            >
              Adaugare reclamatie
            </Button>
          </div>
        </td>
      )}
      {(props.isFurnizor && props.utility === 1) && (
        <td key={-1}>
          <div>
            <span className='ms-2' onClick={() => props.getTechnicalDocuments(props.item)} title="Descarca documente tehnice">
              <FontAwesomeIcon
                style={{ width: '18px', height: '48px', cursor: 'pointer', color: '#ea1c0a' }}
                width={32}
                icon={faFileLines}
              />
            </span>
          </div>
        </td>
      )}

      {props.isFurnizor && Object.keys(props.item).map((attribute: any, index: number) => {
        let value = '-';

        //display first 10 columns for EE
        if (props.utility === 1 && index >= 11) return;

        //display first 18 columns for GN
        if (props.utility === 2 && index >= 18) return;

        if (attribute === 'id') {
          return;
        } else if (attribute === 'dataAdaugare') {
          // value = moment(props.item[attribute]).format('DD/MM/YYYY');
          value = props.item[attribute] ?? '';
        } else {
          value = props.item[attribute];
        }

        return (
          <td key={index}>
            <div className="d-flex align-items-center">
              <span>{value}</span>
            </div>
          </td>
        );
      })}

      {(props.isFurnizor === false && props.utility === 1) &&
        <Fragment>
          <td>
            <div>
              <span>{props.item['clientCode'] || '-'}</span>
            </div>
          </td>
          <td>
            <div>
              <span>{props.item['clientName'] || '-'}</span>
            </div>
          </td>
          <td>
            <div>
              <span>{props.item['supplierName'] || '-'}</span>
            </div>
          </td>
          <td>
            <div>
              <span>{props.item['consumptionCode'] || '-'}</span>
            </div>
          </td>
          <td>
            <div>
              <span>{props.item['pod'] || '-'}</span>
            </div>
          </td>
          <td>
            <div>
              <span>{props.item['address'] || '-'}</span>
            </div>
          </td>
          <td>
            <div>
              <span>{props.item['contractStatus'] || '-'}</span>
            </div>
          </td>
          <td>
            <div>
              <span>{props.item['meterNumber'] || '-'}</span>
            </div>
          </td>
          <td>
            <div>
              <span>{props.item['connectionStatus'] || '-'}</span>
            </div>
          </td>
          <td>
            <div>
              <span>{props.item['voltageLevel'] || '-'}</span>
            </div>
          </td>
          <td>
            <div>
              <span>{props.item['maxPower'] || '-'}</span>
            </div>
          </td>
          <td>
            <div>
              <span>{props.item['datePre'] || '-'}</span>
            </div>
          </td>
        </Fragment>
      }

      {(props.isFurnizor === false && props.utility === 2) &&
        <Fragment>
          <td>
            <div>
              <span>{props.item['clientCode'] || '-'}</span>
            </div>
          </td>
          <td>
            <div>
              <span>{props.item['clientName'] || '-'}</span>
            </div>
          </td>
          <td>
            <div>
              <span>{props.item['supplierName'] || '-'}</span>
            </div>
          </td>
          <td>
            <div>
              <span>{props.item['consumptionCode'] || '-'}</span>
            </div>
          </td>
          <td>
            <div>
              <span>{props.item['pod'] || '-'}</span>
            </div>
          </td>
          <td>
            <div>
              <span>{props.item['address'] || '-'}</span>
            </div>
          </td>
          <td>
            <div>
              <span>{props.item['contractStatus'] || '-'}</span>
            </div>
          </td>
          <td>
            <div>
              <span>{props.item['meterNumber'] || '-'}</span>
            </div>
          </td>
          <td>
            <div>
              <span>{props.item['connectionStatus'] || '-'}</span>
            </div>
          </td>
          <td>
            <div>
              <span>{props.item['tariffCategory'] || '-'}</span>
            </div>
          </td>
          <td>
            <div>
              <span>{props.item['srmp'] || '-'}</span>
            </div>
          </td>
          <td>
            <div>
              <span>{props.item['minPressure'] || '-'}</span>
            </div>
          </td>
          <td>
            <div>
              <span>{props.item['maxPressure'] || '-'}</span>
            </div>
          </td>
          <td>
            <div>
              <span>{props.item['techicalMinPressure'] || '-'}</span>
            </div>
          </td>
          <td>
            <div>
              <span>{props.item['contractCapacity'] || '-'}</span>
            </div>
          </td>
          <td>
            <div>
              <span>{props.item['contractDuration'] || '-'}</span>
            </div>
          </td>
        </Fragment>
      }
    </tr>
  );
};

export default CPTableRow;
