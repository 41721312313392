import { toggleLoader } from '@actions/LoaderActions';
import { GetCounties } from '@api/geoApi';
import { postStepThree } from '@api/iConnect/iConnectEE';
import { getTypes } from '@api/prosumatori/prosumatori';
import DatePicker from '@components/common/Date/DatePicker';
import { Input } from '@components/common/Input';
import ChoiceComponent from '@components/prosumatori/components/ChoiceComponent';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tippy from '@tippyjs/react';
import { IState } from '@type/store';
import axios from 'axios';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import AdresaObiectivului from '../forms/AdresaObiectivului';
import AdresaObiectivuluiAA from '../forms/AdresaObiectivuluiAA';
import AdresaObiectivuluiPOD from '../forms/AdresaObiectivuluiPOD';

const ObiectivulDeRacordat = (props: {
    isEditing: any,
    requestId: any,
    setStep: any,
    currentStep: any,
    progress: any,
    setProgress: any,
    casnic: any,
    handleCasnic: any,
    tipLC: any,
    handleTipLC: any,
    wasDataVerified: any,
    handleWasDataVerified: any,
    numeLC: any,
    handleNumeLC: any,
    tipAdresa: any,
    handleTipAdresa: any,
    adresaOb: any,
    handleAdresaOb: any,
    nrAviz: string,
    handleNrAviz: any,
    dataAviz: string,
    handleDataAviz: any,
    nrCamere: any,
    handleNrCamere: any,
    constructieFinalizata: any,
    handleConstructieFinalizata: any,
    santier: any,
    handleSantier: any,
    dateIdentificare: any,
    precizari: any,
    handlePrecizari: any,
    purposeType: any,
    handlePurposeType: any
}) => {
    const {
        isEditing,
        requestId,
        setStep,
        currentStep,
        progress,
        setProgress,
        casnic,
        handleCasnic,
        tipLC,
        handleTipLC,
        wasDataVerified,
        handleWasDataVerified,
        numeLC,
        handleNumeLC,
        tipAdresa,
        handleTipAdresa,
        adresaOb,
        handleAdresaOb,
        nrAviz,
        handleNrAviz,
        dataAviz,
        handleDataAviz,
        nrCamere,
        handleNrCamere,
        constructieFinalizata,
        handleConstructieFinalizata,
        santier,
        handleSantier,
        dateIdentificare,
        precizari,
        handlePrecizari,
        purposeType,
        handlePurposeType
    } = props;

    const [LCOptions, setLCOptions] = useState([]);
    const [validationError, setValidationError] = useState('');
    const [error, setError] = useState('');
    const [PTOptions, setPTOptions] = useState([]);

    const [isCountyInList, setIsCountyInList] = useState(false);
    const [wasCountyChecked, setWasCountyChecked] = useState(false);

    const innerRef = useRef(null);
    const dispatch = useDispatch();
    const token: string | undefined = useSelector<IState>((state) => state.auth.token) as string;

    useEffect(() => {
        handleTipAdresa(tipAdresa);
        window.scrollTo(0, 0);
        mountTypes();
    }, []);

    useEffect(() => {
        if (dateIdentificare.Judet !== '' && wasCountyChecked === false) {
            checkIsCountyInList();
        }
    }, [dateIdentificare.Judet]);

    const checkIsCountyInList = async () => {
        dispatch(toggleLoader(true));

        let found = false;

        await GetCounties('EE').then((response) => {
            if (response) {
                response.map((county: any, index: number) => {
                    if (county.countyCode === dateIdentificare.Judet) {
                        setIsCountyInList(true);
                        found = true;
                    } 
                });

                if (found === false) {
                    handleTipAdresa('AA');
                    setIsCountyInList(false);
                }
            }
        });

        setWasCountyChecked(true);
        dispatch(toggleLoader(false));
    };

    const mountTypes = async () => {
        dispatch(toggleLoader(true));

        await getOptionTypes(['ApplicationType'], 'EE').then((res) => setLCOptions(res));

        purposeType.length === 0 && getOptionTypes(['PurposeType'], 'EE').then((res) => {
            let data = res;

            data.map((el: any, i: number) => {
                console.log('el: ', el);
                el.active = false;
            });

            handlePurposeType(data);
            setPTOptions(data);
        });

        dispatch(toggleLoader(false));
    };

    const setOptionActive = (option: any) => {
        let op = purposeType;

        op.map((el: any, i: number) => {
            if (el === option) {
                el.active = !el.active;
            }
        });

        handlePurposeType(op);
    };

    const renderPurposeType = () => {
        return (
            <div className="mt-3 mb-3">
                <h5 className="m-0" id="PT">Precizări privind scopul cererii*</h5>
                <div className={'d-flex m-3 choices checkbox-container'}>
                    {purposeType.map((option: any, idx: number) => (
                        <span
                            key={idx}
                            style={{ marginRight: '25px', marginBottom: '8px' }}
                            onClick={() => {
                                if (isEditing !== false) {
                                    setOptionActive(option);
                                }
                            }}
                        >
                            <input
                                type="checkbox"
                                onChange={() => setOptionActive(option)}
                                onClick={() => {
                                    if (isEditing !== false) {
                                        setOptionActive(option);
                                    }
                                }}
                                value={option}
                                checked={option.active}
                                style={{ marginRight: '4px' }}
                                className="create-account-checkbox"
                                disabled={isEditing === false}
                            // disabled={disabled}
                            />
                            <span style={{ fontSize: '18px' }}>{option.title || option.name}</span>
                        </span>
                    ))}
                </div>
            </div>
        );
    };

    const getATRDetails = async () => {
        await axios.get(`https://msapi.delgaz.ro/iconnect/SAP/atr?pod=${adresaOb.POD}`, {
            headers: {
                'Ocp-Apim-Subscription-Key': 'bbb053fe2ee440ff8ce6c96cf0c11565',
                'Authorization': 'Bearer ' + token
            }
        }).then((response) => {
            if (response && response.data && response.data.numar_doc) {
                if (response.data.numar_doc !== null) {
                    handleNrAviz(response.data.numar_doc);
                    handleDataAviz(response.data.data_doc, true);
                }
            }
        }).catch((err) => console.log(err));

        dispatch(toggleLoader(false));
    };

    const getOptionTypes = async (keys: string[], utility?: string) => {
        return await getTypes(keys, utility).then((res: any) => {
            return res[keys[0]];
        });
    };

    const handleValidationError = (value: string) => {
        setValidationError(value);
    };

    const handlePrevStep = () => {
        setStep(currentStep - 1);
    };

    const stepThreeApiCall = async (payload: any) => {
        dispatch(toggleLoader(true));

        await postStepThree(payload)
            .then((response: any) => {
                if (response && response.status === 200) {
                    setStep(currentStep + 1);
                    currentStep === progress && setProgress(currentStep + 1);
                }
            })
            .catch((error: any) => {
                if (error && error.status === 200) {
                    setStep(currentStep + 1);
                    currentStep === progress && setProgress(currentStep + 1);
                } else if (error === 'Request is completed') {
                    setError('Cererea a fost deja finalizată.');
                } else if (error && error.StatusCode && error.StatusCode === 500) {
                    setError('A intervenit o eroare tehnică. Vă rugăm reveniți mai târziu.');
                } else if (error && error.statusCode && error.statusCode === 500) {
                    setError('A intervenit o eroare tehnică. Vă rugăm reveniți mai târziu.');
                }
            });

        dispatch(toggleLoader(false));
    };

    const constructPurposeType = () => {
        let list: any[] = [];

        purposeType.map((el: any, i: number) => {
            if (el.active === true) {
                list.push(el);
            }
        });

        list.map((e: any, i: number) => {
            if (e.name === 'Altele') {
                list[i] = {
                    id: 5,
                    name: precizari,
                    code: '',
                    active: true
                };
            }
        });

        let newList: any[] = [];
        list.map((e: any, i: number) => {
            let el = {
                PurposeTypeId: e.id,
                PurposeType: e.name
            };

            newList.push(el);
        });

        return newList;
    };

    const constructPayload = () => {
        return {
            consum_casnic: casnic,
            TipLC: tipLC,
            POD: adresaOb.POD,
            TipAdresa: tipAdresa,
            Adresa: {
                Judet: adresaOb.Judet,
                Localitate: adresaOb.Localitate,
                Comuna: adresaOb.Comuna,
                Strada: adresaOb.Strada,
                Numar: adresaOb.Numar,
                Scara: adresaOb.Scara,
                Bloc: adresaOb.Bloc,
                Etaj: adresaOb.Etaj,
                Ap: adresaOb.Ap,
            },
            nume_loc_consum: numeLC,
            nr_camere: casnic ? nrCamere : null,
            nr_aviz: tipLC === 1 ? null : nrAviz,
            data_aviz: tipLC === 1 ? null : (dataAviz !== '' ? dataAviz : null),
            constructie_finalizata: constructieFinalizata,
            santier: santier,
            cod_loc_consum: adresaOb.cod_loc_consum,
            requestId: requestId,
            nrCad: adresaOb.nrCad,
            nrCF: adresaOb.nrCF,
            alte_precizari: tipLC === 1 ? precizari : '',
            scopul_cererii: tipLC === 2 ? constructPurposeType() : [],
            vstelle: adresaOb.cod_loc_consum || ''
        };
    };

    const handleNextStep = () => {
        if (isEditing !== false) {
            setError('');
            setValidationError('');
            let wasValidated = true;
            let ob = document.getElementById('obiectiv');
            ob?.classList.remove('red');
            let lc = document.getElementById('lc');
            lc?.classList.remove('red');
            let nume = document.getElementById('nume');
            nume?.classList.remove('forced-focus');
            let cf = document.getElementById('cf');
            cf?.classList.remove('red');
            let san = document.getElementById('san');
            san?.classList.remove('red');
            let nrc = document.getElementById('nrc');
            nrc?.classList.remove('forced-focus');
            let adr = document.getElementById('ADR');
            adr?.classList.remove('red');
            let purpose = document.getElementById('PT');
            purpose?.classList.remove('red');

            if (casnic === null) {
                setValidationError('Câmpurile marcate cu * sunt obligatorii.');
                wasValidated = false;
                let err = document.getElementById('obiectiv');
                err?.classList.add('red');
            }

            if (tipLC === null) {
                setValidationError('Câmpurile marcate cu * sunt obligatorii.');
                wasValidated = false;
                let err = document.getElementById('lc');
                err?.classList.add('red');
            }

            if (numeLC === '') {
                setValidationError('Câmpurile marcate cu * sunt obligatorii.');
                wasValidated = false;
                let err = document.getElementById('nume');
                err?.classList.add('forced-focus');
            }

            if (casnic === true && tipLC === 1 && constructieFinalizata === null) {
                setValidationError('Câmpurile marcate cu * sunt obligatorii.');
                wasValidated = false;
                let err = document.getElementById('cf');
                err?.classList.add('red');
            }

            if (casnic === true && tipLC === 1 && constructieFinalizata === false && santier === null) {
                setValidationError('Câmpurile marcate cu * sunt obligatorii.');
                wasValidated = false;
                let err = document.getElementById('san');
                err?.classList.add('red');
            }

            if (casnic === true && (nrCamere === null || nrCamere === '')) {
                setValidationError('Câmpurile marcate cu * sunt obligatorii.');
                wasValidated = false;
                let err = document.getElementById('nrc');
                err?.classList.add('forced-focus');
            }

            if (tipLC === 2 && !wasDataVerified) {
                setValidationError('Te rugăm să verifici informațiile pentru locul de consum existent.');
                wasValidated = false;
            }

            if (tipLC === 2 && constructPurposeType().length === 0) {
                setValidationError('Câmpurile marcate cu * sunt obligatorii.');
                wasValidated = false;
                let err = document.getElementById('PT');
                err?.classList.add('red');
            }

            if (tipLC === 2 && isOtherPurposeTypeActive() && precizari === '') {
                setValidationError('Câmpurile marcate cu * sunt obligatorii.');
                wasValidated = false;
                let err = document.getElementById('PT');
                err?.classList.add('red');
            }

            // if (tipLC === 2 && nrAviz === '') {
            //     setValidationError('Câmpurile marcate cu * sunt obligatorii.');
            //     wasValidated = false;
            // }

            // if (tipLC === 2 && dataAviz === '') {
            //     setValidationError('Câmpurile marcate cu * sunt obligatorii.');
            //     wasValidated = false;
            // }

            if (tipLC === 1 && tipAdresa === 'AA') {
                //@ts-ignore
                innerRef.current?.submitForm();
            }

            if (tipLC === 1 && tipAdresa === '') {
                setValidationError('Câmpurile marcate cu * sunt obligatorii.');
                let err = document.getElementById('ADR');
                err?.classList.add('red');
                wasValidated = false;
            }

            if (wasValidated) {
                //@ts-ignore
                innerRef.current?.submitForm();
                
                //@ts-ignore
                if (innerRef.current && innerRef.current?.isValid) {
                    stepThreeApiCall(constructPayload());
                }
            }
        } else {
            setStep(currentStep + 1);
            currentStep === progress && setProgress(currentStep + 1);
        }
    };

    const consumptionPointTypeExplanation = (type: boolean) => {
        return <Tippy
            content={
                <div className="p-3" style={{ background: 'rgba(0,0,0,0.8)', color: 'white', borderRadius: '6px' }}>
                    {type && 'Client casnic - clientul care cumpără energie electrică pentru propriul consum casnic, excluzând consumul pentru activități comerciale sau profesionale.'}
                    {!type && 'Client noncasnic - orice persoană fizică sau juridică ce cumpără energie electrică ce nu este pentru propriul consum casnic; această categorie include și producători de energie electrică, clienții industriali, întreprinderile mici și mijlocii, operatori economici și clienții angro.'}
                </div>
            }
            duration={0}
            placement="bottom"
        >
            <div className="icon-div">
                <FontAwesomeIcon icon={faCircleInfo} className="icon" color="red" />
            </div>
        </Tippy>; 
    };

    const isOtherPurposeTypeActive = () => {
        let isActive = false;

        purposeType.map((item: any, index: number) => {
            if (item.name === 'Altele' && item.active === true) {
                isActive = true;
            }
        })

        return isActive;
    }

    return (
        <div>
            <h4 className="register-section-title mt-3">Obiectivul de racordat</h4>
            <ChoiceComponent
                title={'Tipul consumului pentru obiectivul de racordat*'}
                options={[
                    { 
                        name: 'Obiectiv cu consum de tip casnic', 
                        id: true,
                        children: consumptionPointTypeExplanation(true)
                    },
                    { 
                        name: 'Obiectiv cu consum de tip noncasnic', 
                        id: false,
                        children: consumptionPointTypeExplanation(false)
                    }
                ]}
                onChange={handleCasnic}
                value={casnic}
                disabled={isEditing === false}
                id="obiectiv"
                colAlign
            />

            <br></br>
            {casnic && <Row className="mt-0">
                <Col xs={12} sm={4}>
                    <Input
                        value={nrCamere}
                        onChange={(e) => {
                            if (e.target.value === '' || /^[0-9]*$/.test(e.target.value)) {
                                handleNrCamere(e.target.value);
                            }
                        }}
                        label={'Număr camere*'}
                        disabled={isEditing === false}
                        id="nrc"
                    />
                </Col>
            </Row>}

            {LCOptions.length > 0 && <ChoiceComponent
                title={'Tipul locului de consum*'}
                options={LCOptions}
                onChange={(id: number) => {
                    handleTipLC(id); 
                    
                    if (id === 1 && !isCountyInList) {
                        handleTipAdresa('AA', true);
                    } else if (id === 1 && isCountyInList) {
                        handleTipAdresa('AU', true);
                    }
                }}
                value={tipLC}
                disabled={isEditing === false}
                id="lc"
            />}
            <br></br>
            <Row>
                <Col xs={12} sm={4}>
                    <div className="position-relative">
                        <Input
                            value={numeLC}
                            onChange={(e) => handleNumeLC(e.target.value)}
                            label={'Denumire loc consum*'}
                            disabled={isEditing === false}
                            id="nume"
                        />
                        <span className="position-absolute" style={{ right: '4%', bottom: '-36px', zIndex: 5 }}>
                            <Tippy
                                content={
                                    <div className="p-3" style={{ background: 'rgba(0,0,0,0.8)', color: 'white', borderRadius: '6px' }}>
                                        Denumire loc consum - denumirea obiectivului pe care vrei să îl racordezi o poți găsi în certificatul de urbanism, în autorizația de construire a imobilului sau în adeverința emisă de către unitatea administrativ teritorială.
                                    </div>
                                }
                                duration={0}
                                placement="bottom"
                            >
                                <div className="icon-div">
                                    <FontAwesomeIcon icon={faCircleInfo} className="icon" color="red" />
                                </div>
                            </Tippy>
                        </span>
                    </div>
                </Col>
            </Row>

            {
                tipLC === 1 && wasCountyChecked && <ChoiceComponent
                    title={'Adresa locului de consum este:*'}
                    options={[{ name: 'Adresa clientului final', id: 'AU' }, { name: 'Altă adresă', id: 'AA' }]}
                    onChange={(value: string) => handleTipAdresa(value, true)}
                    value={tipAdresa}
                    id="ADR"
                    disabled={isEditing === false || !isCountyInList}
                />
            }

            {(LCOptions.length > 0 && tipLC === 1 && tipAdresa === 'AU') && <AdresaObiectivului isEditing={isEditing} innerRef={innerRef} formData={adresaOb} handleFormData={handleAdresaOb} utility={'EE'} />}
            {((LCOptions.length > 0 && tipLC === 1) && (tipAdresa === 'AA' || !isCountyInList)) && <AdresaObiectivuluiAA isEditing={isEditing} innerRef={innerRef} formData={adresaOb} handleFormData={handleAdresaOb} utility={'EE'} />}
            {(LCOptions.length > 0 && tipLC === 2) && <AdresaObiectivuluiPOD getATR={getATRDetails} handleValidationError={handleValidationError} handleWasDataVerified={handleWasDataVerified} isEditing={isEditing} innerRef={innerRef} formData={adresaOb} handleFormData={handleAdresaOb} />}

            {(wasDataVerified && tipLC === 2) && <Row className="mt-2">
                <Col>
                    <Input
                        label='Numărul avizului tehnic de racordare / certificatului de racordare:'
                        value={nrAviz}
                        onChange={(e) => {
                            handleNrAviz(e.target.value);
                        }}
                        id="Aviz"
                        disabled={isEditing === false}
                    />
                </Col>
                <Col>
                    <DatePicker
                        label="Data ATR / CR"
                        name="Data ATR / CR"
                        selected={dataAviz === null ?  '' : dataAviz !== '' ? moment(dataAviz).toDate() : dataAviz}
                        onChange={(e: any) => {
                            if (e !== null) {
                                let date = moment(e).format('YYYY-MM-DD');
                                handleDataAviz(date);
                            } else {
                                handleDataAviz('');
                            }
                        }}
                        disabled={isEditing === false}
                        mbZero
                    // dateFormat={'YYYY-MM-DDThh:mm:ss'}
                    />
                </Col>
            </Row>}

            {
                (casnic === true && tipLC === 1) && <ChoiceComponent
                    title={'Locuința este construită/va fi terminată până la finalizarea racordării?*'}
                    options={[{ name: 'Da', id: true }, { name: 'Nu', id: false }]}
                    onChange={handleConstructieFinalizata}
                    value={constructieFinalizata}
                    disabled={isEditing === false}
                    id="cf"
                />
            }

            {
                (casnic === true && tipLC === 1 && constructieFinalizata === false) && <ChoiceComponent
                    title={'Se solicită organizare de șantier*'}
                    options={[{ name: 'Da', id: true }, { name: 'Nu', id: false }]}
                    onChange={handleSantier}
                    value={santier}
                    disabled={isEditing === false}
                    id="san"
                />
            }

            { (purposeType.length > 0 && tipLC === 2) && renderPurposeType() }

            { (purposeType.length > 0 && isOtherPurposeTypeActive() && tipLC === 2) && (
                <Row>
                    <Col xs={12} sm={6}>
                        <Input
                            label="Alte precizări privind scopul cererii*"
                            value={precizari}
                            onChange={(e) => handlePrecizari(e.target.value)}
                            disabled={isEditing === false}
                        />
                    </Col>
                </Row>
            ) }

            { (purposeType.length > 0 && tipLC === 1) && (
                <Row>
                    <Col xs={12} sm={6}>
                        <Input
                            label="Alte precizări privind scopul cererii (spor de putere, transcriere, separare, etc.)"
                            value={precizari}
                            onChange={(e) => handlePrecizari(e.target.value)}
                            disabled={isEditing === false}
                        />
                    </Col>
                </Row>
            ) }

            {/* {
                casnic === true && <Row>
                    <h5 className="mb-4 d-flex">Alte precizări privind scopul cererii (spor de putere, transcriere, separare, etc.)</h5>
                    <Col xs={12} sm={4}>
                        <Input
                            label='Alte precizări'
                            value={precizari}
                            onChange={(e) => {
                                handlePrecizari(e.target.value);
                            }}
                            disabled={isEditing === false}
                        />
                    </Col>
                </Row>
            } */}

            {validationError !== '' && <p style={{ fontWeight: 'bold', color: 'red' }}>{validationError}</p>}
            {(error !== '') && <p style={{ color: 'red', fontWeight: 'bold' }}>{error}</p>}
            <div className="d-flex flex-row justify-content-between">
                <Button className="d-flex" onClick={handlePrevStep}>Pasul anterior</Button>
                <Button className="d-flex" onClick={handleNextStep}>Pasul următor</Button>
            </div>
        </div>
    );
};

export default ObiectivulDeRacordat;
