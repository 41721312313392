import React, { Fragment, useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import { Form, Formik, Field, ErrorMessage } from 'formik';
import { ValidateOTPSchema } from '../../../helpers/schemas';
import { useDispatch } from 'react-redux';
import { reqLogin } from '@actions/user/user';
import { validateOtp, login } from '@api/user/user';
import { IState } from '@type/store';
import { useSelector } from 'react-redux';
import { toggleLoader } from '@actions/LoaderActions';
import {
  IDENTITY_ROLE_CLIENT_FIZIC,
  IDENTITY_ROLE_CLIENT_JURIDIC,
  IDENTITY_ROLE_OPERATOR_ECONOMIC,
  IDENTITY_ROLE_PARTENER_VALORI_MASURATE
} from '@constants/Roles/RolesIdentity';
import { jwtDecode } from 'jwt-decode';
import { Input } from '@components/common/Input';

const ValidateOTPFormComponent = (props: any) => {
  const [errorMessage, setErrorMessage] = useState('');
  const deviceId: string | undefined = useSelector<IState>((state) => state.device.deviceId) as string;
  const redirect_url: string | undefined = useSelector<IState>((state) => state.options.oldSite) as string;
  const dispatch = useDispatch();
  const { switchStates } = props;

  useEffect(() => {
    return () => {
      setErrorMessage('');
    };
  }, []);

  const getErrorMessage = (errorCode: number) => {
    switch (errorCode) {
      case 1:
        return 'E-mail gresit.';
      case 2:
        return 'Parola gresita.';
      case 5:
        return 'OTP-ul este invalid.';
      default:
        return 'E-mail sau parola gresita.';
    }
  };

  return (
    <div className="login-form-container">
      <h4 className="mb-4">Validează codul primit prin telefon sau email</h4>
      <Fragment>
        <Formik
          initialValues={{ otp: '' }}
          validationSchema={ValidateOTPSchema}
          validateOnChange={false}
          onSubmit={async (values) => {
            dispatch(toggleLoader(true));
            const data = {
              deviceId: deviceId,
              userEmail: props.email,
              otp: values.otp,
              password: props.password,
              transactionId: props.transactionId
            };
            let response = await validateOtp(data);
            if (response && !response.success) {
              setErrorMessage(getErrorMessage(response.errorCode));
            }
            if (
              response &&
              !response.changePasswordRequired &&
              !response.otpRequired &&
              response.success &&
              response.jwt &&
              response.jwt.access_token
            ) {
              const loginPayload = {
                deviceId: deviceId,
                userEmail: props.email,
                password: props.password
              };

              // dispatch(reqLogin(props.email, props.password, response.jwt.access_token));
              // document.cookie = 'token=' + response.jwt.access_token;
              // window.location.href = `${redirect_url}/login?token=${response.jwt.access_token}`;
              await login(loginPayload).then((loginRes: any) => {
                if (loginRes && loginRes.jwt && loginRes.jwt.access_token) {
                  dispatch(reqLogin(props.email, loginRes.jwt.access_token));
                  document.cookie = 'token=' + loginRes.jwt.access_token;

                  //redirect pe vechi doar pentru rol diferit de PARTENER_VALORI_MASURATE
                  const token = loginRes.jwt.access_token;
                  const decoded = jwtDecode(token);
                  //@ts-ignore
                  const roles = decoded?.groups || [];
                  if (typeof localStorage !== 'undefined') {
                    if (localStorage.getItem('ReturnUrl')) {
                      localStorage.setItem('ReturnUrlActive', 'true');
                    }
                  }
                  if (
                    roles.includes(IDENTITY_ROLE_PARTENER_VALORI_MASURATE) ||
                    roles.includes(IDENTITY_ROLE_CLIENT_FIZIC) ||
                    roles.includes(IDENTITY_ROLE_CLIENT_JURIDIC)
                  ) {
                    window.location.href = '/solicitari';
                  } else if (roles.includes(IDENTITY_ROLE_OPERATOR_ECONOMIC)) {
                    window.location.href = '/solicitari-operatori-economici';
                  } else {
                    window.location.href = `${redirect_url}/login?token=${loginRes.jwt.access_token}`;
                  }
                }
              });
            }
            dispatch(toggleLoader(false));
          }}
        >
          {(props) => (
            <Fragment>
              <Form>
                <div className="form-container">
                  <div className="mb-4">
                    <Field
                      className="input-field"
                      id="otp"
                      name="otp"
                      placeholder="OTP"
                      render={() => (
                        <Input
                          label="OTP"
                          mbZero
                          onBlur={() => {
                            props.setFieldTouched('otp');
                          }}
                          onChange={(e: any) => {
                            e.target.value.length !== 0 && props.setFieldValue('otp', e.target.value);
                            e.target.value.length === 0 && props.setFieldValue('otp', '');
                          }}
                        />
                      )}
                    />
                    <ErrorMessage name="otp" render={(msg) => <div style={{ color: 'red', fontWeight: 'bold' }}>{msg}</div>} />
                    {errorMessage !== '' && <div style={{ color: 'red', fontWeight: 'bold' }}>{errorMessage}</div>}
                  </div>
                </div>
                <div className="buttons-container d-flex flex-row align-items-center">
                  <Button type="submit">Accesează</Button>
                  <Button style={{ marginLeft: '10px' }} onClick={() => switchStates('login')}>
                    Inapoi
                  </Button>
                </div>
              </Form>
            </Fragment>
          )}
        </Formik>
      </Fragment>
    </div>
  );
};

export default ValidateOTPFormComponent;
