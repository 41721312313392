import React from 'react';
// import { AemWrapper } from '@components/global';
import FooterMenu from '@components/delgaz/Footer/FooterMenu';
import { FooterKentico } from '../../typescript/kentico-types';
import { DelgazWrapper } from '@components/global/DelgazWrapper';

export type FooterCompProps = { kentico: FooterKentico };

export const FooterUserComp = (props: FooterCompProps): JSX.Element => {
  return (
    <DelgazWrapper>
      <FooterMenu {...props} />
    </DelgazWrapper>
  );
};
