import { toggleLoader } from '@actions/LoaderActions';
import { GetCounties } from '@api/geoApi';
import { getTypes, postStepThree } from '@api/prosumatori/prosumatori';
import DatePicker from '@components/common/Date/DatePicker';
import { Input } from '@components/common/Input';
import ChoiceComponent from '@components/prosumatori/components/ChoiceComponent';
import AdresaObiectivului from '@components/prosumatori/forms/AdresaObiectivului';
import AdresaObiectivuluiPOD from '@components/prosumatori/forms/AdresaObiectivuluiPOD';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tippy from '@tippyjs/react';
import { IState } from '@type/store';
import axios from 'axios';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import AdresaObiectivuluiAA from '../forms/AdresaObiectivuluiAA';

const AdresaObiectiv = (props: {
    tipCerere: any,
    handleTipCerere: any,
    handleRequestType: any,
    isEditing: any,
    requestId: any,
    setStep: any,
    currentStep: any,
    progress: any,
    setProgress: any,
    tipLC: any,
    handleTipLC: any,
    tipAdresa: any,
    handleTipAdresa: any,
    nrAviz: string,
    handleNrAviz: any,
    dataAviz: string,
    handleDataAviz: any,
    dateUser: any,
    wasDataVerified: any,
    handleWasDataVerified: any,
    adresaOb: any,
    handleAdresaOb: any,
    tipLProd: any,
    handleTipLProd: any,
    sursaFinantare: any,
    handleSursaFinantare: any,
    sporPutere: any,
    handleSporPutere: any,
    modificariInst: any,
    handleModificariInst: any,
    solicit: any,
    handleSolicit: any,
    numeLC: any,
    handleNumeLC: any,
    dateIdentificare: any
}) => {
    const {
        tipCerere,
        handleTipCerere,
        handleRequestType,
        isEditing,
        requestId,
        setStep,
        currentStep,
        progress,
        setProgress,
        tipLC,
        handleTipLC,
        tipAdresa,
        handleTipAdresa,
        nrAviz,
        handleNrAviz,
        dataAviz,
        handleDataAviz,
        wasDataVerified,
        handleWasDataVerified,
        adresaOb,
        handleAdresaOb,
        tipLProd,
        handleTipLProd,
        sursaFinantare,
        handleSursaFinantare,
        sporPutere,
        handleSporPutere,
        modificariInst,
        handleModificariInst,
        solicit,
        handleSolicit,
        numeLC,
        handleNumeLC,
        dateIdentificare
    } = props;
    const [LCOptions, setLCOptions] = useState([]);
    const [LPOptions, setLPOptions] = useState([]);
    const [SFOptions, setSFOptions] = useState([]);
    //eslint-disable-next-line
    const [RROptions, setRROptions] = useState([]);
    const [showModal, setShowModal] = useState(false);

    const [validationError, setValidationError] = useState('');

    const [isCountyInList, setIsCountyInList] = useState(false);
    const [wasCountyChecked, setWasCountyChecked] = useState(false);

    const token: string | undefined = useSelector<IState>((state) => state.auth.token) as string;

    const handleValidationError = (value: string) => {
        setValidationError(value);
    };

    const getATRDetails = async () => {
        await axios.get(`https://msapi.delgaz.ro/iconnect/SAP/atr?pod=${adresaOb.POD}`, {
            headers: {
                'Ocp-Apim-Subscription-Key': 'e0064182bc534b33a19c781c744010d9',
                'Authorization': 'Bearer ' + token
            }
        }).then((response) => {
            if (response && response.data && response.data.numar_doc) {
                if (response.data.numar_doc !== null) {
                    handleNrAviz(response.data.numar_doc);
                    handleDataAviz(response.data.data_doc, true);
                }
            }
        }).catch((err) => console.log(err));

        dispatch(toggleLoader(false));
    };


    useEffect(() => {
        window.scrollTo(0, 0);
        handleTipAdresa(tipAdresa);
        mountTypes();
    }, []);

    const mountTypes = async () => {
        dispatch(toggleLoader(true));

        await getOptionTypes(['ApplicationType']).then((res) => setLCOptions(res));
        await getOptionTypes(['ProductionPlaceType']).then((res) => setLPOptions(res));
        await getOptionTypes(['SourceFoundingType']).then((res) => setSFOptions(res));
        solicit.length === 0 && getReasonType(['RequestReasonType'], 'EE').then((res) => {
            let data = res;
            console.log(data);
            data.map((el: any, i: number) => {
                el.active = false;
            });

            handleSolicit(data);
            setRROptions(res);
        });

        dispatch(toggleLoader(false));
    };

    useEffect(() => {
        if (dateIdentificare.Judet !== '' && wasCountyChecked === false) {
            checkIsCountyInList();
        }
    }, [dateIdentificare.Judet]);

    const innerRef = useRef(null);

    const dispatch = useDispatch();

    const checkIsCountyInList = async () => {
        dispatch(toggleLoader(true));

        let found = false;

        await GetCounties('EE').then((response) => {
            if (response) {
                response.map((county: any, index: number) => {
                    if (county.countyCode === dateIdentificare.Judet) {
                        setIsCountyInList(true);
                        found = true;
                    }
                });

                if (found === false) {
                    handleTipAdresa('AA');
                    setIsCountyInList(false);
                }
            }
        });

        setWasCountyChecked(true);
        dispatch(toggleLoader(false));
    };

    const getOptionTypes = async (keys: string[], utility?: string) => {
        return await getTypes(keys, utility).then((res: any) => {
            return res[keys[0]];
        });
    };

    const getReasonType = async (keys: string[], utility?: string) => {
        return await getTypes(keys, utility).then((res: any) => {
            return res.requestreasontype;
        });
    };

    const constructSolicit = () => {
        let list: any[] = [];

        solicit.map((el: any) => {
            if (el.active === true) {
                list.push(el.id);
            }
        });

        return list;
    };

    const constructPayload = () => {
        return {
            requestId: requestId,
            TipLC: tipLC,
            POD: adresaOb.POD,
            cod_loc_consum: adresaOb.cod_loc_consum,
            TipAdresa: tipLC === 1 ? tipAdresa : 'AA',
            Adresa: {
                Judet: adresaOb.Judet,
                Localitate: adresaOb.Localitate,
                Comuna: adresaOb.Comuna,
                Strada: adresaOb.Strada,
                Numar: adresaOb.Numar,
                Bloc: adresaOb.Bloc,
                Scara: adresaOb.Scara,
                Ap: adresaOb.Ap
            },
            nr_aviz: tipLC === 1 ? null : nrAviz,
            data_aviz: tipLC === 1 ? null : (dataAviz !== '' ? dataAviz : null),
            nrCF: adresaOb.nrCF,
            nrCad: adresaOb.nrCad,
            TipLP: tipLProd,
            sursaFinantare: sursaFinantare,
            sporPutere: sporPutere,
            modificari: modificariInst === null ? false : modificariInst,
            solicit: constructSolicit(),
            nume_loc_consum: numeLC
        };
    };

    const stepThreeApiCall = async (payload: any) => {
        dispatch(toggleLoader(true));

        await postStepThree(payload)
            .then((response: any) => {
                console.log(response);
                if (response && response.status === 200) {
                    setShowModal(true);
                    handleTipCerere(response.data.tip_cerere);
                }
            })
            .catch((error: any) => {
                console.log(error);
                if (error && error.status === 200) {
                    setShowModal(true);
                } else if (error === 'Request is completed') {
                    setValidationError('Cererea a fost deja finalizată.');
                } else if (error && error.StatusCode && error.StatusCode === 500) {
                    setValidationError('A intervenit o eroare tehnică. Vă rugăm reveniți mai târziu.');
                } else if (error && error.statusCode && error.statusCode === 500) {
                    setValidationError('A intervenit o eroare tehnică. Vă rugăm reveniți mai târziu.');
                }
            });

        dispatch(toggleLoader(false));
    };

    const handleNextStep = () => {
        if (isEditing !== false) {
            setValidationError('');
            let lc = document.getElementById('LC');
            lc?.classList.remove('red');
            let lp = document.getElementById('LP');
            lp?.classList.remove('red');
            let sf = document.getElementById('SF');
            sf?.classList.remove('red');
            let sp = document.getElementById('SP');
            sp?.classList.remove('red');
            let sol = document.getElementById('sol');
            sol?.classList.remove('red');
            let adr = document.getElementById('ADR');
            adr?.classList.remove('red');
            let numeLocConsum = document.getElementById('numeLC');
            numeLocConsum?.classList.remove('red');
            let validated = true;

            //@ts-ignore
            innerRef.current?.submitForm();

            if (tipLC === null) {
                let err = document.getElementById('LC');
                err?.classList.add('red');
                setValidationError('Câmpurile marcate cu * sunt obligatorii.');
                validated = false;
            }

            if (tipLC == 1 && (tipAdresa === null || tipAdresa === '')) {
                let err = document.getElementById('ADR');
                err?.classList.add('red');
                setValidationError('Câmpurile marcate cu * sunt obligatorii.');
                validated = false;
            }

            if (tipLProd === null) {
                let err = document.getElementById('LP');
                err?.classList.add('red');
                setValidationError('Câmpurile marcate cu * sunt obligatorii.');
                validated = false;
            }

            if (sursaFinantare === null) {
                let err = document.getElementById('SF');
                err?.classList.add('red');
                setValidationError('Câmpurile marcate cu * sunt obligatorii.');
                validated = false;
            }

            if (sporPutere === null) {
                let err = document.getElementById('SP');
                err?.classList.add('red');
                setValidationError('Câmpurile marcate cu * sunt obligatorii.');
                validated = false;
            }

            if (tipLC === 2 && !wasDataVerified) {
                setValidationError('Te rugăm să verifici informațiile pentru locul de consum existent.');
                validated = false;
            }

            if (numeLC === '') {
                let err = document.getElementById('numeLC');
                err?.classList.add('red');
                setValidationError('Câmpurile marcate cu * sunt obligatorii.');
                validated = false;
            }

            //@ts-ignore
            if (innerRef.current && !innerRef.current?.isValid) {
                //@ts-ignore
                console.log(innerRef.current?.isValid);
                let err = document.getElementById('ADR');
                err?.classList.add('red');
                validated = false;
            }

            //@ts-ignore
            if (innerRef.current && innerRef.current?.isValid && validated === true) {
                stepThreeApiCall(constructPayload());
                //@ts-ignore
            }
        } else {
            setStep(currentStep + 1);
            currentStep === progress && setProgress(currentStep + 1);
        }
    };

    const handlePrevStep = () => {
        setStep(currentStep - 1);
    };

    const setOptionActive = (option: any) => {
        let op = solicit;

        op.map((el: any, i: number) => {
            if (el === option) {
                el.active = !el.active;
            }
        });

        handleSolicit(op);
    };

    const renderModal = () => {
        return <Modal show={showModal} centered onHide={() => setShowModal(false)}>
            <Modal.Body>
                <p>În urma selecțiilor tale, se va genera: <b>{tipCerere}</b></p>
                <ul>
                    <li>Loc de consum: {tipLC === 1 ? 'Nou' : 'Existent'}</li>
                    <li>Loc de producere: {tipLProd === 1 ? 'Nou' : 'Existent'}</li>
                    <li>Sursa de finanțare: {sursaFinantare === 1 ? 'Proprie' : 'Accesarea de programe de finanțare guvernamentale'}</li>
                    <li>Spor de putere: {sporPutere === true ? 'Da' : 'Nu'}</li>
                    <li>Modificări in instalația de utilizare: {modificariInst === true ? 'Da' : 'Nu'}</li>
                </ul>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={() => setShowModal(false)}>Revizuiește opțiunile</Button>
                <Button
                    onClick={() => {
                        handleRequestType();
                        setStep(currentStep + 1);
                        currentStep === progress && setProgress(currentStep + 1);
                    }
                    }
                >
                    Continuă
                </Button>
            </Modal.Footer>
        </Modal>;
    };

    const renderSolicit = () => {
        return (
            <div className="mt-3 mb-3">
                <h5 className="m-0" id="sol">Prin prezenta solicit:</h5>
                <div className={'d-flex m-3 choices checkbox-container flex-column'}>
                    {solicit.map((option: any, idx: number) => (
                        <span
                            key={idx}
                            style={{ marginRight: '25px', marginBottom: '8px' }}
                            onClick={() => {
                                if (isEditing !== false) {
                                    setOptionActive(option);
                                }
                            }}
                        >
                            <input
                                type="checkbox"
                                onChange={() => setOptionActive(option)}
                                onClick={() => {
                                    if (isEditing !== false) {
                                        setOptionActive(option);
                                    }
                                }}
                                value={option.name}
                                checked={option.active}
                                style={{ marginRight: '4px' }}
                                className="create-account-checkbox"
                                disabled={isEditing === false}
                            // disabled={disabled}
                            />
                            <span style={{ fontSize: '18px' }}>{option.title || option.name}</span>
                        </span>
                    ))}
                </div>
            </div>
        );
    };

    return (
        <div className="prosumatori-adresa-obiectiv m-3 w-100 d-flex align-self-center flex-column">
            <h4 className="register-section-title mt-3">Adresa obiectivului și tipul de solicitare</h4>
            {LCOptions.length > 0 && <ChoiceComponent
                title={'Tipul locului de consum:*'}
                subtitle={'(Locul de consum este incinta sau zona în care se consumă printr-o singură instalație de utilizare, energia electrică furnizată printr-una sau mai multe instalații de racordare.)'}
                options={LCOptions}
                onChange={handleTipLC}
                value={tipLC}
                disabled={isEditing === false}
                id="LC"
            />}

            <h5 className="mb-2" id="numeLC">Denumirea locului de consum:*</h5>
            <Row className="mb-2 mt-2">
                <Col xs={12} sm={4}>
                    <div className="position-relative">
                        <span
                            style={{ fontSize: '18px', position: 'absolute', left: 20 + (7.25 * ((numeLC ? numeLC.length : 5) / 0.90)), bottom: '-38px', zIndex: 3 }}
                        >+ centrala fotovoltaica</span>
                        <Input
                            value={`${numeLC}`}
                            label="Denumire loc consum"
                            onChange={(e) => handleNumeLC(e.target.value)}
                            disabled={isEditing === false}
                        />
                        <span className="position-absolute" style={{ right: '4%', bottom: '-36px', zIndex: 5 }}>
                            <Tippy
                                content={
                                    <div className="p-3" style={{ background: 'rgba(0,0,0,0.8)', color: 'white', borderRadius: '6px' }}>
                                        Denumirea locului de consum se regăsește în certificatul de urbanism sau actul de proprietate. De ex: Locuință, Garaj, Seră, Magazin, etc.
                                    </div>
                                }
                                duration={0}
                                placement="bottom"
                            >
                                <div className="icon-div">
                                    <FontAwesomeIcon icon={faCircleInfo} className="icon" color="red" />
                                </div>
                            </Tippy>
                        </span>
                    </div>
                </Col>

            </Row>


            {
                tipLC === 1 && wasCountyChecked && <ChoiceComponent
                    title={'Adresa locului de consum este:*'}
                    options={[{ name: 'Adresa clientului final', id: 'AU' }, { name: 'Altă adresă', id: 'AA' }]}
                    onChange={(value: string) => handleTipAdresa(value, true)}
                    value={tipAdresa}
                    id="ADR"
                    disabled={isEditing === false || !isCountyInList}
                />
            }

            {(LCOptions.length > 0 && tipLC === 1 && tipAdresa === 'AU') && <AdresaObiectivului isEditing={isEditing} innerRef={innerRef} formData={adresaOb} handleFormData={handleAdresaOb} utility={'EE'} />}
            {(LCOptions.length > 0 && tipLC === 1 && tipAdresa === 'AA') && <AdresaObiectivuluiAA isEditing={isEditing} innerRef={innerRef} formData={adresaOb} handleFormData={handleAdresaOb} utility={'EE'} />}
            {(LCOptions.length > 0 && tipLC === 2) && <AdresaObiectivuluiPOD getATR={getATRDetails} handleValidationError={handleValidationError} handleWasDataVerified={handleWasDataVerified} isEditing={isEditing} innerRef={innerRef} formData={adresaOb} handleFormData={handleAdresaOb} />}

            {(wasDataVerified && tipLC === 2) && <Row className="mt-2">
                <Col>
                    <Input
                        label='Numărul avizului tehnic de racordare / certificatului de racordare:'
                        value={nrAviz}
                        onChange={(e) => {
                            handleNrAviz(e.target.value);
                        }}
                        id="Aviz"
                    />
                </Col>
                <Col>
                    <DatePicker
                        label="Data ATR / CR"
                        name="Data ATR / CR"
                        selected={dataAviz !== '' ? moment(dataAviz).toDate() : dataAviz}
                        onChange={(e: any) => {
                            if (e !== null) {
                                handleDataAviz(e, true);
                            } else {
                                handleDataAviz('', true);
                            }
                        }}
                        disabled={isEditing === false}
                        maxDate={moment().toDate()}
                        mbZero
                    // dateFormat={'YYYY-MM-DDThh:mm:ss'}
                    />
                </Col>
            </Row>}

            {LPOptions.length > 0 && <ChoiceComponent
                title={'Tipul locului de producere:*'}
                subtitle={'(Locul de producere este incinta sau zona în care sunt amplasate instalațiile de producere a energiei electrice ale unui utilizator al rețelei electrice.)'}
                options={LPOptions}
                onChange={handleTipLProd}
                value={tipLProd}
                disabled={tipLC === 1 || isEditing === false}
                id="LP"
            />}

            {SFOptions.length > 0 && <ChoiceComponent
                title={'Sursa de finanțare:*'}
                options={SFOptions}
                onChange={handleSursaFinantare}
                value={sursaFinantare}
                disabled={isEditing === false}
                id="SF"
            />}

            <ChoiceComponent
                title={'Se solicită spor de putere pentru locul de consum și/sau de producere?*'}
                options={[{ title: 'Da', id: true }, { title: 'Nu', id: false }]}
                onChange={handleSporPutere}
                value={sporPutere}
                disabled={tipLC === 1 || isEditing === false}
                id="SP"
            />

            {sporPutere === false && <ChoiceComponent
                title={'Se vor realiza modificări în instalația de utilizare?'}
                subtitle={'(ex. montare de receptoare noi, montare de instalații de producere noi)'}
                options={[{ title: 'Da', id: true }, { title: 'Nu', id: false }]}
                onChange={handleModificariInst}
                value={modificariInst}
                disabled={tipLC === 1 || isEditing === false}
                canUncheck={true}
                id="mod"
            />}

            {solicit.length > 0 && renderSolicit()}

            <p>În funcție de câmpurile selectate mai sus, se va genera în mod automat, una din urmatoarele tipuri de cerere:</p>
            <ul>
                <li>Cerere de racordare pentru un loc de consum si producere</li>
                <li>Cerere de racordare pentru locul de consum şi de producere care accesează programe de finanţare</li>
                <li>Notificare privind racordarea la un loc de consum existent a unei instalații de producere a energiei electrice</li>
                <li>Notificare privind certificarea calității de prosumator pentru utilizatorul care deține un loc de consum și de producere prevăzut cu instalații de producere a energiei electrice din surse regenerabile</li>
            </ul>

            {validationError !== '' && <p style={{ fontWeight: 'bold', color: 'red' }}>{validationError}</p>}
            <div className="d-flex flex-row justify-content-between">
                <Button className="d-flex" onClick={handlePrevStep}>Pasul anterior</Button>
                <Button className="d-flex" onClick={handleNextStep}>Pasul următor</Button>
            </div>

            {renderModal()}
        </div>
    );
};

export default AdresaObiectiv;