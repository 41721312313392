import { toggleLoader } from '@actions/LoaderActions';
import { postStepFive } from '@api/prosumatori/prosumatori';
import ChoiceComponent from '@components/prosumatori/components/ChoiceComponent';
import TabelTipRacord from '@components/prosumatori/components/TabelTipRacord';
import DynamicTable from '@components/prosumatori/components/table/DynamicTable';
import { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useDispatch } from 'react-redux';

const LPNou = (props: {
    isEditing: any,
    requestId: any,
    setStep: any,
    currentStep: any,
    progress: any,
    setProgress: any,
    dateTehniceLP: any,
    handleDateTehniceLP: any,
    generatoare: number | null,
    handleGeneratoare: any,
    tabelDateGen: any,
    dateGen: any,
    handleDateGen: any,
    mijloaceComp: number | null,
    handleMijloaceComp: any,
    tabelMC: any,
    handleDateMC: any,
    fotovoltaic: number | null,
    handleFotovoltaic: any,
    tabelDateFoto: any,
    handleDateFoto: any,
    invertoare: number | null,
    handleInvertoare: any,
    tabelDateInv: any,
    handleDateInv: any,
    psi: any,
    handlePSI: any,
    pmsa: any,
    handlePMSA: any,
    surseRegen: number | null,
    handleSurseRegen: any,
    detaliiSchema: any,
    handleDetaliiSchema: any,
    capacitate: any,
    handleCapacitate: any,
    masurare: number | null,
    handleMasurare: any,
    tabelDateEM: any,
    handleDateEM: any,
    dateMC: any,
    dateFoto: any,
    dateInv: any,
    dateEM: any,
}) => {
    const {
        isEditing,
        requestId,
        setStep,
        currentStep,
        progress,
        setProgress,
        dateTehniceLP,
        handleDateTehniceLP,
        generatoare,
        handleGeneratoare,
        tabelDateGen,
        dateGen,
        handleDateGen,
        mijloaceComp,
        handleMijloaceComp,
        tabelMC,
        handleDateMC,
        fotovoltaic,
        handleFotovoltaic,
        tabelDateFoto,
        handleDateFoto,
        invertoare,
        handleInvertoare,
        tabelDateInv,
        handleDateInv,
        psi,
        handlePSI,
        pmsa,
        handlePMSA,
        surseRegen,
        handleSurseRegen,
        detaliiSchema,
        handleDetaliiSchema,
        capacitate,
        handleCapacitate,
        masurare,
        handleMasurare,
        tabelDateEM,
        handleDateEM,
        dateMC,
        dateFoto,
        dateInv,
        dateEM,
    } = props;
    const [validationError, setValidationError] = useState('');
    const [GenError, setGenError] = useState('');
    const [MCError, setMCError] = useState('');
    const [FotoError, setFotoError] = useState('');
    const [InvError, setInvError] = useState('');
    const [SRError, setSRError] = useState('');
    const [EMError, setEMError] = useState('');
    const [error, setError] = useState('');
    const [numbersError, setNumbersError] = useState('');

    const dispatch = useDispatch();

    useEffect(() => {
        window.scrollTo(0,0);
    }, []);

    const setOptionsForGenerators = (data: any) => {
        let newData = JSON.parse(JSON.stringify(data));

        newData.map((el: any, i: number) => {
            Object.keys(el).map((key: any, idx: number) => {
                if (idx === 1) {
                    if (el[key] === 'Asincron') {
                        el[key] = 1;
                    } else if (el[key] === 'Sincron') {
                        el[key] = 2;
                    }
                }
                if (idx === 2) {
                    if (el[key] === 'Termo') {
                        el[key] = 1;
                    } else if (el[key] === 'Hidro') {
                        el[key] = 2;
                    } else if (el[key] === 'Eolian') {
                        el[key] = 3;
                    }
                }
            });
        });

        return newData;
    };

    const constructPayload = () => {
        return {
            requestId: requestId,
            date_tehnice_old: {
                KW: Number(dateTehniceLP.seKW.replace(',', '.')),
                KVA: Number(dateTehniceLP.seKVA.replace(',', '.'))
            },
            date_tehnice_new: {
                KW: Number(dateTehniceLP.seKW.replace(',', '.')),
                KVA: Number(dateTehniceLP.seKVA.replace(',', '.'))
            },
            exista_generatoare: generatoare === 1,
            exista_mijl_comp: mijloaceComp === 1,
            exista_fotovoltaice: fotovoltaic === 1,
            exista_invertoare: invertoare === 1,
            servicii_interne: {
                psi: psi.includes(',') ? psi.replace(',','.') : psi,
                pmsa: pmsa.includes(',') ? pmsa.replace(',','.') : pmsa,
            },
            exista_surse_regen: surseRegen === 1,
            exista_echipamente_masurare: masurare === 1,
            ...(generatoare === 1 ? {generatoare: setOptionsForGenerators(dateGen)} : {generatoare: []}),
            ...(mijloaceComp === 1 ? {mijl_comp: dateMC} : {mijl_comp: []}),
            ...(fotovoltaic === 1 ? {fotovoltaice: dateFoto} : {fotovoltaice: []}),
            ...(invertoare === 1 ? {invertoare: dateInv} : {invertoare: []}),
            ...(surseRegen === 1 ?
                {surse_regen: {detalii_schema_alimentare: detaliiSchema !== '' ? detaliiSchema : null, capacitate_baterii: capacitate !== '' ? capacitate : null}} :
                {surse_regen: {detalii_schema_alimentare: null, capacitate_baterii: null}}),
            ...(masurare === 1 ? {echipamente: dateEM} : {echipamente: []})
        };
    };

    const stepFiveApiCall = async (payload: any) => {
        dispatch(toggleLoader(true));

        await postStepFive(payload)
            .then((response: any) => {
                console.log(response);
                if (response && response.status === 200) {
                    setStep(currentStep+1);
                    currentStep === progress && setProgress(currentStep+1);
                }
            })
            .catch((error: any) => {
                if (error && error.status === 200) {
                    setStep(currentStep+1);
                    currentStep === progress && setProgress(currentStep+1);
                } else if (error && error.StatusCode === 500) {
                    setError('A apărut o problemă tehnică. Vă rugăm reveniți mai târziu.');
                } else if (error === 'Request is completed') {
                    setError('Cererea a fost deja finalizată.');
                } else if (error && error.statusCode && error.statusCode === 500) {
                    setError('A intervenit o eroare tehnică. Vă rugăm reveniți mai târziu.');
                }
            });

        dispatch(toggleLoader(false));
    };

    const handleNextStep = () => {
        if( isEditing !== false) {
            setValidationError('');
            setGenError('');
            setMCError('');
            setFotoError('');
            setInvError('');
            setSRError('');
            setEMError('');
            setError('');
            setNumbersError('');
            let dt = document.getElementById('DT');
            dt?.classList.remove('red');
            let table = document.getElementById('DATE');
            table?.classList.remove('err-border');
            let gen = document.getElementById('gen');
            gen?.classList.remove('red');
            let mijl = document.getElementById('mijl');
            mijl?.classList.remove('red');
            let foto = document.getElementById('foto');
            foto?.classList.remove('red');
            let inv = document.getElementById('inv');
            inv?.classList.remove('red');
            let si = document.getElementById('si');
            si?.classList.remove('red');
            let siTable = document.getElementById('siTable');
            siTable?.classList.remove('err-border');
            let reg = document.getElementById('reg');
            reg?.classList.remove('red');
            let em = document.getElementById('em');
            em?.classList.remove('red');
            let wasValidated = true;

            if (generatoare === null) {
                let err = document.getElementById('gen');
                err?.classList.add('red');
                setValidationError('Câmpurile marcate cu * sunt obligatorii.');
                wasValidated = false;
            }
 
            if (generatoare === 1 && !validateTable(dateGen, 13)) {
                setGenError('Te rugăm să revizuiești informațiile completate.');
                let err = document.getElementById('gen');
                err?.classList.add('red');
                setValidationError('Câmpurile marcate cu * sunt obligatorii.');
                wasValidated = false;
            }
            if (generatoare === 1 &&
                (!validateNumbersInTable(dateGen, 0) || !validateNumbersInTable(dateGen, 3) || !validateNumbersInTable(dateGen, 4) ||
                    !validateNumbersInTable(dateGen, 5) || !validateNumbersInTable(dateGen, 6) || !validateNumbersInTable(dateGen, 7) ||
                    !validateNumbersInTable(dateGen, 8) || !validateNumbersInTable(dateGen, 9) || !validateNumbersInTable(dateGen, 10) ||
                    !validateNumbersInTable(dateGen, 11) || !validateNumbersInTable(dateGen, 12))
            ) {
                setNumbersError('Te rugăm să revizuiești informațiile completate.');
                let err = document.getElementById('gen');
                err?.classList.add('red');
                wasValidated = false;
            }

            if (mijloaceComp === null) {
                let err = document.getElementById('mijl');
                err?.classList.add('red');
                setValidationError('Câmpurile marcate cu * sunt obligatorii.');
                wasValidated = false;
            }

            if (mijloaceComp === 1 && !validateTable(dateMC, 5)) {
                setMCError('Te rugăm să revizuiești informațiile completate.');
                let err = document.getElementById('mijl');
                err?.classList.add('red');
                setValidationError('Câmpurile marcate cu * sunt obligatorii.');
                wasValidated = false;
            }
            if (mijloaceComp === 1 &&
                (!validateNumbersInTable(dateMC, 1) || !validateNumbersInTable(dateMC, 2) ||
                !validateNumbersInTable(dateMC, 3) || !validateNumbersInTable(dateMC, 4))
            ) {
                setNumbersError('Te rugăm să revizuiești informațiile completate.');
                let err = document.getElementById('DT');
                err?.classList.add('mijl');
                wasValidated = false;
            }

            if (fotovoltaic === null) {
                let err = document.getElementById('foto');
                err?.classList.add('red');
                setValidationError('Câmpurile marcate cu * sunt obligatorii.');
                wasValidated = false;
            }

            if (fotovoltaic === 1 && !validateTable(dateFoto, 6)) {
                setFotoError('Te rugăm să revizuiești informațiile completate.');
                let err = document.getElementById('foto');
                err?.classList.add('red');
                setValidationError('Câmpurile marcate cu * sunt obligatorii.');
                wasValidated = false;
            }
            if (fotovoltaic === 1 &&
                (!validateNumbersInTable(dateFoto, 0) || !validateNumbersInTable(dateFoto, 2) ||
                    !validateNumbersInTable(dateFoto, 3) || !validateNumbersInTable(dateFoto, 4) ||
                    !validateNumbersInTable(dateFoto, 5))
            ) {
                setNumbersError('Te rugăm să revizuiești informațiile completate.');
                let err = document.getElementById('foto');
                err?.classList.add('red');
                wasValidated = false;
            }

            if (invertoare === null) {
                let err = document.getElementById('inv');
                err?.classList.add('red');
                setValidationError('Câmpurile marcate cu * sunt obligatorii.');
                wasValidated = false;
            }

            if (invertoare === 1 && !validateTable(dateInv, 6)) {
                setInvError('Te rugăm să revizuiești informațiile completate.');
                let err = document.getElementById('inv');
                err?.classList.add('red');
                setValidationError('Câmpurile marcate cu * sunt obligatorii.');
                wasValidated = false;
            }
            if (invertoare === 1 && (!validateNumbersInTable(dateInv, 0) || !validateNumbersInTable(dateInv, 2) ||
                !validateNumbersInTable(dateInv, 3) || !validateNumbersInTable(dateInv, 4) ||
                !validateNumbersInTable(dateInv, 5))
            ) {
                setNumbersError('Te rugăm să revizuiești informațiile completate.');
                let err = document.getElementById('inv');
                err?.classList.add('red');
                wasValidated = false;
            }

            if (isNaN(Number(psi.replace(',','.'))) || isNaN(Number(pmsa.replace(',','.')))) {
                setNumbersError('Te rugăm să revizuiești informațiile completate.');
                let err = document.getElementById('si');
                err?.classList.add('red');
                let table = document.getElementById('siTable');
                table?.classList.add('err-border');
                wasValidated = false;
                console.log('teest');
            }

            if (surseRegen === null || surseRegen === 0) {
                let err = document.getElementById('reg');
                err?.classList.add('red');
                setValidationError('Câmpurile marcate cu * sunt obligatorii.');
                wasValidated = false;
            }

            if ((surseRegen === 1 && detaliiSchema === '') || (surseRegen === 1 && capacitate === '')) {
                setSRError('Te rugăm să revizuiești informațiile completate.');
                let err = document.getElementById('reg');
                err?.classList.add('red');
                wasValidated = false;
            }
            if (surseRegen === 1 && isNaN(Number(capacitate.replace(',','.')))) {
                setNumbersError('Te rugăm să revizuiești informațiile completate.');
                let err = document.getElementById('reg');
                err?.classList.add('red');
                wasValidated = false;
            }

            if (masurare === null) {
                let err = document.getElementById('em');
                err?.classList.add('red');
                setValidationError('Câmpurile marcate cu * sunt obligatorii.');
                wasValidated = false;
            }

            if (masurare === 1 && !validateTable(dateEM, null)) {
                setEMError('Te rugăm să revizuiești informațiile completate.');
                let err = document.getElementById('em');
                err?.classList.add('red');
                setValidationError('Câmpurile marcate cu * sunt obligatorii.');
                wasValidated = false;
            }

            if (dateTehniceLP.seKW === '' || dateTehniceLP.seKVA === '') {
                setValidationError('Câmpurile marcate cu * sunt obligatorii.');
                let err = document.getElementById('DT');
                err?.classList.add('red');
                let table = document.getElementById('DATE');
                table?.classList.add('err-border');
                wasValidated = false;
            }
            if (isNaN(Number(dateTehniceLP.seKW.replace(',', '.'))) || isNaN(Number(dateTehniceLP.seKVA.replace(',', '.')))) {
                setNumbersError('Te rugăm să revizuiești informațiile completate.');
                let err = document.getElementById('DT');
                err?.classList.add('red');
                wasValidated = false;
            }

            if (psi === '' || pmsa === '') {
                setValidationError('Câmpurile marcate cu * sunt obligatorii.');
                let err = document.getElementById('si');
                err?.classList.add('red');
                let table = document.getElementById('siTable');
                table?.classList.add('err-border');
                wasValidated = false;
            }

            if (invertoare === 1 && fotovoltaic === 2) {
                let err = document.getElementById('foto');
                err?.classList.add('red');
                setValidationError('Te rugăm să revizuiești informațiile completate.');
                wasValidated = false;
            }

            if (fotovoltaic === 1 && invertoare === 2) {
                let err = document.getElementById('inv');
                err?.classList.add('red');
                setValidationError('Te rugăm să revizuiești informațiile completate.');
                wasValidated = false;
            }

            if (wasValidated) {
                stepFiveApiCall(constructPayload());
            }
        } else {
            setStep(currentStep+1);
            currentStep === progress && setProgress(currentStep+1);
        }
    };

    const validateTable = (table: any, indexException: number | null) => {
        let validated = true;
        table.map((row: any, index: number) => {
            Object.keys(row).map((column: any, idx: number) => {
                if (idx !== indexException) {
                    if (row[column] === '' || row[column] === null) {
                        validated = false;
                    }
                }
            });
        });

        return validated;
    };

    const validateNumbersInTable = (table: any, indexToLookAt: number) => {
        let validated = true;

        table.map((row: any, index: number) => {
            Object.keys(row).map((column: any, idx: number) => {
                if (idx === indexToLookAt) {
                    if ((isNaN(row[column])) || (typeof row[column] !== 'number' && row[column].includes(',') && isNaN(Number(row[column].replace(',','.'))))) {
                        validated = false;
                    }
                }
            });
        });

        return validated;
    };

    const handlePrevStep = () => {
        setStep(currentStep-1);
    };

    return (
        <div className="prosumatori-informatii-tehnice-lc m-3 w-100 d-flex align-self-center flex-column">
            <h4 className="register-section-title mt-3 mb-4">Date tehnice și energetice pentru locul de producere</h4>

            <h5 id="DT">Date tehnice</h5>
            <TabelTipRacord
                tableData={dateTehniceLP}
                type={3}
                handleData={handleDateTehniceLP}
                isEditing={isEditing}
                tipPutere={2}
                id="DATE"
            />

            <div className="mt-2 mb-2">
                <br></br>
            </div>

            <ChoiceComponent
                title={'În instalația de producere există generatoare asincrone si sincrone?*'}
                options={[{title: 'Da', id: 1}, {title: 'Nu', id: 2}]}
                onChange={handleGeneratoare}
                value={generatoare}
                disabled={isEditing === false}
                id="gen"
            />
            {generatoare === 1 && <div className="w-100"><DynamicTable format={tabelDateGen} data={dateGen} handler={handleDateGen} isEditing={isEditing} scroll={true}/></div>}
            {GenError !== '' && <p style={{fontWeight: 'bold', color: 'red'}}>{GenError}</p>}

            <div className="mt-2 mb-2">
                <br></br>
            </div>

            <ChoiceComponent
                title={'Există mijloace de compensare a energiei reactive?*'}
                options={[{title: 'Da', id: 1}, {title: 'Nu', id: 2}]}
                onChange={handleMijloaceComp}
                value={mijloaceComp}
                disabled={isEditing === false}
                id="mijl"
            />
            {mijloaceComp === 1 && <DynamicTable format={tabelMC} data={dateMC} handler={handleDateMC} isEditing={isEditing}/>}
            {MCError !== '' && <p style={{fontWeight: 'bold', color: 'red'}}>{MCError}</p>}

            <div className="mt-2 mb-2">
                <br></br>
            </div>

            <ChoiceComponent
                title={'În instalația de producere există module generatoare de tip fotovoltaic?*'}
                options={[{title: 'Da', id: 1}, {title: 'Nu', id: 2}]}
                onChange={handleFotovoltaic}
                value={fotovoltaic}
                disabled={isEditing === false}
                id="foto"
            />
            {fotovoltaic === 1 && <DynamicTable format={tabelDateFoto} data={dateFoto} handler={handleDateFoto} isEditing={isEditing}/>}
            {FotoError !== '' && <p style={{fontWeight: 'bold', color: 'red'}}>{FotoError}</p>}

            <div className="mt-2 mb-2">
                <br></br>
            </div>

            <ChoiceComponent
                title={'În instalația de producere utilizați invertoare?*'}
                options={[{title: 'Da', id: 1}, {title: 'Nu', id: 2}]}
                onChange={handleInvertoare}
                value={invertoare}
                disabled={isEditing === false}
                id="inv"
            />
            {invertoare === 1 && <DynamicTable format={tabelDateInv} data={dateInv} handler={handleDateInv} isEditing={isEditing}/>}
            {InvError !== '' && <p style={{fontWeight: 'bold', color: 'red'}}>{InvError}</p>}

            <div className="mt-2 mb-2">
                <br></br>
            </div>

            <h5 id="si">Servicii interne ale instalației de producere:*</h5>
            <form className="m-0 mb-4 overflow-auto">
                <table className="dynamic-table" id="siTable">
                    <tbody>
                        <tr style={{height: '25px'}}>
                            <td className="p-2">Pi servicii interne (kW)</td>
                            <td>
                                <input
                                    className="table-input"
                                    onChange={(e) => {
                                        const re = /^[,.0-9\b]+$/;

                                        if (e.target.value === '' || re.test(e.target.value)) {
                                            handlePSI(e.target.value);
                                        }
                                    }}
                                    value={psi}
                                    disabled={isEditing === false}
                                />
                            </td>
                        </tr>
                        <tr style={{height: '25px'}}>
                            <td className="p-2">Puterea maxim simultan absorbită servicii interne (kW)</td>
                            <td>
                                <input
                                    className="table-input"
                                    onChange={(e) => {
                                        const re = /^[,.0-9\b]+$/;

                                        if (e.target.value === '' || re.test(e.target.value)) {
                                            handlePMSA(e.target.value);
                                        }
                                    }}
                                    value={pmsa}
                                    disabled={isEditing === false}
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </form>

            <div className="mt-2 mb-2">
                <br></br>
            </div>

            <ChoiceComponent
                title={'La locul de producere există sisteme de stocare a energiei electrice produse din surse regenerabile?*'}
                options={[{title: 'Da', id: 1}, {title: 'Nu', id: 2}]}
                onChange={handleSurseRegen}
                value={surseRegen}
                disabled={isEditing === false}
                id="reg"
            />
            {surseRegen === 1 && <form className="m-0 mb-4 overflow-auto">
                <table className="dynamic-table">
                    <tbody>
                    <tr style={{height: '25px'}}>
                        <td className="p-2">Detalii schemă alimentare</td>
                        <td colSpan={2} className="custom-td">
                            <input
                                className="table-input"
                                onChange={(e) => handleDetaliiSchema(e.target.value)}
                                value={detaliiSchema}
                                disabled={isEditing === false}
                            />
                        </td>
                    </tr>
                    <tr style={{height: '25px'}}>
                        <td className="p-2">Capacitate baterii de acumulare (Ah)</td>
                        <td className="custom-td">
                            <input
                                className="table-input"
                                onChange={(e) => {
                                    const re = /^[,.0-9\b]+$/;

                                    if (e.target.value === '' || re.test(e.target.value)) {
                                        handleCapacitate(e.target.value);
                                    }
                                }}
                                value={capacitate}
                                disabled={isEditing === false}
                            />
                        </td>
                    </tr>
                    </tbody>
                </table>
            </form>}
            {SRError !== '' && <p style={{fontWeight: 'bold', color: 'red'}}>{SRError}</p>}

            <div className="mt-2 mb-2">
                <br></br>
            </div>

            <ChoiceComponent
                title={'La locul de producere există echipamente de măsurare a energiei electrice montate în instalația de utilizare, altele decât cele aparținând operatorilor de distribuție?*'}
                subtitle={'(dacă da, te rog precizează caracteristicile acestora, respectiv serie contor, tip contor, date tehnice)'}
                options={[{title: 'Da', id: 1}, {title: 'Nu', id: 2}]}
                onChange={handleMasurare}
                value={masurare}
                disabled={isEditing === false}
                id="em"
            />
            {masurare === 1 && <DynamicTable format={tabelDateEM} data={dateEM} handler={handleDateEM} isEditing={isEditing}/>}
            {EMError !== '' && <p style={{fontWeight: 'bold', color: 'red'}}>{EMError}</p>}

            <div className="mt-2 mb-2">
                <br></br>
            </div>

            {validationError !== '' && <p style={{fontWeight: 'bold', color: 'red'}}>{validationError}</p>}
            {error !== '' && <p style={{fontWeight: 'bold', color: 'red'}}>{error}</p>}
            {numbersError !== '' && <p style={{fontWeight: 'bold', color: 'red'}}>{numbersError}</p>}
            <div className="d-flex flex-row justify-content-between">
                <Button className="d-flex" onClick={handlePrevStep}>Pasul anterior</Button>
                <Button className="d-flex" onClick={handleNextStep}>Pasul următor</Button>
            </div>
        </div>
    );
};

export default LPNou;