import { Fragment } from 'react';

const CPTableRow = (props: {selected: any, handleSelect: any, utility: any, item: any}) => {
    return (
        <tr style={{cursor: 'pointer'}} onClick={() => props.handleSelect(props.item['pod'], props.item['address'], props.item['clientName'])}>
            {(props.utility === 1) &&
                <Fragment>
                    <td>
                        <div className="d-flex justify-content-center h-100 checkbox-container">
                            <input 
                                type="checkbox" 
                                checked={props.selected === props.item['pod']} 
                                className="create-account-checkbox" 
                                style={{width: '15px'}}
                                onChange={() => props.handleSelect(props.item['pod'], props.item['address'])}
                            />
                        </div>
                    </td>
                    <td>
                        <div>
                            <span>{props.item['clientCode'] || '-'}</span>
                        </div>
                    </td>
                    <td>
                        <div>
                            <span>{props.item['clientName'] || '-'}</span>
                        </div>
                    </td>
                    <td>
                        <div>
                            <span>{props.item['supplierName'] || '-'}</span>
                        </div>
                    </td>
                    <td>
                        <div>
                            <span>{props.item['consumptionCode'] || '-'}</span>
                        </div>
                    </td>
                    <td>
                        <div>
                            <span>{props.item['pod'] || '-'}</span>
                        </div>
                    </td>
                    <td>
                        <div>
                            <span>{props.item['address'] || '-'}</span>
                        </div>
                    </td>
                    <td>
                        <div>
                            <span>{props.item['contractStatus'] || '-'}</span>
                        </div>
                    </td>
                    <td>
                        <div>
                            <span>{props.item['meterNumber'] || '-'}</span>
                        </div>
                    </td>
                    <td>
                        <div>
                            <span>{props.item['connectionStatus'] || '-'}</span>
                        </div>
                    </td>
                    <td>
                        <div>
                            <span>{props.item['voltageLevel'] || '-'}</span>
                        </div>
                    </td>
                    <td>
                        <div>
                            <span>{props.item['maxPower'] || '-'}</span>
                        </div>
                    </td>
                    <td>
                        <div>
                            <span>{props.item['datePre'] || '-'}</span>
                        </div>
                    </td>
                </Fragment>
            }

            {(props.utility === 2) &&
                <Fragment>
                    <td>
                        <div className="d-flex justify-content-center h-100 checkbox-container">
                            <input 
                                type="checkbox" 
                                checked={props.selected === props.item['pod']} 
                                className="create-account-checkbox" style={{width: '15px'}}
                                onChange={() => props.handleSelect(props.item['pod'], props.item['address'])}
                            />
                        </div>
                    </td>
                    <td>
                        <div>
                            <span>{props.item['clientCode'] || '-'}</span>
                        </div>
                    </td>
                    <td>
                        <div>
                            <span>{props.item['clientName'] || '-'}</span>
                        </div>
                    </td>
                    <td>
                        <div>
                            <span>{props.item['supplierName'] || '-'}</span>
                        </div>
                    </td>
                    <td>
                        <div>
                            <span>{props.item['consumptionCode'] || '-'}</span>
                        </div>
                    </td>
                    <td>
                        <div>
                            <span>{props.item['pod'] || '-'}</span>
                        </div>
                    </td>
                    <td>
                        <div>
                            <span>{props.item['address'] || '-'}</span>
                        </div>
                    </td>
                    <td>
                        <div>
                            <span>{props.item['contractStatus'] || '-'}</span>
                        </div>
                    </td>
                    <td>
                        <div>
                            <span>{props.item['meterNumber'] || '-'}</span>
                        </div>
                    </td>
                    <td>
                        <div>
                            <span>{props.item['connectionStatus'] || '-'}</span>
                        </div>
                    </td>
                    <td>
                        <div>
                            <span>{props.item['tariffCategory'] || '-'}</span>
                        </div>
                    </td>
                    <td>
                        <div>
                            <span>{props.item['srmp'] || '-'}</span>
                        </div>
                    </td>
                    <td>
                        <div>
                            <span>{props.item['minPressure'] || '-'}</span>
                        </div>
                    </td>
                    <td>
                        <div>
                            <span>{props.item['maxPressure'] || '-'}</span>
                        </div>
                    </td>
                    <td>
                        <div>
                            <span>{props.item['techicalMinPressure'] || '-'}</span>
                        </div>
                    </td>
                    <td>
                        <div>
                            <span>{props.item['contractCapacity'] || '-'}</span>
                        </div>
                    </td>
                    <td>
                        <div>
                            <span>{props.item['contractDuration'] || '-'}</span>
                        </div>
                    </td>
                </Fragment>
            }
        </tr>
    );
};

export default CPTableRow;
