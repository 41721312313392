import React, { useEffect, useState } from 'react';

import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Card,
  Col,
  Row
} from 'react-bootstrap';

import { CustomGet, QueryParams } from '@api/backOffice/fetchResults';
import { RadioGroup } from '@components/common/RadioGroup';
import { BackOffice, BackOfficeContext, BackOfficeContextType } from '@contexts/backoffice-context';
import { ToString } from '@lib/Utils';
import moment from 'moment';
import DateRange, { DataItem, DateRangeInputs } from './Fields/DateRange';
import DateTime from './Fields/DateTime';
import DropdownInt from './Fields/DropdownInt';
import DropdownString, { OptionsForField } from './Fields/DropdownString';
import Int from './Fields/Int';
import MultiSelectDropdownInt from './Fields/MultiSelectDropdownInt';
import MultiSelectDropdownString from './Fields/MultiSelectDropdownString';
import String from './Fields/String';
import { Filter } from './PageWrapper';
import YearMonthSelector from './Fields/YearMonthSelector';
import { DataTypes } from '@type/backOfficeModule';

export type Item = BackOffice['filters'][number] | BackOffice['advancedFilters'][number];

type MetaData = {
  pageIndex: number;
  pageSize: number;
  totalItemsCount: number;
}

export type ValueToSet = {
  name: string;
  value: string | number | string[];
  itemTypeId: number;
}

export class BackOfficeSearch {
  PageIndex: number = 1;
  PageSize: number | null = null;
  SortColumn?: string | null = null;
  SortAscending: boolean = false;
  GeneralSearchText: string | null = '';
  Filters: Filter[] = [];
}


export type ResponseType = {
  items: Array<any>,
  metadata?: MetaData
};

export type Option = {
  [key: string | number]: any
}

export const defaultEvent = { preventDefault: () => undefined, persist: () => undefined } as React.SyntheticEvent;

type FiltersFormProps = {
  filters: Filter[];
  onValueChange: ({ name, value, itemTypeId }: ValueToSet) => void
  removeFilter: (name: string) => void
  initialFiltersCollapsedState: FiltersCollapsedState;
}

export type FiltersCollapsedState = {
  [key: string]: boolean;
}

const FiltersForm = ({ filters, onValueChange, removeFilter, initialFiltersCollapsedState }: FiltersFormProps) => {
  const { config } = React.useContext(BackOfficeContext) as BackOfficeContextType;
  const [optionsForField, setOptionsForField] = useState<OptionsForField[]>([] as OptionsForField[]);
  const [filtersCollapsedState, setFiltersCollapsedState] = useState<FiltersCollapsedState>({} as FiltersCollapsedState);

  useEffect(() => {
    setFiltersCollapsedState({...initialFiltersCollapsedState});
  },[initialFiltersCollapsedState]);

  useEffect(() => {
    if (config && config.filters) {
      config.filters.forEach((item: Item) => {
        // set options from api
        if(item.itemsConfiguration && !item.itemsConfiguration.staticData) {
          const url = item.itemsConfiguration.itemsDefinition?.url;
          const keyFieldName = item.itemsConfiguration?.keyFieldName ? item.itemsConfiguration?.keyFieldName.charAt(0).toLocaleLowerCase() + item.itemsConfiguration?.keyFieldName.slice(1)  : 'id';
          //eslint-disable-next-line
          const valueFieldName = item.itemsConfiguration?.valueFieldName ? item.itemsConfiguration?.valueFieldName : 'value';

          let childName;
          let childUrl;
          let childUrlQuerryParamName = '';
          let extraPropertiesToSendInActionModel = item.itemsConfiguration.extraPropertiesToSendInActionModel || [];
              if(item.itemsConfiguration.cascadeChild) {
                  const child = item.itemsConfiguration.cascadeChild;
                  if(child.itemsConfiguration && !child.itemsConfiguration.staticData) {
                    childName = child.name;
                    childUrl = child.itemsConfiguration.itemsDefinition?.url;
                    childUrlQuerryParamName = ((child.itemsConfiguration.itemsDefinition?.queryStringSourceParameterName ?? '').charAt(0).toLowerCase())
                     + ((child.itemsConfiguration.itemsDefinition?.queryStringSourceParameterName ?? '').slice(1));
                  }
                  if(child.itemsConfiguration.extraPropertiesToSendInActionModel && child.itemsConfiguration.extraPropertiesToSendInActionModel[0]) {
                    //eslint-disable-next-line
                    extraPropertiesToSendInActionModel = [...extraPropertiesToSendInActionModel, ...child.itemsConfiguration.extraPropertiesToSendInActionModel ];
                  }
                }
          getOptionsFromApi(item.name, keyFieldName, url, childUrl, childName as string, '', childUrlQuerryParamName);
        }
        //
      });
    }
  }, [config]);

  // set child options from api
  useEffect(()=> {
    optionsForField.forEach((option: OptionsForField) => {
      const fieldValue = filters.find(filter => filter.Name === option.field)?.Value;
      if(option.child && option.child !== '' && fieldValue) {
        let paramValue = option.options.find((op: Option) => op[option.keyFieldName] == fieldValue);
        paramValue = paramValue ? paramValue[option.childUrlQuerryParameterName] : '';

        let params = [{
          name: option.childUrlQuerryParameterName as string,
          value: paramValue
        }] as QueryParams[];
        getOptionsFromApi(option.child, '', option.childUrl, '','', option.field, '', params);
      }
    });
  },[filters]);

  const removeChildFilterValueAtParentChange = (child: any) => {
    if (child) {
      removeFilter(child.name);
    }
    const childChild = child?.itemsConfiguration?.cascadeChild ?? null;
    if (childChild) {
      removeChildFilterValueAtParentChange(childChild);
    }
  };

  const renderField = (item: Item, parent?: any) => {
    let itemType = item.dataTypeName;
    let itemTypeId = item.dataTypeId as number;
    let toggled = filtersCollapsedState[item.name];
    let hasChild = !!item?.itemsConfiguration?.cascadeChild;
    //@ts-ignore
    if (item.required) {
      if (!item.displayName.endsWith('*')) {
        item.displayName = `${item.displayName} *`;
      }
    } else if (item.dataTypeId == DataTypes.DateRange) {
      item.items?.forEach((dateItem) => {
        //@ts-ignore
        if (dateItem.required) {
          if (!item.displayName.endsWith('*')) {
            item.displayName = `${item.displayName} *`;
          }
        }
      });
    }

    switch (itemType) {
      case 'StringList':
        const stringListOptions = item.items ? (item.items.map((item: any) => {
          return {
            value: item,
            name: item
          };
        })) : [];

        stringListOptions && stringListOptions.unshift({
          value: '',
          name: 'Fără filtru'
        });

        return <RadioGroup
          className='mw-100 mb-4'
          multiline={true}
          defaultMax={10}
          value={filters.find((x: Filter) => x.Name === item.name)?.Value ?? ''}
          itemClassName='bg-eon-red d-inline mr-2 ms-2'
          options={stringListOptions}
          onClick={(value?: string | string[] | number | boolean) => {
            onValueChange({ name: item.name, value: ToString(value), itemTypeId } as ValueToSet);
          }}
        />;
      case 'String':
        return <String
          // label={item.displayName}
          name={item.name}
          value={ToString(filters.find((x: Filter) => x.Name === item.name)?.Value ?? '')}
          itemTypeId={itemTypeId}
          onChange={onValueChange}
          focus={toggled}
          mbZero
        />;
      case 'DateTime':
        return <DateTime
          label={item.displayName}
          name={item.name}
          value={ToString(filters.find((x: Filter) => x.Name === item.name)?.Value ?? '')}
          itemTypeId={itemTypeId}
          onChange={onValueChange}
          hideLabel
          mbZero
          //@ts-ignore
          minValue={item.minValue}
          //@ts-ignore
          maxValue={item.maxValue}
        />;
      case 'Dropdown':
        let value: string = ToString(filters.find((x: Filter) => x.Name === item.name)?.Value ?? '');
        let finalValue: string | number = value && parseInt(value) ? parseInt(value) : value;

        const dropdownOptions = item.items ? (item.items.map((item: any) => {
          return {
            value: item.id,
            name: item.name
          };
        })) : [];

        dropdownOptions && dropdownOptions.unshift({
          value: '',
          name: 'Fără filtru'
        });

        return <RadioGroup
          className='mw-100 mb-4'
          multiline={true}
          value={finalValue}
          defaultMax={8}
          itemClassName='bg-eon-red d-inline mr-2 ms-2'
          options={dropdownOptions}
          onClick={(value?: string | string[] | number | boolean) => {
            onValueChange({ name: item.name, value: ToString(value), itemTypeId } as ValueToSet);
          }}
        />;
      case 'Bool':
        return <RadioGroup
          className='mw-100 mb-4'
          value={filters.find((x: Filter) => x.Name === item.name)?.Value ?? ''}
          itemClassName='bg-eon-red d-inline mr-2 ms-2 small-font'
          multiline={true}
          options={[
            {
              value: '',
              name: 'Fără filtru'
            },
            {
              value: 'true',
              name: 'Da'
            },
            {
              value: 'false',
              name: 'Nu'
            }
          ]}
          onClick={(value?: string | string[] | number | boolean) => {
            onValueChange({ name: item.name, value: ToString(value), itemTypeId } as ValueToSet);
          }}
        />;
      case 'DateRange':
        let values: DateRangeInputs = {};

        if (item.items) {
          item.items.forEach((dateItem: any, index: number) => {
            let existingFilterValue: string | number | string[] = filters.find((x: Filter) => x.Name === dateItem.name)?.Value ?? '';
            values[dateItem.name] = existingFilterValue ? moment(existingFilterValue).toDate() : null;
          });
        }

        return <DateRange
          onChange={onValueChange}
          values={values}
          items={item.items?.map((dateItem: any): DataItem => ({
            label: dateItem.displayName,
            itemTypeId: dateItem.dataTypeId,
            name: dateItem.name,
            minValue: dateItem.minValue,
            maxValue: dateItem.maxValue,
            //@ts-ignore
            required: dateItem.required
          }))}
          hideLabel
          mbZero
        />;
      case 'DropdownInt':
          return (
            <DropdownInt
            item={item}
            itemTypeId={itemTypeId}
            value={filters.find((x: Filter) => x.Name === item.name)?.Value ?? ''}
            onChange={(value) => {
              onValueChange(value);
              const child = item.itemsConfiguration?.cascadeChild ?? null;
              removeChildFilterValueAtParentChange(child);
            }}
            isUsedInFilters={true}
            renderChildFilter={renderField}
            parent={parent}
            filters={filters}
            optionsForField={optionsForField}
            hideLabel={true}
            mbZero
            toggled={toggled}
            containerClassName={hasChild? 'mb-2' : ''}
            />
          );
      case 'DropdownString':
          return (
            <DropdownString 
            item={item}
            itemTypeId={itemTypeId}
            value={filters.find((x: Filter) => x.Name === item.name)?.Value ?? ''}
            onChange={(value) => {
              onValueChange(value);
              const child = item.itemsConfiguration?.cascadeChild ?? null;
              removeChildFilterValueAtParentChange(child);
            }}
            isUsedInFilters={true}
            renderChildFilter={renderField}
            parent={parent}
            filters={filters}
            optionsForField={optionsForField}
            hideLabel={true}
            mbZero
            toggled={toggled}
            containerClassName={hasChild? 'mb-2' : ''}
            />
          );
      case 'MultiSelectDropdownInt':
          return (
            <MultiSelectDropdownInt
            item={item}
            itemTypeId={itemTypeId}
            value={filters.find((x: Filter) => x.Name === item.name)?.Value ?? ''}
            onChange={onValueChange}
            isUsedInFilters={true}
            name={item.name}
            hideLabel={true}
            mbZero
            toggled={toggled}
            />
          );
      case 'MultiSelectDropdownString':
            return (
              <MultiSelectDropdownString
              item={item}
              itemTypeId={itemTypeId}
              value={filters.find((x: Filter) => x.Name === item.name)?.Value ?? ''}
              onChange={onValueChange}
              isUsedInFilters={true}
              name={item.name}
              hideLabel={true}
              mbZero
              toggled={toggled}
              />
            );
      case 'DropdownIntSL':
              return (
                <DropdownInt
                item={item}
                itemTypeId={itemTypeId}
                value={filters.find((x: Filter) => x.Name === item.name)?.Value ?? ''}
                onChange={onValueChange}
                isUsedInFilters={true}
                renderChildFilter={renderField}
                parent={parent}
                filters={filters}
                optionsForField={optionsForField}
                hideLabel={true}
                mbZero
                toggled={toggled}
                containerClassName={hasChild? 'mb-2' : ''}
                />
              );
        case 'Int':
              return (
                <Int
                // label={item.displayName}
                name={item.name}
                value={filters.find((x: Filter) => x.Name === item.name)?.Value ?? ''}
                itemTypeId={itemTypeId}
                onChange={onValueChange}
                focus={toggled}
                mbZero
                />
              );
        case 'YearMonthSelector':
              return (
                <YearMonthSelector
                name={item.name}
                label={item.displayName}
                value={filters.find((x: Filter) => x.Name === item.name)?.Value ?? ''}
                //@ts-ignore
                minValue={item.minValue}
                //@ts-ignore
                maxValue={item.maxValue}
                itemTypeId={itemTypeId}
                onChange={onValueChange}
                focus={toggled}
                monthFieldName={item.itemsConfiguration?.cascadeChild?.name}
                monthFieldValue={filters.find((x: Filter) => x.Name === item.itemsConfiguration?.cascadeChild?.name)?.Value ?? ''}
                monthItemTypeId={item.itemsConfiguration?.cascadeChild?.dataTypeId ?? 2}
                />
              );
      default:
        break;
    }

    return null;
  };

  //get options and save them in optionsForField state
  const getOptionsFromApi = async (fieldName: string, keyFieldName: string, url: string | any, childUrl: string | any,childName: string, parentName: string,  childUrlQuerryParameterName: string, urlParams?: QueryParams[]) => {
    let options = await CustomGet(url, urlParams);
    setOptionsForField( preProps => { 
      let existentFieldIndex = preProps.findIndex((p: OptionsForField) => p.field === fieldName);

      if (existentFieldIndex >= 0) {
        return [
          ...preProps.slice(0, existentFieldIndex),
          Object.assign({}, preProps[existentFieldIndex], {
            field: fieldName,
            child: childName,
            keyFieldName: keyFieldName,
            childUrl: childUrl,
            childUrlQuerryParameterName: childUrlQuerryParameterName,
            parent: parentName,
            options: [...options]
          }),
          ...preProps.slice(existentFieldIndex + 1)
        ];
      } else {
        return [
          ...preProps,
          {
            field: fieldName,
            child: childName,
            keyFieldName: keyFieldName,
            childUrl: childUrl,
            childUrlQuerryParameterName: childUrlQuerryParameterName,
            parent: parentName,
            options: [...options]
          }
        ];
      }
    });
  };

  return (
    <div>
      {config && <>
        <Row>
          {config.filters && config.filters.map((item: Item, index: number) => {
            //display filters conditioned by other filter value
            //@ts-ignore
            if(item.visibilityConditionedBy) {
              const relatedFilterValidValue = !!filters.find(
              //@ts-ignore
                (filter) => filter.Name == item.visibilityConditionedBy.filterId && filter.Value == item.visibilityConditionedBy.filterValue
              );
              if(!relatedFilterValidValue) {
                if(filters.find(filter => filter.Name == item.name)) {
                  removeFilter(item.name);
                }
                return;
              }
            }

            return (
              <Col key={index} md={12} className='py-1 px-3 mb-1 filter-section' style={{fontSize: '14px'}}>
                <Card className={`scroll-on-hover ${item && item.dataTypeId !== 29 && item.dataTypeId !== 28 && item.dataTypeId !== 33 && item.dataTypeId !== 34 && item.dataTypeId !== 35 && 'scroll-styled'}`}>
                  <Card.Subtitle
                    onClick={() => setFiltersCollapsedState({ ...filtersCollapsedState, [item.name]: !filtersCollapsedState[item.name] })}
                    className='d-flex justify-content-between p-2 pointer'>
                    {item.displayName}
                    <FontAwesomeIcon
                      icon={filtersCollapsedState[item.name] ? faAngleUp : faAngleDown}
                      style={{
                        textAlign: 'center',
                        width: '12px',
                        lineHeight: '10px',
                        zIndex: 1
                      }}
                    />
                  </Card.Subtitle>
                  <Card.Body className={`${filtersCollapsedState[item.name] ? 'collapsed-body' : 'hidden-collapsed-body'}`} >
                    {renderField(item)}
                  </Card.Body>
                </Card>
              </Col>);
          })}
        </Row>
      </>
      }
    </div >
  );
};

export default FiltersForm;