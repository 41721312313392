import { Fragment, useState } from 'react';
// import { toggleLoader } from '@actions/LoaderActions';
import Modal from '@components/dev/Components/Modal';
import { Button, Col, Row } from 'react-bootstrap';
// import { useDispatch } from 'react-redux';
import { toggleLoader } from '@actions/LoaderActions';
import { postCancelRequestEE, postCancelRequestGN } from '@api/timeline';
import { Input } from '@components/common/Input';
import ModalConfirm from '@components/common/ModalConfirm/ModalConfirm';
import { UTILITY_EE, UTILITY_GN } from '@constants/Utility';
import * as notification from '@lib/Notification';
import { useDispatch } from 'react-redux';

type SendReclamationPropsType = {
  utility: number | any;
  requestId: any;
  className?: any;
  reloadTimeline: () => void;
  isBo: boolean | any;
};

const CancelClientRequest = (props: SendReclamationPropsType) => {
  //modal reclamation
  const { utility, requestId } = props;

  const [showModal, setShowModal] = useState<any>(false);
  const [showModalSucces, setShowModalSucces] = useState<any>(false);
  const [reason, setReason] = useState<any>('');

  // const [error, setError] = useState<any>(false);
  const dispatch = useDispatch();

  const submit = () => {
    // setError('');
    dispatch(toggleLoader(true));

    let payload = { requestId: Number(requestId), message: reason };

    if (utility == UTILITY_EE) {
      postCancelRequestEE(payload)
        .then((res) => {
          setShowModalSucces(true);
          setShowModal(false);
          dispatch(toggleLoader(false));
        })
        .catch((err) => {
          if (err.status == 200) {
            setShowModalSucces(true);
            setShowModal(false);
          } else {
            notification.error('A apărut o eroare. Vă rugăm să reveniți mai tărziu.');
            // setError('A apărut o eroare. Vă rugăm să reveniți mai tărziu.');
          }
          dispatch(toggleLoader(false));
        });
    } else if (utility == UTILITY_GN) {
      postCancelRequestGN(payload)
        .then((res) => {
          setShowModalSucces(true);
          setShowModal(false);
          dispatch(toggleLoader(false));
        })
        .catch((err) => {
          if (err.status == 200) {
            setShowModalSucces(true);
            setShowModal(false);
          } else {
            notification.error('A apărut o eroare. Vă rugăm să reveniți mai tărziu.');
            // setError('A apărut o eroare. Vă rugăm să reveniți mai tărziu.');
          }
          dispatch(toggleLoader(false));
        });
    }
  };

  const handleCloseModalSucces = () => {
    setShowModalSucces(false);
    setReason('');
    props.reloadTimeline();
  };

  return (
    <Fragment>
      <Button
        className={props.className}
        onClick={() => {
          if (props.isBo) {
            notification.warning('Acțiunea este accesibilă doar clienților!');
            return;
          }
          setShowModal(true);
        }}
      >
        Renunță la cererea de racordare
      </Button>

      <Modal
        title="Renunțare la cererea clientului"
        show={showModal}
        setShow={(state: boolean) => {
          setShowModal(state);
          if (!state) setReason('');
        }}
        size={'lg'}
        onClose={() => {
          setShowModal(false);
          setReason('');
        }}
        hideQuit
      >
        <Row className="mt-2">
          <Col xs={12}>
            {utility == UTILITY_EE ? (
              <p className="fw-bold red mb-4">
                Important! <br />
                Dacă renunți la cererea de racordare, se va încheia procesul în pasul actual și nu va putea fi reluat în baza acestei
                cereri. Pentru a relua procesul de racordare este necesar să depui o nouă cerere.
              </p>
            ) : (
              <p className="fw-bold red mb-4">
                Important! <br />
                Dacă renunți la cererea de racordare, se va încheia procesul în pasul actual și nu va putea fi reluat în baza acestei
                cereri. Dacă ai achitat deja facturi de racordare, sumele NU vor fi rambursate.
              </p>
            )}
          </Col>

          <Col xs={12}>
            <p className="fw-bold mb-3">Completează motivul de renunțare</p>
          </Col>

          <Col xs={12} className="mb-4">
            <Input value={reason} onChange={(e) => setReason(e.target.value)} label="Motiv*" mbZero />
          </Col>

          <Col xs={12} className="mt-1 d-flex align-items-center justify-content-center">
            <p className="fw-bold">Ești sigur că vrei să renunți la procesul de racordare?</p>
          </Col>

          <div className="d-flex align-items-center justify-content-center">
            <Button className="me-2 px-5" onClick={() => submit()} disabled={!reason}>
              Da
            </Button>

            <Button
              className="px-5"
              onClick={() => {
                setShowModal(false);
                setReason('');
              }}
              disabled={!reason}
            >
              Nu
            </Button>
          </div>

          {/* {error && <div style={{ color: 'red', fontWeight: 'bold' }}>{error}</div>} */}
        </Row>
      </Modal>

      <ModalConfirm modalTitle="Succes!" showModal={showModalSucces} onClose={handleCloseModalSucces}>
        <p>Solicitarea de renunțare a fost transmisă cu succes.</p>
      </ModalConfirm>
    </Fragment>
  );
};

export default CancelClientRequest;
