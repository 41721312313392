import { proxy, Proxy } from '@api/azureProxy';
import { store } from '@lib/store';
import axios from 'axios';

export async function pvaeSaveAgreement(payloadFormData: any) {
  const state = store.getState();

  return axios.post(`${state.options.apiHost}/meter-reading/PVAE/save_agreement`, payloadFormData, {
    headers: {
      Authorization: 'Bearer ' + state.auth.token,
      'Ocp-Apim-Subscription-Key': state.options.subscriptionKey as string
    }
  });
}

export async function pvaeSendSmsValidationCode(payload: any) {
  const state = store.getState();

  const proxyData: Proxy = {
    method: 'POST',
    endPoint: '/meter-reading/PVAE/validate_agreement',
    data: payload,
    withAuthBearer: true
  };

  return proxy(proxyData, {
    headers: {
      Authorization: 'Bearer ' + state.auth.token,
      'Access-Control-Allow-Headers': 'Authorization',
      'Access-Control-Allow-Credentials': true,
      'access-control-allow-methods': 'POST'
    }
  });
}

export function PVAEList(payload: any) {
  const state = store.getState();

  return axios.get(`${state.options.apiHost}/meter-reading/PVAESupplier/list?${payload.join('&')}`, {
    headers: {
      Authorization: 'Bearer ' + state.auth.token,
      'Ocp-Apim-Subscription-Key': state.options.subscriptionKey as string
    }
  });
}

export function downloadPVAE(payload: any) {
  const state = store.getState();

  const proxyData: Proxy = {
      method: 'GET',
      endPoint: `/meter-reading/PVAESupplier/download?supplierDocumentId=${payload}`,
      data: payload,
      withAuthBearer: true
  };

  return proxy(proxyData, {
      headers: {
          'Authorization': 'Bearer ' + state.auth.token,
          'Access-Control-Allow-Headers': 'Authorization',
          'Access-Control-Allow-Credentials': true,
          'access-control-allow-methods': 'POST',
      },
      responseType: 'arraybuffer'
  });
}

export function PVAEListClient(payload: any) {
  const state = store.getState();

  return axios.get(`${state.options.apiHost}/meter-reading/PVAEClient/list?${payload.join('&')}`, {
    headers: {
      Authorization: 'Bearer ' + state.auth.token,
      'Ocp-Apim-Subscription-Key': state.options.subscriptionKey as string
    }
  });
}

export function downloadPVAEClient(payload: any) {
  const state = store.getState();

  const proxyData: Proxy = {
      method: 'GET',
      endPoint: `/meter-reading/PVAEClient/download?supplierDocumentId=${payload}`,
      data: payload,
      withAuthBearer: true
  };

  return proxy(proxyData, {
      headers: {
          'Authorization': 'Bearer ' + state.auth.token,
          'Access-Control-Allow-Headers': 'Authorization',
          'Access-Control-Allow-Credentials': true,
          'access-control-allow-methods': 'POST',
      },
      responseType: 'arraybuffer'
  });
}
