import Tippy from '@tippyjs/react';
import { Fragment } from 'react';
import { COUNTY_PATHS } from './components/CountyPaths';
import { getCountyLabel } from './components/CountyCodePaths';

type CountyWithDetails = {
  copuntyDetails: number;
  countyCode: string;
};

type Props = { countiesWithDetails: CountyWithDetails[]; onCountySelect: (code: string) => void };

const RomaniaMapByCounties = (props: Props) => {
  return (
    <div className="w-100 d-flex justify-content-center">
      <div className="overflow-auto p-4">
        <svg xmlns="http://www.w3.org/2000/svg" width="612.36395" height="432.29416">
          {COUNTY_PATHS.map((c, index) => {
            let activeCounty = props.countiesWithDetails?.find((j) => j.countyCode == c.countyCode);
            if (activeCounty && activeCounty.copuntyDetails) {
              return (
                <Fragment key={index}>
                  <Tippy
                    content={
                      <div
                        style={{
                          background: 'rgba(0,0,0,.85)',
                          width: 'auto',
                          height: '55px',
                          color: 'white',
                          padding: '7.5px',
                          borderRadius: '5px',
                          fontSize: '0.75rem',
                          display: 'flex',
                          flexDirection: 'column'
                        }}
                      >
                        <p style={{ marginBottom: '5px' }}>
                          <span>{c.countyName}</span>
                          <span
                            style={{
                              background: 'red',
                              padding: '1px 3px',
                              marginLeft: '3px',
                              borderRadius: '3px'
                            }}
                          >
                            {props.countiesWithDetails.find((cd) => cd.countyCode == c.countyCode)?.copuntyDetails}
                          </span>
                        </p>
                        <p>Click pentru detalii</p>
                      </div>
                    }
                    duration={0}
                  >
                    <path
                      key={index}
                      d={c.path}
                      id={c.countyCode}
                      fill="#ea1c0a"
                      stroke="#808080"
                      strokeWidth="1"
                      className="red-path"
                      onClick={() => props.onCountySelect(c.countyCode)}
                    />
                  </Tippy>
                  {getCountyLabel(c.countyCode, 'white')}
                </Fragment>
              );
            } else if (activeCounty) {
              return (
                <Fragment key={index}>
                  <path d={c.path} id={c.countyCode} fill="#bfbfbf" stroke="#808080" strokeWidth="1" className="red-path" />
                  {getCountyLabel(c.countyCode, 'white')}
                </Fragment>
              );
            } else {
              return (
                <Fragment key={index}>
                  <path d={c.path} id={c.countyCode} fill="white" stroke="#808080" strokeWidth="1" className="red-path" />
                  {getCountyLabel(c.countyCode, '#BFBFBF')}
                </Fragment>
              );
            }
          })}
        </svg>
      </div>
    </div>
  );
};

export default RomaniaMapByCounties;
