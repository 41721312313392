import { Tabs } from '@components/dev/Components/Tabs';
import { useState } from 'react';

const MultiTabsContent = (props: any) => {

    const { kentico } = props;
    //eslint-disable-next-line
    const [currentTab, setCurrentTab] = useState<number>(0);

    //eslint-disable-next-line
    const openTabSection = (evt: any, tabNmae: any, index?: number) => {
        let i, tabcontent, tablinks;

        tabcontent = document.getElementsByClassName('content_tabs_item');
        for (i = 0; i < tabcontent.length; i++) {
            let element: Element = tabcontent[i];

            element.setAttribute('style', 'display:none');
        }

        tablinks = document.getElementsByClassName('tab-links');
        for (i = 0; i < tablinks.length; i++) {
            tablinks[i].className = tablinks[i].className.replace(' current', '');
        }

        document.getElementById(tabNmae)?.setAttribute('style', 'display:block');
        evt.currentTarget.className += ' current';
        index && setCurrentTab(index);
    };

    //eslint-disable-next-line
    const renderDescription = (items: any, index: number) => {
        if (items[index].hasOwnProperty('description')) {
            return <p className="paragraph" style={{padding: '15px'}} dangerouslySetInnerHTML={{ __html: items[index].description }} />;
        }
        if (items[0].hasOwnProperty('description')) {
            return <p className="paragraph" style={{padding: '15px'}} dangerouslySetInnerHTML={{ __html: items[0].description }} />;
        }

        return null;
    };

    return (
        <section className="tab-section mt-5">
            <div className="container">
                <div className="content mt-3">
                    {/* Tabs Nav */}
                    {/*<div className="multi-tabs d-sm-flex justify-content-between">*/}
                    {/*    <div className={`w-100 tab-links bl-s bb-ns bw2 b--red`}*/}
                    {/*        onClick={(e) => openTabSection(e, `content-tab`)}*/}
                    {/*    >*/}
                    {/*        <span className="brand-subtitle d-block p-3 border-0">{kentico.items[0].title}</span>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    <div className="tab_content">
                        <Tabs kentico={{ ...kentico }} />
                    </div>
                </div>
            </div>
        </section>
    );

};

export default MultiTabsContent;