import React from 'react';
import { DelgazWrapper } from '@components/global/DelgazWrapper';
import MyAccountMenuComp from '@components/delgaz/Menu/MyAccountMenuComp';
import {MyAccountMenuKentico} from '@type/kentico-types';

export type MyAccountMenuProps = { kentico: MyAccountMenuKentico };

export const MyAccountMenu = (props: MyAccountMenuProps): JSX.Element => {
    return (
        <DelgazWrapper>
            <MyAccountMenuComp {...props}/>
        </DelgazWrapper>
    );
};