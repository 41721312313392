import { getTypes } from '@api/prosumatori/prosumatori';
import ChoiceComponent from '@components/prosumatori/components/ChoiceComponent';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tippy from '@tippyjs/react';
import { useEffect, useState } from 'react';

const TabelTipRacord = (props: {isEditing: any, tableData: any, type: number, handleData: any, tipPutere: number, id?: string}) => {
    const [tipRacordOptions, setTipRacordOptions] = useState([]);

    const {isEditing, tableData, type, handleData, tipPutere} = props;

    useEffect(() => {
        getOptionTypes(['RequestConnectionType']).then((res) => setTipRacordOptions(res));
    }, []);

    const getOptionTypes = async (keys: string[]) => {
        return await getTypes(keys).then((res: any) => {
            return res[keys[0]];
        });
    };

    return (
        <div className="d-flex flex-column mb-4 overflow-auto">
            <form className="m-0">
                <table className="dynamic-table" id={props.id}>
                    {(type === 2 || type === 4) && <thead>
                        <tr style={{height: '25px'}}>
                            <th style={{width: '350px'}} colSpan={2}></th>
                                <th className="text-center">Situație existentă</th>
                                <th className="text-center">Situație solicitată</th>
                            <th></th>
                        </tr>
                    </thead>}
                    <tbody>
                        <tr style={{height: '25px'}}>
                            <td className="p-2" style={{border: '1px solid grey'}} colSpan={2}>
                                    <span className="d-flex flex-row">
                                        {tipPutere === 1 && 'Puterea maximă simultan absorbită din rețea (kW)*'}
                                        {tipPutere === 2 && 'Puterea maximă simultan evacuată în rețea (kW)*'}
                                        <Tippy
                                            content={
                                                tipPutere === 1 ? <div className="p-3" style={{background: 'rgba(0,0,0,0.9)', color: 'white', borderRadius: '6px'}}>
                                                    Puterea maximă simultan absorbită din rețea [kW] = Puterea maximă simultan absorbită a receptoarelor [kW] + Puterea maximă simultan absorbită a stațiilor de incărcare auto [kW].
                                                </div> : <div className="p-3" style={{background: 'rgba(0,0,0,0.9)', color: 'white', borderRadius: '6px'}}>
                                                    Suma puterilor maxime simultan evacuate în rețea a echipamentelor de producere.
                                                </div>
                                            }
                                            duration={0}
                                            placement="top"
                                        >
                                        <div className="icon-div" style={{marginLeft: '8px'}}>
                                            <FontAwesomeIcon icon={faCircleInfo} className="icon" color="red"/>
                                        </div>
                                    </Tippy>
                                    </span>
                            </td>
                            <td style={{border: '1px solid grey'}}>
                                <input
                                    className="table-input"
                                    value={tableData.seKW}
                                    onChange={(e) => {
                                        e.preventDefault();
                                        const re = /^[,.0-9\b]+$/;

                                        if (e.target.value === '' || re.test(e.target.value)) {
                                            handleData(e.target.value, 'seKW');
                                        }
                                    }}
                                    disabled={isEditing === false}
                                />
                            </td>
                            {(type === 2 || type === 4) && <td style={{border: '1px solid grey'}}>
                                <input
                                    className="table-input"
                                    value={tableData.ssKW}
                                    onChange={(e) => {
                                        e.preventDefault();
                                        const re = /^[,.0-9\b]+$/;

                                        if (e.target.value === '' || re.test(e.target.value)) {
                                            handleData(e.target.value, 'ssKW');
                                        }
                                    }}
                                    disabled={isEditing === false}
                                />
                            </td>}
                        </tr>
                        <tr style={{height: '25px'}}>
                            <td className="p-2" style={{border: '1px solid grey'}} colSpan={2}>
                                <span className="d-flex flex-row">
                                        {tipPutere === 1 && 'Puterea maximă simultan absorbită din rețea (kVA)*'}
                                        {tipPutere === 2 && 'Puterea maximă simultan evacuată în rețea (kVA)*'}
                                        {/*<Tippy*/}
                                        {/*    content={*/}
                                        {/*        tipPutere === 1 ? <div className="p-3" style={{background: 'rgba(0,0,0,0.9)', color: 'white', borderRadius: '6px'}}>*/}
                                        {/*            Puterea maximă simultan absorbită din rețea [kVA] = Puterea maximă simultan absorbită a receptoarelor [kVA] + Puterea maximă simultan absorbită a stațiilor de incărcare auto [kVA].*/}
                                        {/*        </div> : <div className="p-3" style={{background: 'rgba(0,0,0,0.9)', color: 'white', borderRadius: '6px'}}>*/}
                                        {/*            Puterea maximă simultan evacuată în rețea [kVA] = Puterea maximă simultan evacuată a receptoarelor [kVA] + Puterea maximă simultan evacuată a stațiilor de incărcare auto [kVA].*/}
                                        {/*        </div>*/}
                                        {/*    }*/}
                                        {/*    duration={0}*/}
                                        {/*    placement="top"*/}
                                        {/*>*/}
                                        {/*    <div className="icon-div" style={{marginLeft: '8px'}}>*/}
                                        {/*        <FontAwesomeIcon icon={faCircleInfo} className="icon" color="red"/>*/}
                                        {/*    </div>*/}
                                        {/*</Tippy>*/}
                                    </span>
                            </td>
                            <td style={{border: '1px solid grey'}}>
                                <input
                                    className="table-input"
                                    value={tableData.seKVA}
                                    onChange={(e) => {
                                        e.preventDefault();
                                        const re = /^[,.0-9\b]+$/;

                                        if (e.target.value === '' || re.test(e.target.value)) {
                                            handleData(e.target.value, 'seKVA');
                                        }
                                    }}
                                    disabled={isEditing === false}
                                />
                            </td>
                            {(type === 2 || type === 4) && <td style={{border: '1px solid grey'}}>
                                <input
                                    className="table-input"
                                    value={tableData.ssKVA}
                                    onChange={(e) => {
                                        e.preventDefault();
                                        const re = /^[,.0-9\b]+$/;

                                        if (e.target.value === '' || re.test(e.target.value)) {
                                            handleData(e.target.value, 'ssKVA');
                                        }
                                    }}
                                    disabled={isEditing === false}
                                />
                            </td>}
                        </tr>
                        {(type !== 3 && type !== 4) && <tr style={{height: '25px'}}>
                            <td className="p-2" style={{border: '1px solid grey'}} colSpan={2}>Tip racord*</td>
                            {<td style={{border: '1px solid grey'}}>
                                <ChoiceComponent
                                    options={tipRacordOptions}
                                    onChange={(option: number) => handleData(option, 'seTipRacord')}
                                    value={tableData.seTipRacord}
                                    disabled={isEditing === false}
                                />
                            </td>}
                            {type === 2 && <td style={{border: '1px solid grey'}}>
                                <ChoiceComponent
                                    options={tipRacordOptions}
                                    onChange={(option: number) => handleData(option, 'ssTipRacord')}
                                    value={tableData.ssTipRacord}
                                    disabled={isEditing === false}
                                />
                            </td>}
                        </tr>}
                    </tbody>
                </table>
            </form>
        </div>
    );
};

export default TabelTipRacord;