import React from 'react';
import { Portal } from 'react-overlays';

export const CalendarWrapper = ({ children }: { children: any }) => {
  const el = document.getElementById('calendar-portal');

  if (!children) return null;

  return <Portal container={el}>{children}</Portal>;
};
