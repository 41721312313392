import { toggleLoader } from '@actions/LoaderActions';
import { editAccount, getAccountDetails } from '@api/user/user';
import { Input } from '@components/common/Input';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { Fragment, useEffect, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { EditPJSchema } from '../../../../helpers/schemas';
import ChangeEmailGenerateEmail from '../ChangeEmail/ChangeEmailGenerateEmail';
import ChangePasswordFromEditPage from '../ChangePasswordFromEdit/ChangePasswordFromEditPage';
import 'react-phone-number-input/style.css';
import PhoneInput, { isValidPhoneNumber, parsePhoneNumber } from 'react-phone-number-input';

const ClientPJ = (props: any) => {
  const [details, setDetails] = useState({});
  const [errorMessage, setErrorMessage] = useState('');
  const [wasFetched, setWasFetched] = useState(false);
  const [isReqPasswordChange, setIsReqPasswordChange] = useState(false);
  const [showChangeEmailForm, setShowChangeEmailForm] = useState(false);
  const [country, setCountry] = useState<any>('RO');
  const [phoneErr, setPhoneErr] = useState('');

  const dispatch = useDispatch();

  const getDetails = async () => {
    dispatch(toggleLoader(true));

    await getAccountDetails()
      .then((response: any) => {
        if (response) {
          let d = response;
          let phone = '';

          if (response.contactPhone[0] === '0' && response.contactPhone[1] === '7') {
            phone = '+4' + response.contactPhone;
          } else {
            phone = response.contactPhone;
          }

          d = { ...d, contactPhone: phone, emailConfirmation: d.email };
          setDetails(d);
          let c = parsePhoneNumber(phone)?.country;
          setCountry(c || 'RO');
        } else {
          setWasFetched(true);
        }
      })
      .catch((e: any) => {
        setWasFetched(true);
      });

    dispatch(toggleLoader(false));
  };

  useEffect(() => {
    getDetails();
  }, []);

  const submit = async (values: any) => {
    setErrorMessage('');

    if (isValidPhoneNumber(values.contactPhone)) {
      dispatch(toggleLoader(true));

      let intl_phone = values.contactPhone;

      if (intl_phone.startsWith('+40')) {
          intl_phone = intl_phone.slice(2);
      }

      values = { ...values, mobilePhone: intl_phone };
      values = { ...values, contactPhone: intl_phone };
      values = { ...values, contactMobilePhone: intl_phone };
      if (values.operatorName) {
        values = { ...values, operatorName: null };
      }
  
      editAccount(values)
        .then((response: any) => {
          if (response && response.success === true) {
            setErrorMessage('Datele contului au fost modificate cu succes.');
          } else {
            setErrorMessage('A intervenit o eroare tehnică. Vă rugăm reveniți mai târziu.');
          }
          dispatch(toggleLoader(false));
        })
        .catch((e: any) => {
          console.log(e);
          setErrorMessage('A intervenit o eroare tehnică. Vă rugăm reveniți mai târziu.');
          dispatch(toggleLoader(false));
        });
    } else {
      setPhoneErr('Numărul de telefon are formatul greșit.');
    }
  };

  const renderCompanyDetails = () => {
    if (Object.keys(details).length !== 0) {
      return (
        <Fragment>
          <Col xs={12} md={12}>
            <div>
              <span>Nume Companie:&nbsp;</span>
              <b>
                {
                  // @ts-ignore
                  details.companyName
                }
              </b>
            </div>
          </Col>
          <Col xs={12} md={12}>
            <div>
              <span>Registrul Comerțului:&nbsp;</span>
              <b>
                {
                  // @ts-ignore
                  details.orc
                }
              </b>
            </div>
          </Col>
          <Col xs={12} md={12}>
            <div>
              <span>CUI/CIF:&nbsp;</span>
              <b>
                {
                  // @ts-ignore
                  details.cui
                }
              </b>
            </div>
          </Col>
        </Fragment>
      );
    } else {
      return null;
    }
  };

  const renderEditForm = () => {
    return (
      <Fragment>
        <Formik enableReinitialize initialValues={details} onSubmit={(values) => submit(values)} validationSchema={EditPJSchema}>
          {(props: any) => {
            return (
              <Fragment>
                <Form>
                  <div className="form-container">
                    <div className="mt-3 d-flex flex-column">
                      <h4 className="register-section-title">Informații persoană juridica</h4>
                    </div>
                    <div className="d-flex flex-column">
                      <Row className="mb-2 mt-2">{renderCompanyDetails()}</Row>
                    </div>
                    <div className="mt-3 d-flex flex-column">
                      <h4 className="register-section-title">Date personale cont</h4>
                    </div>
                    <div className="d-flex flex-column">
                      <Row className="mt-2">
                        <Col className="mb-3" xs={12} md={6}>
                          <Field
                            id="contactLastName"
                            name="contactLastName"
                            placeholder="Nume"
                            render={() => (
                              <Input
                                label="Nume"
                                mbZero
                                onBlur={() => {
                                  props.setFieldTouched('contactLastName');
                                }}
                                onChange={(e: any) => {
                                  e.target.value.length !== 0 && props.setFieldValue('contactLastName', e.target.value);
                                  e.target.value.length === 0 && props.setFieldValue('contactLastName', '');
                                }}
                                value={props.values['contactLastName']}
                              />
                            )}
                          />
                          <ErrorMessage
                            name="contactLastName"
                            render={(msg) => <div style={{ color: '#ea1c0a', fontWeight: 'bold' }}>{msg}</div>}
                          />
                        </Col>
                        <Col className="mb-3" xs={12} md={6}>
                          <Field
                            id="contactFirstName"
                            name="contactFirstName"
                            placeholder="Prenume"
                            render={() => (
                              <Input
                                label="Prenume"
                                mbZero
                                onBlur={() => {
                                  props.setFieldTouched('contactFirstName');
                                }}
                                onChange={(e: any) => {
                                  e.target.value.length !== 0 && props.setFieldValue('contactFirstName', e.target.value);
                                  e.target.value.length === 0 && props.setFieldValue('contactFirstName', '');
                                }}
                                value={props.values['contactFirstName']}
                              />
                            )}
                          />
                          <ErrorMessage
                            name="contactFirstName"
                            render={(msg) => <div style={{ color: '#ea1c0a', fontWeight: 'bold' }}>{msg}</div>}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col className="mb-3" xs={12} md={6}>
                          <PhoneInput 
                            international
                            value={props.values['contactPhone']}
                            defaultCountry={country}
                            onChange={(e) => {
                              props.setFieldValue('contactPhone', e);

                              if (e && !isValidPhoneNumber(e)) {
                                  setPhoneErr('Numărul de telefon are formatul greșit.');
                              } else if (e && isValidPhoneNumber(e)) {
                                  setPhoneErr('');
                              }
                            }}
                            countryCallingCodeEditable={false}
                            onCountryChange={(e) => {
                              setCountry(e);
                              
                              if (e !== 'RO') {
                                  props.setFieldValue('emailNotifications', true);
                                  props.setFieldValue('smsNotifications', false);
                              }
                            }}
                          />
                          {phoneErr !== '' && <p className="red fw-bold">{phoneErr}</p>}
                        </Col>
                      </Row>
                      <Row>
                        <Col className="mb-3" sm={12} md={6}>
                          <Field
                            name="email"
                            placeholder="Email"
                            disabled
                            render={() => <Input label="Email" mbZero disabled value={props.values['email']} />}
                          />
                          <ErrorMessage name="email" render={(msg) => <div style={{ color: '#ea1c0a', fontWeight: 'bold' }}>{msg}</div>} />
                        </Col>
                        <Col className="mb-3" sm={12} md={6}>
                          <Field
                            id="emailConfirmation"
                            name="emailConfirmation"
                            placeholder="Confirmă Email"
                            disabled
                            render={() => <Input label="Confirmă Email" mbZero disabled value={props.values['emailConfirmation']} />}
                          />
                          <ErrorMessage
                            name="emailConfirmation"
                            render={(msg) => <div style={{ color: '#ea1c0a', fontWeight: 'bold' }}>{msg}</div>}
                          />
                        </Col>
                      </Row>
                    </div>
                    <div className="d-flex flex-column mt-3">
                      <h4 className="register-section-title">Canale de comunicare pentru modificările de pe cont</h4>
                      <Row className="mb-2">
                        <Row sm={12} className="d-flex">
                          <Col xs={4} className="d-flex align-items-center checkbox-container">
                            <Field type="checkbox" name="emailNotifications" className="create-account-checkbox" disabled={country !== 'RO'} />
                            <span className="checkbox-span">Email</span>
                          </Col>
                          <Col xs={4} className="d-flex align-items-center checkbox-container">
                            <Field type="checkbox" name="smsNotifications" className="create-account-checkbox" disabled={country !== 'RO'} />
                            <span className="checkbox-span">SMS</span>
                          </Col>
                        </Row>
                      </Row>
                    </div>

                    {errorMessage !== '' && <div style={{ color: 'red', fontWeight: 'bold' }}>{errorMessage}</div>}
                    <div className="mb-4 mt-1 buttons-container d-flex justify-content-center justify-content-sm-start">
                      <Button type="submit">Salvează</Button>
                      <Button style={{ marginLeft: '8px' }} onClick={() => setIsReqPasswordChange(true)}>
                        Schimbă parola
                      </Button>
                      <Button style={{ marginLeft: '8px' }} onClick={() => setShowChangeEmailForm(true)}>
                        Modifică e-mail
                      </Button>
                    </div>
                  </div>
                </Form>
              </Fragment>
            );
          }}
        </Formik>
      </Fragment>
    );
  };

  return (
    <div className="edit-pj">
      {showChangeEmailForm ? (
        <ChangeEmailGenerateEmail setShowChangeEmailForm={setShowChangeEmailForm} />
      ) : (
        <>
          {Object.keys(details).length !== 0 && !isReqPasswordChange && renderEditForm()}
          {Object.keys(details).length === 0 && wasFetched && (
            <div className="d-flex align-text-center justify-content-center">
              A intervenit o eroare tehnică. Vă rugăm reveniți mai târziu.
            </div>
          )}
          {isReqPasswordChange && <ChangePasswordFromEditPage setIsReqPasswordChange={setIsReqPasswordChange} />}
        </>
      )}
    </div>
  );
};

export default ClientPJ;