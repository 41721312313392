import { GetCounties, GetLocalities } from '@api/geoApi';
import { Dropdown, DropdownOptionType } from '@components/common/Dropdown';
import { Input } from '@components/common/Input';
import { ErrorMessage, Field } from 'formik';
import { Fragment, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';

type CountyTemplate = {
    countyId: number,
    countyName: string,
    countyCode: string
};

type LocalityTemplate = {
    localityId: number,
    localityName: string,
    localityCode: string
};

const PFForm = (props: any) => {
    const [counties, setCounties] = useState<CountyTemplate[]>([]);
    const [county, setCounty] = useState('');
    const [localities, setLocalities] = useState<LocalityTemplate[]>([]);
    //eslint-disable-next-line
    const [locality, setLocality] = useState<number | null>(null);

    let formikProps = props.props;
    let data = props.formData;
    let handleData = props.handleData;

    useEffect(() => {
        GetCounties().then(res => setCounties(res));

        if (data.judet !== '') {
            GetLocalities(data.judet).then((res) => {
                setLocalities(res);
                res.map((c: LocalityTemplate, i: number) => {
                    if (data.localitate === c.localityId) {
                        setLocality(c.localityId);
                    }
                });
            });
        }

    }, [data]);

    useEffect(() => {
        county !== '' && GetLocalities(county).then((res) => setLocalities(res));
    }, [county]);

    return (
        <Fragment>
            {props && <div className="client-section mb-0 justify-content-center">
                <Row className="justify-content-center">
                    <Row className="mb-1 p-0">
                        <Col xs={12} sm={6} className='mb-3'>
                            <Field label='cnp' id="cnp" name="cnp" placeholder="CNP (Cod Numeric Personal)">
                                {({
                                      //@ts-ignore
                                      meta: {touched, error}
                                  }) =>
                                    <Input
                                        mbZero
                                        value={formikProps.values.cnp}
                                        label='CNP (Cod Numeric Personal)'
                                        onBlur={() => formikProps.setFieldTouched('cnp')}
                                        onChange={(e: any) => {
                                            handleData(e.target.value, 'cnp');
                                            formikProps.setFieldValue('cnp', e.target.value);
                                        }}
                                    />
                                }
                            </Field>
                            <ErrorMessage name="cnp" render={msg => <div style={{color: '#ea1c0a', fontWeight: 'bold'}}>{msg}</div>}/>
                        </Col>
                        <Col xs={12} sm={6} className='mb-3'>
                            <Field label='nume' id="nume" name="nume" placeholder={`Nume${props.type == 1 ? '' : '*'}`}>
                                {({
                                      //@ts-ignore
                                      meta: {touched, error}
                                  }) =>
                                    <Input
                                        mbZero
                                        value={formikProps.values.nume}
                                        label={`Nume${props.type == 1 ? '' : '*'}`}
                                        onBlur={() => formikProps.setFieldTouched('nume')}
                                        onChange={(e: any) => {
                                            handleData(e.target.value, 'nume');
                                            formikProps.setFieldValue('nume', e.target.value);
                                        }}
                                        disabled={props.disabled}
                                    />
                                }
                            </Field>
                            <ErrorMessage name="nume" render={msg => <div
                                style={{color: '#ea1c0a', fontWeight: 'bold'}}>{msg}</div>}/>
                        </Col>
                    </Row>
                    <Row className="mb-1 p-0">
                        <Col xs={12} sm={6} className='mb-3'>
                            <Field label='prenume' id="prenume" name="prenume" placeholder={`Prenume${props.type == 1 ? '' : '*'}`}>
                                {({
                                      //@ts-ignore
                                      meta: {touched, error}
                                  }) =>
                                    <Input
                                        mbZero
                                        value={formikProps.values.prenume}
                                        label={`Prenume${props.type == 1 ? '' : '*'}`}
                                        onBlur={() => formikProps.setFieldTouched('prenume')}
                                        onChange={(e: any) => {
                                            handleData(e.target.value, 'prenume');
                                            formikProps.setFieldValue('prenume', e.target.value);
                                        }}
                                        disabled={props.disabled}
                                    />
                                }
                            </Field>
                            <ErrorMessage name="prenume" render={msg => <div
                                style={{color: '#ea1c0a', fontWeight: 'bold'}}>{msg}</div>}/>
                        </Col>
                        <Col xs={12} sm={6} className='mb-3'>
                            <Field label='telefon' id="telefon" name="telefon" placeholder="Telefon">
                                {({
                                      //@ts-ignore
                                      meta: {touched, error}
                                  }) =>
                                    <Input
                                        mbZero
                                        value={formikProps.values.telefon}
                                        label='Telefon'
                                        onBlur={() => formikProps.setFieldTouched('telefon')}
                                        onChange={(e: any) => {
                                            handleData(e.target.value, 'telefon');
                                            formikProps.setFieldValue('telefon', e.target.value);
                                        }}
                                    />
                                }
                            </Field>
                            <ErrorMessage name="telefon" render={msg => <div
                                style={{color: '#ea1c0a', fontWeight: 'bold'}}>{msg}</div>}/>
                        </Col>
                    </Row>
                    <Row className="mb-1 p-0">
                        <Col xs={12} className='mb-3'>
                            <Field label='email' id="email" name="email" placeholder="Email">
                                {({
                                      //@ts-ignore
                                      meta: {touched, error}
                                  }) =>
                                    <Input
                                        mbZero
                                        value={formikProps.values.email}
                                        label='Email'
                                        onBlur={() => formikProps.setFieldTouched('email')}
                                        onChange={(e: any) => {
                                            handleData(e.target.value, 'email');
                                            formikProps.setFieldValue('email', e.target.value);
                                        }}
                                    />
                                }
                            </Field>
                            <ErrorMessage name="email" render={msg => <div
                                style={{color: '#ea1c0a', fontWeight: 'bold'}}>{msg}</div>}/>
                        </Col>
                    </Row>
                    <Row className="mb-1 p-0">
                        <Col xs={12} sm={6} className='mb-3'>
                            <Dropdown
                                options={counties.map((option, index) => (
                                    {
                                        id: option.countyCode,
                                        name: option.countyName
                                    }
                                )) as DropdownOptionType[]}
                                label={`Județ${props.type == 1 ? '' : '*'}`}
                                defaultPlaceholder={'Alege județul'}
                                displaySearch={true}
                                onChange={(value) => {
                                    value && handleData(value, 'judet');
                                    value && formikProps.setFieldValue('judet', value);
                                    value.length === 0 && props.setFieldValue('judet', '');
                                    setCounty(value);
                                }}
                                value={data.judet}
                                mbZero
                                disabled={true}
                            />
                            <ErrorMessage name="judet" render={msg => <div style={{color: '#ea1c0a', fontWeight: 'bold'}}>{msg}</div>}/>
                        </Col>
                        <Col xs={12} sm={6} className="mb-3">
                            <Dropdown
                                options={localities.map((option, index) => (
                                    {
                                        id: option.localityCode,
                                        name: option.localityName
                                    }
                                )) as  DropdownOptionType[]}
                                label={`Localitate${props.type == 1 ? '' : '*'}`}
                                defaultPlaceholder={'Alege localitatea'}
                                displaySearch={true}
                                onChange={(value) => {
                                    value && handleData(value, 'localitate');
                                    value && formikProps.setFieldValue('localitate', value);
                                    value.length === 0 && props.setFieldValue('localitate', '');
                                }}
                                value={data.localitate}
                                mbZero
                                disabled={true}
                            />
                            <ErrorMessage name="localitate" render={msg => <div style={{color: '#ea1c0a', fontWeight: 'bold'}}>{msg}</div>}/>
                        </Col>
                    </Row>
                    <Row className="mb-1 p-0">
                        <Col xs={12} sm={4} className='mb-3'>
                            <Field label='strada' id="strada" name="strada" placeholder={`Stradă${props.type == 1 ? '' : '*'}`}>
                                {({
                                      //@ts-ignore
                                      meta: {touched, error}
                                  }) =>
                                    <Input
                                        mbZero
                                        value={formikProps.values.strada}
                                        label={`Stradă${props.type == 1 ? '' : '*'}`}
                                        onBlur={() => formikProps.setFieldTouched('strada')}
                                        onChange={(e: any) => {
                                            handleData(e.target.value, 'strada');
                                            formikProps.setFieldValue('strada', e.target.value);
                                        }}
                                        disabled={props.disabled}
                                    />
                                }
                            </Field>
                            <ErrorMessage name="strada" render={msg => <div style={{color: '#ea1c0a', fontWeight: 'bold'}}>{msg}</div>}/>
                        </Col>
                        <Col xs={12} sm={4} className='mb-3'>
                            <Field label='numar' id="numar" name="numar" placeholder={`Număr${props.type == 1 ? '' : '*'}`}>
                                {({
                                      //@ts-ignore
                                      meta: {touched, error}
                                  }) =>
                                    <Input
                                        mbZero
                                        value={formikProps.values.numar}
                                        label={`Număr${props.type == 1 ? '' : '*'}`}
                                        onBlur={() => formikProps.setFieldTouched('numar')}
                                        onChange={(e: any) => {
                                            handleData(e.target.value, 'numar');
                                            formikProps.setFieldValue('numar', e.target.value);
                                        }}
                                        disabled={props.disabled}
                                    />
                                }
                            </Field>
                            <ErrorMessage name="numar" render={msg => <div style={{color: '#ea1c0a', fontWeight: 'bold'}}>{msg}</div>}/>
                        </Col>
                        <Col xs={12} sm={4}>
                            <Field label='codPostal' id="codPostal" name="codPostal" placeholder="Cod postal">
                                {({
                                      //@ts-ignore
                                      meta: {touched, error}
                                  }) =>
                                    <Input
                                        value={formikProps.values.codPostal}
                                        label='Cod poștal'
                                        onBlur={() => formikProps.setFieldTouched('codPostal')}
                                        onChange={(e: any) => {
                                            handleData(e.target.value, 'codPostal');
                                            formikProps.setFieldValue('codPostal', e.target.value);
                                        }}
                                        disabled={props.disabled}
                                    />
                                }
                            </Field>
                            <ErrorMessage name="codPostal" render={msg => <div style={{color: '#ea1c0a', fontWeight: 'bold'}}>{msg}</div>}/>
                        </Col>
                    </Row>
                </Row>
            </div>}
        </Fragment>
    );
};

export default PFForm;