import * as Yup from 'yup';

export const emailRules = Yup.string()
    .email( 'Adresa de email conține caractere nepermise, ori are formatul greșit.' )
    .matches(
        //eslint-disable-next-line
        /^[a-zA-Z0-9+._\-]+[a-zA-Z0-9]@[a-zA-Z0-9.\-_]+\.[a-zA-Z]{2,15}/,
        'Adresa de email conține caractere nepermise, ori are formatul greșit.')
    .required( 'E-mail-ul este obligatoriu.' )
    .trim();

export const passwordRules = Yup.string()
    .required( 'Parola este obligatorie.' );

export const OTPRules = Yup.string()
    .typeError('OTP-ul este invalid.')
    .required( 'OTP-ul este obligatoriu.' );

export const oldPasswordRules = Yup.string()
    .required( 'Parola veche este obligatorie.' );

export const passwordConfirmationRules = Yup.string()
    .required( 'Noua parolă este obligatorie.' )
    .matches(
        //eslint-disable-next-line
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        'Parola trebuie să conțină minim 8 caractere, minim o literă, minim o cifră și minim un caracter special ( de ex ! . $ * & ).'
    );

export const registerPasswordRules = Yup.string()
    .required( 'Parola este obligatorie.' )
    .matches(
        // /^(?=.*[a-z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        /^(?=.*[A-Za-z])(?=.*[^a-zA-Z0-9])(?=.*\d).{8,}$/,
        'Parola trebuie să conțină minim 8 caractere, minim o literă, minim o cifră și minim un caracter special ( de ex ! . $ * & ).'
    );

export const firstNameRules = Yup.string()
    .min( 3, 'Prenumele trebuie să conțină minim 3 caractere.')
    .max( 42, 'Prenumele trebuie să conțină maxim 42 caractere.')
    .matches(
        /^[a-zA-ZăĂțȚșȘàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð '-]+$/u,
        {
            message: 'Prenumele nu poate conține caractere speciale',
            excludeEmptyString: true
        }
    )
    .required( 'Prenumele este obligatoriu.' );

export const lastNameRules = Yup.string()
    .min( 3, 'Numele trebuie să conțină minim 3 caractere.')
    .max( 42, 'Numele trebuie să conțină maxim 42 caractere.')
    .matches(
        /^[a-zA-ZăĂțȚșȘàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð '-]+$/u,
        {
            message: 'Numele nu poate conține caractere speciale',
            excludeEmptyString: true
        }
    )
    .required( 'Numele este obligatoriu.' );

export const phoneRunes = Yup.string()
    .matches(
        // /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
        /0\d{9}/, //validare ca pe portal vechi
        'Numărul de telefon are formatul gresit.'
    )
    .max( 10, 'Numărul de telefon are formatul gresit.')
    .required('Numărul de telefon este obligatoriu.');

export const companyRules = Yup.string()
    .required( 'Numele companiei este obligatoriu.' );

export const orcRules = Yup.string()
    .required( 'Registrul comerțului este obligatoriu.' );

export const cuiRules = Yup.string()
    .required( 'CIF/CUI este obligatoriu.' );

export const faxRules = Yup.string()
    .matches(
        /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
        'Campul Fax are formatul gresit.'
    );

export const etsoCodeRules = Yup.string()
    .required( 'Codul ETSO este obligatoriu.' );

export const licenceRules = Yup.string()
    .matches(
        /^[0-9]{1,5}$/, 'Numărul de licență trebuie să conțină maxim 5 cifre'
    )
    .required( 'Câmpul Număr licență este obligatoriu.' );

export const supplierCodeRules = Yup.string()
    .matches(
        /10[0-9]{8,8}/, 'Codul client trebuie sa aiba exact 10 caractere, de forma 10xxxxxxxx.'
    )
    .test('len', 'Codul client trebuie sa aiba exact 10 caractere, de forma 10xxxxxxxx.', val => val?.length === 10)
    .required( 'Câmpul Cod client este obligatoriu.' );

export const operatorNameRules = Yup.string()
    .required( 'Denumirea este obligatorie.' );

export const ediEdibAuthorization = Yup.string()
    .matches(
        /^[0-9]{5,5}$/, 'Câmpul EDI/EDIB trebuie sa aiba exact 5 caractere, de forma 30214.'
    )
    .required( 'Nr. Autorizație EDI/EDIB este obligatoriu.' );

export const localityIdRules = Yup.string()
    .required( 'Alege o localitate.' );

export const countyIdRules = Yup.string()
    .required( 'Alege un județ.' );

export const newPasswordRules = Yup.string()
    .required( 'Noua parolă este obligatorie.' )
    .matches(
        //eslint-disable-next-line
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        'Parola trebuie să conțină minim 8 caractere, minim un caracter din alfabet, minim un caracter special și minim o cifră.'
    )
    .oneOf([Yup.ref('oldPassword')], 'Parolele nu coincid.');

export const emailConfirmationRules = Yup.string()
    .required( 'E-mail-ul este obligatoriu.' )
    .email('Adresa de email conține caractere nepermise, ori are formatul greșit.')
    .oneOf([Yup.ref('email')], 'Cele două adrese de email trebuie să fie identice.')
    .trim();

export const registerPasswordConfirmationRules = Yup.string()
    .required('Parola este obligatorie.')
    .oneOf([Yup.ref('password')], 'Cele două parole trebuie să fie identice.');

export const streetRules = Yup.string()
    .required( 'Alege o stradă.' );

export const measurementPointCodeRules = Yup.string()
    .required( 'Câmpul Cod punct de măsurare este obligatoriu.')
    .matches(/^EMO\d{7}$/, 'Câmpul Cod punct de măsurare trebuie sa fie de forma EMOxxxxxxx.');

export const cnpRules = Yup.string()
    .matches(
        /^[1-9]\d{2}(0[1-9]|1[0-2])(0[1-9]|[12]\d|3[01])(0[1-9]|[1-9]\d|5[0-2]|99)(00[1-9]|0[1-9]\d|[1-9]\d\d)\d$/, 'Codul Numeric Personal are formatul gresit.'
        )
    .required( 'Codul Numeric Personal este obligatoriu.' );

export const reprezentant = Yup.string()
    .required( 'Câmpul Reprezentant este obligatoriu.' );

export const inCalitateDe = Yup.string()
    .required( 'Câmpul In calitate de este obligatoriu.' );

export const bankAccountRules = Yup.string()
    .max( 24, 'Contul bancar trebuie să conțină 24 de caractere.')
    .min( 24, 'Contul bancar trebuie să conțină 24 de caractere.');

export const numberPVAERules = Yup.string()
    .required( 'Câmpul Număr PVAE este obligatoriu.' );

export const dateRules = Yup.string()
    .required( 'Câmpul Data este obligatoriu.' );

export const IDRules = Yup.string()
    .required( 'Acest câmp este obligatoriu.' );

export const IDNumberRules = Yup.string()
    .required( 'Acest câmp este obligatoriu.' )
    .max(6, 'Acest câmp trebuie să aibă maxim 6 caractere.');

export const IDElRules = Yup.string()
    .required( 'Acest câmp este obligatoriu.' );

export const IDDateRules = Yup.string()
    .required( 'Acest câmp este obligatoriu.' );

export const IDSeriesRules = Yup.string()
    .required( 'Acest câmp este obligatoriu.' )
    .max(2, 'Acest câmp trebuie să aibă 2 caractere.');

export const forcedPasswordConfirmationRules = Yup.string()
    .required( 'Noua parolă este obligatorie.' )
    .matches(
        /^(?=.*[A-Za-z])(?=.*[^a-zA-Z0-9])(?=.*\d).{8,}$/,
        'Parola trebuie să conțină minim 8 caractere, minim o literă, minim o cifră și minim un caracter special ( de ex ! . $ * & ).'
    );

    export const rssStreetRules = Yup.string()
    .required('Strada este obligatorie.');

export const rssStreetNoRules = Yup.string()
    .required('Numărul este obligatoriu.');

export const rssEmailRules = Yup.string()
    .email( 'Adresa de email conține caractere nepermise, ori are formatul greșit.' )
    .matches(
        //eslint-disable-next-line
        /^[a-zA-Z0-9+._\-]+[a-zA-Z0-9]@[a-zA-Z0-9.\-_]+\.[a-zA-Z]{2,15}/,
        'Adresa de email conține caractere nepermise, ori are formatul greșit.')
    .trim();

export const postalCodeSupplierRules = Yup.string()
    .min(6, 'Codul poștal trebuie să aibă exact 6 cifre.')
    .max(6, 'Codul poștal trebuie să aibă exact 6 cifre.');

export const postalCodeRules = Yup.string()
    .required('Codul poștal este obligatoriu.')
    .min(6, 'Codul poștal trebuie să aibă exact 6 cifre.')
    .max(6, 'Codul poștal trebuie să aibă exact 6 cifre.');


export const optionalCnpRules = Yup.string()
    .matches(
        /^[1-9]\d{2}(0[1-9]|1[0-2])(0[1-9]|[12]\d|3[01])(0[1-9]|[1-9]\d|5[0-2]|99)(00[1-9]|0[1-9]\d|[1-9]\d\d)\d$/, 'Codul Numeric Personal are formatul gresit.'
    );

export const optionalPhoneRunes = Yup.string()
    .matches(
        // /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
        /0\d{9}/, //validare ca pe portal vechi
        'Numărul de telefon are formatul gresit.'
    )
    .max( 10, 'Numărul de telefon are formatul gresit.');

export const nrEra = Yup.string()
    .required('Câmpul număr petiție este obligatoriu.')
    .matches(
         /RSS_\d+/,
        'Câmpul număr petiție trebuie sa fie de forma RSS_xxxx...'
    );
export const yearPetition = Yup.string()
    .required('Câmpul an petiție este obligatoriu.');

export const measurementPointCodeRulesDEG = Yup.string()
    .required( 'Câmpul Cod punct de măsurare este obligatoriu.')
    .matches(/^DEG\d{7}$/, 'Câmpul Cod punct de măsurare trebuie sa fie de forma DEGxxxxxxx.');

export const numberPVCRules = Yup.string()
.required( 'Câmpul Număr PVC este obligatoriu.' );

export const clcRules = Yup.string()
    .required( 'Câmpul Cod loc consum este obligatoriu.')
    .matches(/^500\d{7}$/, 'Câmpul Cod loc consum trebuie sa fie de forma 500xxxxxxx.');

export const optionalEmailRules = Yup.string()
    .email( 'Adresa de email conține caractere nepermise, ori are formatul greșit.' )
    .matches(
        //eslint-disable-next-line
        /^[a-zA-Z0-9+._\-]+[a-zA-Z0-9]@[a-zA-Z0-9.\-_]+\.[a-zA-Z]{2,15}/,
        'Adresa de email conține caractere nepermise, ori are formatul greșit.')
    .trim();

export const podRules = Yup.string()
    .required('Acest câmp este obligatoriu.')
    .min( 10, 'Acest câmp trebuie să conțină exact 10 caractere.')
    .max( 42, 'Acest câmp trebuie să conțină exact 10 caractere.');

export const tipLucrareFisaRvRules = Yup.string()
    .required( 'Câmpul Tip lucrare este obligatoriu.');

export const nrContractFisaRvRules = Yup.string()
    .required( 'Câmpul Nr. contract prestări servicii este obligatoriu.');

export const dataContractFisaRvRules = Yup.string()
    .required( 'Câmpul Dată contract prestări servicii este obligatoriu.');

export const nrFisaFisaRvRules = Yup.string()
    .required( 'Câmpul Nr. înregistrare fișa OE este obligatoriu.');

export const dataFisaFisaRvRules = Yup.string()
    .required( 'Câmpul Dată înregistrare fișa OE este obligatoriu.');

export const dataEfectuariiFisaRvRules = Yup.string()
    .required( 'Câmpul Data efectuării verificării/reviziei este obligatoriu.');

export const requiredFieldRules = Yup.string()
    .required( 'Câmp obligatoriu.');

    export const optionalLastNameRules = Yup.string()
    .min( 3, 'Numele trebuie să conțină minim 3 caractere.')
    .max( 42, 'Numele trebuie să conțină maxim 42 caractere.')
    .matches(
        /^[a-zA-ZăĂțȚșȘàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð '-]+$/u,
        {
            message: 'Numele nu poate conține caractere speciale',
            excludeEmptyString: true
        }
    );

export const optionalFirstNameRules = Yup.string()
    .min( 3, 'Prenumele trebuie să conțină minim 3 caractere.')
    .max( 42, 'Prenumele trebuie să conțină maxim 42 caractere.')
    .matches(
        /^[a-zA-ZăĂțȚșȘàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð '-]+$/u,
        {
            message: 'Numele nu poate conține caractere speciale',
            excludeEmptyString: true
        }
    );

export const IDNumberDigitsRules = Yup.string()
    .required('Acest câmp este obligatoriu.')
    .matches(
        /^[0-9]/,
        'Acest câmp trebuie să conțină doar cifre.'
    );
