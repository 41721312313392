import React from 'react';

type ProgressBarProps = {
  progress: string | any;
};

const ProgressBar = (props: ProgressBarProps) => {
  const { progress } = props;

  return (
    <div className="general-progress-bar">
      <div className="general-progress" style={{ width: `${progress}%` }}></div>
    </div>
  );
};

export default ProgressBar;
