import { cleanupOldDeviceId } from '@actions/user/device';
import { logout } from '@actions/user/user';
import ContentSearch from '@components/common/ActionSearch/ContentSearch';
import UserNotifications from '@components/userNotifications/UserNotifications';
import { ERROR_MESSAGE_NO_ACCES_AUTH_REQUESTED } from '@constants/Errors';
import { IDENTITY_ROLE_INTERNAL_EVERYONE, IDENTITY_ROLE_OPERATOR_ECONOMIC } from '@constants/Roles/RolesIdentity';
import { AuthenticationContext, AuthenticationContextType } from '@contexts/authentication-context';
import { faBars, faCaretLeft, faCaretRight, faRightFromBracket, faUser, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as notification from '@lib/Notification';
import { IState } from '@type/store';
import { UserJwtType } from '@type/userJwt';
import { jwtDecode } from 'jwt-decode';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { Accordion, Button, Col, Container, Dropdown, Image, Nav, NavDropdown, Navbar, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import '../../../App.css';
import { getQueryParam, removeQueryParam } from '../../../helpers/urlUtils';
import { updateNotificationSummary, updateNotificationCount } from '@actions/NotificationActions';

// const NR_OF_SLIDER_ITEMS = 10;

const MenuUser = (props: any) => {
  const kentico = props.kentico;
  const [isMobile, setIsMobile] = useState(false);
  const [isTablet, setIsTablet] = useState(false);
  const [isOpened, setIsOpened] = useState<boolean>(false);
  const [isNavOpened, setIsNavOpened] = useState<boolean>(false);
  const loggedInUser: string | undefined = useSelector<IState>((state) => state.auth.email) as string;
  const token: string | undefined = useSelector<IState>((state) => state.auth.token) as string;
  const OLD_SITE_URL: string | undefined = useSelector<IState>((state) => state.options.webApiHost) as string;
  const deviceId: string | undefined = useSelector<IState>((state) => state?.device?.deviceId) as string;
  const isLoggedin = kentico.user !== '' && kentico.user !== null;
  const loginFormRef = useRef<HTMLFormElement>(null);

  const dispatch = useDispatch();
  const { isBackOfficeSection } = React.useContext(AuthenticationContext) as AuthenticationContextType;

  //navbar bo slider
  const [nrOfSliderItems, setNrOfSliderItems] = useState(10);
  const [displayFrom, setDisplayFrom] = useState(0);
  const [displayTo, setDisplayTo] = useState(nrOfSliderItems - 1);
  const [selectedItemIndex, setSelectedItemIndex] = useState(-1);

  const [windowInnerWidth, setWindowInnerWidth] = useState(1300);

  const [role, setRole] = useState('');

  useEffect(() => {
    if (windowInnerWidth) {
      if (windowInnerWidth < 1100) {
        setNrOfSliderItems(windowInnerWidth / 290);
      } else if (windowInnerWidth < 1200) {
        setNrOfSliderItems(windowInnerWidth / 260);
      } else if (windowInnerWidth < 1500) {
        setNrOfSliderItems(windowInnerWidth / 250);
      } else if (windowInnerWidth < 1700) {
        setNrOfSliderItems(windowInnerWidth / 220);
      } else if (windowInnerWidth < 1800) {
        setNrOfSliderItems(windowInnerWidth / 200);
      } else if (windowInnerWidth < 2000) {
        setNrOfSliderItems(windowInnerWidth / 195);
      } else {
        setNrOfSliderItems(windowInnerWidth / 190);
      }
    }
  }, [windowInnerWidth]);

  useEffect(() => {
    setDisplayFrom(0);
    setDisplayTo(nrOfSliderItems - 1);
  }, [nrOfSliderItems]);

  useEffect(() => {
    if (token) {
      let jwt: UserJwtType = jwtDecode(token);
      jwt && setRole(jwt.groups.filter((role) => role !== IDENTITY_ROLE_INTERNAL_EVERYONE)[0]);
    }
  }, [token]);

  //bo adjust slider
  useEffect(() => {
    if (isBackOfficeSection && kentico.items.length > nrOfSliderItems) {
      if (selectedItemIndex > displayTo) {
        let diff = selectedItemIndex - displayTo;
        setDisplayFrom(displayFrom + diff);
        setDisplayTo(displayTo + diff);
      }
    }
  }, [selectedItemIndex]);

  useEffect(() => {
    kentico.items.forEach((item: any, itemIndex: number) => {
      let selected = item.items.find((i: any) => i.link == window.location.pathname);
      if (selected) {
        setSelectedItemIndex(itemIndex);
      }
    });

    //FLASH NOTIFICATION AT PAGE INITIAL LOAD
    const flashNotificationSucces = getQueryParam('FlashNotificationSucces');
    const flashNotificationWarnning = getQueryParam('FlashNotificationWarnning');
    const flashNotificationError = getQueryParam('FlashNotificationError');
    if (flashNotificationSucces) {
      notification.success(flashNotificationSucces);
      removeQueryParam('FlashNotificationSucces');
    }
    if (flashNotificationWarnning) {
      notification.warning(flashNotificationWarnning);
      removeQueryParam('FlashNotificationWarnning');
    }
    if (flashNotificationError) {
      notification.error(flashNotificationError);
      removeQueryParam('FlashNotificationError');
    }

    //RETURN URL SET
    const returnUrlParam = getQueryParam('ReturnUrl');
    if (returnUrlParam) {
      if (!token) {
        //user is not logged in
        const url = new URL(`${OLD_SITE_URL ?? ''}/login`);
        url.searchParams.set('FlashNotificationError', ERROR_MESSAGE_NO_ACCES_AUTH_REQUESTED);
        if (typeof localStorage !== 'undefined') {
          localStorage.setItem('ReturnUrl', returnUrlParam);
        }
        window.location.href = url.href;
      }
    }
  }, []);

  const handleResize = () => {
    if (window.innerWidth <= 500) {
      setIsMobile(true);
      setIsTablet(false);
    } else if (window.innerWidth <= 992 && window.innerWidth > 500) {
      setIsTablet(true);
      setIsMobile(false);
    } else if (window.innerWidth > 992) {
      setIsTablet(false);
      setIsMobile(false);
    }
    setWindowInnerWidth(window.innerWidth);
  };

  const delay = (ms: any) => new Promise((res) => setTimeout(res, ms));

  const wait = async () => {
    await delay(3000);
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const backToPortal: string | null = queryParams.get('backtoportal');
    const isPayment: string | null = queryParams.get('isPayment');
    const ReturnUrlLS = localStorage.getItem('ReturnUrl');
    const ReturnUrlActiveLS = localStorage.getItem('ReturnUrlActive');
    let decodedUrl = decodeURIComponent(window.location.href);

    if (
      typeof localStorage !== 'undefined' &&
      ReturnUrlLS &&
      ReturnUrlActiveLS &&
      window.location.href &&
      window.location.href.includes(ReturnUrlLS)
    ) {
      localStorage.removeItem('ReturnUrl');
      localStorage.removeItem('ReturnUrlActive');
    }

    if (isPayment || decodedUrl.includes('isPayment=1')) {
      //if is romcard callback set token and don't redirect
      document.cookie = 'token=' + token;
    } else if (!isLoggedin && loggedInUser && !backToPortal && !decodedUrl.includes('backtoportal=1')) {
      wait().then(() => {
        if (!isLoggedin && loggedInUser && !backToPortal && !decodedUrl.includes('backtoportal=1')) {
          dispatch(logout());
          dispatch(updateNotificationSummary([]));
          dispatch(updateNotificationCount(0));
          document.cookie = 'token=';
          window.location.href = OLD_SITE_URL;
        }
      });
    } else if (
      (loggedInUser && backToPortal && window.location.href !== `${OLD_SITE_URL}/solicitari`) ||
      (loggedInUser && decodedUrl.includes('backtoportal=1') && window.location.href !== `${OLD_SITE_URL}/solicitari`)
    ) {
      document.cookie = 'token=' + token;

      let redirectPage = `${OLD_SITE_URL}/solicitari`;
      if (typeof localStorage !== 'undefined' && ReturnUrlLS && ReturnUrlActiveLS) {
        redirectPage = ReturnUrlLS;
      }
      window.location.href = redirectPage;
    }
  }, [isLoggedin, loggedInUser]);

  useEffect(() => {
    handleResize();

    if (deviceId.length < 37) {
      dispatch(cleanupOldDeviceId());
    }
  }, []);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
  });

  useEffect(() => {
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });

  const showMenu = (modifier: any) => {
    if (isTablet || isMobile) {
      if (modifier) return true;
    } else return true;
  };

  const renderLogOut = () => (
    <Button
      style={{ backgroundColor: '#E4E4E4', borderRadius: '5px', width: '42px' }}
      href={`${OLD_SITE_URL}/Home/logout`}
      onClick={() => {
        dispatch(logout());
        dispatch(updateNotificationSummary([]));
        dispatch(updateNotificationCount(0));
        document.cookie = 'token=';
        window.location.href = OLD_SITE_URL;
      }}
    >
      <FontAwesomeIcon color="#ea1b0a" icon={faRightFromBracket} size={'lg'} />
    </Button>
  );

  const renderLogOutBackOffice = () => (
    <div className="icons-align">
      <a href={'/ExternalAuthentication/logout'}>
        <FontAwesomeIcon icon={faRightFromBracket} size={'lg'} className="logout-icon" />
      </a>
    </div>
  );

  const renderAccount = (isLoggedin: boolean, isMobile: boolean) => {
    if (isMobile) {
      if (!isLoggedin) {
        return (
          <a href={`${OLD_SITE_URL}/login`} style={{ textDecoration: 'none' }} className="ml-2">
            <div className="red">
              <FontAwesomeIcon icon={faUser} className="login-icon" size={'lg'} />
            </div>
          </a>
        );
      } else {
        return (
          <a
            href={`${OLD_SITE_URL}/${role == IDENTITY_ROLE_OPERATOR_ECONOMIC ? 'solicitari-operatori-economici' : 'solicitari'}`}
            style={{ textDecoration: 'none' }}
            className="ml-2"
          >
            <div className="red">
              <FontAwesomeIcon icon={faUser} className="login-icon" size={'lg'} />
            </div>
          </a>
        );
      }
    } else {
      if (!isLoggedin) {
        return (
          <Fragment>
            <Button className="border-rounded" href={`${OLD_SITE_URL}/login`}>
              <span>
                <FontAwesomeIcon width={14} icon={faUser} size={'lg'} className="login-icon ml-2" /> Contul tău
              </span>
            </Button>
          </Fragment>
        );
      } else {
        if (isTablet && windowInnerWidth < 770) {
          return (
            <a
              href={`${OLD_SITE_URL}/${role == IDENTITY_ROLE_OPERATOR_ECONOMIC ? 'solicitari-operatori-economici' : 'solicitari'}`}
              style={{ textDecoration: 'none' }}
              className="ml-2"
            >
              <div className="red">
                <FontAwesomeIcon icon={faUser} className="login-icon" size={'lg'} />
              </div>
            </a>
          );
        } else if (!isTablet && windowInnerWidth < 1315) {
          return (
            <a
              href={`${OLD_SITE_URL}/${role == IDENTITY_ROLE_OPERATOR_ECONOMIC ? 'solicitari-operatori-economici' : 'solicitari'}`}
              style={{ textDecoration: 'none' }}
              className="ml-2"
            >
              <div className="red">
                <FontAwesomeIcon icon={faUser} className="login-icon" size={'lg'} />
              </div>
            </a>
          );
        } else {
          return (
            <div className="d-flex flex-column justify-content-center align-items-center">
              <a
                href={`${OLD_SITE_URL}/${role == IDENTITY_ROLE_OPERATOR_ECONOMIC ? 'solicitari-operatori-economici' : 'solicitari'}`}
                style={{ textDecoration: 'none' }}
              >
                <span style={{ fontSize: '1rem', color: '#323134' }}>{kentico.user}</span>
              </a>
            </div>
          );
        }
      }
    }
  };

  const renderNonMobileHeader = () => {
    if (!isLoggedin) {
      return (
        <div className="login-button d-flex flex-column justify-content-center align-items-center">
          <ContentSearch placeholder="Caută" className="me-2" isLoggedIn={isLoggedin} />
          <div style={{ marginTop: '3px' }}>{renderAccount(isLoggedin, false)}</div>
        </div>
      );
    } else {
      return (
        <div className="d-flex justify-content-center align-items-center">
          <ContentSearch placeholder="Caută" className="me-2" isLoggedIn={isLoggedin} />

          <div style={{ marginTop: '3px' }}>{renderAccount(isLoggedin, false)}</div>
          {/* <div style={{ marginTop: '3px' }}>
            <UserNotifications isLoggedin={isLoggedin} />
          </div> */}
          <div className="ms-2" style={{ marginTop: '3px' }}>
            {renderLogOut()}
          </div>
        </div>
      );
    }
  };

  const renderTabletUserHeader = () => {
    if (!isLoggedin) {
      return (
        <div className="login-button d-flex flex-column justify-content-center">
          <ContentSearch placeholder="Caută" className="me-2" isMobile={true} isLoggedIn={isLoggedin} />
          <div style={{ marginTop: '3px' }} className="me-2">
            {renderAccount(isLoggedin, false)}
          </div>
        </div>
      );
    } else {
      return (
        <div className="d-flex justify-content-center align-items-center">
          <ContentSearch placeholder="Caută" className="me-2" isMobile={true} isLoggedIn={isLoggedin} />
          <div style={{ marginTop: '3px' }}>{renderAccount(isLoggedin, false)}</div>
          {/* <div style={{ marginTop: '3px' }}>
            <UserNotifications isLoggedin={isLoggedin} />
          </div> */}
          <div className="ms-2 me-2" style={{ marginTop: '3px' }}>
            {renderLogOut()}
          </div>
        </div>
      );
    }
  };

  const renderMobileUserHeader = () => {
    if (!isLoggedin) {
      return (
        <>
          <ContentSearch placeholder="Caută" className="me-2" isMobile={true} isLoggedIn={isLoggedin} />
          <div className="me-2" style={{ marginTop: '3px' }}>
            {renderAccount(isLoggedin, true)}
          </div>
        </>
      );
    } else {
      return (
        <div className="d-flex justify-content-center align-items-center" style={{ padding: '8px' }}>
          <ContentSearch placeholder="Caută" className="me-2" isMobile={true} isLoggedIn={isLoggedin} />
          <div className="ms-1 me-1" style={{ marginTop: '3px' }}>
            {renderAccount(isLoggedin, true)}
          </div>
          {/* <div style={{ marginTop: '3px' }}>
            <UserNotifications isLoggedin={isLoggedin} />
          </div> */}
          <div className="ms-2 me-2" style={{ marginTop: '3px' }}>
            {renderLogOut()}
          </div>
        </div>
      );
    }
  };

  const renderBackOfficeUser = () => {
    if (!kentico.user) {
      return (
        <div className="login-button d-flex flex-column justify-content-center">
          <a
            onClick={(event: React.MouseEvent<HTMLElement>) => {
              event.preventDefault();
              loginFormRef && loginFormRef.current && loginFormRef.current.submit();
            }}
            style={{ textDecoration: 'none', marginTop: '2px', marginRight: '15px' }}
          >
            <div className="login-mobile-button d-flex flex-column justify-content-center align-items-center">
              <FontAwesomeIcon icon={faUser} className="login-icon" size={'lg'} />
            </div>
          </a>
        </div>
      );
    } else {
      return (
        <div className="d-flex justify-content-center align-items-center">
          <a style={{ textDecoration: 'none' }}>
            <div className="login-mobile-button d-flex flex-column justify-content-center align-items-center">
              <span style={{ color: 'black' }}>{kentico.user}</span>
            </div>
          </a>
          {renderLogOutBackOffice()}
        </div>
      );
    }
  };

  return (
    <Navbar
      expand="lg"
      fixed="top"
      style={{ background: '#ffffff', zIndex: '1000', display: 'flex', alignItems: 'center', justifyContent: 'start' }}
      expanded={isNavOpened}
      onToggle={() => {
        isOpened && setIsOpened(false);
        setIsNavOpened((prev) => !prev);
      }}
    >
      <Container className="navbar-container" style={{ marginLeft: isBackOfficeSection ? '10px' : '' }}>
        <Navbar.Brand href={kentico?.logo?.homeUrl ?? '/'}>
          {kentico?.logo?.url && kentico.logo.url.length > 1 ? (
            <img src={kentico.logo.url} className="delgaz-logo d-inline-block align-top" alt="React Bootstrap logo" />
          ) : (
            'Delgaz'
          )}
        </Navbar.Brand>

        {isMobile || isTablet ? (
          <div className="login-container d-flex">
            {!isBackOfficeSection && (isTablet ? renderTabletUserHeader() : renderMobileUserHeader())}
            {isBackOfficeSection && renderBackOfficeUser()}
            <div className="mobile-navbar-right d-flex">
              <Navbar.Toggle aria-controls="basic-navbar">
                <div style={{ width: '25px', height: '25px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  {!isNavOpened ? <FontAwesomeIcon icon={faBars} size={'lg'} /> : <FontAwesomeIcon icon={faXmark} size={'lg'} />}
                </div>
              </Navbar.Toggle>
            </div>
          </div>
        ) : (
          <div className="mobile-navbar-right d-flex">
            <Navbar.Toggle aria-controls="basic-navbar">
              <div style={{ width: '25px', height: '25px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                {!isNavOpened ? <FontAwesomeIcon icon={faBars} size={'lg'} /> : <FontAwesomeIcon icon={faXmark} size={'lg'} />}
              </div>
            </Navbar.Toggle>
          </div>
        )}
        {showMenu(isNavOpened) && (
          <Navbar.Collapse id="basic-navbar-nav" style={{ justifyContent: 'space-between' }}>
            <Nav className={`${!isBackOfficeSection && !isMobile && !isTablet ? 'ms-4' : ''}`}>
              {!isBackOfficeSection &&
                !isMobile &&
                !isTablet &&
                kentico &&
                kentico.items &&
                kentico.items.map((item: any, indexItem: number) => {
                  return (
                    <NavDropdown
                      key={indexItem}
                      className="pr-2 py-0 py-md-2 ms-4 align-text-top basic-nav-dropdown__hide-arrow"
                      title={<span>{item.title}</span>}
                      id="basic-nav-dropdown"
                      onClick={() => {
                        setIsOpened(false);
                      }}
                    >
                      <Container className="eventsNav pt-0 mt-0">
                        <Row>
                          {!isTablet && !isMobile && (
                            <Col xs="12" md="6" className="text-left">
                              {item.image && <Image src={item.image} thumbnail={true} />}
                              <Dropdown.Header className="mt-2" style={{ paddingLeft: 0 }}>
                                <h4>{item.subtitle ?? item.title}</h4>
                              </Dropdown.Header>
                              <Row>
                                <Col xs="12">
                                  <p className="basic-nav-dropdown__description" dangerouslySetInnerHTML={{ __html: item.description }} />
                                </Col>
                              </Row>
                            </Col>
                          )}
                          <Col
                            xs="12"
                            md="6"
                            className={`text-left d-flex pt-0 pt-md-4 flex-column ${!isMobile && !isTablet ? 'border-start' : ''}`}
                            style={{ fontSize: '1.2rem' }}
                          >
                            {item.items.map((link: any, index: number) => {
                              return (
                                <Dropdown.Item key={index} className="mt-1 mt-md-3 link" href={`${link.link}`}>
                                  <span>{link.title}</span>
                                </Dropdown.Item>
                              );
                            })}
                          </Col>
                        </Row>
                      </Container>
                    </NavDropdown>
                  );
                })}

              {isBackOfficeSection && !isMobile && !isTablet && kentico && kentico.items && (
                <>
                  {kentico.items.length > nrOfSliderItems && (
                    <div
                      className={displayFrom > 0 ? 'hover-red' : ''}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        margin: 10,
                        marginTop: 15,
                        color: '#ea1c0a',
                        transition: 'color 0.3s',
                        cursor: 'pointer',
                        userSelect: 'none'
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faCaretLeft}
                        size="2x"
                        onClick={() => {
                          if (displayFrom > 0) {
                            setDisplayFrom(displayFrom - 1);
                            setDisplayTo(displayTo - 1);
                          }
                        }}
                      />
                    </div>
                  )}

                  {kentico.items.map((item: any, indexItem: number) => {
                    if (indexItem >= displayFrom && indexItem <= displayTo) {
                      let selected = selectedItemIndex == indexItem;
                      return (
                        <NavDropdown
                          key={indexItem}
                          className="pr-2 py-0 py-md-2 basic-nav-dropdown__hide-arrow"
                          style={{
                            minWidth: '9rem',
                            maxWidth: '9rem',
                            width: '9rem',
                            display: 'flex',
                            justifyContent: 'center'
                          }}
                          title={
                            <span className={selected ? 'red' : ''} style={{ overflow: 'hidden', whiteSpace: 'normal' }}>
                              {item.title}
                            </span>
                          }
                          id="basic-nav-dropdown"
                          onClick={() => {
                            setIsOpened(false);
                          }}
                        >
                          <Container className="eventsNav pt-0 mt-0">
                            <Row>
                              {!isTablet && !isMobile && (
                                <Col xs="12" md="6" className="text-left">
                                  {item.image && <Image src={item.image} thumbnail={true} />}
                                  <Dropdown.Header className="mt-2" style={{ paddingLeft: 0 }}>
                                    <h4>{item.subtitle ?? item.title}</h4>
                                  </Dropdown.Header>
                                  <Row>
                                    <Col xs="12">
                                      <p
                                        className="basic-nav-dropdown__description"
                                        dangerouslySetInnerHTML={{ __html: item.description }}
                                      />
                                    </Col>
                                  </Row>
                                </Col>
                              )}
                              <Col
                                xs="12"
                                md="6"
                                className={`text-left d-flex pt-0 pt-md-4 flex-column ${!isMobile && !isTablet ? 'border-start' : ''}`}
                                style={{ fontSize: '1.2rem' }}
                              >
                                {item.items.map((link: any, index: number) => {
                                  let selectedLink = link.link == window.location.pathname;
                                  return (
                                    <Dropdown.Item
                                      key={index}
                                      className={`mt-1 mt-md-3 link ${selectedLink ? 'dropdown-item__selected' : ''}`}
                                      href={`${link.link}`}
                                    >
                                      <span>{link.title}</span>
                                    </Dropdown.Item>
                                  );
                                })}
                              </Col>
                            </Row>
                          </Container>
                        </NavDropdown>
                      );
                    } else {
                      return '';
                    }
                  })}

                  {kentico.items.length > nrOfSliderItems && (
                    <div
                      className={displayTo < kentico.items.length - 1 ? 'hover-red' : ''}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        margin: 10,
                        marginTop: 15,
                        marginRight: '2rem',
                        color: '#ea1c0a',
                        transition: 'color 0.3s',
                        cursor: 'pointer',
                        userSelect: 'none'
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faCaretRight}
                        size="2x"
                        onClick={() => {
                          if (displayTo < kentico.items.length - 1) {
                            setDisplayFrom(displayFrom + 1);
                            setDisplayTo(displayTo + 1);
                          }
                        }}
                      />
                    </div>
                  )}
                </>
              )}

              {(isMobile || isTablet) && (
                <Accordion>
                  {kentico &&
                    kentico.items &&
                    kentico.items.map((item: any, indexItem: number) => {
                      return (
                        <Accordion.Item eventKey={`${indexItem}`} key={indexItem}>
                          <Accordion.Header style={{ fontSize: '1.5rem', color: 'rgba(0,0,0,.55)' }}>{item.title}</Accordion.Header>
                          <Accordion.Body>
                            <div className="d-flex flex-column">
                              {item.items.map((link: any, index: number) => {
                                return (
                                  <a href={link.link} key={index} className="accordion-link">
                                    {link.title}
                                  </a>
                                );
                              })}
                            </div>
                          </Accordion.Body>
                        </Accordion.Item>
                      );
                    })}
                </Accordion>
              )}
            </Nav>
          </Navbar.Collapse>
        )}
        {!isMobile && !isTablet && !isBackOfficeSection && renderNonMobileHeader()}
        {!isMobile && !isTablet && isBackOfficeSection && renderBackOfficeUser()}
        {isBackOfficeSection && (
          <form method="POST" action="/ExternalAuthentication/RequestExternalSignIn?returnurl=%2Fbackoffice" ref={loginFormRef}>
            <input name="provider" type="hidden" value="Microsoft" />
          </form>
        )}
      </Container>
    </Navbar>
  );
};

export default MenuUser;