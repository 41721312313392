import React, { Fragment } from 'react';

const CPTableHead = (props: any) => {
  return (
    <Fragment>
      {(props.utility === 2 && !props.isFurnizor) &&
        <thead>
          <tr>
            <th></th>
            <th>Cod partener</th>
            <th>Denumire partener</th>
            <th>Denumire furnizor</th>
            <th>Codul locului de consum</th>
            <th>POD</th>
            <th>Adresa</th>
            <th>Status Contract</th>
            <th>Serie contor</th>
            <th>Status conectare</th>
            <th>Categorie de consum</th>
            <th>SRMP(Statie reglare masurare presiune)</th>
            <th>Presiune minima</th>
            <th>Presiune maxima</th>
            <th>Presiune minima technica/avarie</th>
            <th>Cantitatea contactata</th>
            <th>Durata contract furnizare</th>
          </tr>
        </thead>}
      {(props.utility === 1 && !props.isFurnizor) &&
        <thead>
          <tr>
            <th></th>
            <th>Cod partener</th>
            <th>Denumire partener</th>
            <th>Denumire furnizor</th>
            <th>Codul locului de consum</th>
            <th>POD</th>
            <th>Adresa</th>
            <th>Status Contract</th>
            <th>Serie contor</th>
            <th>Status conectare</th>
            <th>Nivel de tensiune</th>
            <th>Putere maxima admisa</th>
            <th>Data PRE</th>
          </tr>
        </thead>
      }
    </Fragment>
  );
};

export default CPTableHead;
