import React from 'react';
import { DelgazWrapper } from '@components/global/DelgazWrapper';
import DecRecSolicitareNoua from '@components/decRec/solicitareNoua/DecRecSolicitareNoua';

export const DecRecSolicitareNouaComponent = () => {
  return (
    <DelgazWrapper>
      <DecRecSolicitareNoua />
    </DelgazWrapper>
  );
};
