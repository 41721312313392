import React from 'react';
import InputMask from 'react-input-mask';

const Mask = (props: {
    value: string,
    onChange: (e: any)=> any,
    mask: string,
    disabled?: boolean,
    label?: string
    onBlur?: (e: any) => any,
    mbZero?: boolean,
    autoFocus?: boolean,
    preloadedStartDisabled?: boolean
    onFocus?: (e: any) => any,
    name?: string
}) => {

    const {value, onChange, mask, disabled, label, onBlur, onFocus, mbZero, autoFocus, name} = props;

    let requestNoFormat = {
        '0': '[2]',
        '1': '[1]',
        '2': '[0123456789]'
    };

    return (
        <div className={`field-group ${mbZero && 'mb-0'}`}>
            <span className="input">
                <InputMask
                    className={'input-with-value'}
                    mask={mask}
                    placeholder={'21_______'}
                    formatChars={requestNoFormat}
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    disabled={disabled}
                    autoFocus={autoFocus}
                    onFocus={onFocus}
                    name={name}
                />
                <label>{label}</label>
            </span>
        </div>
    );
};

export default Mask;