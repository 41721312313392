import React from 'react';

import { DelgazWrapper } from '@components/global/DelgazWrapper';
import SearchByClc from '@components/delgaz/Account/SuperiorCaloricValue/SearchByClc';

export const SearchByClcComponent = (): JSX.Element => {
  return (
    <DelgazWrapper>
      <SearchByClc />
    </DelgazWrapper>
  );
};
