import React from 'react';
import { DelgazWrapper } from '@components/global/DelgazWrapper';
import AdCardDetails from '@components/dev/Components/AdCard/AdCardDetails';
import {AdCardKentico} from '@type/kentico-types';

export type AdCardProps = { kentico: AdCardKentico };

export const Details = (props: AdCardProps): JSX.Element => {
    return (
        <DelgazWrapper>
            <AdCardDetails {...props}/>
        </DelgazWrapper>
    );
};