import { faSort, faSortDown, faSortUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

type SortAscDescProps = {
  sortColumn: string;
  sortAscending: boolean;
  onSort: (column: string) => void;
  fieldId: string;
  fieldName: string;
};

const SortAscDesc = (props: SortAscDescProps) => {
  const { sortColumn, sortAscending, onSort, fieldId, fieldName } = props;
  return (
    <div style={{ cursor: 'pointer' }} onClick={() => onSort(fieldId)}>
      {`${fieldName} `}
      {sortColumn != fieldId ? (
        <FontAwesomeIcon icon={faSort} size="sm" />
      ) : sortAscending ? (
        <FontAwesomeIcon icon={faSortUp} size="sm" color="#bfbfbf" />
      ) : (
        <FontAwesomeIcon icon={faSortDown} size="sm" color="#bfbfbf" />
      )}
    </div>
  );
};

export default SortAscDesc;
