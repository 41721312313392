import React from 'react';
import { DelgazWrapper } from '@components/global/DelgazWrapper';
import FormularContact from '@components/rss/formularContact/FormularContact';

export const FormularContactRSS = () => {
    return (
        <DelgazWrapper>
            <FormularContact />
        </DelgazWrapper>
    );
};