import React, { useState, useEffect, Fragment } from 'react';

import { BackOffice, BackOfficeContext, BackOfficeContextType, InputValues } from '@contexts/backoffice-context';
import { ActionTypes, CustomActionTypes, DataTypes, DetailActionResponse, ModalSize, RowAction } from '@type/backOfficeModule';
import { Button, Col, Form, Row, Table } from 'react-bootstrap';
import ModalComponent from '@components/dev/Components/Modal/Modal';
import StringList from './Fields/StringList';
import String from './Fields/String';
import DateTime from './Fields/DateTime';
import { ValueToSet } from './FiltersForm';
import { DropdownOptionType } from '@components/common/Dropdown';
import DropdownField from './Fields/DropdownField';
import DateRange, { DataItem } from './Fields/DateRange';
import { RadioGroup } from '@components/common/RadioGroup';
import { ToString } from '@lib/Utils';
import StringListEdit from './Fields/StringListEdit';
import { FormField, FormGroup, Option, OptionsForField } from './PageWrapper';
import { CustomGet, QueryParams } from '@api/backOffice/fetchResults';
import Int from './Fields/Int';
import Html from './Fields/Html';
import DropdownString from './Fields/DropdownString';
import DropdownInt from './Fields/DropdownInt';
import Base64FileList from './Fields/Base64FileList';
import { useDispatch, useSelector } from 'react-redux';
import { toggleLoader } from '@actions/LoaderActions';
import Base64File from './Fields/Base64File';
import { IState } from '@type/store';
import useBoHelpers from './useBoHelpers';
import TimelineComponent from '@components/timeline/TimelineComponent';
import ChoiceModal from '@components/common/Modal/ChoiceModal';
import Paragraph from './Fields/Paragraph';
import BackofficeChart from '../Requests/BackofficeChart';

let integerTypes = [DataTypes.Int, DataTypes.HiddenInt, DataTypes.ReadonlyInt];
export type Item = BackOffice['filters'][number] | BackOffice['advancedFilters'][number];

const HIGHLIGHT_TYPE_DEFAULT = '0';
const HIGHLIGHT_TYPE_HIGH = '1';
const HIGHLIGHT_TYPE_LOW = '2';

const RowActions = ({ item }: { [key: string]: string | any }) => {
  const { config, onRowAction, fetchDetails } = React.useContext(BackOfficeContext) as BackOfficeContextType;
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [action, setAction] = useState<RowAction | null>(null);
  const [values, setValues] = useState<InputValues>({} as InputValues);
  const [detailActionValues, setDetailActionValues] = useState<DetailActionResponse | DetailActionResponse[]>({} as DetailActionResponse);
  const [viewFilesValues, setViewFilesValues] = useState<any>([]);
  const [optionsForField, setOptionsForField] = useState<OptionsForField[]>([] as OptionsForField[]);
  const [confirmButtonDisabled, setConfirmButtonDisabled] = useState<boolean>(false);
  const [formValidationErrors, setFormValidationErrors] = useState<any>([]);
  const { getDetailsValueForItemByType } = useBoHelpers();

  //ModalConfirm Popup
  const [showModalFormConfirmActionPopup, setShowModalFormConfirmActionPopup] = useState<boolean>(false);
  const [modalFormConfirmActionPopupMessage, setModalFormConfirmActionPopupMessage] = useState<string>('');
  const [modalFormConfirmActionPopupActionId, setModalFormConfirmActionPopupActionId] = useState<string>('');

  const basePath: any = useSelector<IState>((state) => state.backOffice.basePath);

  const { downloadFileAction } = useBoHelpers();
  const dispatch = useDispatch();

  const onValueChange = ({ name, value, itemTypeId }: ValueToSet) => {
    let assign: string | number | boolean | string[] | any;
    if (itemTypeId === DataTypes.Bool) {
      assign = value === '1' ? true : false;
    } else {
      assign = value;
    }
    setValues((prevValues: any) => {
      return { ...prevValues, [name]: assign };
    });
  };

  useEffect(() => {
    if (isEditing === false) {
      setValues({} as InputValues);
    } else {
      if (config) {
        let allFields: InputValues = {};
        action?.formGroups?.forEach((group) => {
          let fields = group?.fields?.reduce(
            (o, current) => ({
              ...o,
              [current.name]:
                current.dataTypeId === DataTypes.Bool
                  ? item[current.name.charAt(0).toLowerCase() + current.name.slice(1)] === 'True' ||
                    item[current.name.charAt(0).toLowerCase() + current.name.slice(1)] === true
                    ? true
                    : false
                  : item[current.name.charAt(0).toLowerCase() + current.name.slice(1)]
                  ? integerTypes.includes(current.dataTypeId)
                    ? Number(item[current.name.charAt(0).toLowerCase() + current.name.slice(1)])
                    : item[current.name.charAt(0).toLowerCase() + current.name.slice(1)]
                  : current.value
                  ? integerTypes.includes(current.dataTypeId)
                    ? Number(current.value)
                    : current.value
                  : current.dataTypeId === DataTypes.Base64FileList
                  ? []
                  : ''
            }),
            {} as InputValues
          );
          allFields = { ...allFields, ...fields };
        });

        //set values for field childs
        action?.formGroups?.forEach((group) => {
          let fields: { [key: string]: any } = {};
          //@ts-ignore
          group?.fields?.forEach((field: FormField) => {
            if (field.itemsConfiguration?.cascadeChild) {
              let child = field.itemsConfiguration?.cascadeChild;
              while (child) {
                fields[child.name] = child.value
                  ? integerTypes.includes(field.dataTypeId)
                    ? Number(child.value)
                    : child.value
                  : integerTypes.includes(field.dataTypeId)
                  ? Number(item[child.name.charAt(0).toLowerCase() + child.name.slice(1)])
                  : item[child.name.charAt(0).toLowerCase() + child.name.slice(1)];

                if (child.itemsConfiguration?.cascadeChild) {
                  //@ts-ignore
                  child = child.itemsConfiguration?.cascadeChild;
                } else {
                  //@ts-ignore
                  child = null;
                }
              }
            }
          });
          allFields = { ...allFields, ...fields };
        });

        setValues({
          ...allFields,
          [config.identityColumnName]: item[config.identityColumnName.charAt(0).toLowerCase() + config.identityColumnName.slice(1)]
        });
      }
    }
  }, [isEditing]);

  //set field child options
  useEffect(() => {
    optionsForField.forEach((option: OptionsForField) => {
      const fieldValue = values[option.field];

      if (option.child && option.child !== '' && fieldValue) {
        let paramValue = option.options.find((op: Option) => op[option.keyFieldName] == values[option.field]);
        paramValue = paramValue ? paramValue[option.childUrlQuerryParameterName] : '';

        let params = [
          {
            name: option.childUrlQuerryParameterName as string,
            value: paramValue
          }
        ] as QueryParams[];
        getOptionsFromApi(option.child, '', option.childUrl, '', '', option.field, '', params);
      }
    });
  }, [values]);

  //set field options
  useEffect(() => {
    config?.rowActions.forEach((action: RowAction) => {
      if (action.actionTypeName === 'ModalForm' && action.formGroups) {
        //@ts-ignore
        action.formGroups.forEach((formGroup: FormGroup) => {
          if (formGroup.fields) {
            formGroup.fields.forEach((field: FormField) => {
              if (field.itemsConfiguration && !field.itemsConfiguration.staticData) {
                const url = field.itemsConfiguration.itemsDefinition?.url;
                const keyFieldName = field.itemsConfiguration?.keyFieldName
                  ? field.itemsConfiguration?.keyFieldName.charAt(0).toLocaleLowerCase() + field.itemsConfiguration?.keyFieldName.slice(1)
                  : 'id';
                //eslint-disable-next-line
                const valueFieldName = field.itemsConfiguration?.valueFieldName ? field.itemsConfiguration?.valueFieldName : 'value';

                let childName;
                let childUrl;
                let childUrlQuerryParamName = '';
                if (field.itemsConfiguration.cascadeChild) {
                  const child = field.itemsConfiguration.cascadeChild;
                  if (child.itemsConfiguration && !child.itemsConfiguration.staticData) {
                    childName = child.name;
                    childUrl = child.itemsConfiguration.itemsDefinition?.url;
                    childUrlQuerryParamName =
                      (child.itemsConfiguration.itemsDefinition?.queryStringSourceParameterName ?? '').charAt(0).toLowerCase() +
                      (child.itemsConfiguration.itemsDefinition?.queryStringSourceParameterName ?? '').slice(1);
                  }
                }

                getOptionsFromApi(field.name, keyFieldName, url, childUrl, childName as string, '', childUrlQuerryParamName);
              }
            });
          }
        });
      }
    });
  }, [config?.generalActions]);

  const getOptionsFromApi = async (
    fieldName: string,
    keyFieldName: string,
    url: string | any,
    childUrl: string | any,
    childName: string,
    parentName: string,
    childUrlQuerryParameterName: string,
    urlParams?: QueryParams[]
  ) => {
    let options = await CustomGet(url, urlParams);
    setOptionsForField((preProps) => {
      let existentFieldIndex = preProps.findIndex((p: OptionsForField) => p.field === fieldName);

      if (existentFieldIndex >= 0) {
        return [
          ...preProps.slice(0, existentFieldIndex),
          Object.assign({}, preProps[existentFieldIndex], {
            field: fieldName,
            child: childName,
            keyFieldName: keyFieldName,
            childUrl: childUrl,
            childUrlQuerryParameterName: childUrlQuerryParameterName,
            parent: parentName,
            options: [...options]
          }),
          ...preProps.slice(existentFieldIndex + 1)
        ];
      } else {
        return [
          ...preProps,
          {
            field: fieldName,
            child: childName,
            keyFieldName: keyFieldName,
            childUrl: childUrl,
            childUrlQuerryParameterName: childUrlQuerryParameterName,
            parent: parentName,
            options: [...options]
          }
        ];
      }
    });
  };

  //@ts-ignore
  const validateValue = (field: any) => {
    let res = true;
    let childRes = true;
    if (field.isRequired) {
      if (!values[field.name]) {
        res = false;
        if (!formValidationErrors.find((v: any) => v.name === field.name)) {
          setFormValidationErrors((prevState: any) => [
            ...prevState,
            {
              name: field.name,
              displayName: field.displayName
            }
          ]);
        }
      } else {
        if (formValidationErrors.find((v: any) => v.name === field.name)) {
          setFormValidationErrors((prevState: any) => [...prevState.filter((v: any) => v.name !== field.name)]);
        }
      }
    }

    const child = field.itemsConfiguration?.cascadeChild ?? null;
    if (child) {
      childRes = validateValue(child);
    }
    return res && childRes;
  };

  const validateValues = (values: any, action: any) => {
    let res = true;
    action.formGroups?.forEach((group: any, index: number) => {
      group.fields?.forEach((field: any, index: number) => {
        const isFieldValid = validateValue(field);
        if (!isFieldValid) {
          res = false;
        }
      });
    });
    return res;
  };

  const removeChildValueAtParentChange = (child: any) => {
    if (child) {
      if (child.dataTypeId == DataTypes.DropdownInt) {
        onValueChange({
          name: child.name,
          value: 0,
          itemTypeId: child.dataTypeId
        });
      } else if (child.dataTypeId == DataTypes.DropdownString) {
        onValueChange({
          name: child.name,
          value: '',
          itemTypeId: child.dataTypeId
        });
      }

      const childChild = child?.itemsConfiguration?.cascadeChild ?? null;
      if (childChild) {
        removeChildValueAtParentChange(childChild);
      }
    }
  };

  const getModalSize = (size: number) => {
    if (size == ModalSize.Small) {
      return 'sm';
    } else if (size == ModalSize.Medium) {
      return 'lg';
    } else if (size == ModalSize.Large) {
      return 'xl';
    }
  };

  const renderDetails = (field: Item) => {
    let key = field.name.charAt(0).toLowerCase() + field.name.slice(1);
    let keyV = field.name;

    //@ts-ignore
    let showRowIndex = field.showRowIndex ? field.showRowIndex : false;
    if (Array.isArray(detailActionValues)) {
      if (field.dataTypeId == DataTypes.GroupedFieldsList) {
        return (
          <div>
            {detailActionValues.map((detailObject: DetailActionResponse, index: number) => {
              return (
                <Fragment>
                  <Row className="my-2">
                    {showRowIndex && (
                      <Col sm={1} lg={1} md={1} xs={1} className="d-flex justify-content-center">
                        <h4 className="mb-0">{index + 1}</h4>
                      </Col>
                    )}

                    <Col className={`${showRowIndex ? 'p-0' : ''}`}>
                      {field.items?.map((item: any) => {
                        const value = getDetailsValueForItemByType(item, detailObject);
                        if (item.hideIfEmptyValue && !value) return;
                        return (
                          <div>
                            <span>
                              <strong>{item.displayName}:</strong>
                            </span>{' '}
                            {value}
                          </div>
                        );
                      })}
                    </Col>
                  </Row>
                  <hr />
                </Fragment>
              );
            })}
          </div>
        );
      } else if (field.dataTypeId == DataTypes.TableFieldsList) {
        return (
          <div style={{ overflow: 'scroll' }}>
            <div className="general-table-container">
              <Table>
                <thead>
                  <tr>
                    {showRowIndex && <th>#</th>}
                    {field.items?.map((item: any) => {
                      return <th>{item.displayName}</th>;
                    })}
                  </tr>
                </thead>
                <tbody>
                  {detailActionValues.map((detailObject: DetailActionResponse, index: number) => {
                    return (
                      <tr>
                        {showRowIndex && (
                          <td>
                            <span>{index + 1}</span>
                          </td>
                        )}
                        {field.items?.map((item: any) => {
                          const value = getDetailsValueForItemByType(item, detailObject);
                          if (item.hideIfEmptyValue && !value) return;
                          return (
                            <td>
                              <span>{value}</span>
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
          </div>
        );
      }
    } else {
      switch (field.dataTypeId) {
        case DataTypes.ReadonlyJson:
          var obj = '';
          var parsed = '';
          if (!detailActionValues[key] && !values[keyV]) {
            return <p>Fara detalii obiect!</p>;
          } else if (detailActionValues[key]) {
            try {
              obj = JSON.parse(detailActionValues[key]);
              parsed = JSON.stringify(obj, undefined, 2);
            } catch (E) {
              return (
                <div>
                  <h5>Eroare la parsare JSON!</h5>
                  <h6>Conținut inițial:</h6>
                  <p>{detailActionValues[key]}</p>
                </div>
              );
            }
          } else if (values[keyV]) {
            try {
              obj = JSON.parse(values[keyV] as string);
              parsed = JSON.stringify(obj, undefined, 2);
            } catch (E) {
              return (
                <div>
                  <h5>Eroare la parsare JSON!</h5>
                  <h6>Conținut inițial:</h6>
                  <p>{values[keyV]}</p>
                </div>
              );
            }
          }

          return (
            <div>
              <h5>{field.displayName}</h5>
              <pre>{parsed}</pre>
            </div>
          );
        case DataTypes.ReadonlyString:
          if (!detailActionValues[key] && !values[keyV]) {
            return <p>Fara detalii mesaj!</p>;
          } else if (detailActionValues[key]) {
            return (
              <div>
                <h5>{field.displayName}</h5>
                {detailActionValues[key]}
              </div>
            );
          } else if (values[keyV]) {
            return (
              <div>
                <h5>{field.displayName}</h5>
                {values[keyV]}
              </div>
            );
          } else {
            return '-';
          }
        default:
          const value = getDetailsValueForItemByType(field, detailActionValues);
          //@ts-ignore
          if (field.hideIfEmptyValue && !value) return;
          return (
            <div>
              <h5>{field.displayName}</h5>
              <div>{value}</div>
            </div>
          );
      }
    }

    return null;
  };

  const renderField = (field: FormField, index: number, groupNumberOfColumnsPerRow: number, parent?: any) => {
    let itemType = field.dataTypeName;
    let itemTypeId = field.dataTypeId as number;
    if (groupNumberOfColumnsPerRow && field.colspan > groupNumberOfColumnsPerRow) {
      field.colspan = groupNumberOfColumnsPerRow;
    }
    let oneColSize = 12 / groupNumberOfColumnsPerRow;
    let colspanValue = field.colspan * oneColSize;

    if (field.isRequired) {
      if (!field.displayName.endsWith('*')) {
        field.displayName = `${field.displayName} *`;
      }
    }
    const child = field.itemsConfiguration?.cascadeChild;
    if (child) {
      //@ts-ignore
      if (child.isRequired) {
        if (!child.displayName.endsWith('*')) {
          child.displayName = `${child.displayName} *`;
        }
      }
    }

    switch (itemType) {
      case 'StringList':
        return (
          <Col key={index} md={colspanValue}>
            <StringList
              options={[] as string[]}
              label={field.displayName}
              value={(values[field.name] as string) ?? ''}
              itemTypeId={itemTypeId}
              onChange={onValueChange}
              name={field.name}
            />
          </Col>
        );
      case 'String':
        return (
          <Col key={index} md={colspanValue}>
            <String
              label={field.displayName}
              name={field.name}
              value={(values[field.name] as string) ?? ''}
              itemTypeId={itemTypeId}
              onChange={onValueChange}
            />
          </Col>
        );
      case 'MultilineString':
        return (
          <Col key={index} md={colspanValue}>
            <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
              <p className="mb-1">{field.displayName}</p>
              <Form.Control
                as="textarea"
                //@ts-ignore
                className={field.rows ? 'h-100' : ''}
                //@ts-ignore
                rows={field.rows ? field.rows : 3}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  onValueChange({
                    name: field.name,
                    value: event.target.value,
                    itemTypeId
                  } as ValueToSet);
                }}
                value={(values[field.name] as string) ?? ''}
              />
            </Form.Group>
          </Col>
        );
      case 'DateTime':
        return (
          <Col key={index} md={colspanValue}>
            <DateTime label={field.displayName} name={field.name} itemTypeId={itemTypeId} onChange={onValueChange} />
          </Col>
        );
      case 'Dropdown':
        let value: string | number | boolean | string[] = typeof values[field.name] !== 'boolean' ? values[field.name] ?? '' : '';
        value = typeof value === 'boolean' ? '' : value;

        return (
          <Col key={index} md={colspanValue}>
            <DropdownField
              options={field.items as DropdownOptionType[]}
              label={field.displayName}
              itemTypeId={itemTypeId}
              name={field.name}
              value={value}
              onChange={onValueChange}
              isUsedInBackOffice={true}
              displaySearch={true}
            />
          </Col>
        );
      case 'DateRange':
        return (
          <Col key={index} md={colspanValue}>
            <DateRange
              onChange={onValueChange}
              items={field.items?.map(
                (dateItem: any): DataItem => ({
                  label: dateItem.displayName,
                  itemTypeId: itemTypeId,
                  name: dateItem.name
                })
              )}
            />
          </Col>
        );
      case 'Bool':
        return (
          <Col key={index} md={colspanValue}>
            <RadioGroup
              className="mw-100 mb-4"
              label={field.displayName}
              value={(values[field.name] as boolean) === true ? '1' : '2'}
              itemClassName="bg-eon-red d-inline mr-2 ms-2"
              options={[
                {
                  value: '1',
                  name: 'Da'
                },
                {
                  value: '2',
                  name: 'Nu'
                }
              ]}
              onClick={(value?: string | string[] | number | boolean) => {
                onValueChange({ name: field.name, value: ToString(value), itemTypeId } as ValueToSet);
              }}
            />
          </Col>
        );
      case 'Date':
        return (
          <Col key={index} md={colspanValue}>
            <DateTime
              label={field.displayName}
              isInModal={true}
              name={field.name}
              itemTypeId={itemTypeId}
              onChange={onValueChange}
              value={ToString(values[field.name] ? (values[field.name] as string) : '')}
            />
          </Col>
        );
      case 'ReadonlyString':
        return (
          <Col key={index} md={colspanValue}>
            <String
              label={field.displayName}
              name={field.name}
              value={(values[field.name] as string) ?? ''}
              itemTypeId={itemTypeId}
              onChange={onValueChange}
              disabled={true}
            />
          </Col>
        );
      case 'DropdownInt':
        return (
          <DropdownInt
            key={index}
            item={field}
            itemTypeId={itemTypeId}
            value={values[field.name] ? values[field.name] : ''}
            // onChange={onValueChange}
            onChange={(value) => {
              onValueChange(value);
              const child = field.itemsConfiguration?.cascadeChild ?? null;
              removeChildValueAtParentChange(child);
            }}
            isUsedInFilters={false}
            values={values}
            renderChild={renderField}
            groupNumberOfColumnsPerRow={groupNumberOfColumnsPerRow}
            optionsForField={optionsForField}
            parent={parent}
            colspanValue={colspanValue}
          />
        );
      case 'DropdownString':
        return (
          <DropdownString
            key={index}
            item={field}
            itemTypeId={itemTypeId}
            value={values[field.name] ? values[field.name] : ''}
            onChange={(value) => {
              onValueChange(value);
              const child = field.itemsConfiguration?.cascadeChild ?? null;
              removeChildValueAtParentChange(child);
            }}
            isUsedInFilters={false}
            values={values}
            renderChild={renderField}
            groupNumberOfColumnsPerRow={groupNumberOfColumnsPerRow}
            optionsForField={optionsForField}
            parent={parent}
            colspanValue={colspanValue}
          />
        );
      case 'StringListEdit':
        if (!values[field.name] || values[field.name] === '') {
          setValues({
            ...values,
            [field.name]: ['']
          });
        }
        return (
          <StringListEdit
            key={index}
            values={values[field.name] ? (values[field.name] as string[]) : ([''] as string[])}
            label={field.displayName}
            itemTypeId={itemTypeId}
            onChange={onValueChange}
            name={field.name}
            colspan={field.colspan ? field.colspan : groupNumberOfColumnsPerRow ? groupNumberOfColumnsPerRow : 2}
          />
        );
      case 'Int':
        return (
          <Col key={index} md={colspanValue}>
            <Int
              label={field.displayName}
              name={field.name}
              value={values[field.name] ? (values[field.name] as string) : ''}
              itemTypeId={itemTypeId}
              onChange={onValueChange}
            />
          </Col>
        );
      case 'Html':
        return (
          <Col key={index} md={colspanValue}>
            <Html
              label={field.displayName}
              name={field.name}
              value={(values[field.name] as string) ?? ''}
              itemTypeId={itemTypeId}
              onChange={onValueChange}
            />
          </Col>
        );
      case 'Empty':
        return (
          <Col md={12}>
            <div className="m-2 p-3"></div>
          </Col>
        );
      case 'Base64FileList':
        let label = field.displayName;

        //pentru fisiere valori masurate se foloseste label dinamic
        if (basePath == 'masterdata/BackofficeMeasuredValuesFiles') {
          label = `Partener: ${item.partnerName} Cod ETSO: ${item.codeETSO}`;
        }
        return (
          <Col key={index} md={colspanValue}>
            <Base64FileList
              label={label}
              name={field.name}
              itemTypeId={itemTypeId}
              onChange={onValueChange}
              value={values[field.name] ? values[field.name] : []}
              viewFilesValues={viewFilesValues}
              //@ts-ignore
              allowedFileExtensions={field.allowedFileExtensions}
              //@ts-ignore
              allowedMaxTotalFileSizeMB={field.allowedMaxTotalFileSizeMB}
            />
          </Col>
        );
      case 'Base64File':
        return (
          <Col key={index} md={colspanValue}>
            <Base64File
              label={field.displayName}
              name={field.name}
              itemTypeId={itemTypeId}
              onChange={onValueChange}
              //@ts-ignore
              allowedFileExtensions={field.allowedFileExtensions}
              //@ts-ignore
              allowedMaxTotalFileSizeMB={field.allowedMaxTotalFileSizeMB}
            />
          </Col>
        );
      case 'Paragraph':
        return (
          <Col key={index} md={colspanValue}>
            <Paragraph
              //@ts-ignore
              color={field.color}
              //@ts-ignore
              fontStyle={field.fontStyle}
              label={field.displayName}
              name={field.name}
              value={(values[field.name] as string) ?? ''}
              hideIfEmtyValue={true}
            />
          </Col>
        );
      default:
        break;
    }

    return null;
  };

  return (
    <>
      {config && config.rowActions && (
        <div className="d-flex flex-wrap">
          {config.rowActions.map((action: RowAction, key: number) => {
            if (
              action.logicalConditionFieldName === '' ||
              (action.logicalConditionFieldName &&
                item[action.logicalConditionFieldName.charAt(0).toLowerCase() + action.logicalConditionFieldName.slice(1)])
            ) {
              let highlightType = HIGHLIGHT_TYPE_DEFAULT;
              //@ts-ignore
              if (action.highlightTypeFieldName) {
                //@ts-ignore
                highlightType = item[action.highlightTypeFieldName.charAt(0).toLowerCase() + action.highlightTypeFieldName.slice(1)];
              }
              return (
                <Button
                  key={key}
                  className={`m-1 ${
                    highlightType == HIGHLIGHT_TYPE_HIGH ? 'btn-high' : highlightType == HIGHLIGHT_TYPE_LOW ? 'btn-low' : ''
                  }`}
                  onClick={(e) => {
                    setAction(action);
                    setIsEditing(true);

                    if (action.actionTypeName === ActionTypes.ModalDetails && action.preloadDataAction) {
                      let value = item[config.identityColumnName.charAt(0).toLowerCase() + config.identityColumnName.slice(1)];
                      setDetailActionValues({});
                      let response =
                        fetchDetails &&
                        fetchDetails(
                          {
                            [config.identityColumnName]:
                              config.identityColumnDataTypeId === DataTypes.Int ? parseInt(value) : ToString(value)
                          },
                          action?.preloadDataAction?.actionId
                        );

                      response.then((responseData: DetailActionResponse) => {
                        setDetailActionValues(responseData);
                      });
                    } else if (
                      action.actionTypeName === ActionTypes.ModalForm &&
                      action.preloadDataAction &&
                      action.preloadDataAction.actionId === 'view_files'
                    ) {
                      let value = item[config.identityColumnName.charAt(0).toLowerCase() + config.identityColumnName.slice(1)];
                      setDetailActionValues({});
                      let response =
                        fetchDetails &&
                        fetchDetails(
                          {
                            [config.identityColumnName]:
                              config.identityColumnDataTypeId === DataTypes.Int ? parseInt(value) : ToString(value)
                          },
                          action?.preloadDataAction?.actionId
                        );

                      response.then((responseData: any) => {
                        setViewFilesValues(responseData);
                      });
                    } else if(action.actionTypeName === 'DownloadInternalAction'
                      || action.actionTypeName === 'DownloadInternal' 
                      || action.actionTypeName === 'DownloadExternal') {
                          downloadFileAction(action, item);
                      }
                  }}
                >
                  <span>{action.displayName}</span>
                </Button>
              );
            }
            //@ts-ignore
            else if (action.showTextWhenLogicalConditionIsFalse) {
              //@ts-ignore
              let btnMessage = action.showTextWhenLogicalConditionIsFalse;
              return (
                <button type="button" className="btn btn-secondary ms-2" disabled={true}>
                  {btnMessage}
                </button>
              );
            } else {
              return;
            }
          })}
        </div>
      )}

      {action && action.actionTypeName === 'ModalForm' && (
        <ModalComponent
          title={action.displayMessage}
          className="backoffice-modal"
          show={isEditing}
          backdropStatic={true}
          setShow={(state: boolean) => {
            setIsEditing(!isEditing);
          }}
          //@ts-ignore
          fullScreen={action.modalSize && action.modalSize == ModalSize.FullScreen ? true : 'md-down'}
          //@ts-ignore
          size={action.modalSize ? getModalSize(action.modalSize) : 'lg'}
          onClose={() => setFormValidationErrors([])}
          footerAction={
            <Button
              className="btn btn-primary"
              onClick={async (e) => {
                if (validateValues(values, action) === true) {
                  //@ts-ignore
                  if (action.showConfirmPopup) {
                    //@ts-ignore
                    setModalFormConfirmActionPopupMessage(action.confirmMessage ?? 'Sunteți sigur că doriți să efectuați operațiunea?');
                    setModalFormConfirmActionPopupActionId(action.actionId);
                    setShowModalFormConfirmActionPopup(true);
                  } else {
                    if (onRowAction) {
                      dispatch(toggleLoader(true));
                      //@ts-ignore
                      let success: boolean = await onRowAction(values, action.actionId, action.showApiResponseAsConfirmDialog);
                      if (success === false) {
                      }
                      dispatch(toggleLoader(false));
                    }
                  }
                }
              }}
              variant="primary"
            >
              Salveaza
            </Button>
          }
        >
          <Form className="form">
            <Row>
              <Col xs={12} md={12}>
                {action.formGroups?.map((group, index: number) => {
                  return (
                    <Row className="mb-2">
                      {group.displayName && (
                        <Col xs={12}>
                          <h3 className="brand-subtitle">{group.displayName}</h3>
                        </Col>
                      )}
                      {group.fields?.map((field, index: number) => {
                        return (
                          <Fragment>
                            {field.hint ? (
                              <Fragment>
                                <p className="field_hint">* {field.hint}</p>
                                {renderField(field as FormField, index, group.numberOfColumnsPerRow)}
                              </Fragment>
                            ) : (
                              renderField(field as FormField, index, group.numberOfColumnsPerRow)
                            )}
                          </Fragment>
                        );
                      })}
                    </Row>
                  );
                })}
              </Col>
            </Row>
            {formValidationErrors[0] && (
              <Row>
                <Col xs={12} md={12}>
                  <div style={{ color: 'red', fontWeight: 'bold' }}>Câmp obligatoriu {formValidationErrors[0].displayName}</div>
                </Col>
              </Row>
            )}
          </Form>
        </ModalComponent>
      )}
      {action && action.actionTypeName === 'Confirm' && (
        <ModalComponent
          title={action.displayName}
          show={isEditing}
          setShow={(state: boolean) => {
            setIsEditing(!isEditing);
          }}
          //@ts-ignore
          size={action.modalSize ? getModalSize(action.modalSize) : 'lg'}
          footerAction={
            <Button
              className="btn btn-primary"
              onClick={async (e) => {
                setConfirmButtonDisabled(true);
                dispatch(toggleLoader(true));
                let success: boolean;
                if (onRowAction) {
                  //@ts-ignore
                  success = await onRowAction(values, action.actionId, action.showApiResponseAsConfirmDialog);
                  setConfirmButtonDisabled(false);
                  if (success) {
                    setIsEditing(false);
                  }
                  dispatch(toggleLoader(false));
                }
              }}
              variant="primary"
              disabled={confirmButtonDisabled}
            >
              {action.displayName}
            </Button>
          }
        >
          <p>{action.displayMessage}</p>
        </ModalComponent>
      )}
      {action && action.actionTypeName === 'ModalDetails' && (
        <ModalComponent
          title={action.displayName}
          show={isEditing}
          className="backoffice-modal"
          setShow={(state: boolean) => {
            setIsEditing(!isEditing);
          }}
          //@ts-ignore
          fullScreen={action.modalSize && action.modalSize == ModalSize.FullScreen ? true : 'md-down'}
          //@ts-ignore
          size={action.modalSize ? getModalSize(action.modalSize) : 'lg'}
        >
          <div className="mt-2">
            {Object.keys(detailActionValues).length > 0 || true ? (
              <Row>
                {action.formGroups?.map((group, index: number) => {
                  return (
                    group.fields &&
                    group.fields?.map((field, index: number) => {
                      return (
                        <Col key={index} md={12} className={'mt-1'}>
                          {renderDetails(field as Item)}
                        </Col>
                      );
                    })
                  );
                })}
              </Row>
            ) : (
              <Row>
                <Col>
                  <h4>Loading...</h4>
                </Col>
              </Row>
            )}
          </div>
        </ModalComponent>
      )}

      {action &&
        action.actionTypeName === ActionTypes.CustomAction &&
        //@ts-ignore
        action.customActionTypeName == CustomActionTypes.Timeline && (
          <ModalComponent
            title={action.displayName}
            show={isEditing}
            className="backoffice-modal"
            setShow={(state: boolean) => {
              setIsEditing(!isEditing);
            }}
            //@ts-ignore
            fullScreen={action.modalSize && action.modalSize == ModalSize.FullScreen ? true : 'md-down'}
            //@ts-ignore
            size={action.modalSize ? getModalSize(action.modalSize) : 'lg'}
          >
            <div className="mt-2">
              <TimelineComponent
                //@ts-ignore
                requestId={item[config.identityColumnName.charAt(0).toLowerCase() + config.identityColumnName.slice(1)]}
                isBo={true}
                boBasePath={basePath}
                utility={item['segment'] == 'EE' ? '1' : '2'}
                processStatus={item['processStatus']}
              />
            </div>
          </ModalComponent>
        )}

        {config && action && action.actionTypeName == CustomActionTypes.RSS && (
            <ModalComponent
              title={action.displayName}
              show={isEditing}
              className="backoffice-modal"
              setShow={(state: boolean) => {
                setIsEditing(!isEditing);
              }}
              //@ts-ignore
              fullScreen={action.modalSize && action.modalSize == ModalSize.FullScreen ? true : 'md-down'}
              //@ts-ignore
              size={'lg'}
              backdropStatic
            >
              <div className="mt-2">
                <BackofficeChart 
                  type={item.reportTypeName}
                  id={item[config.identityColumnName.charAt(0).toLowerCase() + config.identityColumnName.slice(1)]}
                />
              </div>
            </ModalComponent>
        )}

      <ChoiceModal
        showModal={showModalFormConfirmActionPopup}
        modalTitle={modalFormConfirmActionPopupMessage}
        options={[
          { id: 'DA', title: 'Da' },
          { id: 'NU', title: 'Nu' }
        ]}
        onClose={async (option: any) => {
          if (option.id == 'DA') {
            if (onRowAction) {
              dispatch(toggleLoader(true));
              //eslint-disable-next-line
              let success: boolean = await onRowAction(values, modalFormConfirmActionPopupActionId);
              dispatch(toggleLoader(false));
            }
            setShowModalFormConfirmActionPopup(false);
          } else if (option.id == 'NU') {
            setShowModalFormConfirmActionPopup(false);
          }
        }}
        onHide={() => {
          setShowModalFormConfirmActionPopup(false);
        }}
        btnWide
      />
    </>
  );
};

export default React.memo(RowActions);
