import React from 'react';
import { DelgazWrapper } from '@components/global/DelgazWrapper';
import ProgramariCitiriContoare from '@components/programariCitiri/ProgramariCitiriContoare';

export const ProgramariCitiriCont = (): JSX.Element => {
    return (
        <DelgazWrapper>
            <ProgramariCitiriContoare />
        </DelgazWrapper>
    );
};