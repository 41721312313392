import { toggleLoader } from '@actions/LoaderActions';
import { logout } from '@actions/user/user';
import {
  addLicenseToSupplier,
  addLicenseToSupplierOldSite,
  editAccount,
  getAccountDetails,
  getSupplierByLicenceId,
  removeLicenseFromSupplier,
  verifySupplier
} from '@api/user/user';
import { IState } from '@type/store';
import { ErrorMessage, Field, Form, Formik, FormikValues } from 'formik';
import { Fragment, useEffect, useState } from 'react';
import { Button, Col, Row, Tab, Tabs } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { EditSchemaFurn, VerifyLicenseForAddToFurnSchema } from '../../../../helpers/schemas';

import { Input } from '@components/common/Input';
import ModalConfirm from '@components/common/ModalConfirm/ModalConfirm';
import { jwtDecode } from 'jwt-decode';
import moment from 'moment';
import ChangeEmailGenerateEmail from '../ChangeEmail/ChangeEmailGenerateEmail';
import ChangePasswordFromEditPage from '../ChangePasswordFromEdit/ChangePasswordFromEditPage';

type JWT = {
  aud: string;
  aut: string;
  azp: string;
  deviceid: string;
  exp: number;
  given_name: string;
  groups: string[];
  iat: number;
  iss: string;
  jti: string;
  nbf: number;
  scope: string;
  sub: string;
  userid: string;
  CodFurnizor: string;
  Utilitate: string;
  LegacyId: number;
};

const Furnizor = (props: any) => {
  const [details, setDetails] = useState<any>({});
  const [userDetails, setUserDetails] = useState<null | JWT>(null);

  const [furnEEDetails, setFurnEEDetails] = useState<any>({});
  const [furnGNDetails, setFurnGNDetails] = useState<any>({});

  const [errorMessage, setErrorMessage] = useState('');
  // eslint-disable-next-line
  const [wasFetched, setWasFetched] = useState(false);
  const [isReqPasswordChange, setIsReqPasswordChange] = useState(false);
  const [showChangeEmailForm, setShowChangeEmailForm] = useState(false);

  //verify / add license
  const [wasGNDataVerified, setWasGNDataVerified] = useState(false);
  const [wasEEDataVerified, setWasEEDataVerified] = useState(false);
  const [verifiedSupplierEE, setVerifiedSupplierEE] = useState({});
  const [verifiedSupplierGN, setVerifiedSupplierGN] = useState({});
  const [verifyError, setVerifyError] = useState('');
  const [addLicenseError, setAddLicenseError] = useState('');
  const [showModalLicenseAddSoucces, setShowModalLicenseAddSoucces] = useState(false);

  const OLD_SITE_URL: string | undefined = useSelector<IState>((state) => state.options.webApiHost) as string;
  const token: string | undefined = useSelector<IState>((state) => state?.auth?.token) as string;

  const dispatch = useDispatch();

  useEffect(() => {
    let jwt: JWT = jwtDecode(token);
    jwt && setUserDetails(jwt);
  }, []);

  useEffect(() => {
    getDetails();
  }, [userDetails]);

  useEffect(() => {
    getFurn();
  }, [details]);

  const getDetails = async () => {
    dispatch(toggleLoader(true));

    await getAccountDetails()
      .then((response: any) => {
        if (response) {
          let d = response;
          d = { ...d, emailConfirmation: d.email };
          const det = { ...details, ...d };
          setDetails(det);
        } else {
          setWasFetched(true);
        }
      })
      .catch((e: any) => {
        setWasFetched(true);
      });
    dispatch(toggleLoader(false));
  };

  const getFurn = async () => {
    dispatch(toggleLoader(true));

    if (userDetails) {
      if (userDetails?.Utilitate === '1') {
        await getSupplierByLicenceId({ code: userDetails?.CodFurnizor, licenseType: userDetails?.Utilitate, userid: userDetails.userid })
          .then((response: any) => {
            if (response) {
              let d = response.partner;
              delete d.email;
              const det = { ...details, ...d };
              setFurnEEDetails(det);
            } else {
              setWasFetched(true);
            }
          })
          .catch((e: any) => {
            setWasFetched(true);
          });
      }
      if (userDetails?.Utilitate === '2') {
        await getSupplierByLicenceId({ code: userDetails?.CodFurnizor, licenseType: userDetails?.Utilitate, userid: userDetails.userid })
          .then((response: any) => {
            if (response) {
              let d = response.partner;
              delete d.email;
              const det = { ...details, ...d };
              setFurnGNDetails(det);
            } else {
              setWasFetched(true);
            }
          })
          .catch((e: any) => {
            setWasFetched(true);
          });
      }
      if (userDetails?.Utilitate && userDetails.Utilitate.includes(';')) {
        let codFurnizorEE;
        let codFurnizorGN;
        if (userDetails.Utilitate.split(';')[0] === '1') {
          codFurnizorEE = userDetails?.CodFurnizor.split(';')[0];
          codFurnizorGN = userDetails?.CodFurnizor.split(';')[1];
        } else {
          codFurnizorEE = userDetails?.CodFurnizor.split(';')[1];
          codFurnizorGN = userDetails?.CodFurnizor.split(';')[0];
        }

        await getSupplierByLicenceId({ code: codFurnizorEE, licenseType: 1, userid: userDetails.userid })
          .then((response: any) => {
            if (response) {
              let d = response.partner;
              delete d.email;
              const det = { ...details, ...d };
              setFurnEEDetails(det);
            } else {
              setWasFetched(true);
            }
          })
          .catch((e: any) => {
            setWasFetched(true);
          });

        await getSupplierByLicenceId({ code: codFurnizorGN, licenseType: 2, userid: userDetails.userid })
          .then((response: any) => {
            if (response) {
              let d = response.partner;
              delete d.email;
              const det = { ...details, ...d };
              setFurnGNDetails(det);
            } else {
              setWasFetched(true);
            }
          })
          .catch((e: any) => {
            setWasFetched(true);
          });
      }
    }
    dispatch(toggleLoader(false));
  };

  const submitVerifyLicenseForm = async (values: any) => {
    dispatch(toggleLoader(true));
    setVerifyError('');

    let data = {
      email: userDetails ? userDetails.sub : '',
      code: values.clientCodeEon,
      license: values.licenseNumber,
      licenseType: values.licenseType
    };

    await verifySupplier(data)
      .then((res: any) => {
        if (res.message === null && res.partner) {
          let partner = res.partner;
          partner = { ...partner, email: data.email };
          partner = { ...partner, supplierCode: data.code };
          partner = { ...partner, licence: data.license };

          values.licenseType === 1 && setVerifiedSupplierEE(partner);
          values.licenseType === 2 && setVerifiedSupplierGN(partner);

          values.licenseType === 1 && setWasEEDataVerified(true);
          values.licenseType === 2 && setWasGNDataVerified(true);
        } else {
          setVerifyError(res.message);
        }
      })
      .catch((e: any) => console.log(e));

    dispatch(toggleLoader(false));
  };

  const submitAddLicenseForm = async (values: any, licenseType: string) => {
    dispatch(toggleLoader(true));
    let payload = {
      licenseNumber: values.licenseNumber,
      supplierCode: values.supplierCode,
      utility: licenseType,
      legacyId: userDetails && userDetails.LegacyId,
      token: token
    };
    // aici se face call pentru add licenta pe nou
    await addLicenseToSupplier(payload)
      .then((res: any) => {
        if (res.success) {
          //adaugare licenta pe vechi
          addLicenseToSupplierOldSite(payload)
            .then((res: any) => {
              if (res.data.data === 'succes') {
                //modal confirm
                setShowModalLicenseAddSoucces(true);
                dispatch(toggleLoader(false));
              } else {
                //daca esueaza adaugarea pe vechi se face rollback
                removeLicenseFromSupplier(payload);
                if (res.data.errors[0]) {
                  setAddLicenseError(res.data.errors[0]);
                } else {
                  setAddLicenseError(getErrorMessage(''));
                }
                dispatch(toggleLoader(false));
              }
            })
            .catch((e: any) => {
              //daca esueaza adaugarea pe vechi se face rollback
              removeLicenseFromSupplier(payload);
              setAddLicenseError(getErrorMessage(''));
              dispatch(toggleLoader(false));
            });
        } else {
          let errorMessage = '';
          if (res.errors && res.errors[0]) {
            errorMessage = getErrorMessage(res.errors[0].errorDescription);
          } else {
            errorMessage = getErrorMessage('');
          }
          setAddLicenseError(errorMessage);
          dispatch(toggleLoader(false));
        }
      })
      .catch((e: any) => {
        setAddLicenseError(getErrorMessage(''));
        dispatch(toggleLoader(false));
      });
  };

  const getErrorMessage = (errorDescription: string) => {
    switch (errorDescription) {
      case 'InvalidSupplier':
        return 'Furnizorul nu este valid.';
      case 'ExistingUserWithSupplierCode':
        return 'Licenta este deja asociata unui cont. Incearca sa recuperezi parola sau contacteaza un reprezentant Delgaz.';
      default:
        return 'A apărut o eroare.';
    }
  };
  const handleCloseModalLicenseAddSoucces = () => {
    setShowModalLicenseAddSoucces(false);
    dispatch(logout());
    document.cookie = 'token=';
    window.location.href = `${OLD_SITE_URL}/login`;
  };

  const submit = async (values: any) => {
    setErrorMessage('');

    dispatch(toggleLoader(true));

    editAccount(values)
      .then((response: any) => {
        if (response && response.success === true) {
          setErrorMessage('Datele contului au fost modificate cu succes.');
        } else {
          setErrorMessage('A intervenit o eroare tehnică. Vă rugăm reveniți mai târziu.');
        }
        dispatch(toggleLoader(false));
      })
      .catch((e: any) => {
        setErrorMessage('A intervenit o eroare tehnică. Vă rugăm reveniți mai târziu.');
        dispatch(toggleLoader(false));
      });
  };

  const handleSelect = () => {
    setIsReqPasswordChange(false);
  };

  const renderValidateLicenseForm = (licenseType: any) => {
    return (
      <Fragment>
        <Formik
          enableReinitialize
          initialValues={{ licenseNumber: '', clientCodeEon: '', licenseType: licenseType }}
          onSubmit={(values) => submitVerifyLicenseForm(values)}
          validationSchema={VerifyLicenseForAddToFurnSchema}
        >
          {(props) => {
            return (
              <Fragment>
                <Form>
                  <div className="form-container edit-account-furnizor">
                    <div className="mt-3 d-flex flex-column">
                      <h4 className="register-section-title">
                        {licenseType === 1 ? 'Date furnizare energie electrică' : 'Date furnizare gaz natural'}
                      </h4>
                      <Row className="mt-3">
                        <Col className="mb-3" xs={12} md={6}>
                          <Field
                            id="licenseNumber"
                            name="licenseNumber"
                            placeholder="Număr licență"
                            render={() => (
                              <Input
                                label="Număr licență"
                                mbZero
                                onBlur={() => {
                                  props.setFieldTouched('licenseNumber');
                                }}
                                onChange={(e: any) => {
                                  e.target.value.length !== 0 && props.setFieldValue('licenseNumber', e.target.value);
                                  e.target.value.length === 0 && props.setFieldValue('licenseNumber', '');
                                }}
                              />
                            )}
                          />
                          <ErrorMessage
                            name="licenseNumber"
                            render={(msg) => <div style={{ color: 'red', fontWeight: 'bold' }}>{msg}</div>}
                          />
                        </Col>
                        <Col className="mb-3" xs={12} md={6}>
                          <Field
                            id="clientCodeEon"
                            name="clientCodeEon"
                            placeholder="Cod client"
                            render={() => (
                              <Input
                                label="Cod client"
                                mbZero
                                onBlur={() => {
                                  props.setFieldTouched('clientCodeEon');
                                }}
                                onChange={(e: any) => {
                                  e.target.value.length !== 0 && props.setFieldValue('clientCodeEon', e.target.value);
                                  e.target.value.length === 0 && props.setFieldValue('clientCodeEon', '');
                                }}
                              />
                            )}
                          />
                          <ErrorMessage
                            name="clientCodeEon"
                            render={(msg) => <div style={{ color: 'red', fontWeight: 'bold' }}>{msg}</div>}
                          />
                        </Col>
                      </Row>
                      {verifyError !== '' && <div style={{ color: '#ea1c0a', fontWeight: 'bold' }}>{verifyError}</div>}

                      <div className="mb-4 mt-2 buttons-container d-flex justify-content-center justify-content-sm-start">
                        <Button type="submit">Verificare date</Button>
                      </div>
                    </div>
                  </div>
                </Form>
              </Fragment>
            );
          }}
        </Formik>
      </Fragment>
    );
  };

  const renderAddLicenseForm = (licenseType: any) => {
    const furnDet = (licenseType == 1 ? verifiedSupplierEE : verifiedSupplierGN) as FormikValues;
    return (
      <Fragment>
        <Formik enableReinitialize initialValues={furnDet} onSubmit={(values) => submitAddLicenseForm(values, licenseType)}>
          {(props: any) => {
            return (
              <Fragment>
                <Form>
                  <div className="form-container edit-account-furnizor">
                    <div className="mt-3 d-flex flex-column">
                      <h4 className="register-section-title">
                        {licenseType === 1 ? 'Date furnizare energie electrică' : 'Date furnizare gaz natural'}
                      </h4>
                      <Row className="mt-3">
                        <Col className="mb-3" xs={12} md={6}>
                          <Field
                            id="licenseNumber"
                            name="licenseNumber"
                            placeholder="Număr licență"
                            disabled
                            render={() => <Input label="Număr licență" mbZero disabled value={props.values['licenseNumber']} />}
                          />
                          <ErrorMessage
                            name="licenseNumber"
                            render={(msg) => <div style={{ color: 'red', fontWeight: 'bold' }}>{msg}</div>}
                          />
                        </Col>
                        <Col className="mb-3" xs={12} md={6}>
                          <Field
                            id="clientCodeEon"
                            name="clientCodeEon"
                            placeholder="Cod client"
                            disabled
                            render={() => <Input label="Cod client" mbZero disabled value={props.values['clientCodeEon']} />}
                          />
                          <ErrorMessage
                            name="clientCodeEon"
                            render={(msg) => <div style={{ color: 'red', fontWeight: 'bold' }}>{msg}</div>}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12} md={12}>
                          <div>
                            <span>Perioadă contractuală:&nbsp;</span>
                            <span className="startDate">
                              {
                                // @ts-ignore
                                moment(furnDet.licenseStartDate).format('DD/MM/YYYY')
                              }
                            </span>{' '}
                            -
                            <span className="endDate">
                              {
                                // @ts-ignore
                                moment(furnDet.licenseEndDate).format('DD/MM/YYYY')
                              }
                            </span>
                          </div>
                        </Col>
                      </Row>
                    </div>
                    <div className="mt-4 mb-4 d-flex flex-column">
                      <h4 className="register-section-title">Date de identificare</h4>
                      <Row className="mt-3">
                        <Col className="mb-3" xs={12} md={6}>
                          <Field
                            id="name"
                            name="name"
                            placeholder="Denumire"
                            disabled
                            render={() => <Input label="Denumire" mbZero disabled value={props.values['name']} />}
                          />
                          <ErrorMessage name="name" render={(msg) => <div style={{ color: 'red', fontWeight: 'bold' }}>{msg}</div>} />
                        </Col>
                        <Col className="mb-3" xs={12} md={6}>
                          <Field
                            id="fixPhone"
                            name="fixPhone"
                            placeholder="Telefon fix"
                            disabled
                            render={() => <Input label="Telefon fix" mbZero disabled value={props.values['fixPhone']} />}
                          />
                          <ErrorMessage name="fixPhone" render={(msg) => <div style={{ color: 'red', fontWeight: 'bold' }}>{msg}</div>} />
                        </Col>
                      </Row>
                      <Row>
                        <Col className="mb-3" xs={12} md={6}>
                          <Field
                            id="phone"
                            name="phone"
                            placeholder="Telefon mobil"
                            disabled
                            render={() => <Input label="Telefon mobil" mbZero disabled value={props.values['phone']} />}
                          />
                          <ErrorMessage name="phone" render={(msg) => <div style={{ color: 'red', fontWeight: 'bold' }}>{msg}</div>} />
                        </Col>
                        <Col className="mb-3" xs={12} md={6}>
                          <Field
                            id="regComertului"
                            name="regComertului"
                            placeholder="Registrul Comertului"
                            disabled
                            render={() => <Input label="Registrul Comertului" mbZero disabled value={props.values['regComertului']} />}
                          />
                          <ErrorMessage
                            name="regComertului"
                            render={(msg) => <div style={{ color: 'red', fontWeight: 'bold' }}>{msg}</div>}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col className="mb-3" xs={12} md={6}>
                          <Field
                            id="cui"
                            name="cui"
                            placeholder="CIF/CUI"
                            disabled
                            render={() => <Input label="CIF/CUI" mbZero disabled value={props.values['cui']} />}
                          />
                          <ErrorMessage name="cui" render={(msg) => <div style={{ color: 'red', fontWeight: 'bold' }}>{msg}</div>} />
                        </Col>
                        <Col className="mb-3" xs={12} md={6}>
                          <Field
                            id="iban"
                            name="iban"
                            placeholder="IBAN"
                            disabled
                            render={() => <Input label="IBAN" mbZero disabled value={props.values['iban']} />}
                          />
                          <ErrorMessage name="iban" render={(msg) => <div style={{ color: 'red', fontWeight: 'bold' }}>{msg}</div>} />
                        </Col>
                      </Row>
                      <Row>
                        <Col className="mb-3" xs={12} md={6}>
                          <Field
                            id="bank"
                            name="bank"
                            placeholder="Banca"
                            disabled
                            render={() => <Input label="Banca" mbZero disabled value={props.values['bank']} />}
                          />
                          <ErrorMessage name="bank" render={(msg) => <div style={{ color: 'red', fontWeight: 'bold' }}>{msg}</div>} />
                        </Col>
                      </Row>
                    </div>

                    <div className="mt-4 mb-4 d-flex flex-column">
                      <h4 className="register-section-title">Reprezentant legal</h4>
                      <Row className="mt-3">
                        <Col className="mb-3" xs={12} md={6}>
                          <Field
                            id="lastName"
                            name="lastName"
                            placeholder="Nume"
                            disabled
                            render={() => <Input label="Nume" mbZero disabled value={props.values['lastName']} />}
                          />
                          <ErrorMessage name="lastName" render={(msg) => <div style={{ color: 'red', fontWeight: 'bold' }}>{msg}</div>} />
                        </Col>
                        <Col className="mb-3" xs={12} md={6}>
                          <Field
                            id="firstName"
                            name="firstName"
                            placeholder="Prenume"
                            disabled
                            render={() => <Input label="Prenume" mbZero disabled value={props.values['firstName']} />}
                          />
                          <ErrorMessage name="firstName" render={(msg) => <div style={{ color: 'red', fontWeight: 'bold' }}>{msg}</div>} />
                        </Col>
                      </Row>
                    </div>

                    <div className="mt-4 mb-4 d-flex flex-column">
                      <h4 className="register-section-title">Adresa sediu social</h4>
                      <Row className="mt-3">
                        <Col className="mb-3" xs={12} md={6}>
                          <Field
                            id="county"
                            name="county"
                            placeholder="Judet"
                            disabled
                            render={() => <Input label="Judet" mbZero disabled value={props.values['county']} />}
                          />
                          <ErrorMessage name="county" render={(msg) => <div style={{ color: 'red', fontWeight: 'bold' }}>{msg}</div>} />
                        </Col>
                        <Col className="mb-3" xs={12} md={6}>
                          <Field
                            id="localitate"
                            name="localitate"
                            placeholder="Localitate"
                            disabled
                            render={() => <Input label="Localitate" mbZero disabled value={props.values['localitate']} />}
                          />
                          <ErrorMessage name="localitate" render={(msg) => <div style={{ color: 'red', fontWeight: 'bold' }}>{msg}</div>} />
                        </Col>
                      </Row>
                      <Row>
                        <Col className="mb-3" xs={12} md={6}>
                          <Field
                            id="street"
                            name="street"
                            placeholder="Strada"
                            disabled
                            render={() => <Input label="Strada" mbZero disabled value={props.values['street']} />}
                          />
                          <ErrorMessage name="street" render={(msg) => <div style={{ color: 'red', fontWeight: 'bold' }}>{msg}</div>} />
                        </Col>
                        <Col className="mb-3" xs={12} md={6}>
                          <Field
                            id="streetNo"
                            name="streetNo"
                            placeholder="Nr."
                            disabled
                            render={() => <Input label="Nr." mbZero disabled value={props.values['streetNo']} />}
                          />
                          <ErrorMessage name="streetNo" render={(msg) => <div style={{ color: 'red', fontWeight: 'bold' }}>{msg}</div>} />
                        </Col>
                      </Row>
                      <Row>
                        <Col className="mb-3" xs={12} md={6}>
                          <Field
                            id="apartment"
                            name="apartment"
                            placeholder="Apartament"
                            disabled
                            render={() => <Input label="Apartament" mbZero disabled value={props.values['apartment']} />}
                          />
                          <ErrorMessage name="apartment" render={(msg) => <div style={{ color: 'red', fontWeight: 'bold' }}>{msg}</div>} />
                        </Col>
                        <Col className="mb-3" xs={12} md={6}>
                          <Field
                            id="floor"
                            name="floor"
                            placeholder="Etaj"
                            disabled
                            render={() => <Input label="Etaj" mbZero disabled value={props.values['floor']} />}
                          />
                          <ErrorMessage name="floor" render={(msg) => <div style={{ color: 'red', fontWeight: 'bold' }}>{msg}</div>} />
                        </Col>
                      </Row>
                      <Row>
                        <Col className="mb-3" xs={12} md={6}>
                          <Field
                            id="postalCode"
                            name="postalCode"
                            placeholder="Cod postal"
                            disabled
                            render={() => <Input label="Cod postal" mbZero disabled value={props.values['postalCode']} />}
                          />
                          <ErrorMessage name="postalCode" render={(msg) => <div style={{ color: 'red', fontWeight: 'bold' }}>{msg}</div>} />
                        </Col>
                        <Col className="mb-3" xs={12} md={6}>
                          <Field
                            id="fax"
                            name="fax"
                            placeholder="Fax"
                            disabled
                            render={() => <Input label="Fax" mbZero disabled value={props.values['fax']} />}
                          />
                          <ErrorMessage name="fax" render={(msg) => <div style={{ color: 'red', fontWeight: 'bold' }}>{msg}</div>} />
                        </Col>
                      </Row>
                    </div>

                    <div className="mt-4 mb-4 d-flex flex-column">
                      <h4 className="register-section-title">Adresa de corespondenta</h4>
                      <Row className="mt-3">
                        <Col className="mb-3" xs={12} md={6}>
                          <Field
                            id="corespCounty"
                            name="corespCounty"
                            placeholder="Judet"
                            disabled
                            render={() => <Input label="Judet" mbZero disabled value={props.values['corespCounty']} />}
                          />
                          <ErrorMessage
                            name="corespCounty"
                            render={(msg) => <div style={{ color: 'red', fontWeight: 'bold' }}>{msg}</div>}
                          />
                        </Col>
                        <Col className="mb-3" xs={12} md={6}>
                          <Field
                            id="corespLocalitate"
                            name="corespLocalitate"
                            placeholder="Localitate"
                            disabled
                            render={() => <Input label="Localitate" mbZero disabled value={props.values['corespLocalitate']} />}
                          />
                          <ErrorMessage
                            name="corespLocalitate"
                            render={(msg) => <div style={{ color: 'red', fontWeight: 'bold' }}>{msg}</div>}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col className="mb-3" xs={12} md={6}>
                          <Field
                            id="corespStreet"
                            name="corespStreet"
                            placeholder="Strada"
                            disabled
                            render={() => <Input label="Strada" mbZero disabled value={props.values['corespStreet']} />}
                          />
                          <ErrorMessage
                            name="corespStreet"
                            render={(msg) => <div style={{ color: 'red', fontWeight: 'bold' }}>{msg}</div>}
                          />
                        </Col>
                        <Col className="mb-3" xs={12} md={6}>
                          <Field
                            id="corespStreetNo"
                            name="corespStreetNo"
                            placeholder="Nr."
                            disabled
                            render={() => <Input label="Nr." mbZero disabled value={props.values['corespStreetNo']} />}
                          />
                          <ErrorMessage
                            name="corespStreetNo"
                            render={(msg) => <div style={{ color: 'red', fontWeight: 'bold' }}>{msg}</div>}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col className="mb-3" xs={12} md={6}>
                          <Field
                            id="corespApartment"
                            name="corespApartment"
                            placeholder="Apartament"
                            disabled
                            render={() => <Input label="Apartament" mbZero disabled value={props.values['corespApartment']} />}
                          />
                          <ErrorMessage name="apartment" render={(msg) => <div style={{ color: 'red', fontWeight: 'bold' }}>{msg}</div>} />
                        </Col>
                        <Col className="mb-3" xs={12} md={6}>
                          <Field
                            id="corespFloor"
                            name="corespFloor"
                            placeholder="Etaj"
                            disabled
                            render={() => <Input label="Etaj" mbZero disabled value={props.values['corespFloor']} />}
                          />
                          <ErrorMessage
                            name="corespFloor"
                            render={(msg) => <div style={{ color: 'red', fontWeight: 'bold' }}>{msg}</div>}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col className="mb-3" xs={12} md={6}>
                          <Field
                            id="corespPostalCode"
                            name="corespPostalCode"
                            placeholder="Cod postal"
                            disabled
                            render={() => <Input label="Cod postal" mbZero disabled value={props.values['corespPostalCode']} />}
                          />
                          <ErrorMessage
                            name="corespPostalCode"
                            render={(msg) => <div style={{ color: 'red', fontWeight: 'bold' }}>{msg}</div>}
                          />
                        </Col>
                        <Col className="mb-3" xs={12} md={6}>
                          <Field
                            id="fax"
                            name="fax"
                            placeholder="Fax"
                            disabled
                            render={() => <Input label="Fax" mbZero disabled value={props.values['fax']} />}
                          />
                          <ErrorMessage name="fax" render={(msg) => <div style={{ color: 'red', fontWeight: 'bold' }}>{msg}</div>} />
                        </Col>
                      </Row>
                    </div>
                    <div className="d-flex flex-column">
                      <h4 className="register-section-title">Date personale cont</h4>
                      <Row className="mt-2">
                        <Col className="mb-3" sm={12} md={6}>
                          <Field
                            name="email"
                            placeholder="Email"
                            disabled
                            render={() => <Input label="Email" mbZero disabled value={props.values['email']} />}
                          />
                          <ErrorMessage name="email" render={(msg) => <div style={{ color: '#ea1c0a', fontWeight: 'bold' }}>{msg}</div>} />
                        </Col>
                      </Row>
                    </div>

                    {addLicenseError !== '' && <div style={{ color: '#ea1c0a', fontWeight: 'bold' }}>{addLicenseError}</div>}
                    <div className="mb-4 mt-2 buttons-container d-flex justify-content-center justify-content-sm-start">
                      <Button type="submit">Adaugă licența</Button>
                      <Button
                        style={{ marginLeft: '8px' }}
                        type="button"
                        onClick={() => {
                          if (licenseType == 1) {
                            setWasEEDataVerified(false);
                          } else {
                            setWasGNDataVerified(false);
                          }
                        }}
                      >
                        Inapoi
                      </Button>
                    </div>
                  </div>
                </Form>
              </Fragment>
            );
          }}
        </Formik>
      </Fragment>
    );
  };

  const renderEditForm = (furnDet: any, licenseType: any) => {
    return (
      <Fragment>
        <Formik enableReinitialize initialValues={furnDet || null} onSubmit={(values) => submit(values)} validationSchema={EditSchemaFurn}>
          {(props) => {
            return (
              <Fragment>
                <Form>
                  <div className="form-container edit-account-furnizor">
                    <div className="mt-3 d-flex flex-column">
                      <h4 className="register-section-title">
                        {licenseType === 1 ? 'Date furnizare energie electrică' : 'Date furnizare gaz natural'}
                      </h4>
                      <Row className="mt-3">
                        <Col className="mb-3" xs={12} md={6}>
                          <Field
                            id="licenseNumber"
                            name="licenseNumber"
                            placeholder="Număr licență"
                            disabled
                            render={() => <Input label="Număr licență" mbZero disabled value={props.values['licenseNumber']} />}
                          />
                          <ErrorMessage
                            name="licenseNumber"
                            render={(msg) => <div style={{ color: 'red', fontWeight: 'bold' }}>{msg}</div>}
                          />
                        </Col>
                        <Col className="mb-3" xs={12} md={6}>
                          <Field
                            id="clientCodeEon"
                            name="clientCodeEon"
                            placeholder="Cod client"
                            disabled
                            render={() => <Input label="Cod client" mbZero disabled value={props.values['clientCodeEon']} />}
                          />
                          <ErrorMessage
                            name="clientCodeEon"
                            render={(msg) => <div style={{ color: 'red', fontWeight: 'bold' }}>{msg}</div>}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12} md={12}>
                          <div>
                            <span>Perioadă contractuală:&nbsp;</span>
                            <span className="startDate">
                              {
                                // @ts-ignore
                                moment(furnDet.licenseStartDate).format('DD/MM/YYYY')
                              }
                            </span>{' '}
                            -
                            <span className="endDate">
                              {
                                // @ts-ignore
                                moment(furnDet.licenseEndDate).format('DD/MM/YYYY')
                              }
                            </span>
                          </div>
                        </Col>
                      </Row>
                    </div>
                    <div className="mt-4 mb-4 d-flex flex-column">
                      <h4 className="register-section-title">Date de identificare</h4>
                      <Row className="mt-3">
                        <Col className="mb-3" xs={12} md={6}>
                          <Field
                            id="name"
                            name="name"
                            placeholder="Denumire"
                            disabled
                            render={() => <Input label="Denumire" mbZero disabled value={props.values['name']} />}
                          />
                          <ErrorMessage name="name" render={(msg) => <div style={{ color: 'red', fontWeight: 'bold' }}>{msg}</div>} />
                        </Col>
                        <Col className="mb-3" xs={12} md={6}>
                          <Field
                            id="fixPhone"
                            name="fixPhone"
                            placeholder="Telefon fix"
                            disabled
                            render={() => <Input label="Telefon fix" mbZero disabled value={props.values['fixPhone']} />}
                          />
                          <ErrorMessage name="fixPhone" render={(msg) => <div style={{ color: 'red', fontWeight: 'bold' }}>{msg}</div>} />
                        </Col>
                      </Row>
                      <Row>
                        <Col className="mb-3" xs={12} md={6}>
                          <Field
                            id="phone"
                            name="phone"
                            placeholder="Telefon mobil"
                            disabled
                            render={() => <Input label="Telefon mobil" mbZero disabled value={props.values['phone']} />}
                          />
                          <ErrorMessage name="phone" render={(msg) => <div style={{ color: 'red', fontWeight: 'bold' }}>{msg}</div>} />
                        </Col>
                        <Col className="mb-3" xs={12} md={6}>
                          <Field
                            id="regComertului"
                            name="regComertului"
                            placeholder="Registrul Comertului"
                            disabled
                            render={() => <Input label="Registrul Comertului" mbZero disabled value={props.values['regComertului']} />}
                          />
                          <ErrorMessage
                            name="regComertului"
                            render={(msg) => <div style={{ color: 'red', fontWeight: 'bold' }}>{msg}</div>}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col className="mb-3" xs={12} md={6}>
                          <Field
                            id="cui"
                            name="cui"
                            placeholder="CIF/CUI"
                            disabled
                            render={() => <Input label="CIF/CUI" mbZero disabled value={props.values['cui']} />}
                          />
                          <ErrorMessage name="cui" render={(msg) => <div style={{ color: 'red', fontWeight: 'bold' }}>{msg}</div>} />
                        </Col>
                        <Col className="mb-3" xs={12} md={6}>
                          <Field
                            id="iban"
                            name="iban"
                            placeholder="IBAN"
                            disabled
                            render={() => <Input label="IBAN" mbZero disabled value={props.values['iban']} />}
                          />
                          <ErrorMessage name="iban" render={(msg) => <div style={{ color: 'red', fontWeight: 'bold' }}>{msg}</div>} />
                        </Col>
                      </Row>
                      <Row>
                        <Col className="mb-3" xs={12} md={6}>
                          <Field
                            id="bank"
                            name="bank"
                            placeholder="Banca"
                            disabled
                            render={() => <Input label="Banca" mbZero disabled value={props.values['bank']} />}
                          />
                          <ErrorMessage name="bank" render={(msg) => <div style={{ color: 'red', fontWeight: 'bold' }}>{msg}</div>} />
                        </Col>
                      </Row>
                    </div>

                    <div className="mt-4 mb-4 d-flex flex-column">
                      <h4 className="register-section-title">Reprezentant legal</h4>
                      <Row className="mt-3">
                        <Col className="mb-3" xs={12} md={6}>
                          <Field
                            id="lastName"
                            name="lastName"
                            placeholder="Nume"
                            disabled
                            render={() => <Input label="Nume" mbZero disabled value={props.values['lastName']} />}
                          />
                          <ErrorMessage name="lastName" render={(msg) => <div style={{ color: 'red', fontWeight: 'bold' }}>{msg}</div>} />
                        </Col>
                        <Col className="mb-3" xs={12} md={6}>
                          <Field
                            id="firstName"
                            name="firstName"
                            placeholder="Prenume"
                            disabled
                            render={() => <Input label="Prenume" mbZero disabled value={props.values['firstName']} />}
                          />
                          <ErrorMessage name="firstName" render={(msg) => <div style={{ color: 'red', fontWeight: 'bold' }}>{msg}</div>} />
                        </Col>
                      </Row>
                    </div>

                    <div className="mt-4 mb-4 d-flex flex-column">
                      <h4 className="register-section-title">Adresa sediu social</h4>
                      <Row className="mt-3">
                        <Col className="mb-3" xs={12} md={6}>
                          <Field
                            id="county"
                            name="county"
                            placeholder="Judet"
                            disabled
                            render={() => <Input label="Judet" mbZero disabled value={props.values['county']} />}
                          />
                          <ErrorMessage name="county" render={(msg) => <div style={{ color: 'red', fontWeight: 'bold' }}>{msg}</div>} />
                        </Col>
                        <Col className="mb-3" xs={12} md={6}>
                          <Field
                            id="localitate"
                            name="localitate"
                            placeholder="Localitate"
                            disabled
                            render={() => <Input label="Localitate" mbZero disabled value={props.values['localitate']} />}
                          />
                          <ErrorMessage name="localitate" render={(msg) => <div style={{ color: 'red', fontWeight: 'bold' }}>{msg}</div>} />
                        </Col>
                      </Row>
                      <Row>
                        <Col className="mb-3" xs={12} md={6}>
                          <Field
                            id="street"
                            name="street"
                            placeholder="Strada"
                            disabled
                            render={() => <Input label="Strada" mbZero disabled value={props.values['street']} />}
                          />
                          <ErrorMessage name="street" render={(msg) => <div style={{ color: 'red', fontWeight: 'bold' }}>{msg}</div>} />
                        </Col>
                        <Col className="mb-3" xs={12} md={6}>
                          <Field
                            id="streetNo"
                            name="streetNo"
                            placeholder="Nr."
                            disabled
                            render={() => <Input label="Nr." mbZero disabled value={props.values['streetNo']} />}
                          />
                          <ErrorMessage name="streetNo" render={(msg) => <div style={{ color: 'red', fontWeight: 'bold' }}>{msg}</div>} />
                        </Col>
                      </Row>
                      <Row>
                        <Col className="mb-3" xs={12} md={6}>
                          <Field
                            id="apartment"
                            name="apartment"
                            placeholder="Apartament"
                            disabled
                            render={() => <Input label="Apartament" mbZero disabled value={props.values['apartment']} />}
                          />
                          <ErrorMessage name="apartment" render={(msg) => <div style={{ color: 'red', fontWeight: 'bold' }}>{msg}</div>} />
                        </Col>
                        <Col className="mb-3" xs={12} md={6}>
                          <Field
                            id="floor"
                            name="floor"
                            placeholder="Etaj"
                            disabled
                            render={() => <Input label="Etaj" mbZero disabled value={props.values['floor']} />}
                          />
                          <ErrorMessage name="floor" render={(msg) => <div style={{ color: 'red', fontWeight: 'bold' }}>{msg}</div>} />
                        </Col>
                      </Row>
                      <Row>
                        <Col className="mb-3" xs={12} md={6}>
                          <Field
                            id="postalCode"
                            name="postalCode"
                            placeholder="Cod postal"
                            disabled
                            render={() => <Input label="Cod postal" mbZero disabled value={props.values['postalCode']} />}
                          />
                          <ErrorMessage name="postalCode" render={(msg) => <div style={{ color: 'red', fontWeight: 'bold' }}>{msg}</div>} />
                        </Col>
                        <Col className="mb-3" xs={12} md={6}>
                          <Field
                            id="fax"
                            name="fax"
                            placeholder="Fax"
                            disabled
                            render={() => <Input label="Fax" mbZero disabled value={props.values['fax']} />}
                          />
                          <ErrorMessage name="fax" render={(msg) => <div style={{ color: 'red', fontWeight: 'bold' }}>{msg}</div>} />
                        </Col>
                      </Row>
                    </div>

                    <div className="mt-4 mb-4 d-flex flex-column">
                      <h4 className="register-section-title">Adresa de corespondenta</h4>
                      <Row className="mt-3">
                        <Col className="mb-3" xs={12} md={6}>
                          <Field
                            id="corespCounty"
                            name="corespCounty"
                            placeholder="Judet"
                            disabled
                            render={() => <Input label="Judet" mbZero disabled value={props.values['corespCounty']} />}
                          />
                          <ErrorMessage
                            name="corespCounty"
                            render={(msg) => <div style={{ color: 'red', fontWeight: 'bold' }}>{msg}</div>}
                          />
                        </Col>
                        <Col className="mb-3" xs={12} md={6}>
                          <Field
                            id="corespLocalitate"
                            name="corespLocalitate"
                            placeholder="Localitate"
                            disabled
                            render={() => <Input label="Localitate" mbZero disabled value={props.values['corespLocalitate']} />}
                          />
                          <ErrorMessage
                            name="corespLocalitate"
                            render={(msg) => <div style={{ color: 'red', fontWeight: 'bold' }}>{msg}</div>}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col className="mb-3" xs={12} md={6}>
                          <Field
                            id="corespStreet"
                            name="corespStreet"
                            placeholder="Strada"
                            disabled
                            render={() => <Input label="Strada" mbZero disabled value={props.values['corespStreet']} />}
                          />
                          <ErrorMessage
                            name="corespStreet"
                            render={(msg) => <div style={{ color: 'red', fontWeight: 'bold' }}>{msg}</div>}
                          />
                        </Col>
                        <Col className="mb-3" xs={12} md={6}>
                          <Field
                            id="corespStreetNo"
                            name="corespStreetNo"
                            placeholder="Nr."
                            disabled
                            render={() => <Input label="Nr." mbZero disabled value={props.values['corespStreetNo']} />}
                          />
                          <ErrorMessage
                            name="corespStreetNo"
                            render={(msg) => <div style={{ color: 'red', fontWeight: 'bold' }}>{msg}</div>}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col className="mb-3" xs={12} md={6}>
                          <Field
                            id="corespApartment"
                            name="corespApartment"
                            placeholder="Apartament"
                            disabled
                            render={() => <Input label="Apartament" mbZero disabled value={props.values['corespApartment']} />}
                          />
                          <ErrorMessage name="apartment" render={(msg) => <div style={{ color: 'red', fontWeight: 'bold' }}>{msg}</div>} />
                        </Col>
                        <Col className="mb-3" xs={12} md={6}>
                          <Field
                            id="corespFloor"
                            name="corespFloor"
                            placeholder="Etaj"
                            disabled
                            render={() => <Input label="Etaj" mbZero disabled value={props.values['corespFloor']} />}
                          />
                          <ErrorMessage
                            name="corespFloor"
                            render={(msg) => <div style={{ color: 'red', fontWeight: 'bold' }}>{msg}</div>}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col className="mb-3" xs={12} md={6}>
                          <Field
                            id="corespPostalCode"
                            name="corespPostalCode"
                            placeholder="Cod postal"
                            disabled
                            render={() => <Input label="Cod postal" mbZero disabled value={props.values['corespPostalCode']} />}
                          />
                          <ErrorMessage
                            name="corespPostalCode"
                            render={(msg) => <div style={{ color: 'red', fontWeight: 'bold' }}>{msg}</div>}
                          />
                        </Col>
                        <Col className="mb-3" xs={12} md={6}>
                          <Field
                            id="fax"
                            name="fax"
                            placeholder="Fax"
                            disabled
                            render={() => <Input label="Fax" mbZero disabled value={props.values['fax']} />}
                          />
                          <ErrorMessage name="fax" render={(msg) => <div style={{ color: 'red', fontWeight: 'bold' }}>{msg}</div>} />
                        </Col>
                      </Row>
                    </div>
                    <div className="d-flex flex-column">
                      <h4 className="register-section-title">Date personale cont</h4>
                      <Row className="mt-2">
                        <Col className="mb-3" sm={12} md={6}>
                          <Field
                            name="email"
                            placeholder="Email"
                            disabled
                            render={() => <Input label="Email" mbZero disabled value={props.values['email']} />}
                          />
                          <ErrorMessage name="email" render={(msg) => <div style={{ color: '#ea1c0a', fontWeight: 'bold' }}>{msg}</div>} />
                        </Col>
                        <Col className="mb-3" sm={12} md={6}>
                          <Field
                            id="emailConfirmation"
                            name="emailConfirmation"
                            placeholder="Confirmă Email"
                            disabled
                            render={() => <Input label="Confirmă Email" mbZero disabled value={props.values['emailConfirmation']} />}
                          />
                          <ErrorMessage
                            name="emailConfirmation"
                            render={(msg) => <div style={{ color: '#ea1c0a', fontWeight: 'bold' }}>{msg}</div>}
                          />
                        </Col>
                      </Row>
                    </div>

                    <div className="d-flex flex-column mt-4">
                      <h4 className="register-section-title">Canale de comunicare pentru modificările de pe cont</h4>
                      <Row className="mb-2">
                        <Row sm={12} className="d-flex">
                          <Col xs={4} className="d-flex align-items-center checkbox-container">
                            <Field type="checkbox" name="emailNotifications" className="create-account-checkbox" />
                            <span className="checkbox-span">Email</span>
                          </Col>
                          <Col xs={4} className="d-flex align-items-center checkbox-container">
                            <Field type="checkbox" name="smsNotifications" className="create-account-checkbox" />
                            <span className="checkbox-span">SMS</span>
                          </Col>
                        </Row>
                      </Row>
                    </div>

                    {errorMessage !== '' && <div style={{ color: 'red', fontWeight: 'bold' }}>{errorMessage}</div>}
                    <div className="mb-4 mt-1 buttons-container d-flex justify-content-center justify-content-sm-start">
                      <Button type="submit">Salvează</Button>
                      <Button style={{ marginLeft: '8px' }} onClick={() => setIsReqPasswordChange(true)}>
                        Schimbă parola
                      </Button>
                      <Button style={{ marginLeft: '8px' }} onClick={() => setShowChangeEmailForm(true)}>
                        Modifică e-mail
                      </Button>
                    </div>
                  </div>
                </Form>
              </Fragment>
            );
          }}
        </Formik>
      </Fragment>
    );
  };

  const renderTabs = () => {
    return (
      <div>
        {showChangeEmailForm ? (
          <ChangeEmailGenerateEmail setShowChangeEmailForm={setShowChangeEmailForm} />
        ) : (
          <>
            <Tabs className="security-tabs" mountOnEnter={true} onSelect={handleSelect}>
              <Tab eventKey="ee" title={'Furnizor Energie Electrica'}>
                {renderTabInfo(1)}
                {Object.keys(details).length !== 0 &&
                  !isReqPasswordChange &&
                  furnEEDetails &&
                  furnEEDetails.legacyId &&
                  renderEditForm(furnEEDetails, 1)}
                {furnEEDetails && !furnEEDetails.legacyId && !wasEEDataVerified && renderValidateLicenseForm(1)}
                {furnEEDetails && !furnEEDetails.legacyId && wasEEDataVerified && renderAddLicenseForm(1)}
                {isReqPasswordChange && <ChangePasswordFromEditPage setIsReqPasswordChange={setIsReqPasswordChange} />}
              </Tab>

              <Tab eventKey="gn" title={'Furnizor Gaze Naturale'}>
                {renderTabInfo(2)}
                {Object.keys(details).length !== 0 &&
                  !isReqPasswordChange &&
                  furnGNDetails &&
                  furnGNDetails.legacyId &&
                  renderEditForm(furnGNDetails, 2)}
                {furnGNDetails && !furnGNDetails.legacyId && !wasGNDataVerified && renderValidateLicenseForm(2)}
                {furnGNDetails && !furnGNDetails.legacyId && wasGNDataVerified && renderAddLicenseForm(2)}
                {isReqPasswordChange && <ChangePasswordFromEditPage setIsReqPasswordChange={setIsReqPasswordChange} />}
              </Tab>
            </Tabs>
          </>
        )}
      </div>
    );
  };

  const renderTabInfo = (utility: any) => {
    const email = utility === 1 ? 'contractedistributie.el@delgaz-grid.ro' : 'contractegaz@delgaz-grid.ro';

    return (
      <div className="m-3 p-3 me-4 bg-eon-lightgrey d-flex flex-column">
        <p className="m-0">Date contact Back Office Serviciul Managementul Contractelor DELGAZ GRID S.A.</p>
        <p className="m-0">
          Email:{' '}
          <a className="red" href={`mailto:${email}`}>
            {email}
          </a>
        </p>
      </div>
    );
  };

  return (
    <div className="edit-pf">
      {renderTabs()}
      <ModalConfirm showModal={showModalLicenseAddSoucces} modalTitle={'Succes'} onClose={handleCloseModalLicenseAddSoucces}>
        <p>Licența a fost adăugată cu succes.</p>
      </ModalConfirm>
    </div>
  );
};
export default Furnizor;
