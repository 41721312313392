import { useRef, useState } from 'react';
import SearchInput from './SearchInput';

export type SelectProps = {
  className?: string;
  placeholder?: string;
  isMobile?: boolean;
  isLoggedIn?: boolean;
};

const ContentSearch = (props: SelectProps) => {
  const [searchInputValue, setSearchInputValue] = useState<any>('');
  const formRef = useRef<HTMLFormElement>(null);

  return (
    <form
      style={{ marginTop: '3px' }}
      method="POST"
      action="/Search"
      className={`${props.className ? props.className : ''}`}
      onSubmit={() => {}}
      ref={formRef}
    >
      <SearchInput
        placeholder={props.placeholder}
        setSearchInputValue={setSearchInputValue}
        searchInputValue={searchInputValue}
        onSearch={() => {
          if (formRef && formRef.current) {
            formRef.current.submit();
          }
        }}
        collapsed={props.isLoggedIn}
        isMobile={props.isMobile}
      />
      <input id="hdnFormPageIndex" name="hdnFormPageIndex" type="hidden" value="1" />
      <input id="hdnFormPageSize" name="hdnFormPageSize" type="hidden" value="10" />
    </form>
  );
};

export default ContentSearch;
