export const IANUARIE = 'Ianuarie';
export const FEBRUARIE = 'Februarie';
export const MARTIE = 'Martie';
export const APRILIE = 'Aprilie';
export const MAI = 'Mai';
export const IUNIE = 'Iunie';
export const IULIE = 'Iulie';
export const AUGUST = 'August';
export const SEPTEMBRIE = 'Septembrie';
export const OCTOMBRIE = 'Octombrie';
export const NOIEMBRIE = 'Noiembrie';
export const DECEMBRIE = 'Decembrie';

export const MONTHS = [
  { id: '01', value: 'Ianuarie' },
  { id: '02', value: 'Februarie' },
  { id: '03', value: 'Martie' },
  { id: '04', value: 'Aprilie' },
  { id: '05', value: 'Mai' },
  { id: '06', value: 'Iunie' },
  { id: '07', value: 'Iulie' },
  { id: '08', value: 'August' },
  { id: '09', value: 'Septembrie' },
  { id: '10', value: 'Octombrie' },
  { id: '11', value: 'Noiembrie' },
  { id: '12', value: 'Decembrie' }
];
