import React from 'react';
import { DelgazWrapper } from '@components/global/DelgazWrapper';
import PublicRelationsCenterMap from '@components/publicRelationsCenterMap/PublicRelationsCenterMap';

export const PublicRelationsCenterMapComponent = (props: any): JSX.Element => {
  return (
    <DelgazWrapper>
      <PublicRelationsCenterMap {...props} />
    </DelgazWrapper>
  );
};
