import React, { useState, useEffect, Fragment } from 'react';
import { toggleLoader } from '@actions/LoaderActions';
import { CustomGet, QueryParams } from '@api/backOffice/fetchResults';
import { getTimelineStepsEE, getTimelineStepsGN } from '@api/timeline';
import ProgressBar from '@components/common/ProgressBar/ProgressBar';
import { BusinessCardComponent } from '@components/dev/Components/BusinessCard/BusinessCardComponent';
import PayInvoices from '@components/invoices/PayInvoices';
import { UTILITY_EE, UTILITY_GN } from '@constants/Utility';
import { faBolt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Col, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import * as notification from '@lib/Notification';
import UploadDocumentsTimeline from './components/UploadDocuments';
import DocumentsTableTimeline from './components/DocumentsTableTimeline';
import CancelClientRequest from './components/CancelClientRequest';
import SendReclamation from './components/SendReclamation';
import {
  PROCESS_STATUS_ANULAT,
  PROCESS_STATUS_FINALIZAT,
  PROCESS_STATUS_INCETARE_PROCES,
  PROCESS_STATUS_RENUNTARE_CLIENT
} from '@constants/iConnect/ProcessStatus';
import gnIcon from '../assets/resources/img/events-icons/gn-icon.svg';

type RequestType = {
  consumptionPlaceName: string;
  requestReasonType: string;
  requestNO: string;
  requestId: number;
  requestPortalID: string;
  requestDate: string;
  requestType: number;
  neededPower: string;
  requestConnectionType: string;
  consumptionPlaceClientName: string;
  requestAnswer: string;
  firstName: string;
  lastName: string;
  displayPortal: boolean;
  buildingAddress: {
    countyName: string;
    localityName: string;
    countyCode: string;
    localityCode: number;
    villageName: string;
    streetName: string;
    streetNumber: string;
    building: string;
    entraceNumber: string;
    apartment: string;
    supl: string;
    floor: string;
  };
};

type TimelineComponentProps = {
  isBo?: boolean;
  requestId?: string;
  boBasePath?: string;
  utility?: string;
  processStatus?: string;
};

type InvoiceType = {
  id: number;
  idUser: number;
  idRequestEvent: number;
  idRequest: number;
  idRequestType: number;
  requestTypeCode: string;
  requestTypeName: string;
  invoiceNO: string;
  invoiceDate: string;
  dueDate: string;
  invoiceValue: number;
  paymentNO: string;
  paymentDate: string;
  paymentValue: number;
  invoiceLink: string;
  paymentResponseCode: number;
  sapProcessing: number;
  division: string;
  region: string;
  contract: string;
  statusId: number;
  dueAmount: number;
};

type EventType = {
  id: number;
  idRequest: number;
  idEvent: number;
  eventNr: string;
  eventDate: string;
  valabilDeLa: string;
  valabilPanaLa: string;
  requestNO: string;
  requestDate: string;
  eventName: string;
  eventTypeName: string;
  idEventType: number;
  executed: boolean;
  orderNo: number;
  uploadArea: boolean;
  displayPortal: boolean;
  eventDescription: string;
  documents: any;
  showNotificationDate: boolean;
  dateMR: {
    name: string;
    phoneNumber: string;
    emailAddress: string;
    jobPost: string;
    requestEventTypeId: number;
  };
  invoices: InvoiceType[];
  docTypes: any;
  showDownloadAllButton: boolean;
};

// const EVENT_TYPE_TARIF_RACORDARE = 12; //Emitere factură tarif racordare
// const EVENT_TYPE_AVIZ_TEHNIC_DE_RACORDARE = 2; //Emitere factură aviz tehnic de racordare

const TimelineComponent = (props: TimelineComponentProps) => {
  const [steps, setSteps] = useState<EventType[]>([]);
  const [request, setRequest] = useState<RequestType | null>(null);
  const [requestId, setRequestId] = useState<string>(props.requestId ?? '');
  const [eventsProgress, setEventsProgress] = useState(0);
  const [utility, setUtility] = useState<any>(0);
  const [processStatus, setProcessStatus] = useState<any>(null);
  const [showCancelButton, setShowCancelButton] = useState<any>(props.isBo ? false : true);
  const [showReclamationButton, setShowReclamationButton] = useState<any>(props.isBo ? false : true);
  const [toggleLoading, setToggleLoading] = useState<boolean>(false);

  const [error, setError] = useState('');

  const dispatch = useDispatch();

  useEffect(() => {
    if (request && !request.displayPortal && utility == UTILITY_GN) {
      setShowCancelButton(false);
    }
  }, [request, utility]);

  useEffect(() => {
    if (processStatus) {
      if (
        processStatus == PROCESS_STATUS_FINALIZAT ||
        processStatus == PROCESS_STATUS_ANULAT ||
        processStatus == PROCESS_STATUS_INCETARE_PROCES ||
        processStatus == PROCESS_STATUS_RENUNTARE_CLIENT
      ) {
        setShowCancelButton(false);
      }

      if (processStatus == PROCESS_STATUS_FINALIZAT) {
        setShowReclamationButton(false);
      }
    }
  }, [processStatus]);

  useEffect(() => {
    if (utility == UTILITY_EE && steps.length) {
      steps.forEach((step: EventType) => {
        //Emitere Aviz tehnic de racordare - Odata activat evenimentul de emitere ATR, nu se mai afiseaza butonul
        if (step.idEvent == 6 && step.executed) {
          setShowCancelButton(false);
        }
      });
    }
  }, [utility, steps]);

  useEffect(() => {
    let majorEventsCount = 0;
    let activeMajorEventsCount = 0;
    if (steps && steps.length > 0) {
      steps.forEach((step: any) => {
        if (step.eventTypeName === 'Major') {
          majorEventsCount = majorEventsCount + 1;
          if (step.executed) {
            activeMajorEventsCount = activeMajorEventsCount + 1;
          }
        }
      });
      let progress = (activeMajorEventsCount * 100) / majorEventsCount;
      setEventsProgress(progress);
    }
  }, [steps]);

  useEffect(() => {
    let requestId: string | any;
    let utility: string | any;
    let processStatus: string | any;
    if (props.isBo) {
      requestId = props.requestId;
      processStatus = props.processStatus;
      setUtility(props.utility);
    } else {
      const queryParams = new URLSearchParams(location.search);
      requestId = queryParams.get('requestId');
      utility = queryParams.get('utility');
      processStatus = queryParams.get('processStatus');
      if (!requestId || !utility) {
        setError('A apărut o eroare tehnică. Vă rugam reveniți mai târziu.');
      }
      setUtility(utility);
    }

    setProcessStatus(processStatus);
    setRequestId(requestId);
    getSteps(requestId, utility);
  }, []);

  const getSteps = async (requestId: string | any, utility: number) => {
    setError('');
    let res: any;
    dispatch(toggleLoader(true, 'TimelineComponent_getSteps'));
    if (props.isBo) {
      res = await CustomGet(`${props.boBasePath}/request_timeline?requestId=`, [
        { name: 'requestId', value: requestId }
      ] as QueryParams[]).catch((err) => {
        setError('A apărut o eroare tehnică. Vă rugam reveniți mai târziu.');
      });
      res = res.result;
    } else {
      if (utility == UTILITY_EE) {
        res = await getTimelineStepsEE({ requestId: requestId }).catch((err) => {
          setError('A apărut o eroare tehnică. Vă rugam reveniți mai târziu.');
        });
      } else if (utility == UTILITY_GN) {
        res = await getTimelineStepsGN({ requestId: requestId }).catch((err) => {
          setError('A apărut o eroare tehnică. Vă rugam reveniți mai târziu.');
        });
      }
    }
    if (res) {
      setSteps(res.events);
      setRequest(res.request);
    }
    dispatch(toggleLoader(false, 'TimelineComponent_getSteps'));
  };

  const handleUpdateDocumentsDms = async () => {
    dispatch(toggleLoader(true));

    let succes = false;
    await CustomGet(`${props.boBasePath}/update_doc_dms?requestId=${request?.requestId}`)
      .then((res) => {
        succes = true;
        dispatch(toggleLoader(false));
      })
      .catch((err) => {
        if (err.status == 200) {
          succes = true;
        }
        dispatch(toggleLoader(false));
      });

    if (succes) {
      notification.success('Fișierele au fost actualizate cu succes.');
    } else {
      notification.error('A apărut o eroare tehnică. Vă rugam reveniți mai târziu.');
    }
  };

  const getAddressConcatenated = () => {
    let res = '';
    if (!request?.buildingAddress) return res;

    if (request?.buildingAddress.countyName) {
      // let countyName = counties.find((c) => c.countyCode == request?.buildingAddress.Judet)?.countyName;
      res = res + request?.buildingAddress.countyName;
    }
    if (request?.buildingAddress.localityName) {
      res = res + ', ' + request?.buildingAddress.localityName;
    }
    if (request?.buildingAddress.streetName) {
      res = res + ', ' + request?.buildingAddress.streetName;
    }
    if (request?.buildingAddress.streetNumber) {
      res = res + ', nr. ' + request?.buildingAddress.streetNumber;
    }
    if (request?.buildingAddress.supl) {
      res = res + ', ' + request?.buildingAddress.supl;
    }
    if (request?.buildingAddress.building) {
      res = res + ', bloc/scara ' + request?.buildingAddress.building;
    }
    if (request?.buildingAddress.entraceNumber) {
      res = res + '/' + request?.buildingAddress.entraceNumber;
    }
    if (request?.buildingAddress.floor) {
      res = res + ', et. ' + request?.buildingAddress.floor;
    }
    if (request?.buildingAddress.apartment) {
      res = res + ', ap. ' + request?.buildingAddress.apartment;
    }
    return res;
  };

  const renderStep = (step: EventType, index: number) => {
    let displayStep = true;

    if (request && !request.displayPortal) {
      if (utility == UTILITY_GN) {
        displayStep = false;
      }
      // Deactivated for EE
      // else if (utility == UTILITY_EE && step.idEvent > 3) {
      //   displayStep = false;
      // }
    }

    if (!displayStep) return;

    let eventDate = step.eventDate ? step.eventDate.replaceAll('/', '.') : '';
    return (
      <div className="timeline-item" key={index}>
        {/* {step.eventTypeName == 'Major' && <div className={`dot ${!step.executed && 'soon'}`}></div>} */}
        <div className={`dot ${!step.executed ? 'soon' : ''}`}></div>
        <div className="date">{step.executed && eventDate ? eventDate : 'Eveniment viitor'}</div>

        <div className={`${!step.dateMR ? 'content' : ''}`}>
          <h5 className={`${step.dateMR ? 'timeline_conent_h3' : ''}`} style={{ marginLeft: '2rem' }}>
            <strong>{step.eventName}</strong>
          </h5>

          {step.executed && (
            <>
              {index == 0 && step.requestNO && (
                <p>
                  Număr cerere: <b>{step.requestNO}</b>
                </p>
              )}

              {step.eventDescription && (
                <h5
                  className="p-gray"
                  style={{ marginLeft: '2rem', fontSize: '1rem' }}
                  dangerouslySetInnerHTML={{ __html: step.eventDescription }}
                />
              )}

              {step.dateMR && (
                <div style={{ marginLeft: '2rem', marginRight: '2rem' }}>
                  <BusinessCardComponent
                    kentico={{
                      ...{
                        nume: step.dateMR.name,
                        functie: step.dateMR.jobPost,
                        telefon: step.dateMR.phoneNumber,
                        email: step.dateMR.emailAddress
                      },
                      margin: 0,
                      culoare: '#ea1b0a',
                      descriere: '',
                      adresa: ''
                    }}
                  />
                </div>
              )}

              {step.invoices && step.invoices.length > 0 && (
                <div style={{ marginLeft: '2rem', marginRight: '2rem' }}>
                  <PayInvoices
                    invoices={step.invoices}
                    isBo={props.isBo}
                    boBasePath={props.boBasePath}
                    utility={utility}
                    requestId={requestId}
                    fetchInvoices={false}
                    hideHeader
                  />
                </div>
              )}

              {step.uploadArea && (
                <div style={{ marginLeft: '2rem', marginRight: '2rem' }}>
                  <UploadDocumentsTimeline
                    idEvent={step.id}
                    documents={step.documents}
                    isBo={props.isBo}
                    utility={utility}
                    boBasePath={props.boBasePath}
                    requestId={requestId}
                    reloadTimeline={() => {
                      getSteps(requestId, utility);
                    }}
                    documentTypes={step.docTypes}
                    toggleLoading={(value: boolean) => setToggleLoading(value)}
                    isLoading={toggleLoading}
                  />
                </div>
              )}

              {step.documents && step.documents.length > 0 && (
                <div style={{ marginLeft: '2rem', marginRight: '2rem' }}>
                  <DocumentsTableTimeline
                    showDownloadAllButton={step.showDownloadAllButton}
                    showNotificationDate={step.showNotificationDate}
                    documents={step.documents}
                    isBo={props.isBo}
                    utility={utility}
                    boBasePath={props.boBasePath}
                    requestId={requestId}
                    eventId={step.idEvent}
                  />
                </div>
              )}
            </>
          )}
        </div>
      </div>
    );
  };

  return (
    <Fragment>
      {error && <div style={{ color: 'red', fontWeight: 'bold' }}>{error}</div>}

      <div className={`timeline ${props.isBo ? 'p-0' : ''}`}>
        <div className="timeline-items">
          {request && (
            <div className="timeline-item">
              <Row>
                <Col sm={12} md={8}>
                  <div className={'square'}>
                    {utility == UTILITY_EE ? <FontAwesomeIcon icon={faBolt} size="lg" /> : <img width={17.5} src={gnIcon} alt="gn-icon" />}
                  </div>
                  <div className="date">Obiectiv de racordare</div>

                  {request && (
                    <div className={'content'}>
                      <h3 className="mb-2">
                        <strong>{request.consumptionPlaceName}</strong> {request.consumptionPlaceClientName}
                      </h3>

                      <p className="p-gray mb-1">
                        Adresa: <strong className="black">{getAddressConcatenated()}</strong>
                      </p>

                      {utility == UTILITY_EE && request.neededPower != null && (
                        <p className="p-gray mb-1">
                          Putere solicitată: <strong className="black">{request.neededPower}</strong> kW
                        </p>
                      )}

                      {utility == UTILITY_EE && request.requestConnectionType && (
                        <p className="p-gray mb-1">
                          Tip bransament: <strong className="black">{request.requestConnectionType}</strong>
                        </p>
                      )}

                      {utility == UTILITY_GN && request.requestReasonType && (
                        <p className="p-gray mb-1">
                          Motiv racordare: <strong className="black">{request.requestReasonType}</strong>
                        </p>
                      )}

                      {utility == UTILITY_GN && request.requestAnswer && (
                        <p className="p-gray mb-1">
                          Răspuns așteptat: <strong className="black">{request.requestAnswer}</strong>
                        </p>
                      )}

                      {eventsProgress > 0 && (
                        <div style={{ marginLeft: '2rem' }}>
                          <ProgressBar progress={eventsProgress} />
                        </div>
                      )}
                      <p className="p-gray mb-1">
                        Număr cerere portal: <strong className="black">{request.requestPortalID}</strong>
                      </p>
                    </div>
                  )}
                </Col>
                <Col sm={12} md={4}>
                  <div className="d-flex flex-column mt-1" style={{ marginRight: '2rem', marginLeft: '2rem' }}>
                    {props.isBo && (
                      <Button className="btn-white mt-2" onClick={handleUpdateDocumentsDms}>
                        Actualizare fișiere DMS
                      </Button>
                    )}

                    {showCancelButton && (
                      <CancelClientRequest
                        className="btn-white mt-2"
                        requestId={requestId}
                        utility={utility}
                        isBo={props.isBo}
                        reloadTimeline={() => {
                          getSteps(requestId, utility);
                          setShowCancelButton(false);
                        }}
                      />
                    )}

                    {showReclamationButton && (
                      <SendReclamation
                        className="btn-white mt-2"
                        requestId={request.requestId}
                        utility={utility}
                        isBo={props.isBo}
                        reloadTimeline={() => {
                          getSteps(requestId, utility);
                        }}
                      />
                    )}

                    {/* <ChangeCommunicationChannel
                        className="btn-white mt-2 ms-2"
                        isBo={props.isBo}
                        requestId={request.requestId}
                        utility={utility}
                      />
                      \*/}
                  </div>
                </Col>
              </Row>
            </div>
          )}

          {steps &&
            steps.length > 0 &&
            steps.map((step: EventType, index: number) => {
              return renderStep(step, index);
            })}

          {request && !request.displayPortal && utility == UTILITY_GN && (
            <div className="timeline-item">
              <div className={'content'}>
                {utility == UTILITY_GN && (
                  <>
                    <p className="fw-bold" style={{ color: '#ea1b0a' }}>
                      În momentul de faţă, aplicaţia gestionează doar cereri pentru racordarea imobilelor prin branșament din conductele de
                      distribuție existente, cereri pentru extindere de conductă cu unul sau mai multe branșamente, cereri pentru instalații
                      de utilizare noi sau modificări ale instalațiilor existente. Solicitarea ta nu se încadrează în aceaste categorii, din
                      acest motiv, stadiul procesului tău de racordare nu poate fi afişat online.
                    </p>

                    <p className="fw-bold" style={{ color: '#ea1b0a' }}>
                      Această funcţionalitate se află în curs de dezvoltare. Te rugăm să păstrezi legătura cu reprezentantul Delgaz, care
                      îţi va oferi toate informaţiile şi suportul de care ai nevoie. Îţi mulţumim pentru înţelegere!
                    </p>
                  </>
                )}

                {/* Deactivated for EE */}
                {/* {utility == UTILITY_EE && (
                  <p className="fw-bold" style={{ color: '#ea1b0a' }}>
                    În momentul de faţă, aplicaţia gestionează doar cereri pentru racordarea unor imobile noi din reţelele existente de
                    joasă tensiune, iar solicitarea adresată de tine nu se încadrează în această categorie. Din acest motiv, stadiul
                    procesului tău de racordare nu poate fi afişat online. Această funcţionalitate se află în curs de dezvoltare. Te rugăm
                    să păstrezi legătura cu reprezentantul Delgaz, care îţi va oferi toate informaţiile şi suportul de care ai nevoie. Îţi
                    mulţumim pentru înţelegere!
                  </p>
                )} */}
              </div>
            </div>
          )}
        </div>
      </div>
    </Fragment>
  );
};

export default TimelineComponent;
