import { Dropdown, DropdownOptionType } from '@components/common/Dropdown';
import { faBolt, faFire, faFolderOpen } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect } from 'react';
import { Accordion, Button, Col, Row } from 'react-bootstrap';

const icons = [
    {
        id: 1,
        icon: faBolt
    },
    {
        id: 2,
        icon: faFire
    },
    {
        id: 3,
        icon: faFolderOpen
    }
];

const StepOne = (
    props: {
        categories: any,
        step: any,
        subjects: any,
        subSubjects: any,
        category: string,
        subject: string,
        subSubject: string,
        handleCategory: any,
        handleSubject: any,
        handleSubSubject: any,
        handleSubjects: any,
        handleSubSubjects: any,
        faqs: any,
        handleFaqs: any,
    }) => {
    // const [changed, setChanged] = useState(false);

    const {
        categories,
        step,
        category,
        subject,
        subSubject,
        handleCategory,
        handleSubject,
        handleSubSubject,
        subjects,
        subSubjects,
        handleSubjects,
        handleSubSubjects,
        faqs,
        handleFaqs
    } = props;

    // useEffect(() => {
    //     if (categories && categories.length > 0 && !changed) {
    //         let aux = categories[0];
    //         categories[0] = categories[1];
    //         categories[1] = aux;
    //         handleCategory(categories[0].categorySapValue);
    //         setChanged(true);
    //     }
    // }, [categories, step]);

    useEffect(() => {
        handleSubjects(mountSubjects);
        handleSubSubjects([]);
    }, [category]);

    useEffect(() => {
        handleSubSubjects(mountSubSubjects);
    }, [subject]);

    useEffect(() => {
        handleFaqs(mountFaqs);
    }, [subSubject]);

    const mountSubjects = () => {
        let subjects = [];

        subjects = categories.filter((i: any) => i.categorySapValue === category).map((c: any) => (
            c.subjects.map((item: any) => ({
                id: item.subjectSapValue,
                name: item.subject
            }))
        ));

        return subjects;
    };

    const mountSubSubjects = () => {
        let subSubjects: any[] = [];

        subSubjects = categories.filter((i: any) => i.categorySapValue === category);
        subSubjects = subSubjects.map((c: any) => c.subjects.filter((s: any) => s.subjectSapValue === subject).map((subj: any) => subj.subSubjects.map((subSubj: any) => ({
            id: subSubj.subSubjectSapValue,
            name: subSubj.subSubject
        }))));

        return subSubjects.flat(2);
    };

    const mountFaqs = () => {
        let faqs: any[] = [];

        categories.filter((i: any) => i.categorySapValue === category).map((c: any) => c.subjects.filter((s: any) => s.subjectSapValue === subject).map((subj: any) => subj.subSubjects.filter((subSubj: any) => subSubj.subSubjectSapValue === subSubject).map((ss: any) => {
            faqs = ss.faqs;
        })));

        handleFaqs(faqs.flat(1));
    };

    return (
        <div className="formular-contact-pasul-1">
            {categories && <div>
                <p>Alege una din categoriile de mai jos:</p>
                <Row className="categories m-4 justify-content-center">
                    {
                        categories.map((c: any, i: number) => (
                            <Col xs={12} sm={3} onClick={() => handleCategory(c.categorySapValue)} key={i}
                                 className={'category-container justify-content-center align-items-center text-center pointer'}>
                                <div className={`category ${category === c.categorySapValue ? 'active' : ''} p-4`}>
                                    <div>
                                        <FontAwesomeIcon icon={icons[i].icon} size={'3x'} className="icon"/>
                                    </div>
                                    <div className="mt-2">
                                        {c.category}
                                    </div>
                                </div>
                            </Col>
                        ))
                    }
                </Row>

                <div className="mb-4">
                    <Dropdown
                        label="Subiect"
                        defaultPlaceholder="Alege o optiune"
                        options={subjects[0] as DropdownOptionType[]}
                        onChange={(value) => handleSubject(value)}
                        value={subject}
                    />

                    <Dropdown
                        label="Motivul interacțiunii (subiectul detaliat)*"
                        defaultPlaceholder="Alege o optiune"
                        options={subSubjects as DropdownOptionType[]}
                        onChange={(value) => handleSubSubject(value)}
                        value={subSubject}
                    />
                </div>

                { faqs.length > 0 && <Row>
                    <p>Poți afla răspunsul de care ai nevoie în informațiile de mai jos:</p>
                    <Accordion defaultActiveKey="0" className="contact-form-accordion" alwaysOpen>
                        {faqs.map((faq: any, i: number) => (
                            <Accordion.Item eventKey={i.toString()} key={i}>
                                <Accordion.Header>
                                    {faq.question}
                                </Accordion.Header>
                                <Accordion.Body>
                                    <div dangerouslySetInnerHTML={{__html: faq.answer}} className="p-2"/>
                                </Accordion.Body>
                            </Accordion.Item>
                        ))}
                    </Accordion>
                </Row> }

                <Row className="mt-4">
                    <p className="m-0">Am adunat pentru tine cele mai frecvente întrebări și răspunsuri. Intră <a href="https://delgaz.ro/serviciul-de-informare/intrebari-frecvente" className="red pointer hover-red">aici</a> și află mai multe.</p>
                    <p>În cazul în care nu ai găsit răspunsul la subiectul tău, te rugăm să apeși butonul de mai jos:</p>
                </Row>

                <Row className="justify-content-center mt-4">
                    <Button className="w-auto" onClick={() => step(2)} disabled={category === '' || subject === '' || subSubject === ''}>Pasul următor</Button>
                </Row>
            </div>}
        </div>
    );
};

export default StepOne;