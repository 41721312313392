import React, { Fragment, useEffect, useState } from 'react';
import { GetLocalities } from '@api/geoApi';
import moment from 'moment';
import { Row, Col, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';

type VerifyDetailsProps = {
  item: any;
  hideDetailsPage: () => void;
};

const VerifyDetails = (props: VerifyDetailsProps) => {
  const [item, setItem] = useState<any>(null);
  const [localityName, setLocalityName] = useState<string>('');
  const [errorInvalidRequestStatus, setErrorInvalidRequestStatus] = useState('');

  useEffect(() => {
    setItem(props.item);
  }, [props]);

  useEffect(() => {
    if (item) {
      if (item.statusId == 1) {
        setErrorInvalidRequestStatus('Solicitarea nu a fost gasită.');
      } else {
        GetLocalities(item.countyCode).then((res) => {
          res.map((c: any, i: number) => {
            if (item.localityCode === c.localityCode) {
              setLocalityName(c.localityName);
            }
          });
        });
      }
    }
  }, [item]);

  const displayResp = (responses: any[]) => {
    return (
      <div className="general-table-container">
        <table className="w-100">
          <thead>
            <th>Status</th>
            <th>Solutie</th>
            <th>Data</th>
            <th>Documente</th>
          </thead>
          <tbody>
            {responses.map((resp: any, idx: number) => {
              return (
                <tr key={idx}>
                  <td>{resp.statusRaspuns === 'True' ? 'Final' : 'Intermediar'}</td>
                  <td>{resp.rezolutie}</td>
                  <td>{moment(resp.sentDate).format('DD.MM.YYYY')}</td>
                  <td>
                    {resp.documents.map((doc: any, i: number) => {
                      return (
                        <a className="hover-red link--download pointer black text-decoration-none" key={i} href={doc.documentLink}>
                          {doc.fileName}
                        </a>
                      );
                    })}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  };

  return (
    <Fragment>
      <Button className="ms-3" onClick={() => props.hideDetailsPage()}>
        <FontAwesomeIcon icon={faChevronLeft} />
        <span>&nbsp;Înapoi</span>
      </Button>
      {item !== null && !errorInvalidRequestStatus && (
        <div className="m-3 rss-details">
          <Row>
            <Col xs={12} className="mb-3">
              <div className="details-block h-100">
                <div className="block-header">
                  <span className="block-title-border" />
                  <span className="block-title">
                    {item.requestNo !== null && item.requestNo} {item.requestNo && item.requestDate && '/'}{' '}
                    {item.requestDate && moment(item.requestDate).format('DD.MM.YYYY')}
                  </span>
                </div>
                <div className="block-body">
                  <div className="property-container">
                    <span className="property-key">POD</span>
                    <span className="property-value">{item.pod}</span>
                  </div>
                  <div className="property-container">
                    <span className="property-key">Adresa</span>
                    <span className="property-value">
                      {item.countyCode}, {localityName}, {item.street && `${item.street},`} {item.number && `${item.number}`}
                    </span>
                  </div>
                  <div className="property-container">
                    <span className="property-key">Tip adresă</span>
                    <span className="property-value">{item.requestTypeName}</span>
                  </div>
                  <div className="property-container">
                    <span className="property-key">Categorie</span>
                    <span className="property-value">{item.category}</span>
                  </div>
                  <div className="property-container">
                    <span className="property-key">Subiect</span>
                    <span className="property-value">{item.subject}</span>
                  </div>
                  <div className="property-container">
                    <span className="property-key">Subiect Detaliat</span>
                    <span className="property-value">{item.subSubject}</span>
                  </div>
                  <div className="property-container">
                    <span className="property-key">Status</span>
                    <span className="property-value">{item.statusName}</span>
                  </div>
                  <div className="property-container">
                    <span className="property-key">Dată depunere</span>
                    <span className="property-value">{moment(item.entryDate).format('DD.MM.YYYY')}</span>
                  </div>
                  <div className="property-container justify-content-center d-block">
                    <span className="property-key">Documente</span>
                    <span className="d-flex flex-column">
                      {item.documents.map((doc: any, i: number) => (
                        <a
                          className="hover-red link--download pointer black text-decoration-none d-flex"
                          style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
                          key={i}
                          href={doc.documentLink}
                        >
                          {doc.fileName}
                        </a>
                      ))}
                    </span>
                  </div>
                </div>
              </div>
            </Col>
          </Row>

          <Row className="mt-2">
            <Col xs={12} className="mb-4">
              <div className="details-block">
                <div className="block-header">
                  <span className="block-title-border" />
                  <span className="block-title">Mesaj</span>
                </div>
                <div className="block-body">
                  <div className="property-container">
                    <span className="property-value">{item.description}</span>
                  </div>
                </div>
              </div>
            </Col>

            <Col xs={12} className="mb-3">
              <div className="details-block">
                <div className="block-header">
                  <span className="block-title-border" />
                  <span className="block-title">Soluție / Răspuns</span>
                </div>
                <div className="block-body">
                  {item.responses.length > 0 ? (
                    displayResp(item.responses)
                  ) : (
                    <span className="property-value">Nu există nicio soluție.</span>
                  )}
                </div>
              </div>
            </Col>
          </Row>
        </div>
      )}
      {errorInvalidRequestStatus !== '' && <p className="fw-bold red text-center">{errorInvalidRequestStatus}</p>}
    </Fragment>
  );
};

export default VerifyDetails;
