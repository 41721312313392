import React from 'react';
import { DelgazWrapper } from '@components/global/DelgazWrapper';
import ActivateAccount from '@components/auth/activateAccount/ActivateAccount';

export const ActivateAccountComponent = () => {
    return (
        <DelgazWrapper>
            <ActivateAccount />
        </DelgazWrapper>
    );
};