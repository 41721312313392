import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import { Fragment } from 'react';
import DatePicker from '../Date/DatePicker';

const EditableRow = (props: any) => {
  const { columns, rows, editCell, addRowAtIndex, removeRowAtIndex, canEdit, minimumNrOfRows } = props;

  return (
    <Fragment>
      {rows.map((row: any, index: number) => (
        <tr key={index} style={{ height: '25px' }}>
          {Object.keys(row).map((key: any, idx) => {
            if (columns[idx].type === 'input') {
              return (
                <td
                  style={{ border: '1px solid grey', minWidth: 'unset' }}
                  className={`${columns[idx].customTd ? 'custom-td' : ''}`}
                  key={idx}
                >
                  {columns[idx].dataType == 'date' ? (
                    <DatePicker
                      mbZero
                      className="dynamic-table-date-selector"
                      selected={row[key] ? moment(row[key]).toDate() : ''}
                      onChange={(e: any) => {
                        let date = '';
                        if (e) {
                          date = moment(e).format('YYYY-MM-DD');
                        }
                        editCell(row, key, date);
                      }}
                    />
                  ) : (
                    <input
                      style={{ minWidth: columns[idx].width }}
                      className="table-input"
                      value={row[key]}
                      disabled={canEdit === false}
                      placeholder={columns[idx].placeholder ?? ''}
                      onChange={(e) => {
                        e.preventDefault();

                        if (columns[idx].dataType === 'string') {
                          editCell(row, key, e.target.value);
                        } else if (columns[idx].dataType === 'numeric') {
                          // const re = /^[,.0-9\b]+$/;
                          const dots = /^[^.]*.?[^.]*$/;
                          const cons = /^(?!.*\.\.)[,.0-9]+$/;

                          if (e.target.value === '' || cons.test(e.target.value)) {
                            if (dots.test(e.target.value)) {
                              if (columns[idx].floatPrecision) {
                                let stringValue = e.target.value as string;
                                if (stringValue.slice(stringValue.lastIndexOf('.')).length <= columns[idx].floatPrecision + 1) {
                                  editCell(row, key, e.target.value);
                                }
                              } else {
                                editCell(row, key, e.target.value);
                              }
                            }
                          }
                        } else if (columns[idx].dataType === 'integer') {
                          const re = /^[0-9\b]+$/;

                          if (e.target.value === '' || re.test(e.target.value)) {
                            editCell(row, key, e.target.value);
                          }
                        }
                      }}
                    />
                  )}
                </td>
              );
            } else if (columns[idx].type === 'select') {
              return (
                <td style={{ border: '1px solid grey' }} key={idx}>
                  <div className="table-select-group field-group select">
                    <select
                      onChange={(e) => editCell(row, key, e.target.value)}
                      className="table-select"
                      value={row[key]}
                      style={{ minWidth: columns[idx].width }}
                    >
                      <option></option>
                      {columns[idx].options.map((option: any, indx: number) => (
                        <option key={indx} value={option.name}>
                          {option.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </td>
              );
            } else if (columns[idx].type === 'id') {
              return (
                <td style={{ border: '1px solid grey' }} key={idx}>
                  <div>
                    <span>{index + 1}</span>
                  </div>
                </td>
              );
            } else if (columns[idx].type === 'radio') {
              return (
                <td style={{ border: '1px solid grey' }} key={idx}>
                  <div style={{ minWidth: columns[idx].width }}>
                    {columns[idx].options.map((option: any, index: number) => {
                      return (
                        <span
                          key={index}
                          onClick={() => {
                            editCell(row, key, option.id);
                          }}
                          className="ms-2 me-1 pointer"
                        >
                          <input
                            className="custom-radio-button pointer"
                            type="radio"
                            value={row[key]}
                            checked={row[key] === option.id}
                            onChange={() => editCell(row, key, option.id)}
                            style={{ marginRight: '6px' }}
                          />
                          <span className="fw-bold">{option.name}</span>
                        </span>
                      );
                    })}
                  </div>
                </td>
              );
            }
          })}
          {canEdit !== false && (
            <td className="d-flex text-center actions-td" style={{ marginLeft: '8px' }}>
              {rows.length < 10 && <FontAwesomeIcon className="add-row" icon={faPlus} onClick={() => addRowAtIndex(index)} />}
              {rows.length > (minimumNrOfRows || minimumNrOfRows == 0 ? minimumNrOfRows : 1) && (
                <FontAwesomeIcon className="remove-row" icon={faMinus} onClick={() => removeRowAtIndex(index)} />
              )}
            </td>
          )}
        </tr>
      ))}
    </Fragment>
  );
};

export default EditableRow;
