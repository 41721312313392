import React from 'react';
import { DelgazWrapper } from '@components/global/DelgazWrapper';
import Conventions from '@components/conventions/list/Conventions';

export const CList = () => {
    return (
        <DelgazWrapper>
            <Conventions />
        </DelgazWrapper>
    );
};