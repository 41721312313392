import { Dropdown, DropdownOptionType } from '@components/common/Dropdown';
import { ValueToSet } from '../PageWrapper';
import { useEffect, useRef } from 'react';

type DropdownFieldProps = {
    options: DropdownOptionType[],
    name: string,
    label: string,
    value?: string|number|string[]|any,
    itemTypeId: number,
    isUsedInBackOffice?: boolean,
    displaySearch?: boolean,
    onChange?: (e: ValueToSet) => void,
    valueWrapper?: (value: string|number) => number|string,
    changeExtraPropertyValue?: (value: string|number) => void;
    hideLabel?: boolean;
    mbZero?: boolean;
    toggled?: boolean;
    containerClassName?: string;
};

const DropdownField = ({ options, name, label, onChange, itemTypeId, value, valueWrapper, isUsedInBackOffice, displaySearch, changeExtraPropertyValue, hideLabel, mbZero, toggled, containerClassName }: DropdownFieldProps) => {
    const dropdownRef = useRef();
    let placeholder = label;
    //remove * for placeholder
    if(label && label.endsWith('*')) {
        placeholder = label.slice(0, -1);
    }

    useEffect(() => {
        if(toggled) {
            setTimeout(() => {
            //@ts-ignore
            dropdownRef.current?.toggleOptionList();
            }, 50);
        }
      }, [toggled]);
    
    return (
        <Dropdown
            ref={dropdownRef}
            containerClassName={containerClassName}
            mbZero={mbZero}
            options={options}
            name={name}
            value={value}
            label={hideLabel ? '' : label}
            displaySearch={displaySearch}
            isUsedInBackOffice={isUsedInBackOffice}
            defaultPlaceholder={`Selectează ${placeholder}`}
            onChange={(value) => {onChange && onChange({name, value: (valueWrapper ? valueWrapper(value) : value), itemTypeId} as ValueToSet);
            if(options[0].extraPropertyName) {
                let extraPropertyvalue =  options.find(op => op.id === value)?.extraPropertyValue || '';
                changeExtraPropertyValue && changeExtraPropertyValue(extraPropertyvalue);
            }
        }}
        />
    );
};

export default DropdownField;