import DatePicker from '@components/common/Date/DatePicker';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { ValueToSet } from '../PageWrapper';

export type DataItem = {
  name: string;
  label: string;
  itemTypeId: number;
  minValue?: string;
  maxValue?: string;
  required?: boolean;
};

type DateTimeProps = {
  items: DataItem[] | undefined;
  values?: DateRangeInputs;
  onChange?: (e: ValueToSet) => void;
  hideLabel?: boolean;
  mbZero?: boolean;
};

export type DateRangeInputs = { [name: string]: Date | null };

const DateRange = ({ items, values, onChange, hideLabel, mbZero }: DateTimeProps) => {
  const [value, setValue] = useState<DateRangeInputs>({} as DateRangeInputs);

  useEffect(() => {
    if (items && values) {
      setValue({ ...values });
    }
  }, [values]);

  return (
    <>
      {items && (
        <div className="d-flex flex-column">
          {items.map((item: DataItem, index: number) => {
            return (
              <div key={index} className={index == 1 ? 'mt-2' : ''}>
                <DatePicker
                  key={index}
                  placeholderText={hideLabel ? (item.required ? `${item.label}*` : item.label) : ''}
                  label={item.label}
                  hideLabel={hideLabel}
                  mbZero={mbZero}
                  name={item.name}
                  selected={value[item.name]}
                  dateFormat="dd.MM.yyyy"
                  minDate={index == 1 ? (value[items[0].name] ? value[items[0].name] : item.minValue) : item.minValue}
                  maxDate={item.maxValue}
                  onChange={(date) => {
                    setValue({ ...value, [item.name]: date });
                    let selectedDate = moment(date);
                    onChange &&
                      onChange({
                        name: item.name,
                        value: selectedDate.isValid() ? selectedDate.format('YYYY-MM-DD') : null,
                        itemTypeId: item.itemTypeId
                      } as ValueToSet);
                  }}
                />
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};

export default DateRange;
