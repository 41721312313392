import React from 'react';
import { DelgazWrapper } from '@components/global/DelgazWrapper';
import AlocareCerereClient from '@components/iConnect/AlocareCerereClient';

export const AlocareCerereClientComponent = (): JSX.Element => {
  return (
    <DelgazWrapper>
      <AlocareCerereClient />
    </DelgazWrapper>
  );
};
