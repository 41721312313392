import BarChart from '@components/chart/BarChart';
import LineChart from '@components/chart/LineChart';
import { Dropdown, DropdownOptionType } from '@components/common/Dropdown';
import { faChartLine, faChartSimple } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Col, Modal, Row } from 'react-bootstrap';

const ChartModal = (props: { show: boolean; setShow: any; chartData: any }) => {
  const [chartType, setChartType] = useState(1);
  const [data, setData] = useState<any>(null);
  const [clc, setClc] = useState<string>(props.chartData[0].clc);
  const [active, setActive] = useState(props.chartData[0].data);
  const [activeYear, setActiveYear] = useState<string | number>(props.chartData[0].data[0].year);
  const [activeMonth, setActiveMonth] = useState<string | number>(-1);

  const barOptions = {
    backgroundColor: ['#ea1b0a', '#931206'],
  };

  const lineOptions = {
    backgroundColor: 'transparent',
    tension: 0.5,
    borderColor: '#ea1b0a',
    pointBorderColor: 'transparent',
    pointBorderWidth: 4,
    pointRadius: 3,
    pointBackgroundColor: '#ea1b0a'
  };

  const options = {
    plugins: {
      legend: false
    },
    scales: {
      x: {
        grid: {
          display: false
        }
      },
      y: {
        ticks: {
          stepSize: 1
        }
      }
    }
  };

  useEffect(() => {
    setData({
      labels: active.filter((item: any) => item.year === activeYear).filter((item: any) => activeMonth !== -1 ? item.month === activeMonth : active).map((data: any) => data.day + ' ' + data.month + ' ' + data.year),
      datasets: [
        {
          label: 'Consum',
          data: active.filter((item: any) => item.year === activeYear).filter((item: any) => activeMonth !== -1 ? item.month === activeMonth : active).map((data: any) => data.consumption),
          ...(chartType === 1 ? barOptions : lineOptions)
        }
      ]
    });
  }, [active, chartType, activeYear, activeMonth]);

  const getYears = (data: any) => {
    let years: any[] = [];
    let yArray: any[] = [];

    data.map((item: any) => {
      if (!years.includes(item.year)) {
        years.push(item.year);
        yArray.push({ value: item.year });
      }
    });

    return yArray;
  };

  const getMonthName = (short: string) => {
    switch (short) {
      case 'ian': return 'Ianuarie';
      case 'feb': return 'Februarie';
      case 'mar': return 'Martie';
      case 'apr': return 'Aprilie';
      case 'mai': return 'Mai';
      case 'iun': return 'Iunie';
      case 'iul': return 'Iulie';
      case 'aug': return 'August';
      case 'sep': return 'Septembrie';
      case 'oct': return 'Octombrie';
      case 'noi': return 'Noiembrie';
      case 'dec': return 'Decembrie';
      default: return '-';
    }
  }

  const getMonths = (data: any) => {
    let months: any[] = [];
    let mArray: any[] = [];

    mArray.push({ name: 'Toate', id: -1 });

    data.map((item: any) => {
      if (!months.includes(item.month)) {
        months.push(item.month);
        mArray.push({ name: getMonthName(item.month), id: item.month });
      }
    });

    return mArray;
  };

  return (
    <Modal
      className="rss-modal"
      centered
      backdrop={'static'}
      show={props.show}
      onExit={() => props.setShow(false)}
      onHide={() => props.setShow(false)}
    >
      <Modal.Header closeButton>
        <h4 className="red">Grafic consumuri</h4>
      </Modal.Header>
      <Modal.Body>
        <Row className="d-flex justify-content-between">
          <Col xs={8} sm={8}>
            <Row>
              <Col xs={4}>
                <Dropdown
                  options={
                    props.chartData.map((option: any, index: number) => ({
                      id: option.clc,
                      name: option.clc
                    })) as DropdownOptionType[]
                  }
                  label={'Cod loc consum'}
                  displaySearch={true}
                  onChange={(value) => {
                    setClc(value);
                    setActive(props.chartData.filter((item: any) => item.clc === value)[0].data);
                  }}
                  value={clc}
                  disabled={props.chartData.length < 2}
                  mbZero
                />
              </Col>
              <Col xs={4}>
                <Dropdown
                  options={
                    getYears(props.chartData[0].data).map((option: any, index: number) => ({
                      id: option.value,
                      name: option.value
                    })) as DropdownOptionType[]
                  }
                  label={'Anul'}
                  displaySearch={true}
                  onChange={(value) => {
                    setActiveYear(value);
                  }}
                  value={activeYear}
                  mbZero
                />
              </Col>
              <Col xs={4}>
                <Dropdown
                  options={
                    getMonths(props.chartData[0].data).map((option: any, index: number) => ({
                      id: option.id,
                      name: option.name
                    })) as DropdownOptionType[]
                  }
                  label={'Luna'}
                  displaySearch={true}
                  onChange={(value) => {
                    setActiveMonth(value);
                  }}
                  value={activeMonth}
                  mbZero
                />
              </Col>
            </Row>
          </Col>
          <Col xs={4} sm={4} className="d-flex align-items-center justify-content-end">
            <div
              style={{ marginRight: '8px' }}
              onClick={() => setChartType(1)}
              className={`chart-type-option ${chartType === 1 ? 'active' : ''}`}
            >
              <FontAwesomeIcon icon={faChartSimple} className="chart-type-icon" />
            </div>
            <div onClick={() => setChartType(2)} className={`chart-type-option ${chartType === 2 ? 'active' : ''}`}>
              <FontAwesomeIcon icon={faChartLine} className="chart-type-icon" />
            </div>
          </Col>
        </Row>
        <div className="mt-4 mb-4">
          {chartType === 1 && data && <BarChart chartData={data} options={options} />}
          {chartType === 2 && data && <LineChart chartData={data} options={options} />}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ChartModal;
