import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IState } from '@type/store';
import { Fragment, useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { getNotificationDetails, markNotificationAsRead, deleteNotification } from '@api/userNotifications';
import moment from 'moment';
import { toggleLoader } from '@actions/LoaderActions';
import { useDispatch } from 'react-redux';

const NotificationDetails = () => {
  //eslint-disable-next-line
  const [notification, setNotification] = useState<any>(null);
  const OLD_SITE_URL: string | undefined = useSelector<IState>((state) => state.options.webApiHost) as string;
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [idsToDelete, setIdsToDelete] = useState<any[]>([]);

  const dispatch = useDispatch();

  useEffect(() => {
    getDetails();
  }, []);

  const getDetails = async () => {
    dispatch(toggleLoader(true));

    const url = new URL(window.location.href);

    const params = url.search;
    const searchParams = new URLSearchParams(params);

    if (searchParams.has('id')) {
      await getNotificationDetails({ id: searchParams.get('id') })
        .then((response: any) => {
          console.log(response);
          if (response && response.data && response.status === 200) {
            setNotification(response.data);
            apiMarkAsRead([searchParams.get('id')]);
          }
        })
        .catch((err: any) => console.log(err));
    }

    dispatch(toggleLoader(false));
  };

  const apiMarkAsRead = async (ids: any[]) => {

    await markNotificationAsRead({ids})
      .then((response) => {
        if (response && response.data && response.status === 200) {
        }
      })
      .catch((err) => console.log(err));

    dispatch(toggleLoader(false));
  };

  const apiCallDelete = async (ids: any[]) => {
    dispatch(toggleLoader(true));

    await deleteNotification({ids})
      .then((response) => {
        if (response && response.data && response.status === 200) {
            setIdsToDelete([]);
            setShowSuccessModal(true);
        }
      })
      .catch((err) => console.log(err));

    dispatch(toggleLoader(false));
  };

  const renderConfirmDeleteModal = () => {
    return <Modal centered show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
      <Modal.Title>Confirmare</Modal.Title>
      <Modal.Body>Ești sigur că vrei să ștergi această notificare?</Modal.Body>
      <Modal.Footer>
        <Button onClick={() => { setShowDeleteModal(false); apiCallDelete(idsToDelete); }}>Da</Button>
        <Button onClick={() => setShowDeleteModal(false)}>Nu</Button>
      </Modal.Footer>
    </Modal>;
  };

  const renderDeleteSuccessModal = () => {
    return (
      <Modal show={showSuccessModal} onExit={() => window.location.href = `${OLD_SITE_URL}/notificari`} onHide={() => setShowSuccessModal(false)}>
        <Modal.Header>Succes</Modal.Header>
        <Modal.Body>Mesajul a fost șters.</Modal.Body>
        <Modal.Footer>
          <Button onClick={() => window.location.href = `${OLD_SITE_URL}/notificari`}>Ok</Button>
        </Modal.Footer>
      </Modal>
    );
  };

  return (
    <div className="notification-details d-flex flex-column m-2 p-2">
      {notification !== null && (
        <Fragment>
          <div className="d-flex align-items-center justify-content-between mb-4">
            <a href={`${OLD_SITE_URL}/notificari`}>
              <Button>
                <FontAwesomeIcon icon={faChevronLeft} />
                <span>&nbsp;Înapoi</span>
              </Button>
            </a>
            <div className="navigation-buttons">
              <FontAwesomeIcon icon={faChevronLeft} className="previous-icon" />
              <FontAwesomeIcon icon={faChevronRight} className="next-icon" />
            </div>
          </div>
          <div className="details-block">
            <div className="block-header d-flex justify-content-between">
              <div className="title">
                <span className="block-title-border" />
                <span className="block-title">{notification.title}</span>
              </div>
              <div className="n-date">
                <span>{moment(notification.EntryDate).format('YYYY-MM-DD HH:mm')}</span>
              </div>
            </div>
            <div className="block-body">
              <div dangerouslySetInnerHTML={{ __html: notification.message }} />

              <div className="mt-4">
                <Button onClick={() => { setIdsToDelete([notification.id]); setShowDeleteModal(true); }}>Șterge mesajul</Button>
              </div>
            </div>
          </div>
        </Fragment>
      )}
      {notification === null && 
        <div className="text-center d-flex flex-column justify-content-center align-items-center">
          <span>Această notificare nu există.</span>
          <Button onClick={() => window.location.href = `${OLD_SITE_URL}/notificari`} className="mt-4">Înapoi la lista de notificări</Button>
        </div>
      }
      {renderConfirmDeleteModal()}
      {renderDeleteSuccessModal()}
    </div>
  );
};

export default NotificationDetails;