import React, { useEffect, useRef, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';

const screenSizes = [true, 'sm-down', 'md-down', 'lg-down', 'xl-down', 'xxl-down'] as const;

export type ModalProps = {
  show: boolean;
  fullScreen?: (typeof screenSizes)[number];
  size?: 'sm' | 'lg' | 'xl';
  setShow: (value: boolean) => void;
  title?: string;
  className?: string;
  modalClassName?: string;
  content?: React.FC;
  hideQuit?: boolean;
  children?: JSX.Element;
  footerAction?: JSX.Element;
  backdropStatic?: boolean;
  onClose?: () => void;
  applyFooterMobileSpacer?: boolean;
  closeButttonLabel?: string;
  minHeigth?: number;
};

export type state = {};

const ModalComponent = (props: ModalProps) => {
  let Component: React.FC | undefined = props.content;
  const [modalBodyHeight, setModalBodyHeight] = useState(0);
  const modalRef = useRef<any>(null);

  useEffect(() => {
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        const modalBodyHeight = modalRef.current.clientHeight;
        setModalBodyHeight(modalBodyHeight);
      });
    });

    if (modalRef.current) {
      observer.observe(modalRef.current, { childList: true, subtree: true, characterData: true });
    }

    // Cleanup function
    return () => {
      observer.disconnect();
      setModalBodyHeight(0);
    };
  }, [props.show]);

  return (
    <Modal
      centered={true}
      show={props.show}
      size={props.size}
      fullscreen={props.fullScreen}
      className={`${props.modalClassName} p-0`}
      backdrop={props.backdropStatic ? 'static' : true}
      onHide={() => props.setShow(false)}
    >
      <Modal.Header closeButton>
        <h3 className={`${props.modalClassName === 'rss-modal' ? 'modal-title' : 'brand-subtitle'}`}>{props.title}</h3>
      </Modal.Header>
      <Modal.Body
        className={props.className ?? ''}
        style={{
          overflowY: `${modalBodyHeight < 800 ? 'visible' : 'scroll'}`,
          maxHeight: `${modalBodyHeight < 800 ? '100%' : ''}`,
          minHeight: props.minHeigth
        }}
      >
        <div ref={modalRef}>{props.children ? props.children : Component ? <Component /> : null}</div>
      </Modal.Body>
      <Modal.Footer>
        {!props.hideQuit && (
          <Button
            className="btn btn-secondary"
            onClick={() => {
              props.setShow(false);
              props.onClose && props.onClose();
            }}
            variant="secondary"
          >
            {props.closeButttonLabel ? props.closeButttonLabel : 'Renunță'}
          </Button>
        )}
        {props.footerAction && <div>{props.footerAction}</div>}

        {props.applyFooterMobileSpacer && <div className="mobile-spacer" />}
      </Modal.Footer>
    </Modal>
  );
};

export default ModalComponent;
