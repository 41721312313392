import { toggleLoader } from '@actions/LoaderActions';
import { getExportRevizii, getRevizii, revisionRetry } from '@api/revizii/revizii';
import ButtonFilters from '@components/common/Button/ButtonFilters';
import DatePicker from '@components/common/Date/DatePicker';
import { Input } from '@components/common/Input';
import MaskedInput from '@components/common/Input/MaskedInput';
import Pagination from '@components/common/Pagination/Pagination';
import SortAscDesc from '@components/common/Sort/SortAscDesc';
import * as notification from '@lib/Notification';
import moment from 'moment';
import { Fragment, useEffect, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';

type RevisionType = {
  revisionId: string;
  clc: string;
  consumptionPlace: string;
  entryDate: string;
  eraNumber: string;
  oe: string;
  pod: string;
  revisionType: string;
  revisionTypeId: number;
  rowId: number;
  status: string;
  statusId: number;
  showRetryButton: boolean;
};
//eslint-disable-next-line
const STATUS_ID_IN_PROCESARE = 1;
const STATUS_ID_EROARE_DE_COMUNICARE = 2;
//eslint-disable-next-line
const STATUS_ID_FINALIZATA = 3;
const STATUS_ID_ANULATA = 4;

const IstoricIncarcariFise = () => {
  const dispatch = useDispatch();

  //eslint-disable-next-line
  const [error, setError] = useState('');
  const [revisions, setRevisions] = useState<RevisionType[]>([]);

  //filters
  const [searchDisabled, setSearchDisabled] = useState<any>(false);
  const [toggled, setToggled] = useState(false);
  const [sortColumn, setSortColumn] = useState('entryDate');
  const [sortAscending, setSortAscending] = useState(false);
  const [filterClc, setFilterClc] = useState<any>('');
  const [filterOe, setFilterOe] = useState<any>('');
  const [filterDate, setFilterDate] = useState<any>('');
  const [filterNr, setFilterNr] = useState<any>('');
  const [filterClcTouched, setFilterClcTouched] = useState<any>(false);

  //pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const paginate = (pageNumber: number) => setCurrentPage(pageNumber);
  const setElementsPerPage = (number: number) => setPerPage(number);
  const [totalItemsCount, setTotalItemsCount] = useState(0);

  useEffect(() => {
    if (filterClc == '__________') {
      setFilterClc('');
    }
  }, [filterClc]);

  useEffect(() => {
    if (!searchDisabled) {
      search(false, false);
    }
  }, [currentPage, perPage, sortColumn, sortAscending, searchDisabled]);

  const search = (firstPage: boolean, reset: boolean) => {
    dispatch(toggleLoader(true));
    let payload = {
      pageNumber: firstPage ? 1 : currentPage,
      pageSize: perPage,
      consumptionPlace: reset ? '' : filterClc,
      oe: reset ? '' : filterOe,
      createdDate: reset ? '' : filterDate,
      eraNumber: reset ? '' : filterNr,
      sortBy: sortColumn,
      sortType: sortAscending ? 'asc' : 'desc'
    };
    //get data
    getRevizii(payload)
      .then((res) => {
        setRevisions(res.items);
        setTotalItemsCount(res.metadata.totalItemsCount);
        dispatch(toggleLoader(false));
      })
      .catch((err) => {
        setError('A intervenit o eroare tehnică. Vă rugăm reveniți mai târziu.');
        dispatch(toggleLoader(false));
      });
  };

  const onSort = (column: string) => {
    setSortColumn(column);
    if (sortColumn == column) {
      setSortAscending(!sortAscending);
    } else {
      setSortAscending(true);
    }
  };

  const exportRevizii = async () => {
    dispatch(toggleLoader(true));
    let payload = {
      consumptionPlace: filterClc,
      oe: filterOe,
      createdDate: filterDate,
      eraNumber: filterNr
    };

    let res = '' as BlobPart;

    try {
      res = await getExportRevizii(payload);

      setTimeout(() => {
        const blob: Blob = new Blob([res]);
        const fileName: string = `export-revizii-${moment().format('YYMMDDHms')}.xlsx`;
        const objectUrl: string = URL.createObjectURL(blob);
        const a: HTMLAnchorElement = document.createElement('a') as HTMLAnchorElement;

        a.href = objectUrl;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();

        document.body.removeChild(a);
        URL.revokeObjectURL(objectUrl);
        dispatch(toggleLoader(false));
      }, 500);
    } catch (error) {
      notification.error('A apărut o eroare tehnică. Vă rugam reveniți mai târziu.');
      dispatch(toggleLoader(false));
    }
  };

  const handleRevisionRetry = (revisionId: string | number) => {
    dispatch(toggleLoader(true));
    revisionRetry(revisionId)
      .then((res) => {
        dispatch(toggleLoader(false));
        notification.success('Retrimis cu succes.');
        search(false, false);
      })
      .catch((err: any) => {
        dispatch(toggleLoader(false));
        if (err.status == 200) {
          notification.success('Retrimis cu succes.');
          search(false, false);
        } else if (err.status == 412) {
          notification.success('Apel cu succes! Fișa este anulată.');
          search(false, false);
        } else if (err.status == 400) {
          notification.error('Nu se permite retrimiterea. Verificați daca fișa mai este în statusul eroare de comunicare.');
        } else if (err.status == 501) {
          notification.error('Eroare de comunicare! Reîncercați mai târziu.');
          search(false, false);
        } else {
          notification.error('Eroare la retrimitere.');
        }
      });
  };

  const resetFilters = () => {
    setFilterClc('');
    setFilterOe('');
    setFilterDate('');
    setFilterNr('');
    setSortColumn('entryDate');
    setSortAscending(false);
    setCurrentPage(1);
    search(true, true);
  };

  return (
    <Fragment>
      <div className="justify-content-end d-flex mb-3">
        <ButtonFilters toggled={toggled} onClick={() => setToggled(!toggled)} />

        <Button className="ms-3 btn-wide" onClick={() => (window.location.href = '/incarcare-fisa-noua')}>
          Încărcare fișă nouă
        </Button>
      </div>
      {toggled && (
        <Row className="d-flex justify-content-between mb-2">
          <Col className="mt-3" xs={12} sm={6} md={3} lg={2}>
            {filterClc && (filterClc[0] == 'D' || filterClc[0] == '5') ? (
              <MaskedInput
                value={filterClc}
                mbZero
                autoFocus
                preloadedStartDisabled
                onChange={(e: any) => {
                  const currentValue = (e.target.value ? e.target.value.toLocaleUpperCase() : '') as string;
                  setFilterClc(currentValue);
                }}
                utility="GN"
                isClc={filterClc[0] == '5'}
                label="Cod loc consum"
                mask={'0123333333'}
              />
            ) : (
              <Input
                label="CLC / NLC"
                mbZero
                value={filterClc}
                autoFocus={filterClcTouched}
                onChange={(e: any) => {
                  const currentValue = (e.target.value ? e.target.value.toLocaleUpperCase() : '') as string;
                  if (currentValue == 'D' || currentValue == '5' || currentValue.startsWith('DEG') || currentValue.startsWith('500')) {
                    setFilterClcTouched(true);
                    setFilterClc(currentValue);
                  }
                }}
              />
            )}
          </Col>
          <Col className="mt-3" xs={12} sm={6} md={3} lg={2}>
            <Input
              label="Nr. înregistrare OE"
              mbZero
              value={filterOe}
              onChange={(e: any) => {
                setFilterOe(e.target.value);
              }}
            />
          </Col>
          <Col className="mt-3" xs={12} sm={6} md={3} lg={2}>
            <DatePicker
              mbZero
              label="Selectați o dată"
              name="Selectați o dată"
              selected={filterDate ? moment(filterDate).toDate() : filterDate}
              onChange={(e: any) => {
                if (e) {
                  let date = e ? moment(e).format('YYYY-MM-DD') : '';
                  setFilterDate(date);
                } else {
                  setFilterDate('');
                }
              }}
            />
          </Col>
          <Col className="mt-3" sm={6} md={3} lg={2}>
            <Input
              label="Număr Înregistrare"
              mbZero
              value={filterNr}
              onChange={(e: any) => {
                setFilterNr(e.target.value);
              }}
            />
          </Col>

          <Col className="mt-3">
            <div className="d-flex align-items-start justify-content-md-end justify-content-sm-center justify-content-center">
              <Button
                className="btn-large"
                onClick={() => {
                  setSearchDisabled(true);
                  setCurrentPage(1);
                  setSearchDisabled(false);
                  search(true, false);
                }}
              >
                Caută
              </Button>
              <Button
                className="btn-large ms-3"
                onClick={() => {
                  resetFilters();
                }}
              >
                Resetează
              </Button>
            </div>
          </Col>
        </Row>
      )}
      <div className="justify-content-end d-flex mb-2">
        <a onClick={exportRevizii} className="link link--download download-link red pointer hover-red">
          Descarcă (.xls)
        </a>
      </div>
      <div className="general-table-container">
        <table>
          <thead>
            <tr>
              <th>Nr. crt.</th>
              <th>Tip fișă</th>
              <th>
                <SortAscDesc
                  sortColumn={sortColumn}
                  sortAscending={sortAscending}
                  onSort={onSort}
                  fieldId="pod"
                  fieldName="Cod loc consum (CLC)"
                />
              </th>
              <th>
                <SortAscDesc
                  sortColumn={sortColumn}
                  sortAscending={sortAscending}
                  onSort={onSort}
                  fieldId="consumptionPlace"
                  fieldName="Număr loc consum (NLC)"
                />
              </th>
              <th>
                <SortAscDesc
                  sortColumn={sortColumn}
                  sortAscending={sortAscending}
                  onSort={onSort}
                  fieldId="entryDate"
                  fieldName="Dată încărcare"
                />
              </th>
              <th>
                <SortAscDesc sortColumn={sortColumn} sortAscending={sortAscending} onSort={onSort} fieldId="oe" fieldName="Nr. înreg. OE" />
              </th>
              <th>
                <SortAscDesc
                  sortColumn={sortColumn}
                  sortAscending={sortAscending}
                  onSort={onSort}
                  fieldId="eraNumber"
                  fieldName="Nr. înregistrare"
                />
              </th>
              <th>Status</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {revisions.map((rev: RevisionType, index: number) => (
              <tr
                key={index}
                className={`${
                  rev.statusId == STATUS_ID_EROARE_DE_COMUNICARE || rev.statusId == STATUS_ID_ANULATA ? 'highlight-light-red' : ''
                }`}
              >
                <td>{(currentPage - 1) * perPage + index + 1}</td>
                <td>{rev.revisionType}</td>
                <td>
                  <p>{rev.pod}</p>
                </td>
                <td>
                  <p>{rev.consumptionPlace}</p>
                </td>
                <td>{rev.entryDate ? moment(rev.entryDate).format('DD.MM.YYYY') : '-'}</td>
                <td>{rev.oe}</td>
                <td>{rev.eraNumber}</td>
                <td className={`${rev.statusId == STATUS_ID_EROARE_DE_COMUNICARE || rev.statusId == STATUS_ID_ANULATA ? 'red' : ''}`}>
                  {rev.status}
                </td>
                <td>
                  <div className="align-items-center d-flex">
                    {rev.statusId == STATUS_ID_EROARE_DE_COMUNICARE && rev.showRetryButton && (
                      <Button onClick={() => handleRevisionRetry(rev.revisionId)} className="ms-2">
                        Retrimite
                      </Button>
                    )}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <Pagination
          currentPage={currentPage}
          totalElements={totalItemsCount}
          perPage={perPage}
          paginate={paginate}
          setElementsPerPage={setElementsPerPage}
        />
      </div>
    </Fragment>
  );
};

export default IstoricIncarcariFise;
