import { proxy, Proxy } from '@api/azureProxy';


export async function verifyCounty(values: any) {
  const queryParams = [
    values.countyCode ? `countyCode=${values.countyCode}` : '',
    values.localityCode ? `localityCode=${values.localityCode}` : '',
    values.streetCode ? `streetCode=${values.streetCode}` : '',
    values.uuid ? `requestIdentifier=${values.uuid}` : '',
  ].filter(Boolean);

  const proxyData: Proxy = {
    method: 'GET',
    endPoint: `/powercut/Ticket/verify_county/?${queryParams.join('&')}`,
    withAuthBearer: false
  };
  return proxy<any>(proxyData);
}

export async function createTiket(values: any) {
  const queryParams = [
    values.countyCode ? `countyCode=${values.countyCode}` : '',
    values.localityCode ? `localityCode=${values.localityCode}` : '',
    values.streetCode ? `streetCode=${values.streetCode}` : '',
    values.uuid ? `requestIdentifier=${values.uuid}` : '',
    values.measurementPointCode ? `pod=${values.measurementPointCode}` : '',
    values.phone ? `phoneNumber=${values.phone}` : '',
    values.captchaText ? `captchaText=${values.captchaText}` : '',
    values.captchaInstance ? `captchaInstance=${values.captchaInstance}` : ''
  ].filter(Boolean);

  const proxyData: Proxy = {
    method: 'GET',
    endPoint: `/powercut/Ticket/create/?${queryParams.join('&')}`,
    withAuthBearer: false
  };
  return proxy<any>(proxyData);
}

export async function verifyCP(values: any) {
  const queryParams = [
    values.countyCode ? `countyCode=${values.countyCode}` : '',
    values.localityCode ? `localityCode=${values.localityCode}` : '',
    values.streetCode ? `streetCode=${values.streetCode}` : '',
    values.measurementPointCode ? `consumptionPointCode=${values.measurementPointCode}` : ''
  ].filter(Boolean);

  const proxyData: Proxy = {
    method: 'GET',
    endPoint: `/powercut/Ticket/verify_cp/?${queryParams.join('&')}`,
    withAuthBearer: false
  };

  return proxy<any[]>(proxyData);
}
