import React, { Fragment } from 'react';
import moment from 'moment';
import { Button } from 'react-bootstrap';

const TableItem = (props: { item: any, getDetails: any }) => {
  const { item, getDetails } = props;

  return (
    <Fragment>
      <tr>
        <td>
          <span>{item.codClient}</span>
        </td>
        <td>
          <span>{item.nrCerereRacordare}</span>
        </td>
        <td>
          <span>{item.dataSap !== '' ? moment(item.dataSap).format('DD.MM.YYYY') : '-'}</span>
        </td>
        <td>
          <span>{item.numar}</span>
        </td>
        <td>
          <span>{item.dataEmitere !== '' ? moment(item.dataEmitere).format('DD.MM.YYYY') : '-'}</span>
        </td>
        <td>
          <span>{item.tipDocument !== '' ? item.tipDocument : '-'}</span>
        </td>
        <td>
          <Button onClick={() => getDetails(item.requestId)}>Detalii</Button>
        </td>
      </tr>
    </Fragment>
  );
};

export default TableItem;