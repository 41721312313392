import { proxy, Proxy } from '@api/azureProxy';
import { store } from '@lib/store';
import axios from 'axios';

export async function GetConfig(baseUrl: string) {
  const state = store.getState();
  const proxyData: Proxy = {
    method: 'GET',
    endPoint: `/orders/${baseUrl}/configuration/`,
    withAuthBearer: true
  };

  return proxy(proxyData, {
    headers: {
      Authorization: 'Bearer ' + state.auth.token,
      'Access-Control-Allow-Headers': 'Authorization',
      'Access-Control-Allow-Credentials': true,
      'access-control-allow-methods': 'GET'
    }
  });
}

export async function registerPortfolioCps(baseUrl: string, payload: any) {
  const state = store.getState();
  const proxyData: Proxy = {
    method: 'POST',
    endPoint: `/orders/${baseUrl}/register_portfolio_cps/`,
    data: payload,
    withAuthBearer: true
  };

  return proxy(proxyData, {
    headers: {
      Authorization: 'Bearer ' + state.auth.token,
      'Access-Control-Allow-Headers': 'Authorization',
      'Access-Control-Allow-Credentials': true,
      'access-control-allow-methods': 'POST'
    }
  });
}

export async function registerNotPortfolioCp(baseUrl: string, payload: any) {
  const state = store.getState();
  const proxyData: Proxy = {
    method: 'POST',
    endPoint: `/orders/${baseUrl}/register_not_portfolio_cp/`,
    data: payload,
    withAuthBearer: true
  };

  return proxy(proxyData, {
    headers: {
      Authorization: 'Bearer ' + state.auth.token,
      'Access-Control-Allow-Headers': 'Authorization',
      'Access-Control-Allow-Credentials': true,
      'access-control-allow-methods': 'POST'
    }
  });
}

export async function listRequests(baseUrl: string, payload: any) {
  const state = store.getState();
  const proxyData: Proxy = {
    method: 'GET',
    endPoint: `/orders/${baseUrl}/list/`,
    withAuthBearer: true
  };

  return proxy(proxyData, {
    params: {
      pageNumber: payload.pageNumber ?? 1,
      pageSize: payload.pageSize ?? 10,
      sortBy: payload.sortBy,
      sortType: payload.sortType,
      statusId: payload.statusId
    },
    headers: {
      Authorization: 'Bearer ' + state.auth.token,
      'Access-Control-Allow-Headers': 'Authorization',
      'Access-Control-Allow-Credentials': true,
      'access-control-allow-methods': 'GET'
    }
  });
}

export async function getRequestDetails(baseUrl: string, payload: any) {
  const state = store.getState();
  const proxyData: Proxy = {
    method: 'GET',
    endPoint: `/orders/${baseUrl}/details?id=${payload.id}`,
    withAuthBearer: true
  };

  return proxy(proxyData, {
    headers: {
      Authorization: 'Bearer ' + state.auth.token,
      'Access-Control-Allow-Headers': 'Authorization',
      'Access-Control-Allow-Credentials': true,
      'access-control-allow-methods': 'GET'
    }
  });
}

export function downloadMergedExport(baseUrl: string, id: string) {
  const state = store.getState();

  return axios.get(`${state.options.apiHost ?? ''}/orders/${baseUrl}/download_merged_export?id=${id}`, {
    headers: {
      Authorization: 'Bearer ' + state.auth.token,
      'Ocp-Apim-Subscription-Key': state.options.subscriptionKey as string
    },
    responseType: 'arraybuffer'
  });
}

export function getReport(baseUrl: string, id: string) {
  const state = store.getState();

  return axios.get(`${state.options.apiHost ?? ''}/orders/${baseUrl}/get_report_data?id=${id}`, {
    headers: {
      Authorization: 'Bearer ' + state.auth.token,
      'Ocp-Apim-Subscription-Key': state.options.subscriptionKey as string
    }
  });
}

export function BOChartData(payload: any) {
  const state = store.getState();
  return axios.post(
      `${state.options.apiHost}/orders/BackOfficeOrdersRapoarteDateMasuraEE/action?actionId=reportdetailsapicall`,
      payload,
      {
          headers: {
              'Ocp-Apim-Subscription-Key': state.options.subscriptionKey as string,
              //@ts-ignore
              'BackOfficeSecurityContext': state.backOffice.securityContext ?? ''
          }
      }
  );
}