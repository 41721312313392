import LineChart from '@components/chart/LineChart';
import { Dropdown, DropdownOptionType } from '@components/common/Dropdown';
import React, { useEffect, useState } from 'react';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import moment from 'moment';

const MeasuredChartModal = (props: { show: boolean; setShow: any; chartData: any }) => {
  const [data, setData] = useState<any>(null);
  const [startDate, setStartDate] = useState<string | number>(-1);
  const [endDate, setEndDate] = useState<string | number>(-1);
  const [showFilters, setShowFilters] = useState<boolean>(false);
  const [filterOptions, setFilterOptions] = useState<any[]>([]);
  const [showLegend, setShowLegend] = useState<boolean>(true);

  const getLineColor = (regName: string) => {
    let reg = regName.toLowerCase();

    if ((reg.includes('total') || reg.includes('cantitate')) && reg.includes('activ') && reg.includes('consumat')) {
      return {
        backgroundColor: 'transparent',
        tension: 0.5,
        borderColor: '#ea1b0a',
        pointBorderColor: 'transparent',
        pointBorderWidth: 4,
        pointRadius: 3,
        pointBackgroundColor: '#ea1b0a',
        spanGaps: true
      };
    } else if (reg.includes('index') && reg.includes('activ') && reg.includes('consumat')) {
      return {
        backgroundColor: 'transparent',
        tension: 0.5,
        borderColor: '#fc665b',
        pointBorderColor: 'transparent',
        pointBorderWidth: 4,
        pointRadius: 3,
        pointBackgroundColor: '#fc665b',
        spanGaps: true
      };
    } else if ((reg.includes('total') || reg.includes('cantitate')) && reg.includes('reactiv') && reg.includes('inductiv')) {
      return {
        backgroundColor: 'transparent',
        tension: 0.5,
        borderColor: '#fcca03',
        pointBorderColor: 'transparent',
        pointBorderWidth: 4,
        pointRadius: 3,
        pointBackgroundColor: '#fcca03',
        spanGaps: true
      };
    } else if (reg.includes('index') && reg.includes('reactiv') && reg.includes('inductiv')) {
      return {
        backgroundColor: 'transparent',
        tension: 0.5,
        borderColor: '#ffe066',
        pointBorderColor: 'transparent',
        pointBorderWidth: 4,
        pointRadius: 3,
        pointBackgroundColor: '#ffe066',
        spanGaps: true
      };
    } else if ((reg.includes('total') || reg.includes('cantitate')) && reg.includes('reactiv') && reg.includes('capacitiv')) {
      return {
        backgroundColor: 'transparent',
        tension: 0.5,
        borderColor: '#038cfc',
        pointBorderColor: 'transparent',
        pointBorderWidth: 4,
        pointRadius: 3,
        pointBackgroundColor: '#038cfc',
        spanGaps: true
      };
    } else if (reg.includes('index') && reg.includes('reactiv') && reg.includes('capacitiv')) {
      return {
        backgroundColor: 'transparent',
        tension: 0.5,
        borderColor: '#63b9ff',
        pointBorderColor: 'transparent',
        pointBorderWidth: 4,
        pointRadius: 3,
        pointBackgroundColor: '#63b9ff',
        spanGaps: true
      };
    } else if ((reg.includes('reactiv') && reg.includes('produs')) || (reg.includes('activ') && reg.includes('produs'))) {
      return {
        backgroundColor: 'transparent',
        tension: 0.5,
        borderColor: '#49c476',
        pointBorderColor: 'transparent',
        pointBorderWidth: 4,
        pointRadius: 3,
        pointBackgroundColor: '#49c476',
        spanGaps: true
      };
    } else
      return {
        backgroundColor: 'transparent',
        tension: 0.5,
        borderColor: '#8f1818',
        pointBorderColor: 'transparent',
        pointBorderWidth: 4,
        pointRadius: 3,
        pointBackgroundColor: '#8f1818',
        spanGaps: true
      };
  };

  const getRegColor = (reg: string) => {
    switch (reg) {
      // red
      case '1.8.0':
        return '#fc665b';
      case '1.9.0':
        return '#ea1b0a';

      // green
      case '2.8.0':
        return '#49c476';
      case '2.9.0':
        return '#00b843';

      // yellow
      case '3.8.0':
        return '#ffe066';
      case '3.9.0':
        return '#fcca03';
      case '5.8.0':
        return '#ffe066';
      case '5.9.0':
        return '#fcca03';
      case '6.8.0':
        return '#ffe066';
      case '6.9.0':
        return '#fcca03';

      // blue
      case '4.8.0':
        return '#63b9ff';
      case '4.9.0':
        return '#038cfc';
      case '7.8.0':
        return '#63b9ff';
      case '7.9.0':
        return '#038cfc';
      case '8.8.0':
        return '#63b9ff';
      case '8.9.0':
        return '#038cfc';

      default:
        return '#ea1b0a';
    }
  };

  const getRegName = (reg: string) => {
    switch (reg) {
      case '1.8.0':
        return 'Index energie activă consumată (kWh)';
      case '1.9.0':
        return 'Cantitate de energie activă consumată la 15’ (kWh)';
      case '2.8.0':
        return 'Index energie activă livrată în rețea (kWh)';
      case '2.9.0':
        return 'Cantitate de energie activă livrată în rețea la 15’ (kWh)';
      case '3.8.0':
        return 'Index de energie reactivă inductivă (kVARh)';
      case '3.9.0':
        return 'Cantitate de energie reactivă inductivă la 15’ (kVARh)';
      case '4.8.0':
        return 'Index de energie reactivă capacitivă (kVARh)';
      case '4.9.0':
        return 'Cantitate de energie reactivă capacitivă la 15’ (kVARh)';
      case '5.8.0':
        return 'Index de energie reactivă inductivă în Q1 (kVARh)';
      case '5.9.0':
        return 'Cantitate de energie reactivă inductivă la 15’ în Q1 (kVARh)';
      case '6.8.0':
        return 'Index de energie reactivă inductivă în Q2 (kVARh)';
      case '6.9.0':
        return 'Cantitate de energie reactivă inductivă la 15’ în Q2 (kVARh)';
      case '7.8.0':
        return 'Index de energie reactivă capacitivă în Q3 (kVARh)';
      case '7.9.0':
        return 'Cantitate de energie reactivă capacitivă la 15’ în Q3 (kVARh)';
      case '8.8.0':
        return 'Index de energie reactivă capacitivă în Q4 (kVARh)';
      case '8.9.0':
        return 'Cantitate de energie reactivă capacitivă la 15’ în Q4 (kVARh)';
      default:
        return 'Registru';
    }
  };

  const options = {
    plugins: {
      legend: false,
      tooltip: {
        callbacks: {
          title: function (tooltipItem: any, data: any) {
            return (startDate === endDate && startDate !== -1) || (startDate !== -1 && endDate === -1) ? tooltipItem[0].label : moment(tooltipItem[0].label, 'DD.MM.YYYY H:mm').format('DD.MM.YYYY H:mm');
          },
          label: function (tooltipItem: any, data: any) {
            return data;
          }
        }
      },
    },
    scales: {
      x: {
        grid: {
          display: false
        },
        ticks: {
          callback: (val: any) => {
            return (startDate === endDate && startDate !== -1) || (startDate !== -1 && endDate === -1) ? data.labels[val] : moment(data.labels[val], 'DD.MM.YYYY H:mm').format('DD.MM.YYYY');
          }
        }
      },
      y: {
        ticks: {
          stepSize: 1
        }
      }
    }
  };

  const searchForFilterOption = (key: string) => {
    let found = false;

    filterOptions.map((option: any, index: number) => {
      if (key === option.name && option.active === true) {
        found = true;
      }
    })

    return found;
  }

  const getDatasets = (data: any) => {
    let datasets: any[] = [];

    Object.keys(data[0]).forEach(function (key, index) {
      if (searchForFilterOption(key) || key.toLowerCase().includes('data')) {
        let dataset = {
          label: key,
          data: filterDates(data).map((item: any) => item[key]),
          ...getLineColor(key)
        };

        datasets.push(dataset);
      }
    });

    return datasets;
  };

  const getDays = (data: any, type: string) => {
    let days: any[] = [];
    let dArray: any[] = [];

    dArray.push({ name: type, id: -1 });

    data.map((item: any) => {
      if (!days.includes(moment(item['Data citirii'], 'DD.MM.YYYY').format('MM.DD.YYYY'))) {
        days.push(moment(item['Data citirii'], 'DD.MM.YYYY').format('MM.DD.YYYY'));
        dArray.push({
          name: moment(item['Data citirii'], 'DD.MM.YYYY').format('DD.MM.YYYY'),
          id: moment(item['Data citirii'], 'DD.MM.YYYY').format('MM.DD.YYYY')
        });
      }
    });

    return dArray;
  };

  const getDataTypes = (data: any) => {
    let op: any[] = [];

    Object.keys(data[0]).forEach(function (key, index) {
      if (!key.toLowerCase().includes('data') && !key.toLowerCase().includes('status')) {
        op.push({ name: key, id: key, active: true });
      }
    });

    return op;
  };

  const filterDates = (data: any) => {
    // interval
    if (startDate !== -1 && endDate !== -1) {
      return data.filter(
        (item: any) =>
          moment(item['Data citirii'], 'DD.MM.YYYY').format('MM.DD.YYYY') >= moment(startDate).format('MM.DD.YYYY') &&
          moment(item['Data citirii'], 'DD.MM.YYYY').format('MM.DD.YYYY') <= moment(endDate).format('MM.DD.YYYY')
      );
    }

    // start only
    if (startDate !== -1 && endDate === -1) {
      return data.filter(
        (item: any) => moment(item['Data citirii'], 'DD.MM.YYYY').format('MM.DD.YYYY') === moment(startDate).format('MM.DD.YYYY')
      );
    }

    // whole date
    if (startDate === -1 && endDate === -1) {
      return data;
    }
  };

  useEffect(() => {
    setFilterOptions(getDataTypes(props.chartData[0].data));
  }, []);

  useEffect(() => {
    setData({
      labels: filterDates(props.chartData[0].data).map((item: any) =>
        (startDate === endDate && startDate !== -1) || (startDate !== -1 && endDate === -1)
          ? moment(item['Data citirii'], 'DD.MM.YYYY H:mm').format('H:mm')
          : moment(item['Data citirii'], 'DD.MM.YYYY H:mm').format('DD.MM.YYYY H:mm')
      ),
      datasets: getDatasets(props.chartData[0].data)
    });
  }, [startDate, endDate, filterOptions]);

  const setFilterOptionActive = (option: any) => {
    let op = filterOptions;

    op.map((el: any, i: number) => {
      if (el.name === option.name) {
          el.active = !el.active;
      }
    });

    setFilterOptions([...op]);
  }

  return (
    <Modal
      className="rss-modal"
      centered
      backdrop={'static'}
      show={props.show}
      onExit={() => props.setShow(false)}
      onHide={() => props.setShow(false)}
    >
      <Modal.Header closeButton>
        <h4 className="red">Grafic date măsură</h4>
      </Modal.Header>
      <Modal.Body>
        <Row className="d-flex mb-4">
          {/* <Col xs={4}>
            <Dropdown
              options={
                getDataTypes(props.chartData[0].data).map((option: any, index: number) => ({
                  id: option.id,
                  name: option.name
                })) as DropdownOptionType[]
              }
              label={'Tip raport'}
              displaySearch={false}
              onChange={(value: string | number) => {
                setDataType(value);
              }}
              value={dataType}
              mbZero
            />
          </Col> */}
          <Col xs={3}>
            <Button className="btn-large btn-white w-100" onClick={() => setShowFilters(filters => !filters)}>Tip raport</Button>
          </Col>
          <Col xs={3}>
            <Button className="btn-large btn-white w-100" onClick={() => setShowLegend(prev => !prev)}>{showLegend ? 'Ascunde legenda' : 'Vezi legenda'}</Button>
          </Col>
          <Col xs={3}>
            <Dropdown
              options={
                getDays(props.chartData[0].data, 'Toate').map((option: any, index: number) => ({
                  id: option.id,
                  name: option.name
                })) as DropdownOptionType[]
              }
              label={'Dată început'}
              displaySearch={false}
              onChange={(value: string | number) => {
                (value === -1 || value > endDate) && setEndDate(-1);
                setStartDate(value);
              }}
              value={startDate}
              mbZero
            />
          </Col>
          {startDate !== -1 && (
            <Col xs={3}>
              <Dropdown
                options={
                  getDays(props.chartData[0].data, 'Niciuna')
                    .filter((item: any) => moment(item.id) >= moment(startDate) || item.id === -1)
                    .map((option: any, index: number) => ({
                      id: option.id,
                      name: option.name
                    })) as DropdownOptionType[]
                }
                label={'Dată sfârșit'}
                displaySearch={false}
                onChange={(value) => {
                  setEndDate(value);
                }}
                disabled={startDate === -1}
                value={endDate}
                mbZero
              />
            </Col>
          )}
        </Row>
        {showFilters && <Row className="mb-3">
          {filterOptions.map((option: any, index: number) => (
            <Col xs={2} key={index} className="mb-1 pointer" onClick={() => setFilterOptionActive(option)}>
              <input 
                type="checkbox" 
                value={option}
                onChange={() => null}
                checked={option.active}
                className="create-account-checkbox pointer"
                style={{ marginRight: '8px' }}
              />
              <span>{option.name}</span>
            </Col>
          ))}
        </Row>}
        {showLegend && <Row className="d-flex">
          {props.chartData[0].registerData
            .filter((item: any) => (props.chartData[0].type === 'Raport indecși zilnici' ? !item['Registru'].includes('9.0') : item))
            .map((item: any, index: number) => {
              return (
                <Col xs={2} key={index} className="mb-2">
                  <div className="d-flex flex-column" style={{ fontSize: 15 }}>
                    <div>
                      <div
                        style={{
                          marginRight: 5,
                          width: 25,
                          height: 15,
                          backgroundColor: getRegColor(item['Registru'])
                        }}
                      ></div>
                      {getRegName(item['Registru'])}
                    </div>
                  </div>
                </Col>
              );
            })}
        </Row>}
        <div className="mt-4 mb-4">{data && <LineChart chartData={data} options={options} />}</div>
      </Modal.Body>
    </Modal>
  );
};

export default MeasuredChartModal;