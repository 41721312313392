import { Proxy, proxy } from '@api/azureProxy';
import { kenticoTemplatesUrl } from '@constants/ApiUrl';
import { store } from '@lib/store';
import { proxy as kenticoProxy, Proxy as KenticoProxy } from '@api/kenticoProxy';
import { AxiosRequestConfig } from 'axios';

export function getSupplierTechnicalDocuments(payload: any) {
  const state = store.getState();
  const proxyData: Proxy = {
    method: 'GET',
    endPoint: `/authentication/User/supplier_technical_documents?clc=${payload.clc}`,
    data: payload,
    withAuthBearer: true
  };
  return proxy(proxyData, {
    headers: {
      Authorization: 'Bearer ' + state.auth.token,
      'Access-Control-Allow-Headers': 'Authorization',
      'Access-Control-Allow-Credentials': true,
      'access-control-allow-methods': 'GET'
    }
  });
}

export function getSupplierTechnicalDocumentDownload(payload: any) {
  const state = store.getState();
  const proxyData: Proxy = {
    method: 'GET',
    endPoint: `/authentication/User/supplier_technical_documents_content?clc=${payload.clc}`,
    data: payload,
    withAuthBearer: true
  };
  return proxy(proxyData, {
    headers: {
      Authorization: 'Bearer ' + state.auth.token,
      'Access-Control-Allow-Headers': 'Authorization',
      'Access-Control-Allow-Credentials': true,
      'access-control-allow-methods': 'GET'
    },
    responseType: 'arraybuffer'
  });
}

export async function getTemplatesTechnicalDocuments() {
  const state = store.getState();

  const proxyData: KenticoProxy = {
    method: 'GET',
    endPoint: `${kenticoTemplatesUrl}?path=/Template-uri/Documente-tehnice-EE/Mesaj`,
    withAuthBearer: false
  };

  const options: AxiosRequestConfig = {
    headers: {
      XApiKey: state.options.xApiKey ?? ''
    }
  };

  return kenticoProxy<any>(proxyData, options);
}
