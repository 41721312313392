import { toggleLoader } from '@actions/LoaderActions';
import { getCaptcha } from '@api/captcha';
import { GetCounties, GetLocalities, GetStreets } from '@api/geoApi';
import { Dropdown, DropdownOptionType } from '@components/common/Dropdown';
import { Input } from '@components/common/Input';
import { Multiselect } from '@components/common/Multiselect';
import { faArrowRotateRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CountyTemplate, LocalityTemplate, StreetTemplate } from '@type/geo';
import { useEffect, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { isObjectEmpty } from '../../helpers/object';
import { REGEX_EMAIL_RULES } from '../../helpers/regex';
import { interruptionsUnsubscribeEE, postInterruptionsSubscribeEE } from '@api/intreruperiProgramateEE/intreruperiProgramateEE';
import { GENERAL_ERROR_MESSAGE } from '@constants/Errors';
import * as notification from '@lib/Notification';
import { getQueryParam, removeQueryParam } from '../../helpers/urlUtils';
import ChoiceModal from '@components/common/Modal/ChoiceModal';

export const PLANNED_INTERRUPTION_STATUS_ID_CANCELED = 2;

const AbonareIntreruperiProgramateEE = (props: any) => {
  const [counties, setCounties] = useState<CountyTemplate[]>([]);
  const [localities, setLocalities] = useState<LocalityTemplate[]>([]);
  const [streets, setStreets] = useState<StreetTemplate[]>([]);

  const [county, setCounty] = useState('');
  const [locality, setLocality] = useState('');
  const [selectedStreets, setSelectedStreets] = useState<DropdownOptionType[]>([]);
  const [email, setEmail] = useState('');

  const [countyTouched, setCountyTouched] = useState(false);
  const [localityTouched, setLocalityTouched] = useState(false);
  const [selectedStreetsTouched, setSelectedStreetsTouched] = useState(false);
  const [emailTouched, setEmailTouched] = useState(false);
  const [captchaTouched, setCaptchaTouched] = useState(false);

  //captcah
  const [captchaImage, setCaptchaImage] = useState<string | null>(null);
  const [captcha, setCaptcha] = useState<string>('');
  const [instanceId, setInstanceId] = useState<string>('');

  const [error, setError] = useState('');
  const [errorCaptchaValidation, setErrorCaptchaValidation] = useState('');
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [success, setSuccess] = useState(false);

  //unsubscribe
  const [showModalUnsubscribe, setShowModalUnsubscribe] = useState(false);
  const [unsubscribeEmail, setUnsubscribeEmail] = useState('');
  const [unsubscribeValidationKey, setUnsubscribeValidationKey] = useState('');

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(toggleLoader(true, 'GetCounties'));

    let isUnsubscribe = getQueryParam('unsubscribe');
    let email = getQueryParam('email');
    let validationKey = getQueryParam('validationKey');

    if (isUnsubscribe) {
      if (!email || !validationKey) {
        setError(GENERAL_ERROR_MESSAGE);
      } else {
        setShowModalUnsubscribe(true);
        setUnsubscribeEmail(email);
        setUnsubscribeValidationKey(validationKey);
      }
    }

    GetCounties('EE').then((counties) => {
      setCounties(counties);
      dispatch(toggleLoader(false, 'GetCounties'));
    });
    apiGetCaptcha();
  }, []);

  useEffect(() => {
    setLocality('');
    if (county) {
      GetLocalities(county).then((res) => setLocalities(res));
    }
  }, [county]);

  useEffect(() => {
    setSelectedStreets([]);
    if (locality) {
      GetStreets(localities.find((l) => l.localityCode == locality)?.localityId + '').then((res) => {
        let streets = res as any[];
        streets = streets.filter((street: any) => street.streetName !== '');
        setStreets(streets);
      });
    }
  }, [locality]);

  useEffect(() => {
    if (countyTouched) validateValues('county');
  }, [county, countyTouched]);

  useEffect(() => {
    if (localityTouched) validateValues('locality');
  }, [locality, localityTouched]);

  useEffect(() => {
    if (selectedStreetsTouched) validateValues('streets');
  }, [selectedStreets, selectedStreetsTouched]);

  useEffect(() => {
    if (emailTouched) validateValues('email');
  }, [email, emailTouched]);

  useEffect(() => {
    if (captchaTouched) validateValues('captcha');
  }, [captcha, captchaTouched]);

  const apiGetCaptcha = async () => {
    dispatch(toggleLoader(true));

    await getCaptcha().then((response) => {
      setCaptchaImage(response.image);
      setInstanceId(response.imageInstanceId);
    });

    dispatch(toggleLoader(false));
  };

  const submit = () => {
    if (!validateValues()) return;
    dispatch(toggleLoader(true));
    setError('');
    setErrorCaptchaValidation('');

    let payload: any = {
      email: email,
      countyCode: county,
      localityCode: locality,
      streets: selectedStreets.map((s) => s.id),
      captchaInstance: instanceId,
      captchaText: captcha
    };

    postInterruptionsSubscribeEE(payload)
      .then((res) => {
        notification.success('Abonare salvată cu succes.');
        setSuccess(true);
        setTimeout(() => {
          window.location.href = '/energie-electrica/intreruperi-programate-energie-electrica';
        }, 2000);
        dispatch(toggleLoader(false));
      })
      .catch((err) => {
        if (err.status == 200) {
          if (err.data == 0) {
            notification.warning('Străzile selectate au fost deja salvate.');
            setCaptchaTouched(false);
            apiGetCaptcha();
            setCaptcha('');
          } else {
            notification.success('Abonare salvată cu succes.');
            setSuccess(true);
            setTimeout(() => {
              window.location.href = '/energie-electrica/intreruperi-programate-energie-electrica';
            }, 2000);
          }
        } else {
          if (err.includes('Codul captcha nu e valid!')) {
            setError('');
            setErrorCaptchaValidation(err);
            apiGetCaptcha();
            setCaptcha('');
          } else if (err.toLowerCase().includes('limita maximă')) {
            setError(err);
          } else {
            setError(GENERAL_ERROR_MESSAGE);
          }
        }
        dispatch(toggleLoader(false));
      });
  };

  const validateValues = (field?: string) => {
    let newErrors: { [key: string]: string } = { ...errors };

    if (!field || field === 'county') {
      delete newErrors['county'];
      if (!county) {
        newErrors['county'] = 'Câmp obligatoriu.';
      }
    }

    if (!field || field === 'locality') {
      delete newErrors['locality'];
      if (!locality) {
        newErrors['locality'] = 'Câmp obligatoriu.';
      }
    }
    if (!field || field === 'email') {
      delete newErrors['email'];
      if (!email) {
        newErrors['email'] = 'Câmp obligatoriu.';
      } else if (!REGEX_EMAIL_RULES.test(email)) {
        newErrors['email'] = 'Adresa de email conține caractere nepermise, ori are formatul greșit.';
      }
    }
    if (!field || field === 'streets') {
      delete newErrors['streets'];
      if (!selectedStreets.length) {
        newErrors['streets'] = 'Câmp obligatoriu.';
      }
    }
    if (!field || field === 'captcha') {
      delete newErrors['captcha'];
      if (!captcha.length) {
        newErrors['captcha'] = 'Câmp obligatoriu.';
      }
    }

    setErrors(newErrors);
    return isObjectEmpty(newErrors);
  };

  const unsubscribe = () => {
    dispatch(toggleLoader(true));

    interruptionsUnsubscribeEE({ email: unsubscribeEmail, key: unsubscribeValidationKey })
      .then((res) => {
        notification.success('Dezabonarea a fost efectuată cu succes!');
        removeUnsubscribeUrlParams();
        setShowModalUnsubscribe(false);
        dispatch(toggleLoader(false));
      })
      .catch((err) => {
        if (err.status == 200) {
          notification.success('Dezabonarea a fost efectuată cu succes!');
          setShowModalUnsubscribe(false);
          removeUnsubscribeUrlParams();
        } else {
          notification.error(GENERAL_ERROR_MESSAGE);
        }
        dispatch(toggleLoader(false));
      });
  };

  const removeUnsubscribeUrlParams = () => {
    removeQueryParam('unsubscribe');
    removeQueryParam('email');
    removeQueryParam('validationKey');
  };

  return (
    <div className="p-3">
      <Row>
        <Col xs={12} sm={6} className="mb-3">
          <Input
            label="Email*"
            mbZero
            type="text"
            onChange={(e: any) => {
              setEmailTouched(true);
              setEmail(e.target.value);
            }}
          />
          {errors['email'] && <div style={{ color: '#ea1c0a', fontWeight: 'bold' }}>{errors['email']}</div>}
        </Col>
        <Col xs={12} sm={6}></Col>

        <Col xs={12} sm={6} className="mb-3">
          <Dropdown
            options={
              counties.map((option, index) => ({
                id: option.countyCode,
                name: option.countyName
              })) as DropdownOptionType[]
            }
            label={'Județ*'}
            defaultPlaceholder={'Alege județul'}
            onChange={(value) => {
              setCounty(value);
              setCountyTouched(true);
            }}
            value={county}
            mbZero
          />
          {errors['county'] && <div style={{ color: '#ea1c0a', fontWeight: 'bold' }}>{errors['county']}</div>}
        </Col>

        <Col xs={12} sm={6} className="mb-3">
          <Dropdown
            options={
              localities.map((option, index) => ({
                id: option.localityCode,
                name: option.localityName
              })) as DropdownOptionType[]
            }
            label={'Localitate*'}
            onChange={(value) => {
              setLocality(value);
              setLocalityTouched(true);
            }}
            defaultPlaceholder={'Alege localitatea'}
            displaySearch={true}
            value={locality}
            disabled={!county}
            mbZero
          />
          {errors['locality'] && <div style={{ color: '#ea1c0a', fontWeight: 'bold' }}>{errors['locality']}</div>}
        </Col>

        <Col xs={12} className="mb-3">
          <Multiselect
            initialSelectedOptions={selectedStreets}
            options={streets.map((s) => {
              return { id: s.streetCode, name: s.streetName } as DropdownOptionType;
            })}
            onChange={(selectedOptions: any) => {
              setSelectedStreets(selectedOptions);
              setSelectedStreetsTouched(true);
            }}
            label={'Alege una sau mai multe străzi*'}
            placeholder={'Alege una sau mai multe străzi'}
            disabled={!locality}
            mbZero
          />
          {errors['streets'] && <div style={{ color: '#ea1c0a', fontWeight: 'bold' }}>{errors['streets']}</div>}
        </Col>
      </Row>

      <Row className="mb-3">
        <Col xs={12} sm={10} className="flex-row">
          {captchaImage !== null && <img src={`data:image/jpeg;base64, ${captchaImage}`} alt="captcha" />}
          <FontAwesomeIcon
            icon={faArrowRotateRight}
            color="red"
            className="mt-3 pointer"
            style={{ width: '20px', height: '20px', marginLeft: '8px' }}
            onClick={() => apiGetCaptcha()}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12} sm={4}>
          <Input
            label="Introdu codul de securitate*"
            value={captcha}
            onChange={(e) => {
              setCaptcha(e.target.value);
              setCaptchaTouched(true);
            }}
            mbZero
          />
        </Col>
        {!errorCaptchaValidation && errors['captcha'] && <div style={{ color: '#ea1c0a', fontWeight: 'bold' }}>{errors['captcha']}</div>}
        {errorCaptchaValidation && <div style={{ color: '#ea1c0a', fontWeight: 'bold' }}>{errorCaptchaValidation}</div>}
      </Row>

      {error && (
        <div className="mt-1" style={{ color: 'red', fontWeight: 'bold' }}>
          {error}
        </div>
      )}

      <Button className="btn-wide mt-2" onClick={submit} disabled={success}>
        Abonare
      </Button>

      <ChoiceModal
        showModal={showModalUnsubscribe}
        modalTitle={'Ești sigur că dorești să te dezabonezi de la notificări întreruperi energie electrică?'}
        options={[
          { id: 'DA', title: 'Da' },
          { id: 'NU', title: 'Nu' }
        ]}
        onClose={(option: any) => {
          if (option.id == 'DA') {
            unsubscribe();
          } else if (option.id == 'NU') {
            setShowModalUnsubscribe(false);
            removeUnsubscribeUrlParams();
          }
        }}
        onHide={() => {
          setShowModalUnsubscribe(false);
          removeUnsubscribeUrlParams();
        }}
        btnWide
      />
    </div>
  );
};

export default AbonareIntreruperiProgramateEE;
