import { toggleLoader } from '@actions/LoaderActions';
import { getBulkExportEntriesByStatus, getBulkImportDetails, postBulkConfirmImport } from '@api/revizii/revizii';
import ChoiceModal from '@components/common/Modal/ChoiceModal';
import { CustomModal } from '@components/common/ModalConfirm';
import Pagination from '@components/common/Pagination/Pagination';
import SortAscDesc from '@components/common/Sort/SortAscDesc';
import {
  STATUS_ANULAT,
  STATUS_ID_INVALID,
  STATUS_ID_PARTIAL_VALID,
  STATUS_INVALID,
  STATUS_PARTIAL_VALID,
  STATUS_VALID
} from '@constants/revizii/Revizii';
import * as notification from '@lib/Notification';
import { Fragment, useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { downloadBlobWithFilename } from '../../../helpers/download';

type RevisionType = {
  bulkImportId: number;
  bulkImportStatusnumber: number;
  bulkImportEntryId: number;
  clc: string;
  pod: string;
  county: string;
  locality: string;
  optionType: string;
  contactNumber: string;
  contactDate: string;
  sheetNumber: string;
  sheetRegisterDate: string;
  verifyDate: string;
  eraNumber: string;
  status: string;
  errors: {
    propertyName: string;
    errorMessage: string;
  }[];
};

type ConfirmareProps = {
  bulkId: string;
  resetComponentState: () => any;
};

const Confirmare = (props: ConfirmareProps) => {
  const { bulkId, resetComponentState } = props;
  const [revisionList, setRevisionList] = useState<RevisionType[]>([]);

  const [selectedItemErrorsArray, setSelectedItemErrorsArray] = useState([]);
  const [showModalErrors, setShowModalErrors] = useState(false);

  //buttons
  const [showButtonContinueWithValids, setShowButtonContinueWithValids] = useState(false);
  const [showButtonContinueWithPartialValids, setShowButtonContinueWithPartialValids] = useState(false);

  //modals
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [confirmModalType, setConfirmModalType] = useState('');
  const [showModalCancel, setShowModalCancel] = useState(false);

  //pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const paginate = (pageNumber: number) => setCurrentPage(pageNumber);
  const setElementsPerPage = (number: number) => setPerPage(number);
  const [totalItemsCount, setTotalItemsCount] = useState(0);
  const [sortColumn, setSortColumn] = useState('BulkImportEntryId');
  const [sortAscending, setSortAscending] = useState(true);
  const [filterStatus, setFilterStatus] = useState<any>('');
  const [summary, setSummary] = useState<any>([]);
  const [initialTotalItemsCount, setInitialTotalItemsCount] = useState(0);

  const [error, setError] = useState('');

  const dispatch = useDispatch();

  useEffect(() => {
    getBulkDetails();
  }, [currentPage, perPage, sortColumn, sortAscending, filterStatus]);

  useEffect(() => {
    if (bulkId) {
      getBulkDetails();
    }
  }, [bulkId]);

  useEffect(() => {
    revisionList.forEach((item) => {
      if (item.status == STATUS_VALID) {
        setShowButtonContinueWithValids(true);
      }
      if (item.status == STATUS_PARTIAL_VALID) {
        setShowButtonContinueWithPartialValids(true);
      }
    });
  }, [revisionList]);

  useEffect(() => {
    if (totalItemsCount && !initialTotalItemsCount) {
      setInitialTotalItemsCount(totalItemsCount);
    }
  }, [totalItemsCount, initialTotalItemsCount]);

  const getBulkDetails = () => {
    dispatch(toggleLoader(true));
    let payload = {
      pageNumber: currentPage,
      pageSize: perPage,
      sortBy: sortColumn,
      sortType: sortAscending ? 'asc' : 'desc',
      statusId: filterStatus,
      bulkImportId: bulkId
    };
    getBulkImportDetails(payload)
      .then((res) => {
        setRevisionList(res.items);
        setTotalItemsCount(res.metadata.totalItemsCount);
        setSummary(res.summary);
        dispatch(toggleLoader(false));
      })
      .catch((err) => {
        setError('A apărut o eroare. Vă rugăm să reveniți mai tărziu.');
        dispatch(toggleLoader(false));
      });
  };

  const handleShowErrorsForItem = (item: any) => {
    setSelectedItemErrorsArray(item.errors);
    setShowModalErrors(true);
  };

  const handleModalConfirm = (option: any) => {
    //send
    if (option.id == 'DA') {
      dispatch(toggleLoader(true));

      let payload = { bulkId: bulkId, entryStatusIds: confirmModalType == 'VAL' ? [1] : [1, 2] };

      postBulkConfirmImport(payload)
        .then((res) => {
          notification.success('Acțiune de confirmare reusită.');
          setTimeout(() => {
            window.location.href = `/detalii-incarcare-in-masa?bulkId=${bulkId}`;
          }, 2000);
        })
        .catch((err) => {
          if (err.status == 200) {
            notification.success('Acțiune de confirmare reusită.');
            setTimeout(() => {
              window.location.href = `/detalii-incarcare-in-masa?bulkId=${bulkId}`;
            }, 2000);
          } else {
            notification.error('A apărut o eroare. Vă rugăm să reveniți mai tărziu.');
            dispatch(toggleLoader(false));
          }
        });
      setShowConfirmModal(false);
    }
    if (option.id == 'NU') {
      setShowConfirmModal(false);
    }
  };

  const handleCancelModal = (option: any) => {
    if (option.id == 'DA') {
      dispatch(toggleLoader(true));

      // se trimite entryStatusIds: [] pentru anulare
      let payload = { bulkId: bulkId, entryStatusIds: [] };

      postBulkConfirmImport(payload)
        .then((res) => {
          notification.success('Acțiune de anulare reusită.');
          resetComponentState();
          dispatch(toggleLoader(false));
        })
        .catch((err) => {
          if (err.status == 200) {
            notification.success('Acțiune de anulare reusită.');
            resetComponentState();
          } else {
            notification.error('A apărut o eroare. Vă rugăm să reveniți mai tărziu.');
          }
          dispatch(toggleLoader(false));
        });
      setShowConfirmModal(false);
    } else {
      setShowModalCancel(false);
    }
  };

  const handleExport = async () => {
    dispatch(toggleLoader(true));

    let payload = {
      bulkId: bulkId,
      statusId: filterStatus
    };

    let res = '' as BlobPart;
    res = await getBulkExportEntriesByStatus(payload).catch((err) =>
      notification.error('A apărut o eroare. Vă rugăm să reveniți mai tărziu.')
    );

    downloadBlobWithFilename(
      res,
      `export-incarcare-in-masa-${filterStatus == STATUS_ID_INVALID ? 'invalide' : 'partial-valide'}`,
      '.xlsx',
      true,
      () => dispatch(toggleLoader(false)),
      (err: any) => notification.error('A apărut o eroare. Vă rugăm să reveniți mai tărziu.')
    );
  };

  const onSort = (column: string) => {
    setSortColumn(column);
    if (sortColumn == column) {
      setSortAscending(!sortAscending);
    } else {
      setSortAscending(true);
    }
  };

  return (
    <Fragment>
      <div className="d-flex justify-content-between mt-2 mb-3 flex-wrap">
        <div>
          <div className="gray-filters-list flex-wrap">
            <div className="d-flex flex-column mb-2">
              <span
                className={!filterStatus ? 'active' : 'not-active'}
                onClick={() => {
                  setCurrentPage(1);
                  setFilterStatus(null);
                }}
              >
                <span className="m-2">Total</span>
                <span className="bg-eon-lightgrey fw-bold" style={{ marginRight: '10px', borderRadius: '4px', padding: '2px 6px' }}>
                  {initialTotalItemsCount}
                </span>
              </span>
            </div>
            {summary &&
              summary.length > 0 &&
              summary[0].values.map((item: any, index: number) => {
                let active = filterStatus === item.id;
                if (!item.count) return;

                return (
                  <div className="d-flex flex-column mb-2" key={index}>
                    <span
                      className={active ? 'active' : 'not-active'}
                      key={index}
                      onClick={() => {
                        setCurrentPage(1);
                        !active ? setFilterStatus(item.id) : setFilterStatus('');
                      }}
                    >
                      <span className="m-2">{item.name}</span>
                      <span className="bg-eon-lightgrey fw-bold" style={{ marginRight: '10px', borderRadius: '4px', padding: '2px 6px' }}>
                        {item.count}
                      </span>
                    </span>
                    {active && (filterStatus == STATUS_ID_PARTIAL_VALID || filterStatus == STATUS_ID_INVALID) && (
                      <a onClick={handleExport} className="link link--download download-link red pointer hover-red mx-2">
                        Descarcă
                      </a>
                    )}
                  </div>
                );
              })}
          </div>
        </div>
        <div>
          {showButtonContinueWithValids && (
            <Button
              className="me-2 mb-2"
              onClick={() => {
                setConfirmModalType('VAL');
                setShowConfirmModal(true);
              }}
            >
              Continuă cu locurile valide
            </Button>
          )}
          {showButtonContinueWithPartialValids && (
            <Button
              className="me-2 mb-2"
              onClick={() => {
                setConfirmModalType('PVAL');
                setShowConfirmModal(true);
              }}
            >
              Continuă cu locurile valide / parțial valide
            </Button>
          )}
          <Button className="mb-2" onClick={() => setShowModalCancel(true)}>
            Anulează
          </Button>
        </div>
      </div>

      {revisionList.length > 0 && (
        <div className="general-table-container">
          <table>
            <thead>
              <tr>
                <th>
                  <SortAscDesc
                    sortColumn={sortColumn}
                    sortAscending={sortAscending}
                    onSort={onSort}
                    fieldId="clc"
                    fieldName="Identificator loc Consum"
                  />
                </th>
                <th>
                  <SortAscDesc sortColumn={sortColumn} sortAscending={sortAscending} onSort={onSort} fieldId="county" fieldName="Județ" />
                </th>
                <th>
                  <SortAscDesc
                    sortColumn={sortColumn}
                    sortAscending={sortAscending}
                    onSort={onSort}
                    fieldId="locality"
                    fieldName="Localitate"
                  />
                </th>
                <th>
                  <SortAscDesc
                    sortColumn={sortColumn}
                    sortAscending={sortAscending}
                    onSort={onSort}
                    fieldId="optionType"
                    fieldName="Tip lucrare"
                  />
                </th>
                <th>
                  <SortAscDesc
                    sortColumn={sortColumn}
                    sortAscending={sortAscending}
                    onSort={onSort}
                    fieldId="sheetNumber"
                    fieldName="Număr fișă"
                  />
                </th>
                <th>
                  <SortAscDesc
                    sortColumn={sortColumn}
                    sortAscending={sortAscending}
                    onSort={onSort}
                    fieldId="sheetRegisterDate"
                    fieldName="Dată înregistrare fișă"
                  />
                </th>
                <th>
                  <SortAscDesc
                    sortColumn={sortColumn}
                    sortAscending={sortAscending}
                    onSort={onSort}
                    fieldId="verifyDate"
                    fieldName="Data efectuării reviziei/verificării"
                  />
                </th>
                <th>Status</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {revisionList.map((item: any, index: number) => (
                <tr key={index}>
                  <td>{item.consumptionPoint ? item.consumptionPoint : '-'}</td>
                  <td>{item.county ? item.county : '-'}</td>
                  <td>{item.locality ? item.locality : '-'}</td>
                  <td>{item.optionType ? item.optionType : '-'}</td>
                  <td>{item.sheetNumber ? item.sheetNumber : '-'}</td>
                  <td>{item.sheetRegisterDate ? item.sheetRegisterDate : '-'}</td>
                  <td>{item.verifyDate ? item.verifyDate : '-'}</td>
                  <td>
                    {item.status}
                    {(item.status == STATUS_INVALID || item.status == STATUS_ANULAT) && item.errorMessage && (
                      <div className="p-gray" style={{ fontSize: '12px' }}>
                        {item.errorMessage}
                      </div>
                    )}
                  </td>
                  <td>
                    <div className="align-items-center d-flex">
                      {item.status == STATUS_PARTIAL_VALID && (
                        <Button
                          onClick={() => {
                            handleShowErrorsForItem(item);
                          }}
                          className="ms-2"
                        >
                          Erori
                        </Button>
                      )}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      {error && <div style={{ color: 'red', fontWeight: 'bold' }}>{error}</div>}

      <Pagination
        currentPage={currentPage}
        totalElements={totalItemsCount}
        perPage={perPage}
        paginate={paginate}
        setElementsPerPage={setElementsPerPage}
      />

      <CustomModal
        showModal={showModalErrors}
        modalTitle={'Erori'}
        onClose={() => {
          setShowModalErrors(false);
        }}
        mtZero
      >
        <div>
          {selectedItemErrorsArray.map((error: any, index) => {
            return (
              <p key={index}>
                {index + 1}. <strong>{error.propertyName}</strong> - {error.errorMessage}
              </p>
            );
          })}
        </div>
      </CustomModal>

      <ChoiceModal
        showModal={showConfirmModal}
        modalTitle={
          confirmModalType == 'VAL'
            ? 'Doriti continuarea solicitarii doar cu locurile de consum valide?'
            : 'Continua solicitarea cu locurile de consum valide si partial valide'
        }
        options={[
          { title: 'Da', id: 'DA' },
          { title: 'Nu', id: 'NU' }
        ]}
        onClose={handleModalConfirm}
        onHide={() => setShowConfirmModal(false)}
        btnWide
      ></ChoiceModal>

      <ChoiceModal
        showModal={showModalCancel}
        modalTitle={'Doriți anularea acestei solicitări?'}
        options={[
          { title: 'Da', id: 'DA' },
          { title: 'Nu', id: 'NU' }
        ]}
        onClose={handleCancelModal}
        onHide={() => setShowModalCancel(false)}
        btnWide
      ></ChoiceModal>
    </Fragment>
  );
};

export default Confirmare;
