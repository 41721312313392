import { Action } from '.';
import { v4 as uuidv4 } from 'uuid';
import { DeviceDetect } from '@lib/deviceDetect';
import { CLEANUP_DEVICE } from '@constants/ActionTypes/PostTypes';

const getDeviceId = DeviceDetect(uuidv4());

export class DeviceIdPayload {
    deviceId?: string = getDeviceId;
}

const initialState = {deviceId: getDeviceId};

export function device(state = initialState, action = {} as Action<DeviceIdPayload>) {
    const { type } = action;

    switch (type) {
        case CLEANUP_DEVICE: {
            const stateToKeep = new DeviceIdPayload();

            return { ...initialState, ...stateToKeep };
        }
        default:
            return state;
    }
}