import { Proxy, proxy } from '@api/azureProxy';
import { store } from '@lib/store';

export function getInterruptionsEE(payload: any) {
  const state = store.getState();
  const proxyData: Proxy = {
    method: 'GET',
    endPoint: '/powercut/PlannedInterruptionsNotifications/list',
    data: payload,
    withAuthBearer: true
  };

  return proxy(proxyData, {
    params: {
      active: payload.active,
      pageNumber: payload.pageNumber ?? 1,
      pageSize: payload.pageSize ?? 10,
      countyCode: payload.countyCode,
      localityCode: payload.localityCode,
      keywords: payload.keywords,
      isCurrentDay: payload.isCurrentDay, // for active
      isCurrentWeek: payload.isCurrentWeek, // for active
      startDate: payload.startDate, // for history
      endDate: payload.endDate // for history
    },
    headers: {
      Authorization: 'Bearer ' + state.auth.token,
      'Access-Control-Allow-Headers': 'Authorization',
      'Access-Control-Allow-Credentials': true,
      'access-control-allow-methods': 'GET'
    }
  });
}

export function getInterruptionsCountEE() {
  const state = store.getState();
  const proxyData: Proxy = {
    method: 'GET',
    endPoint: '/powercut/PlannedInterruptionsNotifications/interruptions_count',
    withAuthBearer: true
  };
  return proxy(proxyData, {
    headers: {
      Authorization: 'Bearer ' + state.auth.token,
      'Access-Control-Allow-Headers': 'Authorization',
      'Access-Control-Allow-Credentials': true,
      'access-control-allow-methods': 'GET'
    }
  });
}

export function postInterruptionsSubscribeEE(payload: any) {
  const state = store.getState();
  const proxyData: Proxy = {
    method: 'POST',
    endPoint: '/powercut/PlannedInterruptionsNotifications/add_subscription',
    data: payload,
    withAuthBearer: true
  };
  return proxy(proxyData, {
    headers: {
      Authorization: 'Bearer ' + state.auth.token,
      'Access-Control-Allow-Headers': 'Authorization',
      'Access-Control-Allow-Credentials': true,
      'access-control-allow-methods': 'POST'
    }
  });
}

export function interruptionsUnsubscribeEE(payload: any) {
  const state = store.getState();
  const proxyData: Proxy = {
    method: 'DELETE',
    endPoint: '/powercut/PlannedInterruptionsNotifications/delete_subscription',
    data: payload,
    withAuthBearer: true
  };
  return proxy(proxyData, {
    headers: {
      Authorization: 'Bearer ' + state.auth.token,
      'Access-Control-Allow-Headers': 'Authorization',
      'Access-Control-Allow-Credentials': true,
      'access-control-allow-methods': 'DELETE'
    }
  });
}
