import { faUser, faUsers, faHouse, faPlug, faBolt, faFolderOpen, faClipboardCheck } from '@fortawesome/free-solid-svg-icons';

export const stepsArray = [
    {
        title: 'Client final',
        icon: faUser
    },
    {
        title: 'Reprezentant legal',
        icon: faUsers
    },
    {
        title: 'Adresă obiectiv și tip de solicitare',
        icon: faHouse
    },
    {
        title: 'Informații tehnice loc de consum',
        icon: faPlug
    },
    {
        title: 'Informații tehnice loc de producere',
        icon: faBolt
    },
    {
        title: 'Documente anexate',
        icon: faFolderOpen
    },
    {
        title: 'Informare finală',
        icon: faClipboardCheck
    }
];