import { toggleLoader } from '@actions/LoaderActions';
import { postStepTwo } from '@api/prosumatori/prosumatori';
import { Select } from '@components/common/Select';
import ImputernicitPF from '@components/prosumatori/forms/ImputernicitPF';
import ImputernicitPJ from '@components/prosumatori/forms/ImputernicitPJ';
import { IState } from '@type/store';
import { jwtDecode } from 'jwt-decode';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

type JWT = {
    aud: string;
    aut: string;
    azp: string;
    deviceid: string;
    exp: number;
    given_name: string;
    groups: string[];
    iat: number;
    iss: string;
    jti: string;
    nbf: number;
    scope: string;
    sub: string;
    userid: string;
}

const ReprezentantLegal = (props: {isEditing: any, requestId: any, dateId: any, adressType: any, handleAdressType: any, adressData: any, handleAdressData: any, startingModalOption: any, tipImputernicit: any, handleTipImputernicit: any, setStep: any, progress: any, currentStep: any, setProgress: any, formData: any, handleFormData: any}) => {
    const [clientTypeError, setClientTypeError] = useState('');
    //eslint-disable-next-line
    const [adressTypeError, setAdressTypeError] = useState('');
    const [error, setError] = useState('');

    //eslint-disable-next-line
    const {isEditing, requestId, dateId, adressType, handleAdressType, adressData, handleAdressData, startingModalOption, tipImputernicit, handleTipImputernicit, setStep, progress, currentStep, setProgress, formData, handleFormData} = props;
    const formRefPF = useRef(null);
    const formRefPJ = useRef(null);
    const adressRef = useRef(null);

    const token: string | undefined = useSelector<IState>((state) => state?.auth?.token) as string;
    let jwt: JWT = jwtDecode(token);

    useEffect(() => {
        window.scrollTo(0,0);

        startingModalOption === 2 && jwt.groups.includes('ClientFizic') && handleTipImputernicit('PF');
        startingModalOption === 2 && jwt.groups.includes('ClientJuridic') && handleTipImputernicit('PJ');
    }, []);

    const dispatch = useDispatch();

    const constructPayload = () => {
        const adresa = {
            Judet: formData.Judet,
            Localitate: formData.Localitate,
            Comuna: formData.Comuna,
            Strada: formData.Strada,
            Numar: formData.Numar,
            Bloc: formData.Bloc,
            Etaj: formData.Etaj,
            Scara: formData.Scara,
            Ap: formData.Ap
        };

        const adresaUser = {
            Judet: dateId.Judet,
            Localitate: dateId.Localitate,
            Comuna: dateId.Comuna,
            Strada: dateId.Strada,
            Numar: dateId.Numar,
            Bloc: dateId.Bloc,
            Etaj: dateId.Etaj,
            Scara: dateId.Scara,
            Ap: dateId.Ap
        };

        let corespAdr = null;
        if (adressType === 'AI') {
            corespAdr = adresa;
        } else if (adressType === 'AU') {
            corespAdr = adresaUser;
        } else if (adressType === 'AA') {
            corespAdr = adressData;
        }

        if (tipImputernicit === 'PJ') {
            formData.CIData = null;
            formData.CIDataExp = null;
        }

        if (tipImputernicit === 'PF') {
            formData.CUI = '';
            formData.Denumire = '';
            formData.CIData = moment(formData.CIData).add(1, 'days');
            formData.CIDataExp = moment(formData.CIDataExp).add(1, 'days');
        }

        let intl_phone = formData.Telefon;

        if (formData.Telefon.startsWith('+40')) {
            intl_phone = intl_phone.slice(2);
        }

        const payload = {
            RequestId: requestId,
            TipImputernicit: tipImputernicit,
            Imputernicit: {
                ...formData,
                Telefon: intl_phone,
                Adresa: adresa
            },
            TipCorespondenta: adressType,
            AdresaCorespondenta: corespAdr
        };

        ['Judet', 'Localitate', 'Comuna', 'Strada', 'Numar', 'Bloc', 'Etaj', 'Scara', 'Ap'].forEach(e => delete payload.Imputernicit[e]);

        return payload;
    };

    const stepTwoApiCall = async (payload: any) => {
        dispatch(toggleLoader(true));

        await postStepTwo(payload)
            .then((response: any) => {
                if (response && response.status === 200) {
                    setStep(currentStep+1);
                    currentStep === progress && setProgress(currentStep+1);
                }
            })
            .catch((error: any) => {
                console.log(error);
                if (error && error.status === 200) {
                    setStep(currentStep+1);
                    currentStep === progress && setProgress(currentStep+1);
                } else if (error === 'Request is completed') {
                    setError('Cererea a fost deja finalizată.');
                } else if (error && error.StatusCode && error.StatusCode === 500) {
                    setError('A intervenit o eroare tehnică. Vă rugăm reveniți mai târziu.');
                } else if (error && error.statusCode && error.statusCode === 500) {
                    setError('A intervenit o eroare tehnică. Vă rugăm reveniți mai târziu.');
                }
            });

        dispatch(toggleLoader(false));
    };

    const handleNextStep = () => {
        if (isEditing !== false) {
            setClientTypeError('');
            setAdressTypeError('');
            setError('');
            let ac = document.getElementById('ac');
            ac?.classList.remove('red');

            if (tipImputernicit === '') {
                setClientTypeError('Te rugăm să alegi tipul de împuternicit.');
            }

            //@ts-ignore
            formRefPF.current?.submitForm();
            //@ts-ignore
            formRefPJ.current?.submitForm();

            //@ts-ignore
            if (formRefPF.current && formRefPF.current?.isValid === true) {
                stepTwoApiCall(constructPayload());
                //@ts-ignore
            } else if (formRefPJ.current && formRefPJ.current?.isValid === true) {
                stepTwoApiCall(constructPayload());
            }

        } else {
            setStep(currentStep+1);
            currentStep === progress && setProgress(currentStep+1);
        }
    };

    const handlePrevStep = () => {
        setStep(currentStep-1);
    };

    return (
        <div className="prosumatori-reprezentant-legal m-3 w-100 d-flex align-self-center flex-column">
            <Row>
                <h4 className="register-section-title mt-3">Informatii despre împuternicit</h4>
                <Col xs={12} sm={6}>
                    <Select
                        onChange={(e: any) => {
                            setClientTypeError('');
                            if (e.target.value === 'PF' && jwt.groups.includes('ClientJuridic')) handleFormData('', 'Email');
                            if (e.target.value === 'PJ' && jwt.groups.includes('ClientFizic')) handleFormData('', 'Email');
                            if (e.target.value === 'PF' && jwt.groups.includes('ClientFizic')) handleFormData(jwt.sub, 'Email');
                            if (e.target.value === 'PJ' && jwt.groups.includes('ClientJuridic')) handleFormData(jwt.sub, 'Email');
                            if (e.target.value === 'PF') {
                                handleFormData('', 'CIData');
                                handleFormData('', 'CIDataExp');
                            }
                            handleTipImputernicit(e.target.value);
                        }}
                        options={[{name: 'Persoană fizică', id: 'PF'}, {name: 'Persoană juridică', id: 'PJ'}]}
                        placeholder={'Alege tipul de împuternicit'}
                        value={tipImputernicit}
                        mbZero
                        disabled={isEditing === false || startingModalOption === 2}
                    />
                    { clientTypeError !== '' && <p style={{color: 'red', fontWeight: 'bold'}}>{clientTypeError}</p> }
                </Col>
            </Row>
            
            {(tipImputernicit === 'PF' && jwt.groups.includes('ClientFizic')) &&
                <Row className="mt-4">
                    <ImputernicitPF
                        isEditing={isEditing}
                        innerRef={formRefPF}
                        submit={handleNextStep}
                        formData={formData}
                        handleFormData={handleFormData}
                        isEmailDisabled={true}
                    />
                </Row>
            }

            {(tipImputernicit === 'PF' && !jwt.groups.includes('ClientFizic')) &&
                <Row className="mt-4">
                    <ImputernicitPF
                        isEditing={isEditing}
                        innerRef={formRefPF}
                        submit={handleNextStep}
                        formData={formData}
                        handleFormData={handleFormData}
                        isEmailDisabled={false}
                    />
                </Row>
            }

            {(tipImputernicit === 'PJ' && jwt.groups.includes('ClientFizic')) &&
                <Row className="mt-4">
                    <ImputernicitPJ
                        isEditing={isEditing}
                        innerRef={formRefPJ}
                        submit={handleNextStep}
                        formData={formData}
                        handleFormData={handleFormData}
                        isEmailDisabled={false}
                    />
                </Row>
            }

            {(tipImputernicit === 'PJ' && !jwt.groups.includes('ClientFizic')) &&
                <Row className="mt-4">
                    <ImputernicitPJ
                        isEditing={isEditing}
                        innerRef={formRefPJ}
                        submit={handleNextStep}
                        formData={formData}
                        handleFormData={handleFormData}
                        isEmailDisabled={true}
                    />
                </Row>
            }

            { (error !== '') && <p style={{color: 'red', fontWeight: 'bold'}}>{error}</p> }
            <div className="d-flex flex-row justify-content-between mt-3 mb-3">
                <Button className="d-flex" onClick={handlePrevStep}>Pasul anterior</Button>
                <Button className="d-flex" onClick={handleNextStep}>Pasul următor</Button>
            </div>
        </div>
    );
};

export default ReprezentantLegal;