import React from 'react';
import { DelgazWrapper } from '@components/global/DelgazWrapper';
import ConsumptionPointsList from '@components/account/MyAccount/ConsumptionPointsList/ConsumptionPointsList';

export const ConsumptionPoints = () => {
    return (
        <DelgazWrapper>
            <ConsumptionPointsList />
        </DelgazWrapper>
    );
};