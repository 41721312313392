import { proxy, Proxy } from '@api/azureProxy';
import { store } from '@lib/store';
import axios from 'axios';

export async function postDecrecRequestsCreate(payload: any) {
  const state = store.getState();
  const proxyData: Proxy = {
    method: 'POST',
    endPoint: '/decrec/Requests/create/',
    data: payload,
    withAuthBearer: true
  };

  return proxy(proxyData, {
    headers: {
      Authorization: 'Bearer ' + state.auth.token,
      CommunicationChannel: 'SupplierAccount',
      'Access-Control-Allow-Headers': 'Authorization',
      'Access-Control-Allow-Credentials': true,
      'access-control-allow-methods': 'POST'
    }
  });
}

export async function getDecrecList(payload: any) {
  const state = store.getState();
  const proxyData: Proxy = {
    method: 'GET',
    endPoint: '/decrec/Requests/list',
    withAuthBearer: true
  };

  return proxy(proxyData, {
    params: {
      pageNumber: payload.pageNumber ?? 1,
      pageSize: payload.pageSize ?? 10,
      sortBy: payload.sortBy,
      sortType: payload.sortType,
      utility: payload.utility,
      statusId: payload.statusId,
      keywords: payload.keywords,
      startDate: payload.startDate,
      endDate: payload.endDate,
      requestTypeId: payload.requestTypeId,
      cp: payload.cp,
      requestNo: payload.requestNo
    },
    headers: {
      Authorization: 'Bearer ' + state.auth.token,
      CommunicationChannel: 'SupplierAccount',
      'Access-Control-Allow-Headers': 'Authorization',
      'Access-Control-Allow-Credentials': true,
      'access-control-allow-methods': 'GET'
    }
  });
}

export async function getDecrecVerifyConsumptionPoint(payload: any) {
  const state = store.getState();
  const proxyData: Proxy = {
    method: 'GET',
    endPoint: '/decrec/Requests/verify_consumption_point',
    withAuthBearer: true
  };

  return proxy(proxyData, {
    params: {
      localityCode: payload.localityCode,
      countyCode: payload.countyCode,
      pod: payload.pod,
      utility: payload.utility,
      requestType: payload.requestType
    },
    headers: {
      Authorization: 'Bearer ' + state.auth.token,
      CommunicationChannel: 'SupplierAccount',
      'Access-Control-Allow-Headers': 'Authorization',
      'Access-Control-Allow-Credentials': true,
      'access-control-allow-methods': 'GET'
    }
  });
}

export async function getDecrecDetails(payload: { utility: number; id: number }) {
  const state = store.getState();
  const proxyData: Proxy = {
    method: 'GET',
    endPoint: '/decrec/Requests/details',
    withAuthBearer: true
  };

  return proxy(proxyData, {
    params: {
      utility: payload.utility,
      id: payload.id
    },
    headers: {
      Authorization: 'Bearer ' + state.auth.token,
      CommunicationChannel: 'SupplierAccount',
      'Access-Control-Allow-Headers': 'Authorization',
      'Access-Control-Allow-Credentials': true,
      'access-control-allow-methods': 'GET'
    }
  });
}

export async function postDecrecRequestsUpdate(payload: {
  RequestId: number | any;
  Utility: number | any;
  DataDeconectare: string;
  MotivDeconectare: string;
  AlteMotive: string;
  Sold: number;
  DataReconectare: string;
  CantitateEE: number;
  DataInceputNeplata: string;
  DataSfarsitNeplata: string;
  DeleteExistingDocuments: boolean;
  DocumenteSolicitare: any[];
}) {
  const state = store.getState();
  const proxyData: Proxy = {
    method: 'POST',
    endPoint: '/decrec/Requests/update/',
    data: payload,
    withAuthBearer: true
  };

  return proxy(proxyData, {
    headers: {
      Authorization: 'Bearer ' + state.auth.token,
      CommunicationChannel: 'SupplierAccount',
      'Access-Control-Allow-Headers': 'Authorization',
      'Access-Control-Allow-Credentials': true,
      'access-control-allow-methods': 'POST'
    }
  });
}

export async function postDecrecSend(payload: { Utility: number; RequestId: number; DocumenteSolicitare: [] }) {
  const state = store.getState();
  const proxyData: Proxy = {
    method: 'POST',
    endPoint: '/decrec/Requests/send',
    withAuthBearer: true,
    data: payload
  };

  return proxy(proxyData, {
    headers: {
      Authorization: 'Bearer ' + state.auth.token,
      CommunicationChannel: 'SupplierAccount',
      'Access-Control-Allow-Headers': 'Authorization',
      'Access-Control-Allow-Credentials': true,
      'access-control-allow-methods': 'POST'
    }
  });
}

export async function getGenerateRequestDocument(payload: { utility: number; id: number }) {
  const state = store.getState();
  const proxyData: Proxy = {
    method: 'GET',
    endPoint: '/decrec/Requests/generate_request_document',
    withAuthBearer: true
  };

  return proxy(proxyData, {
    params: {
      utility: payload.utility,
      id: payload.id
    },
    headers: {
      Authorization: 'Bearer ' + state.auth.token,
      CommunicationChannel: 'SupplierAccount',
      'Access-Control-Allow-Headers': 'Authorization',
      'Access-Control-Allow-Credentials': true,
      'access-control-allow-methods': 'GET'
    },
    responseType: 'arraybuffer'
  });
}

export async function deleteRequest(payload: { utility: number; id: number }) {
  const state = store.getState();
  const proxyData: Proxy = {
    method: 'GET',
    endPoint: '/decrec/Requests/delete',
    withAuthBearer: true
  };

  return proxy(proxyData, {
    params: {
      utility: payload.utility,
      id: payload.id
    },
    headers: {
      Authorization: 'Bearer ' + state.auth.token,
      CommunicationChannel: 'SupplierAccount',
      'Access-Control-Allow-Headers': 'Authorization',
      'Access-Control-Allow-Credentials': true,
      'access-control-allow-methods': 'GET'
    }
  });
}

export async function postBulkImportUpload(payloadFormData: any) {
  const state = store.getState();

  return axios.post(`${state.options.apiHost}/decrec/Requests/bulk_import_upload`, payloadFormData, {
    headers: {
      Authorization: 'Bearer ' + state.auth.token,
      'Ocp-Apim-Subscription-Key': state.options.subscriptionKey as string
    }
  });
}

export async function postBulkImportContinue(payload: { bulkImportId: number }) {
  const state = store.getState();
  const proxyData: Proxy = {
    method: 'POST',
    endPoint: `/decrec/Requests/bulk_import_continue?bulkImportId=${payload.bulkImportId}`,
    withAuthBearer: true,
    data: payload
  };

  return proxy(proxyData, {
    headers: {
      Authorization: 'Bearer ' + state.auth.token,
      CommunicationChannel: 'SupplierAccount',
      'Access-Control-Allow-Headers': 'Authorization',
      'Access-Control-Allow-Credentials': true,
      'access-control-allow-methods': 'POST'
    }
  });
}

export async function getDownloadDocument(payload: { utility: number; documentId: number }) {
  const state = store.getState();
  const proxyData: Proxy = {
    method: 'GET',
    endPoint: '/decrec/Requests/download',
    withAuthBearer: true
  };

  return proxy(proxyData, {
    params: {
      utility: payload.utility,
      documentId: payload.documentId
    },
    headers: {
      Authorization: 'Bearer ' + state.auth.token,
      CommunicationChannel: 'SupplierAccount',
      'Access-Control-Allow-Headers': 'Authorization',
      'Access-Control-Allow-Credentials': true,
      'access-control-allow-methods': 'GET'
    },
    responseType: 'arraybuffer'
  });
}
