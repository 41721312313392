import React from 'react';
import { DelgazWrapper } from '@components/global/DelgazWrapper';
import NotificationList from '@components/userNotifications/NotificationList';

export const UserNotificationList = (): JSX.Element => {
    return (
        <DelgazWrapper>
            <NotificationList />
        </DelgazWrapper>
    );
};