import { toggleLoader } from '@actions/LoaderActions';
import { TFAValidatePassword, createTFACode, getAccountDetails, validateTFACode } from '@api/user/user';
import { faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tippy from '@tippyjs/react';
import { IState } from '@type/store';
import { jwtDecode } from 'jwt-decode';
import { useEffect, useState } from 'react';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import 'react-phone-number-input/style.css';
import PhoneInput, { isValidPhoneNumber, parsePhoneNumber } from 'react-phone-number-input';

type JWT = {
    aud: string;
    aut: string;
    azp: string;
    deviceid: string;
    exp: number;
    given_name: string;
    groups: string[];
    iat: number;
    iss: string;
    jti: string;
    nbf: number;
    scope: string;
    sub: string;
    userid: string;
}

const TwoFactorAuth = (props: any) => {
    const [step, setStep] = useState(1);
    const [password, setPassword] = useState('');
    const [phone, setPhone] = useState<any>('');
    const [otp, setOtp] = useState('');
    const [show, setShow] = useState(false);
    const [modalError, setModalError] = useState('');
    const [email, setEmail] = useState('');
    const [wasSent, setWasSent] = useState('');
    const [phoneError, setPhoneError] = useState('');
    const [wasFetched, setWasFetched] = useState(false);
    const token: string | undefined = useSelector<IState>((state) => state?.auth?.token) as string;
    const dispatch = useDispatch();
    const [country, setCountry] = useState<any>('RO');

    useEffect(() => {
        if (!wasFetched && step === 2) {
          fetchPhone();
        }
      }, [wasFetched, step]);

    useEffect(() => {
        let jwt: JWT = jwtDecode(token);
        jwt && setEmail(jwt.sub);
    }, []);

    useEffect(() => {
        if (phone && !isValidPhoneNumber(phone)) {
            setPhoneError('Numărul de telefon nu are formatul corect.');
        } else if (phone && isValidPhoneNumber(phone) && country !== 'RO') {
            setPhoneError('Pentru conturi cu numere internaționale de telefon, autentificarea în doi pași se face exclusiv cu adresa de email a contului.');
        } else if (phone && isValidPhoneNumber(phone) && country === 'RO') {
            setPhoneError('');
        }
    }, [phone, country]);

    const handleClose = () => setShow(false);
    // const handleShow = () => setShow(true);

    const stepOneApiCall = async () => {
        dispatch(toggleLoader(true));

        await TFAValidatePassword({userEmail: email, password: password}).then((response: any) => {
            if (response && response.success === true) {
                setStep(2);
            } else if (response && response.success === false) {
                setModalError(response.message);
                setShow(true);
            }
        });

        dispatch(toggleLoader(false));
    };

    const stepTwoApiCall = async () => {
        dispatch(toggleLoader(true));
        setWasSent('');

        let intl_phone = phone;

        if (intl_phone.startsWith('+40')) {
            intl_phone = intl_phone.slice(2);
        }

        await createTFACode({userEmail: email, phone: intl_phone}).then((response: any) => {
            if (response && response.success === true) {
                setStep(3);
                setWasSent(response.message);
            }
        });

        dispatch(toggleLoader(false));
    };

    const stepThreeApiCall = async () => {
        dispatch(toggleLoader(true));
        setWasSent('');

        let intl_phone = phone;

        if (intl_phone.startsWith('+40')) {
            intl_phone = intl_phone.slice(2);
        }

        validateTFACode({userEmail: email, phone: intl_phone, code: otp}).then((response: any) => {
            if (response && response.success === true) {
                setStep(4);
            } else if (response && response.success === false) {
                setModalError(response.message);
                setShow(true);
            }
        });

        dispatch(toggleLoader(false));
    };

    const fetchPhone = async () => {
        dispatch(toggleLoader(true));
        await getAccountDetails()
          .then((response: any) => {
            if (response) {
              if (!phone) {

                if (response.tfaPhone[0] === '0' && response.tfaPhone[1] === '7') {
                    setPhone('+4' + response.tfaPhone);
                } else {
                    setPhone(response.tfaPhone ? response.tfaPhone : '');
                    let c = parsePhoneNumber(response.tfaPhone)?.country;
                    setCountry(c || 'RO');
                }

                setWasFetched(true);
              }
            } else {
              setWasFetched(false);
            }
          })
          .catch((e: any) => {
            setWasFetched(false);
          });
          dispatch(toggleLoader(false));
      };

    const renderModal = () => {
        return (
            <div className="modal-container">
                <Modal show={show} onHide={handleClose} dialogClassName="2fa-modal">
                    <Modal.Title>
                        <div className="p-3">
                            <span>Eroare!</span>
                        </div>
                    </Modal.Title>
                    <Modal.Body>
                        <h5>{modalError}</h5>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" onClick={handleClose}>Ok</Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    };

    const renderStep = (step: number) => {
        switch (step) {
            case 1: return renderStepOne();
            case 2: return renderStepTwo();
            case 3: return renderStepThree();
            case 4: return renderStepFour();
            default: return renderStepOne();
        }
    };

    const renderStepOne = () => {
        return (
            <div>
                <h4 className="register-section-title">Autentificare în doi pași - modificare număr de telefon</h4>
                <h5>Vă rugăm introduceți parola curentă pentru configurarea telefonului</h5>
                <Col sm={12} md={4} className="mt-2 mb-2">
                    <input type="password" placeholder="Parola" onChange={(e) => setPassword(e.target.value)}/>
                </Col>
                {password !== '' ? <Button onClick={() => stepOneApiCall()}>Trimite</Button> : <Button disabled>Trimite</Button>}
            </div>
        );
    };

    const renderStepTwo = () => {
        return (
            <div>
                <h4 className="register-section-title">Configurați telefonul</h4>
                <Col sm={12} md={4} className="mt-2">
                    <PhoneInput 
                        international
                        value={phone}
                        defaultCountry={country}
                        onChange={(e) => setPhone(e)}
                        countryCallingCodeEditable={false}
                        onCountryChange={(e) => setCountry(e)}
                    />
                </Col>
                {phoneError && <div style={{color: '#ea1c0a', fontWeight: 'bold'}}>{phoneError}</div>}
                <div className='mt-2'> 
                {(phone !== '' && !phoneError) ? <Button onClick={() => stepTwoApiCall()}>Salvează</Button> : <Button disabled>Salvează</Button>}
                <Button className='ms-2' onClick={() => setStep(1)}>Înapoi</Button>
                </div>
                
            </div>
        );
    };

    const renderStepThree = () => {
        return (
            <div>
                <h4 className="register-section-title">Confirmați codul primit</h4>
                <h5>Vă rugăm să introduceți codul primit prin SMS la numărul {phone}.</h5>
                <Col sm={12} md={4} className="mt-2 mb-2">
                    <input className="mb-1" placeholder="Cod" onChange={(e) => setOtp(e.target.value)} />
                    <a style={{color: 'black'}} href="#" onClick={() => stepTwoApiCall()}>Trimite din nou</a>
                </Col>
                {wasSent !== '' && <div style={{fontWeight: 'bold', color: 'red'}}>{wasSent}</div>}
                {otp !== '' ? <Button onClick={() => stepThreeApiCall()}>Salvează</Button> : <Button disabled>Salvează</Button>}
                <Button className='ms-2' onClick={() => setStep(2)}>Înapoi</Button>
            </div>
        );
    };

    const renderStepFour = () => {
        return (
            <div>
                <h4 className="register-section-title">Numărul de telefon a fost modificat cu succes.</h4>
                <Row className="d-flex flex-row mt-1 mb-1" style={{marginLeft: '10px'}}>
                    <div className="ml-1 d-flex justify-content-center align-items-center" style={{width: '40px'}}>
                        <Tippy
                            content={<span className="two-fa-tooltip">Modifică numărul de telefon</span>}
                            duration={0}
                            placement="top"
                        >
                            <div>
                                <FontAwesomeIcon icon={faPenToSquare} size={'2x'} className="two-fa-retry" onClick={() => setStep(1)}/>
                            </div>
                        </Tippy>
                    </div>
                    <div style={{width: '400px'}}>
                        <span>Când vă conectați, vi se solicită, pe langă parolă, și un cod de verificare trimis prin SMS pe telefonul dvs.</span>
                    </div>
                </Row>
            </div>
        );
    };

    return (
        <div className="m-3">
            {renderStep(step)}
            {renderModal()}
        </div>
    );
};

export default TwoFactorAuth;