import { SET_SETTINGS, CLEANUP_SETTINGS } from '@constants/ActionTypes/PostTypes';

import { Action } from '.';
import moment from 'moment';

export class SettingsPayload {
  version?: string;
  isVersionChanged?: boolean = false;
  isSettingsLoaded?: boolean = false;
  currentDate: moment.Moment = moment();
}

const initialState = new SettingsPayload();

export function settings(state = initialState, action = {} as Action<SettingsPayload>) {
  const { type, payload } = action;

  switch (type) {
    case SET_SETTINGS: {
      return { ...state, ...payload };
    }
    case CLEANUP_SETTINGS: {
      return { ...initialState };
    }
    default:
      return state;
  }
}
