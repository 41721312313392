import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

import { Modal, Button } from 'react-bootstrap';

type DocumentType = {
  name: string;
  url: string;
};

export type CustomModalProps = {
  showModal: boolean;
  modalTitle?: string;
  setShowModal?: (value: any) => void;
  onClose?: () => void;
  children?: JSX.Element;
  okButtonText?: string;
  cancelButton?: boolean;
  cancelButtonText?: string;
  documents: DocumentType[];
};

const ModalListDownload = ({
  showModal,
  setShowModal,
  modalTitle,
  children,
  onClose,
  okButtonText,
  cancelButton,
  cancelButtonText,
  documents
}: CustomModalProps) => {
  const handleCloseModal = () => {
    onClose && onClose();
  };

  return (
    <Modal style={{ marginTop: '20vh' }} show={showModal} onHide={handleCloseModal}>
      <Modal.Title>
        <div className="p-3" style={{ color: '#ea1c0a', fontWeight: 'bold' }}>
          <span>{modalTitle}</span>
        </div>
      </Modal.Title>
      <Modal.Body>
        <div>
          {documents.map((document) => (
            <div className="d-flex align-items-center ms-3 mb-2" style={{ overflowWrap: 'break-word' }}>
              <a style={{ color: 'black', textDecoration: 'none', cursor: 'pointer', width: '100%' }} href={document.url}>
                <FontAwesomeIcon color="red" className="me-1" icon={faDownload} /> {document.name}
              </a>
            </div>
          ))}
        </div>
        <div>{children}</div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={() => setShowModal && setShowModal(false)}>
          {cancelButtonText ?? 'Inapoi'}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalListDownload;
