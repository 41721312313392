import React from 'react';
import { Modal } from 'react-bootstrap';

const DetailsModal = (props: { show: boolean; setShow: any; details: any; clearDetails: any }) => {
  const { show, setShow, details, clearDetails } = props;

  console.log(details);

  return (
    <Modal
      show={show}
      onHide={() => {
        setShow(false);
        clearDetails();
      }}
      centered
      className="rss-modal"
    >
      <Modal.Header closeButton style={{ padding: '30px', paddingBottom: '0' }}>
        <h4 className="red fw-bold">Detalii</h4>
      </Modal.Header>
      <Modal.Body style={{ padding: '30px' }}>
        {details.request.documentNumber !== 'Documentatie clasata' && (
          <div className="details-block">
            <div className="block-header">
              <span className="block-title-border" />
              <span className="block-title">
                Detalii referitoare la etapa din procesul de racordare pentru cererea cu numărul: {details.request.requestNO}
              </span>
            </div>
            <div className="block-body">
              <div className="property-container">
                <span className="property-key">Adresa unde se solicită racordare la rețea</span>
                <span className="property-value">
                  {details.request.address.countyName ? `județ ${details.request.address.countyName},` : ''}{' '}
                  {details.request.address.localityName ? `localitatea ${details.request.address.localityName}` : ''}
                </span>
              </div>
              <div className="property-container">
                <span className="property-key">Tipul documentului emis</span>
                <span className="property-value">{details.request.documentType || '-'}</span>
              </div>
              <div className="property-container">
                <span className="property-key">Nr. documentului emis</span>
                <span className="property-value">{details.request.documentNumber || '-'}</span>
              </div>
              <div className="property-container">
                <span className="property-key">Dată emitere</span>
                <span className="property-value">{details.request.documentDate || '-'}</span>
              </div>
            </div>
          </div>
        )}

        {details.request.documentNumber === 'Documentatie clasata' && (
          <p className="mt-4 fw-bold red text-center">
            Te informăm că cererea ta de racordare a fost clasată și va trebui să reiei întregul proces, fără restituirea tarifului de
            analiză a cererii.
          </p>
        )}

        {details.request.documentNumber !== 'Documentatie clasata' && details.events && details.events.length > 0 && (
          <div className="timeline" style={{ padding: '50px 10px' }}>
            <div className="timeline-items">
              {details.events.map((event: any, index: number) => {
                return (
                  <div key={index} className="timeline-item">
                    <div className={`dot ${!event.executed ? 'soon' : ''}`}></div>
                    <div className="date">{event.executed && event.eventDate ? event.eventDate : 'Eveniment viitor'}</div>
                    <div className="content">
                      <h5 className="timeline_conent_h3" style={{ marginLeft: '2rem' }}>
                        <strong>{event.eventName}</strong>
                      </h5>
                      <div className={`registru-electronic-event ${event.executed ? '' : 'soon'}`}>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: event.executed ? event.eventDescriptionAfterActivation : event.eventDescriptionBeforeActivation
                          }}
                        />
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default DetailsModal;