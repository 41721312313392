import { faCalendar, faEnvelope, faFax, faLocationArrow, faPhone } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ContactComponent = (props: { item: any, show: boolean }) => {
    return (
        <div className={`d - flex align-items-center justify-content-center h-100 flex-wrap ${props.show ? '' : 'd-none'}`}>
            {/* <h3 className="col-12 text-center">{item.title}</h3> */}
            <ul className="active col-12 p-3">
                <li className="row">
                    <div className="icon col-1">
                        <FontAwesomeIcon icon={faPhone} className="red" />
                    </div>
                    <div className="text col-11">
                        <div className="H3">Phone</div>
                        <em></em>
                        <p><b>{props.item.details.PhoneNumber}</b>&nbsp;</p>
                    </div>
                </li>
                <li className="row">
                    <div className="icon col-1" aria-hidden="true">
                        <FontAwesomeIcon icon={faFax} className="red"/>
                    </div>
                    <div className="text col-11">
                        <div className="H3">Fax</div>
                        <em></em>
                        <p><b>{props.item.details.Fax}</b></p>
                    </div>
                </li>
                <li className="row">
                    <div className="icon col-1" aria-hidden="true">
                        <FontAwesomeIcon icon={faEnvelope} className="red" />
                    </div>
                    <div className="text col-11">
                        <div className="H3">Email</div>
                        <em></em>
                        <p><a className="link" href="mailto:infocenter@unicredit.ro">{props.item.details.Email}</a></p>
                    </div>
                </li>
                <li className="row">
                    <div className="icon col-1" aria-hidden="true">
                        <FontAwesomeIcon icon={faLocationArrow} className="red" />
                    </div>
                    <div className="text col-11">
                        <div className="H3">Adresa</div>
                        <em></em>
                        <p><b>{props.item.details.Address}</b></p>
                    </div>
                </li>
                <li className="row">
                    <div className="icon col-1" aria-hidden="true">
                        <FontAwesomeIcon icon={faCalendar} className="red" />
                    </div>
                    <div className="text col-11">
                        <div className="H3">Program de lucru</div>
                        <em></em>
                        <p><b>{props.item.details.WorkingHours}</b></p>
                    </div>
                </li>
            </ul>
            {/*eslint-disable-next-line */}
            <a href="/en/institutional/miscellaneous/contact.html"></a>

        </div>
    );
};

export default ContactComponent;
