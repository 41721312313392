import React, { useState } from 'react';
import { Table, Button } from 'react-bootstrap';
import CPTableHead from './CPTableHead';
import CPTableRow from './CPTableRow';
import { IState } from '@type/store';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import Search from '@components/account/MyAccount/ConsumptionPointsList/Search';

const TabGN = (props: {
    list: any[],
    selected: any,
    handleSelect: any,
    countyChange: any;
    localityChange: any;
    localities: any;
    counties: any;
    submitSearch: any;
    locality: any;
    county: any;
    clcChange: any;
    podChange: any;
    partnerCodeChange: any,
    reset: any;
    searchPod: any;
    searchCLC: any;
    searchPartnerCode: any,

}) => {
    const [toggleSearch, setToggleSearch] = useState(false);

    const SITE_URL: string | undefined = useSelector<IState>((state) => state.options.webApiHost) as string;

    return (
        <div>
            {props.list.length > 0 && <div className="m-3 d-flex justify-content-end">
                <Button onClick={() => setToggleSearch((prev) => !prev)}>
                    <span style={{ marginRight: '6px' }}>Panou filtrare</span>
                    <FontAwesomeIcon icon={faMagnifyingGlass} />
                </Button>
            </div>}
            {toggleSearch && (
                <Search
                    counties={props.counties}
                    localities={props.localities}
                    handleLocalityChange={props.localityChange}
                    handleCountyChange={props.countyChange}
                    submitSearch={props.submitSearch}
                    locality={props.locality}
                    county={props.county}
                    clcChange={props.clcChange}
                    podChange={props.podChange}
                    partnerCodeChange={props.partnerCodeChange}
                    reset={props.reset}
                    pod={props.searchPod}
                    clc={props.searchCLC}
                    partnerCode={props.searchPartnerCode}
                    utility={2}
                />
            )}
            {props.list.length > 0 && <div className="m-3 general-table-container rss">
                <Table>
                    <CPTableHead utility={2} isFurnizor={false} />
                    <tbody>
                        {props.list &&
                            props.list.map((item, index) => (
                                <CPTableRow
                                    utility={2}
                                    item={item}
                                    key={index}
                                    selected={props.selected}
                                    handleSelect={props.handleSelect}
                                />
                            ))}
                    </tbody>
                </Table>
            </div>}
            {props.list.length === 0 && <>
                <p className="mt-3 mb-0">Momentan, în contul tau online nu sunt alocate locuri de consum.</p>
                <p>Poți adăuga noi parteneri și locuri de consum, pentru o mai bună gestionare a contului și pentru a avea la îndemână detaliile tehnice ale locurilor de consum.</p>
                <Button onClick={() => window.location.href = `${SITE_URL}/parteneri-locuri-de-consum`}>Adaugă loc de consum</Button>
            </>}
        </div>
    );
};

export default TabGN;