import { toggleLoader } from '@actions/LoaderActions';
import { adressByPOD } from '@api/consumptionPoint';
import { GetCounties, GetLocalities } from '@api/geoApi';
import { Dropdown, DropdownOptionType } from '@components/common/Dropdown';
import { Input } from '@components/common/Input';
import MaskedInput from '@components/common/Input/MaskedInput';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tippy from '@tippyjs/react';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { AdresaObiectivuluiSchema } from '../../../helpers/schemas';

type CountyTemplate = {
    countyId: number,
    countyName: string,
    countyCode: string
};

type LocalityTemplate = {
    localityId: number,
    localityName: string,
    localityCode: string
};

const AdresaObiectivuluiPOD = (props: { getATR: any, handleValidationError: any, handleWasDataVerified: any, isEditing: any, innerRef: any, submit?: any, formData: any, handleFormData: any, utility?: string }) => {
    const [dataError, setDataError] = useState('');
    const [counties, setCounties] = useState<CountyTemplate[]>([]);
    const [county, setCounty] = useState('');
    const [localities, setLocalities] = useState<LocalityTemplate[]>([]);
    //eslint-disable-next-line
    const [locality, setLocality] = useState<number | null>(null);
    // const [localityCode, setLocalityCode] = useState<number>(0);
    // const [disableBtn, setDisableBtn] = useState(false);
    const [podValidationError, setPodValidationError] = useState('');
    const dispatch = useDispatch();

    const { getATR, handleValidationError, handleWasDataVerified, isEditing, innerRef, formData, handleFormData, utility } = props;

    useEffect(() => {
        GetCounties(utility || 'EE').then(res => {
            setCounties(res);

            res.map((c: CountyTemplate, i: number) => {
                if (c.countyCode === formData.Judet) {
                    GetLocalities(formData.Judet).then((res) => {
                        setLocalities(res);
                        res.map((c: LocalityTemplate, i: number) => {
                            if (formData.Localitate === c.localityId) {
                                console.log(c);
                                setLocality(c.localityId);
                            }
                        });
                    });
                }
            });
        });
    }, []);

    useEffect(() => {
        county === '' && setLocalities([]);
        county !== '' && GetLocalities(county).then((res) => {
            setLocalities(res);
        });
    }, [county]);

    // const getLocalityCode = async (code: any) => {
    //     console.log('get Code');
    //
    //     await GetLocalities(formData.Judet).then((res) => {
    //         res.map((c: LocalityTemplate, i: number) => {
    //             if (c.localityCode === code) {
    //                 console.log(c);
    //                 setLocalityCode(Number(c.localityCode));
    //             }
    //         });
    //     });
    // };

    const getAdressByPod = async (props: any, pod: string, countyCode: string, localityCode: number | string) => {
        setDataError('');
        setPodValidationError('');

        if (utility === 'GN' && (pod.length !== 10 || pod[0] !== 'D' || pod[1] !== 'E' || pod[2] !== 'G' || pod.includes('_'))) {
            setPodValidationError('Câmpul POD trebuie să fie de forma DEGXXXXXXX.');
        } else if (utility !== 'GN' && (pod.length !== 10 || pod[0] !== 'E' || pod[1] !== 'M' || pod[2] !== 'O' || pod.includes('_'))) {
            setPodValidationError('Câmpul POD trebuie să fie de forma EMOXXXXXXX.');
        } else if (countyCode === '' || localityCode === 0 || localityCode === '') {
            console.log(localityCode);
            setDataError('Câmpurile marcate cu * sunt obligatorii.');
        } else {
            dispatch(toggleLoader(true));

            handleFormData('', 'Strada');
            props.setFieldValue('Strada', '');
            handleFormData('', 'Bloc');
            props.setFieldValue('Bloc', '');
            handleFormData('', 'Etaj');
            props.setFieldValue('Etaj', '');
            handleFormData('', 'Numar');
            props.setFieldValue('Numar', '');
            handleFormData('', 'Ap');
            props.setFieldValue('Ap', '');
            handleFormData('', 'Scara');
            props.setFieldValue('Scara', '');
            handleFormData('', 'Comuna');
            props.setFieldValue('Comuna', '');
            handleFormData('', 'cod_loc_consum');
            props.setFieldValue('cod_loc_consum', '');

            const payload = { pod, countyCode, localityCode };
            console.log(payload);

            await adressByPOD(payload)
                .then((response) => {
                    if (response) {
                        console.log('R: ', response);
                        handleFormData(response.streetName, 'Strada');
                        props.setFieldValue('Strada', response.streetName);
                        handleFormData(response.building, 'Bloc');
                        props.setFieldValue('Bloc', response.building);
                        handleFormData(response.houseNum1, 'Numar');
                        props.setFieldValue('Numar', response.houseNum1);
                        handleFormData(response.roomNumber, 'Ap');
                        props.setFieldValue('Ap', response.roomNumber);
                        handleFormData(response.vstelle, 'cod_loc_consum');
                        props.setFieldValue('cod_loc_consum', response.vstelle);
                        handleWasDataVerified(true);
                        handleValidationError('');
                        getATR();
                    }
                })
                .catch((error) => {
                    console.log('e: ', error);
                    if (error && error.status === 204) {
                        console.log('e: ', error);
                        setDataError('Locul de consum nu a fost identificat, te rugăm să corectezi informațiile!');
                        handleWasDataVerified(false);

                        dispatch(toggleLoader(false));
                    }
                });
        }

    };

    return (
        <div className="mt-3 mb-3">
            <Formik
                enableReinitialize={true}
                initialValues={formData}
                onSubmit={(values) => (values)}
                validationSchema={AdresaObiectivuluiSchema}
                innerRef={innerRef}
                validateOnMount={true}
                validateOnChange={true}
            >
                {(props) => (
                    <Form>
                        <Row>
                            <Col className="mb-3" sm={3}>
                                <div className="position-relative">
                                    <Field label='POD' id="POD" name="POD" placeholder="POD">
                                        {() =>
                                            // <Input
                                            //     mbZero
                                            //     value={formData.POD}
                                            //     label='POD*'
                                            //     onBlur={() => props.setFieldTouched('POD')}
                                            //     onChange={(e:any) => {
                                            //         handleFormData(e.target.value, 'POD');
                                            //         props.setFieldValue('POD', e.target.value);
                                            //     }}
                                            //     disabled={isEditing === false}
                                            //     placeholder="EMOXXXXXXX"
                                            // />
                                            <MaskedInput
                                                onChange={(e: any) => {
                                                    let v = e.target.value;

                                                    handleFormData(v, 'POD');
                                                    props.setFieldValue('POD', v);
                                                }}
                                                value={formData.POD}
                                                utility={utility || 'EE'}
                                                label='POD*'
                                                mask={'0123333333'}
                                                disabled={isEditing === false}
                                            />
                                        }
                                    </Field>
                                    <span className="position-absolute" style={{ right: '4%', bottom: '-36px', zIndex: 5 }}>
                                        <Tippy
                                            content={
                                                <div className="p-3" style={{ background: 'rgba(0,0,0,0.8)', color: 'white', borderRadius: '6px' }}>
                                                    POD - punct de măsurare, codul de regăsește pe factura de energie electrică primită de la furnizor.
                                                </div>
                                            }
                                            duration={0}
                                            placement="bottom"
                                        >
                                            <div className="icon-div">
                                                <FontAwesomeIcon icon={faCircleInfo} className="icon" color="red" />
                                            </div>
                                        </Tippy>
                                    </span>
                                </div>
                            </Col>
                            <Col className="mb-3" sm={3}>
                                <Dropdown
                                    options={counties.map((option, index) => (
                                        {
                                            id: option.countyCode,
                                            name: option.countyName
                                        }
                                    )) as DropdownOptionType[]}
                                    label={'Județ*'}
                                    defaultPlaceholder={'Alege județul'}
                                    displaySearch={true}
                                    onChange={(value) => {
                                        if (value === '') {
                                            handleFormData('', 'Localitate');
                                            props.setFieldValue('Localitate', '');
                                        }
                                        handleFormData(value, 'Judet');
                                        value && props.setFieldValue('Județ', value);
                                        value.length === 0 && props.setFieldValue('Județ', '');
                                        setCounty(value);
                                    }}
                                    value={formData.Judet}
                                    mbZero
                                    disabled={isEditing === false}
                                />
                                <ErrorMessage name="Judet" render={msg => <div style={{ color: '#ea1c0a', fontWeight: 'bold' }}>{msg}</div>} />
                            </Col>
                            <Col className="mb-3" sm={3}>
                                <Dropdown
                                    options={localities.map((option, index) => (
                                        {
                                            id: option.localityCode,
                                            name: option.localityName,
                                        }
                                    )) as DropdownOptionType[]}
                                    label={'Localitate*'}
                                    defaultPlaceholder={'Alege localitatea'}
                                    displaySearch={true}
                                    onChange={(value) => {
                                        // value && getLocalityCode(value);
                                        value && handleFormData(value, 'Localitate');
                                        value && props.setFieldValue('Localitate', value);
                                        value.length === 0 && props.setFieldValue('Localitate', '');
                                    }}
                                    value={formData.Localitate}
                                    mbZero
                                    disabled={isEditing === false}
                                />
                                <ErrorMessage name="Localitate" render={msg => <div style={{ color: '#ea1c0a', fontWeight: 'bold' }}>{msg}</div>} />
                            </Col>
                            <Col className="mb-3" sm={3}>
                                <Button
                                    className="w-100"
                                    style={{ height: '44px' }}
                                    onClick={() => getAdressByPod(props, formData.POD, formData.Judet, formData.Localitate)}
                                    disabled={isEditing === false}
                                >
                                    Verifică informații
                                </Button>
                            </Col>
                        </Row>

                        {podValidationError !== '' &&
                            <Row>
                                <p style={{ fontWeight: 'bold', color: 'red' }}>{podValidationError}</p>
                            </Row>
                        }

                        {dataError !== '' &&
                            <Row>
                                <p style={{ fontWeight: 'bold', color: 'red' }}>{dataError}</p>
                            </Row>
                        }

                        <Row>
                            {/* <Col className="mb-3">
                                <Field label='cod_loc_consum' id="cod_loc_consum" name="cod_loc_consum" placeholder="Cod loc consum">
                                    {() =>
                                        <Input
                                            mbZero
                                            value={formData.cod_loc_consum}
                                            label='Cod loc consum'
                                            onBlur={() => props.setFieldTouched('cod_loc_consum')}
                                            onChange={(e:any) => {
                                                handleFormData(e.target.value, 'cod_loc_consum');
                                                props.setFieldValue('cod_loc_consum', e.target.value);
                                            }}
                                        />
                                    }
                                </Field>
                            </Col> */}
                            <Col className="mb-3">
                                <Field label='Strada' id="Strada" name="Strada" placeholder="Strada">
                                    {() =>
                                        <Input
                                            mbZero
                                            value={formData.Strada}
                                            label='Stradă'
                                            onBlur={() => props.setFieldTouched('Strada')}
                                            onChange={(e: any) => {
                                                handleFormData(e.target.value, 'Strada');
                                                props.setFieldValue('Strada', e.target.value);
                                            }}
                                            disabled={isEditing === false}
                                        />
                                    }
                                </Field>
                            </Col>
                            <Col className="mb-3">
                                <Field label='Numar' id="Numar" name="Numar" placeholder="Numar">
                                    {() =>
                                        <Input
                                            mbZero
                                            value={formData.Numar}
                                            label='Număr'
                                            onBlur={() => props.setFieldTouched('Numar')}
                                            onChange={(e: any) => {
                                                handleFormData(e.target.value, 'Numar');
                                                props.setFieldValue('Numar', e.target.value);
                                            }}
                                            disabled={isEditing === false}
                                        />
                                    }
                                </Field>
                            </Col>
                        </Row>

                        <Row>
                            <Col className="mb-3" xs={12} md={6}>
                                <Field label='Bloc' id="Bloc" name="Bloc" placeholder="Bloc">
                                    {() =>
                                        <Input
                                            mbZero
                                            value={formData.Bloc}
                                            label='Bloc'
                                            onBlur={() => props.setFieldTouched('Bloc')}
                                            onChange={(e: any) => {
                                                handleFormData(e.target.value, 'Bloc');
                                                props.setFieldValue('Bloc', e.target.value);
                                            }}
                                            disabled={isEditing === false}
                                        />
                                    }
                                </Field>
                            </Col>
                            <Col className="mb-3" xs={12} md={6}>
                                <Field label='Scara' id="Scara" name="Scara" placeholder="Scara">
                                    {() =>
                                        <Input
                                            mbZero
                                            value={formData.Scara}
                                            label='Scară'
                                            onBlur={() => props.setFieldTouched('Scara')}
                                            onChange={(e: any) => {
                                                handleFormData(e.target.value, 'Scara');
                                                props.setFieldValue('Scara', e.target.value);
                                            }}
                                            disabled={isEditing === false}
                                        />
                                    }
                                </Field>
                            </Col>
                            <Col className="mb-3" xs={12} md={6}>
                                <Field label='Etaj' id="Etaj" name="Etaj" placeholder="Etaj">
                                    {({
                                        //@ts-ignore
                                        meta: { touched, error }
                                    }) =>
                                        <Input
                                            mbZero
                                            value={formData.Etaj}
                                            label='Etaj'
                                            onBlur={() => props.setFieldTouched('Etaj')}
                                            onChange={(e: any) => {
                                                handleFormData(e.target.value, 'Etaj');
                                                props.setFieldValue('Etaj', e.target.value);
                                            }}
                                            disabled={isEditing === false}
                                            className={touched && error ? 'forced-focus' : ''}
                                        />
                                    }
                                </Field>
                            </Col>
                            <Col className="mb-3" xs={12} md={6}>
                                <Field label='Ap' id="Ap" name="Ap" placeholder="Ap">
                                    {() =>
                                        <Input
                                            mbZero
                                            value={formData.Ap}
                                            label='Apartament'
                                            onBlur={() => props.setFieldTouched('Ap')}
                                            onChange={(e: any) => {
                                                handleFormData(e.target.value, 'Ap');
                                                props.setFieldValue('Ap', e.target.value);
                                            }}
                                            disabled={isEditing === false}
                                        />
                                    }
                                </Field>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="mb-3" xs={12} md={6}>
                                <Field label='nrCF' id="nrCF" name="nrCF" placeholder="nrCF">
                                    {() =>
                                        <Input
                                            mbZero
                                            value={formData.nrCF}
                                            label='Nr. Extras carte funciară (CF)'
                                            onBlur={() => props.setFieldTouched('nrCF')}
                                            onChange={(e: any) => {
                                                handleFormData(e.target.value, 'nrCF');
                                                props.setFieldValue('nrCF', e.target.value);
                                            }}
                                            disabled={isEditing === false}
                                        />
                                    }
                                </Field>
                            </Col>
                            <Col className="mb-3" xs={12} md={6}>
                                <Field label='nrCad' id="nrCad" name="nrCad" placeholder="nrCad">
                                    {() =>
                                        <Input
                                            mbZero
                                            value={formData.nrCad}
                                            label='Coordonate geografice, număr cadastral'
                                            onBlur={() => props.setFieldTouched('nrCad')}
                                            onChange={(e: any) => {
                                                handleFormData(e.target.value, 'nrCad');
                                                props.setFieldValue('nrCad', e.target.value);
                                            }}
                                            disabled={isEditing === false}
                                        />
                                    }
                                </Field>
                            </Col>
                        </Row>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default AdresaObiectivuluiPOD;
