import React, { FormEvent, useState } from 'react';

import {
  Button,
  Form,
  Row,
  Col
} from 'react-bootstrap';
import InputMask from 'react-input-mask';

import { searchSuperiorCalorificPowerByClcOrPod } from '@api/superiorCalorificValue';
import { SuperiorCalorificTemplateByPodOrClc } from '@type/superiorCalorificPower';
import ResultListing from '@components/delgaz/Account/SuperiorCaloricValue/ResultListing';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { toggleLoader } from '@actions/LoaderActions';
import DatePicker from '@components/common/Date/DatePicker';

export const defaultEvent = { preventDefault: () => undefined, persist: () => undefined } as React.SyntheticEvent;

// type ErrorsType = { [key: string]: string | undefined };

export class SuperiorCalorificValuesByClc {
  clc: string | null = '';
  startDate?: Date | null = null;
  endDate?: Date | null = null;
};

const SearchByPod = () => {
  const dispatch = useDispatch();
  const [inputValues, setInputValues] = useState<SuperiorCalorificValuesByClc>(new SuperiorCalorificValuesByClc());
  // const [errors, setErrors] = useState<ErrorsType>({});
  const [hasApiError, setHasApiError] = useState<boolean>(false);
  const [apiLoaded, setApiLoaded] = useState<boolean>(false);

  const [calorificPowers, setCalorificPowers] = useState<SuperiorCalorificTemplateByPodOrClc[]>([]);

  const onSubmit = async (event: FormEvent) => {
    event.preventDefault();
    dispatch(toggleLoader(true));
    setHasApiError(false);
    setCalorificPowers([]);
    setApiLoaded(false);
    try {
      let calorificPowers: SuperiorCalorificTemplateByPodOrClc[] = await searchSuperiorCalorificPowerByClcOrPod('pod', inputValues);
      calorificPowers = calorificPowers.sort((a: SuperiorCalorificTemplateByPodOrClc, b: SuperiorCalorificTemplateByPodOrClc) =>
        (a.startDate > b.startDate) ? 1 : ((b.startDate > a.startDate) ? -1 : 0)
      );
      setCalorificPowers(calorificPowers);
    } catch (err) {
      setHasApiError(true);
    }
    setApiLoaded(true);
    dispatch(toggleLoader(false));
  };

  const formatChars = {
    'd': '[Dd]',
    'e': '[Ee]',
    'g': '[Gg]',
    '9': '[0123456789]'
  };

  return (
    <>
      <Form className="form" onSubmit={(event) => onSubmit(event)}>
        <Row>
          <Col md={6} sm={12} className='mb-2'>
            <InputMask
              mask="deg9999999"
              placeholder={'DEG0000000'}
              formatChars={formatChars}
              onChange={(event: any) => {
                let value = event.target.value as string;
                value = value.toLocaleUpperCase();
                setInputValues({
                  ...inputValues, clc: value
                });
              }}
              value={inputValues['clc'] ?? ''}>
            </InputMask>
          </Col>

          <Col md={3} sm={6} xs={6} className="mt-2 mt-md-0">
            <DatePicker
              label="Dată început"
              name="Dată început"
              selected={inputValues['startDate']}
              onChange={(date) => {
                setInputValues({
                  ...inputValues, startDate: date
                });
              }}
              maxDate={new Date()}
              mbZero
            />
          </Col>
          <Col md={3} sm={6} xs={6} className="mt-2 mt-md-0">
            <DatePicker
              disabled={!!inputValues['startDate'] === false}
              label="Dată sfârșit"
              name="Dată sfârșit"
              selected={inputValues['endDate']}
              onChange={(date) => {
                setInputValues({
                  ...inputValues, endDate: date
                });
              }}
              minDate={inputValues['startDate']}
              maxDate={moment().toDate()}
              mbZero
            />
          </Col>
        </Row>
        <Button type='submit'
          disabled={!!(Object.keys(inputValues).find(key => !(!!(inputValues[key as keyof SuperiorCalorificValuesByClc]))))}
          className="btn btn-primary mt-2 mb-2 btn-md btn-submit">Caută</Button>
      </Form>
      <ResultListing
        searchType={'pod'}
        searchValue={inputValues['clc'] ?? ''}
        superiorCaloricValuesByClcOrPod={calorificPowers}
        showError={hasApiError}
        isDataLoaded={apiLoaded}
      />
    </>
  );
};

export default SearchByPod;