import { GetCounties } from '@api/geoApi';
import { getInterruptions } from '@api/intreruperiNeprogramate/intreruperiNeprogramate';
import { Dropdown, DropdownOptionType } from '@components/common/Dropdown';
import RomaniaMapByCounties from '@components/dev/Components/RomaniaMap/RomaniaMapByCounties';
import { UTILITY_GN } from '@constants/Utility';
import { faArrowRight, faBolt, faFire, faRss } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CountyTemplate } from '@type/geo';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { getQueryParam } from '../../helpers/urlUtils';
import { useDispatch } from 'react-redux';
import { toggleLoader } from '@actions/LoaderActions';
import { store } from '@lib/store';

type Interruption = {
  county: string;
  startDate: string;
  endDate: string;
  description: string;
  title: string;
};

const IntreruperiNeprogramate = (props: any) => {
  const utility = UTILITY_GN;
  const [counties, setCounties] = useState<CountyTemplate[]>([]);
  //eslint-disable-next-line
  const [allInterruptions, setAllInterruptions] = useState<Interruption[]>([]);
  const [interruptions, setInterruptions] = useState<Interruption[]>([]);

  const [interruptionsCount, setInterruptionsCount] = useState<any>(null);
  const [county, setCounty] = useState('');
  const [pageNumber, setPageNumber] = useState(1);
  //eslint-disable-next-line
  const [pageSize, setPageSize] = useState(10);
  const [showAll, setShowAll] = useState(false);
  const [showLoadMoreButton, setShowLoadMoreButton] = useState(false);
  const [error, setError] = useState('');

  const state = store.getState();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(toggleLoader(true));
    GetCounties(utility == UTILITY_GN ? 'GN' : 'EE').then((res) => setCounties(res));

    const countyCodeParam = getQueryParam('countyCode');
    setCounty(countyCodeParam ?? '');

    getInterruptions()
      .then((res) => {
        setAllInterruptions(res);
        dispatch(toggleLoader(false));
      })
      .catch((err) => {
        setError('A apărut o eroare tehnică. Vă rugam reveniți mai târziu.');
        dispatch(toggleLoader(false));
      });
  }, []);

  useEffect(() => {
    setPageNumber(1);
  }, [county]);

  useEffect(() => {
    if (!allInterruptions) return;

    let interruptions = [...allInterruptions];

    if (county) {
      interruptions = allInterruptions.filter((i) => {
        return i.county == county;
      });
    }

    let showLoadMoreButton = false;
    interruptions = interruptions.filter((i, index) => {
      if (index < pageSize * pageNumber) {
        return true;
      } else {
        showLoadMoreButton = true;
        return false;
      }
    });

    setShowLoadMoreButton(showLoadMoreButton);
    setInterruptions(interruptions);
  }, [county, allInterruptions, pageNumber]);

  useEffect(() => {
    let interruptionsCount: any = {};
    if (allInterruptions && allInterruptions.length) {
      allInterruptions.forEach((i) => {
        let startDate = moment(i.startDate);
        let endDate = moment(i.endDate);
        let now = moment().locale('ro');
        let isBetween = now.isSameOrAfter(startDate) && now.isSameOrBefore(endDate);
        if (isBetween) {
          interruptionsCount[i.county] = interruptionsCount[i.county] ? interruptionsCount[i.county] + 1 : 1;
        }
      });
    }
    setInterruptionsCount(interruptionsCount);
  }, [allInterruptions]);

  const handleCountySelect = (code: string) => {
    setCounty(code);
    setShowAll(false);
  };

  return (
    <div className="unscheduled-interruptions">
      <div className="d-flex flex-wrap justify-content-center">
        <div className="overflow-auto">
          <RomaniaMapByCounties
            onCountySelect={handleCountySelect}
            countiesWithDetails={counties.map((c: CountyTemplate, i: number) => {
              return { copuntyDetails: interruptionsCount[c.countyCode], countyCode: c.countyCode };
            })}
          />
        </div>

        <div className="unscheduled-interruptions_dropdown-container">
          <h4 className="mb-3">Întreruperi neplanificate</h4>

          <p className="mb-3">
            Selectează județul pentru care dorești să afli informații despre întreruperile neplanificate ale serviciului de distribuție gaze
            naturale.
          </p>

          <Dropdown
            options={
              counties.map((option, index) => ({
                id: option.countyCode,
                name: option.countyName
              })) as DropdownOptionType[]
            }
            label={'Județ'}
            defaultPlaceholder={'Alege județul'}
            onChange={(value) => {
              setCounty(value);
            }}
            value={county}
          />

          <div className="mb-3">
            <Button
              className="w-100"
              onClick={() => {
                setCounty('');
                setShowAll(true);
              }}
            >
              Afișează toate anunțurile
            </Button>
          </div>

          <span>
            <FontAwesomeIcon icon={faRss} color="#ea1c0a" /> <b className="me-2">Feed RSS:</b>
            <span>
              <FontAwesomeIcon icon={faArrowRight} color="#ea1c0a" />{' '}
              <a href={`${state.options.webApiHost}/RSSFeedInterruption`} className="me-2" target="_blank" rel="noreferrer">
                Toate județele
              </a>
            </span>
            {county && (
              <span>
                <FontAwesomeIcon icon={faArrowRight} color="#ea1c0a" />{' '}
                <a href={`${state.options.webApiHost}/RSSFeedInterruption?judet=${county}`} target="_blank" rel="noreferrer">
                  Județul {counties.find((c) => c.countyCode == county)?.countyName}
                </a>
              </span>
            )}
          </span>
        </div>
      </div>

      <div className="p-3">
        {county && (
          <h4 className="red mb-3">Întreruperi neplanificate în județul {counties.find((c) => c.countyCode == county)?.countyName}</h4>
        )}

        {interruptions &&
          (county || showAll) &&
          interruptions.map((i, index) => (
            <Row key={index} className="mb-5">
              <Col md={12}>
                <h5>{i.title}</h5>
              </Col>
              <Col md={6}>
                <div className="d-flex flex-row p-3 details-block mb-3">
                  <div className="utility-square me-3">
                    {utility == UTILITY_GN ? <FontAwesomeIcon icon={faFire} size="lg" /> : <FontAwesomeIcon icon={faBolt} size="lg" />}
                  </div>

                  <div>
                    <div>
                      <h3 className="mb-2" style={{ fontSize: '22px', fontWeight: '400' }}>
                        <strong>Detalii eveniment</strong>
                      </h3>

                      <p className="p-gray mb-1">
                        Serviciu afectat:{' '}
                        <strong className="black">
                          {utility == UTILITY_GN ? 'Furnizare Gaze Naturale' : 'Furnizare Energie Electrică'}
                        </strong>
                      </p>

                      <p className="p-gray mb-1">
                        Data de începere: <strong className="black">{moment(i.startDate).format('DD.MM.YYYY')}</strong>
                      </p>

                      <p className="p-gray mb-1">
                        Data de finalizare: <strong className="black">{moment(i.endDate).format('DD.MM.YYYY')}</strong>
                      </p>
                    </div>
                  </div>
                </div>
              </Col>
              <Col md={12}>
                <div className="unscheduled-interruptions_description-container" dangerouslySetInnerHTML={{ __html: i.description }} />
              </Col>

              <Col md={12}>
                <hr />
              </Col>
            </Row>
          ))}

        {showLoadMoreButton && (county || showAll) ? (
          <Button
            onClick={() => {
              setPageNumber(pageNumber + 1);
            }}
            className="w-100 btn-white"
          >
            Incarcă mai multe
          </Button>
        ) : (
          ''
        )}

        {(!interruptions || !interruptions.length) && county && (
          <div style={{ color: '#ea1c0a', fontWeight: 'bold' }}>Nu există întreruperi neplanificate pentru acest județ.</div>
        )}
      </div>

      {error && <div style={{ color: 'red', fontWeight: 'bold' }}>{error}</div>}
    </div>
  );
};

export default IntreruperiNeprogramate;
