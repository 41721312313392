export const TYPE_DISCONNECT = 1;
export const TYPE_RECONNECT = 2;
export const TYPE_CANCEL_DISCONNECT = 3;

export const DECREC_TYPE_NAME_DISCONNECT = 'DECONECTARE';
export const DECREC_TYPE_NAME_RECONNECT = 'RECONECTARE';
export const DECREC_TYPE_NAME_CANCEL_DISCONNECT = 'ANULARE_DECONECTARE';

export const DECREC_DISCONNECT_REASON__CERERE_CLIENT_FINAL = 'CERERE CLIENT FINAL';
export const DECREC_DISCONNECT_REASON__NEPLATA_CLIENT_FINAL = 'NEPLATA CLIENT FINAL';
export const DECREC_DISCONNECT_REASON__ALTE_MOTIVE = 'ALTE MOTIVE';

export const DECREC_STATUS__NETRIMISA = 1;
export const DECREC_STATUS__NEINREGISTRATA = 2;
export const DECREC_STATUS__IN_ANALIZA = 3;
export const DECREC_STATUS__PROCESATA = 4;
export const DECREC_STATUS__ANULATA = 5;

export const DECREC_STATUS_CP__NECONFIRMAT = 'Neconfirmat';
export const DECREC_STATUS_CP__CONFIRMAT = 'Confirmat';
export const DECREC_STATUS_CP__FINALIZAT = 'Finalizat';
export const DECREC_STATUS_CP__ANULAT = 'Anulat';
