import React, { useEffect, useState } from 'react';
import Notification from './Notification';
import { Button, Modal } from 'react-bootstrap';
import { faEnvelope, faMagnifyingGlass, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getNotifications, markNotificationAsRead, deleteNotification, getNotificationDetails, notificationsSummary } from '@api/userNotifications';
import Pagination from '@components/common/Pagination/Pagination';
import { toggleLoader } from '@actions/LoaderActions';
import { useDispatch } from 'react-redux';
import { updateNotificationCount, updateNotificationSummary } from '@actions/NotificationActions';
import { useSelector } from 'react-redux';
import { IState } from '@type/store';

const NotificationList = () => {
  const [selected, setSelected] = useState<number[]>([]);
  const [search, setSearch] = useState('');
  const [notifications, setNotifications] = useState<any[]>([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalItems, setTotalItems] = useState(0);
  const [toBeDeleted, setToBeDeleted] = useState<number[]>([]);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [mountedFromUrl, setMountedFromUrl] = useState(false);
  const userNotifications: any = useSelector<IState>((state) => state.notifications) as {};

  const dispatch = useDispatch();

  useEffect(() => {
    getNotificationList(search, pageNumber, pageSize);
  }, [pageNumber, pageSize]);

  useEffect(() => {
    getNotificationList('', 1, 10);
  }, [userNotifications.externalUpdate]);

  useEffect(() => {
    if (notifications.length > 0 && !mountedFromUrl) {
      const url = new URL(window.location.href);

      const params = url.search;
      const searchParams = new URLSearchParams(params);

      if (searchParams.has('read')) {
        getContent(Number(searchParams.get('read')));
      }

      setMountedFromUrl(true);
    }
  }, [notifications]);

  const setElementsPerPage = (elements: number) => {
    setPageSize(elements);
  };

  const paginate = (pageNumber: number) => {
    setPageNumber(pageNumber);
  };

  const getNotificationList = async (search: string, pageNumber: number, pageSize: number) => {
    dispatch(toggleLoader(true));

    setNotifications([]);

    await getNotifications({ search, pageNumber, pageSize }).then((response) => {
      if (response && response.data && response.data.items) {
        let list: any[] = [];

        response.data.items.map((item: any, index: number) => {
          let newItem = item;
          newItem.content = '';
          newItem.isCollapsed = true;

          list.push(newItem);
        });

        setNotifications(list);
        setTotalItems(response.data.metadata.totalItemsCount);
        setPageNumber(response.data.metadata.pageIndex);
        setPageSize(response.data.metadata.pageSize);
      }
    })
    .catch((err) => console.log(err));

    dispatch(toggleLoader(false));
  };

  const selectNotification = (id: number) => {
    console.log('selected: ', id);
    let current = selected;
    let found = false;

    selected.map((item: any) => {
      if (item === id) {
        found = true;
      }
    });

    if (found) {
      if (current.length === 1) {
        current.splice(0, 1);
      } else {
        current.splice(id, 1);
      }
    } else {
      current.push(id);
    }

    current = current.sort();
    setSelected([...current]);
  };

  const selectAll = () => {
    if (selected.length === notifications.length) {
      setSelected([]);
    } else {
      let all: number[] = [];

      notifications.map((notification: any, index: number) => {
        all.push(notification.id);
      });

      console.log('all: ', all);
      setSelected(all);
    }
  };

  const deleteNotifications = (id?: number) => {
    if (id) {
      setToBeDeleted([...toBeDeleted, id]);
    } else {
      console.log(selected);
      setToBeDeleted(selected);
    }

    setShowConfirmModal(true);
  };

  const apiCallDelete = async (ids: any[]) => {
    dispatch(toggleLoader(true));

    setSearch('');
    setPageNumber(1);
    setPageSize(10);

    await deleteNotification({ ids })
      .then((response) => {
        if (response && response.status === 200) {
          setShowConfirmModal(false);
          setShowSuccessModal(true);
          setSelected([]);
          getNotificationList(search, pageNumber, pageSize);
          apiGetSummary();
        }
      })
      .catch((err) => {
        if (err && err.status === 200) {
          setShowConfirmModal(false);
          setShowSuccessModal(true);
          setSelected([]);
          getNotificationList(search, pageNumber, pageSize);
          apiGetSummary();
        }
      });

    dispatch(toggleLoader(false));
  };

  const apiMarkAsRead = async (ids: any[]) => {
    dispatch(toggleLoader(true));

    setSearch('');
    setPageNumber(1);
    setPageSize(10);

    await markNotificationAsRead({ ids })
      .then((response) => {
        if (response && response.data && response.status === 200) {
          setSelected([]);
          getNotificationList(search, pageNumber, pageSize);
          apiGetSummary();
        }
      })
      .catch((err) => {
        if (err && err.status === 200) {
          setSelected([]);
          getNotificationList(search, pageNumber, pageSize);
          apiGetSummary();
        }
      });

    dispatch(toggleLoader(false));
  };

  const apiGetSummary = async () => {
    await notificationsSummary({ pageNumber: 1, pageSize: 3 }).then((response) => {
      if (response && response.data && response.data.items) {
        let summary = response.data.items;
        let itemsCount = response.data.metadata.totalItemsCount;
        dispatch(updateNotificationSummary(summary));
        dispatch(updateNotificationCount(itemsCount));
      }
    });
  };

  const renderConfirmationModal = () => {
    return (
      <Modal show={showConfirmModal} centered onExit={() => setShowConfirmModal(false)} onHide={() => setShowConfirmModal(false)}>
        <Modal.Header>
          <h5 className="fw-bold red">Confirmare</h5>
        </Modal.Header>
        <Modal.Body>Ești sigur că vrei să ștergi mesajul?</Modal.Body>
        <Modal.Footer>
          <Button onClick={() => apiCallDelete(toBeDeleted)}>Da</Button>
          <Button onClick={() => setShowConfirmModal(false)}>Nu</Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const renderDeleteSuccessModal = () => {
    return (
      <Modal show={showSuccessModal} centered onExit={() => setShowSuccessModal(false)} onHide={() => setShowSuccessModal(false)}>
        <Modal.Header>
          <h5 className="fw-bold red">Succes</h5>
        </Modal.Header>
        <Modal.Body>Mesajul a fost șters.</Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setShowSuccessModal(false)}>Ok</Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const getContent = async (id: number) => {
    dispatch(toggleLoader(true));

    await getNotificationDetails({ id })
      .then((response: any) => {
        if (response && response.data && response.status === 200) {
          let notif = notifications;

          notif.map((item: any, index: number) => {
            if (item.id === response.data.id) {
              item.content = response.data.message;
              item.isCollapsed = false;
              item.wasOpened = true;
            }
          });

          setNotifications([...notif]);
          apiGetSummary();
        }
      })
      .catch((err: any) => console.log(err));

    dispatch(toggleLoader(false));
  };

  const handleIsCollapsed = (id: number, value: boolean) => {
    let notif = notifications;

    notif.map((item: any, index: number) => {
      if (item.id === id) {
        item.isCollapsed = value;

        if (value === true) {
          item.wasOpened = true;
        }
      }
    });

    setNotifications([...notif]);
  };

  return (
    <div className="notification-list m-2 p-2">
      <div className="select-all">
        <div className="notification-item d-flex justify-content-between">
          <div className="n-item d-flex p-1">
            <div className="n-item-checkbox checkbox-container p-2">
              <input
                className="create-account-checkbox"
                type="checkbox"
                onChange={() => selectAll()}
                checked={selected.length === notifications.length && notifications.length > 0}
              />
            </div>
            <div className={'n-item-title p-2 select-title'} onClick={() => selectAll()}>
              <span>Selectează toate mesajele</span>
            </div>
          </div>
          <div className="n-item-content d-flex p-1">
            <div className="n-item-actions d-flex p-2 flex-wrap">
              <div className="search-notification position-relative mb-1">
                <FontAwesomeIcon icon={faMagnifyingGlass} className="search-icon" onClick={() => getNotificationList(search, 1, 10)} />
                <input
                  type="text"
                  className="plceholder-red"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  placeholder="Caută"
                />
              </div>
              <Button className='mb-1' style={{ marginRight: '6px' }} disabled={selected.length === 0} onClick={() => deleteNotifications()}>
                <FontAwesomeIcon icon={faTrash} className="n-item-delete" />
                <span>Șterge mesajele</span>
              </Button>
              <Button className='mb-1' disabled={selected.length === 0} onClick={() => apiMarkAsRead(selected)}>
                <FontAwesomeIcon icon={faEnvelope} className="n-item-delete" />
                <span>Marchează ca citite</span>
              </Button>
            </div>
          </div>
        </div>
      </div>
      {notifications.length > 0 &&
        notifications.map((item: any, index: number) => {
          return (
            <Notification
              index={index}
              notification={item}
              key={index}
              selected={selected}
              selectNotification={selectNotification}
              deleteNotification={deleteNotifications}
              getContent={getContent}
              handleIsCollapsed={handleIsCollapsed}
            />
          );
        })}

      {notifications.length === 0 && <div className="text-center mt-4">Nu există notificări.</div>}

      {notifications.length > 0 && (
        <Pagination
          currentPage={pageNumber}
          totalElements={totalItems}
          perPage={pageSize}
          paginate={paginate}
          setElementsPerPage={setElementsPerPage}
        />
      )}

      {renderConfirmationModal()}
      {renderDeleteSuccessModal()}
    </div>
  );
};

export default NotificationList;