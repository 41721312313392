import React from 'react';
import { DelgazWrapper } from '@components/global/DelgazWrapper';
import FormularComanda from '@components/formularComanda/FormularComanda';

export const OrderForm = (): JSX.Element => {
    return (
        <DelgazWrapper>
            <FormularComanda />
        </DelgazWrapper>
    );
};