import { toggleLoader } from '@actions/LoaderActions';
import { GetCounties } from '@api/geoApi';
import { Dropdown, DropdownOptionType } from '@components/common/Dropdown';
import MaskedInput from '@components/common/Input/MaskedInput';
import { UTILITY_GN } from '@constants/Utility';
import { Fragment, useEffect, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';

type CountyTemplate = {
  countyId: number;
  countyName: string;
  countyCode: string;
};

const Verify = (props: {
  onClick: any;
  pod: any;
  handlePod: any;
  county: any;
  handleCounty: any;
  disabled?: boolean;
  setCountyObject: (county: any) => void;
  utility: number;
}) => {
  const { onClick, pod, handlePod, county, handleCounty, setCountyObject, utility } = props;
  const [counties, setCounties] = useState<CountyTemplate[]>([]);

  const dispatch = useDispatch();

  useEffect(() => {
    mountCounties();
  }, []);

  const mountCounties = async () => {
    dispatch(toggleLoader(true));

    await GetCounties().then((res) => {
      setCounties(res);
    });

    dispatch(toggleLoader(false));
  };

  return (
    <Fragment>
      <Row className="d-flex justify-content-center">
        <Col xs={12} sm={4} className="mb-4">
          <MaskedInput
            value={pod}
            mbZero
            onChange={(e: any) => {
              const currentValue = (e.target.value ? e.target.value.toLocaleUpperCase() : '') as string;
              handlePod(currentValue);
            }}
            utility={utility == UTILITY_GN ? 'GN' : 'EE'}
            label="Cod punct de masurare*"
            mask={'0123333333'}
            disabled={props.disabled}
          />
        </Col>

        <Col xs={12} sm={4}>
          <Dropdown
            options={
              counties.map((option, index) => ({
                id: option.countyCode,
                name: option.countyName
              })) as DropdownOptionType[]
            }
            onChange={(value) => {
              handleCounty(value);
              setCountyObject(counties.find((c) => c.countyCode == value));
            }}
            label={'Județ*'}
            defaultPlaceholder={'Alege județ'}
            displaySearch={true}
            value={county}
            disabled={props.disabled}
          />
        </Col>
      </Row>
      <Row className="justify-content-center mb-3">
        <Col xs={12} sm={8}>
          <div className="triplet-separator"></div>
        </Col>
      </Row>
      <Row>
        <div className="d-flex justify-content-center">
          <Button className="d-flex flex-row align-items-center" onClick={onClick} disabled={pod === '' || county === '' || props.disabled}>
            {/* <FontAwesomeIcon icon={faMagnifyingGlass} style={{ marginRight: '5px' }} /> */}
            <span>Căutare</span>
          </Button>
        </div>
      </Row>
    </Fragment>
  );
};

export default Verify;
