import React from 'react';
import { DelgazWrapper } from '@components/global/DelgazWrapper';
import AdCardMapButtonComponent from '@components/dev/Components/AdCard/AdCardMapButtonComponent';
import { AdCardGoogleMapsKentico } from '@type/kentico-types';

export type AdCardProps = { kentico: AdCardGoogleMapsKentico };

export const AdCardMapButton = (props: AdCardProps): JSX.Element => {
    return (
        <DelgazWrapper>
            <AdCardMapButtonComponent {...props}/>
        </DelgazWrapper>
    );
};