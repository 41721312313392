import { AchizitieKentico } from '@type/kentico-types';
import { Row } from 'react-bootstrap';


const DetaliiAchizitie = (props: {kentico: AchizitieKentico}) => {
    const item = props.kentico;

    const showProperties = () => {
        return Object.keys(item).map((prop: any, index) => {
            if (item[prop].details.is_link === false && item[prop].details.is_download === false && item[prop].details.is_image === false && item[prop].name !== 'imagine' && item[prop].name !== 'Latitude' && item[prop].name !== 'Longitude' && item[prop].name !== 'Title' && item[prop].name !== 'ImageDisplay' && item[prop].name !== 'Telefon' && item[prop].name !== 'Fax' && item[prop].name !== 'email' && item[prop].name !== 'Tip_Centru') {
                return (
                    <div key={index} className="d-flex flex-row">
                        { item[prop].value !== '' && <div className="d-flex flex-row">
                            {item[prop].details.show_property && <span style={{fontWeight: 'bold'}}>{item[prop].name}: &nbsp;</span>}
                            <div
                                key={index}
                                dangerouslySetInnerHTML={{__html: item[prop].value}}
                                className="mb-3"
                                style={item[prop].details.is_highlight ? {fontWeight: 'bold'} : {fontWeight: 'normal'}}
                            />
                        </div>
                        }
                    </div>
                );
            }
        });
    };

    const getLinkName = (link: string) => {
        var name = '';
        for (let i=link.length-1; i>=0; --i) {
            if (link[i] === '/') break;
            name += link[i];
        }
        name = name.slice(4,name.length);
        name = name.split('').reverse().join('');

        return name.replaceAll('_', ' ');
    };

    const showLinks = () => {
        return Object.keys(item).map((prop: any, index) => {
            if (item[prop].name !== 'Poze' && item[prop].details.is_image === false && (item[prop].details.is_link === true || item[prop].details.is_download === true) ) {
                return (
                    <div key={index} className="d-flex flex-column">
                        {item[prop].value !== '' && item[prop].value && item[prop].value !== null && item[prop].value.split(';').map((link, i) => {
                            return (
                                <span key={i} className="d-flex flex-column">
                                        <a className="red" href={link}>{item[prop].details.show_document_name ? getLinkName(link) : 'Descarcă'}{'\n'}</a>
                                    </span>
                            );
                        })}
                    </div>
                );
            }
        });
    };

    const showProperty = (name: string) =>{
        return Object.keys(item).map((prop: any, index) => {
            if (item[prop].name === name) {
                return <div key={index} dangerouslySetInnerHTML={{ __html: item[prop].value}} className="m-2"/>;
            }
        });
    };

    return (
        <div className="container-detalii mt-4 p-3">
            <h5 style={{fontSize: '29px'}} className="red">Detalii Anunț</h5>
            <h5>{showProperty('Tip_Centru')}</h5>
            <Row>
                <div style={{width: '100%'}}>
                    {/*{showImages()}*/}
                    <div className="mt-2 mb-2">
                        {showProperties()}
                        <b>Documente:</b>
                        {showLinks()}
                    </div>
                </div>
            </Row>
        </div>
    );
};

export default DetaliiAchizitie;