import { toggleLoader } from '@actions/LoaderActions';
import { checkSMS, getGDPRFile, getUserDetails, postStepSeven, sendSMS } from '@api/prosumatori/prosumatori';
import { Input } from '@components/common/Input';
import { IState } from '@type/store';
import { useEffect, useState } from 'react';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import 'react-phone-number-input/style.css';
import PhoneInput, { isValidPhoneNumber, parsePhoneNumber } from 'react-phone-number-input';

const InformareFinala = (props: {isEditing: any, checkedOne: any, handleCheckOne: any, checkedTwo: any, handleCheckTwo: any, requestId: any, setStep: any, currentStep: any}) => {
    const [wasSent, setWasSent] = useState(false);
    const [phone, setPhone] = useState<any>('');
    const [wasSMSSent, setWasSMSSent] = useState(false);
    const [code, setCode] = useState('');
    const [success, setSuccess] = useState('');
    const [error, setError] = useState('');
    const [stepError, setStepError] = useState('');
    const [wasSuccessful, setWasSuccessful] = useState(false);
    const [GDPRFileURL, setGDPRFileURL] = useState('');
    const [phoneFormatError, setPhoneFormatError] = useState<string>('');
    const [phoneCountry, setPhoneCountry] = useState<any>('RO');
    const [email, setEmail] = useState<string>('');
    const [emailError, setEmailError] = useState<string>('');
    const [isInputDisabled, setIsInputDisabled] = useState(false);
    const [showEmailInput, setShowEmailInput] = useState(false);

    const {setStep, currentStep} = props;

    const RedirectURL: string | undefined = useSelector<IState>((state) => state.options.webApiHost) as string;

    const dispatch = useDispatch();

    const getUserPhone = async () => {
        await getUserDetails()
            .then((response) => {
                if (response && response.mobilePhone) {
                    if (response.mobilePhone[0] === '0' && response.mobilePhone[1] === '7') {
                        setPhone('+4' + response.mobilePhone);
                    } else {
                        setPhone(response.mobilePhone);
                        let c = parsePhoneNumber(response.mobilePhone)?.country;
                        setPhoneCountry(c || 'RO');
                    }
                } else if (response && response.contactMobilePhone) {
                    if (response.contactMobilePhone[0] === '0' && response.contactMobilePhone[1] === '7') {
                        setPhone('+4' + response.contactMobilePhone);
                    } else {
                        setPhone(response.contactMobilePhone);
                        let c = parsePhoneNumber(response.contactMobilePhone)?.country;
                        setPhoneCountry(c || 'RO');
                    }
                } else if (response && response.contactPhone) {
                    if (response.contactPhone[0] === '0' && response.contactPhone[1] === '7') {
                        setPhone('+4' + response.contactPhone);
                    } else {
                        setPhone(response.contactPhone);
                        let c = parsePhoneNumber(response.contactPhone)?.country;
                        setPhoneCountry(c || 'RO');
                    }
                }

                setEmail(response.email);
            });
    };

    useEffect(() => {
        window.scrollTo(0,0);
        getUserPhone();
        getGDPRFile().then((res) => {
            setGDPRFileURL(res);
        });
    }, []);

    useEffect(() => {
        if (wasSuccessful === true) {
            setTimeout(() => {
                window.location.href = `${RedirectURL}/lista-cereri-racordare`;
            }, 3000);
        }
    }, [wasSuccessful]);

    const handleSendRequest = () => {
        const payload = {
            informare_1: props.checkedOne,
            informare_2: props.checkedTwo,
            requestId: props.requestId
        };

        stepSevenApiCall(payload);
    };

    const stepSevenApiCall = async (payload: any) => {
        setStepError('');
        dispatch(toggleLoader(true));

        await postStepSeven(payload)
            .then((response: any) => {
                if (response && response.status === 200) {
                    setWasSent(true);
                }
            })
            .catch((error: any) => {
                if (error && error.status === 200) {
                    setWasSent(true);
                } else if (error === 'Request is completed') {
                    setStepError('Cererea a fost deja finalizată.');
                } else if (error && error.StatusCode && error.StatusCode === 500) {
                    setStepError('A intervenit o eroare tehnică. Vă rugăm reveniți mai târziu.');
                } else if (error && error.statusCode && error.statusCode === 500) {
                    setError('A intervenit o eroare tehnică. Vă rugăm reveniți mai târziu.');
                }
            });

        dispatch(toggleLoader(false));
    };

    const callSendSMS = async (payload: any) => {
        dispatch(toggleLoader(true));

        await sendSMS(payload)
            .then((response: any) => {
                if (response && response.status === 200) {
                    setWasSMSSent(true);
                }
            })
            .catch((error: any) => {
                if (error && error.status === 200) {
                    setWasSMSSent(true);
                } else if (error === 'Request is completed') {
                    setError('Cererea a fost deja finalizată.');
                } else if (error && error.statusCode && error.statusCode === 500) {
                    setError('A intervenit o eroare tehnică. Vă rugăm reveniți mai târziu.');
                }
            });

        dispatch(toggleLoader(false));
    };

    const callCheckSMS = async (payload: any) => {
        dispatch(toggleLoader(true));

        await checkSMS(payload)
            .then((response: any) => {
                if (response && response.status === 200) {
                    if (response === false || response.data === false) {
                        setError('Codul primit nu este corect.');
                    } else if (response === true || (response.data && response.data === true)) {
                        setSuccess('Cererea a fost trimisă pentru procesare și se poate descărca din secțiunea Listă cereri.');
                        setWasSuccessful(true);
                    }
                } else if (response === true) {
                    setSuccess('Cererea a fost trimisă pentru procesare și se poate descărca din secțiunea Listă cereri.');
                    setWasSuccessful(true);
                }
            })
            .catch((error: any) => {
                if (error && error.status === 200) {
                    if (error === false || error.data === false) {
                        setError('Codul primit nu este corect.');
                    } else if (error === true) {
                        setSuccess('Cererea a fost generată și se poate descărca din secțiunea Listă cereri racordare.');
                    }
                } else if (error === 'Request is completed') {
                    setError('Cererea a fost deja finalizată.');
                } else if (error && error.statusCode && error.statusCode === 500) {
                    setError('A intervenit o eroare tehnică. Vă rugăm reveniți mai târziu.');
                }
            });

        dispatch(toggleLoader(false));
    };

    const handleSendSMS = () => {
        setSuccess('');
        setError('');
        setCode('');

        let intl_phone = phone;

        if (intl_phone.startsWith('+40')) {
            intl_phone = intl_phone.slice(2);
        }

        callSendSMS({phoneNumber: intl_phone, requestId: props.requestId, ...phoneCountry !== 'RO' && { email: email }});
    };

    const handleCheckSMS = () => {
        setSuccess('');
        setError('');
        callCheckSMS({code: code, requestId: props.requestId, ...phoneCountry !== 'RO' && { email: email }});
    };

    const handlePrevStep = () => {
        setStep(currentStep-1);
    };

    const replaceAt = (s: string, index: number, replacement: string) => {
        return s.substring(0, index) + replacement + s.substring(index + replacement.length);
    };

    const showPhoneSecretFormat = (p: string) => {
        let phone = p;
        phone = replaceAt(phone, 2, '*');
        phone = replaceAt(phone, 3, '*');
        phone = replaceAt(phone, 4, '*');
        phone = replaceAt(phone, 5, '*');
        phone = replaceAt(phone, 6, '*');

        return phone;
    };

    useEffect(() => {
        if (phoneCountry !== 'RO') {
            setIsInputDisabled(true);
            setPhoneFormatError('');
        }
    }, [phoneCountry]);

    useEffect(() => {
        if (email !== '') {
            if (!/^[a-zA-Z0-9+._\-]+[a-zA-Z0-9]@[a-zA-Z0-9.\-_]+\.[a-zA-Z]{2,15}/.test(email)) {
                setEmailError('Adresa de email conține caractere nepermise, ori are formatul greșit.');
            } else {
                setEmailError('');
            }
        } else {
            setEmailError('');
        }
    }, [email]);

    const changeCountry = (newCountry: string | undefined) => {
        setPhoneCountry(newCountry);
        
        // Clear phone number and error if country is changed to non-RO
        if (newCountry !== 'RO') {
          setPhone('');  // Clear the phone number
          setPhoneFormatError('');  // Clear any error
          setShowEmailInput(true);
        }
    };

    const renderModal = () => {
        return (
            <Modal style={{'marginTop' : '3%'}} show={wasSent} onHide={() => setWasSent(false)} className="modal-prosumatori">
                <Modal.Title>
                    <div className="p-3" style={{color: '#ea1c0a', fontWeight: 'bold'}}>
                        <span>Cod confirmare</span>
                    </div>
                </Modal.Title>
                <Modal.Body>
                    <p>Pentru a confirma corectitudinea informațiilor completate în cerere și a o transmite pentru înregistrare, vei primi un cod pentru semnarea cererii, prin SMS, la numărul de telefon:</p>
                    <Row className="mb-4">
                        <Col xs={12}>
                            <div className="position-relative">
                                { phoneCountry !== 'RO' && 
                                    <span
                                        onClick={() => { setIsInputDisabled(false); setShowEmailInput(false); }}
                                        className='red'
                                        style={{ 
                                            position: 'absolute', 
                                            right: 0, 
                                            top: '50%', 
                                            transform: 'translate(-50%, -50%)',
                                            textDecoration: 'underline',
                                            cursor: 'pointer',
                                            zIndex: 2
                                        }}
                                    >
                                        Modifică
                                    </span>
                                }
                                <PhoneInput 
                                    international
                                    value={phoneCountry === 'RO' ? phone : ''}
                                    defaultCountry={phoneCountry}
                                    onChange={(e) => {
                                        if (e) {
                                            setPhone(e);
                                        } else {
                                            setPhone('');
                                        }

                                        if (e && !isValidPhoneNumber(e) && phoneCountry === 'RO') {
                                            setPhoneFormatError('Numărul de telefon are formatul greșit.');
                                        } else if (e && isValidPhoneNumber(e)) {
                                            setPhoneFormatError('');
                                        }
                                    }}
                                    disabled={isInputDisabled}
                                    countryCallingCodeEditable={false}
                                    onCountryChange={(e) => changeCountry(e)}
                                    countrySelectProps={{ value: phoneCountry }}
                                    style={{ marginBottom: '18px', zIndex: 1 }}
                                />
                            </div>
                            {phoneFormatError !== '' && <p className="fw-bold red">{phoneFormatError}</p>}
                            {(phoneCountry !== 'RO' && showEmailInput) && <Input
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                label="Introdu adresa de email"
                                mbZero={emailError !== ''}
                            />}
                            {emailError !== '' && <p className="fw-bold red">{emailError}</p>}
                        </Col>
                        <Col xs={12}>
                            <Button disabled={(phoneCountry === 'RO' && !isValidPhoneNumber(phone)) || (phoneCountry !== 'RO' && (email === '' || emailError !== ''))} onClick={handleSendSMS}>Solicită cod de semnare</Button>
                        </Col>
                    </Row>
                    { wasSMSSent &&
                        <>
                            <p>Te rugăm să introduci codul pentru semnarea cererii{ phoneCountry === 'RO' && ` (Telefon: ${showPhoneSecretFormat(phone)})`}.</p>
                            <Row>
                                <Col xs={12}>
                                    <Input
                                        value={code}
                                        onChange={(e) => {
                                            const re = /^[0-9\b]+$/;

                                            if ((e.target.value === '' || re.test(e.target.value)) && e.target.value.length <= 6) {
                                                setCode(e.target.value);
                                            }
                                        }}
                                        label="Cod de semnare"
                                        placeholder="6 cifre"
                                    />
                                </Col>
                                <span className="fst-italic mb-4">
                                    Codul a fost transmis prin {phoneCountry === 'RO' ? `SMS` : 'email' }. Acesta este valabil timp de 10 minute. Dacă nu ai primit {phoneCountry === 'RO' ? `SMS-ul` : 'email-ul'}, click <span onClick={handleSendSMS} className="red hover-red text-decoration-underline pointer">aici</span>.
                                </span>
                                <Col xs={12}>
                                    <Button disabled={code.length < 6} onClick={handleCheckSMS}>Trimite cererea</Button>
                                </Col>
                            </Row>
                        </>
                    }
                    { success !== '' && <Row className="mt-4">
                        <p className="m-0" style={{fontWeight: 'bold', color: 'red'}}>{success}</p>
                    </Row> }
                    { error !== '' && <Row className="mt-4">
                        <p className="m-0"  style={{fontWeight: 'bold', color: 'red'}}>{error}</p>
                    </Row> }
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => {
                        setWasSent(false);
                        setWasSMSSent(false);
                        setCode('');
                        setError('');
                        setSuccess('');
                    }}>Anulare</Button>
                </Modal.Footer>
            </Modal>
        );
    };

    return (
        <div className="prosumatori-informare m-3 w-100 d-flex align-self-center flex-column">
            <h4 className="register-section-title mt-3">Informare</h4>
            <div className="checkbox-container mt-3 mb-4" onClick={() => {
                if (props.isEditing !== false) {
                    props.handleCheckOne(!props.checkedOne);
                }
            }}>
                <input
                    className="create-account-checkbox"
                    type="checkbox"
                    checked={props.checkedOne}
                    onChange={() => props.handleCheckOne(!props.checkedOne)}
                    disabled={props.isEditing === false}
                />
                <span style={{marginLeft: '5px'}}>
                    Prin semnarea prezentei cereri confirm primirea unui exemplar al <a href={GDPRFileURL} download={GDPRFileURL} target="_blank" className="red hover-red" rel="noreferrer">Notei de informare cu privire la prelucrarea datelor cu caracter personal</a>, ce conţine printre altele, informaţii referitoare la scopul şi temeiul prelucrăriii, durata prelucrării, drepturile mele, măsuri de securitate, reclamaţii etc.
                </span>
            </div>

            <div className="checkbox-container mb-4" onClick={() => {
                if (props.isEditing !== false) {
                    props.handleCheckTwo(!props.checkedTwo);
                }
            }}>
                <input
                    className="create-account-checkbox" type="checkbox"
                    checked={props.checkedTwo}
                    onChange={() => props.handleCheckTwo(!props.checkedTwo)}
                    disabled={props.isEditing === false}
                />
                <span style={{marginLeft: '5px'}}>
                    În considerarea prevederilor art. 326 din Codul penal referitoare la falsul în declaraţii, declar că toate informaţiile şi documentele ce însoţesc prezenta cerere sunt corecte şi reale. De asemenea mă angajez să prezint operatorului de distribuție Delgaz Grid, la solicitarea acestuia, documentele în original.
                </span>
            </div>

            {stepError !== '' && <p style={{fontWeight: 'bold', color: 'red'}}>{stepError}</p>}
            { props.isEditing === false &&
                <p style={{fontWeight: 'bold', color: 'red'}}>Cererea a fost generată și se poate descărca din secțiunea Listă cereri racordare.</p>
            }
            <div className="d-flex flex-row">
                <Button className="d-flex" style={{marginRight: '8px'}} onClick={handlePrevStep}>Pasul anterior</Button>
                {(props.isEditing !== false) && <Button disabled={!props.checkedOne || !props.checkedTwo} onClick={handleSendRequest}>Trimite cerere</Button>}
            </div>
            {renderModal()}
        </div>
    );
};

export default InformareFinala;