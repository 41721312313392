import React from 'react';
import { DelgazWrapper } from '@components/global/DelgazWrapper';
import IncarcareFisaNoua from '@components/revizii/IncarcareFisaNoua/IncarcareFisaNoua';

export const IncarcareFisaNouaComponent = () => {
  return (
    <DelgazWrapper>
      <IncarcareFisaNoua />
    </DelgazWrapper>
  );
};
