import React from 'react';
import { DelgazWrapper } from '@components/global/DelgazWrapper';
import IntreruperiFinalizateEE from '@components/intreruperiProgramateEE/IntreruperiFinalizateEE';

export const IntreruperiProgramateFinalizateEEComponent = (props: any): JSX.Element => {
  return (
    <DelgazWrapper>
      <IntreruperiFinalizateEE />
    </DelgazWrapper>
  );
};
