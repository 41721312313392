import React, { useState, Fragment } from 'react';
import LoginForm from '@components/auth/login/LoginForm';
import ValidateOTPForm from '@components/auth/validateOtp/ValidateOTPForm';
import ForcedChangePasswordForm from '@components/auth/forcedChangePassword/ForcedChangePasswordForm';

const AuthComponent = () => {
    const [state, setState] = useState('login');
    const [transactionId, setTransactionId] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const setUserEmail = (email: string) => {
        setEmail(email);
    };

    const setUserPassword = (password: string) => {
        setPassword(password);
    };

    const switchStates = (state: string) => {
        setState(state);
    };

    const setTId = (TId: string) => {
        setTransactionId(TId);
    };

    return (
        <Fragment>
            { state === 'login' && <LoginForm switchStates={switchStates} setTId={setTId} setUserEmail={setUserEmail} setUserPassword={setUserPassword}/> }
            { state === 'validate_otp' && <ValidateOTPForm switchStates={switchStates} transactionId={transactionId} email={email} password={password}/> }
            { state === 'change_password' && <ForcedChangePasswordForm switchStates={switchStates} transactionId={transactionId} email={email}/> }
        </Fragment>
    );
};

export default AuthComponent;