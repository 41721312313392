import React, { Fragment, useState, useEffect } from 'react';
import { Table, Row, Col, Button } from 'react-bootstrap';
import CountySelect from '@components/auth/createAccount/CountySelect';
import LocalitySelect from '@components/auth/createAccount/LocalitySelect';
import {GetCounties, GetLocalities} from '@api/geoApi';
import {consumptionPoints, saveConsumptionPoints} from '@api/user/user';
import {useDispatch, useSelector} from 'react-redux';
import {toggleLoader} from '@actions/LoaderActions';
import AddCPTableHead from '@components/account/MyAccount/AddConsumptionPoint/table/AddCPTableHead';
import AddCPTableRow from '@components/account/MyAccount/AddConsumptionPoint/table/AddCPTableRow';
import {jwtDecode} from 'jwt-decode';
import {IState} from '@type/store';

type JWT = {
    aud: string;
    aut: string;
    azp: string;
    deviceid: string;
    exp: number;
    given_name: string;
    groups: string[];
    iat: number;
    iss: string;
    jti: string;
    nbf: number;
    scope: string;
    sub: string;
    userid: string;
}

type ConsumptionPoint = {
    pod: string,
    address: string,
    isAvailable: boolean,
};

type CountyTemplate = {
    countyId: number,
    countyName: string,
    countyCode: string
};

type LocalityTemplate = {
    localityId: number,
    localityName: string,
    localityCode: string
};

type PayloadTemplate = {
    pod?: string,
    countyId?: number,
    localityId?: number
}

// const exampleItems = [
//     {
//         pod: 'EMO1234567',
//         address: 'Adresa test',
//         isAvailable: true
//     },
//     {
//         pod: 'EMO1234555',
//         address: 'Adresa test 2',
//         isAvailable: false
//     },
//     {
//         pod: 'EMO1111111',
//         address: 'Adresa test 3',
//         isAvailable: true
//     }
// ]

const AddConsumptionPoint = (props: any) => {
    const [items, setItems] = useState<ConsumptionPoint[]>([]);
    const [pod, setPod] = useState('');
    //eslint-disable-next-line
    const [showCounty, setShowCounty] = useState(true);
    const [counties, setCounties] = useState<CountyTemplate[]>([]);
    const [county, setCounty] = useState('');
    const [localities, setLocalities] = useState<LocalityTemplate[]>([]);
    const [locality, setLocality] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [selectedItems, setSelectedItems] = useState<ConsumptionPoint[]>([]);
    const [success, setSuccess] = useState(false);
    const [selectAll, setSelectAll] = useState(false);
    const dispatch = useDispatch();

    const token: string | undefined = useSelector<IState>((state) => state?.auth?.token) as string;

    useEffect(() => {
        GetCounties().then(res => setCounties(res));
    }, []);

    useEffect(() => {
        county !== '' && GetLocalities(county).then((res) => setLocalities(res));
    }, [county]);

    const submit = async () => {
        dispatch(toggleLoader(true));
        setErrorMessage('');
        setSuccess(false);
        setItems([]);
        setSelectedItems([]);

        let payload: PayloadTemplate = {};

        if (pod !== '') {
            payload.pod = pod;
        }

        if (county !== '') {
            let id = 0;

            counties.map((item) => {
                if (item.countyCode === county) {
                    id = item.countyId;
                }
            });

            payload.countyId = id;
        }

        if (locality !== '') {
            payload.localityId = Number(locality);
        }

        await consumptionPoints(payload).then((res) => {
            if (res) {
                if (res.data && res.data.items && res.data.items.length > 0) {
                    setItems(res.data.items);
                } else if (res.data.items.length === 0 && res.message) {
                    setErrorMessage(res.message);
                }
            }
        }).catch((e: any) => {
            if (e && e.errors && e.errors.pod) {
                setErrorMessage('Campul POD este obligatoriu.');
            }
        });

        dispatch(toggleLoader(false));
    };

    const onSelect = (item: ConsumptionPoint) => {
        setSelectAll(false);
        setSelectedItems([]);

        let isAlready = false;

        selectedItems.map((search) => {
            if (JSON.stringify(item) === JSON.stringify(search)) {
                isAlready = true;
            }
        });

        !isAlready && setSelectedItems(prev => [...prev, item]);
        isAlready && setSelectedItems(prev => prev.filter(el => JSON.stringify(el) !== JSON.stringify(item)));
    };

    const handleSelectAll = () => {
        if (selectAll === false) {
            setSelectAll(true);
            let selected: ConsumptionPoint[] = [];

            items.map((item) => {
                if (item.isAvailable) {
                    selected.push(item);
                }
            });

            setSelectedItems(selected);
        } else {
            setSelectAll(false);
            setSelectedItems([]);
        }
    };

    const saveCP = async () => {
        dispatch(toggleLoader(true));

        let pods: any = [];

        selectedItems.map((item, index) => pods.push(item.pod));

        let jwt: JWT = jwtDecode(token);

        const payload = {
            userId: jwt.userid,
            consumptionPoints: pods
        };

        await saveConsumptionPoints(payload).then((res: any) => {
            res.length === 0 && setSuccess(true);
        }).catch((e: any) => console.log(e));

        dispatch(toggleLoader(false));
    };

    const saveAllCP = async () => {
        dispatch(toggleLoader(true));

        let jwt: JWT = jwtDecode(token);

        const payload = {
            userId: jwt.userid,
            consumptionPoints: [pod],
            saveAllConsumptions: true
        };

        await saveConsumptionPoints(payload).then((res: any) => {
            res.length === 0 && setSuccess(true);
        }).catch((e: any) => console.log(e));

        dispatch(toggleLoader(false));
    };

    return (
        <div className="m-3">
            <Row className="mt-3 mb-3">
                <h4 className="register-section-title">Adaugă punct consum</h4>
            </Row>
            <Row className="mb-3">
                <Col xs={12} md={6}>
                    <input placeholder="POD" value={pod} onChange={
                        (e) => {
                            setErrorMessage('');
                            setPod(e.target.value);
                        }}/>
                </Col>
            </Row>
            <Row>
                <Col xs={12} md={6}>
                    <CountySelect
                        onChange={(e) => {
                            e.preventDefault();
                            setErrorMessage('');
                            e.target.value.length !== 0 && setCounty(e.target.value);
                        }}
                        options={counties}
                        placeholder={'Alege județul'}
                        defaultValue={''}
                        value={county}
                    />
                </Col>
            </Row>
            {county !== '' && <Row>
                <Col xs={12} md={6}>
                    <LocalitySelect
                        onChange={(e) => {
                            e.preventDefault();
                            setErrorMessage('');
                            e.target.value.length !== 0 && setLocality(e.target.value);
                        }}
                        options={localities}
                        placeholder={'Alege localitatea'}
                        defaultValue={''}
                        value={locality}
                    />
                </Col>
            </Row>}
            <div style={showCounty ? {marginTop: '-15px', marginBottom: '8px'} : {marginTop: '0', marginBottom: '8px'}}>
                {errorMessage && <p className="register-section-title" style={{fontWeight: 'bold'}}>{errorMessage}</p>}
            </div>
            <div style={showCounty ? {marginTop: '-15px'} : {marginTop: '8px'}}>
                <Button onClick={() => submit()}>Salvează</Button>
            </div>
            {success && <div style={{color: 'red', fontWeight: 'bold'}}>Punctele de consum au fost adăugate cu succes.</div>}
            {
                items.length > 0 && <Fragment>
                    <div className="mt-4 d-flex align-items-center" onClick={() => handleSelectAll()}>
                        <input type="checkbox" className="create-account-checkbox" checked={selectAll} readOnly={true} />
                        <span style={{marginLeft: '6px'}}>Bifează-le pe toate</span>
                    </div>
                    <div className="mt-2 mb-2 general-table-container">
                        <Table>
                            <AddCPTableHead />
                            <tbody>
                            { items.map((item, index) => (
                                <AddCPTableRow item={item} key={index} onSelect={onSelect} allSelected={selectAll}/>
                            )) }
                            </tbody>
                        </Table>
                    </div>
                    <div className="mt-3 mb-3 d-flex justify-content-end">
                        {(selectedItems.length > 0 && selectAll === false) && <Button onClick={() => saveCP()}>Adaugă</Button>}
                        {(selectedItems.length > 0 && selectAll === true) && <Button onClick={() => saveAllCP()}>Adaugă</Button>}
                        {selectedItems.length <= 0 && <Button disabled>Adaugă</Button>}
                    </div>
                </Fragment>
            }
        </div>
    );
};

export default AddConsumptionPoint;