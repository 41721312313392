import React, { Fragment } from 'react';

const TotalColRow = (props: any) => {
  //eslint-disable-next-line
  const { rows, totalCol, handledCols, columns } = props;

  const calcRowTotal = (index: number) => {
    let total = 0;

    rows.map((row: any, idx: number) =>
      Object.keys(row).map((key: any, i: number) => {
        if (totalCol.includes(index) && index === i) {
          total += Number(row[key]);
        }
      })
    );

    return total;
  };

  return (
    <Fragment>
      {rows.map((row: any, index: number) => {
        if (index === 0) {
          return (
            <tr key={index} style={{ height: '25px' }}>
              {Object.keys(row).map((key: any, idx) => {
                return (
                  <td style={{ border: '1px solid grey', background: 'lightgray' }} key={idx}>
                    <input
                      disabled
                      className="table-input disabled"
                      value={calcRowTotal(idx) > 0 ? 'Total: ' + String(calcRowTotal(idx)) : ''}
                    />
                  </td>
                );
              })}
            </tr>
          );
        }
      })}
    </Fragment>
  );
};

export default TotalColRow;
