import { Dispatch } from 'redux';
import { OptionsPayload } from '@reducers/options';
import { SET_OPTIONS, CLEANUP_OPTIONS, FETCH_USER_STATE } from '@constants/ActionTypes/PostTypes';

export function setOptions(payload?: OptionsPayload) {
  return {
    type: SET_OPTIONS,
    payload: { ...payload } as OptionsPayload
  };
}

export function cleanUpOptions() {
  return (dispatch: Dispatch) => dispatch({ type: CLEANUP_OPTIONS, payload: {} as OptionsPayload });
}

export function fetchAuthState() {
  return { type: FETCH_USER_STATE, payload: {} as OptionsPayload };
}

