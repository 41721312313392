import { getClientPortofolio } from '@api/user/user';
import React, { useEffect, useState } from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import TabEE from './TabEE';
import TabGN from './TabGN';
import { toggleLoader } from '@actions/LoaderActions';
import { useDispatch } from 'react-redux';
import Pagination from '@components/common/Pagination/Pagination';
import { CountyTemplate, LocalityTemplate } from '@type/geo';
import { GetCounties, GetLocalities } from '@api/geoApi';

const ConsumptionPointsList = (props: { handleList: any, handleMetadata: any, selected: any, handleSelect: any, onTabChange: any, subject: any }) => {
    const [list, setList] = useState([]);
    const [selectedTab, setSelectedTab] = useState<string | null>(props.subject.includes('GN') ? '2' : '1');
    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalItems, setTotalItems] = useState(0);
    //search
    const [searchCLC, setSearchCLC] = useState('');
    const [searchPod, setSearchPod] = useState('');
    const [searchPartnerCode, setSearchPartnerCode] = useState('');
    const [counties, setCounties] = useState<CountyTemplate[]>([]);
    const [county, setCounty] = useState('');
    const [localities, setLocalities] = useState<LocalityTemplate[]>();
    const [locality, setLocality] = useState('');
    //eslint-disable-next-line
    const [didSearch, setDidSearch] = useState(false);
    // const [toggleSearch, setToggleSearch] = useState(false);
    // const [errorNoResultsForSearch, setErrorNoResultsForSearch] = useState(false);

    const dispatch = useDispatch();

    useEffect(() => {
        mountCounties();
    }, []);


    useEffect(() => {
        county !== '' && mountLocalities();
    }, [county]);

    const mountCounties = async () => {
        dispatch(toggleLoader(true));

        await GetCounties().then(res => {
            setCounties(res);
        }).catch(err => console.log(err));

        dispatch(toggleLoader(false));
    };

    const mountLocalities = async () => {
        dispatch(toggleLoader(true));

        await GetLocalities(county).then((res) => setLocalities(res)).catch((err) => console.log(err));

        dispatch(toggleLoader(false));
    };

    useEffect(() => {
        setList([]);

        let payload = {
            utility: selectedTab,
            pageNumber: pageNumber,
            pageSize: pageSize,
            pod: '',
            clc: '',
            countyId: '',
            localityId: ''
        };

        mountConsumptionPoints(payload);
    }, [selectedTab, pageNumber, pageSize]);

    const mountConsumptionPoints = async (payload: any) => {
        dispatch(toggleLoader(true));

        await getClientPortofolio(payload).then((res) => {
            if (res && res.items && res.metadata) {
                props.handleList(res.items);
                props.handleMetadata(res.metadata);
                setPageSize(res.metadata.pageSize);
                setPageNumber(res.metadata.pageIndex);
                setTotalItems(res.metadata.totalItemsCount);

                setList(res.items);
            }
        }).catch((err) => console.log('Error'));

        dispatch(toggleLoader(false));
    };

    const onSelectTab = (eventKey: string | null) => {
        setSelectedTab(eventKey);
        props.onTabChange();
    };

    const paginate = (pageNumber: number) => {
        setPageNumber(pageNumber);
    };

    const setElementsPerPage = (number: number) => {
        setPageSize(number);
    };

    const localityChange = (locality: any) => {
        setLocality(locality);
    };

    const CLCChange = (clc: any) => {
        setSearchCLC(clc);
    };

    const PodChange = (pod: any) => {
        setSearchPod(pod);
    };

    const PartnerCodeChange = (partnerCode: any) => {
        setSearchPartnerCode(partnerCode);
    };

    const countyChange = (county: any) => {
        setCounty(county);
        setLocality('');
    };

    const searchConsumptionPoints = async (
        utility?: any,
        county?: any,
        locality?: any,
        pod?: any,
        clc?: any,
        partnerCode?: any,
        firstPage?: boolean
    ) => {
        let payload = {
            utility: selectedTab,
            countyId: county || '',
            localityId: locality || '',
            pod: pod || '',
            clc: clc || '',
            partnerCode: partnerCode || ''
        };

        if (county) {
            let id = 0;
            counties.map((item) => {
                if (item.countyCode === county) {
                    id = item.countyId;
                }
            });
            payload.countyId = id;
        }
        if (locality) {
            payload.localityId = Number(locality);
        }
        mountConsumptionPoints(payload);
    };

    const submitSearch = () => {
        setPageNumber(1);
        setDidSearch(true);
        searchConsumptionPoints(selectedTab, county, locality, searchPod, searchCLC, searchPartnerCode, true);
    };

    const resetSearch = (utility: number) => {
        setCounty('');
        setLocality('');
        setSearchPod('');
        setSearchCLC('');
        setSearchPartnerCode('');

        let payload = {
            utility: selectedTab,
            pageNumber: pageNumber,
            pageSize: pageSize,
            pod: '',
            clc: '',
            countyId: '',
            localityId: ''
        };

        mountConsumptionPoints(payload);
    };

    return (
        <div className="mt-2">
            <Tabs className="my-account-tabs" defaultActiveKey={props.subject.includes('GN') ? '2' : '1'} mountOnEnter={true} unmountOnExit={true} onSelect={onSelectTab}>
                {!props.subject.includes('GN') && <Tab eventKey={'1'} title="Portofoliu Energie Electrică">
                    <TabEE
                        list={list}
                        selected={props.selected}
                        handleSelect={props.handleSelect}
                        countyChange={countyChange}
                        counties={counties}
                        localityChange={localityChange}
                        localities={localities}
                        submitSearch={submitSearch}
                        county={county}
                        locality={locality}
                        clcChange={CLCChange}
                        podChange={PodChange}
                        partnerCodeChange={PartnerCodeChange}
                        reset={resetSearch}
                        searchPod={searchPod}
                        searchCLC={searchCLC}
                        searchPartnerCode={searchPartnerCode}
                    />
                </Tab>}
                {!props.subject.includes('EE') && <Tab eventKey={'2'} title="Portofoliu Gaze Naturale">
                    <TabGN
                        list={list}
                        selected={props.selected}
                        handleSelect={props.handleSelect}
                        countyChange={countyChange}
                        counties={counties}
                        localityChange={localityChange}
                        localities={localities}
                        submitSearch={submitSearch}
                        county={county}
                        locality={locality}
                        clcChange={CLCChange}
                        podChange={PodChange}
                        partnerCodeChange={PartnerCodeChange}
                        reset={resetSearch}
                        searchPod={searchPod}
                        searchCLC={searchCLC}
                        searchPartnerCode={searchPartnerCode}
                    />
                </Tab>}
            </Tabs>
            {list.length > 0 && <Pagination
                currentPage={pageNumber}
                totalElements={totalItems}
                perPage={pageSize}
                setElementsPerPage={setElementsPerPage}
                paginate={paginate}
            />}
        </div>
    );
};

export default ConsumptionPointsList;