import React from 'react';
import { DelgazWrapper } from '@components/global/DelgazWrapper';
import IntreruperiProgramateEE from '@components/intreruperiProgramateEE/IntreruperiProgramateEE';

export const IntreruperiProgramateEEComponent = (props: any): JSX.Element => {
  return (
    <DelgazWrapper>
      <IntreruperiProgramateEE />
    </DelgazWrapper>
  );
};
