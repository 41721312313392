import React from 'react';

const CPTableHead = (props: any) => {
  return (
    <thead>
      <tr>
        <th></th>
        <th>Cod partener</th>
        <th>Denumire partener</th>
        <th>{props.utility == 2 ? 'Număr loc consum' : 'Cod loc consum'}</th>
        <th>{props.utility == 2 ? 'Cod loc consum' : 'Cod punct de măsură'}</th>
        <th>Adresă</th>
      </tr>
    </thead>
  );
};

export default CPTableHead;
