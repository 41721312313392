const ChoiceComponent = (props: {
    title?: string;
    subtitle?: any;
    options: any;
    onChange: any;
    value: any;
    colAlign?: boolean;
    disabled?: boolean;
    canUncheck?: boolean;
    id?: string;
    fullWidth?: boolean;
    mZero?: boolean;
    identifier?: string;
    children?: any;
  }) => {
    const { title, options, onChange, value, subtitle, colAlign, disabled, canUncheck, id, fullWidth, mZero, identifier, children } = props;
  
    return (
      <div className="mt-3 mb-3">
        {title && (
          <h5 className="m-0 d-flex" id={id}>
            {title} {children}
          </h5>
        )}
        {subtitle && <p>{subtitle}</p>}
        <div className={`d-flex ${!mZero && 'm-3'} choices ${colAlign && 'flex-column'} ${fullWidth && 'justify-content-between'}`}>
          {options.map((option: any, idx: number) => (
            <span
              key={idx}
              className="d-flex pointer"
              style={{ marginRight: '25px', marginBottom: '8px', alignItems: 'flex-start' }}
              onClick={() => {
                if (!disabled) {
                  if (canUncheck) {
                    if (!identifier) {
                      if (value === option.id) {
                        onChange(null);
                      } else {
                        onChange(option.id);
                      }
                    } else if (identifier === 'code') {
                      if (value === option.code) {
                        onChange(null);
                      } else {
                        onChange(option.code);
                      }
                    }
                  } else {
                    if (!identifier) {
                      onChange(option.id);
                    } else if (identifier === 'code') {
                      onChange(option.code);
                    }
                  }
                }
              }}
            >
              <input
                type="radio"
                onChange={() => {
                  if (!identifier) {
                    onChange(option.id);
                  } else if (identifier === 'code') {
                    onChange(option.code);
                  }
                }}
                value={option}
                checked={!identifier ? value === option.id : value === option.code}
                style={{ marginRight: '4px', marginTop: '8px' }}
                disabled={disabled}
              />
  
              <div className="d-flex justify-content-center">
                <span style={{ fontSize: '18px' }}>{option.title || option.name}</span>
                {option.children && <div style={{ marginLeft: '6px', marginTop: '3px' }}>{option.children}</div>}
                {option.subtitle && <span style={{ fontSize: '16px' }}>{option.subtitle}</span>}
              </div>
            </span>
          ))}
        </div>
      </div>
    );
  };
  
  export default ChoiceComponent;  