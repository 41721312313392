import React from 'react';
import { DelgazWrapper } from '@components/global/DelgazWrapper';
import { RedesignedResourcesComp } from '@components/dev/Components/Resources/RedesignedResources';
import { ResourcesKentico } from '@type/kentico-types';

export type ResourcesProps = { kentico: ResourcesKentico };

export const RedesignedResources = (props: ResourcesProps): JSX.Element => {
    return (
        <DelgazWrapper>
            <RedesignedResourcesComp {...props}/>
        </DelgazWrapper>
    );
};