import React from 'react';
import { DelgazWrapper } from '@components/global/DelgazWrapper';
import ResetPasswordForm from '@components/auth/resetPassword/ResetPasswordForm';

export const ResetPassword = () => {
    return (
        <DelgazWrapper>
            <ResetPasswordForm />
        </DelgazWrapper>
    );
};