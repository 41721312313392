import ClientPF from '@components/account/EditAccount/forms/ClientPF';
import ClientPJ from '@components/account/EditAccount/forms/ClientPJ';
import Furnizor from '@components/account/EditAccount/forms/Furnizor';
import Operator from '@components/account/EditAccount/forms/Operator';
import PartenerVM from '@components/account/EditAccount/forms/PartenerVM';
import { IDENTITY_ROLE_INTERNAL_EVERYONE } from '@constants/Roles/RolesIdentity';
import { IState } from '@type/store';
import { jwtDecode } from 'jwt-decode';
import { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

type JWT = {
    aud: string;
    aut: string;
    azp: string;
    deviceid: string;
    exp: number;
    given_name: string;
    groups: string[];
    iat: number;
    iss: string;
    jti: string;
    nbf: number;
    scope: string;
    sub: string;
    userid: string;
    Utilitate?: string,
}


const EditAccount = (props: any) => {
    const [role, setRole] = useState('');
    //eslint-disable-next-line
    const [utilitate, setUtilitate] = useState('');
    const token: string | undefined = useSelector<IState>((state) => state?.auth?.token) as string;

    useEffect(() => {
        let jwt: JWT = jwtDecode(token);
        jwt && setRole(jwt.groups.filter(role => role !== IDENTITY_ROLE_INTERNAL_EVERYONE)[0]);
        jwt && jwt?.Utilitate && setUtilitate(jwt?.Utilitate);
    }, []);

    const renderForm = () => {
        switch (role) {
            case 'ClientFizic': return <ClientPF />;
            case 'ClientJuridic': return <ClientPJ />;
            case 'OperatorEconomic': return <Operator />;
            case 'PartenerValoriMasurate': return <PartenerVM />;
            case 'Furnizor': return <Furnizor />;
            default: return <ClientPF />;
        }
    };

    return (
        <Fragment>
            <div className="mt-3 mb-3">
                {role !== '' && renderForm()}
            </div>
        </Fragment>
    );
};

export default EditAccount;
