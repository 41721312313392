import { proxy, Proxy } from '@api/azureProxy';
import { proxy as kenticoProxy, Proxy as KenticoProxy } from '@api/kenticoProxy';
import moment from 'moment';
import { AxiosRequestConfig } from 'axios';

import {
  MeterReading,
  ProgramariCitiriTemplate
} from '@type/programari-citiri';

import { meterReadingsBaseUrl, kenticoTemplatesUrl } from '@constants/ApiUrl';
import { NotificationInputs } from '@components/dev/System/ProgramariCitiri/NotificationModal';
import { ToString } from '@lib/Utils';


export async function GetTemplates(XApiKey: string) {
  const proxyData: KenticoProxy = {
    method: 'GET',
    endPoint: `${kenticoTemplatesUrl}?path=/Template-uri/Lipsa-acces`,
    withAuthBearer: false
  };
  
  const options: AxiosRequestConfig = {
    headers: {
      'XApiKey' : XApiKey,
      // 'Access-Control-Allow-Origin': '*',
    }};

  return kenticoProxy<ProgramariCitiriTemplate>(proxyData, options);
}

export async function GetAvailableSlots({
  contactNumber, codLocConsum
}: { contactNumber: string, codLocConsum: string }) {
  const proxyData: Proxy = {
    method: 'GET',
    endPoint: `${meterReadingsBaseUrl}/available_slots?numarContact=${contactNumber}&codLocConsum=${codLocConsum}`,
    // endPoint: `${meterReadingsBaseUrl}/available_slots?numarContact=6054579052&codLocConsum=5001668749`,
    withAuthBearer: false
  };

  return proxy<MeterReading>(proxyData);
}

export async function saveReading(reading: NotificationInputs) {
  let date = moment(reading.date).startOf('day');

  let stringDate = '';
  if (reading.timeHour) {
    date.add(reading.timeHour, 'seconds');
    stringDate = ToString(date.utc().format('YYYYMMDDHHmm'));
  } else {
    date.hour(3); //replaced with 0000
    stringDate = ToString(date.utc().format('YYYYMMDDHHmm'));
    stringDate = stringDate.slice(0, -4).concat('0000');
  }

  const proxyData: Proxy = {
    method: 'POST',
    endPoint: `${meterReadingsBaseUrl}/save`,
    withAuthBearer: false,
    data: {
      notificationId: reading.id,
      phoneNumber: reading.phoneNumber,
      utcDate: stringDate,
      comments: reading.comments,
      kid: reading.kid
    }
  };

  return proxy<MeterReading>(proxyData);
}
