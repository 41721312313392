import { ToNumber, ToString } from '@lib/Utils';



import moment from 'moment';

const CNPRegExp = new RegExp(/^([1-8])(0[0-9]|[1-9][0-9])(0[1-9]|1[0-2])(\d{2})(\d{2})(\d{3})(\d)$/);
const CNPControl = [2, 7, 9, 1, 4, 6, 3, 5, 8, 2, 7, 9];
const MAXIMUM_CHARACTERS = 100;
const MINIMUM_CHARACTERS = 4;

export const CNP_LAST_DIGITS = /^([0-9]{6})$/;

export function ConfirmEmail(confirmEmail = '') {
  return confirmEmail && false;
}

export function CNPValidation(CNP = '') {
  if (CNPRegExp.test(CNP)) {
    let bigSum = 0;

    for (let i = 0; i < CNPControl.length; i++) {
      bigSum += ToNumber(CNP.charAt(i)) * CNPControl[i];
    }

    let ctrlDigit = bigSum % 11;

    if (ctrlDigit === 10) {
      ctrlDigit = 1;
    }

    if (ctrlDigit === ToNumber(CNP[12])) {
      return true;
    }

    return false;
  }

  return false;
}

const PartnerCodeRegExp = new RegExp(/^1\d{9}(_[0-9]{1,}?)?$/);

export function PartnerCodeValidation(str = '') {
  return PartnerCodeRegExp.test(str);
}

const ConsumptionPointRegExp = new RegExp(/^5\d{9}$/);

export function ConsumptionPointValidation(str = '') {
  return ConsumptionPointRegExp.test(str);
}

export const AEM_ENV_FROM_HOST_PATTERN = /(?:\w)+(?=-www)/g; /* Get the AEM environment from host */

export const TEXT = /[^]{1,}/;
export const TEL = /^([0][0-9]{9}|.*?)$/;
export const EMAIL = /^([^ @]{2,}[@][^.@ ]{2,}([.][^.@ ]{2,}){1,})$/;
export const STREET_NUMBER = /^([1-9a-zA-Z][0-9a-zA-Z]{0,})([-][1-9a-zA-Z][0-9a-zA-Z]{0,}){0,}$/;
export const POSTAL_CODE = /^([0-9]{6})$/;
export const ID_CARD_SERIES = /^([A-Za-z]{2}[ -/\\.,;:|]{0,}[0-9]{6})$/;
export const NEW_ID_CARD_SERIES = /^([A-Za-z]{2}[ -/\\.,;:|]{0,}[0-9]{7})$/;
export const PASSWORD = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*|[#$^+=!*()@%&]).{8,}$/;
export const NUMBER = /[0-9]{1,}/;
export const FLOAT = /[0-9]{1,}\.{0,1}[0-9]{0,}/;

export function EmailValidation(email: string) {
  return EMAIL.test(email);
}

export function PeriodTypeValidation(periodType = {} as any) {
  return periodType && ToString(periodType?.id) !== '-1';
}

export function EndDateValidation(endDate = '' as string) {
  return endDate && moment(endDate, 'DD-MM-yyyy').isAfter(moment().subtract(1, 'days'));
}

export function MinCharacters(minCharacters = MINIMUM_CHARACTERS) {
  return (string: string) => {
    return !string || string.length >= minCharacters;
  };
}

export function MaxCharacters(maxCharacters = MAXIMUM_CHARACTERS) {
  return (string: string) => {
    return !string || string.length <= maxCharacters;
  };
}

export function SelectOptionValidator(value: string) {
  return value && ToString(value) !== '-1';
}
