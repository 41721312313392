
import PageWrapper from '@components/delgaz/BackOffice/PageWrapper';
import { DelgazWrapper } from '@components/global/DelgazWrapper';

export type BackOffice = {
  baseUrl: string,
  securityContext: string,
  user: string
}

export type BackOfficeComponentProps = {
    kentico: BackOffice
};

export const BackOfficeComponent = (props: BackOfficeComponentProps): JSX.Element => {

    return (
        <DelgazWrapper>
            <PageWrapper {...props} />
        </DelgazWrapper>
    );
};