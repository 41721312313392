import React from 'react';
import { DelgazWrapper } from '@components/global/DelgazWrapper';
import DecRecDetaliiSolicitare from '@components/decRec/detalii/DecRecDetaliiSolicitare';

export const DecRecDetaliiSolicitareComponent = () => {
  return (
    <DelgazWrapper>
      <DecRecDetaliiSolicitare />
    </DelgazWrapper>
  );
};
