import React from 'react';
import { DelgazWrapper } from '@components/global/DelgazWrapper';
import ConventionDetails from '@components/conventions/details/ConventionDetails';

export const CDetails = () => {
    return (
        <DelgazWrapper>
            <ConventionDetails />
        </DelgazWrapper>
    );
};