import React, { Fragment, useEffect, useState } from 'react';
import { getRequestsDetails } from '@api/rss/rss';
import { toggleLoader } from '@actions/LoaderActions';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Button } from 'react-bootstrap';
import { GetLocalities } from '@api/geoApi';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { IState } from '@type/store';
import moment from 'moment';
import { jwtDecode } from 'jwt-decode';

type JWT = {
  aud: string;
  aut: string;
  azp: string;
  deviceid: string;
  exp: number;
  given_name: string;
  groups: string[];
  iat: number;
  iss: string;
  jti: string;
  nbf: number;
  scope: string;
  sub: string;
  userid: string;
}

const Details = () => {
  const [item, setItem] = useState<any>(null);
  const [error, setError] = useState('');
  const [localityName, setLocalityName] = useState<string>('');
  const [errorInvalidRequestStatus, setErrorInvalidRequestStatus] = useState('');
  const token: string | undefined = useSelector<IState>((state) => state?.auth?.token) as string;
  const [clientType, setClientType] = useState<number | null>(null);
  let jwt: JWT = jwtDecode(token);

  useEffect(() => {
    if (jwt.groups.includes('ClientFizic') || jwt.groups.includes('ClientJuridic')) {
      setClientType(1);
    } else {
      setClientType(2);
    }
  }, []);

  const dispatch = useDispatch();

  const OLD_SITE_URL: string | undefined = useSelector<IState>((state) => state.options.webApiHost) as string;

  useEffect(() => {
    getInfo();
  }, []);

  useEffect(() => {
    if (item) {
      if (item.statusId == 1) {
        setErrorInvalidRequestStatus('Solicitarea nu a fost gasită.');
      }
    }
  }, [item]);

  const getLocality = (data: any) => {
    GetLocalities(data.countyCode).then((res) => {
      res.map((c: any, i: number) => {
        if (data.localityCode === c.localityCode) {
          setLocalityName(c.localityName);
        }
      });
    });
  };

  const getInfo = async () => {
    dispatch(toggleLoader(true));

    const url = new URL(window.location.href);
    // const url = new URL('https://dev.delgaz.ro/detalii-petitie-furnizor?id=160');

    const params = url.search;
    const searchParams = new URLSearchParams(params);

    if (searchParams.has('id')) {
      await getRequestsDetails(searchParams.get('id'))
        .then((res) => {
          if (res && res.data) {
            setItem(res.data);
            getLocality(res.data);
          } else {
            setError('Solicitarea nu a fost gasită.');
          }
        })
        .catch((err) => setError('Solicitarea nu a fost gasită.'));
    } else {
      setError('Solicitarea nu a fost gasită.');
    }

    dispatch(toggleLoader(false));
  };

  //eslint-disable-next-line
  const displayResponses = (responses: any[]) => {
    return responses.map((resp: any, idx: number) => {
      return (
        <div className="p-1 mb-2 d-flex flex-column" key={idx}>
          <span className="p-0 mb-0 " style={{ fontSize: '20px', color: '#202529', opacity: '0.5' }}>
            {resp.statusRaspuns === 'True' ? 'Răspuns Final' : 'Răspuns Intermediar'}
          </span>
          <span className="mb-0 mt-0" style={{ fontSize: '20px' }}>
            {resp.rezolutie}
          </span>
          <div className="d-flex flex-column mt-1">
            {resp.documents.map((doc: any, i: number) => {
              return (
                <a className="hover-red link--download pointer black text-decoration-none" key={i} href={doc.documentLink}>
                  {doc.fileName}
                </a>
              );
            })}
          </div>
        </div>
      );
    });
  };

  const displayResp = (responses: any[]) => {
    return (
      <div className="general-table-container">
        <table className="w-100">
          <thead>
            <th>Status</th>
            <th>Solutie</th>
            <th>Data</th>
            <th>Documente</th>
          </thead>
          <tbody>
            {responses.map((resp: any, idx: number) => {
              return (
                <tr key={idx}>
                  <td>{resp.statusRaspuns === 'True' ? 'Final' : 'Intermediar'}</td>
                  <td>{resp.rezolutie}</td>
                  <td>{moment(resp.sentDate).format('DD.MM.YYYY')}</td>
                  <td>
                    {resp.documents.map((doc: any, i: number) => {
                      return (
                        <a className="hover-red link--download pointer black text-decoration-none" key={i} href={doc.documentLink}>
                          {doc.fileName}
                        </a>
                      );
                    })}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  };

  return (
    <Fragment>
      {item !== null && (
        <div className="m-3 rss-details">
          <Row className="mt-2 mb-2">
            <div className="mb-3">
              <a href={`${OLD_SITE_URL}/${clientType === 1 ? 'lista-petitii' : 'petitii'}`}>
                <Button>
                  <FontAwesomeIcon icon={faChevronLeft} style={{ marginRight: '6px' }} />
                  <span>Înapoi</span>
                </Button>
              </a>
            </div>
            {!errorInvalidRequestStatus && (
              <>
                <Col xs={12} md={6} className="mb-3">
                  <div className="details-block h-100">
                    <div className="block-header">
                      <span className="block-title-border" />
                      <span className="block-title">
                        {item.requestNo !== null && item.requestNo} {item.requestNo && item.requestDate && '/'} {item.requestDate && moment(item.requestDate).format('DD.MM.YYYY')}
                      </span>
                    </div>
                    <div className="block-body">
                      <div className="property-container">
                        <span className="property-key">Status</span>
                        <span className="property-value">{item.statusName}</span>
                      </div>
                      <div className="property-container">
                        <span className="property-key">Dată depunere</span>
                        <span className="property-value">{moment(item.entryDate).format('DD.MM.YYYY')}</span>
                      </div>
                      <div className="property-container">
                        <span className="property-key">Tip adresă</span>
                        <span className="property-value">{item.requestTypeName}</span>
                      </div>
                      <div className="property-container justify-content-center d-block">
                        <span className="property-key">Documente</span>
                        <span className="d-flex flex-column">
                          {item.documents.map((doc: any, i: number) => (
                            <a className="hover-red link--download pointer black text-decoration-none d-flex" style={{ overflow: 'hidden', textOverflow: 'ellipsis' }} key={i} href={doc.documentLink}>
                              {doc.fileName}
                            </a>
                          ))}
                        </span>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col xs={12} md={6} className="mb-3">
                  <div className="details-block h-100">
                    <div className="block-header">
                      <span className="block-title-border" />
                      {(clientType === 1 && item.statusId === 5) ? <span className="block-title">Date client</span> :
                      <span className="block-title">POD: {item.pod}</span>}
                    </div>
                    <div className="block-body">
                      <div className="property-container">
                        <span className="property-key">Client</span>
                        <span className="property-value">
                          {item.firstName} {item.lastName} {item.companyName}
                        </span>
                      </div>
                      <div className="property-container">
                        <span className="property-key">Telefon</span>
                        <span className="property-value">{item.phone}</span>
                      </div>
                      <div className="property-container">
                        <span className="property-key">Adresă</span>
                        <span className="property-value">
                          {item.countyCode}, {localityName}, {item.street && `${item.street},`} {item.number && `${item.number}`}
                        </span>
                      </div>
                      <div className="property-container">
                        <span className="property-key">CNP/CUI</span>
                        <span className="property-value">{item.cnp || item.cui}</span>
                      </div>
                    </div>
                  </div>
                </Col>
              </>
            )}
          </Row>

          {!errorInvalidRequestStatus && (
            <>
              <Row className="mt-2">
                <Col xs={12} className="mb-4">
                  <div className="details-block h-100">
                    <div className="block-header">
                      <span className="block-title-border" />
                      <span className="block-title">Mesaj</span>
                    </div>
                    <div className="block-body">
                      <div className="property-container">
                        <span>{item.description}</span>
                      </div>
                    </div>
                  </div>
                </Col>

                {((clientType === 1 && item.statusId !== 5) || clientType === 2) && <Col xs={12} className="mb-3">
                  <div className="details-block h-100">
                    <div className="block-header">
                      <span className="block-title-border" />
                      <span className="block-title">Soluție / Răspuns</span>
                    </div>
                    <div className="block-body">
                      {item.responses.length > 0 ? (
                        displayResp(item.responses)
                      ) : (
                        <span className="property-value">Nu există nicio soluție.</span>
                      )}
                    </div>
                  </div>
                </Col>}
              </Row>
            </>
          )}
        </div>
      )}
      {error !== '' && <p className="fw-bold red text-center">{error}</p>}
      {errorInvalidRequestStatus !== '' && <p className="fw-bold red text-center">{errorInvalidRequestStatus}</p>}
    </Fragment>
  );
};

export default Details;