import { toggleLoader } from '@actions/LoaderActions';
import { reqLogin } from '@actions/user/user';
import { getCaptcha } from '@api/captcha';
import { login } from '@api/user/user';
import { Input } from '@components/common/Input';
import { faArrowRotateRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IState } from '@type/store';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { jwtDecode } from 'jwt-decode';
import { Fragment, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import { useDispatch, useSelector } from 'react-redux';
import { LoginSchema } from '../../helpers/schemas';
import {
  IDENTITY_ROLE_CLIENT_FIZIC,
  IDENTITY_ROLE_CLIENT_JURIDIC,
  IDENTITY_ROLE_OPERATOR_ECONOMIC,
  IDENTITY_ROLE_PARTENER_VALORI_MASURATE
} from '@constants/Roles/RolesIdentity';

const LoginPopupForm = () => {
  const [errorMessage, setErrorMessage] = useState('');
  const [failCount, setFailCount] = useState(0);
  const [wasCaptchaLoaded, setWasCaptchaLoaded] = useState(false);
  const [captchaError, setCaptchaError] = useState('');

  //captcha
  const [captchaImage, setCaptchaImage] = useState<string | null>(null);
  const [captcha, setCaptcha] = useState<string>('');
  const [instanceId, setInstanceId] = useState<string>('');

  const deviceId: string | undefined = useSelector<IState>((state) => state?.device?.deviceId) as string;
  const URL: string | undefined = useSelector<IState>((state) => state.options.webApiHost) as string;
  const redirect_url: string | undefined = useSelector<IState>((state) => state.options.oldSite) as string;
  const dispatch = useDispatch();

  useEffect(() => {
    if (failCount >= 3) {
      setWasCaptchaLoaded(true);
    }
  }, [failCount]);

  useEffect(() => {
    if (wasCaptchaLoaded) {
      apiGetCaptcha();
    }
  }, [wasCaptchaLoaded]);

  useEffect(() => {
    return () => {
      setErrorMessage('');
    };
  }, []);

  const apiGetCaptcha = async () => {
    dispatch(toggleLoader(true));

    await getCaptcha().then((response) => {
      setCaptchaImage(response.image);
      setInstanceId(response.imageInstanceId);
    });

    dispatch(toggleLoader(false));
  };

  const submit = async (values: any, validateCaptcha: boolean) => {
    if (validateCaptcha && !captcha) {
      setCaptchaError('Codul de securitate este obligatoriu.');
      return;
    }

    dispatch(toggleLoader(true));

    let data: any = {
      deviceId: deviceId,
      userEmail: values.email.trim(),
      password: values.password
    };

    if (validateCaptcha) {
      data = {
        ...data,
        captchaText: captcha,
        captchaInstance: instanceId
      };
    }

    await login(data)
      .then((response: any) => {
        if (response && !response.success) {
          if (response.errorCode === 9) {
            window.location.href = `${URL}/resetare-parola`;
          } else {
            setErrorMessage(getErrorMessage(response.errorCode));
            setFailCount((prev) => prev + 1);

            if (validateCaptcha) {
              apiGetCaptcha();
              setCaptcha('');
              if (response.errorCode == 12) {
                setCaptchaError('Codul de securitate nu este valid.');
                setErrorMessage('');
              } else {
                setCaptchaError('');
              }
            }
          }
        }

        if (
          response &&
          !response.changePasswordRequired &&
          !response.otpRequired &&
          response.success &&
          response.jwt &&
          response.jwt.access_token
        ) {
          dispatch(reqLogin(values.email, response.jwt.access_token));
          document.cookie = `token=${response.jwt.access_token};path=/`;
          // document.cookie = 'token=' + response.jwt.access_token;

          //redirect pe vechi doar pentru rol diferit de PARTENER_VALORI_MASURATE
          const token = response.jwt.access_token;
          const decoded = jwtDecode(token);
          //@ts-ignore
          const roles = decoded?.groups || [];
          if (typeof localStorage !== 'undefined') {
            if (localStorage.getItem('ReturnUrl')) {
              localStorage.setItem('ReturnUrlActive', 'true');
            }
          }
          if (
            roles.includes(IDENTITY_ROLE_PARTENER_VALORI_MASURATE) ||
            roles.includes(IDENTITY_ROLE_CLIENT_FIZIC) ||
            roles.includes(IDENTITY_ROLE_CLIENT_JURIDIC)
          ) {
            window.location.href = '/solicitari';
          } else if (roles.includes(IDENTITY_ROLE_OPERATOR_ECONOMIC)) {
            window.location.href = '/solicitari-operatori-economici';
          } else {
            window.location.href = `${redirect_url}/login?token=${response.jwt.access_token}`;
          }
        } else {
          if (response.success && response.changePasswordRequired) {
            window.location.href = `${URL}/login`;
          } else if (response.success && response.otpRequired) {
            window.location.href = `${URL}/login`;
          }
        }
      })
      .catch(function (error: any) {
        if (error.response) {
          console.log(error.response);
        } else if (error.request) {
          console.log(error.request);
        } else {
          setErrorMessage('A intervenit o eroare tehnică. Vă rugăm reveniți mai târziu.');
        }
      });

    dispatch(toggleLoader(false));
  };

  const getErrorMessage = (errorCode: number) => {
    switch (errorCode) {
      case 1:
        return 'E-mail greșit.';
      case 2:
        return 'Email și/sau parolă greșită.';
      case 5:
        return 'OTP-ul este invalid.';
      case 10:
        return 'Contul tău este blocat pentru 15 minute în urma a 5 tentative eșuate de logare în Portalul Delgaz. Te rugăm să accesezi linkul primit pe mail pentru deblocarea contului tău.';
      default:
        return 'A apărut o eroare. Vă rugăm încercați mai tărziu.';
    }
  };

  return (
    <div className="popup-form-container">
      <Formik
        initialValues={{ email: '', password: '' }}
        validationSchema={LoginSchema}
        validateOnChange={false}
        onSubmit={(values) => {
          submit(values, failCount >= 3);
        }}
      >
        {(props) => (
          <Fragment>
            <Form>
              <div className="form-container">
                <div className="mb-4">
                  <Field
                    label="E-mail"
                    id="email"
                    name="email"
                    placeholder="E-mail*"
                    render={() => (
                      <Input
                        label="E-mail*"
                        mbZero
                        onBlur={() => {
                          props.setFieldTouched('email');
                        }}
                        onChange={(e: any) => {
                          e.target.value.length !== 0 && props.setFieldValue('email', e.target.value);
                          e.target.value.length === 0 && props.setFieldValue('email', '');
                        }}
                      />
                    )}
                  />
                  <ErrorMessage name="email" render={(msg) => <div style={{ color: 'red', fontWeight: 'bold' }}>{msg}</div>} />
                </div>
                <div className="mb-2">
                  <Field
                    label="Parola"
                    id="password"
                    name="password"
                    placeholder="Parola*"
                    render={() => (
                      <Input
                        label="Parola*"
                        mbZero
                        type="password"
                        autoComplete="new-password"
                        onBlur={() => {
                          props.setFieldTouched('password');
                        }}
                        onChange={(e: any) => {
                          e.target.value.length !== 0 && props.setFieldValue('password', e.target.value);
                          e.target.value.length === 0 && props.setFieldValue('password', '');
                        }}
                      />
                    )}
                  />
                  <ErrorMessage name="password" render={(msg) => <div style={{ color: 'red', fontWeight: 'bold' }}>{msg}</div>} />
                  {errorMessage !== '' && <div style={{ color: 'red', fontWeight: 'bold' }}>{errorMessage}</div>}
                </div>

                {wasCaptchaLoaded && (
                  <>
                    <Row className="mt-0 mb-3">
                      <Col xs={12} className="flex-row">
                        {captchaImage !== null && (
                          <img src={`data:image/jpeg;base64, ${captchaImage}`} alt="captcha" className="captcha-img" />
                        )}
                        <FontAwesomeIcon
                          icon={faArrowRotateRight}
                          color="red"
                          className="mt-3 pointer"
                          style={{ width: '20px', height: '20px', marginLeft: '8px' }}
                          onClick={() => apiGetCaptcha()}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12}>
                        <Input
                          id="user_captcha_input"
                          name="user_captcha_input"
                          type="text"
                          label="Cod securitate"
                          value={captcha}
                          onChange={(e) => setCaptcha(e.target.value)}
                          mbZero
                          className="mb-3"
                        />
                      </Col>
                      {captchaError !== '' && <div style={{ color: 'red', fontWeight: 'bold' }}>{captchaError}</div>}
                    </Row>
                  </>
                )}
                <div className="mt-2 buttons-container d-flex justify-content-end">
                  <Button className="mb-2 btn-large w-100" type="submit" onClick={() => setErrorMessage('')}>
                    Login
                  </Button>
                </div>
                <div className="buttons-container d-flex justify-content-center">
                  <a className="forgot-password-text" href={`${URL}/resetare-parola`}>
                    Ai uitat parola?
                  </a>
                </div>
              </div>
            </Form>
          </Fragment>
        )}
      </Formik>
    </div>
  );
};

export default LoginPopupForm;
