import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IState } from '@type/store';
import moment from 'moment';
import { Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';

const Notification = (props: {
  index: number;
  notification: any;
  selected: any;
  selectNotification: any;
  deleteNotification: any;
  getContent: any;
  handleIsCollapsed: any;
}) => {
  //eslint-disable-next-line
  const { index, notification, selected, selectNotification, deleteNotification, getContent, handleIsCollapsed } = props;

  //eslint-disable-next-line
  const OLD_SITE_URL: string | undefined = useSelector<IState>((state) => state.options.webApiHost) as string;

  return (
    <div
      className={`delgaz-ro-collapsible-notification ${notification.isCollapsed === true ? '' : 'active'}`}
      style={{ margin: '0', maxWidth: '100%' }}
    >
      <div 
        className={`delgaz-ro-collapsible-title d-flex align-items-center p-2 ${(notification.markedAsRead === true || notification.wasOpened) ? 'read' : 'not-read'}`}
        onClick={() => {
          if (notification.isCollapsed === true) {
            getContent(notification.id);
          }
  
          handleIsCollapsed(notification.id, !notification.isCollapsed);
        }}
      >
        <div className="d-flex align-items-center w-100">
          <div className="n-item-checkbox checkbox-container p-2" onClick={(e) => e.stopPropagation()}>
            <input
              className="create-account-checkbox"
              type="checkbox"
              checked={selected.includes(notification.id)}
              onChange={() => selectNotification(notification.id)}
            />
          </div>
          <span style={(notification.markedAsRead === true || notification.wasOpened) ? { fontWeight: 'normal' } : { fontWeight: 'bold' }}>{notification.title}</span>
        </div>
        <div
          className="notification-date d-flex"
          style={{ marginRight: '12px', fontWeight: 'normal', color: 'gray', fontSize: '16px', width: '175px' }}
        >
          {moment.utc(notification.entryDate).local().format('DD-MM-YYYY HH:mm')}
        </div>
      </div>
      <div className="delgaz-ro-collapsible-content" style={notification.isCollapsed ? { maxHeight: '0px' } : { maxHeight: 'unset' }}>
        <div className="p-4 d-flex flex-column">
          <div dangerouslySetInnerHTML={{ __html: notification.content }} />
          <div className="notification-actions mt-4">
            <Button
              onClick={(e) => {
                e.stopPropagation();
                deleteNotification(notification.id);
              }}
            >
              <FontAwesomeIcon icon={faTrash} className="n-item-delete" style={{ marginRight: '8px' }} />
              <span>Șterge mesajul</span>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Notification;