import React from 'react';
import { Modal, Button, Col, Row } from 'react-bootstrap';

const ConfirmModal = (props: { show: boolean, handleShow: any, handleRSSModalShow: any, handleModalType: any }) => {
    return (
        <Modal show={props.show} centered className="rss-confirm-modal" onHide={() => props.handleShow(false)}>
            <Modal.Header closeButton>
                <div className="d-flex">
                    <h5>Locul de consum nu este în portofoliul dvs. Continuați cu crearea unui RSS?</h5>
                </div>
            </Modal.Header>
            <Modal.Footer className="justify-content-center">
                <Row className="w-100">
                    <Col xs={6}>
                        <div className="d-flex justify-content-center">
                            <Button style={{width: '75px', height: '44px'}} onClick={() => {
                                props.handleShow(false);
                                props.handleModalType(2);
                                props.handleRSSModalShow(true);
                            }}>Da</Button>
                        </div>

                    </Col>
                    <Col xs={6}>
                        <div className="d-flex justify-content-center">
                            <Button style={{width: '75px', height: '44px'}} onClick={() => props.handleShow(false)}>Nu</Button>
                        </div>
                    </Col>
                </Row>


            </Modal.Footer>
        </Modal>
    );
};

export default ConfirmModal;