export const getCountyLabel = (countyCode: string, fill: string) => {
  return (
    <text
      style={{ pointerEvents: 'none', fontSize: `${countyCode == 'B' ? '12' : ''}` }}
      x={COUNTY_CODE_PATHS.find((ccp) => ccp.countyCode == countyCode)?.x}
      y={COUNTY_CODE_PATHS.find((ccp) => ccp.countyCode == countyCode)?.y}
      fill={fill}
    >
      {countyCode}
    </text>
  );
};

export const COUNTY_CODE_PATHS = [
  {
    countyCode: 'AB',
    x: '204',
    y: '208'
  },
  {
    countyCode: 'AG',
    x: '286',
    y: '310'
  },
  {
    countyCode: 'AR',
    x: '86',
    y: '194'
  },
  {
    countyCode: 'BC',
    x: '415',
    y: '185'
  },
  {
    countyCode: 'BH',
    x: '115',
    y: '125'
  },
  {
    countyCode: 'BN',
    x: '265',
    y: '102'
  },
  {
    countyCode: 'BR',
    x: '470',
    y: '306'
  },
  {
    countyCode: 'BT',
    x: '415',
    y: '45'
  },
  {
    countyCode: 'B',
    x: '375',
    y: '364'
  },
  {
    countyCode: 'BV',
    x: '315',
    y: '242'
  },
  {
    countyCode: 'BZ',
    x: '412',
    y: '292'
  },
  {
    countyCode: 'CJ',
    x: '210',
    y: '148'
  },
  {
    countyCode: 'CL',
    x: '430',
    y: '374'
  },
  {
    countyCode: 'CS',
    x: '105',
    y: '302'
  },
  {
    countyCode: 'CT',
    x: '512',
    y: '379'
  },
  {
    countyCode: 'CV',
    x: '362',
    y: '228'
  },
  {
    countyCode: 'DB',
    x: '326',
    y: '322'
  },
  {
    countyCode: 'DJ',
    x: '206',
    y: '386'
  },
  {
    countyCode: 'GJ',
    x: '189',
    y: '315'
  },
  {
    countyCode: 'GL',
    x: '480',
    y: '243'
  },
  {
    countyCode: 'GR',
    x: '359',
    y: '392'
  },
  {
    countyCode: 'HD',
    x: '163',
    y: '245'
  },
  {
    countyCode: 'HR',
    x: '334',
    y: '173'
  },
  {
    countyCode: 'IF',
    x: '378',
    y: '346'
  },
  {
    countyCode: 'IL',
    x: '442',
    y: '348'
  },
  {
    countyCode: 'IS',
    x: '445',
    y: '100'
  },
  {
    countyCode: 'MH',
    x: '165',
    y: '353'
  },
  {
    countyCode: 'MM',
    x: '230',
    y: '60'
  },
  {
    countyCode: 'MS',
    x: '271',
    y: '168'
  },
  {
    countyCode: 'NT',
    x: '379',
    y: '130'
  },
  {
    countyCode: 'OT',
    x: '264',
    y: '380'
  },
  {
    countyCode: 'PH',
    x: '362',
    y: '302'
  },
  {
    countyCode: 'SB',
    x: '248',
    y: '236'
  },
  {
    countyCode: 'SJ',
    x: '180',
    y: '108'
  },
  {
    countyCode: 'SM',
    x: '155',
    y: '62'
  },
  {
    countyCode: 'SV',
    x: '342',
    y: '70'
  },
  {
    countyCode: 'TL',
    x: '551',
    y: '301'
  },
  {
    countyCode: 'TM',
    x: '61',
    y: '245'
  },
  {
    countyCode: 'TR',
    x: '310',
    y: '397'
  },
  {
    countyCode: 'VL',
    x: '242',
    y: '306'
  },
  {
    countyCode: 'VN',
    x: '420',
    y: '241'
  },
  {
    countyCode: 'VS',
    x: '479',
    y: '172'
  }
];
