import React from 'react';
import { DelgazWrapper } from '@components/global/DelgazWrapper';
import IstoricIncarcareInMasa from '@components/revizii/IstoricIncarcareInMasa/IstoricIncarcareInMasa';

export const IstoricIncarcareInMasaComponent = () => {
  return (
    <DelgazWrapper>
      <IstoricIncarcareInMasa />
    </DelgazWrapper>
  );
};
