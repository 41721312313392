export const REQUEST_LIST = 'https://msapi.delgaz.ro/rss/Requests/list';
export const REQUEST_DETAILS = 'https://msapi.delgaz.ro/rss/Requests/details';
export const UPDATE_REQUEST = 'https://msapi.delgaz.ro/rss/Requests/update';
export const SEND_REQUEST = 'https://msapi.delgaz.ro/rss/Requests/send';
export const VERIFY_CONSUMPTION_POINT = 'https://msapi.delgaz.ro/rss/Requests/verify_consumption_point';
export const SUBJECT_LIST = 'https://msapi.delgaz.ro/rss/CategoriesAndSubjects/list';
export const CREATE_RSS = 'https://msapi.delgaz.ro/rss/Requests/Create';
export const EMAIL_LINK = 'https://msapi.delgaz.ro/rss/Requests/Identify';
export const SUBJECT_LIST_FURNIZOR = 'https://msapi.delgaz.ro/rss/CategoriesAndSubjects/list_for_supplier';
export const VERIFY_RSS_BY_EMAIL = 'https://msapi.delgaz.ro/rss/Requests/verify_status';
export const VERIFY_RSS_BY_POD = 'https://msapi.delgaz.ro/rss/Requests/verify_status';
export const GET_CP = 'https://msapi.delgaz.ro/rss/BackOfficeRequests/get_consumption_points';