import React from 'react';
import { DelgazWrapper } from '@components/global/DelgazWrapper';
import RichTextComponent from '@components/dev/Components/RichText/RichTextComponent';
import {RichTextKentico} from '@type/kentico-types';

export type RichTextProps = { kentico: RichTextKentico };

export const RichText = (props: RichTextProps): JSX.Element => {
    return (
        <DelgazWrapper>
            <RichTextComponent {...props}/>
        </DelgazWrapper>
    );
};