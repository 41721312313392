import React, { Fragment, useState, useEffect, useRef } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { Form, Formik, Field, ErrorMessage } from 'formik';
import { PVAEAgreementSchema } from '../../../helpers/schemas';
import { Input } from '@components/common/Input';
import { Signature } from '@components/common/Signature';
import { CheckBox } from '@components/common/CheckBox';
import ModalConfirm from '@components/common/ModalConfirm/ModalConfirm';
import ReactDatePicker from 'react-datepicker';
import MaskedInput from '@components/common/Input/MaskedInput';
import { toggleLoader } from '@actions/LoaderActions';
import { getCaptcha } from '@api/captcha';
import { useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRotateRight } from '@fortawesome/free-solid-svg-icons';
import { pvaeSaveAgreement, pvaeSendSmsValidationCode } from '@api/pvae';
import * as notification from '@lib/Notification';
import moment from 'moment';
import { GDPR_MESSAGE } from '@constants/GDPR';
import { UploadFile } from '@components/common/UploadFile';
import { File } from '@components/common/UploadFile/UploadFile';

const AgreementPVAE = (props: any) => {
  const [agreementPVAEAgree, setAgreementPVAEAgree] = useState(false);
  const [agreementPVAEDisagree, setAgreementPVAEDisagree] = useState(false);
  const [agreementGDPR, setAgreementGDPR] = useState(false);
  const [date, setDate] = useState<any>(null);
  const [measurementPointCode, setMeasurementPointCode] = useState<any>(null);

  //saved pvae id
  const [pvaeId, setPvaeId] = useState('');

  //phone validate
  const [phoneNumber, setPhoneNumber] = useState('');
  const [smsCode, setSmsCode] = useState('');
  const [phoneNumberSent, setPhoneNumberSent] = useState(false);
  const [fieldPhoneNumberTouched, setFieldPhoneNumberTouched] = useState(false);

  //signature
  const [isSignatureEmpty, setIsSignatureEmpty] = useState<any>(false);
  const [isUploadSignature, setIsUploadSignature] = useState<any>(true);
  const [isUploadDocument, setIsUploadDocument] = useState<any>(false);
  const signatureComponentRef = useRef(null);

  //signature file upload
  const [fileList, setFileList] = useState<File[]>([] as File[]);
  const [isSignatureUploadFileEmpty, setIsSignatureUploadFileEmpty] = useState<any>(false);
  const [fieldSignatureUploadFileTouched, setFieldSignatureUploadFileTouched] = useState(false);

  //final modal
  const [showModalSuccesSendFormAgree, setShowModalSuccesSendFormAgree] = useState(false);
  const [showModalSuccesSendFormDisagree, setShowModalSuccesSendFormDisagree] = useState(false);

  //captcha
  const [captchaImage, setCaptchaImage] = useState<string | null>(null);
  const [captcha, setCaptcha] = useState<string>('');
  const [instanceId, setInstanceId] = useState<string>('');

  //error
  const [smsCodeError, setSmsCodeError] = useState('');
  const [phoneNumberError, setPhoneNumberError] = useState('');

  const dispatch = useDispatch();

  useEffect(() => {
    apiGetCaptcha();
  }, []);

  useEffect(() => {
    if (fieldSignatureUploadFileTouched) {
      if (fileList.length > 0) {
        setIsSignatureUploadFileEmpty(false);
      } else {
        setIsSignatureUploadFileEmpty(true);
      }
    }
  }, [fileList, fieldSignatureUploadFileTouched]);

  useEffect(() => {
    if (fieldPhoneNumberTouched) {
      if (!phoneNumber) {
        setPhoneNumberError('Numărul de telefon este obligatoriu.');
        return;
      } else if (!phoneNumber.match(/0\d{9}/) || phoneNumber.length != 10) {
        setPhoneNumberError('Numărul de telefon are formatul gresit.');
        return;
      } else {
        setPhoneNumberError('');
      }
    }
  }, [phoneNumber, fieldPhoneNumberTouched]);

  const apiGetCaptcha = async () => {
    dispatch(toggleLoader(true));

    await getCaptcha().then((response) => {
      setCaptchaImage(response.image);
      setInstanceId(response.imageInstanceId);
    });

    dispatch(toggleLoader(false));
  };

  const submit = async (values: any, { setSubmitting, setErrors }: any) => {
    //validate phone number
    if (!phoneNumber) {
      setPhoneNumberError('Numărul de telefon este obligatoriu.');
      return;
    } else if (!phoneNumber.match(/0\d{9}/) || phoneNumber.length != 10) {
      setPhoneNumberError('Numărul de telefon are formatul gresit.');
      return;
    }
    if (agreementPVAEAgree) {
      //AGREE
      let formData = new FormData();
      formData.append('PVAENumber', values.numberPVAE);
      formData.append('PVAEDate', values.date);
      formData.append('CNP', values.CNP);
      formData.append('POD', values.measurementPointCode);
      formData.append('PVAEAgreement', agreementPVAEAgree ? 'true' : 'false');
      formData.append('GDPRAgreement', agreementGDPR ? 'true' : 'false');
      formData.append('UserPhoneNumber', phoneNumber);
      formData.append('CaptchaText', captcha);
      formData.append('CaptchaInstance', instanceId);

      if (isUploadSignature) {
        //@ts-ignore
        let signature = signatureComponentRef.current.handleSave();
        if (signature) {
          signature = signature.slice(signature.lastIndexOf('base64,') + 7);
          formData.append('UserSignature', signature);
        } else {
          return;
        }
      } else {
        //upload as file
        if (fileList.length > 0) {
          fileList.map((file) => {
            formData.append('PVAEDocuments', file as Blob);
          });
        } else {
          setFieldSignatureUploadFileTouched(true);
          setIsSignatureUploadFileEmpty(true);
          return;
        }
      }

      dispatch(toggleLoader(true));
      await pvaeSaveAgreement(formData)
        .then((res) => {
          setPhoneNumberSent(true);
          setPvaeId(res.data);
          dispatch(toggleLoader(false));
        })
        .catch((err) => {
          apiGetCaptcha();
          setCaptcha('');
          if (err.response.status == 499) {
            notification.error('Codul captcha nu este valid.');
          } else if (err.response.status == 498) {
            notification.error('Locul de consum nu este pe electricitate.');
          } else if (err.response.status == 497) {
            notification.error('Locul de consum nu există.');
          } else notification.error('A apărut o eroare tehnică. Vă rugam reveniți mai târziu.');
          dispatch(toggleLoader(false));
        });
    } else {
      //DISAGREE
      dispatch(toggleLoader(true));
      let formData = new FormData();
      formData.append('PVAENumber', values.numberPVAE);
      formData.append('PVAEDate', values.date);
      formData.append('CNP', values.CNP);
      formData.append('POD', values.measurementPointCode);
      formData.append('PVAEAgreement', agreementPVAEAgree ? 'true' : 'false');
      formData.append('GDPRAgreement', agreementGDPR ? 'true' : 'false');
      formData.append('UserPhoneNumber', phoneNumber);
      formData.append('CaptchaText', captcha);
      formData.append('CaptchaInstance', instanceId);

      await pvaeSaveAgreement(formData)
        .then((res) => {
          if (res.data) {
            setPhoneNumberSent(true);
            setPvaeId(res.data);
          } else {
            notification.error('A apărut o eroare tehnică. Vă rugam reveniți mai târziu.');
          }
          dispatch(toggleLoader(false));
        })
        .catch((err) => {
          apiGetCaptcha();
          setCaptcha('');
          if (err.response.status == 499) {
            notification.error('Codul captcha nu este valid.');
          } else if (err.response.status == 498) {
            notification.error('Locul de consum nu este pe electricitate.');
          } else if (err.response.status == 497) {
            notification.error('Locul de consum nu există.');
          } else notification.error('A apărut o eroare tehnică. Vă rugam reveniți mai târziu.');
          dispatch(toggleLoader(false));
        });
    }
  };

  const sendSmsCode = () => {
    dispatch(toggleLoader(true));

    let payload: any = { id: pvaeId, smsCode: smsCode };
    pvaeSendSmsValidationCode(payload)
      .then((res) => {
        if (res) {
          setSmsCodeError('');
          if (agreementPVAEAgree) {
            setShowModalSuccesSendFormAgree(true);
          } else {
            setShowModalSuccesSendFormDisagree(true);
          }
        } else {
          notification.error('A apărut o eroare tehnică. Vă rugam reveniți mai târziu.');
        }
        dispatch(toggleLoader(false));
      })
      .catch((err) => {
        try {
          if (err.status == 200 && err.data == false) {
            notification.error('Codul introdus este greșit.');
            setSmsCodeError('Codul introdus este greșit.');
          } else {
            notification.error('A apărut o eroare tehnică. Vă rugam reveniți mai târziu.');
          }
        } catch (err) {
          notification.error('A apărut o eroare tehnică. Vă rugam reveniți mai târziu.');
        }
        dispatch(toggleLoader(false));
      });
  };

  const handleFileListChange = (list: File[]) => {
    setFileList(list);
  };

  const validatePhoneNumberForm = () => {
    return (
      <Fragment>
        <div className="form-container">
          <div className="d-flex flex-column">
            {!phoneNumberSent ? (
              <>
                <h5 className="red">
                  Pentru a confirma corectitudinea informațiilor completate în formular vei primi un cod prin SMS la numărul de telefon:
                </h5>
                <Row className="mt-3">
                  <Col xs={12} md={6}>
                    <Input
                      label="Număr telefon*"
                      mbZero
                      onBlur={() => {
                        setFieldPhoneNumberTouched(true);
                      }}
                      value={phoneNumber}
                      onChange={(e: any) => {
                        setPhoneNumber(e.target.value);
                      }}
                    />
                    <div style={{ color: '#ea1c0a', fontWeight: 'bold' }}>{phoneNumberError}</div>
                  </Col>
                </Row>
              </>
            ) : (
              <>
                <h5 className="red">Introduceți codul primit prin SMS</h5>

                <Row className="w-100 mt-3">
                  <Col xs={12} md={6}>
                    <Input
                      label="Cod primit prin SMS*"
                      mbZero
                      onBlur={() => {
                        props.setFieldTouched('smsCode');
                      }}
                      value={smsCode}
                      onChange={(e: any) => {
                        setSmsCode(e.target.value);
                        e.target.value.length !== 0 && props.setFieldValue('smsCode', e.target.value);
                        e.target.value.length === 0 && props.setFieldValue('smsCode', '');
                      }}
                      onKeyDown={(event) => {
                        if (event.key === 'Enter') {
                          event.preventDefault(); // Prevent form submission
                        }
                      }}
                    />
                    <div style={{ color: '#ea1c0a', fontWeight: 'bold' }}>{smsCodeError}</div>
                  </Col>
                </Row>
              </>
            )}
          </div>
        </div>
      </Fragment>
    );
  };

  return (
    <Fragment>
      <Formik
        initialValues={{
          numberPVAE: '',
          date: '',
          CNP: '',
          measurementPointCode: '',
          agreementPVAE: ''
        }}
        onSubmit={submit}
        validationSchema={PVAEAgreementSchema}
      >
        {(props) => (
          <Fragment>
            <Form>
              <div className="form-container">
                {phoneNumberSent ? (
                  <>{validatePhoneNumberForm()}</>
                ) : (
                  <>
                    <div className="mt-4 mb-4 d-flex flex-column">
                      <h5 className="red">Identificare document</h5>

                      <Row className="w-100">
                        <Col xs={12} md={6} className="mb-2 mt-3">
                          <Field
                            label="Număr PVAE*"
                            id="numberPVAE"
                            name="numberPVAE"
                            placeholder="Număr PVAE*"
                            render={() => (
                              <Input
                                label="Număr PVAE*"
                                mbZero
                                onBlur={() => {
                                  props.setFieldTouched('numberPVAE');
                                }}
                                onChange={(e: any) => {
                                  e.target.value.length !== 0 && props.setFieldValue('numberPVAE', e.target.value);
                                  e.target.value.length === 0 && props.setFieldValue('numberPVAE', '');
                                }}
                              />
                            )}
                          />
                          <ErrorMessage
                            name="numberPVAE"
                            render={(msg) => <div style={{ color: '#ea1c0a', fontWeight: 'bold' }}>{msg}</div>}
                          />
                        </Col>
                        <Col xs={12} md={6} className="mb-2 mt-3">
                          <Field
                            id="date"
                            name="date"
                            placeholder="Data*"
                            render={() => (
                              <div style={{ marginTop: '-17px' }}>
                                <label className="calendar_label">
                                  <div>Data*</div>
                                </label>
                                <ReactDatePicker
                                  selected={date ? moment(date).toDate() : null}
                                  dateFormat="dd.MM.yyyy"
                                  placeholderText={'Data*'}
                                  popperPlacement="bottom-end"
                                  onChange={(d) => {
                                    let selectedDate = moment(d);
                                    setDate(selectedDate.format('YYYY-MM-DD'));
                                    d && props.setFieldValue('date', selectedDate.format('YYYY-MM-DD'));
                                  }}
                                />
                              </div>
                            )}
                          />
                          <ErrorMessage name="date" render={(msg) => <div style={{ color: '#ea1c0a', fontWeight: 'bold' }}>{msg}</div>} />
                        </Col>
                      </Row>

                      <h5 className="red">Identificare consumator</h5>

                      <Row className="w-100">
                        <Col xs={12} md={6} className="mb-2 mt-3">
                          <Field
                            label="Cod Numeric Personal (CNP)*"
                            id="CNP"
                            name="CNP"
                            placeholder="Cod Numeric Personal (CNP)*"
                            render={() => (
                              <Input
                                label="Cod Numeric Personal (CNP)*"
                                mbZero
                                onBlur={() => {
                                  props.setFieldTouched('CNP');
                                }}
                                onChange={(e: any) => {
                                  e.target.value.length !== 0 && props.setFieldValue('CNP', e.target.value);
                                  e.target.value.length === 0 && props.setFieldValue('CNP', '');
                                }}
                              />
                            )}
                          />
                          <ErrorMessage name="CNP" render={(msg) => <div style={{ color: '#ea1c0a', fontWeight: 'bold' }}>{msg}</div>} />
                        </Col>
                        <Col xs={12} md={6} className="mb-2 mt-3">
                          <Field
                            id="measurementPointCode"
                            name="measurementPointCode"
                            placeholder="Cod de identificare punct de măsurare (POD)*"
                            render={() => (
                              <MaskedInput
                                value={measurementPointCode}
                                mbZero
                                onChange={(e: any) => {
                                  const currentValue = (e.target.value ? e.target.value.toLocaleUpperCase() : '') as string;
                                  e.target.value.length !== 0 && props.setFieldValue('measurementPointCode', currentValue);
                                  e.target.value.length === 0 && props.setFieldValue('measurementPointCode', '');
                                  setMeasurementPointCode(currentValue);
                                }}
                                onBlur={() => {
                                  props.setFieldTouched('measurementPointCode');
                                }}
                                utility="EE"
                                label="Cod de identificare punct de măsurare (POD)*"
                                mask={'0123333333'}
                              />
                            )}
                          />
                          <ErrorMessage
                            name="measurementPointCode"
                            render={(msg) => <div style={{ color: '#ea1c0a', fontWeight: 'bold' }}>{msg}</div>}
                          />
                        </Col>
                      </Row>

                      <h5 className="red">Decizie PVAE</h5>

                      <Row className="w-100 mb-2 mt-3">
                        <div className="mb-3">
                          <div className={'d-flex flex-column'}>
                            <span
                              style={{ marginRight: '25px', marginBottom: '8px', cursor: 'pointer' }}
                              className={`eon-radio-label bg-eon-red ${agreementPVAEAgree && 'checked'}`}
                              onClick={() => {
                                setAgreementPVAEAgree(true);
                                setAgreementPVAEDisagree(false);
                              }}
                            >
                              <input type="radio" className="eon-radio" name="optionGroup" style={{ marginRight: '4px' }} />
                              <span style={{ fontSize: '18px' }}>Sunt de acord cu cantitatea recalculată și facturarea acesteia</span>
                            </span>
                          </div>
                        </div>
                        {agreementPVAEAgree && (
                          <Row>
                            <Col xs={12} md={4}>
                              <div className="p-3">
                                <div className={'d-flex flex-column'}>
                                  <span
                                    style={{ marginRight: '25px', marginBottom: '8px', cursor: 'pointer' }}
                                    className={`eon-radio-label bg-eon-red ${isUploadSignature && 'checked'}`}
                                    onClick={() => {
                                      setIsUploadDocument(false);
                                      setIsUploadSignature(true);
                                    }}
                                  >
                                    <input type="radio" className="eon-radio" name="optionGroup" style={{ marginRight: '4px' }} />
                                    <span style={{ fontSize: '18px' }}>Semnătura</span>
                                  </span>
                                </div>
                                <div className={'d-flex flex-column'}>
                                  <span
                                    style={{ marginRight: '25px', marginBottom: '8px', cursor: 'pointer' }}
                                    className={`eon-radio-label bg-eon-red ${isUploadDocument && 'checked'}`}
                                    onClick={() => {
                                      setIsUploadDocument(true);
                                      setIsUploadSignature(false);
                                    }}
                                  >
                                    <input type="radio" className="eon-radio" name="optionGroup" style={{ marginRight: '4px' }} />
                                    <span style={{ fontSize: '18px' }}>Încarcă documentul PVAE semnat (poză sau document scanat)</span>
                                  </span>
                                </div>
                              </div>
                            </Col>

                            {isUploadSignature && (
                              <Col xs={12} md={8}>
                                <p className="m-0">Vă rugăm să semnați în chenarul de mai jos, folosind click-ul de la mouse</p>
                                <Signature
                                  ref={signatureComponentRef}
                                  height={100}
                                  width={400}
                                  setEmpty={(empty) => {
                                    setIsSignatureEmpty(empty);
                                  }}
                                />
                                <div style={{ color: '#ea1c0a', fontWeight: 'bold' }}>
                                  {isSignatureEmpty && 'Semnătura este obligatorie.'}
                                </div>
                              </Col>
                            )}
                            {isUploadDocument && (
                              <Col xs={12} md={6} className="mb-2">
                                <p className="m-0">Document</p>
                                <UploadFile
                                  onSetFileList={handleFileListChange}
                                  fileList={fileList}
                                  index={0}
                                  uploadedFilesLimit={3}
                                  acceptedExtensions={['.jpg', '.jpeg', '.png', '.pdf']}
                                />

                                <div style={{ color: '#ea1c0a', fontWeight: 'bold' }}>
                                  {isSignatureUploadFileEmpty && 'Documentul este obligatoriu.'}
                                </div>
                              </Col>
                            )}
                          </Row>
                        )}

                        <div className="mt-3 mb-3">
                          <div className={'d-flex flex-column'}>
                            <span
                              style={{ marginRight: '25px', marginBottom: '8px', cursor: 'pointer' }}
                              className={`eon-radio-label bg-eon-red ${agreementPVAEDisagree && 'checked'}`}
                              onClick={() => {
                                setAgreementPVAEAgree(false);
                                setAgreementPVAEDisagree(true);
                              }}
                            >
                              <input type="radio" className="eon-radio" name="optionGroup" style={{ marginRight: '4px' }} />
                              <span style={{ fontSize: '18px' }}>
                                Am luat la cunoștință, dar nu sunt de acord cu modalitatea de calcul / cantitatea recalculată
                              </span>
                            </span>
                          </div>
                        </div>
                        <ErrorMessage
                          name="agreementPVAE"
                          render={(msg) => <div style={{ color: '#ea1c0a', fontWeight: 'bold' }}>{msg}</div>}
                        />
                      </Row>

                      <h5 className="red">Acord de prelucrare a datelor</h5>
                      <Row className="w-100 mt-3">
                        <CheckBox
                          checked={agreementGDPR}
                          subtitle={GDPR_MESSAGE}
                          onClick={() => {
                            setAgreementGDPR(!agreementGDPR);
                          }}
                        />
                      </Row>

                      <Row className="mt-3 mb-3">
                        <Col xs={12} sm={10} className="flex-row">
                          {captchaImage !== null && <img src={`data:image/jpeg;base64, ${captchaImage}`} alt="captcha" />}
                          <FontAwesomeIcon
                            icon={faArrowRotateRight}
                            color="red"
                            className="mt-3 pointer"
                            style={{ width: '20px', height: '20px', marginLeft: '8px' }}
                            onClick={() => apiGetCaptcha()}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12} sm={4}>
                          <Input label="Introdu codul de securitate" value={captcha} onChange={(e) => setCaptcha(e.target.value)} />
                        </Col>
                      </Row>

                      {validatePhoneNumberForm()}
                    </div>
                  </>
                )}

                {
                  <Row className="w-100 mt-1">
                    <Col md={6}>
                      {phoneNumberSent ? (
                        <Button type="button" onClick={() => sendSmsCode()}>
                          Trimite
                        </Button>
                      ) : (
                        <Button
                          disabled={!agreementGDPR || !captcha || (!agreementPVAEAgree && !agreementPVAEDisagree)}
                          type="submit"
                          onKeyDown={(event) => event.preventDefault()}
                        >
                          Salvează
                        </Button>
                      )}
                    </Col>
                  </Row>
                }
              </div>
            </Form>
          </Fragment>
        )}
      </Formik>

      <>
        <ModalConfirm
          modalTitle={'Succes!'}
          showModal={showModalSuccesSendFormAgree}
          onClose={() => {
            setShowModalSuccesSendFormAgree(false);
            window.location.href = '/serviciul-de-informare/intrebari-frecvente';
          }}
        >
          <h5>Am înregistrat acordul tău și vom începe procesarea informațiilor în scopul emiterii facturii aferente procesului verbal.</h5>
        </ModalConfirm>

        <ModalConfirm
          modalTitle={'Succes!'}
          showModal={showModalSuccesSendFormDisagree}
          onClose={() => {
            setShowModalSuccesSendFormDisagree(false);
            window.location.href = '/serviciul-de-informare/intrebari-frecvente';
          }}
        >
          <h5>
            Informațiile au fost trimise cu succes. Aveți posibilitatea să înregistrați o sesizare, accesând link-ul{' '}
            <a href="https://portal.delgaz.ro/despre-noi/contact">https://portal.delgaz.ro/despre-noi/contact</a>
          </h5>
        </ModalConfirm>
      </>
    </Fragment>
  );
};

export default AgreementPVAE;
