import { faCircleInfo, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tippy from '@tippyjs/react';
import { useEffect, useState } from 'react';
import EditableRow from './EditableRow';
import TotalColRow from './TotalColRow';

type HCTotal = {
  col: number;
  total: number;
};

const DynamicTable = (props: {
  format?: any;
  handler: any;
  data: any;
  isEditing?: any;
  id?: string;
  scroll?: boolean;
  emptyTable?: boolean;
  minimumNrOfRows?: number;
  mbZero?: boolean;
  style?: any;
}) => {
  const { format, data, handler, isEditing, emptyTable, minimumNrOfRows, mbZero } = props;

  const [rows, setRows] = useState<any[]>(data);
  const [rowFormat, setRowFormat] = useState({});
  const [calcTotal, setCalcTotal] = useState(0);
  const [handledColsTotal, setHandledColsTotal] = useState<HCTotal[]>([]);

  useEffect(() => {
    data.length > 0 && setRows(data);
  }, [data]);

  const calculateTotal = () => {
    let total = 0;

    rows.map((row: any, index: number) =>
      Object.keys(row).map((key: any, idx: number) => {
        if (format.total.includes(idx) && row[key] >= '0' && row[key] <= '9') {
          total = total + Number(row[key]);
        }
      })
    );

    setCalcTotal(total);
  };

  useEffect(() => {
    format.total && calculateTotal();
  }, [rows]);

  const emptyRow = () => {
    let row = '';

    format.columns.map((column: any, idx: number) => {
      column = column.name.replace(' ', '_');
      //eslint-disable-next-line
      row = row + `"${column}"` + ':' + 'null' + ',';
    });

    row = row.slice(0, -1);
    row = '{' + row + '}';

    row = JSON.parse(row);
    Object.keys(row).map((key: any, idx) => {
      // @ts-ignore
      row[key] = '';
    });

    return row;
  };

  const addEmptyRow = () => {
    const row = emptyRow();

    setRowFormat(row);
    setRows((prev) => [...prev, row]);
  };

  useEffect(() => {
    if (data.length === 0 && !emptyTable) {
      addEmptyRow();
    }
  }, []);

  const addRowAtIndex = (index: number) => {
    const row = emptyRow();
    const newRows = rows;

    newRows.splice(index + 1, 0, row);
    setRows([...newRows]);
  };

  const removeRowAtIndex = (index: number) => {
    const newRows = rows;

    newRows.splice(index, 1);
    setRows([...newRows]);
  };

  const editCell = (row: any, key: any, value: any) => {
    let newRows = rows;

    newRows.map((r, index) =>
      Object.keys(r).map((k: any, idx: number) => {
        if (r === row && k === key) {
          if (format.columns[idx].dataType === 'numeric') {
            for (let i = 0; i < value.length; i++) {
              if (value[i] === ',') {
                value = value.substring(0, i) + '.' + value.substring(i + 1);
              }
            }
          }
          r[k] = value;

          if (format.totalCol && format.totalCol.includes(idx) && format.handledCols.includes(idx)) {
            let newTotal = handledColsTotal;
            newTotal.map((el: HCTotal, i) => {
              if (el.col === idx) {
                newTotal[i].total = value;
              }
            });

            setHandledColsTotal(newTotal);
          }
        }
      })
    );

    setRows([...newRows]);
  };

  useEffect(() => {
    if (format.handledCols) {
      let array: any = [];

      format.handledCols.map((hc: any, index: any) => {
        let el = {
          col: hc,
          total: 0
        };

        array.push(el);
      });

      setHandledColsTotal(array);
    }
  }, []);

  useEffect(() => {
    handler(rows);
  }, [rows]);

  return (
    <div className={props.scroll ? 'overflow-scroll' : ''}>
      <div className={`d-flex flex-column overflow-auto ${mbZero ? 'mb-0' : 'mb-4'}`} style={{ ...props.style }}>
        <form className="m-0">
          <table className="dynamic-table" id={props.id}>
            <thead>
              <tr>
                {format.columns.map((col: any, index: number) => (
                  <th style={{ border: '1px solid red' }} className="p-2" key={index}>
                    <span>
                      {col.colName}
                      {col.info && (
                        <Tippy
                          content={
                            <div
                              className="p-3"
                              style={{
                                background: 'rgba(0,0,0,0.8)',
                                color: 'white',
                                borderRadius: '6px'
                              }}
                            >
                              {col.info}
                            </div>
                          }
                          duration={0}
                          placement="bottom"
                        >
                          <span style={{ marginLeft: '8px' }}>
                            <FontAwesomeIcon icon={faCircleInfo} className="icon" />
                          </span>
                        </Tippy>
                      )}
                    </span>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {rows.length > 0 && (
                <EditableRow
                  canEdit={isEditing}
                  columns={format.columns}
                  rows={rows}
                  editCell={editCell}
                  addRowAtIndex={addRowAtIndex}
                  removeRowAtIndex={removeRowAtIndex}
                  minimumNrOfRows={minimumNrOfRows}
                />
              )}
              {format.totalCol && (
                <TotalColRow rows={rows} totalCol={format.totalCol} handledCols={format.handledCols} columns={format.columns} />
              )}
              {format.total && format.total.length > 0 && (
                <tr style={{ height: '48px' }}>
                  <td
                    colSpan={Object.keys(rowFormat).length}
                    style={{ border: '1px solid grey', textAlign: 'center', background: 'lightgray' }}
                  >
                    Total coloane{' '}
                    {format.total.map((el: any, i: number) => (
                      <span>
                        {el + 1}
                        {i === format.total.length - 1 ? '' : ', '}
                      </span>
                    ))}
                    : {calcTotal}
                  </td>
                </tr>
              )}
              {emptyTable && rows.length == 0 && (
                <tr>
                  <td className="d-flex text-center actions-td">
                    <span onClick={() => addRowAtIndex(0)} className="pointer">
                      <FontAwesomeIcon className="add-row btn-wide" icon={faPlus} />
                    </span>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </form>
        {format.total && format.total.length > 0 && (
          <p>
            Totalul se face pe coloanele: &nbsp;
            {format.total.map((el: number, idx: number) => (
              <span style={{ marginRight: '4px' }} key={idx}>
                {el + 1}
              </span>
            ))}
          </p>
        )}
      </div>
    </div>
  );
};

export default DynamicTable;
