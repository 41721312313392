import { toggleLoader } from '@actions/LoaderActions';
import {
  deleteRequest,
  getDecrecDetails,
  getDecrecVerifyConsumptionPoint,
  getGenerateRequestDocument,
  postDecrecSend
} from '@api/decrec/decrec';
import { UploadFile } from '@components/common/UploadFile';
import { UTILITY_EE } from '@constants/Utility';
import { faChevronLeft, faPen, faRectangleList, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as notification from '@lib/Notification';
import { GetDecrecDetailsResponseType, GetDecrecSendResponseType, GetDecrecVerifyConsumptionPointResponseType } from '@type/decrec';
import { Fragment, useEffect, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { getQueryParam } from '../../../helpers/urlUtils';
import ModalAdd from './components/ModalAdd';
import Verify from './components/Verify';
import { DECREC_STATUS__NETRIMISA, TYPE_CANCEL_DISCONNECT } from '@constants/decRec/decRecConstants';
import { downloadBlobWithFilename } from '../../../helpers/download';
import ChoiceModal from '@components/common/Modal/ChoiceModal';
import { getFreeDatesForNextMonths } from '@api/freeDates/freeDates';

const DecRecSolicitareNoua = () => {
  const [utility, setUtility] = useState<any>(0);
  const [decRecDetails, setDecRecDetails] = useState<GetDecrecDetailsResponseType | null>(null);
  const [requestId, setRequestId] = useState<number | any>(null);
  const [isEdit, setIsEdit] = useState<boolean | any>(false);
  const [type, setType] = useState<any>('');
  const [resetKey, setResetKey] = useState(0);

  const [freeDates, setFreeDates] = useState<string[]>([]);

  //verify
  const [pod, setPod] = useState('');
  const [county, setCounty] = useState('');
  const [countyObject, setCountyObject] = useState('');

  const [relatedRequestId, setRelatedRequestId] = useState<number | any>(null);
  const [relatedRequestStatus, setRelatedRequestStatus] = useState<number | any>(null);

  //add new item
  const [headerData, setHeaderData] = useState<GetDecrecVerifyConsumptionPointResponseType | null>(null);
  const [showModal, setShowModal] = useState(false);

  const [fileList, setFileList] = useState<File[]>([] as File[]);

  //delete
  const [showDeleteRequestModal, setShowDeleteRequestModal] = useState(false);
  const [requestToDelete, setRequestToDelete] = useState(0);
  const [actionsDisabled, setActionsDisabled] = useState(false);

  const [error, setError] = useState('');
  const [fatalError, setFatalError] = useState('');

  const dispatch = useDispatch();

  useEffect(() => {
    if (decRecDetails && decRecDetails.statusId && decRecDetails.statusId != DECREC_STATUS__NETRIMISA) {
      setFatalError('Cererea a fost deja trimisă.');
    }
  }, [decRecDetails]);

  useEffect(() => {
    const utility = getQueryParam('utility');
    const type = getQueryParam('type');
    const id = getQueryParam('id');

    if (utility) setUtility(utility);
    if (type) setType(type);
    if (id) setRequestId(id);
    setIsEdit(!!id);

    if (!id && !utility && !type) {
      setFatalError('A apărut o eroare tehnică. Vă rugăm să accesați pagina din secțiunea principală.');
    }

    getFreeDatesForNextMonths(24)
      .then((res) => {
        setFreeDates(res);
      })
      .catch((err) => {
        setFatalError('A apărut o eroare tehnică. Vă rugam reveniți mai târziu.');
      });
  }, []);

  useEffect(() => {
    if (requestId) {
      dispatch(toggleLoader(true, 'getDecrecDetails'));

      getDecrecDetails({ id: requestId, utility: utility })
        .then((res: GetDecrecDetailsResponseType) => {
          setDecRecDetails(res);
          setType(res.idRequestType);
          setHeaderData({
            errorMessage: '',
            isValid: true,
            relatedRequestId: 0,
            relatedRequestStatus: 0,
            address: res.address,
            consumptionPointCode: res.vstelle,
            installationStatusName: res.installationStatusName,
            meterStatusName: res.meterStatusName,
            pod: res.pod,
            supplierName: res.supplierName,
            contractStatusName: res.contractStatusName
          });
          dispatch(toggleLoader(false, 'getDecrecDetails'));
        })
        .catch((error: any) => {
          setFatalError('Cererea nu a fost găsită.');
          notification.error('A apărut o eroare. Vă rugăm să reveniți mai tărziu.');
          dispatch(toggleLoader(false, 'getDecrecDetails'));
        });
    }
  }, [requestId]);

  const handlePod = (value: string) => {
    setPod(value);
  };

  const handleCounty = (value: string) => {
    setCounty(value);
  };

  const validate = async () => {
    setError('');
    setRelatedRequestId('');
    dispatch(toggleLoader(true));

    const payload = {
      pod,
      countyCode: county,
      localityCode: 0,
      utility: utility,
      requestType: type
    };

    getDecrecVerifyConsumptionPoint(payload)
      .then((response: GetDecrecVerifyConsumptionPointResponseType) => {
        if (response && response.isValid) {
          if (type == TYPE_CANCEL_DISCONNECT) {
            setDecRecDetails(response as any);
          }
          setHeaderData(response as any);
          setShowModal(true);
          dispatch(toggleLoader(false));
        } else {
          setError(response.errorMessage);
          setRelatedRequestId(response.relatedRequestId);
          setRelatedRequestStatus(response.relatedRequestStatus);
          dispatch(toggleLoader(false));
        }
      })
      .catch((error: any) => {
        notification.error('A apărut o eroare. Vă rugăm să reveniți mai tărziu.');
        dispatch(toggleLoader(false));
      });
  };

  const editDecRecDetailsEntry = (cpToEditData: GetDecrecDetailsResponseType) => {
    setIsEdit(true);
    setShowModal(true);
  };

  const sendfinalRequest = () => {
    //api call
    dispatch(toggleLoader(true));

    let payload: any = {
      Utility: Number(utility),
      RequestId: Number(requestId),
      DocumenteSolicitare: fileList.map((file: any) => {
        return {
          NumeDocument: file.name,
          ContinutDocument: file.base64.split(',')[1]
        };
      })
    };

    postDecrecSend(payload)
      .then((response: GetDecrecSendResponseType) => {
        if (response && response.succes) {
          notification.success('Cererea a fost trimisă.');
          setActionsDisabled(true);
          setTimeout(() => {
            window.location.href = `/detalii-deconectari-reconectari?id=${requestId}&utility=${utility}`;
          }, 3000);
        } else {
          setError(response.descriereEroare);
        }
        dispatch(toggleLoader(false));
      })
      .catch((error: any) => {
        notification.error('A apărut o eroare. Vă rugăm să reveniți mai tărziu.');
        dispatch(toggleLoader(false));
      });
  };

  const handleReset = async () => {
    dispatch(toggleLoader(true));
    // if (newRequestId) {
    //   setRequestId(newRequestId);
    // }
    setPod('');
    setCounty('');
    setResetKey(resetKey + 1);
    setShowModal(false);
    dispatch(toggleLoader(false));
  };

  const handleDownloadReqeust = () => {
    dispatch(toggleLoader(true));

    getGenerateRequestDocument({ utility: Number(utility), id: requestId })
      .then((response: BlobPart) => {
        downloadBlobWithFilename(
          response,
          'cerere-pentru-semnare-',
          '.pdf',
          true,
          () => {},
          (err: any) => notification.error('A apărut o eroare. Vă rugăm să reveniți mai tărziu.')
        );

        dispatch(toggleLoader(false));
      })
      .catch((error: any) => {
        notification.error('A apărut o eroare. Vă rugăm să reveniți mai tărziu.');
        dispatch(toggleLoader(false));
      });
  };

  const deleteDecRecEntry = (id: number) => {
    dispatch(toggleLoader(true));

    deleteRequest({ id: requestToDelete, utility: utility })
      .then((res) => {
        dispatch(toggleLoader(false));
        if (!res.succes) {
          if (res.descriereEroare) {
            notification.error(res.descriereEroare);
          } else notification.error('A intervenit o eroare tehnică. Vă rugăm reveniți mai târziu.');
        } else {
          notification.success('Cererea a fost ștearsă cu succes.');
          setActionsDisabled(true);
          setTimeout(() => {
            window.location.href = '/lista-deconectare-reconectare-' + (utility == UTILITY_EE ? 'ee' : 'gn');
          }, 3000);
        }
      })
      .catch((err) => {
        notification.error('A intervenit o eroare tehnică. Vă rugăm reveniți mai târziu.');
        dispatch(toggleLoader(false));
      });
  };

  return (
    <Fragment>
      <Row>
        <div className="mb-3">
          <a href={'/lista-deconectare-reconectare-' + (utility == UTILITY_EE ? 'ee' : 'gn')}>
            <Button disabled={actionsDisabled}>
              <FontAwesomeIcon icon={faChevronLeft} style={{ marginRight: '6px' }} />
              <span>Înapoi</span>
            </Button>
          </a>
        </div>
      </Row>

      {fatalError && <p className="text-center fw-bold red mt-3">{fatalError} </p>}

      {!fatalError && (
        <Fragment>
          {!decRecDetails && (
            <Verify
              key={`verify_key_${resetKey}`}
              onClick={() => validate()}
              pod={pod}
              handlePod={handlePod}
              county={county}
              handleCounty={handleCounty}
              setCountyObject={(val) => {
                setCountyObject(val);
              }}
              utility={utility}
            />
          )}

          {decRecDetails && (
            <Row className="mt-3">
              <Col md={7} className="p-3 mt-0">
                <div className="general-table-container">
                  <table>
                    <thead>
                      <tr>
                        <th>POD</th>
                        <th>CLC</th>
                        <th>Adresa locului de consum</th>
                        <th>Acțiuni</th>
                      </tr>
                    </thead>
                    <tbody>
                      {decRecDetails && (
                        <tr key={0}>
                          <td>{decRecDetails.pod}</td>
                          <td>{decRecDetails.vstelle}</td>
                          <td>{decRecDetails.address}</td>
                          <td>
                            <Button className="mt-1 me-2" onClick={() => editDecRecDetailsEntry(decRecDetails)} disabled={actionsDisabled}>
                              {type == TYPE_CANCEL_DISCONNECT ? (
                                <>
                                  <FontAwesomeIcon icon={faRectangleList} /> Vizualizează
                                </>
                              ) : (
                                <>
                                  <FontAwesomeIcon icon={faPen} /> Editează
                                </>
                              )}
                            </Button>
                            <Button
                              className="mt-1"
                              onClick={() => {
                                console.log('decrecDetails', decRecDetails);
                                setRequestToDelete(decRecDetails.id);
                                setShowDeleteRequestModal(true);
                              }}
                              disabled={actionsDisabled}
                            >
                              <FontAwesomeIcon icon={faTrash} /> Șterge
                            </Button>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </Col>

              <Col md={5} className="p-3">
                <a className="link link--download red download-link-text pointer hover-black" onClick={handleDownloadReqeust}>
                  Descarcă cererea pentru semnare
                </a>

                <p className="m-0 mt-3">Încarcă cerere semnată (.pdf)</p>
                <UploadFile
                  onSetFileList={(values: any) => setFileList(values)}
                  fileList={fileList}
                  index={0}
                  uploadedFilesLimit={1}
                  acceptedExtensions={['.pdf']}
                  hideDownloadButton
                />

                <p className="mt-3">
                  Locuri consum valide incluse:
                  <span className={'active ms-1'}>
                    <span className="rounded-lightgrey-box fw-bold">{decRecDetails ? '1/1' : '0'}</span>
                  </span>
                </p>

                <p className="mt-3">
                  Cerere încărcată:
                  <span className={'active ms-1'}>
                    <span className="rounded-lightgrey-box fw-bold">{fileList.length ? 'DA' : 'NU'}</span>
                  </span>
                </p>

                <div className="d-flex">
                  <Button
                    className="btn-wide mt-3 mb-1"
                    disabled={!decRecDetails || actionsDisabled}
                    onClick={async () => {
                      sendfinalRequest();
                    }}
                  >
                    Trimite solicitare
                  </Button>
                </div>
              </Col>
            </Row>
          )}

          {error !== '' && (
            <p className="text-center fw-bold red mt-3">
              {error}{' '}
              {relatedRequestId && (
                <p className="">
                  Vezi solicitarea{' '}
                  <a
                    href={`${
                      relatedRequestStatus == DECREC_STATUS__NETRIMISA ? '/solicitare-noua' : '/detalii-deconectari-reconectari'
                    }?id=${relatedRequestId}&utility=${utility}`}
                    className="text-underline"
                  >
                    aici
                  </a>
                </p>
              )}
            </p>
          )}

          <ModalAdd
            key={`modal_add_key_${resetKey}`}
            utility={utility}
            show={showModal}
            handleShow={(show: boolean) => {
              setShowModal(show);
            }}
            headerData={headerData}
            requestId={requestId}
            type={type}
            isValid={true}
            setRequestId={(value: number) => setRequestId(value)}
            reset={handleReset}
            decRecDetails={decRecDetails}
            county={countyObject}
            isEdit={isEdit}
            freeDates={freeDates}
          />
        </Fragment>
      )}

      <ChoiceModal
        showModal={showDeleteRequestModal}
        modalTitle={'Sunteți sigur că doriți să ștergeți cererea?'}
        options={[
          { id: 'DA', title: 'Da' },
          { id: 'NU', title: 'Nu' }
        ]}
        onClose={(option: any) => {
          if (option.id == 'DA') {
            deleteDecRecEntry(requestToDelete);
            setShowDeleteRequestModal(false);
          } else if (option.id == 'NU') {
            setShowDeleteRequestModal(false);
          }
        }}
        onHide={() => {
          setShowDeleteRequestModal(false);
        }}
        btnWide
      />
    </Fragment>
  );
};

export default DecRecSolicitareNoua;
