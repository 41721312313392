import React from 'react';
import { DelgazWrapper } from '@components/global/DelgazWrapper';
import InfoPVAEClient from '@components/pvae/InfoPVAEClient';

export const PVAEInfoClient = () => {
    return (
        <DelgazWrapper>
            <InfoPVAEClient />
        </DelgazWrapper>
    );
};