import { proxy as kenticoProxy, Proxy as KenticoProxy } from '@api/kenticoProxy';
import { store } from '@lib/store';
import { AxiosRequestConfig } from 'axios';

export async function getInterruptions() {
  const state = store.getState();

  const proxyData: KenticoProxy = {
    method: 'GET',
    endPoint: '/cms_api/Interruption/get_all',
    withAuthBearer: false
  };

  const options: AxiosRequestConfig = {
    headers: {
      XApiKey: state.options.xApiKey ?? ''
    }
  };

  return kenticoProxy<any>(proxyData, options);
}
