import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {IState} from '@type/store';
import {jwtDecode} from 'jwt-decode';
import {GetDeviceDetails} from '@lib/deviceDetect';

type JWT = {
    aud: string;
    aut: string;
    azp: string;
    DeviceId: string[];
    exp: number;
    given_name: string;
    groups: string[];
    iat: number;
    iss: string;
    jti: string;
    nbf: number;
    scope: string;
    sub: string;
    userid: string;
}

const Devices = (props: any) => {
    const [devices, setDevices] = useState<string[]>([]);
    const token: string | undefined = useSelector<IState>((state) => state?.auth?.token) as string;

    // let example = [
    //     'WC16692891527638ca5b-2578-4081-bb3f-3500c362f913',
    //     'MC16592871527635bb9o-9011-4348-bb3f-7053c362f667',
    //     'MS16692881527638ca7f-2578-5153-bc7d-8744c362f913'
    // ];

    useEffect(() => {
        if (token) {
            let jwt: JWT = jwtDecode(token);
            if (Array.isArray(jwt.DeviceId)) {
                setDevices(jwt.DeviceId);
            } else {
                let device: string[] = [];
                device.push(jwt.DeviceId);
                setDevices(device);
            }
        }
    }, []);

    // useEffect(() => {
    //         if (example) {
    //             if (Array.isArray(example)) {
    //                 setDevices(example);
    //             } else {
    //                 let device: string[] = [];
    //                 device.push(example);
    //                 setDevices(device);
    //             }
    //         }
    // }, []);

    return (
        <div className="devices">
            <div className="device-container m-3">
                {devices.length > 0 && <ol type="1">
                    {
                        devices.map((device, index) => (
                            <div className="mb-3" key={index}>
                                <li>
                                    <div>
                                        <p className="m-0">Sistem de operare: {GetDeviceDetails(device).os}</p>
                                        <p className="m-0">ID: {GetDeviceDetails(device).id}</p>
                                        <p className="m-0">Browser: {GetDeviceDetails(device).browser}</p>
                                        {/*<p className="m-0">Data: {GetDeviceDetails(device).date}</p>*/}
                                    </div>
                                </li>
                            </div>
                        ))
                    }
                </ol>}
            </div>
        </div>
    );
};

export default Devices;