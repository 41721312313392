import { toggleLoader } from '@actions/LoaderActions';
import { postStepFour } from '@api/iConnect/iConnectEE';
import { getTypes } from '@api/prosumatori/prosumatori';
import { Input } from '@components/common/Input';
import ChoiceComponent from '@components/prosumatori/components/ChoiceComponent';
import DynamicTable from '@components/prosumatori/components/table/DynamicTable';
import { useEffect, useState } from 'react';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import TabelTipRacordEE from '../components/TabelTipRacordEE';

const LCCasnic = (props: {
  isEditing: any;
  requestId: any;
  setStep: any;
  currentStep: any;
  progress: any;
  setProgress: any;
  tipLC: any;
  dateTehnice: any;
  handleDateTehnice: any;
  aparateFrecvente: any;
  alteAparate: any;
  dateAF: any;
  dateAA: any;
  handleDateAF: any;
  handleDateAA: any;
  SI: any;
  handleSI: any;
  statiiIncarcare: any;
  handleDateSI: any;
  dateSI: any;
  KWTotalInstalat: any;
  handleKWTotalInstalat: any;
  KWAbsorbit: any;
  showAF: boolean;
  showAA: boolean;
  setShowAF: any;
  setShowAA: any;
  handleKWAbsorbit: any;
  santier: any;
}) => {
  const {
    isEditing,
    requestId,
    setStep,
    currentStep,
    progress,
    setProgress,
    tipLC,
    dateTehnice,
    handleDateTehnice,
    SI,
    handleSI,
    statiiIncarcare,
    handleDateSI,
    dateSI,
    aparateFrecvente,
    alteAparate,
    dateAF,
    dateAA,
    handleDateAF,
    handleDateAA,
    KWTotalInstalat,
    // handleKWTotalInstalat,
    KWAbsorbit,
    showAF,
    showAA,
    setShowAF,
    setShowAA,
    // handleKWAbsorbit,
    santier
  } = props;
  const [error, setError] = useState('');
  const [validationError, setValidationError] = useState('');
  const [AFIDs, setAFIDs] = useState([]);
  const [tipRacord, setTipRacord] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showPowerModal, setShowPowerModal] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    getOptionTypes(['RequestConnectionType']).then((res) => setTipRacord(res));
    getOptionTypes(['DomesticConsumerTypes']).then((res) => setAFIDs(res));
  }, []);

  const getOptionTypes = async (keys: string[]) => {
    return await getTypes(keys).then((res: any) => {
      return res[keys[0]];
    });
  };

  const constructDateAA = (data: any) => {
    let newData = JSON.parse(JSON.stringify(data));

    newData.map((row: any, index: number) => {
      tipRacord?.map((type: any, idx: number) => {
        if (row.tip_receptor === type.name) {
          row.tip_receptor = type.id;
        }
      });
    });

    return newData;
  };

  const constructDateAF = (data: any) => {
    let newData = JSON.parse(JSON.stringify(data));

    newData.map((row: any, index: number) => {
      row['ConsumptionPower'] = Number(row['ConsumptionPower']);
      row['DomesticConsumerCount'] = Number(row['DomesticConsumerCount']);
      row['TotalConsumptionPower'] = Number(row['TotalConsumptionPower']);
    });

    let newArray: any = [];

    newData.map((row: any, index: number) => {
      if (row['Enabled'] === true) {
        newArray.push(row);
      }
    });

    newArray.forEach(function (v: any) {
      delete v.Enabled;
    });

    newArray.map((entry: any, index: number) => {
      AFIDs.map((item: any, idx: number) => {
        if (entry['DomesticConsumerName'] === item['domesticConsumerName']) {
          entry['DomesticConsumerTypeId'] = item.domesticConsumerTypeId;
        }
      });
    });

    return newArray;
  };

  const setOptionsForChargingStations = (data: any) => {
    let newData = JSON.parse(JSON.stringify(data));

    newData.map((el: any, i: number) => {
      Object.keys(el).map((key: any, idx: number) => {
        // if (idx === 4) {
        //     if (el[key] === 'AC') {
        //         el[key] = 1;
        //     } else if (el[key] === 'DC') {
        //         el[key] = 2;
        //     }
        // }
        if (idx === 5) {
          if (el[key] === 'public') {
            el[key] = 2;
          } else if (el[key] === 'privat') {
            el[key] = 1;
          }
        }
        if (idx === 6) {
          if (el[key] === 'FORTE PROPRII') {
            el[key] = 1;
          } else if (el[key] === 'AFM2021-UAT SI INSTIT.PUBLICE') {
            el[key] = 2;
          } else if (el[key] === 'ELECTRIC UP') {
            el[key] = 3;
          }
        }
        if (idx === 4) {
          if (el[key] === 'lenta') {
            el[key] = 1;
          } else if (el[key] === 'rapida') {
            el[key] = 2;
          }
        }
      });
    });

    return newData;
  };

  const constructPayload = () => {
    console.log('dateAA: ', dateAA);
    return {
      requestId: requestId,
      data_solicitata: null,
      mod_alimentare: '',
      tip_tarif: null,
      date_tehnice_old: {
        TipRacord: dateTehnice.seTipRacord,
        KW: Number(dateTehnice.seKW.replace(',', '.')),
        KVA: Number(dateTehnice.seKVA.replace(',', '.'))
      },
      date_tehnice_new: {
        TipRacord: tipLC === 2 ? dateTehnice.ssTipRacord : dateTehnice.seTipRacord,
        KW: tipLC === 2 ? Number(dateTehnice.ssKW.replace(',', '.')) : Number(dateTehnice.seKW.replace(',', '.')),
        KVA: tipLC === 2 ? Number(dateTehnice.ssKVA.replace(',', '.')) : Number(dateTehnice.seKVA.replace(',', '.'))
      },
      tensiune: 0,
      factor_putere: 0,
      specificul_activitatii: [],
      regim_lucru: {
        nr_schimburi: 0,
        interval_orar: '',
        nr_zile: 0
      },
      surse_alimentare: {
        tip: '',
        putere: '',
        timp: '',
        durata: ''
      },
      timp_max_intrerupere: '',
      lista_receptoare: [],
      statie_masina_electrica: SI === 1,
      consumatori_predefiniti: showAF ? constructDateAF(dateAF) : [],
      consumatori: showAA ? constructDateAA(dateAA) : [],
      detalii_factor_putere: '',
      nr_cai_alimentare: 0,
      dezvoltare_in_etape: false,
      numar_etape: 0,
      ...(SI === 1 ? { statii_incarcare: setOptionsForChargingStations(dateSI) } : { statii_incarcare: [] }),
      santier_data_valabilitate: null,
      kw_total_instalata_santier: 0,
      kw_maxim_absorbita_santier: 0,
      tip_instalatii: [],
      kw_maxim_absorbita: tipLC === 2 ? Number(dateTehnice.ssKW.replace(',', '.')) : Number(dateTehnice.seKW.replace(',', '.')),
      alimentare_utilizatori_externi: false,
      utilizatori_externi: [],
      cerinte_specifice: '',
      detalii_energetice_receptoare: [],
      pi_total: tipLC === 1 ? Number(dateTehnice.seKW.replace(',', '.')) : Number(dateTehnice.ssKW.replace(',', '.')),
      TipRacord: tipLC === 2 ? dateTehnice.ssTipRacord : dateTehnice.seTipRacord,
      santier: santier
    };
  };

  const stepFourApiCall = async (payload: any) => {
    dispatch(toggleLoader(true));
    setError('');
    setValidationError('');

    await postStepFour(payload)
      .then((response: any) => {
        if (response && response.status === 200) {
          setStep(currentStep + 1);
          currentStep === progress && setProgress(currentStep + 1);
        }
      })
      .catch((error: any) => {
        if (error && error.status === 200) {
          setStep(currentStep + 1);
          currentStep === progress && setProgress(currentStep + 1);
        } else if (error === 'Request is completed') {
          setError('Cererea a fost deja finalizată.');
        } else if (error && error.StatusCode && error.StatusCode === 500) {
          setError('A intervenit o eroare tehnică. Vă rugăm reveniți mai târziu.');
        } else if (error && error.statusCode && error.statusCode === 500) {
          setError('A intervenit o eroare tehnică. Vă rugăm reveniți mai târziu.');
        }
      });

    dispatch(toggleLoader(false));
  };

  const validateTable = (table: any, indexException: number | null) => {
    let validated = true;
    table.map((row: any, index: number) => {
      Object.keys(row).map((column: any, idx: number) => {
        if (idx !== indexException) {
          if (row[column] === '' || row[column] === null) {
            validated = false;
          }
        }
      });
    });

    return validated;
  };

  const areSIPowersValid = (table: any) => {
    let validated = true;

    table.map((row: any, index: number) => {
      Object.keys(row).map((column: any, idx: number) => {
        if (Number(row['Putere_maxima simultan absorbita (kW)']) > Number(row['Putere_maxima instalata (kW)'])) {
          validated = false;
        }
      });
    });

    return validated;
  };

  const validateTableAF = (table: any, indexException: number | null) => {
    let validated = true;
    table.map((row: any, index: number) => {
      Object.keys(row).map((column: any, idx: number) => {
        if (idx !== indexException && row['Enabled'] === true) {
          if (row['DomesticConsumerCount'] === '' || Number(row['DomesticConsumerCount']) <= 0) {
            validated = false;
          }

          if (row['ConsumptionPower'] === '' || Number(row['ConsumptionPower']) <= 0) {
            validated = false;
          }
        }
      });
    });

    return validated;
  };

  const getAFMaxPI = (table: any) => {
    let maxPI = 0;

    table.map((row: any, index: number) => {
      Object.keys(row).map((column: any, idx: number) => {
        if (row['Enabled'] === true) {
          if (Number(row['ConsumptionPower']) > maxPI) {
            maxPI = row['ConsumptionPower'];
          }
        }
      });
    });

    return maxPI;
  };

  const getAAMaxPI = (table: any) => {
    let maxPI = 0;

    table.map((row: any, index: number) => {
      Object.keys(row).map((column: any, idx: number) => {
        if (Number(row['ConsumptionPower']) > maxPI) {
          maxPI = row['ConsumptionPower'];
        }
      });
    });

    return maxPI;
  };

  const getSIMaxPI = (table: any) => {
    let maxPI = 0;

    table.map((row: any, index: number) => {
      Object.keys(row).map((column: any, idx: number) => {
        if (Number(row['Putere_maxima instalata (kW)']) > maxPI) {
          maxPI = row['Putere_maxima instalata (kW)'];
        }
      });
    });

    return maxPI;
  };

  const renderPowerModal = () => {
    return (
      <Modal
        show={showPowerModal}
        centered
        onHide={() => {
          setShowPowerModal(false);
        }}
        onClose={() => {
          setShowPowerModal(false);
        }}
      >
        <Modal.Header closeButton>
          <span style={{ height: '30px' }}></span>
        </Modal.Header>
        <Modal.Body>
          <p>
            Puterea solicitată este mai mică decât cea existentă. Ești sigur că iți dorești să reduci puterea?
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button 
            onClick={() => {
              if ((tipLC === 1 && dateTehnice.seTipRacord === 2) || (tipLC === 2 && dateTehnice.ssTipRacord === 2)) {
                setShowPowerModal(false);
                setShowModal(true);
              } else {
                stepFourApiCall(constructPayload());
              }
            }}
            >
              Da
            </Button>
          <Button onClick={() => setShowPowerModal(false)}>Modific puterea</Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const renderModal = () => {
    return (
      <Modal
        show={showModal}
        centered
        onHide={() => {
          setShowModal(false);
        }}
        onClose={() => {
          setShowModal(false);
        }}
      >
        <Modal.Header closeButton>
          <span style={{ height: '30px' }}></span>
        </Modal.Header>
        <Modal.Body>
          <p>
            Atenție! Pentru puterea maximă simultan absorbită solicitată de tine se va executa un branșament de tip trifazat. Dacă dorești
            un branșament monofazat, puterea maximă simultan absorbită nu trebuie să depășească 9.35 kW.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => stepFourApiCall(constructPayload())}>Sunt de acord</Button>
          <Button onClick={() => setShowModal(false)}>Modific puterea</Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const handleNextStep = () => {
    if (isEditing !== false) {
      setError('');
      let wasValidated = true;
      let dt = document.getElementById('dt');
      dt?.classList.remove('red');
      let aa = document.getElementById('alte_aparate');
      aa?.classList.remove('red');
      let af = document.getElementById('aparate_frecvente');
      af?.classList.remove('red');
      let si = document.getElementById('SI');
      si?.classList.remove('red');

      console.log('si: ', SI);

      if (isNaN(dateTehnice.seKW) || isNaN(dateTehnice.seKVA)) {
        setValidationError('Te rugăm să revizuiești informațiile completate.');
        wasValidated = false;
        let err = document.getElementById('dt');
        err?.classList.add('red');
      }

      if (dateTehnice.seKW === '' || (tipLC === 1 && dateTehnice.seKVA === '')) {
        setValidationError('Câmpurile marcate cu * sunt obligatorii.');
        wasValidated = false;
        let err = document.getElementById('dt');
        err?.classList.add('red');
      }

      if (Number(dateTehnice.seKW) === 0 || (tipLC === 1 && Number(dateTehnice.seKVA) === 0)) {
        setValidationError('Câmpurile marcate cu * sunt obligatorii.');
        wasValidated = false;
        let err = document.getElementById('dt');
        err?.classList.add('red');
      }

      if (tipLC === 2 && (Number(dateTehnice.ssKW) === 0 || Number(dateTehnice.ssKVA) === 0)) {
        setValidationError('Câmpurile marcate cu * sunt obligatorii.');
        wasValidated = false;
        let err = document.getElementById('dt');
        err?.classList.add('red');
      }

      if (tipLC === 2 && (dateTehnice.ssKW === '' || dateTehnice.ssKVA === '')) {
        setValidationError('Câmpurile marcate cu * sunt obligatorii.');
        wasValidated = false;
        let err = document.getElementById('dt');
        err?.classList.add('red');
      }

      if (dateTehnice.seTipRacord === 0 || dateTehnice.seTipRacord === null) {
        setValidationError('Câmpurile marcate cu * sunt obligatorii.');
        wasValidated = false;
        let err = document.getElementById('dt');
        err?.classList.add('red');
      }

      if (tipLC === 2 && (dateTehnice.ssTipRacord === 0 || dateTehnice.ssTipRacord === null)) {
        setValidationError('Câmpurile marcate cu * sunt obligatorii.');
        wasValidated = false;
        let err = document.getElementById('dt');
        err?.classList.add('red');
      }

      if (Number(dateTehnice.seKVA) > Number(dateTehnice.seKW)) {
        setValidationError('Puterea maximă simultan absorbită totală trebuie să fie mai mică sau egală cu puterea instalată totală.');
        wasValidated = false;
        let err = document.getElementById('dt');
        err?.classList.add('red');
      }

      if (tipLC === 2 && Number(dateTehnice.ssKVA) > Number(dateTehnice.ssKW)) {
        setValidationError('Puterea maximă simultan absorbită totală trebuie să fie mai mică sau egală cu puterea instalată totală.');
        wasValidated = false;
        let err = document.getElementById('dt');
        err?.classList.add('red');
      }

      if (showAF && !validateTableAF(dateAF, null)) {
        setValidationError('Câmpurile marcate cu * sunt obligatorii.');
        wasValidated = false;
        let err = document.getElementById('aparate_frecvente');
        err?.classList.add('red');
      }

      if (showAA && !validateTable(dateAA, null)) {
        setValidationError('Câmpurile marcate cu * sunt obligatorii.');
        wasValidated = false;
        let err = document.getElementById('alte_aparate');
        err?.classList.add('red');
      }

      if (showAF) {
        if (Number(tipLC === 1 ? dateTehnice.seKVA : dateTehnice.ssKVA) < getAFMaxPI(dateAF)) {
          setValidationError(
            'Puterea maximă simultan absorbită totală trebuie să fie mai mare sau egală cu puterea instalată a celui mai mare receptor din listele cu aparate și stații de încărcare.'
          );
          wasValidated = false;
          let err = document.getElementById('dt');
          err?.classList.add('red');
        }
      }

      if (showAA) {
        if (Number(tipLC === 1 ? dateTehnice.seKVA : dateTehnice.ssKVA) < getAAMaxPI(dateAA)) {
          setValidationError(
            'Puterea maximă simultan absorbită totală trebuie să fie mai mare sau egală cu puterea instalată a celui mai mare receptor din listele cu aparate și stații de încărcare.'
          );
          wasValidated = false;
          let err = document.getElementById('dt');
          err?.classList.add('red');
        }
      }

      if (SI === null) {
        setValidationError('Câmpurile marcate cu * sunt obligatorii.');
        wasValidated = false;
        let err = document.getElementById('SI');
        err?.classList.add('red');
      }

      if (SI === 1 && !validateTable(dateSI, 7)) {
        setValidationError('Câmpurile marcate cu * sunt obligatorii.');
        wasValidated = false;
        let err = document.getElementById('SI');
        err?.classList.add('red');
      }

      if (SI === 1 && validateTable(dateSI, 7)) {
        if (!areSIPowersValid(dateSI)) {
          setValidationError(
            'Puterea maximă simultan absorbită a unui receptor trebuie să fie mai mică sau egală cu puterea instalată a acestuia.'
          );
          wasValidated = false;
          let err = document.getElementById('SI');
          err?.classList.add('red');
        }
      }

      if (SI === 1 && Number(tipLC === 1 ? dateTehnice.seKVA : dateTehnice.ssKVA) < getSIMaxPI(dateSI)) {
        setValidationError(
          'Puterea maximă simultan absorbită totală trebuie să fie mai mare sau egală cu puterea instalată a celui mai mare receptor din listele cu aparate și stații de încărcare.'
        );
        wasValidated = false;
        let err = document.getElementById('dt');
        err?.classList.add('red');
      }

      if (wasValidated) {
        if (tipLC === 2 && ((Number(dateTehnice.ssKW) < Number(dateTehnice.seKW)) || (Number(dateTehnice.ssKVA) < Number(dateTehnice.seKVA)))) {
          setShowPowerModal(true);
        } else if ((tipLC === 1 && dateTehnice.seTipRacord === 2) || (tipLC === 2 && dateTehnice.ssTipRacord === 2)) {
          setShowModal(true);
        } else {
          stepFourApiCall(constructPayload());
        }
      }
    } else {
      setStep(currentStep + 1);
      currentStep === progress && setProgress(currentStep + 1);
    }
  };

  const handlePrevStep = () => {
    setStep(currentStep - 1);
  };

  return (
    <div className="iconnect-informatii-tehnice m-3 w-100 d-flex align-self-center flex-column">
      <h4 className="register-section-title mt-3">Date tehnice și energetice pentru locul de consum</h4>
      <br></br>
      <h5 className="mb-0">
        Pentru calculul Puterii total instalate și calculul Puterii total simultan absorbite te poți folosi de tabelul de mai jos, “Aparate
        de utilizare”.
      </h5>
      <h5>
        Detalii despre puterile nominale ale receptoarelor se află în manualul de instrucțiuni al acestora sau pe etichetele lipite pe
        aparate. Informațiile privind puterea receptoarelor din tabelul de mai jos (coloana a doua) sunt strict orientative.
      </h5>
      <br></br>
      <h5 id="aparate_frecvente">Aparate de utilizare</h5>
      <Col xs={12} sm={4} className="mb-2">
        <Button onClick={() => isEditing !== false && setShowAF(!showAF)} disabled={isEditing === false}>Adaugă aparat</Button>
      </Col>
      {showAF && <DynamicTable format={aparateFrecvente} data={dateAF} handler={handleDateAF} isEditing={isEditing} modifyLines={false} />}
      <br></br>
      <h5 id="alte_aparate">Alte aparate</h5>
      <Col xs={12} sm={4} className="mb-2">
        <Button onClick={() => isEditing !== false && setShowAA(!showAA)} disabled={isEditing === false}>Adaugă aparat</Button>
      </Col>
      {showAA && <DynamicTable format={alteAparate} data={dateAA} handler={handleDateAA} isEditing={isEditing} />}

      <ChoiceComponent
        title={'În instalația de utilizare vor fi montate stații de încărcare a mașinilor electrice?*'}
        options={[
          { title: 'Da', id: 1 },
          { title: 'Nu', id: 2 }
        ]}
        onChange={handleSI}
        value={SI}
        disabled={isEditing === false}
        id="SI"
      />

      {SI === 1 && <DynamicTable format={statiiIncarcare} data={dateSI} handler={handleDateSI} isEditing={isEditing} />}
      {SI === 1 && (
        <div className="mt-2 mb-2">
          <br></br>
        </div>
      )}

      <h5>
        Valori orientative calculate pentru Putere total instalată (kW) și Puterea maximă simultan absorbită din rețea (kW) pentru
        receptoarele selectate de tine:
      </h5>
      <Row className="mt-2">
        <Col xs={12} sm={4}>
          <Input
            label="Putere total instalată (kW)"
            value={KWTotalInstalat}
            disabled
          />
        </Col>
        <Col xs={12} sm={4}>
          <Input
            label="Putere maximă simultan absorbită (kW)"
            value={KWAbsorbit}
            disabled
          />
        </Col>
      </Row>

      <h5 id="dt">Completează în tabelul de mai jos puterile solicitate și tipul de racord:*</h5>
      {tipLC === 1 && (
        <TabelTipRacordEE isEditing={isEditing} tableData={dateTehnice} type={1} handleData={handleDateTehnice} tipPutere={1} id="DATE" />
      )}
      {tipLC === 2 && (
        <TabelTipRacordEE isEditing={isEditing} tableData={dateTehnice} type={2} handleData={handleDateTehnice} tipPutere={1} id="DATE" />
      )}

      {validationError !== '' && <p style={{ fontWeight: 'bold', color: 'red' }}>{validationError}</p>}
      {error !== '' && <p style={{ color: 'red', fontWeight: 'bold' }}>{error}</p>}
      <div className="d-flex flex-row justify-content-between">
        <Button className="d-flex" onClick={handlePrevStep}>
          Pasul anterior
        </Button>
        <Button
          className="d-flex"
          onClick={() => {
            handleNextStep();
          }}
        >
          Pasul următor
        </Button>
      </div>

      {renderPowerModal()}
      {renderModal()}
    </div>
  );
};

export default LCCasnic;