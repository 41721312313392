import React, {Fragment, useEffect, useState} from 'react';
import {Button, Col, Row} from 'react-bootstrap';
import {
    Form,
    Formik,
    Field,
    ErrorMessage
} from 'formik';
import { OperatorSchema } from '../../../../helpers/schemas';
import PasswordStrengthBar from 'react-password-strength-bar';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faRotate} from '@fortawesome/free-solid-svg-icons';
import { loadCaptchaEngine, LoadCanvasTemplateNoReload, validateCaptcha } from '../CaptchaLib';
import {useSelector} from 'react-redux';
import {IState} from '@type/store';
import { validateRomanianCIF } from '../validations/Cui';
import { Input } from '@components/common/Input';
import { ROLE_OPERATOR_ECONOMIC } from '@constants/Roles/Roles';
import FormDetails from '../FormDetails';

const OperatorEconomic = ({ templates, ...props }: any) => {
    const [terms, setTerms] = useState(false);
    const [error, setError] = useState('');
    const resError = props.error;
    const resSuccess = props.success;
    const OLD_SITE_URL: string | undefined = useSelector<IState>((state) => state.options.webApiHost) as string;
    const syncErrors = props.syncErrors;
    const [validationError, setValidationError] = useState('');

    useEffect(() => {
        loadCaptchaEngine('6', 'white', 'black', '', 'oe');
    }, []);

    const submit = (values: any) => {
        setError('');
        let user_captcha = (document.getElementById('user_captcha_input') as HTMLInputElement).value;

        if (validateCaptcha(user_captcha, true, 'oe')===true) {
            loadCaptchaEngine('6', 'white', 'black', '', 'oe');
            (document.getElementById('user_captcha_input') as HTMLInputElement).value = '';

        //validare cui
        let cui = values.cui ?? ('' as string);
        const valid = validateRomanianCIF(cui);
        if (!valid) {
            setValidationError('Cui invalid.');
            return;
        } else {
            setValidationError('');
        }
            values = { ...values, contactMobilePhone: values.contactPhone.includes('-') ? values.contactPhone.replaceAll('-', '') : values.contactPhone };
            values = { ...values, mobilePhone: values.mobilePhone.includes('-') ? values.mobilePhone.replaceAll('-', '') : values.mobilePhone };
            values = { ...values, contactLastName: values.operatorName };
            props.submit(values);
        }

        else {
            setError('Codul de securitate nu este valid.');
            (document.getElementById('user_captcha_input') as HTMLInputElement).value = '';
        }
    };

    return (
        <Fragment>
            <Formik
                initialValues={{
                    role: 3,
                    operatorName: '',
                    mobilePhone: '',
                    contactPhone: '',
                    companyName: '',
                    cui: '',
                    orc: '',
                    ediEdibAuthorization: '',
                    email: '',
                    password: '',
                    emailConfirmation: '',
                    passwordConfirmation: '',
                    emailNotifications: true,
                    smsNotifications: false,
                }}
                onSubmit={(values) => submit(values)}
                validationSchema={ OperatorSchema }
            >
                {(props) => (
                    <Fragment>
                        <Form>
                            <FormDetails role={ROLE_OPERATOR_ECONOMIC} templates={templates}/>
                            <div className="form-container">
                                <div className="mt-4 mb-4 d-flex flex-column">
                                    <h5 className="register-section-title">Date de identificare</h5>
                                    <Row className="mt-3">
                                        <Col className="mb-3" xs={12} md={6}>
                                            <Field id="operatorName" name="operatorName" placeholder="Denumire"  render = {() => 
                                            <Input label='Denumire' mbZero onBlur={()=> {props.setFieldTouched('operatorName');}}
                                              onChange={(e:any) => {e.target.value.length !== 0 && props.setFieldValue('operatorName', e.target.value); 
                                              e.target.value.length === 0 && props.setFieldValue('operatorName', '');}}/>}/>
                                            <ErrorMessage name="operatorName" render={msg => <div style={{color: 'red', fontWeight: 'bold'}}>{msg}</div>}/>
                                        </Col>
                                        <Col className="mb-3" xs={12} md={3}>
                                            <Field  id="contactPhone" name="contactPhone" placeholder="Telefon fix" render = {() => 
                                            <Input label='Telefon fix' mbZero onBlur={()=> {props.setFieldTouched('contactPhone');}}
                                              onChange={(e:any) => {e.target.value.length !== 0 && props.setFieldValue('contactPhone', e.target.value); 
                                              e.target.value.length === 0 && props.setFieldValue('contactPhone', '');}}/>}/>
                                            <ErrorMessage name="contactPhone" render={msg => <div style={{color: 'red', fontWeight: 'bold'}}>{msg}</div>}/>
                                        </Col>
                                        <Col className="mb-3" xs={12} md={3}>
                                            <Field id="mobilePhone" name="mobilePhone" placeholder="Telefon mobil" render = {() => 
                                            <Input label='Telefon mobil' mbZero onBlur={()=> {props.setFieldTouched('mobilePhone');}}
                                              onChange={(e:any) => {e.target.value.length !== 0 && props.setFieldValue('mobilePhone', e.target.value); 
                                              e.target.value.length === 0 && props.setFieldValue('mobilePhone', '');}}/>}/>
                                            <ErrorMessage name="mobilePhone" render={msg => <div style={{color: 'red', fontWeight: 'bold'}}>{msg}</div>}/>
                                        </Col>
                                    </Row>
                                </div>

                                <div className="mt-4 mb-4 d-flex flex-column">
                                    <h5 className="register-section-title">Date operator economic</h5>
                                    <Row className="mt-3">
                                        <Col className="mb-3" xs={12} md={6}>
                                            <Field id="companyName" name="companyName" placeholder="Denumire societate"  render = {() => 
                                            <Input label='Denumire societate' mbZero onBlur={()=> {props.setFieldTouched('companyName');}}
                                              onChange={(e:any) => {e.target.value.length !== 0 && props.setFieldValue('companyName', e.target.value); 
                                              e.target.value.length === 0 && props.setFieldValue('companyName', '');}}/>}/>
                                            <ErrorMessage name="companyName" render={msg => <div style={{color: 'red', fontWeight: 'bold'}}>{msg}</div>}/>
                                        </Col>
                                        <Col className="mb-3" xs={12} md={6}>
                                            <Field id="cui" name="cui" placeholder="Cod de inregistrare fiscala"  render = {() => 
                                            <Input label='Cod de inregistrare fiscala' mbZero onBlur={()=> {props.setFieldTouched('cui');}}
                                              onChange={(e:any) => {e.target.value.length !== 0 && props.setFieldValue('cui', e.target.value); 
                                              e.target.value.length === 0 && props.setFieldValue('cui', '');}}/>}/>
                                            <ErrorMessage name="cui" render={msg => <div style={{color: 'red', fontWeight: 'bold'}}>{msg}</div>}/>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="mb-3" xs={12} md={6}>
                                            <Field id="orc" name="orc" placeholder="Nr. Registrul Comertului" render = {() => 
                                            <Input label='Nr. Registrul Comertului' mbZero onBlur={()=> {props.setFieldTouched('orc');}}
                                              onChange={(e:any) => {e.target.value.length !== 0 && props.setFieldValue('orc', e.target.value); 
                                              e.target.value.length === 0 && props.setFieldValue('orc', '');}}/>}/>
                                            <ErrorMessage name="orc" render={msg => <div style={{color: 'red', fontWeight: 'bold'}}>{msg}</div>}/>
                                        </Col>
                                        <Col className="mb-3" xs={12} md={6}>
                                            <Field id="ediEdibAuthorization" name="ediEdibAuthorization" placeholder="Nr. Autorizatie EDI/EDIB" render = {() => 
                                            <Input label='Nr. Autorizatie EDI/EDIB' mbZero onBlur={()=> {props.setFieldTouched('ediEdibAuthorization');}}
                                              onChange={(e:any) => {e.target.value.length !== 0 && props.setFieldValue('ediEdibAuthorization', e.target.value); 
                                              e.target.value.length === 0 && props.setFieldValue('ediEdibAuthorization', '');}}/>}/>
                                            <ErrorMessage name="ediEdibAuthorization" render={msg => <div style={{color: 'red', fontWeight: 'bold'}}>{msg}</div>}/>
                                        </Col>
                                    </Row>
                                </div>

                                <div className="d-flex flex-column">
                                    <h5 className="register-section-title">Canale de comunicare pentru modificările de pe cont</h5>
                                    <Row className="mb-2">
                                        <Row sm={12} className="d-flex">
                                            <Col xs={4} className="d-flex align-items-center checkbox-container">
                                                <Field type="checkbox" name="emailNotifications" className="create-account-checkbox"/>
                                                <span className="checkbox-span">Email</span>
                                            </Col>
                                            <Col xs={4} className="d-flex align-items-center checkbox-container">
                                                <Field type="checkbox" name="smsNotifications" className="create-account-checkbox"/>
                                                <span className="checkbox-span">SMS</span>
                                            </Col>
                                            {/*<ErrorMessage name="email" render={msg => <div style={{color: 'red', fontWeight: 'bold'}}>{msg}</div>}/>*/}
                                        </Row>
                                    </Row>
                                </div>

                                <div className="d-flex flex-column mt-4">
                                    <h5 className="register-section-title">Date cont</h5>
                                    <Row className="mt-3">
                                        <Col className="mb-3" sm={12} md={6}>
                                            <Field id="email" name="email" placeholder="Email" render = {() => 
                                            <Input label='Email' mbZero onBlur={()=> {props.setFieldTouched('email');}}
                                              onChange={(e:any) => {e.target.value.length !== 0 && props.setFieldValue('email', e.target.value); 
                                              e.target.value.length === 0 && props.setFieldValue('email', '');}}/>}/>
                                            <ErrorMessage name="email" render={msg => <div style={{color: '#ea1c0a', fontWeight: 'bold'}}>{msg}</div>}/>
                                        </Col>
                                        <Col className="mb-3" sm={12} md={6}>
                                            <Field id="emailConfirmation" name="emailConfirmation" placeholder="Confirmă Email" render = {() => 
                                            <Input label='Confirmă Email' mbZero onBlur={()=> {props.setFieldTouched('emailConfirmation');}}
                                              onChange={(e:any) => {e.target.value.length !== 0 && props.setFieldValue('emailConfirmation', e.target.value); 
                                              e.target.value.length === 0 && props.setFieldValue('emailConfirmation', '');}}/>}/>
                                            <ErrorMessage name="emailConfirmation" render={msg => <div style={{color: '#ea1c0a', fontWeight: 'bold'}}>{msg}</div>}/></Col>
                                    </Row>
                                    <Row>
                                        <Col className="mb-3" sm={12} md={6}>
                                            <Field id="password" name="password" placeholder="Parolă" type="password" autoComplete="new-password" render = {() => 
                                            <Input label='Parolă' mbZero type='password' onBlur={()=> {props.setFieldTouched('password');}}
                                              onChange={(e:any) => {e.target.value.length !== 0 && props.setFieldValue('password', e.target.value); 
                                              e.target.value.length === 0 && props.setFieldValue('password', '');}}/>}/>
                                            <ErrorMessage name="password" render={msg => <div style={{color: '#ea1c0a', fontWeight: 'bold'}}>{msg}</div>}/>
                                            <PasswordStrengthBar password={props.values.password} scoreWords={['Puterea parolei: Slabă', 'Puterea parolei: Slabă', 'Puterea parolei: Medie', 'Puterea parolei: Bună', 'Puterea parolei: Puternică']} shortScoreWord={'Puterea parolei: Slabă'}/>
                                        </Col>
                                        <Col className="mb-3" sm={12} md={6}>
                                            <Field id="passwordConfirmation" name="passwordConfirmation" placeholder="Confirmă parola" type="password" autoComplete="new-password" render = {() => 
                                            <Input label='Confirmă parola' mbZero type='password' onBlur={()=> {props.setFieldTouched('passwordConfirmation');}}
                                              onChange={(e:any) => {e.target.value.length !== 0 && props.setFieldValue('passwordConfirmation', e.target.value); 
                                              e.target.value.length === 0 && props.setFieldValue('passwordConfirmation', '');}}/>}/>
                                            <ErrorMessage name="passwordConfirmation" render={msg => <div style={{color: '#ea1c0a', fontWeight: 'bold'}}>{msg}</div>}/>
                                        </Col>
                                    </Row>
                                </div>

                                <div className="d-flex flex-column">
                                    <Row className="mb-2-mt-3">
                                        <Col sm={12} md={6}>
                                            <div className="d-flex">
                                                <LoadCanvasTemplateNoReload id='oe' />
                                                <FontAwesomeIcon
                                                    icon={faRotate}
                                                    style={{width: '18px', height: '18px', cursor: 'pointer', color: '#ea1c0a'}}
                                                    onClick={() => loadCaptchaEngine('6', 'white', 'black', '', 'oe')}
                                                />
                                            </div>
                                            <div>
                                                <Input label='Cod securitate' mbZero id="user_captcha_input" name="user_captcha_input" type="text" autoComplete="false" />
                                                {/* <input placeholder="Cod securitate" id="user_captcha_input" name="user_captcha_input" type="text" /> */}
                                                </div>
                                            {error !== '' && <div style={{color: '#ea1c0a', fontWeight: 'bold'}}>{error}</div>}
                                        </Col>
                                    </Row>
                                </div>

                            </div>
                            <div className="d-flex align-items-center checkbox-container mt-2">
                                <Field type="checkbox" checked={terms} onChange={() => setTerms(prev => !prev)} className="create-account-checkbox"/>
                                <span className="checkbox-span">Sunt de acord cu <a href={`${OLD_SITE_URL}/conditii-de-utilizare`} target="_blank" className="link link--download red download-link-text" rel="noreferrer">termenii și condițiile.</a></span>
                            </div>

                            <Row className="mt-2 mb-2">
                                {resError !== '' && <div style={{color: '#ea1c0a', fontWeight: 'bold'}}>{resError}</div>}
                                {
                                    syncErrors.map((error: string, index: number) => (
                                        <div key={index} style={{color: '#ea1c0a', fontWeight: 'bold'}}>{error}</div>
                                    ))
                                }
                                {validationError && <div style={{ color: '#ea1c0a', fontWeight: 'bold' }}>{validationError}</div>}
                                {resSuccess === 'success' && <div style={{color: '#ea1c0a', fontWeight: 'bold'}}>Un mail a fost trimis pentru activare!</div>}
                            </Row>

                            <div className="mb-4 buttons-container d-flex flex-row align-items-center">
                                {terms ? <Button type="submit">Creare Cont</Button> : <Button disabled type="submit">Creare Cont</Button>}
                            </div>
                        </Form>
                    </Fragment>
                )}
            </Formik>
        </Fragment>
    );
};

export default OperatorEconomic;