import React from 'react';
import { DelgazWrapper } from '@components/global/DelgazWrapper';
import AuthComponent from '@components/auth/AuthComponent';

export const Auth = () => {
    return (
        <DelgazWrapper>
            <AuthComponent />
        </DelgazWrapper>
    );
};