import {Proxy, proxy, proxyPost, proxyPostOldSite} from '@api/azureProxy';
import { store } from '@lib/store';
import { proxy as kenticoProxy, Proxy as KenticoProxy } from '@api/kenticoProxy';
import { kenticoTemplatesUrl } from '@constants/ApiUrl';
import { AxiosRequestConfig } from 'axios';

export function login(payload: any) {
    // const proxyData: Proxy = {
    //     method: 'POST',
    //     endPoint: 'authentication/User/login',
    //     data: payload,
    //     withAuthBearer: false
    // };

    // return proxy(proxyData);
    return proxyPost('authentication/User/login', payload);
}

export function validateOtp(payload: any) {
    return proxyPost('authentication/User/validate_otp', payload);
}

export function changePassword(payload: any) {

    return proxyPost('authentication/User/forced_change_password', payload);
}

export function validateAccount(payload: any) {

    return proxyPost('authentication/User/validate_account', payload);
}

export function createAccount(payload: any) {

    return proxyPost('authentication/User/create_account', payload);
}

export function passwordChange(payload: any) {
    const state = store.getState();

    const proxyData: Proxy = {
        method: 'POST',
        endPoint: '/authentication/User/change_password',
        data: payload,
        withAuthBearer: true,
    };

    return proxy(proxyData, {
        headers: {
            'Authorization': 'Bearer ' + state.auth.token,
            'Access-Control-Allow-Headers': 'Authorization',
            'Access-Control-Allow-Credentials': true,
            'access-control-allow-methods': 'POST',
        }
    });
    // return proxyPost('authentication/User/change_password', payload);
}

export function resetPassword(payload: any) {
    const proxyData: Proxy = {
        method: 'GET',
        endPoint: `/authentication/User/reset_password?email=${payload.email}`,
        data: payload,
        withAuthBearer: false
    };

    return proxy(proxyData);
}

export function requestResetPassword(payload: any) {

    return proxyPost('authentication/User/reset_password', payload);
}

export function getSupplierEEPortofolio(payload: any) {
    const state = store.getState();
    const proxyData: Proxy = {
        method: 'GET',
        endPoint: '/masterdata/Supplier/supplier_portofolio_ee',
        data: payload,
        withAuthBearer: true
    };
    let params: any = {
        pageNumber: payload.pageNumber ?? 1,
        pageSize: payload.pageSize ?? 10,
        utility: payload.utility,
        pod: payload.pod,
        clc: payload.clc,
        countyId: payload.countyId,
        localityId: payload.localityId,
        partnerCode: payload.partnerCode
      };
      if (payload.meterTypes) {
        params.meterTypes = payload.meterTypes;
      }
      if (payload.mmrOnly != null) {
        params.mmrOnly = payload.mmrOnly;
      }

    return proxy(proxyData, {
        params: params,
        headers: {
            Authorization: 'Bearer ' + state.auth.token,
            'Access-Control-Allow-Headers': 'Authorization',
            'Access-Control-Allow-Credentials': true,
            'access-control-allow-methods': 'GET'
          }
    });
}

export function getSupplierGNPortofolio(payload: any) {
    const state = store.getState();
    const proxyData: Proxy = {
      method: 'GET',
      endPoint: '/masterdata/Supplier/supplier_portofolio_gn',
      data: payload,
      withAuthBearer: true
    };

    let params: any = {
        pageNumber: payload.pageNumber ?? 1,
        pageSize: payload.pageSize ?? 10,
        utility: payload.utility,
        pod: payload.pod,
        clc: payload.clc,
        countyId: payload.countyId,
        localityId: payload.localityId,
        partnerCode: payload.partnerCode
      };
      if (payload.meterTypes) {
        params.meterTypes = payload.meterTypes;
      }
      if (payload.mmrOnly != null) {
        params.mmrOnly = payload.mmrOnly;
      }
  
    return proxy(proxyData, {
      params: params,
      headers: {
        Authorization: 'Bearer ' + state.auth.token,
        'Access-Control-Allow-Headers': 'Authorization',
        'Access-Control-Allow-Credentials': true,
        'access-control-allow-methods': 'GET'
      }
    });
  }

export function postSupplierEEPortofolioExport(payload: any) {
    const state = store.getState();
    const proxyData: Proxy = {
        method: 'POST',
        endPoint: '/masterdata/Supplier/supplier_portofolio_ee_export',
        data: payload,
        withAuthBearer: true
    };

    return proxy(proxyData, {
        params: {
            utility: payload.utility,
            pod: payload.pod,
            clc: payload.clc,
            countyId: payload.countyId,
            localityId: payload.localityId,
            partnerCode: payload.partnerCode
        },
        headers: {
            Authorization: 'Bearer ' + state.auth.token,
            'Access-Control-Allow-Headers': 'Authorization',
            'Access-Control-Allow-Credentials': true,
            'access-control-allow-methods': 'POST'
          },
        responseType: 'arraybuffer'
    });
}

export function postSupplierGNPortofolioExport(payload: any) {
    const state = store.getState();
    const proxyData: Proxy = {
        method: 'POST',
        endPoint: '/masterdata/Supplier/supplier_portofolio_gn_export',
        data: payload,
        withAuthBearer: true
    };

    return proxy(proxyData, {
        params: {
            utility: payload.utility,
            pod: payload.pod,
            clc: payload.clc,
            countyId: payload.countyId,
            localityId: payload.localityId,
            partnerCode: payload.partnerCode
        },
        headers: {
            Authorization: 'Bearer ' + state.auth.token,
            'Access-Control-Allow-Headers': 'Authorization',
            'Access-Control-Allow-Credentials': true,
            'access-control-allow-methods': 'POST'
          },
        responseType: 'arraybuffer'
    });
}

export function postClientPortofolioExport(payload: any) {
    const state = store.getState();
    const proxyData: Proxy = {
        method: 'POST',
        endPoint: '/masterdata/Supplier/client_portofolio_export',
        data: payload,
        withAuthBearer: true
    };

    return proxy(proxyData, {
        params: {
            utility: payload.utility,
            pod: payload.pod,
            clc: payload.clc,
            countyId: payload.countyId,
            localityId: payload.localityId,
            partnerCode: payload.partnerCode
        },
        headers: {
            Authorization: 'Bearer ' + state.auth.token,
            'Access-Control-Allow-Headers': 'Authorization',
            'Access-Control-Allow-Credentials': true,
            'access-control-allow-methods': 'POST'
          },
        responseType: 'arraybuffer'
    });
}

export function postSupplierAnexa2Export(payload: any) {
    const state = store.getState();
    const proxyData: Proxy = {
        method: 'POST',
        endPoint: '/masterdata/Supplier/anexa2_export',
        data: payload,
        withAuthBearer: true
    };

    return proxy(proxyData, {
        params: {
            utility: payload.utility,
            pod: payload.pod,
            clc: payload.clc,
            countyId: payload.countyId,
            localityId: payload.localityId,
        },
        headers: {
            Authorization: 'Bearer ' + state.auth.token,
            'Access-Control-Allow-Headers': 'Authorization',
            'Access-Control-Allow-Credentials': true,
            'access-control-allow-methods': 'POST'
          },
        responseType: 'arraybuffer'
    });
}

// export function postSupplierConventiiConsumExportFromOldSite(payload: any) {
//     return proxyPostOldSite(
//       `api/export-conventii-consum?pod=${payload.pod}&clc=${payload.clc}&countyId=${payload.countyId}&localityId=${payload.localityId}&token=${payload.token}`,
//       {},
//       {
//         responseType: 'arraybuffer'
//       }
//     );
//   }

export function getClientPortofolio(payload: any) {
    const state = store.getState();

    const proxyData: Proxy = {
      method: 'GET',
      endPoint: '/masterdata/Supplier/client_portofolio',
      data: payload,
      withAuthBearer: true
    };
  
    let params: any = {
      pageNumber: payload.pageNumber ?? 1,
      pageSize: payload.pageSize ?? 10,
      utility: payload.utility,
      pod: payload.pod,
      clc: payload.clc,
      countyId: payload.countyId,
      localityId: payload.localityId,
      partnerCode: payload.partnerCode
    };
    if (payload.meterTypes) {
      params.meterTypes = payload.meterTypes;
    }
    if (payload.mmrOnly != null) {
        params.mmrOnly = payload.mmrOnly;
    }
  
    return proxy(proxyData, {
      params: params,
      headers: {
        Authorization: 'Bearer ' + state.auth.token,
        'Access-Control-Allow-Headers': 'Authorization',
        'Access-Control-Allow-Credentials': true,
        'access-control-allow-methods': 'GET'
      }
    });
}

export function consumptionPoints(payload: any) {
    const proxyData: Proxy = {
        method: 'GET',
        endPoint: '/masterdata/Supplier/consumption_points',
        data: payload,
        withAuthBearer: false
    };

    return proxy(proxyData, {
        params: {
            pageNumber: 1,
            pageSize: 10,
            pod: payload.pod,
            countyId: payload.countyId,
            localityId: payload.localityId,
        }
    });
}

export function saveConsumptionPoints(payload: any) {
    return proxyPost('/masterdata/Supplier/save_client_consumption_points', payload);
}

export function syncCreateAccount(payload: any) {
    const state = store.getState();

    return proxyPost(`${state.options.oldSite}/inregistrare-sync`, payload, {
        headers: {
            removeSub: true,
            'Content-Security-Policy': `connect-src 'self' ${state.options.oldSite}`
        }
    });
}

export function syncRemoveUser(payload: any) {
    const state = store.getState();

    return proxyPost(`${state.options.oldSite}/remove-user`, payload, {
        headers: {
            removeSub: true,
            'Content-Security-Policy': `connect-src 'self' ${state.options.oldSite}`
        }
    });
}

export function syncValidateAccount(payload: any) {
    const state = store.getState();

    const proxyData: Proxy = {
        method: 'GET',
        endPoint: `${state.options.oldSite}/activare-cont-sync/${payload}`,
        data: payload,
        withAuthBearer: false,
    };

    return proxy(proxyData, {
        headers: {
            removeSub: true,
            sync: true,
            'Content-Security-Policy': `connect-src 'self' ${state.options.oldSite}`
        }
    });
}

export function TFAValidatePassword(payload: any) {
    const state = store.getState();

    const proxyData: Proxy = {
        method: 'POST',
        endPoint: '/authentication/User/validate_password',
        data: payload,
        withAuthBearer: true,
    };

    return proxy(proxyData, {
        headers: {
            'Authorization': 'Bearer ' + state.auth.token,
            'Access-Control-Allow-Headers': 'Authorization',
            'Access-Control-Allow-Credentials': true,
            'access-control-allow-methods': 'POST',
        }
    });
}

export function createTFACode(payload: any) {
    const state = store.getState();

    const proxyData: Proxy = {
        method: 'POST',
        endPoint: '/authentication/User/create_tfa_code',
        data: payload,
        withAuthBearer: true,
    };

    return proxy(proxyData, {
        headers: {
            'Authorization': 'Bearer ' + state.auth.token,
            'Access-Control-Allow-Headers': 'Authorization',
            'Access-Control-Allow-Credentials': true,
            'access-control-allow-methods': 'POST',
        }
    });
}

export function validateTFACode(payload: any) {
    const state = store.getState();

    const proxyData: Proxy = {
        method: 'POST',
        endPoint: '/authentication/User/validate_tfa_code',
        data: payload,
        withAuthBearer: true,
    };

    return proxy(proxyData, {
        headers: {
            'Authorization': 'Bearer ' + state.auth.token,
            'Access-Control-Allow-Headers': 'Authorization',
            'Access-Control-Allow-Credentials': true,
            'access-control-allow-methods': 'POST',
        }
    });
}

export function accountDetailsToken(payload: any) {
    return proxyPost('authentication/User/account_details_token', payload);
}

export function getAccountDetails() {
    const state = store.getState();

    const proxyData: Proxy = {
        method: 'GET',
        endPoint: '/authentication/User/account_details',
        withAuthBearer: true,
    };

    return proxy(proxyData, {
        headers: {
            'Authorization': 'Bearer ' + state.auth.token,
            'Access-Control-Allow-Headers': 'Authorization',
            'Access-Control-Allow-Credentials': true,
            'access-control-allow-methods': 'POST',
        }
    });
    // return proxyPost('authentication/User/change_password', payload);
}

export function editAccount(payload: any) {
    const state = store.getState();

    const proxyData: Proxy = {
        method: 'POST',
        endPoint: '/authentication/User/account_details',
        data: payload,
        withAuthBearer: true,
    };

    return proxy(proxyData, {
        headers: {
            'Authorization': 'Bearer ' + state.auth.token,
            'Access-Control-Allow-Headers': 'Authorization',
            'Access-Control-Allow-Credentials': true,
            'access-control-allow-methods': 'POST',
        }
    });
}

export function chageUsernameRequest(payload: any) {
    const state = store.getState();

    const proxyData: Proxy = {
        method: 'POST',
        endPoint: '/authentication/User/change_username_request',
        data: payload,
        withAuthBearer: true,
    };

    return proxy(proxyData, {
        headers: {
            'Authorization': 'Bearer ' + state.auth.token,
            'Access-Control-Allow-Headers': 'Authorization',
            'Access-Control-Allow-Credentials': true,
            'access-control-allow-methods': 'POST',
        }
    });
}

export function chageUsernameIdentity(payload: any) {
    const proxyData: Proxy = {
        method: 'POST',
        endPoint: '/authentication/User/change_username',
        data: payload,
        withAuthBearer: false,
    };

    return proxy(proxyData);
}


export function chageUsernameOldSite(payload: any) {
    return proxyPostOldSite(`api/change_username?newUsername=${payload.email}&oldUsername=${payload.currentEmail}&token=${payload.token}&isRollback=${payload.isRollback}`);
}

export function verifySupplier(payload: any) {
    const proxyData: Proxy = {
        method: 'GET',
        endPoint: '/masterdata/Supplier/verify_supplier',
        withAuthBearer: false
    };

    return proxy(proxyData, {
        params: {
            email: payload.email,
            code: payload.code,
            license: payload.license,
            licenseType: payload.licenseType,
            userId: payload.userId
        }
    });
}

export function addLicenseToSupplier(payload: any) {
    const state = store.getState();
    const proxyData: Proxy = {
        method: 'POST',
        endPoint: `/authentication/User/account_add_supplier?licenseNumber=${payload.licenseNumber}&supplierCode=${payload.supplierCode}&utility=${payload.utility}`,
        withAuthBearer: true
    };

    return proxy(proxyData, {
        headers: {
            'Authorization': 'Bearer ' + state.auth.token,
            'Access-Control-Allow-Headers': 'Authorization',
            'Access-Control-Allow-Credentials': true,
            'access-control-allow-methods': 'POST',
        }
    });
}

export function addLicenseToSupplierOldSite(payload: any) {
    return proxyPostOldSite(`adauga_licenta/${payload.legacyId}/${payload.licenseNumber}/${payload.supplierCode}/${payload.utility}/${payload.token}`);
}


export function removeLicenseFromSupplier(payload: any) {
    const state = store.getState();
    const proxyData: Proxy = {
        method: 'POST',
        endPoint: `/authentication/User/account_remove_supplier?licenseNumber=${payload.licenseNumber}&supplierCode=${payload.supplierCode}&utility=${payload.utility}`,
        withAuthBearer: true
    };

    return proxy(proxyData, {
        headers: {
            'Authorization': 'Bearer ' + state.auth.token,
            'Access-Control-Allow-Headers': 'Authorization',
            'Access-Control-Allow-Credentials': true,
            'access-control-allow-methods': 'POST',
        }
    });
}

export function unlockAccount(payload: any) {
    return proxyPost('authentication/User/unlock_account', payload);
}

//code=1001662144&licenseType=2&userid=xxxxx
export function getSupplierByLicenceId(payload: any) {
    const state = store.getState();
    const proxyData: Proxy = {
        method: 'GET',
        endPoint: `/masterdata/Supplier/verify_supplier?code=${payload.code}&&licenseType=${payload.licenseType}&&userid=${payload.userid}`,
        data: payload,
        withAuthBearer: false
    };
    return proxy(proxyData, {
        headers: {
            'Authorization': 'Bearer ' + state.auth.token,
            'Access-Control-Allow-Headers': 'Authorization',
            'Access-Control-Allow-Credentials': true,
            'access-control-allow-methods': 'POST',
        }
    });
}

export function getMeasuredValuesPartnerFiles() {
    const state = store.getState();
    const proxyData: Proxy = {
        method: 'GET',
        endPoint: '/meter-reading/MeasuredValuesPartners/files',
        withAuthBearer: true
    };
    return proxy(proxyData, {
        headers: {
            'Authorization': 'Bearer ' + state.auth.token,
            'Access-Control-Allow-Headers': 'Authorization',
            'Access-Control-Allow-Credentials': true,
            'access-control-allow-methods': 'GET',
        }
    });
}

export async function getTemplatesCreateAccount() {
    const state = store.getState();
  
    const proxyData: KenticoProxy = {
      method: 'GET',
      endPoint: `${kenticoTemplatesUrl}?path=/Template-uri/Creare-cont`,
      withAuthBearer: false
    };
  
    const options: AxiosRequestConfig = {
      headers: {
        XApiKey: state.options.xApiKey ?? ''
      }
    };
  
    return kenticoProxy<any>(proxyData, options);
  }