import React from 'react';
import { DelgazWrapper } from '@components/global/DelgazWrapper';
import AbonareIntreruperiProgramateEE from '@components/intreruperiProgramateEE/AbonareIntreruperiProgramateEE';

export const AbonareIntreruperiProgramateEEComponent = (props: any): JSX.Element => {
  return (
    <DelgazWrapper>
      <AbonareIntreruperiProgramateEE />
    </DelgazWrapper>
  );
};
