import React from 'react';
import { DelgazWrapper } from '@components/global/DelgazWrapper';
import DecRecIncarcareInMasa from '@components/decRec/incarcareInMasa/DecRecIncarcareInMasa';

export const DecRecIncarcareInMasaComponent = () => {
  return (
    <DelgazWrapper>
      <DecRecIncarcareInMasa />
    </DelgazWrapper>
  );
};
