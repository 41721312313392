import React from 'react';
import { DelgazWrapper } from '@components/global/DelgazWrapper';
import UnlockAccount from '@components/auth/unlockAccount/UnlockAccount';

export const UnlockAccountComponent = () => {
    return (
        <DelgazWrapper>
            <UnlockAccount />
        </DelgazWrapper>
    );
};