import React from 'react';
import { DelgazWrapper } from '@components/global/DelgazWrapper';
import InfoPVRClient from '@components/pvr/InfoPVRClient';

export const PVRInfoClient = () => {
    return (
        <DelgazWrapper>
            <InfoPVRClient />
        </DelgazWrapper>
    );
};