import {Proxy, proxy} from '@api/azureProxy';

export function getCaptcha() {
    const proxyData: Proxy = {
        method: 'GET',
        endPoint: '/utils/Captcha/get',
        withAuthBearer: false
    };

    return proxy(proxyData);
}