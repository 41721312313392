import React from 'react';
import { DelgazWrapper } from '@components/global/DelgazWrapper';
import DetaliiAchizitie from '@components/dev/Components/Achizitii/DetaliiAchizitie';
import {AchizitieKentico} from '@type/kentico-types';

export type DetaliiAchizitieProps = { kentico: AchizitieKentico };

export const Detalii = (props: DetaliiAchizitieProps): JSX.Element => {
    return (
        <DelgazWrapper>
            <DetaliiAchizitie {...props}/>
        </DelgazWrapper>
    );
};