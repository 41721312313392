import React, { ReactElement } from 'react';
import { Button, Modal } from 'react-bootstrap';

type ModalDownloadDocumentsProps = {
  showModal: boolean;
  modalTitle: string;
  handleCloseModal: () => void;
  children: ReactElement<any, any> | any;
};

const ModalDownloadDocuments = (props: ModalDownloadDocumentsProps) => {
  return (
    <Modal  size='lg'fullscreen="lg-down" centered show={props.showModal} onHide={props.handleCloseModal}>
    <Modal.Title>
      <div className="p-3" style={{ color: '#ea1c0a', fontWeight: 'bold' }}>
        <span>{props.modalTitle}</span>
      </div>
    </Modal.Title>
    <Modal.Body>
      <div>{props.children}</div>
    </Modal.Body>
    <Modal.Footer>
      <Button variant="primary" onClick={props.handleCloseModal}>
        Înapoi
      </Button>
    </Modal.Footer>
  </Modal>
  );
};

export default ModalDownloadDocuments;
