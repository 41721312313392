import { faEnvelope, faHome, faPhone } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { BusinessCardKentico } from '@type/kentico-types';

export const BusinessCardComponent = (props: { kentico: BusinessCardKentico }) => {
  const { nume, functie, telefon, email, culoare, margin, descriere, adresa } = props.kentico;
  const isRedBackground = culoare === '#ea1b0a' || culoare === 'red';
  const textColor = isRedBackground ? 'white' : 'black';
  const iconColor = isRedBackground ? 'white' : '#ea1b0a';

  return (
    <div className={`m-${margin ?? '5'}`}>
      <div className="business-card-container">
        <div
          className="business-card"
          style={{
            maxWidth: '425px',
            width: '100%',
            background: culoare,
            color: textColor,
            padding: '18px'
          }}
        >
          <h4 style={{ margin: '0', marginBottom: '5px', fontSize: '29px', fontWeight: 'bold' }}>{nume}</h4>

          {functie && <p style={{ marginBottom: '15px', fontSize: '18px' }}>{functie}</p>}

          {adresa && (
            <p style={{ marginBottom: '5px', fontSize: '18px' }}>
              <FontAwesomeIcon icon={faHome} style={{ width: '20px', paddingTop: '3px', marginRight: '8px' }} color={iconColor} />
              {adresa}
            </p>
          )}

          {email && (
            <a
              className="d-flex flex-row"
              href={`mailto:${email}`}
              style={{
                marginBottom: '5px',
                textDecoration: 'none',
                color: textColor
              }}
            >
              <FontAwesomeIcon icon={faEnvelope} style={{ width: '20px', paddingTop: '3px', marginRight: '8px' }} color={iconColor} />
              <p style={{ margin: '0', maxWidth: '75%', fontSize: '18px' }}>{email}</p>
            </a>
          )}

          {telefon && (
            <a
              className="d-flex flex-row"
              href={`tel:${telefon}`}
              style={{
                textDecoration: 'none',
                color: textColor
              }}
            >
              <FontAwesomeIcon icon={faPhone} style={{ width: '20px', paddingTop: '3px', marginRight: '8px' }} color={iconColor} />
              <p style={{ margin: '0', maxWidth: '75%', fontSize: '18px' }}>{telefon}</p>
            </a>
          )}

          {descriere && (
            <div
              className="d-flex flex-row"
              style={{
                marginLeft: '28px',
                textDecoration: 'none',
                color: textColor
              }}
            >
              <p style={{ margin: '0', maxWidth: '75%', fontSize: '15px' }}>{descriere}</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
