import React from 'react';
import { DelgazWrapper } from '@components/global/DelgazWrapper';
import Form from '@components/dev/Components/Achizitii/Form';

export const FormAchizitie = (): JSX.Element => {
    return (
        <DelgazWrapper>
            <Form/>
        </DelgazWrapper>
    );
};