import { File } from '@components/common/UploadFile/UploadFile';
import { useEffect, useState } from 'react';

type Base64FileListReadOnlyProps = {
  label: string;
  viewFilesValues?: any;
};

const Base64FileListReadOnly = ({ label, viewFilesValues }: Base64FileListReadOnlyProps) => {
  const [filesView, setFilesView] = useState<any>([]);

  //edit case
  useEffect(() => {
    if (viewFilesValues && viewFilesValues.length > 0) {
      let list: File[] = [];
      viewFilesValues.forEach((file: any) => {
        list.push({
          uuid: file.blobName,
          fileUrl: file.documentUrl,
          name: file.originalFileName,
          containerName: file.containerName
        });
      });
      setFilesView(list);
    }
  }, [viewFilesValues]);

  const renderViewList = () => {
    return (
      <div
        style={{
          backgroundColor: '#F1F0EF',
          width: '100%',
          marginTop: '-30px',
          minHeight: '120px',
          height: 'auto',
          borderRadius: '0.5em'
        }}
      >
        <div style={{ backgroundColor: '#F1F0EF', height: '14px' }}></div>
        <hr className="hr" />

        <div style={{ margin: '0.25em', paddingBottom: '0.25em' }}>
          <p style={{ margin: '4px', paddingLeft: '10px' }}>Fișiere existente</p>

          {filesView.map((fe: any, index: number) => (
            <div
              key={index}
              style={{
                backgroundColor: '#ffff',
                margin: '0.75em',
                height: '45px',
                borderRadius: '0.5em'
              }}
            >
              <div
                style={{
                  position: 'static',
                  display: 'flex',
                  alignItems: 'center',
                  padding: '0.5625em',
                  margin: 0,
                  color: '#ea1c0a',
                  overflow: 'hidden',
                  height: '45px'
                }}
              >
                <div
                  onClick={() => {
                    window.location.href = fe.fileUrl;
                  }}
                >
                  <a download style={{ display: 'flex' }} className="ms-2 brand-link-download link link--download pointer">
                    &nbsp;
                  </a>
                </div>
                <p
                  style={{
                    marginLeft: '8px',
                    marginBottom: 0,
                    fontSize: '12px',
                    lineHeight: 'normal',
                    whiteSpace: 'nowrap' /* Prevents line breaks */,
                    overflow: 'hidden' /* Hides any content beyond the width of the container */,
                    textOverflow: 'ellipsis',
                    fontWeight: 450,
                    textRendering: 'optimizeLegibility'
                  }}
                >
                  {fe.name}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };

  return (
    <>
      <label style={{ position: 'relative', left: '5px' }}>
        <div>{label}</div>
      </label>
      {renderViewList()}
    </>
  );
};

export default Base64FileListReadOnly;
