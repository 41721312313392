import React, { useState, useEffect } from 'react';
import { ValueToSet } from '../PageWrapper';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

type StringProps = {
  name: string;
  label: string;
  value?: string | any;
  itemTypeId?: number;
  onChange?: (e: ValueToSet) => void;
  disabled?: boolean;
};

const Html = ({ name, label, onChange, itemTypeId, value, disabled }: StringProps) => {
  const [currentValue, setCurrentValue] = useState('');
  const [firstValueSet, setFirstValueSet] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      onChange && onChange({ name: name, value: currentValue, itemTypeId } as ValueToSet);
    }, 50);
  }, [currentValue]);

  useEffect(() => {
    if (value && !firstValueSet) {
      setCurrentValue(value);
      setFirstValueSet(true);
    }
  }, [value]);

  //eslint-disable-next-line
  const config = {
    buttons: ['bold', 'italic', 'underline', 'link', 'unlink', 'source'],
    tabIndex: 1
  };

  return (
    <>
      <label className="calendar_label">
        <div>{label}</div>
      </label>
      <ReactQuill theme="snow" value={currentValue} onChange={setCurrentValue} className={'react-quill mb-2'} />
    </>
  );
};

export default Html;
