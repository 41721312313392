import React from 'react';
import { DelgazWrapper } from '@components/global/DelgazWrapper';
import NotificationDetails from '@components/userNotifications/NotificationDetails';

export const UserNotificationDetails = (): JSX.Element => {
    return (
        <DelgazWrapper>
            <NotificationDetails />
        </DelgazWrapper>
    );
};