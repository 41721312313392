import React, { Fragment, useEffect, useState } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { Dropdown, DropdownOptionType } from '@components/common/Dropdown';
import { GetCounties, GetLocalities } from '@api/geoApi';
import { toggleLoader } from '@actions/LoaderActions';
import { useDispatch } from 'react-redux';
import MaskedInput from '@components/common/Input/MaskedInput';

type CountyTemplate = {
    countyId: number,
    countyName: string,
    countyCode: string
};

type LocalityTemplate = {
    localityId: number,
    localityName: string,
    localityCode: string
};

const Verify = (props: {
    onClick: any,
    pod: any,
    handlePod: any,
    county: any,
    handleCounty: any,
    locality: any,
    handleLocality: any,
    withLocalityCode?: boolean,
    utility?: string
}) => {
    const [counties, setCounties] = useState<CountyTemplate[]>([]);
    const [localities, setLocalities] = useState<LocalityTemplate[]>([]);
    const dispatch = useDispatch();

    const {
        onClick,
        pod,
        handlePod,
        county,
        handleCounty,
        locality,
        handleLocality,
        withLocalityCode,
        utility
    } = props;

    useEffect(() => {
        mountCounties();
    }, []);


    useEffect(() => {
        county !== '' && mountLocalities();
    }, [county]);

    const mountCounties = async () => {
        dispatch(toggleLoader(true));

        await GetCounties().then(res => {
            setCounties(res);
        });

        dispatch(toggleLoader(false));
    };

    const mountLocalities = async () => {
        dispatch(toggleLoader(true));

        await GetLocalities(county).then((res) => setLocalities(res));

        dispatch(toggleLoader(false));
    };

    return (
        <Fragment>
            <Row className="d-flex justify-content-center">
                <Col xs={12} sm={3} className='mb-3'>
                    {/* <MaskedInput label="POD*" value={pod} onChange={(e) => handlePod(e.target.value)} /> */}
                    <MaskedInput
                                value={pod}
                                mbZero
                                onChange={(e: any) => {
                                  const currentValue = (e.target.value ? e.target.value.toLocaleUpperCase() : '') as string;
                                  handlePod(currentValue);
                                }}
                                utility={utility ? utility : 'EE'}
                                label="POD*"
                                mask={'0123333333'}
                              />
                </Col>
                <Col xs={12} sm={3}>
                    <Dropdown
                        options={counties.map((option, index) => (
                            {
                                id: option.countyCode,
                                name: option.countyName
                            }
                        )) as DropdownOptionType[]}
                        onChange={(value) => {
                            handleCounty(value);
                        }}
                        label={'Județ*'}
                        defaultPlaceholder={'Alege județ'}
                        displaySearch={true}
                        value={county}
                    />
                </Col>
                <Col xs={12} sm={3}>
                    <Dropdown
                        options={localities.map((option, index) => (
                            {
                                id: withLocalityCode ? option.localityCode : option.localityId,
                                name: option.localityName,
                                code: option.localityCode
                            }
                        )) as DropdownOptionType[]}
                        label={'Localitate*'}
                        onChange={(value) => {
                            handleLocality(value);
                        }}
                        defaultPlaceholder={'Alege localitatea'}
                        displaySearch={true}
                        value={locality}
                    />
                </Col>
            </Row>
            <Row className="justify-content-center mb-3">
                <Col xs={12} sm={9}>
                    <div className="triplet-separator"></div>
                </Col>
            </Row>
            <Row>
                <div className="d-flex justify-content-center">
                    <Button className="d-flex flex-row align-items-center" onClick={onClick} disabled={(pod === '' || county === '' || locality === '')}>
                        {/* <FontAwesomeIcon icon={faMagnifyingGlass} style={{ marginRight: '5px' }} /> */}
                        <span>Căutare</span>
                    </Button>
                </div>
            </Row>
        </Fragment>
    );
};

export default Verify;