export function isObjectEmpty(obj: any) {
  if (!obj) return false;
  return Object.entries(obj).length === 0;
}

export function convertNullsAndUndefinedToEmptyString(currentObject: any) {
  const updatedObject = { ...currentObject };
  for (const prop in updatedObject) {
    if (updatedObject[prop] === null || updatedObject[prop] === undefined) {
      updatedObject[prop] = '';
    }
  }
  return updatedObject;
};
