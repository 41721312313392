import { Proxy, proxy } from '@api/azureProxy';
import { store } from '@lib/store';

export function getEconomicOperator() {
  const state = store.getState();

  const proxyData: Proxy = {
    method: 'GET',
    endPoint: '/masterdata/EconomicOperator/get',
    withAuthBearer: true
  };

  return proxy(proxyData, {
    headers: {
      Authorization: 'Bearer ' + state.auth.token
    }
  });
}
