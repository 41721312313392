import { store } from '@lib/store';
import axios from 'axios';

export function postCmsSendEmailRepere(payload: any) {
  const state = store.getState();

  return axios.post(`${state.options.webApiHost}/cms_api/utils/send_email_repere`, payload, {
    headers: {
      Authorization: 'Bearer ' + state.auth.token,
      'Ocp-Apim-Subscription-Key': state.options.subscriptionKey as string,
      XApiKey: state.options.xApiKey as string
    }
  });
}
