import React, { Fragment, useEffect, useState } from 'react';
import { getConventionDetails } from '@api/conventions/conventions';
import { toggleLoader } from '@actions/LoaderActions';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Button } from 'react-bootstrap';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { IState } from '@type/store';

const ConventionDetails = () => {
    const [item, setItem] = useState<any>(null);
    const [error, setError] = useState('');
    const dispatch = useDispatch();

    const OLD_SITE_URL: string | undefined = useSelector<IState>((state) => state.options.webApiHost) as string;

    useEffect(() => {
        getDetails();
    }, []);

    const getDetails = async () => {
        dispatch(toggleLoader(true));
        const url = new URL(window.location.href);
        const params = url.search;
        const searchParams = new URLSearchParams(params);

        if (searchParams.has('id')) {
            await getConventionDetails(searchParams.get('id'))
                .then((res) => {
                    if (res && res.data) {
                        setItem(res.data);
                    } else {
                        setError('Solicitarea nu a fost gasită.');
                    }
                })
                .catch((err) => setError('Solicitarea nu a fost gasită.'));
        } else {
            setError('Solicitarea nu a fost gasită.');
        }

        dispatch(toggleLoader(false));
    };

    return (
        <Fragment>
            <div className="m-3">
                            <a href={`${OLD_SITE_URL}/conventii-de-consum`}>
                                <Button>
                                    <FontAwesomeIcon icon={faChevronLeft} style={{ marginRight: '6px' }} />
                                    <span>Înapoi</span>
                                </Button>
                            </a>
                        </div>
            {item !== null &&
                <div className="m-3 convention-details">
                    <Row>
                        <Col sm={12} md={4}>
                            <div className="details-block">
                                <div className="block-header">
                                    <div className="block-title-border" />
                                    <div className="block-title">
                                        <span className="fw-bold red">{item.requestNo ? `${item.requestNo} / ${item.requestDate ? moment(item.requestDate).format('DD.MM.YYYY') : ''}`: ''}</span>
                                    </div>
                                </div>
                                <div className="block-body">
                                    <div className="property-container">
                                        <div className="property-key">
                                            Status
                                        </div>
                                        <div className="property-value">
                                            {item.statusTypeName}
                                        </div>
                                    </div>
                                    <div className="property-container">
                                        <div className="property-key">
                                            Tipul solicitării
                                        </div>
                                        <div className="property-value">
                                            {item.actionTypeName}
                                        </div>
                                    </div>
                                    <div className="property-container">
                                        <div className="property-key">
                                            Dată depunere
                                        </div>
                                        <div className="property-value">
                                            {item.sentDate ? moment(item.sentDate).format('DD.MM.YYYY') : '-'}
                                        </div>
                                    </div>
                                    <div className="property-container">
                                        <div className="property-key">
                                            Număr locuri de consum
                                        </div>
                                        <div className="property-value">
                                            {item.consumptionPoints.length}
                                        </div>
                                    </div>
                                    {(item.statusTypeName === 'Procesata' || item.statusTypeName === 'Finalizata') &&
                                    item.documentUrl &&
                                    item.actionTypeName != 'Modificare' && (
                                    <div className="property-container">
                                        <div className="property-key">Acțiuni</div>
                                        <div className="property-value">
                                        <a className="hover-red link--download pointer black text-decoration-none" href={item.documentUrl}>
                                            &nbsp;Descarcă convenția
                                        </a>
                                        </div>
                                    </div>
                                    )}
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row className="mt-4">
                        <h5>Locuri consum</h5>
                        <div className="general-table-container">
                            <table className="w-100">
                                <thead>
                                    <tr>
                                        <th>POD</th>
                                        <th>Date client</th>
                                        <th>Portofoliu</th>
                                        <th>Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {item.consumptionPoints.length > 0 && item.consumptionPoints.map((itm: any, idx: number) => (
                                        <tr key={idx}>
                                            <td><span className="fw-bold">{itm.pod}</span></td>
                                            <td>
                                                <div className="d-flex flex-column">
                                                    <span>Client: <span className="fw-bold">{itm.client}</span></span>
                                                    <span>Adresa: <span>{itm.address}</span></span>
                                                </div>
                                            </td>
                                            <td>{itm.portfolio}</td>
                                            <td>
                                                <span className="fw-bold">{itm.statusTypeName}</span>
                                            </td>
                                        </tr>
                                    ))
                                    }
                                </tbody>
                            </table>
                        </div>
                    </Row>
                </div>
            }
            {error && <div style={{ color: 'red', fontWeight: 'bold' }}>{error}</div>}
        </Fragment>
    );
};

export default ConventionDetails;