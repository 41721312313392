import React from 'react';
// import { AemWrapper } from '@components/global';
import { SocialMediaKentico } from '../../typescript/kentico-types';
import { DelgazWrapper } from '@components/global/DelgazWrapper';
import SocialMediaMenu from '@components/delgaz/Footer/SocialMediaMenu';

export type SocialMediaCompProps = { kentico: SocialMediaKentico };

export const SocialMediaComp = (props: SocialMediaCompProps): JSX.Element => {
  return (
    <DelgazWrapper>
      <SocialMediaMenu {...props} />
    </DelgazWrapper>
  );
};
