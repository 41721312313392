import { GetCounties, GetLocalities } from '@api/geoApi';
import { Dropdown, DropdownOptionType } from '@components/common/Dropdown';
import { Input } from '@components/common/Input';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { OrderFormAdressSchema } from '../../helpers/schemas';

type CountyTemplate = {
    countyId: number,
    countyName: string,
    countyCode: string
};

type LocalityTemplate = {
    localityId: number,
    localityName: string,
    localityCode: string
};

const OrderFormAdress = (props: { innerRef: any, submit?: any, formData: any, handleFormData: any }) => {
    const [counties, setCounties] = useState<CountyTemplate[]>([]);
    const [county, setCounty] = useState('');
    const [localities, setLocalities] = useState<LocalityTemplate[]>([]);
    // const [locality, setLocality] = useState<number | null>(null);

    //eslint-disable-next-line
    const { innerRef, formData, handleFormData, submit } = props;

    useEffect(() => {
        GetCounties().then(res => setCounties(res));
    }, []);

    useEffect(() => {
        county !== '' && GetLocalities(county).then((res) => setLocalities(res));
    }, [county]);

    return (
        <div>
            <Formik
                enableReinitialize={true}
                initialValues={formData}
                onSubmit={(values) => (values)}
                innerRef={innerRef}
                validateOnMount={true}
                validateOnChange={true}
                validationSchema={OrderFormAdressSchema}
            >
                {(props) => (
                    <Form>
                        <Row className="mb-3">
                            <Col>
                                <Dropdown
                                    options={counties.map((option, index) => (
                                        {
                                            id: option.countyCode,
                                            name: option.countyName
                                        }
                                    )) as DropdownOptionType[]}
                                    label={'Județ*'}
                                    defaultPlaceholder={'Alege județul'}
                                    displaySearch={true}
                                    onChange={(value) => {
                                        value && handleFormData(value, 'Judet');
                                        value && props.setFieldValue('Judet', value);
                                        value.length === 0 && props.setFieldValue('Judet', '');
                                        setCounty(value);
                                    }}
                                    value={formData.Judet}
                                    mbZero
                                />
                                <ErrorMessage name="Judet" render={msg => <div style={{ color: '#ea1c0a', fontWeight: 'bold' }}>{msg}</div>} />
                            </Col>
                            <Col>
                                <Dropdown
                                    options={localities.map((option, index) => (
                                        {
                                            id: option.localityName,
                                            name: option.localityName
                                        }
                                    )) as DropdownOptionType[]}
                                    label={'Localitate*'}
                                    defaultPlaceholder={'Alege localitatea'}
                                    displaySearch={true}
                                    onChange={(value) => {
                                        value && handleFormData(value, 'Localitate');
                                        value && props.setFieldValue('Localitate', value);
                                        value.length === 0 && props.setFieldValue('Localitate', '');
                                    }}
                                    value={formData.Localitate}
                                    mbZero
                                />
                                <ErrorMessage name="Localitate" render={msg => <div style={{ color: '#ea1c0a', fontWeight: 'bold' }}>{msg}</div>} />
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col>
                                <Field label='Strada*' id="Strada" name="Strada" placeholder="Strada*">
                                    {() =>
                                        <Input
                                            mbZero
                                            value={formData.Strada}
                                            label='Stradă*'
                                            onBlur={() => props.setFieldTouched('Strada')}
                                            onChange={(e: any) => {
                                                handleFormData(e.target.value, 'Strada');
                                                props.setFieldValue('Strada', e.target.value);
                                            }}
                                        />
                                    }
                                </Field>
                                <ErrorMessage name="Strada" render={msg => <div style={{ color: '#ea1c0a', fontWeight: 'bold' }}>{msg}</div>} />
                            </Col>
                            <Col>
                                <Field label='Numar*' id="Numar" name="Numar" placeholder="Numar*">
                                    {() =>
                                        <Input
                                            mbZero
                                            value={formData.Numar}
                                            label='Număr*'
                                            onBlur={() => props.setFieldTouched('Numar')}
                                            onChange={(e: any) => {
                                                handleFormData(e.target.value, 'Numar');
                                                props.setFieldValue('Numar', e.target.value);
                                            }}
                                        />
                                    }
                                </Field>
                                <ErrorMessage name="Numar" render={msg => <div style={{ color: '#ea1c0a', fontWeight: 'bold' }}>{msg}</div>} />
                            </Col>
                            <Col>
                                <Field label='Bloc' id="Bloc" name="Bloc" placeholder="Bloc">
                                    {() =>
                                        <Input
                                            mbZero
                                            value={formData.Bloc}
                                            label='Bloc'
                                            onBlur={() => props.setFieldTouched('Bloc')}
                                            onChange={(e: any) => {
                                                handleFormData(e.target.value, 'Bloc');
                                                props.setFieldValue('Bloc', e.target.value);
                                            }}
                                        />
                                    }
                                </Field>
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col>
                                <Field label='Scara' id="Scara" name="Scara" placeholder="Scara">
                                    {() =>
                                        <Input
                                            mbZero
                                            value={formData.Scara}
                                            label='Scară'
                                            onBlur={() => props.setFieldTouched('Scara')}
                                            onChange={(e: any) => {
                                                handleFormData(e.target.value, 'Scara');
                                                props.setFieldValue('Scara', e.target.value);
                                            }}
                                        />
                                    }
                                </Field>
                            </Col>
                            <Col>
                                <Field label='Ap' id="Ap" name="Ap" placeholder="Ap">
                                    {() =>
                                        <Input
                                            mbZero
                                            value={formData.Ap}
                                            label='Apartament'
                                            onBlur={() => props.setFieldTouched('Ap')}
                                            onChange={(e: any) => {
                                                handleFormData(e.target.value, 'Ap');
                                                props.setFieldValue('Ap', e.target.value);
                                            }}
                                        />
                                    }
                                </Field>
                            </Col>
                            <Col>
                                <Field label='Cod postal' id="CodPostal" name="CodPostal" placeholder="Cod postal">
                                    {() =>
                                        <Input
                                            mbZero
                                            value={formData.CodPostal}
                                            label='Cod poștal'
                                            onBlur={() => props.setFieldTouched('CodPostal')}
                                            onChange={(e: any) => {
                                                handleFormData(e.target.value, 'CodPostal');
                                                props.setFieldValue('CodPostal', e.target.value);
                                            }}
                                        />
                                    }
                                </Field>
                            </Col>
                        </Row>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default OrderFormAdress;
