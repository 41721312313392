import { toggleLoader } from '@actions/LoaderActions';
import { getMeasuredValuesPartnerFiles } from '@api/user/user';
import { ModalListDownload } from '@components/common/Modal/ModalListDownload';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import { useDispatch } from 'react-redux';

const DisplayMeasuredValues = () => {
  const dispatch = useDispatch();
  const [files, setFiles] = useState([]);
  const [showDownloadModa, setShowDownloadModa] = useState(false);
  const [documents, setDocuments] = useState([]);
  const [error, setError] = useState('');

  useEffect(() => {
    dispatch(toggleLoader(true));
    getMeasuredValuesPartnerFiles()
      .then((res) => {
        const files = res ?? [];
        setFiles(files);
        dispatch(toggleLoader(false));
      })
      .catch((err) => {
        console.log(err);
        setError('A intervenit o eroare tehnică. Vă rugăm reveniți mai târziu.');
        dispatch(toggleLoader(false));
      });
  }, []);

  const openDownloadModal = (file: any) => {
    setShowDownloadModa(true);
    const documents = file.downloadLinks.map((dL: any) => {
      return { name: dL.fileName, url: dL.downloadLink };
    });
    setDocuments(documents);
  };

  return (
    <div className="m-3">
      {/*<div className="m-3 p-3 me-4 bg-eon-lightgrey d-flex flex-column">*/}
      {/*  <p className="m-0">*/}
      {/*    Pentru eventualele sesizări legate de corectitudinea <strong>datelor de măsurare</strong> vă rugăm să vă adresați către:*/}
      {/*  </p>*/}
      {/*  <p className="m-0">*/}
      {/*    <a className="red" href={`mailto:ionela.state@delgaz-grid.ro`}>*/}
      {/*      ionela.state@delgaz-grid.ro*/}
      {/*    </a>*/}
      {/*    ;{' '}*/}
      {/*    <a className="red" href={`mailto:mihaela.arhip@delgaz-grid.ro`}>*/}
      {/*      mihaela.arhip@delgaz-grid.ro*/}
      {/*    </a>*/}
      {/*  </p>*/}
      {/*</div>*/}
      {/*<div className="m-3 p-3 me-4 bg-eon-lightgrey d-flex flex-column">*/}
      {/*  <p className="m-0">*/}
      {/*    Pentru eventualele sesizări legate de corectitudinea <strong>datelor tip curbă și profil specific</strong> vă rugăm să vă adresați*/}
      {/*    către:*/}
      {/*  </p>*/}
      {/*  <p className="m-0">*/}
      {/*    <a className="red" href={`mailto:monica.sama@delgaz-grid.ro`}>*/}
      {/*      monica.sama@delgaz-grid.ro*/}
      {/*    </a>*/}
      {/*    ;{' '}*/}
      {/*    <a className="red" href={`mailto:george.cojocariu@delgaz-grid.ro`}>*/}
      {/*      george.cojocariu@delgaz-grid.ro*/}
      {/*    </a>*/}
      {/*  </p>*/}
      {/*</div>*/}

      {error ? (
        <div className="ms-3" style={{ color: 'red', fontWeight: 'bold' }}>
          {error}
        </div>
      ) : (
        <div className='general-table-container'>
          <Table>
            <thead>
              <tr>
                <th>Cod ETSO</th>
                <th>Data </th>
              </tr>
            </thead>
            <tbody>
              {files.length > 0 ? (
                files.map((file: any, index) => (
                  <tr>
                    <td key={index}>
                      <div className="d-flex align-items-center">
                        <span>{file.codEtso}</span>
                      </div>
                    </td>
                    <td key={index}>
                      <div className="d-flex align-items-center">
                        <a style={{ color: 'black', textDecoration: 'none', cursor: 'pointer' }} onClick={() => openDownloadModal(file)}>
                          {file.data} <FontAwesomeIcon icon={faDownload} />
                        </a>
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <p>Momentan nu exista inregistrari.</p>
              )}
            </tbody>
          </Table>
        </div>
      )}

      <ModalListDownload
        modalTitle="Descarcă documente"
        showModal={showDownloadModa}
        setShowModal={() => setShowDownloadModa(false)}
        documents={documents}
      />
    </div>
  );
};

export default DisplayMeasuredValues;
