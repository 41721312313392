import React, {useState} from 'react';
import { ResourcesKentico } from '@type/kentico-types';
import {Row} from 'react-bootstrap';

export const ResourcesComponent = (props: { kentico: ResourcesKentico }) => {
    const [expanded, setExpanded] = useState(false);
    const contentRef = React.useRef() as React.MutableRefObject<HTMLInputElement>;

    const renderResourceItem = (item: any) => {
        return (
            <div
                className={'resource-container'}
                onMouseEnter={() => setExpanded(true)}
                onMouseLeave={() => setExpanded(false)}
            >
                <h6 style={{marginBottom: 0, fontSize: '24px'}}>{item.title}</h6>
                <div
                    className="resource-content"
                    ref={contentRef}
                    style={expanded ? {height: contentRef.current.scrollHeight + 'px'} : {height: '0px'}}
                >
                {item.links.map((link: any, index: any) => (
                    <Row className="resource-links" key={index}>
                        <a href={link.link} key={index}>{link.name}</a>
                    </Row>
                ))}
                </div>
            </div>
        );
    };

    return (
        <div className="m-5">
            <div className="resources-container">
                {renderResourceItem(props.kentico)}
            </div>
        </div>
    );
};