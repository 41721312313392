import * as Yup from 'yup';
import * as Validation from './validation';

export const LoginSchema = Yup.object().shape({
  email: Validation.emailRules,
  password: Validation.passwordRules
});

export const ValidateOTPSchema = Yup.object().shape({
  otp: Validation.OTPRules
});

export const ForcedChangePasswordSchema = Yup.object().shape({
  otp: Validation.OTPRules,
  password: Validation.oldPasswordRules,
  newPassword: Validation.forcedPasswordConfirmationRules
});

export const CPFSchema = Yup.object().shape({
  firstName: Validation.firstNameRules,
  lastName: Validation.lastNameRules,
  // mobilePhone: Validation.phoneRunes,
  email: Validation.emailRules,
  password: Validation.registerPasswordRules,
  emailConfirmation: Validation.emailConfirmationRules,
  passwordConfirmation: Validation.registerPasswordConfirmationRules
});

export const CPJSchema = Yup.object().shape({
  companyName: Validation.companyRules,
  orc: Validation.orcRules,
  cui: Validation.cuiRules,
  contactFirstName: Validation.firstNameRules,
  contactLastName: Validation.lastNameRules,
  // contactMobilePhone: Validation.phoneRunes,
  fax: Validation.faxRules,
  email: Validation.emailRules,
  password: Validation.registerPasswordRules,
  localityId: Validation.localityIdRules,
  emailConfirmation: Validation.emailConfirmationRules,
  passwordConfirmation: Validation.registerPasswordConfirmationRules,
  countyId: Validation.countyIdRules
});

export const PVMSchema = Yup.object().shape({
  companyName: Validation.companyRules,
  orc: Validation.orcRules,
  cui: Validation.cuiRules,
  etsoCode: Validation.etsoCodeRules,
  contactFirstName: Validation.firstNameRules,
  contactLastName: Validation.lastNameRules,
  contactMobilePhone: Validation.phoneRunes,
  fax: Validation.faxRules,
  email: Validation.emailRules,
  password: Validation.registerPasswordRules,
  emailConfirmation: Validation.emailConfirmationRules,
  passwordConfirmation: Validation.registerPasswordConfirmationRules
});

export const FGNSchema = Yup.object().shape({
  // licence: Validation.licenceRules,
  // supplierCode: Validation.supplierCodeRules,
  // operatorName: Validation.operatorNameRules,
  // contactFirstName: Validation.firstNameRules,
  // contactLastName: Validation.lastNameRules,
  // contactMobilePhone: Validation.phoneRunes,
  // fax: Validation.faxRules,
  // email: Validation.emailRules,
  password: Validation.registerPasswordRules,
  // emailConfirmation: Validation.emailConfirmationRules,
  passwordConfirmation: Validation.registerPasswordConfirmationRules,
  tfaPhone: Validation.phoneRunes
});

export const FELSchema = Yup.object().shape({
  // licence: Validation.licenceRules,
  // supplierCode: Validation.supplierCodeRules,
  // operatorName: Validation.operatorNameRules,
  // contactFirstName: Validation.firstNameRules,
  // contactLastName: Validation.lastNameRules,
  // contactMobilePhone: Validation.phoneRunes,
  // fax: Validation.faxRules,
  // email: Validation.emailRules,
  password: Validation.registerPasswordRules,
  // emailConfirmation: Validation.emailConfirmationRules,
  passwordConfirmation: Validation.registerPasswordConfirmationRules,
  tfaPhone: Validation.phoneRunes
});

export const OperatorSchema = Yup.object().shape({
  operatorName: Validation.operatorNameRules,
  // contactPhone: Validation.phoneRunes,
  mobilePhone: Validation.phoneRunes,
  companyName: Validation.companyRules,
  cui: Validation.cuiRules,
  orc: Validation.orcRules,
  ediEdibAuthorization: Validation.ediEdibAuthorization,
  email: Validation.emailRules,
  password: Validation.registerPasswordRules,
  emailConfirmation: Validation.emailConfirmationRules,
  passwordConfirmation: Validation.registerPasswordConfirmationRules
});

export const ChangePasswordSchema = Yup.object().shape({
  oldPassword: Validation.oldPasswordRules,
  password: Validation.registerPasswordRules,
  confirmNewPassword: Validation.registerPasswordConfirmationRules
});

export const ResetPasswordSchema = Yup.object().shape({
  email: Validation.emailRules
});

export const RequestResetPasswordSchema = Yup.object().shape({
  password: Validation.registerPasswordRules,
  confirmNewPassword: Validation.registerPasswordConfirmationRules
});

export const AdDetailsSchema = Yup.object().shape({
  firstName: Validation.firstNameRules,
  lastName: Validation.lastNameRules,
  email: Validation.emailRules,
  phone: Validation.phoneRunes
});

export const DataVerifySchema = Yup.object().shape({
  licence: Validation.licenceRules,
  supplierCode: Validation.supplierCodeRules,
  email: Validation.emailRules,
  emailConfirmation: Validation.emailConfirmationRules
});

export const EditSchema = Yup.object().shape({
  password: Validation.passwordRules,
  confirmPassword: Validation.registerPasswordConfirmationRules
});

export const EditPFSchema = Yup.object().shape({
  contactLastName: Validation.lastNameRules,
  contactFirstName: Validation.firstNameRules,
  // contactPhone: Validation.phoneRunes
});

export const EditPJSchema = Yup.object().shape({
  contactLastName: Validation.lastNameRules,
  contactFirstName: Validation.firstNameRules,
  // contactPhone: Validation.phoneRunes
});

export const EditOperatorSchema = Yup.object().shape({
  operatorName: Validation.operatorNameRules,
  // contactPhone: Validation.phoneRunes,
  mobilePhone: Validation.phoneRunes,
  companyName: Validation.companyRules,
  cui: Validation.cuiRules,
  orc: Validation.orcRules,
  ediEdibAuthorization: Validation.ediEdibAuthorization
});

export const EditSchemaFurn = Yup.object().shape({
  // email: Validation.emailRules,
  // emailConfirmation: Validation.emailConfirmationRules,
  // password: Validation.passwordRules,
  // confirmPassword: Validation.registerPasswordConfirmationRules
});

export const EditPVMSchema = Yup.object().shape({
  contactFirstName: Validation.firstNameRules,
  contactLastName: Validation.lastNameRules,
  contactMobilePhone: Validation.phoneRunes
});

export const CheckDisturbanceSchema = Yup.object().shape({
  countyCode: Validation.countyIdRules,
  localityCode: Validation.localityIdRules,
  streetCode: Validation.streetRules
});

export const CheckDisturbanceCPSchema = Yup.object().shape({
  measurementPointCode: Validation.measurementPointCodeRules,
  phone: Validation.phoneRunes
});

export const CheckDisturbanceTiketStatusSchema = Yup.object().shape({
  countyCode: Validation.countyIdRules,
  localityCode: Validation.localityIdRules,
  measurementPointCode: Validation.measurementPointCodeRules
});

export const VerifyLicenseForAddToFurnSchema = Yup.object().shape({
  licenseNumber: Validation.licenceRules,
  clientCodeEon: Validation.supplierCodeRules
});

export const ChangeEmailSchema = Yup.object().shape({
  currentEmail: Validation.emailRules,
  //new email
  email: Validation.emailRules,
  confirmNewEmail: Validation.emailConfirmationRules,
  password: Validation.passwordRules,
  confirmPassword: Validation.registerPasswordConfirmationRules
});

export const DateIdentificarePFSchema = Yup.object().shape({
  Nume: Validation.lastNameRules,
  Prenume: Validation.firstNameRules,
  CNP: Validation.cnpRules,
  Email: Validation.emailRules,
  Judet: Validation.countyIdRules,
  Localitate: Validation.localityIdRules,
  Cont: Validation.bankAccountRules,
  SerieCI: Validation.IDSeriesRules,
  NumarCI: Validation.IDNumberRules,
  CIEliberatDe: Validation.IDRules,
  CIData: Validation.IDRules,
  CIDataExp: Validation.IDRules
});

export const DateIdentificarePJSchema = Yup.object().shape({
  Denumire: Validation.companyRules,
  NrReg: Validation.orcRules,
  CUI: Validation.cuiRules,
  Email: Validation.emailRules,
  Judet: Validation.countyIdRules,
  Localitate: Validation.localityIdRules,
  Reprezentant: Validation.reprezentant,
  InCalitateDe: Validation.inCalitateDe,
  Cont: Validation.bankAccountRules
});

export const AdresaCorespSchema = Yup.object().shape({
  Judet: Validation.countyIdRules,
  Localitate: Validation.localityIdRules
});

export const ImputernicitPFSchema = Yup.object().shape({
  Nume: Validation.lastNameRules,
  Prenume: Validation.firstNameRules,
  CNP: Validation.cnpRules,
  Email: Validation.emailRules,
  Judet: Validation.countyIdRules,
  Localitate: Validation.localityIdRules,
  SerieCI: Validation.IDSeriesRules,
  NumarCI: Validation.IDNumberRules,
  CIEliberatDe: Validation.IDRules,
  CIData: Validation.IDRules,
  CIDataExp: Validation.IDRules
});

export const ImputernicitPJSchema = Yup.object().shape({
  Denumire: Validation.companyRules,
  CUI: Validation.cuiRules,
  Email: Validation.emailRules,
  Judet: Validation.countyIdRules,
  Localitate: Validation.localityIdRules,
  NrReg: Validation.orcRules
});

export const AdresaObiectivuluiSchema = Yup.object().shape({
  Judet: Validation.countyIdRules,
  Localitate: Validation.localityIdRules
});

export const PVAEAgreementSchema = Yup.object().shape({
  numberPVAE: Validation.numberPVAERules,
  date: Validation.dateRules,
  CNP: Validation.cnpRules,
  measurementPointCode: Validation.measurementPointCodeRules
});

export const RSSPFSchema = Yup.object().shape({
  cnp: Validation.optionalCnpRules,
  nume: Validation.lastNameRules,
  prenume: Validation.firstNameRules,
  telefon: Validation.optionalPhoneRunes,
  email: Validation.rssEmailRules,
  judet: Validation.countyIdRules,
  localitate: Validation.localityIdRules,
  strada: Validation.rssStreetRules,
  numar: Validation.rssStreetNoRules,
  codPostal: Validation.postalCodeSupplierRules
});

export const RSSPFSchemaInPortfolio = Yup.object().shape({
  cnp: Validation.optionalCnpRules,
  telefon: Validation.optionalPhoneRunes,
  email: Validation.rssEmailRules
});

export const RSSPJSchema = Yup.object().shape({
  numeCompanie: Validation.companyRules,
  telefon: Validation.optionalPhoneRunes,
  email: Validation.rssEmailRules,
  judet: Validation.countyIdRules,
  localitate: Validation.localityIdRules,
  strada: Validation.rssStreetRules,
  numar: Validation.rssStreetNoRules,
  codPostal: Validation.postalCodeSupplierRules
});

export const RSSPJSchemaInPortfolio = Yup.object().shape({
  telefon: Validation.optionalPhoneRunes,
  email: Validation.rssEmailRules
});

export const ContactPF = Yup.object().shape({
  nume: Validation.lastNameRules,
  prenume: Validation.firstNameRules,
  cnp: Validation.optionalCnpRules,
  telefon: Validation.optionalPhoneRunes,
  email: Validation.emailRules,
  judet: Validation.countyIdRules,
  localitate: Validation.localityIdRules,
  strada: Validation.rssStreetRules,
  numar: Validation.rssStreetNoRules,
  codPostal: Validation.postalCodeRules
});

export const ContactPJ = Yup.object().shape({
  denumire: Validation.companyRules,
  reprezentant: Validation.reprezentant,
  telefon: Validation.optionalPhoneRunes,
  email: Validation.emailRules,
  judet: Validation.countyIdRules,
  localitate: Validation.localityIdRules,
  strada: Validation.rssStreetRules,
  numar: Validation.rssStreetNoRules,
  codPostal: Validation.postalCodeRules
});

export const VerifyRSSByEmailSchema = Yup.object().shape({
  rssNumber: Validation.nrEra,
  email: Validation.emailRules,
  year: Validation.yearPetition
});

export const VerifyRSSByPodEmoSchema = Yup.object().shape({
  rssNumber: Validation.nrEra,
  podEmo: Validation.measurementPointCodeRules,
  year: Validation.yearPetition
});

export const VerifyRSSByPodDegSchema = Yup.object().shape({
  rssNumber: Validation.nrEra,
  podDeg: Validation.measurementPointCodeRulesDEG,
  year: Validation.yearPetition
});

export const ValidatePodCountyLocallityFormSchemaEE = Yup.object().shape({
  countyCode: Validation.countyIdRules,
  localityCode: Validation.localityIdRules,
  measurementPointCode: Validation.measurementPointCodeRules
});

export const ValidatePodCountyLocallityFormSchemaGN = Yup.object().shape({
  countyCode: Validation.countyIdRules,
  localityCode: Validation.localityIdRules,
  measurementPointCode: Validation.measurementPointCodeRulesDEG
});

export const OrderFormSchema = Yup.object().shape({
  name: Validation.companyRules,
  cui: Validation.cuiRules,
  nrReg: Validation.orcRules,
  county: Validation.countyIdRules,
  locality: Validation.localityIdRules,
  street: Validation.streetRules,
  number: Validation.IDRules,
  contactPerson: Validation.IDRules,
  phone: Validation.phoneRunes,
  email: Validation.emailRules,
  postalCode: Validation.postalCodeSupplierRules,
  bankAccount: Validation.bankAccountRules,
  as: Validation.IDRules
});

export const OrderFormAdressSchema = Yup.object().shape({
  Judet: Validation.countyIdRules,
  Localitate: Validation.localityIdRules,
  Strada: Validation.streetRules,
  Numar: Validation.IDRules
});

export const PVCAgreementSchema = Yup.object().shape({
  numberPVC: Validation.numberPVCRules,
  date: Validation.dateRules,
  CNP: Validation.cnpRules,
  clc: Validation.clcRules
});

export const RSSClientPFInPortofolioSchema = Yup.object().shape({
  phone: Validation.phoneRunes,
  postalCode: Validation.postalCodeSupplierRules
});

export const RSSClientPJInPortofolioSchema = Yup.object().shape({
  phone: Validation.phoneRunes,
  postalCode: Validation.postalCodeSupplierRules
});

export const RSSClientPFNotInPortofolioSchema = Yup.object().shape({
  cnp: Validation.cnpRules,
  firstName: Validation.firstNameRules,
  lastName: Validation.lastNameRules,
  phone: Validation.phoneRunes,
  email: Validation.optionalEmailRules,
  county: Validation.countyIdRules,
  locality: Validation.localityIdRules,
  street: Validation.rssStreetRules,
  number: Validation.rssStreetNoRules,
  postalCode: Validation.postalCodeSupplierRules
});

export const RSSClientPJNotInPortofolioSchema = Yup.object().shape({
  name: Validation.companyRules,
  representative: Validation.reprezentant,
  cui: Validation.cuiRules,
  phone: Validation.phoneRunes,
  email: Validation.optionalEmailRules,
  county: Validation.countyIdRules,
  locality: Validation.localityIdRules,
  street: Validation.rssStreetRules,
  number: Validation.rssStreetNoRules,
  postalCode: Validation.postalCodeSupplierRules
});

export const ModalCitireContorSchema = Yup.object().shape({
  email: Validation.emailRules,
  phone: Validation.phoneRunes
});

export const IncarcareFisaNouaVerificareClient = Yup.object().shape({
  county: Validation.countyIdRules,
  locality: Validation.localityIdRules,
  type: Validation.requiredFieldRules
});

export const IncarcareFisaNouaFormular = Yup.object().shape({
  tipLucrare: Validation.tipLucrareFisaRvRules,
  nrContract: Validation.nrContractFisaRvRules,
  dataContract: Validation.dataContractFisaRvRules,
  nrFisa: Validation.nrFisaFisaRvRules,
  dataFisa: Validation.dataFisaFisaRvRules,
  dataEfectuarii: Validation.dataEfectuariiFisaRvRules,
  instalatieConforma: Validation.requiredFieldRules,
  listaModificata: Validation.requiredFieldRules,
  sistareAlimentareCuGaze: Validation.requiredFieldRules,
  demontareContor: Validation.requiredFieldRules
});

export const RVCreareFisaNouaStepOne = Yup.object().shape({
  nrContract: Validation.nrContractFisaRvRules,
  dataContract: Validation.dataContractFisaRvRules,
  nrFisa: Validation.nrFisaFisaRvRules,
  dataFisa: Validation.dataFisaFisaRvRules,
  demontareContor: Validation.requiredFieldRules,
  dataUltimeiVerificariSauRevizii: Validation.requiredFieldRules,
  dataScadentaVerificariiSauReviziei: Validation.requiredFieldRules,
  numeInstalator: Validation.requiredFieldRules,
  legitimatieTipInstalator: Validation.requiredFieldRules,
  legitimatieNumarInstalator: Validation.requiredFieldRules,
  legitimatieAnInstalator: Validation.requiredFieldRules,
  dataValabilitateLegitimatieInstalator: Validation.requiredFieldRules,
  dataEfectuariiVerificariiSauReviziei: Validation.requiredFieldRules,
  sistareAlimentareCuGaze: Validation.requiredFieldRules
});

export const iConnectImpPFSchema = Yup.object().shape({
  Nume: Validation.lastNameRules,
  Prenume: Validation.firstNameRules,
  CNP: Validation.cnpRules,
  Email: Validation.emailRules,
  Judet: Validation.countyIdRules,
  Localitate: Validation.localityIdRules,
  SerieCI: Validation.IDSeriesRules,
  NumarCI: Validation.IDNumberDigitsRules,
  CIEliberatDe: Validation.IDElRules,
  CIData: Validation.IDDateRules
});

export const AdresaCorespSchemaGN = Yup.object().shape( {
    Judet: Validation.countyIdRules,
    Localitate: Validation.localityIdRules,
    Email: Validation.emailRules,
    Telefon: Validation.phoneRunes
} );

export const ImputernicitPJEESchema = Yup.object().shape( {
    Denumire: Validation.companyRules,
    CUI: Validation.cuiRules,
    Email: Validation.emailRules,
    Judet: Validation.countyIdRules,
    Localitate: Validation.localityIdRules,
    Reprezentant: Validation.reprezentant,
    NrReg: Validation.orcRules,
} );

export const ImputernicitPJGNSchema = Yup.object().shape( {
    Denumire: Validation.companyRules,
    CUI: Validation.cuiRules,
    Email: Validation.emailRules,
    Judet: Validation.countyIdRules,
    Localitate: Validation.localityIdRules,
    NrReg: Validation.orcRules,
} );

export const ConsultantSchema = Yup.object().shape( {
    nume_consultant: Validation.optionalLastNameRules,
    prenume_consultant: Validation.optionalFirstNameRules,
    telefon_consultant: Validation.optionalPhoneRunes,
    email_consultant: Validation.optionalEmailRules
} );

export const DateIdentificarePFEESchema = Yup.object().shape( {
    Nume: Validation.lastNameRules,
    Prenume: Validation.firstNameRules,
    CNP: Validation.cnpRules,
    Email: Validation.emailRules,
    Judet: Validation.countyIdRules,
    Localitate: Validation.localityIdRules,
    Cont: Validation.bankAccountRules,
    SerieCI: Validation.IDSeriesRules,
    NumarCI: Validation.IDNumberDigitsRules,
    CIEliberatDe: Validation.IDRules,
    CIData: Validation.IDRules,
} );

export const AdresaCorespGNSchema = Yup.object().shape( {
    Judet: Validation.countyIdRules,
    Localitate: Validation.localityIdRules,
} );

export const DateIdentificarePJGNSchema = Yup.object().shape( {
    Denumire: Validation.companyRules,
    NrReg: Validation.orcRules,
    CUI: Validation.cuiRules,
    Email: Validation.emailRules,
    Judet: Validation.countyIdRules,
    Localitate: Validation.localityIdRules,
    Reprezentant: Validation.reprezentant,
    InCalitateDe: Validation.inCalitateDe,
    Cont: Validation.bankAccountRules,
} );

export const DateIdentificarePFOptEmailGNSchema = Yup.object().shape( {
    Nume: Validation.lastNameRules,
    Prenume: Validation.firstNameRules,
    CNP: Validation.cnpRules,
    Email: Validation.optionalEmailRules,
    Judet: Validation.countyIdRules,
    Localitate: Validation.localityIdRules,
    Cont: Validation.bankAccountRules,
    SerieCI: Validation.IDSeriesRules,
    NumarCI: Validation.IDNumberDigitsRules,
    CIEliberatDe: Validation.IDRules,
    CIData: Validation.IDRules,
} );

export const DateIdentificarePJOptEmailGNSchema = Yup.object().shape( {
    Denumire: Validation.companyRules,
    NrReg: Validation.orcRules,
    CUI: Validation.cuiRules,
    Email: Validation.optionalEmailRules,
    Judet: Validation.countyIdRules,
    Localitate: Validation.localityIdRules,
    Reprezentant: Validation.reprezentant,
    InCalitateDe: Validation.inCalitateDe,
    Cont: Validation.bankAccountRules,
} );

export const DateIdentificarePFEEOptEmailSchema = Yup.object().shape( {
    Nume: Validation.lastNameRules,
    Prenume: Validation.firstNameRules,
    CNP: Validation.cnpRules,
    Email: Validation.optionalEmailRules,
    Judet: Validation.countyIdRules,
    Localitate: Validation.localityIdRules,
    Cont: Validation.bankAccountRules,
    SerieCI: Validation.IDSeriesRules,
    NumarCI: Validation.IDNumberRules,
    CIEliberatDe: Validation.IDRules,
    CIData: Validation.IDRules,
} );

// Prosumatori
export const DateIdentificarePFOptEmailSchema = Yup.object().shape( {
    Nume: Validation.lastNameRules,
    Prenume: Validation.firstNameRules,
    CNP: Validation.cnpRules,
    Email: Validation.optionalEmailRules,
    Judet: Validation.countyIdRules,
    Localitate: Validation.localityIdRules,
    Cont: Validation.bankAccountRules,
    SerieCI: Validation.IDSeriesRules,
    NumarCI: Validation.IDNumberRules,
    CIEliberatDe: Validation.IDRules,
    CIData: Validation.IDRules,
    CIDataExp: Validation.IDRules
} );

// Prosumatori
export const DateIdentificarePJOptEmailSchema = Yup.object().shape( {
    Denumire: Validation.companyRules,
    NrReg: Validation.orcRules,
    CUI: Validation.cuiRules,
    Email: Validation.optionalEmailRules,
    Judet: Validation.countyIdRules,
    Localitate: Validation.localityIdRules,
    Reprezentant: Validation.reprezentant,
    InCalitateDe: Validation.inCalitateDe,
    Cont: Validation.bankAccountRules
} );

// EE
export const DateIdentificarePJOptEmailEESchema = Yup.object().shape( {
    Denumire: Validation.companyRules,
    NrReg: Validation.orcRules,
    CUI: Validation.cuiRules,
    Email: Validation.optionalEmailRules,
    Judet: Validation.countyIdRules,
    Localitate: Validation.localityIdRules,
    Reprezentant: Validation.reprezentant,
    InCalitateDe: Validation.inCalitateDe,
    Cont: Validation.bankAccountRules,
} );