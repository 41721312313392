import { toggleLoader } from '@actions/LoaderActions';
import { GetCounties } from '@api/geoApi';
import { postStepThree } from '@api/iConnect/iConnectGaz';
import { getTypes } from '@api/prosumatori/prosumatori';
import ChoiceComponent from '@components/prosumatori/components/ChoiceComponent';
import { Fragment, useEffect, useRef, useState } from 'react';
import { Button, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import ConnectingPipe from '../components/ConnectingPipe';
import AdresaObiectivului from '../forms/AdresaObiectivului';
import AdresaObiectivuluiAA from '../forms/AdresaObiectivuluiAA';
import AdresaObiectivuluiPOD from '../forms/AdresaObiectivuluiPOD';
import ObtinereAviz from '../forms/ObtinereAviz';

//eslint-disable-next-line
const cpInfo = [
    'Situație în care nu există conductă de gaze naturale în fața imobilului',
    'Situație în care nu există conductă de gaze naturale în fața imobilelor',
    'Situație în care există conductă de gaze naturale în fața imobilului',
    'Situație în care există branșament realizat',
    'Situație în care se dorește modificarea debitului aprobat sau traseul instalației',
    'Situație în care se dorește înființarea/ dezvoltarea unui nou sistem de distribuție',
    ''
];

const AdresaObiectiv = (props: {
    isEditing: any,
    requestId: any,
    setStep: any,
    currentStep: any,
    progress: any,
    setProgress: any,
    connectingPipe: any,
    handleConnectingPipe: any,
    solicit: any
    handleSolicit: any,
    construit: any,
    handleConstruit: any,
    adresaOb: any,
    handleAdresaOb: any,
    tipLC: any,
    handleTipLC: any,
    wasDataVerified: any,
    handleWasDataVerified: any,
    tipAdresa: any,
    handleTipAdresa: any,
    dateObtinereAviz: any,
    dateRacordare: any,
    handleDateObtinereAviz: any,
    handleDateRacordare: any,
    dateIdentificare: any
}) => {
    const {
        isEditing,
        requestId,
        setStep,
        currentStep,
        progress,
        setProgress,
        connectingPipe,
        handleConnectingPipe,
        construit,
        handleConstruit,
        // solicit,
        // handleSolicit,
        adresaOb,
        handleAdresaOb,
        tipLC,
        handleTipLC,
        wasDataVerified,
        handleWasDataVerified,
        tipAdresa,
        handleTipAdresa,
        dateObtinereAviz,
        dateRacordare,
        handleDateObtinereAviz,
        handleDateRacordare,
        dateIdentificare
    } = props;

    const [CPOptions, setCPOptions] = useState([]);
    //eslint-disable-next-line
    const [SOptions, setSOptions] = useState([]);
    const [LCOptions, setLCOptions] = useState([]);
    const [validationError, setValidationError] = useState('');
    const innerRef = useRef(null);
    const oaRef = useRef(null);
    const rRef = useRef(null);
    const [isCountyInList, setIsCountyInList] = useState(false);
    const [wasCountyChecked, setWasCountyChecked] = useState(false);

    const handleValidationError = (value: string) => {
        setValidationError(value);
    };

    const dispatch = useDispatch();

    useEffect(() => {
        window.scrollTo(0, 0);
        handleTipAdresa(tipAdresa);

        getOptionTypes(['ApplicationType']).then((res: any) => setLCOptions(res[Object.keys(res)[0]]));
        getOptionTypes(['RequestReasonType']).then((res: any) => {
            setSOptions(res[Object.keys(res)[0]]);
        });
        getOptionTypes(['ConnectingPipeType']).then((res: any) => {
            setCPOptions(res[Object.keys(res)[0]]);
        });
    }, []);

    useEffect(() => {
        if (dateIdentificare.Judet !== '' && wasCountyChecked === false) {
            checkIsCountyInList();
        }
    }, [dateIdentificare.Judet]);

    const checkIsCountyInList = async () => {
        dispatch(toggleLoader(true));

        let found = false;

        await GetCounties('GN').then((response) => {
            if (response) {
                response.map((county: any, index: number) => {
                    if (county.countyCode === dateIdentificare.Judet) {
                        setIsCountyInList(true);
                        found = true;
                    } 
                });

                if (found === false) {
                    handleTipAdresa('AA');
                    setIsCountyInList(false);
                }
            }
        });

        setWasCountyChecked(true);
        dispatch(toggleLoader(false));
    };

    const getOptionTypes = async (keys: string[]) => {
        return await getTypes(keys, 'GN').then((res: any) => {
            return res;
        });
    };

    const handlePrevStep = () => {
        setStep(currentStep - 1);
    };

    const adressInPayload = (pipe: any) => {
        console.log(pipe);

        if (pipe == 6) {
            return dateObtinereAviz;
        } else if (pipe == 7) {
            return dateRacordare;
        } else {
            return adresaOb;
        }
    };

    const constructPayload = () => {
        return {
            requestId: requestId,
            TipBransament: Number(connectingPipe),
            TipLC: tipLC,
            POD: adresaOb.POD,
            ImobilConstruit: construit,
            AdresaObiectiv: adressInPayload(connectingPipe),
            nrCF: adresaOb.nrCF,
            nrCad: adresaOb.nrCad,
            TipAdresaObiectiv: (tipLC === 2 || Number(connectingPipe) === 6 || Number(connectingPipe) === 7) ? 'AA' : tipAdresa
        };
    };

    const stepThreeApiCall = async (payload: any) => {
        dispatch(toggleLoader(true));

        await postStepThree(payload)
            .then((response: any) => {
                if (response && response.status === 200) {
                    setStep(currentStep + 1);
                    currentStep === progress && setProgress(currentStep + 1);
                }
            })
            .catch((error: any) => {
                if (error && error.status === 200) {
                    setStep(currentStep + 1);
                    currentStep === progress && setProgress(currentStep + 1);
                } else if (error === 'Request is completed') {
                    setValidationError('Cererea a fost deja finalizată.');
                } else if (error && error.StatusCode && error.StatusCode === 500) {
                    setValidationError('A intervenit o eroare tehnică. Vă rugăm reveniți mai târziu.');
                }
            });

        dispatch(toggleLoader(false));
    };

    const handleNextStep = () => {
        if (isEditing !== false) {
            setValidationError('');
            let wasValidated = true;
            let lc = document.getElementById('LC');
            lc?.classList.remove('red');
            let dec = document.getElementById('declar');
            dec?.classList.remove('red');
            let sit = document.getElementById('situat');
            sit?.classList.remove('red');
            let obt = document.getElementById('obt');
            obt?.classList.remove('red');

            if (tipLC === 2 && !wasDataVerified) {
                setValidationError('Te rugăm să verifici informațiile pentru locul de consum existent.');
                wasValidated = false;
            }

            if (connectingPipe == 0 || connectingPipe === null) {
                setValidationError('Te rugăm să alegi tipul de solicitare.');
                wasValidated = false;
            }

            if (connectingPipe != 6 && connectingPipe != 7 && tipLC === null) {
                setValidationError('Câmpurile marcate cu * sunt obligatorii.');
                let err = document.getElementById('LC');
                err?.classList.add('red');
                wasValidated = false;
            }

            if (connectingPipe != 6 && connectingPipe != 7 && tipAdresa === '' && tipLC !== 2) {
                setValidationError('Câmpurile marcate cu * sunt obligatorii.');
                let err = document.getElementById('situat');
                err?.classList.add('red');
                wasValidated = false;
            }

            if (connectingPipe != 6 && connectingPipe != 7 && tipAdresa === 'AA' && (adresaOb.Judet === '' || adresaOb.Localitate === '')) {
                setValidationError('Câmpurile marcate cu * sunt obligatorii.');
                console.log('fail 4');
                wasValidated = false;
            }

            if (connectingPipe != 6 && connectingPipe !=7 && construit === null) {
                setValidationError('Câmpurile marcate cu * sunt obligatorii.');
                let err = document.getElementById('declar');
                err?.classList.add('red');
                wasValidated = false;
            }

            if (connectingPipe == 6 && dateObtinereAviz.Judet === '') {
                setValidationError('Câmpurile marcate cu * sunt obligatorii.');
                let err = document.getElementById('obt');
                err?.classList.add('red');
                wasValidated = false;
            }

            if (connectingPipe == 7 && dateRacordare.Judet === '') {
                setValidationError('Câmpurile marcate cu * sunt obligatorii.');
                let err = document.getElementById('obt');
                err?.classList.add('red');
                wasValidated = false;
            }

            if (connectingPipe == 6 && dateObtinereAviz.Localitate === '') {
                setValidationError('Câmpurile marcate cu * sunt obligatorii.');
                let err = document.getElementById('obt');
                err?.classList.add('red');
                wasValidated = false;
            }

            if (connectingPipe == 7 && dateRacordare.Localitate === '') {
                setValidationError('Câmpurile marcate cu * sunt obligatorii.');
                let err = document.getElementById('obt');
                err?.classList.add('red');
                wasValidated = false;
            }

            if (wasValidated) {
                stepThreeApiCall(constructPayload());
            }
        } else {
            setStep(currentStep + 1);
            currentStep === progress && setProgress(currentStep + 1);
        }
    };

    return (
        <div className="iconnect-adresa-obiectiv m-3 w-100 d-flex align-self-center flex-column">
            <h4 className="register-section-title mt-3">Tip de solicitare și adresa pentru care se face solicitarea</h4>

            {CPOptions.length > 0 &&
                <Row className="mb-4 justify-content-center">
                    {/* <Col xs={12} sm={6} className="d-flex align-items-center">
                        <Select
                            onChange={(e: any) => handleConnectingPipe(e.target.value)}
                            value={connectingPipe}
                            options={CPOptions}
                            placeholder={"Tip de solicitare*"}
                            mbZero
                        />
                        {(connectingPipe !== null && connectingPipe !== 0 && connectingPipe !== '7') && <Tippy
                            content={
                                <div className="p-3" style={{ background: 'rgba(0,0,0,0.9)', color: 'white', borderRadius: '6px' }}>
                                    {cpInfo[connectingPipe - 1]}
                                </div>
                            }
                            duration={0}
                            placement="top"
                        >
                            <div className="icon-div">
                                <FontAwesomeIcon icon={faCircleInfo} className="icon" color="red" />
                            </div>
                        </Tippy>}
                    </Col> */}
                    <ConnectingPipe options={CPOptions} active={connectingPipe} onChange={handleConnectingPipe} isEditing={isEditing} />
                </Row>

                // <ChoiceComponent
                //     title={'Tip de solicitare:*'}
                //     options={CPOptions}
                //     onChange={handleConnectingPipe}
                //     value={connectingPipe}
                //     colAlign={true}
                // />
            }

            {(connectingPipe >= 1 && connectingPipe <= 5) && <ChoiceComponent
                title={'Tipul locului de consum:*'}
                options={LCOptions}
                onChange={handleTipLC}
                value={tipLC}
                disabled={isEditing === false}
                id="LC"
            />}

            {(connectingPipe >= 1 && connectingPipe <= 5 && wasCountyChecked) && <Fragment>
                {(LCOptions.length > 0 && tipLC !== null) && <h5 id="situat">Se dorește alimentarea cu gaze naturale a locului de consum (imobilul situat la adresa):*</h5>}
                {(connectingPipe >= 1 && connectingPipe <= 5 && tipLC === 1) && <ChoiceComponent
                    options={[{ name: 'Adresa clientului final', id: 'AU' }, { name: 'Altă adresă', id: 'AA' }]}
                    onChange={(value: string) => handleTipAdresa(value, true)}
                    value={tipAdresa}
                    id="ADR"
                    disabled={isEditing === false || isCountyInList === false}
                />}

                {(LCOptions.length > 0 && tipLC === 1 && tipAdresa === 'AU') && <AdresaObiectivului isEditing={isEditing} innerRef={innerRef} formData={adresaOb} handleFormData={handleAdresaOb} utility={'GN'} />}
                {(LCOptions.length > 0 && tipLC === 1 && tipAdresa === 'AA') && <AdresaObiectivuluiAA isEditing={isEditing} innerRef={innerRef} formData={adresaOb} handleFormData={handleAdresaOb} utility={'GN'} />}
                {(LCOptions.length > 0 && tipLC === 2) && <AdresaObiectivuluiPOD utility="GN" handleValidationError={handleValidationError} handleWasDataVerified={handleWasDataVerified} isEditing={isEditing} innerRef={innerRef} formData={adresaOb} handleFormData={handleAdresaOb} />}

                {(connectingPipe >= 1 && connectingPipe <= 5) &&
                    <ChoiceComponent
                        title={'Declar că imobilul pentru care se solicită racordarea la sistemul de distribuţie a gazelor naturale:*'}
                        options={[{ title: 'Nu este construit încă; se va construi în baza autorizației de construire atașată prezentei cereri', id: false }, { title: 'Este construit', id: true }]}
                        onChange={handleConstruit}
                        value={construit}
                        colAlign={true}
                        id="declar"
                        disabled={isEditing === false}
                    />
                }
            </Fragment>}

            {(connectingPipe == 6) && <Fragment>
                <h5 id="obt">Se dorește obţinerea avizului tehnic de principiu/racordare în baza căruia se elaborează studiul de fezabilitate/proiectul tehnic necesar înfiinţării unui nou sistem de distribuţie a gazelor naturale sau dezvoltării unui sistem de distribuție (SD) în localitatea care a fost inclusă în actul adiţional la contractul de concesiune a serviciului de utilitate publică de distribuţie a gazelor naturale, în:*</h5>
                <ObtinereAviz isEditing={isEditing} innerRef={oaRef} formData={dateObtinereAviz} handleFormData={handleDateObtinereAviz} />
            </Fragment>}

            {(connectingPipe == 7) && <Fragment>
                <h5 id="obt">Se dorește racordarea pentru alte situații (solicitanți precizați la art. 2 din Regulament racordare, de ex. sistem de distribuție închis, magistrală directă) în:*</h5>
                <ObtinereAviz isEditing={isEditing} innerRef={rRef} formData={dateRacordare} handleFormData={handleDateRacordare} />
            </Fragment>}

            {validationError !== '' && <p style={{ fontWeight: 'bold', color: 'red' }}>{validationError}</p>}
            <div className="d-flex flex-row justify-content-between mt-3 mb-3">
                <Button className="d-flex" onClick={handlePrevStep}>Pasul anterior</Button>
                <Button className="d-flex" onClick={handleNextStep}>Pasul următor</Button>
            </div>
        </div>
    );
};

export default AdresaObiectiv;