import { toggleLoader } from '@actions/LoaderActions';
import { unlockAccount } from '@api/user/user';
import { CustomModal } from '@components/common/ModalConfirm';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

const UnlockAccount = () => {
    //eslint-disable-next-line
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState('');
    const dispatch = useDispatch();
    const [showModal, setShowModal] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    const [modalTitle, setModalTitle] = useState('');


    const handleCloseModal = () => {
        setShowModal(false);
        window.location.href = '/login';
    };

    const unlock = async () => {
        const url = new URL(window.location.href);
        const params = url.search;
        dispatch(toggleLoader(true));

        await unlockAccount({value: params.substring(7, params.length)}).then((res: any) => {
            if (res && res.success === true) {
                setModalMessage('Contul dumneavoastră a fost deblocat cu succes.');
                setModalTitle('Succes!');
                setShowModal(true);
            } else {
                setError('A intervenit o eroare tehnică. Vă rugăm reveniți mai târziu.');
            }
        }).catch((e: any) => setError('A intervenit o eroare tehnică. Vă rugăm reveniți mai târziu.'));

        dispatch(toggleLoader(false));
    };

    useEffect(() => {
        unlock();
    }, []);

    const renderSuccess = () => {
        return (
            <div className="m-3">
                <h3 style={{color: '#ea1c0a'}}>Mulțumim!</h3>
                <p>Contul dumneavoastră a fost deblocat cu succes.</p>
            </div>
        );
    };

    const renderError = () => {
        return (
            <div className="m-3">
                <p>{error}</p>
            </div>
        );
    };

    return (
        <div>
            {success && renderSuccess()}
            {error !== '' && renderError()}

            <CustomModal showModal={showModal} modalTitle={modalTitle} onClose={handleCloseModal}>
                <h5>{modalMessage}</h5>
            </CustomModal>
        </div>
    );
};

export default UnlockAccount;
