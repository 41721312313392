import { faClipboardCheck, faComments, faFolderOpen, faHouse, faPlug, faUser, faUsers } from '@fortawesome/free-solid-svg-icons';

export const stepsArrayIConnectEE = [
    {
        title: 'Client final',
        icon: faUser
    },
    {
        title: 'Reprezentant legal',
        icon: faUsers
    },
    {
        title: 'Obiectivul de racordat',
        icon: faHouse
    },
    {
        title: 'Informații tehnice loc de consum',
        icon: faPlug
    },
    {
        title: 'Canale de comunicare',
        icon: faComments
    },
    {
        title: 'Documente anexate',
        icon: faFolderOpen
    },
    {
        title: 'Informare finală',
        icon: faClipboardCheck
    }
];