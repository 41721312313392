import React from 'react';
import { DelgazWrapper } from '@components/global/DelgazWrapper';
import SearchByLocality from '@components/delgaz/Account/SuperiorCaloricValue/SearchByLocality';

export const SearchByLocalityComponent = (): JSX.Element => {
  return (
    <DelgazWrapper>
      <SearchByLocality />
    </DelgazWrapper>
  );
};
