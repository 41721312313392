import React, { useState, useEffect, useRef } from 'react';
import { Modal, Row, Col, Button } from 'react-bootstrap';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { Input } from '@components/common/Input';
import { Dropdown, DropdownOptionType } from '@components/common/Dropdown';
import { GetCounties, GetLocalities } from '@api/geoApi';
import ChoiceComponent from '@components/prosumatori/components/ChoiceComponent';
import OrderFormAdress from './OrderFormAdress';
import DynamicTable from '@components/prosumatori/components/table/DynamicTable';
import { OrderFormSchema } from '../../helpers/schemas';
import { postCmsSendEmailRepere } from '@api/cms/cms';
import InputTextArea from '@components/common/Input/InputTextArea';
import ModalConfirm from '@components/common/ModalConfirm/ModalConfirm';

type CountyTemplate = {
    countyId: number,
    countyName: string,
    countyCode: string
};

type LocalityTemplate = {
    localityId: number,
    localityName: string,
    localityCode: string
};

const adr = {
    Judet: '',
    Localitate: '',
    Comuna: '',
    Strada: '',
    Numar: '',
    Bloc: '',
    Scara: '',
    Ap: '',
    CodPostal: ''
};

const formatArticole = {
    columns: [
        {
            name: 'CodReper',
            type: 'input',
            dataType: 'integer',
            colName: 'Cod produs',
            width: '250px'
        },
        {
            name: 'Denumire',
            type: 'input',
            dataType: 'string',
            colName: 'Denumire',
            width: '250px'
        },
        {
            name: 'Unitati',
            type: 'input',
            dataType: 'integer',
            colName: 'Număr de unități',
            width: '250px'
        }
    ]
};

const data = {
    name: '',
    cui: '',
    nrReg: '',
    county: '',
    locality: '',
    street: '',
    number: '',
    building: '',
    entrance: '',
    apartment: '',
    postalCode: '',
    bankAccount: '',
    bank: '',
    contactPerson: '',
    as: '',
    phone: '',
    email: '',
    message: ''
};

const FormularComanda = () => {
    const [show, setShow] = useState(false);
    const [formData, setFormData] = useState<any>({...data});
    const [counties, setCounties] = useState<CountyTemplate[]>([]);
    const [county, setCounty] = useState('');
    const [localities, setLocalities] = useState<LocalityTemplate[]>([]);
    // const [locality, setLocality] = useState<number | null>(null);
    const [adressType, setAdressType] = useState<number | null>(null);
    const [adress, setAdress] = useState<any>({...adr});
    const [orderList, setOrderList] = useState([]);
    const [terms, setTerms] = useState(false);
    const [GDPR, setGDPR] = useState(false);
    const [validationError, setValidationError] = useState('');
    const [error, setError] = useState('');
    const [showModalSuccess, setShowModalSuccess] = useState(false);
    const [check, setCheck] = useState(false);

    const formRef = useRef(null);
    const adressRef = useRef(null);

    useEffect(() => {
        const orderBtn = document.querySelector('.order-button');

        //@ts-ignore
        const handleShow = (event) => {
            //@ts-ignore
            if (orderBtn?.contains(event.target)) {
                setShow(true);
            }
        };

        document.addEventListener('mousedown', handleShow);

        return () => { document.removeEventListener('mousedown', handleShow); };
    }, []);

    useEffect(() => {
        setAdress({...adr});
    }, [adressType]);

    useEffect(() => {
        GetCounties().then(res => setCounties(res));
    }, []);

    useEffect(() => {
        county !== '' && GetLocalities(county).then((res) => setLocalities(res));
    }, [county]);

    const validateTable = (table: any, indexException: number | null) => {
        let validated = true;

        table.map((row: any, index: number) => {
            Object.keys(row).map((column: any, idx: number) => {
                if (idx !== indexException) {
                    if (row[column] === '') {
                        validated = false;
                    }
                }
            });
        });

        return validated;
    };

    const submit = () => {
        setValidationError('');
        setError('');
        let valid = false;
        let payload: any = {
            CompanyName: formData.name,
            TaxCod: formData.cui,
            TradeRegisterNo: formData.nrReg,
            County: counties.find(c => c.countyCode == formData.county)?.countyName ?? '',
            Locality: formData.locality,
            Street: formData.street,
            Number: formData.number,
            Block: formData.building,
            BlockStaircase: formData.entrance,
            Apartment: formData.apartment,
            PostalCode: formData.postalCode,
            BankAccount: formData.bankAccount,
            Bank: formData.bank,
            ContactPerson: formData.contactPerson,
            PhoneNumber: formData.phone,
            Email: formData.email,
            SameDeliveryAddress: adressType == 1 ? true : false,
            OrderItems: orderList.map((order:any) => {
                return {
                    code: order.CodReper,
                    name: order.Denumire,
                    quantity: order.Unitati
                };
            }),
            Message: formData.message,
            As: formData.as
          };

        if (adressType !== null && validateTable(orderList, null)) {
            if (adressType === 1) {
                //@ts-ignore
                formRef.current?.submitForm();
    
                //@ts-ignore
                if (formRef.current && formRef.current?.isValid === true) {
                    valid = true;
                }
            }
    
            if (adressType === 2) {
                //@ts-ignore
                formRef.current?.submitForm();
                //@ts-ignore
                adressRef.current?.submitForm();
    
                //@ts-ignore
                if (formRef.current && formRef.current?.isValid === true && adressRef.current && adressRef.current?.isValid === true) {
                    payload = {
                        ...payload,
                        DeliveryAddress_County: counties.find(c => c.countyCode == adress.Judet)?.countyName ?? '',
                        DeliveryAddress_Locality: adress.Localitate,
                        DeliveryAddress_Street: adress.Strada,
                        DeliveryAddress_Number: adress.Numar,
                        DeliveryAddress_Block: adress.Bloc,
                        DeliveryAddress_BlockStaircase: adress.Scara,
                        DeliveryAddress_Apartment: adress.Ap,
                        DeliveryAddress_PostalCode: adress.CodPostal,
                    };
                    valid = true;
                }
            }
            if(valid) {
                postCmsSendEmailRepere(payload)
                .then(res => {
                    setShowModalSuccess(true);
                })
                .catch(err => {
                    setError('A intervenit o eroare tehnică. Vă rugăm reveniți mai târziu.');
                });
            }
        } else {
            setValidationError('Câmpurile marcate cu * sunt obligatorii.');
        }
    };

    const handleOrderList = (data: any) => {
        setOrderList(data);
    };

    const handleAdressType = (type: number) => {
        setAdressType(type);
    };

    const handleFormData = (data: any, field: any) => {
        let newData = {...formData};

        Object.keys(newData).map((key: string, index) => {
            if (key === field) {
                //@ts-ignore
                newData[key] = data;
            }
        });

        setFormData(newData);
    };

    const handleAdressData = (data: any, field: any) => {
        let newData = {...adress};

        Object.keys(newData).map((key: string, index) => {
            if (key === field) {
                //@ts-ignore
                newData[key] = data;
            }
        });

        setAdress(newData);
    };

    const resetData = () => {
        setFormData({...data});
        setAdress({...adr});
        setAdressType(null);
        setTerms(false);
        setGDPR(false);
        setOrderList([]);
        setError('');
        setValidationError('');
    };

    const renderModal = () => {
        return <Modal 
            show={show}
            backdrop="static" 
            onHide={() => {
                setShow(false);
                resetData();
                }}
            className="rss-modal">
            <Modal.Header closeButton>
                <h4 className="red">Formular comandă</h4>
            </Modal.Header>
            <Modal.Body>
                <Formik
                    initialValues={formData}
                    onSubmit={(values) => (values)}
                    validationSchema={OrderFormSchema}
                    innerRef={formRef}
                    enableReinitialize={true}
                >
                    {(props) => (
                        <Form>
                            <Row className="mb-4">
                                <h5>Date de identificare ale solicitantului</h5>
                            </Row>
                            <Row className="mb-3">
                                <Col xs={12} sm={4}>
                                    <Field label='Denumire societate*' id="name" name="name" placeholder="Denumire societate*">
                                        {({
                                            //@ts-ignore
                                            meta: { touched, error }
                                        }) =>
                                            <Input
                                                mbZero
                                                value={props.values.name}
                                                label='Denumire societate*'
                                                onBlur={() => props.setFieldTouched('name')}
                                                onChange={(e: any) => {
                                                    handleFormData(e.target.value, 'name');
                                                    props.setFieldValue('name', e.target.value);
                                                }}
                                            />
                                        }
                                    </Field>
                                    <ErrorMessage name="name" render={msg => <div style={{ color: '#ea1c0a', fontWeight: 'bold' }}>{msg}</div>} />
                                </Col>
                                <Col xs={12} sm={4}>
                                    <Field label='CUI (cod de înregistrare fiscală)*' id="cui" name="cui" placeholder="CUI (cod de înregistrare fiscală)*">
                                        {({
                                            //@ts-ignore
                                            meta: { touched, error }
                                        }) =>
                                            <Input
                                                mbZero
                                                value={props.values.cui}
                                                label='CUI (cod de înregistrare fiscală)*'
                                                onBlur={() => props.setFieldTouched('cui')}
                                                onChange={(e: any) => {
                                                    handleFormData(e.target.value, 'cui');
                                                    props.setFieldValue('cui', e.target.value);
                                                }}
                                            />
                                        }
                                    </Field>
                                    <ErrorMessage name="cui" render={msg => <div style={{ color: '#ea1c0a', fontWeight: 'bold' }}>{msg}</div>} />
                                </Col>
                                <Col xs={12} sm={4}>
                                    <Field label='Nr. inreg. la Reg. Comertului (J)*' id="nrReg" name="nrReg" placeholder="Nr. inreg. la Reg. Comertului (J)*">
                                        {({
                                            //@ts-ignore
                                            meta: { touched, error }
                                        }) =>
                                            <Input
                                                mbZero
                                                value={props.values.nrReg}
                                                label='Nr. inreg. la Reg. Comertului (J)*'
                                                onBlur={() => props.setFieldTouched('nrReg')}
                                                onChange={(e: any) => {
                                                    handleFormData(e.target.value, 'nrReg');
                                                    props.setFieldValue('nrReg', e.target.value);
                                                }}
                                            />
                                        }
                                    </Field>
                                    <ErrorMessage name="nrReg" render={msg => <div style={{ color: '#ea1c0a', fontWeight: 'bold' }}>{msg}</div>} />
                                </Col>
                            </Row>
                            <Row className="mb-3">
                                <Col xs={12} sm={6}>
                                    <Dropdown
                                        options={counties.map((option, index) => (
                                            {
                                                id: option.countyCode,
                                                name: option.countyName
                                            }
                                        )) as DropdownOptionType[]}
                                        label={'Județ*'}
                                        defaultPlaceholder={'Alege județul'}
                                        displaySearch={true}
                                        onChange={(value) => {
                                            value && props.setFieldValue('county', value);
                                            value && handleFormData(value, 'county');
                                            value.length === 0 && props.setFieldValue('county', '');
                                            setCounty(value);
                                        }}
                                        value={props.values.county}
                                        mbZero
                                    />
                                    <ErrorMessage name="county" render={msg => <div style={{ color: '#ea1c0a', fontWeight: 'bold' }}>{msg}</div>} />
                                </Col>
                                <Col xs={12} sm={6}>
                                    <Dropdown
                                        options={localities.map((option, index) => (
                                            {
                                                id: option.localityName,
                                                name: option.localityName
                                            }
                                        )) as DropdownOptionType[]}
                                        label={'Localitate*'}
                                        defaultPlaceholder={'Alege localitatea'}
                                        displaySearch={true}
                                        onChange={(value) => {
                                            value && props.setFieldValue('locality', value);
                                            value && handleFormData(value, 'locality');
                                            value.length === 0 && props.setFieldValue('locality', '');
                                        }}
                                        value={props.values.locality}
                                        mbZero
                                    />
                                    <ErrorMessage name="locality" render={msg => <div style={{ color: '#ea1c0a', fontWeight: 'bold' }}>{msg}</div>} />
                                </Col>
                            </Row>
                            <Row className="mb-3">
                                <Col xs={6} sm={4}>
                                    <Field label='Strada*' id="street" name="street" placeholder="Strada*">
                                        {() =>
                                            <Input
                                                mbZero
                                                value={props.values.street}
                                                label='Stradă*'
                                                onBlur={() => props.setFieldTouched('street')}
                                                onChange={(e: any) => {
                                                    handleFormData(e.target.value, 'street');
                                                    props.setFieldValue('street', e.target.value);
                                                }}
                                            />
                                        }
                                    </Field>
                                    <ErrorMessage name="street" render={msg => <div style={{ color: '#ea1c0a', fontWeight: 'bold' }}>{msg}</div>} />
                                </Col>
                                <Col xs={6} sm={4}>
                                    <Field label='Numar*' id="number" name="number" placeholder="Numar*">
                                        {() =>
                                            <Input
                                                mbZero
                                                value={props.values.number}
                                                label='Număr*'
                                                onBlur={() => props.setFieldTouched('number')}
                                                onChange={(e: any) => {
                                                    handleFormData(e.target.value, 'number');
                                                    props.setFieldValue('number', e.target.value);
                                                }}
                                            />
                                        }
                                    </Field>
                                    <ErrorMessage name="number" render={msg => <div style={{ color: '#ea1c0a', fontWeight: 'bold' }}>{msg}</div>} />
                                </Col>
                                <Col xs={6} sm={4}>
                                    <Field label='Bloc' id="building" name="building" placeholder="Bloc">
                                        {() =>
                                            <Input
                                                mbZero
                                                value={props.values.building}
                                                label='Bloc'
                                                onBlur={() => props.setFieldTouched('building')}
                                                onChange={(e: any) => {
                                                    handleFormData(e.target.value, 'building');
                                                    props.setFieldValue('building', e.target.value);
                                                }}
                                            />
                                        }
                                    </Field>
                                </Col>
                            </Row>
                            <Row className="mb-3">
                                <Col xs={6} sm={4}>
                                    <Field label='Scara' id="entrance" name="entrance" placeholder="Scara">
                                        {() =>
                                            <Input
                                                mbZero
                                                value={props.values.entrance}
                                                label='Scară'
                                                onBlur={() => props.setFieldTouched('entrance')}
                                                onChange={(e: any) => {
                                                    handleFormData(e.target.value, 'entrance');
                                                    props.setFieldValue('entrance', e.target.value);
                                                }}
                                            />
                                        }
                                    </Field>
                                </Col>
                                <Col xs={6} sm={4}>
                                    <Field label='Ap' id="apartment" name="apartment" placeholder="Apartament">
                                        {() =>
                                            <Input
                                                mbZero
                                                value={props.values.apartment}
                                                label='Apartament'
                                                onBlur={() => props.setFieldTouched('apartment')}
                                                onChange={(e: any) => {
                                                    handleFormData(e.target.value, 'apartment');
                                                    props.setFieldValue('apartment', e.target.value);
                                                }}
                                            />
                                        }
                                    </Field>
                                </Col>
                                <Col xs={6} sm={4}>
                                    <Field label='Cod postal' id="postalCode" name="postalCode" placeholder="Cod poștal">
                                        {() =>
                                            <Input
                                                mbZero
                                                value={props.values.postalCode}
                                                label='Cod poștal'
                                                onBlur={() => props.setFieldTouched('postalCode')}
                                                onChange={(e: any) => {
                                                    handleFormData(e.target.value, 'postalCode');
                                                    props.setFieldValue('postalCode', e.target.value);
                                                }}
                                            />
                                        }
                                    </Field>
                                    <ErrorMessage name="postalCode" render={msg => <div style={{ color: '#ea1c0a', fontWeight: 'bold' }}>{msg}</div>} />
                                </Col>
                            </Row>
                            <Row className="mb-3">
                                <Col xs={12} sm={6}>
                                    <Field label='Cont bancar' id="bankAccount" name="bankAccount" placeholder="Cont bancar">
                                        {({
                                            //@ts-ignore
                                            meta: { touched, error }
                                        }) =>
                                            <Input
                                                mbZero
                                                value={props.values.bankAccount}
                                                label='Cont bancar'
                                                onBlur={() => props.setFieldTouched('bankAccount')}
                                                onChange={(e: any) => {
                                                    handleFormData(e.target.value, 'bankAccount');
                                                    props.setFieldValue('bankAccount', e.target.value);
                                                }}
                                            />
                                        }
                                    </Field>
                                    <ErrorMessage name="bankAccount" render={msg => <div style={{ color: '#ea1c0a', fontWeight: 'bold' }}>{msg}</div>} />
                                </Col>
                                <Col xs={12} sm={6}>
                                    <Field label='Deschis la Banca' id="bank" name="bank" placeholder="Deschis la Banca">
                                        {({
                                            //@ts-ignore
                                            meta: { touched, error }
                                        }) =>
                                            <Input
                                                mbZero
                                                value={props.values.bank}
                                                label='Deschis la Banca'
                                                onBlur={() => props.setFieldTouched('bank')}
                                                onChange={(e: any) => {
                                                    handleFormData(e.target.value, 'bank');
                                                    props.setFieldValue('bank', e.target.value);
                                                }}
                                            />
                                        }
                                    </Field>
                                    <ErrorMessage name="bank" render={msg => <div style={{ color: '#ea1c0a', fontWeight: 'bold' }}>{msg}</div>} />
                                </Col>
                            </Row>
                            <Row className="mb-3">
                                <Col xs={12} sm={6}>
                                    <Field label='Pesoana de contact*' id="contactPerson" name="contactPerson" placeholder="Pesoană de contact*">
                                        {({
                                            //@ts-ignore
                                            meta: { touched, error }
                                        }) =>
                                            <Input
                                                mbZero
                                                value={props.values.contactPerson}
                                                label='Pesoană de contact*'
                                                onBlur={() => props.setFieldTouched('contactPerson')}
                                                onChange={(e: any) => {
                                                    handleFormData(e.target.value, 'contactPerson');
                                                    props.setFieldValue('contactPerson', e.target.value);
                                                }}
                                            />
                                        }
                                    </Field>
                                    <ErrorMessage name="contactPerson" render={msg => <div style={{ color: '#ea1c0a', fontWeight: 'bold' }}>{msg}</div>} />
                                </Col>
                                <Col xs={12} sm={6}>
                                    <Field label='În calitate de*' id="as" name="as" placeholder="În calitate de*">
                                        {({
                                            //@ts-ignore
                                            meta: { touched, error }
                                        }) =>
                                            <Input
                                                mbZero
                                                value={props.values.as}
                                                label='În calitate de*'
                                                onBlur={() => props.setFieldTouched('as')}
                                                onChange={(e: any) => {
                                                    handleFormData(e.target.value, 'as');
                                                    props.setFieldValue('as', e.target.value);
                                                }}
                                            />
                                        }
                                    </Field>
                                    <ErrorMessage name="as" render={msg => <div style={{ color: '#ea1c0a', fontWeight: 'bold' }}>{msg}</div>} />
                                </Col>
                            </Row>
                            <Row className="mb-4">
                                <Col xs={12} sm={6}>
                                    <Field label='Telefon' id="phone" name="phone" placeholder="Telefon">
                                        {({
                                            //@ts-ignore
                                            meta: { touched, error }
                                        }) =>
                                            <Input
                                                mbZero
                                                value={props.values.phone}
                                                label='Telefon*'
                                                onBlur={() => props.setFieldTouched('phone')}
                                                onChange={(e: any) => {
                                                    handleFormData(e.target.value, 'phone');
                                                    props.setFieldValue('phone', e.target.value);
                                                }}
                                            />
                                        }
                                    </Field>
                                    <ErrorMessage name="phone" render={msg => <div style={{ color: '#ea1c0a', fontWeight: 'bold' }}>{msg}</div>} />
                                </Col>
                                <Col xs={12} sm={6}>
                                    <Field label='Email*' id="email" name="email" placeholder="Email*">
                                        {({
                                            //@ts-ignore
                                            meta: { touched, error }
                                        }) =>
                                            <Input
                                                mbZero
                                                value={props.values.email}
                                                label='Email*'
                                                onBlur={() => props.setFieldTouched('email')}
                                                onChange={(e: any) => {
                                                    handleFormData(e.target.value, 'email');
                                                    props.setFieldValue('email', e.target.value);
                                                }}
                                            />
                                        }
                                    </Field>
                                    <ErrorMessage name="email" render={msg => <div style={{ color: '#ea1c0a', fontWeight: 'bold' }}>{msg}</div>} />
                                </Col>
                            </Row>
                            
                        </Form>
                    )}
                </Formik>
                <Row className="mt-2">
                    <ChoiceComponent
                        title="Adresa de livrare este:*"
                        options={[{ title: 'Adresa solicitantului', id: 1 }, { title: 'Altă adresă', id: 2 }]}
                        value={adressType}
                        onChange={handleAdressType}
                    />
                    {adressType === 2 &&
                        <OrderFormAdress formData={adress} handleFormData={handleAdressData} innerRef={adressRef} />
                    }
                </Row>

                <Row className="mt-2">
                    <h5>Articole selectate pentru comandă*</h5>
                    <DynamicTable format={formatArticole} data={orderList} isEditing={true} handler={handleOrderList} />
                </Row>

                <Row className="mb-2 mt-2">
                                <Col xs={12} sm={12}>
                                    <InputTextArea
                                        label="Mesaj"
                                        value={formData.message}
                                        maxLength={2000}
                                        placeholder='Scrie-ne cât mai detaliat cum anume te putem ajuta. În situatia în care subiectul nu este clar, lasă-ne numărul de telefon pentru a avea posibiltatea de a te contacta.'
                                        onChange={(e) => {
                                            handleFormData(e.target.value, 'message');
                                        }}
                                        mbZero
                                    />
                                    <span className="property-label mb-3" style={{fontSize: '14px'}}>Maxim 2000 de caractere.</span>
                                </Col>
                            </Row>

                <Row className="mt-3 mb-3">
                    <Col className="checkbox-container">
                        <input type="checkbox" className="create-account-checkbox" checked={check} onChange={() => setCheck(!check)} />
                        <span onClick={() => setCheck(!check)} style={{ marginLeft: '5px' }}>Sunt de acord cu </span><a className="red hover-red pointer" onClick={() => window.open('https://delgaz.ro/serviciul-de-informare/anunturi-de-vanzare/anunturi-repere/termeni-si-conditii', '_blank')}>Termenii și condițiile.</a>
                    </Col>
                </Row>
                <Row className="mt-3 mb-3">
                    <Col className="checkbox-container">
                        <input type="checkbox" className="create-account-checkbox" checked={terms} onChange={() => setTerms(!terms)} />
                        <span onClick={() => setTerms(!terms)} style={{ marginLeft: '5px' }}>Sunt de acord cu </span><a className="red hover-red pointer" onClick={() => window.open('https://delgaz.ro/conditii-de-utilizare', '_blank')}>Condițiile de utilizare ale site-ului.</a>
                    </Col>
                </Row>
                <Row className="mt-3 mb-3">
                    <Col className="checkbox-container">
                        <input type="checkbox" className="create-account-checkbox" checked={GDPR} onChange={() => setGDPR(!GDPR)} />
                        <span onClick={() => setGDPR(!GDPR)} style={{ marginLeft: '5px' }}>Am luat cunoștință de informațiile privind </span><a className="red hover-red pointer" onClick={() => window.open('https://delgaz.ro/serviciul-de-informare/anunturi-de-vanzare/anunturi-repere/protectia-datelor', '_blank')}>Protecția și confidențialitatea datelor.</a>
                    </Col>
                </Row>

                {validationError !== '' && <p className="fw-bold red">{validationError}</p>}
                {error !== '' && <p className="fw-bold red">{error}</p>}

                <div className="d-flex flex-row mt-4">
                    <Button type="submit" disabled={!terms || !GDPR || !check} onClick={() => submit()}>Trimite</Button>
                    <Button style={{ marginLeft: '12px' }} variant="secondary" onClick={() => {
                        setShow(false);
                        resetData();
                        }}>Renunță</Button>
                </div>
            </Modal.Body>
            <Modal.Footer>

            </Modal.Footer>
        </Modal>;
    };

    return (
        <div>
            {renderModal()}
            <ModalConfirm
                modalTitle={'Succes!'}
                showModal={showModalSuccess}
                onClose={() => {
                    setShowModalSuccess(false);
                    setShow(false);
                    resetData();
                }}
                >
                <h5>Comandă trimisă cu succes.</h5>
            </ModalConfirm>
        </div>
    );
};

export default FormularComanda;