import React from 'react';
import { DelgazWrapper } from '@components/global/DelgazWrapper';
import InfoPVAEFurnizor from '@components/pvae/InfoPVAEFurnizor';

export const PVAEInfo = () => {
    return (
        <DelgazWrapper>
            <InfoPVAEFurnizor />
        </DelgazWrapper>
    );
};