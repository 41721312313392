import { proxy, Proxy } from '@api/azureProxy';
import { store } from '@lib/store';

export async function getFreeDatesForNextMonths(numberOfMonths: number = 12) {
  const state = store.getState();
  const proxyData: Proxy = {
    method: 'GET',
    endPoint: `/utils/FreeDates/get_free_dates_next_months?numberOfMonths=${numberOfMonths}`,
    withAuthBearer: true
  };
  return proxy(proxyData, {
    headers: {
      Authorization: 'Bearer ' + state.auth.token,
      'Access-Control-Allow-Headers': 'Authorization',
      'Access-Control-Allow-Credentials': true,
      'access-control-allow-methods': 'GET'
    }
  });
}
