import DatePicker from '@components/common/Date/DatePicker';
import DateRange from '@components/common/Date/DateRange';
import { Dropdown } from '@components/common/Dropdown';
import { Input } from '@components/common/Input';
import { UTILITY_EE, UTILITY_GN } from '@constants/Utility';
import {
  // DECREC_DISCONNECT_REASON__ALTE_MOTIVE,
  // DECREC_DISCONNECT_REASON__CERERE_CLIENT_FINAL,
  DECREC_DISCONNECT_REASON__NEPLATA_CLIENT_FINAL,
  DECREC_TYPE_NAME_CANCEL_DISCONNECT,
  DECREC_TYPE_NAME_DISCONNECT,
  DECREC_TYPE_NAME_RECONNECT,
  TYPE_CANCEL_DISCONNECT,
  TYPE_DISCONNECT,
  TYPE_RECONNECT
} from '@constants/decRec/decRecConstants';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import { isObjectEmpty } from '../../../../helpers/object';
import { postDecrecRequestsCreate, postDecrecRequestsUpdate } from '@api/decrec/decrec';
import { isFriday, isWeekend } from 'date-fns';
import * as notification from '@lib/Notification';
import { useDispatch } from 'react-redux';
import { toggleLoader } from '@actions/LoaderActions';
import {
  GetDecrecDetailsResponseType,
  GetDecrecVerifyConsumptionPointResponseType,
  PostDecrecRequestsCreateResponseType
} from '@type/decrec';

type ModalAddPropsType = {
  headerData: GetDecrecVerifyConsumptionPointResponseType | any;
  decRecDetails: GetDecrecDetailsResponseType | null;
  isEdit: boolean;
  type: number;
  utility?: number;
  county: any;
  show: boolean;
  handleShow: (show: boolean) => void;
  isValid: boolean;
  requestId: string;
  setRequestId: (requestId: number) => void;
  reset: () => void;
  freeDates: string[];
};

// TYPE_DISCONNECT
const disconnectOptions = [
  { id: DECREC_DISCONNECT_REASON__NEPLATA_CLIENT_FINAL, name: 'Neplata client final' }
  // { id: DECREC_DISCONNECT_REASON__CERERE_CLIENT_FINAL, name: 'Cerere client final' },
  // { id: DECREC_DISCONNECT_REASON__ALTE_MOTIVE, name: 'Alte motive' }
];

const invoicesNotPayedItems = [
  { name: 'Dată început', label: 'Selectează dată început' },
  { name: 'Dată sfârșit', label: 'Selectează dată sfârșit' }
];

const ModalAdd = (props: ModalAddPropsType) => {
  const [sumbited, setSumbited] = useState(false);
  const [error, setError] = useState('');

  const dispatch = useDispatch();

  const [seletedDisconnectOption, setSeletedDisconnectOption] = useState('');
  const [disconnectedDate, setDisconnectedDate] = useState('');
  const [sold, setSold] = useState('');
  const [disconnectedReason, setDisconnectedReason] = useState('');
  const [electricEnerrgyQuantity, setElectricEnerrgyQuantity] = useState('');
  const [invoicesNotPayedStartDate, setInvoicesNotPayedStartDate] = useState<any>('');
  const [invoicesNotPayedEndDate, setInvoicesNotPayedEndDate] = useState<any>('');

  // TYPE_RECONNECT
  const [reconnectedDate, setReconnectedDate] = useState('');

  const [errors, setErrors] = useState<{ [key: string]: string }>({});

  useEffect(() => {
    if (props.decRecDetails) {
      if (props.type == TYPE_DISCONNECT || (props.type == TYPE_CANCEL_DISCONNECT && !props.decRecDetails.requestToCancel)) {
        //GN & EE
        setSeletedDisconnectOption(props.decRecDetails.terminationReason);
        setDisconnectedDate(props.decRecDetails.startDate || props.decRecDetails.startDateToCancel);
        setSold(`${props.decRecDetails.sold ?? ''}`);
        setDisconnectedReason(props.decRecDetails.otherTerminationReason ?? '');
        //EE
        setElectricEnerrgyQuantity(`${props.decRecDetails.cantitateEE ?? ''}`);
        setInvoicesNotPayedStartDate(props.decRecDetails.dataInceputNeplata ?? '');
        setInvoicesNotPayedEndDate(props.decRecDetails.dataSfarsitNeplata ?? '');
      } else if (props.type == TYPE_CANCEL_DISCONNECT) {
        //GN & EE
        setSeletedDisconnectOption(props.decRecDetails.requestToCancel.terminationReason);
        setDisconnectedDate(props.decRecDetails.requestToCancel.startDate);
        setSold(`${props.decRecDetails.requestToCancel.sold ?? ''}`);
        setDisconnectedReason(props.decRecDetails.requestToCancel.otherTerminationReason ?? '');
        //EE
        setElectricEnerrgyQuantity(`${props.decRecDetails.requestToCancel.cantitateEE ?? ''}`);
        setInvoicesNotPayedStartDate(props.decRecDetails.requestToCancel.dataInceputNeplata ?? '');
        setInvoicesNotPayedEndDate(props.decRecDetails.requestToCancel.dataSfarsitNeplata ?? '');
      } else if (props.type == TYPE_RECONNECT) {
        setReconnectedDate(props.decRecDetails.startDate ?? '');
      }
    }
  }, [props.decRecDetails]);

  useEffect(() => {
    if (sumbited) validateValues();
  }, [sumbited, seletedDisconnectOption, disconnectedReason, disconnectedDate, reconnectedDate]);

  const validateValues = () => {
    let errors: { [key: string]: string } = {};

    if (props.type == TYPE_DISCONNECT) {
      if (!seletedDisconnectOption) {
        errors['seletedDisconnectOption'] = 'Câmp obligatoriu!';
      }
      // if (seletedDisconnectOption && seletedDisconnectOption == DECREC_DISCONNECT_REASON__ALTE_MOTIVE && !disconnectedReason) {
      //   errors['disconnectedReason'] = 'Câmp obligatoriu!';
      // }
      if (!disconnectedDate) {
        errors['disconnectedDate'] = 'Câmp obligatoriu!';
      }
    }
    if (props.type == TYPE_RECONNECT) {
      if (!reconnectedDate) {
        errors['reconnectedDate'] = 'Câmp obligatoriu!';
      }
    }

    setErrors(errors);

    return isObjectEmpty(errors);
  };

  const handleSubmit = () => {
    setSumbited(true);
    setError('');
    if (!validateValues()) return;

    dispatch(toggleLoader(true));

    if (props.isEdit) {
      let payload = {
        RequestId: props.decRecDetails?.id,
        Utility: Number(props.utility),
        DataDeconectare: disconnectedDate ? moment(disconnectedDate).format('DD.MM.YYYY') : '',
        MotivDeconectare: seletedDisconnectOption,
        AlteMotive: disconnectedReason,
        Sold: Number(sold),
        DataReconectare: reconnectedDate ? moment(reconnectedDate).format('DD.MM.YYYY') : '',
        CantitateEE: Number(electricEnerrgyQuantity),
        DataInceputNeplata: invoicesNotPayedStartDate ? moment(invoicesNotPayedStartDate).format('DD.MM.YYYY') : '',
        DataSfarsitNeplata: invoicesNotPayedEndDate ? moment(invoicesNotPayedEndDate).format('DD.MM.YYYY') : '',
        DeleteExistingDocuments: false,
        DocumenteSolicitare: []
      };

      postDecrecRequestsUpdate(payload)
        .then((res: { descriereEroare: string; id: number; succes: boolean }) => {
          if (!res.succes) {
            if (res.descriereEroare) {
              notification.error(res.descriereEroare);
            } else setError('A apărut o eroare tehnică. Vă rugam reveniți mai târziu.');
          } else {
            notification.success('Cererea a fost salvată!');
            props.handleShow(false);
          }
          dispatch(toggleLoader(false));
        })
        .catch((err) => {
          setError('A apărut o eroare tehnică. Vă rugam reveniți mai târziu.');
          dispatch(toggleLoader(false));
        });
    } else {
      let payload: any = {
        atributeGenerice: {
          IdExtern: '',
          Scenariu: 'DECREC',
          DocumenteSolicitare: []
        },
        atributeSpecificeScenariului: {
          Utilitate: props.utility == UTILITY_EE ? 'EE' : 'GN',
          TipSolicitare:
            props.type == TYPE_DISCONNECT
              ? DECREC_TYPE_NAME_DISCONNECT
              : props.type == TYPE_RECONNECT
              ? DECREC_TYPE_NAME_RECONNECT
              : DECREC_TYPE_NAME_CANCEL_DISCONNECT,
          LocDeConsum: {
            POD: props.headerData.pod,
            Judet: props.county.countyName,
            CodJudet: props.county.countyCode,
            // Localitate: props.locality.localityName,
            // CodLocalitate: props.locality.localityCode,
            DataDeconectare: disconnectedDate ? moment(disconnectedDate).format('DD.MM.YYYY') : '',
            MotivDeconectare: seletedDisconnectOption,
            AlteMotive: disconnectedReason,
            Sold: Number(sold),
            DataReconectare: reconnectedDate ? moment(reconnectedDate).format('DD.MM.YYYY') : '',
            CantitateEE: Number(electricEnerrgyQuantity),
            DataInceputNeplata: invoicesNotPayedStartDate ? moment(invoicesNotPayedStartDate).format('DD.MM.YYYY') : '',
            DataSfarsitNeplata: invoicesNotPayedEndDate ? moment(invoicesNotPayedEndDate).format('DD.MM.YYYY') : ''
          }
        }
      };

      if (props.type == TYPE_CANCEL_DISCONNECT) {
        payload = {
          atributeGenerice: {
            IdExtern: '',
            Scenariu: 'DECREC',
            DocumenteSolicitare: []
          },
          atributeSpecificeScenariului: {
            Utilitate: props.utility == UTILITY_EE ? 'EE' : 'GN',
            TipSolicitare: DECREC_TYPE_NAME_CANCEL_DISCONNECT,
            LocDeConsum: {
              POD: props.headerData.pod,
              Judet: props.county.countyName,
              CodJudet: props.county.countyCode
              // Localitate: props.locality.localityName,
              // CodLocalitate: props.locality.localityCode
            }
          }
        };
      }

      postDecrecRequestsCreate(payload)
        .then((res: PostDecrecRequestsCreateResponseType) => {
          if (res.status == 'Eroare') {
            setError('A apărut o eroare tehnică. Vă rugam reveniți mai târziu.');
          } else if (res.locuriDeConsumInvalide && res.locuriDeConsumInvalide.length) {
            try {
              res.locuriDeConsumInvalide.forEach((ldci) => {
                ldci.erori.forEach((err) => {
                  notification.error(err.mesaj);
                });
              });
            } catch (err) {
              setError('A apărut o eroare tehnică. Vă rugam reveniți mai târziu.');
            }
          } else {
            notification.success('Cererea a fost salvată!');
            props.reset();
            props.setRequestId(res.rssId);
            props.handleShow(false);
          }
          dispatch(toggleLoader(false));
        })
        .catch((err) => {
          setError('A apărut o eroare tehnică. Vă rugam reveniți mai târziu.');
          dispatch(toggleLoader(false));
        });
    }
  };

  const filterDateIsWorkingDay = (date: Date) => {
    let isDateAvailable = true;

    let today = moment().startOf('day').toDate();

    if (props.type == TYPE_DISCONNECT && isFriday(today) && moment(today).isSame(date, 'day')) isDateAvailable = false;

    if (date < today) isDateAvailable = false;

    props.freeDates.forEach((fd) => {
      let freeDate = moment(fd).toDate();
      if (
        date.getFullYear() === freeDate.getFullYear() &&
        date.getMonth() === freeDate.getMonth() &&
        date.getDate() === freeDate.getDate()
      ) {
        isDateAvailable = false;
      }
    });

    if (!isDateAvailable) return false;

    return !isWeekend(date);
  };

  return (
    <Modal className="rss-modal" backdrop={'static'} show={props.show} onHide={() => props.handleShow(false)}>
      <Modal.Header closeButton>
        <div className="p-0">
          <h5 className="m-0 modal-title">Detalii loc de consum</h5>
        </div>
      </Modal.Header>
      <Modal.Body>
        <Row nameName="mb-4">
          <Col xs={12} sm={6} className="d-flex flex-column">
            <div className="details-block h-100">
              <div className="block-body">
                <div className="property-container">
                  <span className="property-key">Cod loc consum</span>
                  <span className="property-value">{props.headerData?.consumptionPointCode}</span>
                </div>

                <div className="property-container">
                  <span className="property-key">Cod punct de măsurare</span>
                  <span className="property-value">{props.headerData?.pod}</span>
                </div>

                <div className="property-container">
                  <span className="property-key">Stare contract</span>
                  <span className="property-value">{props.headerData?.contractStatusName}</span>
                </div>

                <div className="property-container">
                  <span className="property-key">Adresa</span>
                  <span className="property-value">{props.headerData?.address}</span>
                </div>
              </div>
            </div>
          </Col>

          <Col xs={12} sm={6} className="d-flex flex-column">
            <div className="details-block h-100">
              <div className="block-body">
                <div className="property-container">
                  <span className="property-key">Stare contor</span>
                  <span className="property-value">{props.headerData?.meterStatusName}</span>
                </div>

                <div className="property-container">
                  <span className="property-key">Stare instalatie</span>
                  <span className="property-value">{props.headerData?.installationStatusName}</span>
                </div>

                <div className="property-container">
                  <span className="property-key">Denumirea furnizorului</span>
                  <span className="property-value">{props.headerData?.supplierName}</span>
                </div>
              </div>
            </div>
          </Col>
        </Row>

        {(props.type == TYPE_DISCONNECT || props.type == TYPE_CANCEL_DISCONNECT) && (
          <>
            <Row>
              <div>
                <div className="section-red-border mt-5" />
                <h5 className="m-0 mb-3">Motivul deconectării*</h5>
              </div>

              <Col xs={12} className="mb-3">
                <Dropdown
                  style={{ marginBottom: '0.25rem' }}
                  options={disconnectOptions}
                  label={'Motivul deconectării*'}
                  defaultPlaceholder={'Alege'}
                  onChange={(value) => {
                    setSeletedDisconnectOption(value);
                  }}
                  disabled={props.type == TYPE_CANCEL_DISCONNECT}
                  value={seletedDisconnectOption}
                />
                {errors['seletedDisconnectOption'] && (
                  <div style={{ color: 'red', fontWeight: 'bold' }}>{errors['seletedDisconnectOption']}</div>
                )}
              </Col>

              {/* {seletedDisconnectOption == DECREC_DISCONNECT_REASON__ALTE_MOTIVE && (
                <Col xs={12} className="mb-3">
                  <Input
                    label="Introduceţi motivul deconectării:*"
                    name="disconnectedReason"
                    type="text"
                    value={disconnectedReason}
                    mbZero
                    disabled={props.type == TYPE_CANCEL_DISCONNECT}
                    onChange={(e) => {
                      setDisconnectedReason(e.target.value);
                    }}
                  />
                  {errors['disconnectedReason'] && <div style={{ color: 'red', fontWeight: 'bold' }}>{errors['disconnectedReason']}</div>}
                </Col>
              )} */}

              <Col xs={12} className="mb-3">
                <DatePicker
                  label="Data deconectării*"
                  name="Data deconectării*"
                  mbZero
                  filterDate={filterDateIsWorkingDay}
                  disabled={props.type == TYPE_CANCEL_DISCONNECT}
                  selected={disconnectedDate !== '' ? moment(disconnectedDate).toDate() : disconnectedDate}
                  onChange={(e: any) => {
                    if (e) {
                      setDisconnectedDate(moment(e).format('YYYY-MM-DD'));
                    } else {
                      setDisconnectedDate('');
                    }
                  }}
                />
                {errors['disconnectedDate'] && <div style={{ color: 'red', fontWeight: 'bold' }}>{errors['disconnectedDate']}</div>}
              </Col>
              <div style={{ marginBottom: '2rem' }}>
                <hr className="m-0 " />
              </div>
            </Row>

            <Row>
              <div>
                <div className="section-red-border mt-4" />
                <h5 className="m-0 mb-3">Alte informații</h5>
              </div>
              <Col xs={12} className="mb-3">
                <Input
                  label="Sold"
                  name="sold"
                  type="number"
                  value={sold}
                  mbZero={props.utility == UTILITY_GN}
                  onChange={(e) => {
                    setSold(e.target.value);
                  }}
                  disabled={props.type == TYPE_CANCEL_DISCONNECT}
                />
              </Col>

              {props.utility == UTILITY_EE && (
                <Col xs={12} className="mb-2">
                  <Input
                    label="Cantitatea de energie electrica"
                    name="electricEnerrgyQuantity"
                    type="number"
                    value={electricEnerrgyQuantity}
                    onChange={(e) => {
                      setElectricEnerrgyQuantity(e.target.value);
                    }}
                    disabled={props.type == TYPE_CANCEL_DISCONNECT}
                  />
                </Col>
              )}

              {props.utility == UTILITY_EE && (
                <Col xs={12} className="mb-3">
                  <strong className="mb-1">Perioada pentru care nu s-au achitat facturile</strong>
                  <DateRange
                    items={invoicesNotPayedItems}
                    values={{
                      'Dată început': invoicesNotPayedStartDate ? moment(invoicesNotPayedStartDate).toDate() : null,
                      'Dată sfârșit': invoicesNotPayedEndDate ? moment(invoicesNotPayedEndDate).toDate() : null
                    }}
                    mbZero
                    col={6}
                    onChange={(date: any) => {
                      if (date.name === 'Dată început') {
                        setInvoicesNotPayedStartDate(date.value);
                      } else {
                        setInvoicesNotPayedEndDate(date.value);
                      }
                    }}
                    disabled={props.type == TYPE_CANCEL_DISCONNECT}
                  />
                </Col>
              )}

              <div>
                <hr className="m-0 " />
              </div>
            </Row>
          </>
        )}

        {props.type == TYPE_RECONNECT && (
          <>
            <Row>
              <div>
                <div className="section-red-border mt-5" />
                <h5 className="m-0 mb-3">Reconectare*</h5>
              </div>

              <Col xs={12} className="mb-3 mt-4">
                <DatePicker
                  filterDate={filterDateIsWorkingDay}
                  label="Data reconectării*"
                  name="Data reconectării*"
                  mbZero
                  selected={reconnectedDate !== '' ? moment(reconnectedDate).toDate() : reconnectedDate}
                  onChange={(e: any) => {
                    if (e) {
                      setReconnectedDate(moment(e).format('YYYY-MM-DD'));
                    } else {
                      setReconnectedDate('');
                    }
                  }}
                />
                {errors['reconnectedDate'] && <div style={{ color: 'red', fontWeight: 'bold' }}>{errors['reconnectedDate']}</div>}
              </Col>

              <div style={{ marginBottom: '2rem' }}>
                <hr className="m-0 " />
              </div>
            </Row>
          </>
        )}

        {error && <div style={{ color: 'red', fontWeight: 'bold' }}>{error}</div>}
      </Modal.Body>

      <Modal.Footer>
        <div className="d-flex justify-content-between w-100 align-items-center">
          <p className="mb-0 me-3" style={{ maxWidth: '30rem' }}>
            {props.type != TYPE_CANCEL_DISCONNECT &&
              'Pentru a putea adăuga locul de consum pe solicitare, este necesară completarea tuturor câmpurilor obligatorii, cu date valide.'}
          </p>

          <div>
            <Button className="mb-2" variant="secondary" style={{ marginRight: '8px' }} onClick={() => props.handleShow(false)}>
              Renunță
            </Button>

            {props.type == TYPE_CANCEL_DISCONNECT && props.isEdit ? (
              <></>
            ) : (
              <Button className="mb-2" onClick={() => handleSubmit()} disabled={!props.isValid}>
                Salvează
              </Button>
            )}
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalAdd;
