import { Action } from '.';
import { UPDATE_NOTIFICATIONS, UPDATE_NOTIFICATION_COUNT, EXTERNAL_NOTIF_UPDATE } from '@constants/ActionTypes/PostTypes';

export class NotificationPayload {
  summary: any[] = [];
  itemsCount: number = 0;
  externalUpdate: number = 0;
}

const initialState: NotificationPayload = { summary: [], itemsCount: 0, externalUpdate: 0 };

export function notifications(state = initialState, action = {} as Action<NotificationPayload>) {
  const { type, payload } = action;

  switch (type) {
    case UPDATE_NOTIFICATIONS: {
      return Object.assign({}, state, {
        summary: payload
      });
    }
    case UPDATE_NOTIFICATION_COUNT: {
      return Object.assign({}, state, {
        itemsCount: payload
      });
    }
    case EXTERNAL_NOTIF_UPDATE: {
      return Object.assign({}, state, {
        externalUpdate: payload
      });
    }
    default:
      return state;
  }
}