import moment from 'moment';

export const downloadBlobWithFilename = (
  blobPart: BlobPart,
  fileName?: string | null,
  extension?: string | null,
  withTimestamp?: boolean | null,
  onSucces?: (() => any) | null,
  onError?: ((error: any) => any) | null,
  fileNameWithExtension?: string | null
) => {
  try {
    setTimeout(() => {
      const blob: Blob = new Blob([blobPart]);

      const finalFileName: string = fileNameWithExtension
        ? fileNameWithExtension
        : `${fileName}${withTimestamp ? '-' : ''}${withTimestamp ? moment().format('YYMMDDHms') : ''}${extension}`;
      const objectUrl: string = URL.createObjectURL(blob);
      const a: HTMLAnchorElement = document.createElement('a') as HTMLAnchorElement;

      a.href = objectUrl;
      a.download = finalFileName;
      document.body.appendChild(a);
      a.click();

      document.body.removeChild(a);
      URL.revokeObjectURL(objectUrl);
      onSucces && onSucces();
    }, 500);
  } catch (error) {
    onError && onError(error);
  }
};
