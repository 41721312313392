import React from 'react';

import { DataTypes } from '@type/backOfficeModule';
import moment from 'moment';
import CautareProgramare from '@components/dev/System/ProgramariCitiri/CautareProgramare';
import { BackOfficeContext, BackOfficeContextType } from '@contexts/backoffice-context';
import Base64FileListReadOnly from './Fields/Base64FileListReadOnly';
import { DownloadFile } from '@api/backOffice/fetchResults';
import { useDispatch } from 'react-redux';
import { toggleLoader } from '@actions/LoaderActions';
import * as notification from '@lib/Notification';
import ProgramariCitiriContoare from '@components/programariCitiri/ProgramariCitiriContoare';

const PROCESS_TYPE_ID_CITIRI_CONTOARE = 3;

const ColumnValue = ({ column, item }: any) => {
  const { reloadListResults } = React.useContext(BackOfficeContext) as BackOfficeContextType;
  const dispatch = useDispatch();

  const renderColumn = () => {
    let col: string = column.name.charAt(0).toLowerCase() + column.name.slice(1);
    let value: string | JSX.Element | any;

    if (column.dataTypeId === DataTypes.DateTime) {
      value = item[col] ? moment.utc(item[col])?.local()?.format('DD.MM.YYYY HH:mm:ss') : '-';
    } else if (column.dataTypeId === DataTypes.Date) {
      value = item[col] ? moment.utc(item[col])?.local()?.format('DD.MM.YYYY') : '-';
    } else if (column.dataTypeId === DataTypes.HTML) {
      //[ACTIUNE = LipsaAccces] (folosire form adaugare sau editare programare citire din FO)
      if (item[col].includes("uid='LipsaAccces'")) {
        const processTypeId = item['processTypeId'];
        let edit = item[col].substring(item[col].lastIndexOf('edit='));
        //eslint-disable-next-line
        edit = edit.substring(5, edit.indexOf('&'));

        let contact = item[col].substring(item[col].lastIndexOf('contact='));
        contact = contact.substring(8, contact.indexOf('&'));

        let consumptionPointCode = item[col].substring(item[col].lastIndexOf('consumptionPointCode='));
        consumptionPointCode = consumptionPointCode.substring(21, consumptionPointCode.indexOf('&'));

        let kid = item[col].substring(item[col].lastIndexOf('kid='));
        kid = kid.substring(4, kid.indexOf('"'));

        let linkText = item[col].substring(item[col].indexOf('>'));
        linkText = linkText.substring(1, linkText.indexOf('<'));

        value =
          processTypeId == PROCESS_TYPE_ID_CITIRI_CONTOARE ? (
            <ProgramariCitiriContoare
              kid={kid}
              isUsedInBO={true}
              contactNumber={contact}
              codLocConsum={consumptionPointCode}
              linkText={linkText}
              reloadContent={reloadListResults}
            />
          ) : (
            <CautareProgramare
              kid={kid}
              isUsedInBO={true}
              contactNumber={contact}
              codLocConsum={consumptionPointCode}
              linkText={linkText}
              reloadContent={reloadListResults}
            />
          );
      } else {
        value = <div dangerouslySetInnerHTML={{ __html: item[col] }} />;
      }
    } else if (column.dataTypeId === DataTypes.Url) {
      if (!item[col]) return;
      if (item[col]?.isInternal) {
        value = getUrlInternal(item[col]);
      } else {
        value = <a href={item[col]?.url}>{item[col]?.title}</a>;
      }
    } else if (column.dataTypeId === DataTypes.UrlList) {
      value = '';
      if (item[col] && Array.isArray(item[col])) {
        value = item[col].map(
          (
            urlItem: {
              title: string;
              url: string;
              isInternal: boolean;
              extension: string;
              fileName: string;
            },
            index: number
          ) => {
            if (urlItem.isInternal) {
              return <div key={index}>{getUrlInternal(urlItem)}</div>;
            } else
              return (
                <div key={index}>
                  <a href={urlItem.url}>{urlItem.title}</a>
                </div>
              );
          }
        );
      }
    } else if (column.dataTypeId === DataTypes.Bool) {
      value = item[col] ? 'Da' : 'Nu';
    } else if (column.dataTypeId === DataTypes.Base64FileList) {
      value = (
        <Base64FileListReadOnly
          label={''}
          viewFilesValues={column.value.map((item: any) => {
            return {
              blobName: item.fileName,
              originalFileName: item.fileName,
              documentUrl: item.fileDownloadUrl
            };
          })}
        />
      );
    } else if (column.dataTypeId === DataTypes.StringList) {
      value = '';
      if (item[col] && item[col].length > 0) {
        if (item[col].length == 1) {
          value = item[col][0];
        } else {
          value = item[col].map((item: string, index: number) => (
            <p key={index} className="m-0">
              {item};
            </p>
          ));
        }
      }
    } else {
      value = item[col];
    }

    if (value) {
      return value;
    } else if (column.hideIfEmptyValue) {
      return '';
    } else if (column.messageForEmptyValue) {
      return column.messageForEmptyValue;
    } else if (value == 0) {
      return value;
    } else {
      return '-';
    }
  };

  const getUrlInternal = (
    urlItem:
      | any
      | {
          title: string;
          url: string;
          isInternal: boolean;
          extension: string;
          fileName: string;
        }
  ) => {
    return (
      <a
        href="#!"
        onClick={async () => {
          dispatch(toggleLoader(true));
          let res = '' as BlobPart;
          let isError = false;
          res = await DownloadFile(urlItem?.url, true).catch((error) => {
            isError = true;
            dispatch(toggleLoader(false));
            try {
              const decoder = new TextDecoder('utf-8');
              const decodedString = decoder.decode(error);
              const obj = JSON.parse(decodedString);
              const status = obj.status;
              if (status == 401) {
                notification.error('Sesiunea a expirat. Pagina se va reâncărca automat');
                setTimeout(() => {
                  window.location.reload();
                }, 5000);
              } else {
                notification.error('A intervenit o eroare tehnică. Vă rugăm reveniți mai târziu.');
              }
            } catch (error) {
              notification.error('A intervenit o eroare tehnică. Vă rugăm reveniți mai târziu.');
            }
          });
          if (!isError) {
            try {
              setTimeout(() => {
                const blob: Blob = new Blob([res]);
                const fileName: string = urlItem?.fileName
                  ? `${urlItem?.fileName}-${moment().format('YYMMDDHms')}${urlItem?.extension}`
                  : `Fisier-${moment().format('YYMMDDHms')}.pdf`;
                const objectUrl: string = URL.createObjectURL(blob);
                const a: HTMLAnchorElement = document.createElement('a') as HTMLAnchorElement;

                a.href = objectUrl;
                a.download = fileName;
                document.body.appendChild(a);
                a.click();

                document.body.removeChild(a);
                URL.revokeObjectURL(objectUrl);
                dispatch(toggleLoader(false));
              }, 500);
            } catch (error) {
              dispatch(toggleLoader(false));
            }
          }
        }}
      >
        {urlItem?.title}
      </a>
    );
  };

  return <>{renderColumn()}</>;
};

export default React.memo(ColumnValue);
