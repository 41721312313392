import React, { useEffect, useState } from 'react';
import { Input } from '@components/common/Input';
import { Row, Col, Button } from 'react-bootstrap';
import Mask from './Mask';
import Tippy from '@tippyjs/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import MaskedInput from '@components/common/Input/MaskedInput';

const Verify = (props: {
  contractNumber: any;
  handleContractNumber: any;
  pod: any;
  handlePod: any;
  clc: any;
  handleClc: any;
  verify: any;
  error: string;
  handleError: any;
  handleData: any;
}) => {
  const { contractNumber, handleContractNumber, pod, handlePod, clc, handleClc, verify, error, handleError, handleData } = props;
  const [contactNumberError, setContactNumberError] = useState('');
  const [clcError, setClcError] = useState('');
  const [podError, setPodError] = useState('');
  const [podTouched, setPodTouched] = useState<any>(false);

  useEffect(() => {
    if (pod == '__________') {
      handlePod('');
    }
  }, [pod]);

  const checkFields = () => {
    let isValid = true;
    setContactNumberError('');
    setClcError('');
    setPodError('');
    handleError('');
    handleData(null);
    handleContractNumber(contractNumber.trim());
    handlePod(pod.trim());

    if (contractNumber.trim().length === 0) {
      isValid = false;
      setContactNumberError('Număr contact obligatoriu');
    }

    if (contractNumber.includes('_')) {
      isValid = false;
      setContactNumberError('Numărul este format din 10 caractere');
    }

    if (clc.length === 0 && pod.trim().length === 0) {
      isValid = false;
      setPodError('Completarea unuia din cele 2 campuri (CLC/POD) este obligatorie');
      setClcError('Completarea unuia din cele 2 campuri (CLC/POD) este obligatorie');
      return;
    }

    if (clc.includes('_')) {
      isValid = false;
      setClcError('Cod loc consum este format din 10 caractere');
    }

    if (pod.includes('_')) {
      isValid = false;
      setPodError('Punct de măsurare (POD) este format din 10 caractere');
    }

    if (isValid) {
      verify();
    }
  };

  return (
    <div className="mt-3 mb-3">
      <Row className="mb-2">
        <Col xs={12} sm={3} className="mb-4">
          <div className="position-relative">
            <Mask
              value={contractNumber}
              onChange={(e) => handleContractNumber(e.target.value)}
              mask={'0111111111'}
              type={'contact'}
              label="Număr contact"
              mbZero
            />
            <span className="position-absolute" style={{ right: '4%', bottom: '-36px', zIndex: 5 }}>
              <Tippy
                content={
                  <div className="p-3" style={{ background: 'rgba(0,0,0,0.8)', color: 'white', borderRadius: '6px' }}>
                    Regăsești Numărul de contact în colțul din dreapta jos de pe înștiințarea transmisă de Delgaz Grid.
                  </div>
                }
                duration={0}
                placement="bottom"
              >
                <div className="icon-div" style={{ marginLeft: '5px' }}>
                  <FontAwesomeIcon icon={faCircleInfo} className="icon" color="red" />
                </div>
              </Tippy>
            </span>
          </div>
          {contactNumberError !== '' && <p className="red">{contactNumberError}</p>}
        </Col>
        <Col xs={12} sm={3} className="mb-2">
          <div className="position-relative">
            <Mask
              value={clc}
              onChange={(e) => handleClc(e.target.value)}
              mask={'0123333333'}
              type={'clc'}
              label="Cod loc de consum"
              mbZero
            />
            <span className="position-absolute" style={{ right: '4%', bottom: '-36px', zIndex: 5 }}>
              <Tippy
                content={
                  <div className="p-3" style={{ background: 'rgba(0,0,0,0.8)', color: 'white', borderRadius: '6px' }}>
                    Regăsești Codul locului de consum în colțul din dreapta jos de pe înștiințarea transmisă de Delgaz Grid.
                  </div>
                }
                duration={0}
                placement="bottom"
              >
                <div className="icon-div" style={{ marginLeft: '5px' }}>
                  <FontAwesomeIcon icon={faCircleInfo} className="icon" color="red" />
                </div>
              </Tippy>
            </span>
          </div>

          {clcError !== '' && <p className="red">{clcError}</p>}
        </Col>
        <Col className='verify_or' xs={12}>
          <div className="d-flex justify-content-center" style={{ marginTop: '8px' }}>
            sau
          </div>
        </Col>
        <Col xs={12} sm={3} className="mb-2">
          {pod && (pod[0] == 'D' || pod[0] == 'E') ? (
            <MaskedInput
              value={pod}
              mbZero
              autoFocus
              preloadedStartDisabled
              onChange={(e: any) => {
                const currentValue = (e.target.value ? e.target.value.toLocaleUpperCase() : '') as string;
                handlePod(currentValue);
              }}
              utility={pod[0] == 'D' ? 'GN' : 'EE'}
              label="Punct de măsurare (POD)"
              mask={'0123333333'}
            />
          ) : (
            <Input
              label="Punct de măsurare (POD)"
              mbZero
              value={pod}
              autoFocus={podTouched}
              onChange={(e: any) => {
                const currentValue = (e.target.value ? e.target.value.toLocaleUpperCase() : '') as string;
                if (currentValue == 'D' || currentValue == 'E' || currentValue.startsWith('DEG') || currentValue.startsWith('EMO')) {
                  setPodTouched(true);
                  handlePod(currentValue);
                }
              }}
            />
          )}

          {podError !== '' && <p className="red">{podError}</p>}
        </Col>
      </Row>
      {error !== '' && <p className="red fw-bold">{error}</p>}
      <Row className="mb-2">
        <div>
          <Button className="d-flex flex-row align-items-center" onClick={() => checkFields()}>
            {/* <FontAwesomeIcon icon={faMagnifyingGlass} style={{ marginRight: '5px' }} /> */}
            <span>Căutare</span>
          </Button>
        </div>
      </Row>
      <Row>
        <span>Regăsiți Număr contact și Cod loc de consum în colțul din dreapta jos de pe înștiințarea transmisă de Delgaz Grid.</span>
      </Row>
    </div>
  );
};

export default Verify;
