import React from 'react';
import {TabsRacordare} from '@components/dev/Components/Tabs';
import { DelgazWrapper } from '@components/global/DelgazWrapper';
import { TabsKentico } from '../../../typescript/kentico-types';

export type TabsProps = { kentico: TabsKentico };

export const TabsRacordareComponent = (props: TabsProps): JSX.Element => {
  return (
    <DelgazWrapper>
      <TabsRacordare {...props} />
    </DelgazWrapper>
  );
};