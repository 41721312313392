import { toggleLoader } from '@actions/LoaderActions';
import { GetCounties, GetLocalities } from '@api/geoApi';
import Dropdown, { DropdownOptionType } from '@components/common/Dropdown/Dropdown';
import RomaniaMapByCounties from '@components/dev/Components/RomaniaMap/RomaniaMapByCounties';
import { CountyTemplate, LocalityTemplate } from '@type/geo';
import { useEffect, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { getQueryParam } from '../../helpers/urlUtils';
import moment from 'moment';
import { getInterruptionsCountEE, getInterruptionsEE } from '@api/intreruperiProgramateEE/intreruperiProgramateEE';
import { GENERAL_ERROR_MESSAGE } from '@constants/Errors';
import SearchInput from '@components/common/ActionSearch/SearchInput';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClockFour } from '@fortawesome/free-regular-svg-icons';

interface InterruptionCount {
  countyCode: string;
  count: number;
}

interface Interruption {
  id: number;
  creRequestId: number;
  statusId: number;
  countyCode: string;
  countyName: string;
  description: string;
  startDate: string;
  endDate: string;
  startHour: string;
  endHour: string;
  utility: string;
  installation: string;
  scop: string;
  kidEntryUser: string;
  kidUpdateUser: string;
  entryDate: string;
  updateDate: string;
  localities: Locality[];
  streets: Street[];
}

interface Street {
  id: number;
  plannedInterruptionId: number;
  localityCode: string;
  streetCode: string;
  streetName: string;
  active: boolean;
  entryDate: string;
}

interface Locality {
  id: number;
  plannedInterruptionId: number;
  localityCode: string;
  localityName: string;
  active: boolean;
  entryDate: string;
  hasStreets: boolean;
}

export const PLANNED_INTERRUPTION_STATUS_ID_CANCELED = 2;

const IntreruperiProgramateEE = (props: any) => {
  const [county, setCounty] = useState('');
  const [locality, setLocality] = useState('');
  const [counties, setCounties] = useState<CountyTemplate[]>([]);
  const [localities, setLocalities] = useState<LocalityTemplate[]>([]);

  const [interruptions, setInterruptions] = useState<Interruption[]>([]);

  const [interruptionsCount, setInterruptionsCount] = useState<InterruptionCount[]>([]);

  const [pageNumber, setPageNumber] = useState(0);
  //eslint-disable-next-line
  const [pageSize, setPageSize] = useState(10);

  const [showLoadMoreButton, setShowLoadMoreButton] = useState(false);

  const [keywords, setKeywords] = useState('');
  const [dateFilter, setDateFilter] = useState<'ALL' | 'TODAY' | 'CURRENT_WEEK' | ''>('ALL');

  const [error, setError] = useState('');
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(toggleLoader(true, 'GetCounties'));
    GetCounties('EE').then((res) => {
      setCounties(res);
      dispatch(toggleLoader(false, 'GetCounties'));
    });
    const countyCodeParam = getQueryParam('countyCode');
    setCounty(countyCodeParam ?? '');

    dispatch(toggleLoader(true, 'getInterruptionsCountEE'));
    getInterruptionsCountEE()
      .then((res: InterruptionCount[]) => {
        setInterruptionsCount(res);
        dispatch(toggleLoader(false, 'getInterruptionsCountEE'));
      })
      .catch((err) => {
        dispatch(toggleLoader(false, 'getInterruptionsCountEE'));
      });
  }, []);

  useEffect(() => {
    if (county) fetchInterruptionsEE(true);
  }, [pageNumber]);

  useEffect(() => {
    setInterruptions([]);
    setPageNumber(1);
    if (county) fetchInterruptionsEE();
  }, [county, locality, dateFilter]);

  useEffect(() => {
    if (county) {
      GetLocalities(county).then((res) => setLocalities(res));
      setLocality('');
    }
  }, [county]);

  const handleCountySelect = (code: string) => {
    setCounty(code);
  };

  const fetchInterruptionsEE = (keepPrevious?: boolean) => {
    console.log('search');
    dispatch(toggleLoader(true, 'fetchInterruptionsEE'));
    setError('');

    let payload: any = {
      pageNumber: pageNumber,
      pageSize: pageSize,
      countyCode: county,
      localityCode: locality,
      isCurrentDay: dateFilter == 'TODAY',
      isCurrentWeek: dateFilter == 'CURRENT_WEEK',
      keywords: keywords,
      active: true
    };

    getInterruptionsEE(payload)
      .then((res) => {
        if (keepPrevious) {
          setInterruptions([...interruptions, ...res.items]);
        } else {
          setInterruptions([...res.items]);
        }
        setShowLoadMoreButton(res.metadata.pageIndex < res.metadata.totalItemsCount / res.metadata.pageSize);
        dispatch(toggleLoader(false, 'fetchInterruptionsEE'));
      })
      .catch((err) => {
        setError(GENERAL_ERROR_MESSAGE);
        dispatch(toggleLoader(false, 'fetchInterruptionsEE'));
      });
  };

  return (
    <div>
      <div className="d-flex flex-wrap justify-content-center">
        <div className="overflow-auto">
          <RomaniaMapByCounties
            onCountySelect={handleCountySelect}
            countiesWithDetails={counties.map((c: CountyTemplate, i: number) => {
              return {
                copuntyDetails: interruptionsCount.find((ic) => ic.countyCode == c.countyCode)?.count ?? 0,
                countyCode: c.countyCode
              };
            })}
          />
        </div>

        <div className="scheduled-interruptions-ee_dropdown-container me-4">
          <h4 className="mb-3">Întreruperi programate</h4>

          <p className="mb-3">
            Selectează județul pentru care dorești să afli informații despre întreruperile programate ale serviciului de distribuție energie
            electrică.
          </p>

          <Dropdown
            options={
              counties.map((option, index) => ({
                id: option.countyCode,
                name: option.countyName
              })) as DropdownOptionType[]
            }
            label={'Județ'}
            defaultPlaceholder={'Alege județul'}
            onChange={(value) => {
              setCounty(value);
            }}
            value={county}
          />

          <Dropdown
            options={
              localities.map((option, index) => ({
                id: option.localityCode,
                name: option.localityName
              })) as DropdownOptionType[]
            }
            label={'Localitate'}
            onChange={(value) => {
              setLocality(value);
            }}
            defaultPlaceholder={'Alege localitatea'}
            displaySearch={true}
            value={locality}
          />

          <p className="mb-3">
            Vezi întreruperile finalizate{' '}
            <a href="/energie-electrica/intreruperi-programate-energie-electrica/intreruperi-finalizate" target="_blank">
              aici
            </a>
          </p>

          <p>
            Vrei să te informăm printr-un e-mail, în cazul în care locuința ta va fi afectată de o întrerupere programată de energie
            electrică? Dacă da, apasă{' '}
            <a href="/energie-electrica/intreruperi-programate-energie-electrica/abonare" target="_blank">
              aici
            </a>
          </p>
        </div>

        <Row className="p-3 w-100">
          {county && (
            <>
              <Col xs={12} sm={12} md={6}>
                <h4 className="red mb-3">Întreruperi programate în judetul {counties.find((c) => c.countyCode == county)?.countyName}</h4>
              </Col>

              <Col xs={12} sm={12} md={6} className="d-flex flex-wrap align-items-center justify-content-center justify-content-sm-end">
                <FontAwesomeIcon icon={faClockFour} color="#ea1c0a" />

                <span
                  onClick={() => {
                    setDateFilter('ALL');
                  }}
                  className={`ms-2 mt-1 fw-bold pointer ${dateFilter == 'ALL' ? 'rounded-red-box' : 'rounded-lightgrey-box'}`}
                >
                  Toate
                </span>
                <span
                  onClick={() => {
                    setDateFilter('TODAY');
                  }}
                  className={`ms-2 mt-1 fw-bold pointer ${dateFilter == 'TODAY' ? 'rounded-red-box' : 'rounded-lightgrey-box'}`}
                >
                  Azi
                </span>
                <span
                  onClick={() => {
                    setDateFilter('CURRENT_WEEK');
                  }}
                  className={`ms-2 mt-1 fw-bold pointer ${dateFilter == 'CURRENT_WEEK' ? 'rounded-red-box' : 'rounded-lightgrey-box'}`}
                >
                  Saptămâna curentă
                </span>

                <SearchInput
                  className="ms-3 mt-1"
                  onSearch={() => fetchInterruptionsEE()}
                  placeholder="Caută"
                  searchInputValue={keywords}
                  setSearchInputValue={(value) => setKeywords(value)}
                  width={175}
                />
              </Col>
            </>
          )}

          {interruptions.length > 0 && county && (
            <div className="general-table-container">
              <table>
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Județ</th>
                    <th>Zone afectate</th>
                    <th>Perioada</th>
                    <th>Mențiuni</th>
                  </tr>
                </thead>
                <tbody>
                  {interruptions.map((interruption: Interruption, idx: number) => (
                    <tr key={idx}>
                      <td>
                        <strong className="black">{interruption.id}</strong>
                      </td>

                      <td>
                        <strong className="black">{interruption.countyName ?? '-'}</strong>
                      </td>

                      <td>
                        <div className="d-flex flex-column">
                          {interruption.localities.map((l, index) => (
                            <span key={index}>
                              <strong className="black">
                                {l.localityName} {interruption.streets.filter((s) => s.localityCode == l.localityCode).length ? '-' : ''}{' '}
                              </strong>
                              {interruption.streets.filter((s) => s.localityCode == l.localityCode).length
                                ? interruption.streets.filter((s) => s.localityCode == l.localityCode).length == 1
                                  ? 'Strada: '
                                  : 'Străzile: '
                                : ''}{' '}
                              {interruption.streets
                                .filter((s) => s.localityCode == l.localityCode)
                                .map((s) => s.streetName)
                                .join(', ')}
                            </span>
                          ))}
                        </div>
                      </td>

                      <td>
                        <div className="d-flex flex-column">
                          <span>
                            Data de începere:{' '}
                            <strong className="black">{moment(interruption.startDate).format('DD.MM.YYYY - HH:mm')}</strong>
                          </span>
                          <span>
                            Data de finalizare:{' '}
                            <strong className="black">{moment(interruption.endDate).format('DD.MM.YYYY - HH:mm')}</strong>
                          </span>
                        </div>
                      </td>

                      <td>
                        {interruption.statusId == PLANNED_INTERRUPTION_STATUS_ID_CANCELED && (
                          <span className="rounded-white-box text-center mb-1" style={{ whiteSpace: 'nowrap' }}>
                            LUCRARE ANULATĂ
                          </span>
                        )}

                        <p
                          style={{
                            textDecoration: interruption.statusId == PLANNED_INTERRUPTION_STATUS_ID_CANCELED ? 'line-through' : ''
                          }}
                          dangerouslySetInnerHTML={{ __html: interruption.description }}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}

          {showLoadMoreButton && county ? (
            <div style={{ padding: '12px' }}>
              <Button
                onClick={() => {
                  setPageNumber(pageNumber + 1);
                }}
                className="w-100 btn-white"
              >
                Incarcă mai multe
              </Button>
            </div>
          ) : (
            ''
          )}

          {(!interruptions || !interruptions.length) && county && (
            <div style={{ color: '#ea1c0a', fontWeight: 'bold' }}>Nu există întreruperi programate pentru acest județ.</div>
          )}
        </Row>
      </div>

      {error && <div style={{ color: 'red', fontWeight: 'bold' }}>{error}</div>}
    </div>
  );
};

export default IntreruperiProgramateEE;
