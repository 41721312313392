import { logout } from '@actions/user/user';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IState } from '@type/store';
import { useContext, useEffect, useState } from 'react';
import { Accordion, Row } from 'react-bootstrap';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import AccordionContext from 'react-bootstrap/AccordionContext';
import { useDispatch, useSelector } from 'react-redux';

// @ts-ignore
//eslint-disable-next-line
function ContextAwareToggle({ children, eventKey, callback }) {
    const { activeEventKey } = useContext(AccordionContext);

    const decoratedOnClick = useAccordionButton(
        eventKey,
        () => callback && callback(eventKey),
    );

    const isCurrentEventKey = activeEventKey === eventKey;

    return (
        // <button
        //     type="button"
        //     style={{ backgroundColor: isCurrentEventKey ? 'pink' : 'lavender' }}
        //     onClick={decoratedOnClick}
        // >
        //     {children}
        // </button>
        isCurrentEventKey ?
            <div onClick={decoratedOnClick} className="d-flex align-items-center">
                <FontAwesomeIcon icon={faChevronUp} className="pointer red"/>
            </div> :
            <div onClick={decoratedOnClick} className="d-flex align-items-center">
                <FontAwesomeIcon icon={faChevronDown} className="pointer"/>
            </div>
    );
}

const MyAccountMenuComp = (props: any) => {
    const [activeKey, setActiveKey] = useState<any>([]);
    const kentico = props.kentico;
    const url = window.location.href.toString();
    const dispatch = useDispatch();
    const OLD_SITE_URL: string | undefined = useSelector<IState>((state) => state.options.webApiHost) as string;

    useEffect(() => {
        kentico.items.map((item: any, index: number) => {
            item.items.map((link: any, idx: number) => {
                if (url.includes(link.link)) {
                    setActiveKey([...activeKey, index.toString()]);
                }
            });
        });
    }, []);

    useEffect(() => {
        console.log(activeKey);
    }, [activeKey]);

    const isActive = (item: any): string => {
        let active = false;

        if (url.includes(item.link) && item.link !== '') {
            active = true;
        }

        item.items.map((link: any, idx: number) => {
            if (url.includes(link.link) && link.link !== '') {
                active = true;
            }
        });

        if (active === true) {
            return 'accordion-header-container active';
        } else {
            return 'accordion-header-container';
        }
    };

    return (
        <div className="my-account-menu-container">
            <Accordion activeKey={activeKey} onSelect={(e) => setActiveKey(e)} alwaysOpen>
            {kentico.items.map((item: any, index: number) => (
                <Row key={index}>
                    {((item.link && item.link.includes('home/logout')) || item.items.length === 0) ?
                        <Row className="option-row">
                            <a
                                href={item.link}
                                className={(url.includes(item.link) && item.link !== '') ? 'option-link-active' : 'option-link'}
                                onClick={() => { if (item.link.includes('home/logout')) {
                                        dispatch(logout());
                                        document.cookie = 'token=';
                                        window.location.href = OLD_SITE_URL;
                                    }
                                }}
                            >
                                <span>{item.title}</span>
                            </a>
                        </Row> :
                        <Accordion.Item eventKey={`${index}`}>
                            <div className={`d-flex justify-content-between ${isActive(item)}`}>
                                <Accordion.Header>{item.title}</Accordion.Header>
                                {/* <span className={isActive(item)} onClick={() => window.location.href = item.link}>{item.title}</span> */}
                                {/* <ContextAwareToggle eventKey={`${index}`} callback={undefined}>

                                </ContextAwareToggle> */}
                            </div>
                            {/*<Accordion.Collapse className={isActive(item)} eventKey={`${index}`}>*/}
                            {/*    <span onClick={() => window.location.href = item.link}>{item.title}</span>*/}
                            {/*</Accordion.Collapse>*/}
                            <Accordion.Body className="d-flex flex-column">
                                {item.items.map((link: any, index: number) => {
                                    return <a href={link.link} key={index} className={(url.includes(link.link) && link.link !== '') ? 'accordion-link active' : 'accordion-link'}>
                                        {link.title}
                                    </a>;
                                })}
                            </Accordion.Body>
                        </Accordion.Item>
                    }
                </Row>
            ))}
            </Accordion>
        </div>
    );
};

export default MyAccountMenuComp;