import { toggleLoader } from '@actions/LoaderActions';
import { deleteRequest, getDecrecDetails } from '@api/decrec/decrec';
import { faChevronLeft, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { GetDecrecDetailsResponseType } from '@type/decrec';
import moment from 'moment';
import { Fragment, useEffect, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { getQueryParam } from '../../../helpers/urlUtils';
import { UTILITY_EE } from '@constants/Utility';
import {
  DECREC_STATUS_CP__ANULAT,
  DECREC_STATUS_CP__CONFIRMAT,
  DECREC_STATUS_CP__FINALIZAT,
  DECREC_STATUS_CP__NECONFIRMAT,
  DECREC_STATUS__ANULATA,
  DECREC_STATUS__IN_ANALIZA,
  DECREC_STATUS__NEINREGISTRATA,
  DECREC_STATUS__NETRIMISA,
  DECREC_STATUS__PROCESATA,
  TYPE_CANCEL_DISCONNECT,
  TYPE_DISCONNECT,
  TYPE_RECONNECT
} from '@constants/decRec/decRecConstants';
import ChoiceModal from '@components/common/Modal/ChoiceModal';
import * as notification from '@lib/Notification';

const DecRecDetaliiSolicitare = () => {
  const [requestId, setRequestId] = useState<any>('');
  const [utility, setUtility] = useState<any>(0);
  const [error, setError] = useState('');
  const [decRecDetails, setDecRecDetails] = useState<GetDecrecDetailsResponseType | null>(null);
  const [actionsDisabled, setActionsDisabled] = useState(false);
  const [fatalError, setFatalError] = useState('');

  //delete
  const [showDeleteRequestModal, setShowDeleteRequestModal] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    const id = getQueryParam('id');
    const utility = getQueryParam('utility');
    setRequestId(id);
    setUtility(utility);

    if (!id || !utility) {
      setFatalError('A apărut o eroare tehnică. Vă rugăm să accesați pagina din secțiunea principală.');
    }
  }, []);

  useEffect(() => {
    if (requestId && utility) {
      dispatch(toggleLoader(true));

      getDecrecDetails({ id: requestId, utility: utility })
        .then((res: GetDecrecDetailsResponseType) => {
          setDecRecDetails(res);
          dispatch(toggleLoader(false));
        })
        .catch((error: any) => {
          setError('A apărut o eroare. Vă rugăm să reveniți mai tărziu.');
          dispatch(toggleLoader(false));
        });
    }
  }, [requestId, utility]);

  const deleteDecRecEntry = () => {
    dispatch(toggleLoader(true));

    deleteRequest({ id: decRecDetails?.id!, utility: utility })
      .then((res) => {
        dispatch(toggleLoader(false));
        if (!res.succes) {
          if (res.descriereEroare) {
            notification.error(res.descriereEroare);
          } else notification.error('A intervenit o eroare tehnică. Vă rugăm reveniți mai târziu.');
        } else {
          notification.success('Cererea a fost ștearsă cu succes.');
          setActionsDisabled(true);
          setTimeout(() => {
            window.location.href = '/lista-deconectare-reconectare-' + (utility == UTILITY_EE ? 'ee' : 'gn');
          }, 3000);
        }
      })
      .catch((err) => {
        notification.error('A intervenit o eroare tehnică. Vă rugăm reveniți mai târziu.');
        dispatch(toggleLoader(false));
      });
  };

  const getCpStatus = (statusId: number) => {
    switch (statusId) {
      case DECREC_STATUS__NETRIMISA:
        return DECREC_STATUS_CP__NECONFIRMAT;
      case DECREC_STATUS__NEINREGISTRATA:
        return DECREC_STATUS_CP__NECONFIRMAT;
      case DECREC_STATUS__IN_ANALIZA:
        return DECREC_STATUS_CP__CONFIRMAT;
      case DECREC_STATUS__PROCESATA:
        return DECREC_STATUS_CP__FINALIZAT;
      case DECREC_STATUS__ANULATA:
        return DECREC_STATUS_CP__ANULAT;
      default:
        return '-';
    }
  };

  return (
    <Fragment>
      <div className="mb-3">
        <a href={'/lista-deconectare-reconectare-' + (utility == UTILITY_EE ? 'ee' : 'gn')}>
          <Button disabled={actionsDisabled}>
            <FontAwesomeIcon icon={faChevronLeft} style={{ marginRight: '6px' }} />
            <span>Înapoi</span>
          </Button>
        </a>
      </div>

      {!fatalError && (
        <>
          <Row>
            <Col xs={12} sm={6} className="d-flex flex-column">
              {decRecDetails && (
                <div className="details-block h-100">
                  <div className="block-body">
                    <div className="property-container">
                      <span className="rounded-white-box">
                        {decRecDetails.statusId == DECREC_STATUS__NETRIMISA ? (
                          <span>Indisponibil</span>
                        ) : decRecDetails.statusId == DECREC_STATUS__NEINREGISTRATA ? (
                          <span>Cererea asteaptă număr de înregistrare</span>
                        ) : (
                          <span>
                            {decRecDetails.requestNo}
                            {decRecDetails.requestDate ? `/${moment(decRecDetails.requestDate).format('DD.MM.YYYY HH:mm')}` : ''}
                          </span>
                        )}
                      </span>
                    </div>

                    {decRecDetails.documents && decRecDetails.documents.length && decRecDetails.statusId != DECREC_STATUS__NETRIMISA ? (
                      <div className="property-container">
                        <span className="property-value">
                          <a
                            className="link link--download red download-link-text pointer hover-black"
                            href={decRecDetails.documents[0].documentLink}
                          >
                            Descarcă cerere
                          </a>
                        </span>
                      </div>
                    ) : decRecDetails.statusId == DECREC_STATUS__NEINREGISTRATA ? (
                      <span className="red">Documentul este în curs de generare</span>
                    ) : (
                      ''
                    )}

                    <div className="property-container">
                      <span className="property-key">Status solicitare</span>
                      <span className="property-value">{decRecDetails.statusName}</span>
                    </div>

                    <div className="property-container">
                      <span className="property-key">Dată depunere:</span>
                      <span className="property-value">
                        {decRecDetails.entryDate ? moment(decRecDetails.entryDate).format('DD.MM.YYYY') : '-'}
                      </span>
                    </div>

                    <div className="property-container">
                      <span className="property-key">Număr locuri de consum:</span>
                      <span className="property-value">{decRecDetails ? 1 : 0}</span>
                    </div>

                    {decRecDetails.statusId == DECREC_STATUS__NEINREGISTRATA && (
                      <div className="property-container mt-3">
                        <a
                          style={{ textDecoration: 'none' }}
                          href="#"
                          onClick={() => {
                            setShowDeleteRequestModal(true);
                          }}
                        >
                          Șterge solicitare <FontAwesomeIcon icon={faTrash} />
                        </a>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </Col>

            <h5 className="mt-4">Locuri consum</h5>

            <div>
              <div className="general-table-container">
                <table>
                  <thead>
                    <tr>
                      <th>Loc consum</th>
                      <th>Date despre client</th>
                      <th>{decRecDetails?.idRequestType == TYPE_RECONNECT ? 'Reconectare' : 'Deconectare'}</th>
                      <th>Rezoluție solicitare</th>
                      <th>Dată procesare solicitare</th>
                      <th>Status loc consum</th>
                    </tr>
                  </thead>
                  <tbody>
                    {decRecDetails && (
                      <tr key={0}>
                        <td>
                          <p>
                            CLC: <span className="rounded-white-box">{decRecDetails.pod}</span>
                          </p>
                          <p>
                            NLC: <span className="rounded-white-box">{decRecDetails.vstelle}</span>
                          </p>
                        </td>

                        <td>
                          <p>
                            Client: <strong>{decRecDetails.clientName}</strong>
                          </p>
                          <p>
                            Adresa: <span className="p-gray">{decRecDetails.address}</span>
                          </p>
                        </td>

                        <td>
                          <p>
                            Data {decRecDetails.idRequestType == TYPE_RECONNECT ? 'reconectării' : 'deconectării'}:{' '}
                            <strong>{decRecDetails.startDate ? moment(decRecDetails.startDate).format('DD.MM.YYYY') : '-'}</strong>
                          </p>
                          <p>
                            {decRecDetails.idRequestType == TYPE_DISCONNECT && (
                              <>
                                Motiv:{' '}
                                <strong>
                                  {decRecDetails.terminationReason} {decRecDetails.otherTerminationReason}
                                </strong>
                              </>
                            )}

                            {decRecDetails.idRequestType == TYPE_CANCEL_DISCONNECT && (
                              <>
                                Motiv:{' '}
                                <strong>
                                  {decRecDetails.requestToCancel.terminationReason} {decRecDetails.requestToCancel.otherTerminationReason}
                                </strong>
                              </>
                            )}
                          </p>
                        </td>

                        <td>{decRecDetails.resolution?.responseNote}</td>
                        <td>{decRecDetails.processDate ? moment(decRecDetails.processDate).format('DD.MM.YYYY') : '-'}</td>
                        <td className={decRecDetails.statusId == DECREC_STATUS__PROCESATA ? 'approved fw-bold' : ''}>
                          {getCpStatus(decRecDetails.statusId)}
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </Row>

          {error !== '' && <p className="text-center fw-bold red mt-3">{error}</p>}

          <ChoiceModal
            showModal={showDeleteRequestModal}
            modalTitle={'Sunteți sigur că doriți să ștergeți cererea?'}
            options={[
              { id: 'DA', title: 'Da' },
              { id: 'NU', title: 'Nu' }
            ]}
            onClose={(option: any) => {
              if (option.id == 'DA') {
                deleteDecRecEntry();
                setShowDeleteRequestModal(false);
              } else if (option.id == 'NU') {
                setShowDeleteRequestModal(false);
              }
            }}
            onHide={() => {
              setShowDeleteRequestModal(false);
            }}
            btnWide
          />
        </>
      )}

      {fatalError && <p className="text-center fw-bold red mt-3">{fatalError} </p>}
    </Fragment>
  );
};

export default DecRecDetaliiSolicitare;
