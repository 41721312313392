import React from 'react';
import { DelgazWrapper } from '@components/global/DelgazWrapper';
import PaginationComponent from '@components/dev/Components/Table/PaginationComponent';
import {PaginationKentico} from '@type/kentico-types';

export type PaginationProps = { kentico: PaginationKentico, paginate: any, setElementsPerPage: any };

export const Pagination = (props: PaginationProps): JSX.Element => {
    return (
        <DelgazWrapper>
            <PaginationComponent {...props}/>
        </DelgazWrapper>
    );
};