import { toggleLoader } from '@actions/LoaderActions';
import { getExportAnexa, postAnexaStepEight } from '@api/revizii/revizii';
import { UploadFile } from '@components/common/UploadFile';
import React, { Fragment, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { TYPE_REVIZIE } from '../CreareFisa';
import StepsNavigationButtons from '@components/common/Steps/StepsNavigationButtons';
import { CustomModal } from '@components/common/ModalConfirm';
import * as notification from '@lib/Notification';

type RVStepEightProps = {
  type: string;
  cpData: any;
  pod: string;
  clc: string;
  county: string;
  locality: string;
  dictionare: any;
  currentStep: any;
  setStep: (step: any) => void;
  progress: any;
  setProgress: (step: any) => void;
  idAnexa: string;
};

const RVStepEight = (props: RVStepEightProps) => {
  const dispatch = useDispatch();
  const { setStep, currentStep, progress, setProgress } = props;

  const [fileList, setFileList] = useState<File[]>([] as File[]);

  //errors
  const [error, setError] = useState('');
  const [errorFileEmpty, setErrorFileEmpty] = useState(false);

  const [showModalSucces, setShowModalSucces] = useState(false);
  const [showSuccessWithCommunicationErrorModal, setShowSuccessWithCommunicationErrorModal] = useState(false);
  const [showSuccessWithStatusCanceledModal, setShowSuccessWithStatusCanceledModal] = useState(false);

  useEffect(() => {
    if (fileList.length > 0) {
      setErrorFileEmpty(false);
    }
  }, [fileList]);

  const submit = () => {
    if (validateValues()) {
      dispatch(toggleLoader(true));

      let formData = new FormData();

      if (props.idAnexa) {
        formData.append('AnnexeId', props.idAnexa);
      }
      if (fileList.length > 0) {
        fileList.map((file) => {
          formData.append('Document', file as Blob);
        });
      }

      postAnexaStepEight(formData)
        .then((res) => {
          dispatch(toggleLoader(false));
          setShowModalSucces(true);
        })
        .catch((err) => {
          if (err.response.status == 200) {
            setShowModalSucces(true);
          } else if (err.response.status == 506) {
            setShowSuccessWithCommunicationErrorModal(true);
          } else if (err.response.status == 507) {
            setShowSuccessWithStatusCanceledModal(true);
          } else if (err.response.status == 412) {
            setError('Locul de consum introdus nu mai este valid.');
          } else if (err.response.status == 422) {
            setError('Există deja o fișă deschisă pentru același utilizator.');
          } else {
            setError('A intervenit o eroare tehnică. Vă rugăm reveniți mai târziu.');
          }
          dispatch(toggleLoader(false));
        });
    }
  };

  const validateValues = () => {
    let valid = true;
    if (fileList.length == 0) {
      setErrorFileEmpty(true);
      valid = false;
    }
    return valid;
  };

  const downloadFileAnexa = async () => {
    dispatch(toggleLoader(true));

    let res = '' as BlobPart;

    try {
      res = await getExportAnexa(props.idAnexa);

      setTimeout(() => {
        const blob: Blob = new Blob([res]);

        const fileName: string = `fisa-anexa-${props.type == TYPE_REVIZIE ? '5' : '4'}-pentru-semnare-${moment().format('YYMMDDHms')}.pdf`;
        const objectUrl: string = URL.createObjectURL(blob);
        const a: HTMLAnchorElement = document.createElement('a') as HTMLAnchorElement;

        a.href = objectUrl;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();

        document.body.removeChild(a);
        URL.revokeObjectURL(objectUrl);
        dispatch(toggleLoader(false));
      }, 500);
    } catch (error) {
      dispatch(toggleLoader(false));
      notification.error('A apărut o eroare. Vă rugăm să reveniți mai tărziu.');
    }
  };
  return (
    <Fragment>
      <h4 className="red mt-2 mb-3">Incarca PDF semnat</h4>

      <Row className="mb-3">
        <a href="#" onClick={downloadFileAnexa} className="link link--download download-link pointer hover-red p-gray">
          Descarcă fișă Anexa {props.type == TYPE_REVIZIE ? 5 : 4} pentru semnare
        </a>
      </Row>

      <Row>
        <Col xs={12} md={6} className="mb-2">
          <p className="m-0">Incarcare fișier*</p>
          <UploadFile
            onSetFileList={(list: any) => {
              setFileList(list);
            }}
            fileList={fileList}
            index={0}
            uploadedFilesLimit={1}
            acceptedExtensions={['.pdf']}
          />
          <div style={{ color: '#ea1c0a', fontWeight: 'bold' }}>{errorFileEmpty && 'Documentul este obligatoriu.'}</div>
        </Col>
      </Row>

      {error && (
        <div className="mt-3" style={{ color: 'red', fontWeight: 'bold' }}>
          {error}
        </div>
      )}

      <StepsNavigationButtons
        className="mb-3"
        btnNextLabel={`Trimite fișă anexa ${props.type == TYPE_REVIZIE ? 5 : 4}`}
        onClickPrevious={() => {
          setStep(currentStep - 1);
          currentStep === progress && setProgress(currentStep - 1);
        }}
        onClickNext={() => {
          submit();
        }}
      />

      <CustomModal
        modalTitle={'Succes!'}
        showModal={showModalSucces}
        onClose={() => {
          setShowModalSucces(false);
          window.location.href = '/istoric-incarcari-fisa';
        }}
      >
        <h5>Anexa a fost trimisă cu succes.</h5>
      </CustomModal>

      <CustomModal
        showModal={showSuccessWithCommunicationErrorModal}
        modalTitle={'Succes!'}
        onClose={() => {
          window.location.href = '/istoric-incarcari-fisa';
        }}
      >
        <h5>Anexa a fost trimisă cu succes însă a apărut o eroare de comunicare.</h5>
      </CustomModal>

      <CustomModal
        showModal={showSuccessWithStatusCanceledModal}
        modalTitle={'Succes!'}
        onClose={() => {
          window.location.href = '/istoric-incarcari-fisa';
        }}
      >
        <h5>Anexa a fost trimisă cu succes însă este cu status Anulata.</h5>
      </CustomModal>
    </Fragment>
  );
};

export default RVStepEight;
