import { toggleLoader } from '@actions/LoaderActions';
import { postStepFour } from '@api/iConnect/iConnectEE';
import { getTypes } from '@api/prosumatori/prosumatori';
import { Dropdown, DropdownOptionType } from '@components/common/Dropdown';
import { Input } from '@components/common/Input';
import ChoiceComponent from '@components/prosumatori/components/ChoiceComponent';
import DynamicTable from '@components/prosumatori/components/table/DynamicTable';
import { useEffect, useState } from 'react';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import TabelTipRacordEE from '../components/TabelTipRacordEE';
import DetaliiReceptoare from '../components/DetaliiReceptoare';
import DatePicker from '@components/common/Date/DatePicker';
import moment from 'moment';

let years = [
  { name: '2023', id: 2023 },
  { name: '2024', id: 2024 },
  { name: '2025', id: 2025 },
  { name: '2026', id: 2026 },
  { name: '2027', id: 2027 },
  { name: '2028', id: 2028 },
  { name: '2029', id: 2029 },
  { name: '2030', id: 2030 },
  { name: '2031', id: 2031 },
  { name: '2032', id: 2032 },
  { name: '2033', id: 2033 }
];

const LCNoncasnic = (props: {
  isEditing: any;
  requestId: any;
  setStep: any;
  currentStep: any;
  progress: any;
  setProgress: any;
  casnic: any;
  tipLC: any;
  dateTehnice: any;
  handleDateTehnice: any;
  SI: any;
  handleSI: any;
  statiiIncarcare: any;
  handleDateSI: any;
  dateSI: any;
  modAlimentare: any;
  handleModAlimentare: any;
  dataSolicitata: any;
  handleDataSolicitata: any;
  factorPutere: any;
  handleFactorPutere: any;
  tipFactorPutere: any;
  handleTipFactorPutere: any;
  detaliiFactorPutere: any;
  handleDetaliiFactorPutere: any;
  tensiune: any;
  handleTensiune: any;
  tipTensiune: any;
  handleTipTensiune: any;
  specificulActivitatii: any;
  handleSpecificulActivitatii: any;
  otherSA: any;
  handleOtherSA: any;
  regimLucru: any;
  handleRegimLucru: any;
  surseDeAlimentare: any;
  handleSurseDeAlimentare: any;
  timpIntrerupere: any;
  handleTimpIntrerupere: any;
  listaReceptoare: any;
  handleDateLR: any;
  dateLR: any;
  UE: any;
  handleUE: any;
  dateUE: any;
  utilizatoriExterni: any;
  handleDateUE: any;
  dezvoltare: any;
  handleDezvoltare: any;
  etape: any;
  handleEtape: any;
  detaliiEnergeticeReceptoare: any;
  handleDateDER: any;
  alteInformatii: any;
  handleAlteInformatii: any;
  aparateFrecvente: any;
  alteAparate: any;
  dateAF: any;
  dateAA: any;
  handleDateAF: any;
  handleDateAA: any;
  tipTarif: any;
  handleTipTarif: any;
  tipInstalatii: any;
  handleTipInstalatii: any;
  caiAlimentare: any;
  handleCaiAlimentare: any;
  alteCaiAlimentare: any;
  handleAlteCaiAlimentare: any;
  racordareSantier: any;
  KWSantier: any;
  KVASantier: any;
  dataSantier: any;
  handleRacordareSantier: any;
  handleKWSantier: any;
  handleKVASantier: any;
  handleDataSantier: any;
  KWTotalInstalat: any;
  handleKWTotalInstalat: any;
  KWAbsorbit: any;
  handleKWAbsorbit: any;
  dateEtape: any;
  handleDateEtape: any;
  showDER: any,
  handleShowDER: any
}) => {
  const {
    isEditing,
    requestId,
    setStep,
    currentStep,
    progress,
    setProgress,
    // casnic,
    tipLC,
    dateTehnice,
    handleDateTehnice,
    SI,
    handleSI,
    statiiIncarcare,
    handleDateSI,
    dateSI,
    modAlimentare,
    handleModAlimentare,
    handleDataSolicitata,
    dataSolicitata,
    factorPutere,
    handleFactorPutere,
    tipFactorPutere,
    handleTipFactorPutere,
    detaliiFactorPutere,
    handleDetaliiFactorPutere,
    tensiune,
    handleTensiune,
    tipTensiune,
    handleTipTensiune,
    specificulActivitatii,
    handleSpecificulActivitatii,
    otherSA,
    handleOtherSA,
    regimLucru,
    handleRegimLucru,
    surseDeAlimentare,
    handleSurseDeAlimentare,
    timpIntrerupere,
    handleTimpIntrerupere,
    listaReceptoare,
    handleDateLR,
    dateLR,
    UE,
    handleUE,
    dateUE,
    utilizatoriExterni,
    handleDateUE,
    dezvoltare,
    handleDezvoltare,
    etape,
    handleEtape,
    detaliiEnergeticeReceptoare,
    handleDateDER,
    alteInformatii,
    handleAlteInformatii,
    // aparateFrecvente,
    // alteAparate,
    // dateAF,
    // dateAA,
    // handleDateAF,
    // handleDateAA,
    tipTarif,
    handleTipTarif,
    tipInstalatii,
    handleTipInstalatii,
    caiAlimentare,
    handleCaiAlimentare,
    alteCaiAlimentare,
    handleAlteCaiAlimentare,
    racordareSantier,
    KWSantier,
    KVASantier,
    dataSantier,
    handleRacordareSantier,
    handleKWSantier,
    handleKVASantier,
    handleDataSantier,
    KWTotalInstalat,
    // handleKWTotalInstalat,
    // KWAbsorbit,
    // handleKWAbsorbit,
    dateEtape,
    handleDateEtape,
    showDER,
    handleShowDER
  } = props;

  // const [showAF, setShowAF] = useState(false);
  // const [showAA, setShowAA] = useState(false);
  //eslint-disable-next-line
  const [SAOptions, setSAOptions] = useState([]);
  const [TTOptions, setTTOptions] = useState([]);
  const [validationError, setValidationError] = useState('');
  const [error, setError] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [showPowerModal, setShowPowerModal] = useState(false);
  const [eroriEtape, setEroriEtape] = useState('');

  const dispatch = useDispatch();

  useEffect(() => {
    getOptionTypes(['TariffType']).then((res) => setTTOptions(res));
    specificulActivitatii.length === 0 &&
      getOptionTypes(['ActivityType']).then((res) => {
        let data = res;
        data.map((el: any, i: number) => {
          el.active = false;
        });

        handleSpecificulActivitatii(data);
        setSAOptions(data);
      });

    // if (tipInstalatii.length === 0) {
    //   let data = instalatii;
    //   data.map((el: any, i: number) => {
    //     el.active = false;
    //   });

    //   handleTipInstalatii(data);
    // }
  }, []);

  const getOptionTypes = async (keys: string[]) => {
    return await getTypes(keys).then((res: any) => {
      return res[keys[0]];
    });
  };

  const constructSpecAct = () => {
    let list: any[] = [];
    specificulActivitatii.map((el: any, i: number) => {
      if (el.active === true) {
        list.push(el);
      }
    });

    list.map((e: any, i: number) => {
      if (e.name === 'Altele') {
        list[i] = {
          id: 6,
          name: otherSA,
          code: '',
          active: true
        };
      }
    });

    let newList: any[] = [];
    list.map((e: any, i: number) => {
      let el = {
        requestId: e.id,
        activityTypeId: e.id,
        activityType: e.name
      };

      newList.push(el);
    });

    return newList;
  };

  const setOptionActive = (option: any) => {
    let op = specificulActivitatii;

    op.map((el: any, i: number) => {
      if (el === option) {
        el.active = !el.active;
      }
    });

    handleSpecificulActivitatii(op);
  };

  const constructInst = () => {
    let list: any[] = [];
    tipInstalatii.map((el: any, i: number) => {
      if (el.active === true) {
        list.push(el);
      }
    });

    list.map((e: any, i: number) => {
      if (e.name === 'Altele') {
        list[i] = {
          id: 6,
          name: otherSA,
          code: '',
          active: true
        };
      }
    });

    let newList: any[] = [];

    list.map((e: any, i: number) => {
      let el = {
        Id: e.id,
        tip_instalatie: e.id
        // activityType: e.name
      };

      newList.push(el);
    });

    return newList;
  };

  const setInstOptionActive = (option: any) => {
    let op = tipInstalatii;

    op.map((el: any, i: number) => {
      if (el === option) {
        el.active = !el.active;
      }
    });

    handleTipInstalatii(op);
  };

  const renderTipInstalatii = () => {
    return (
      <div className="mt-3 mb-3">
        <h5 className="m-0" id="TI">
          Tipul instalațiilor pentru care se solicită racordarea*
        </h5>
        <div className={'d-flex m-3 choices checkbox-container'}>
          {tipInstalatii.map((option: any, idx: number) => (
            <span
              key={idx}
              style={{ marginRight: '25px', marginBottom: '8px' }}
              onClick={() => {
                if (isEditing !== false) {
                  setInstOptionActive(option);
                }
              }}
            >
              <input
                type="checkbox"
                onChange={() => setInstOptionActive(option)}
                onClick={() => {
                  if (isEditing !== false) {
                    setInstOptionActive(option);
                  }
                }}
                value={option}
                checked={option.active}
                style={{ marginRight: '4px' }}
                className="create-account-checkbox"
                disabled={isEditing === false}
                // disabled={disabled}
              />
              <span style={{ fontSize: '18px' }}>{option.title || option.name || option.tip_instalatie}</span>
            </span>
          ))}
        </div>
      </div>
    );
  };

  const renderSpecificulActivitatii = () => {
    return (
      <div className="mt-3 mb-3">
        <h5 className="m-0" id="SA">
          Specificul activității:*
        </h5>
        <div className={'d-flex m-3 choices checkbox-container'}>
          {specificulActivitatii.map((option: any, idx: number) => (
            <span
              key={idx}
              style={{ marginRight: '25px', marginBottom: '8px' }}
              onClick={() => {
                if (isEditing !== false) {
                  setOptionActive(option);
                }
              }}
            >
              <input
                type="checkbox"
                onChange={() => setOptionActive(option)}
                onClick={() => {
                  if (isEditing !== false) {
                    setOptionActive(option);
                  }
                }}
                value={option}
                checked={option.active}
                style={{ marginRight: '4px' }}
                className="create-account-checkbox"
                disabled={isEditing === false}
                // disabled={disabled}
              />
              <span style={{ fontSize: '18px' }}>{option.title || option.name}</span>
            </span>
          ))}
        </div>
      </div>
    );
  };

  const constructDateLR = (data: any) => {
    let newData = data;

    newData.map((row: any, index: number) => {
      Object.keys(row).map((key, idx) => {
        row['Putere_instalata'] = Number(row['Putere_instalata']);
        row['numar_receptoare'] = Number(row['numar_receptoare']);
      });
    });

    // newData.forEach(function(v: any){ delete v.TotalConsumptionPower; });

    return newData;
  };

  const setOptionsForChargingStations = (data: any) => {
    let newData = JSON.parse(JSON.stringify(data));

    newData.map((el: any, i: number) => {
      Object.keys(el).map((key: any, idx: number) => {
        // if (idx === 4) {
        //     if (el[key] === 'AC') {
        //         el[key] = 1;
        //     } else if (el[key] === 'DC') {
        //         el[key] = 2;
        //     }
        // }
        if (idx === 5) {
          if (el[key] === 'public') {
            el[key] = 2;
          } else if (el[key] === 'privat') {
            el[key] = 1;
          }
        }
        if (idx === 6) {
          if (el[key] === 'FORTE PROPRII') {
            el[key] = 1;
          } else if (el[key] === 'AFM2021-UAT SI INSTIT.PUBLICE') {
            el[key] = 2;
          } else if (el[key] === 'ELECTRIC UP') {
            el[key] = 3;
          }
        }
        if (idx === 4) {
          if (el[key] === 'lenta') {
            el[key] = 1;
          } else if (el[key] === 'rapida') {
            el[key] = 2;
          }
        }
      });
    });

    return newData;
  };

  const constructDateEtape = (array: any) => {
    let newData = [];

    for (let i = 0; i < etape; ++i) {
      newData.push(array[i]);
    }

    return newData;
  };

  const constructPayload = () => {
    return {
      TipRacord: tipLC === 2 ? dateTehnice.ssTipRacord : dateTehnice.seTipRacord,
      requestId: requestId,
      data_solicitata: dataSolicitata !== '' ? dataSolicitata : null,
      mod_alimentare: modAlimentare,
      tip_tarif: tipTarif,
      date_tehnice_old: {
        TipRacord: dateTehnice.seTipRacord,
        KW: Number(dateTehnice.seKW.replace(',', '.')),
        KVA: Number(dateTehnice.seKVA.replace(',', '.'))
      },
      date_tehnice_new: {
        TipRacord: tipLC === 2 ? dateTehnice.ssTipRacord : dateTehnice.seTipRacord,
        KW: tipLC === 2 ? Number(dateTehnice.ssKW.replace(',', '.')) : Number(dateTehnice.seKW.replace(',', '.')),
        KVA: tipLC === 2 ? Number(dateTehnice.ssKVA.replace(',', '.')) : Number(dateTehnice.seKVA.replace(',', '.'))
      },
      tensiune: Number(tensiune.replace(',', '.')),
      factor_putere: Number(factorPutere.replace(',', '.')),
      specificul_activitatii: constructSpecAct(),
      regim_lucru: {
        nr_schimburi: regimLucru.nrSchimburi !== '' ? regimLucru.nrSchimburi : null,
        interval_orar: regimLucru.interval !== '' ? regimLucru.interval : null,
        nr_zile: regimLucru.nrZile !== '' ? regimLucru.nrZile : null
      },
      surse_alimentare: {
        tip: surseDeAlimentare.tip,
        putere: surseDeAlimentare.putere,
        timp: surseDeAlimentare.timpPornire,
        durata: surseDeAlimentare.durataFunc
      },
      timp_max_intrerupere: timpIntrerupere !== null ? timpIntrerupere : '',
      lista_receptoare: constructDateLR(dateLR),
      statie_masina_electrica: SI === 1,
      consumatori_predefiniti: [],
      consumatori: [],
      detalii_factor_putere: detaliiFactorPutere,
      nr_cai_alimentare: (caiAlimentare === 1 || caiAlimentare === 2) ? caiAlimentare : alteCaiAlimentare,
      dezvoltare_in_etape: dezvoltare,
      numar_etape: dezvoltare ? etape : null,
      ...(SI === 1 ? { statii_incarcare: setOptionsForChargingStations(dateSI) } : { statii_incarcare: [] }),
      santier_data_valabilitate: dataSantier !== '' ? dataSantier : null,
      kw_total_instalata_santier: Number(KWSantier),
      kw_maxim_absorbita_santier: Number(KVASantier),
      tip_instalatii: constructInst(),
      kw_maxim_absorbita: 0,
      alimentare_utilizatori_externi: UE === 1,
      ...(UE === 1 ? { utilizatori_externi: dateUE } : { utilizatori_externi: [] }),
      cerinte_specifice: alteInformatii,
      detalii_energetice_receptoare: detaliiEnergeticeReceptoare,
      santier: racordareSantier,
      pi_total: KWTotalInstalat,
      lista_etape: dezvoltare ? constructDateEtape(dateEtape) : []
    };
  };

  const stepFourApiCall = async (payload: any) => {
    dispatch(toggleLoader(true));

    await postStepFour(payload)
      .then((response: any) => {
        if (response && response.status === 200) {
          setStep(currentStep + 1);
          currentStep === progress && setProgress(currentStep + 1);
        }
      })
      .catch((error: any) => {
        if (error && error.status === 200) {
          setStep(currentStep + 1);
          currentStep === progress && setProgress(currentStep + 1);
        } else if (error === 'Request is completed') {
          setError('Cererea a fost deja finalizată.');
        } else if (error && error.StatusCode && error.StatusCode === 500) {
          setError('A intervenit o eroare tehnică. Vă rugăm reveniți mai târziu.');
        } else if (error && error.statusCode && error.statusCode === 500) {
          setError('A intervenit o eroare tehnică. Vă rugăm reveniți mai târziu.');
        }
      });

    dispatch(toggleLoader(false));
  };

  const handlePrevStep = () => {
    setStep(currentStep - 1);
  };

  const validateTable = (table: any, indexException: number | null) => {
    let validated = true;
    table.map((row: any, index: number) => {
      Object.keys(row).map((column: any, idx: number) => {
        if (idx !== indexException) {
          if (row[column] === '' || row[column] === null) {
            validated = false;
          }
        }
      });
    });

    return validated;
  };

  const areSIPowersValid = (table: any) => {
    let validated = true;

    table.map((row: any, index: number) => {
      Object.keys(row).map((column: any, idx: number) => {
        if (Number(row['Putere_maxima simultan absorbita (kW)']) > Number(row['Putere_maxima instalata (kW)'])) {
          validated = false;
        }
      });
    });

    return validated;
  };

  const areUEPowersValid = (table: any) => {
    let validated = true;

    table.map((row: any, index: number) => {
      Object.keys(row).map((column: any, idx: number) => {
        if (Number(row['kw_maxim_absorbita_utilizator_extern']) > Number(row['kw_total_instalata_utilizator_extern'])) {
          console.log(row['kw_maxim_absorbita_utilizator_extern']);
          console.log(row['kw_total_instalata_utilizator_extern']);
          validated = false;
        }
      });
    });

    return validated;
  };

  const getLRMaxPI = (table: any) => {
    let maxPI = 0;

    table.map((row: any, index: number) => {
      Object.keys(row).map((column: any, idx: number) => {
        if (Number(row['Putere_instalata']) > maxPI) {
          maxPI = row['Putere_instalata'];
        }
      });
    });

    return maxPI;
  };

  const getSIMaxPI = (table: any) => {
    let maxPI = 0;

    table.map((row: any, index: number) => {
      Object.keys(row).map((column: any, idx: number) => {
        if (Number(row['Putere_maxima instalata (kW)']) > maxPI) {
          maxPI = row['Putere_maxima instalata (kW)'];
        }
      });
    });

    return maxPI;
  };

  const getUEMaxPI = (table: any) => {
    let maxPI = 0;

    table.map((row: any, index: number) => {
      Object.keys(row).map((column: any, idx: number) => {
        if (Number(row['kw_total_instalata_utilizator_extern']) > maxPI) {
          maxPI = row['kw_total_instalata_utilizator_extern'];
        }
      });
    });

    return maxPI;
  };

  const handleNextStep = () => {
    if (isEditing !== false) {
      setError('');
      setValidationError('');
      let wasValidated = true;
      let ma = document.getElementById('ma');
      ma?.classList.remove('red');
      let mainput = document.getElementById('ma-input');
      mainput?.classList.remove('forced-focus');
      let de = document.getElementById('DE');
      de?.classList.remove('red');
      let tt = document.getElementById('TT');
      tt?.classList.remove('red');
      let fp = document.getElementById('FP');
      fp?.classList.remove('red');
      let dt = document.getElementById('DT');
      dt?.classList.remove('red');
      let ca = document.getElementById('CA');
      ca?.classList.remove('red');
      let rs = document.getElementById('RS');
      rs?.classList.remove('red');
      let si = document.getElementById('SI');
      si?.classList.remove('red');
      let ue = document.getElementById('UE');
      ue?.classList.remove('red');
      let etp = document.getElementById('etape');
      etp?.classList.remove('red');
      let lr = document.getElementById('LR');
      lr?.classList.remove('red');
      let sa = document.getElementById('SA');
      sa?.classList.remove('red');
      let ti = document.getElementById('TI');
      ti?.classList.remove('red');
      let tu = document.getElementById('TU');
      tu?.classList.remove('red');
      setEroriEtape('');

      if (tipLC === 2 && modAlimentare === '') {
        setValidationError('Câmpurile marcate cu * sunt obligatorii.');
        wasValidated = false;
        let err = document.getElementById('ma');
        err?.classList.add('red');
        let inputerr = document.getElementById('ma-input');
        inputerr?.classList.add('forced-focus');
      }

      if (tipLC === 2 && (dataSolicitata === '' || dataSolicitata === null)) {
        setValidationError('Câmpurile marcate cu * sunt obligatorii.');
        wasValidated = false;
        let err = document.getElementById('DE');
        err?.classList.add('red');
      }

      if (tipTarif === null) {
        setValidationError('Câmpurile marcate cu * sunt obligatorii.');
        wasValidated = false;
        let err = document.getElementById('TT');
        err?.classList.add('red');
      }

      if (factorPutere === 0 || factorPutere === '0' || (tipFactorPutere === '' && factorPutere === '')) {
        setValidationError('Câmpurile marcate cu * sunt obligatorii.');
        wasValidated = false;
        let err = document.getElementById('FP');
        err?.classList.add('red');
      }

      if (racordareSantier === null) {
        let err = document.getElementById('RS');
        err?.classList.add('red');
      }

      if (racordareSantier && (KWSantier === '' || KVASantier === '' || dataSantier === '')) {
        setValidationError('Câmpurile marcate cu * sunt obligatorii.');
        wasValidated = false;
        let err = document.getElementById('RS');
        err?.classList.add('red');
      }

      if ((caiAlimentare === 3 && alteCaiAlimentare === '') || caiAlimentare === null) {
        setValidationError('Câmpurile marcate cu * sunt obligatorii.');
        wasValidated = false;
        let err = document.getElementById('CA');
        err?.classList.add('red');
      }

      if (isNaN(dateTehnice.seKW) || isNaN(dateTehnice.seKVA)) {
        setValidationError('Te rugăm să revizuiești informațiile completate.');
        wasValidated = false;
        let err = document.getElementById('dt');
        err?.classList.add('red');
      }

      if (isNaN(dateTehnice.ssKW) || isNaN(dateTehnice.ssKVA)) {
        setValidationError('Te rugăm să revizuiești informațiile completate.');
        wasValidated = false;
        let err = document.getElementById('dt');
        err?.classList.add('red');
      }

      if (dateTehnice.seKW === '' || (tipLC === 1 && dateTehnice.seKVA === '')) {
        setValidationError('Câmpurile marcate cu * sunt obligatorii.');
        wasValidated = false;
        let err = document.getElementById('DT');
        err?.classList.add('red');
      }

      if (tipLC === 2 && (dateTehnice.ssKW === '' || dateTehnice.ssKVA === '')) {
        setValidationError('Câmpurile marcate cu * sunt obligatorii.');
        wasValidated = false;
        let err = document.getElementById('DT');
        err?.classList.add('red');
      }

      if (Number(dateTehnice.seKW) === 0 || (tipLC === 1 && Number(dateTehnice.seKVA) === 0)) {
        setValidationError('Câmpurile marcate cu * sunt obligatorii.');
        wasValidated = false;
        let err = document.getElementById('DT');
        err?.classList.add('red');
      }

      if (tipLC === 2 && (Number(dateTehnice.ssKW) === 0 || Number(dateTehnice.ssKVA) === 0)) {
        setValidationError('Câmpurile marcate cu * sunt obligatorii.');
        wasValidated = false;
        let err = document.getElementById('DT');
        err?.classList.add('red');
      }

      if (dateTehnice.seTipRacord === 0 || dateTehnice.seTipRacord === null) {
        setValidationError('Câmpurile marcate cu * sunt obligatorii.');
        wasValidated = false;
        let err = document.getElementById('DT');
        err?.classList.add('red');
      }

      if (tipLC === 2 && (dateTehnice.ssTipRacord === 0 || dateTehnice.ssTipRacord === null)) {
        setValidationError('Câmpurile marcate cu * sunt obligatorii.');
        wasValidated = false;
        let err = document.getElementById('DT');
        err?.classList.add('red');
      }

      if (Number(dateTehnice.seKVA) > Number(dateTehnice.seKW)) {
        setValidationError('Puterea maximă simultan absorbită totală trebuie să fie mai mică sau egală cu puterea instalată totală.');
        wasValidated = false;
        let err = document.getElementById('DT');
        err?.classList.add('red');
      }

      if (tipLC === 2 && Number(dateTehnice.ssKVA) > Number(dateTehnice.ssKW)) {
        setValidationError('Puterea maximă simultan absorbită totală trebuie să fie mai mică sau egală cu puterea instalată totală.');
        wasValidated = false;
        let err = document.getElementById('DT');
        err?.classList.add('red');
      }

      if (tipTensiune === null) {
        setValidationError('Câmpurile marcate cu * sunt obligatorii.');
        wasValidated = false;
        let err = document.getElementById('TU');
        err?.classList.add('red');
      }

      if (
        (tensiune.includes(',') && isNaN(Number(tensiune.replace(',', '.')))) ||
        (factorPutere.includes(',') && isNaN(Number(factorPutere.replace(',', '.'))))
      ) {
        setValidationError('Câmpurile marcate cu * sunt obligatorii.');
        wasValidated = false;
      }

      if (tensiune === '') {
        setValidationError('Câmpurile marcate cu * sunt obligatorii.');
        wasValidated = false;
        let err = document.getElementById('TU');
        err?.classList.add('red');
      }

      if (tipTensiune === '' && Number(tensiune) <= 0) {
        setValidationError('Tensiunea de utilizare trebuie să fie mai mare decât 0.');
        wasValidated = false;
        let err = document.getElementById('TU');
        err?.classList.add('red');
      }

      if (constructSpecAct().length === 0) {
        setValidationError('Câmpurile marcate cu * sunt obligatorii.');
        wasValidated = false;
        let err = document.getElementById('SA');
        err?.classList.add('red');
      }

      if (constructInst().length === 0) {
        setValidationError('Câmpurile marcate cu * sunt obligatorii.');
        wasValidated = false;
        let err = document.getElementById('TI');
        err?.classList.add('red');
      }

      if (SI === null) {
        setValidationError('Câmpurile marcate cu * sunt obligatorii.');
        wasValidated = false;
        let err = document.getElementById('SI');
        err?.classList.add('red');
      }

      if (SI === 1 && !validateTable(dateSI, 7)) {
        setValidationError('Câmpurile marcate cu * sunt obligatorii.');
        wasValidated = false;
        let err = document.getElementById('SI');
        err?.classList.add('red');
      }

      if (SI === 1 && validateTable(dateSI, 7)) {
        if (!areSIPowersValid(dateSI)) {
          setValidationError('Puterea maximă simultan absorbită a unui receptor trebuie să fie mai mică sau egală cu puterea instalată a acestuia.');
          wasValidated = false;
          let err = document.getElementById('SI');
          err?.classList.add('red');
        }
      }

      if (SI === 1 && validateTable(dateSI, 7) && Number(tipLC === 1 ? dateTehnice.seKVA : dateTehnice.ssKVA) < getSIMaxPI(dateSI)) {
        console.log('SI 1');
        setValidationError(
          'Puterea totală maximă simultan absorbită trebuie să fie mai mare sau egală cu puterea instalată a celui mai mare receptor completat de tine.'
        );
        wasValidated = false;
        let err = document.getElementById('DT');
        err?.classList.add('red');
      }

      if (!validateTable(dateLR, 4)) {
        setValidationError('Câmpurile marcate cu * sunt obligatorii.');
        wasValidated = false;
        let err = document.getElementById('LR');
        err?.classList.add('red');
      }

      if (validateTable(dateLR, 4) && Number(tipLC === 1 ? dateTehnice.seKVA : dateTehnice.ssKVA) < getLRMaxPI(dateLR)) {
        console.log('LR 2');
        setValidationError(
          'Puterea totală maximă simultan absorbită trebuie să fie mai mare sau egală cu puterea instalată a celui mai mare receptor completat de tine.'
        );
        wasValidated = false;
        let err = document.getElementById('DT');
        err?.classList.add('red');
      }

      if (UE === null) {
        setValidationError('Câmpurile marcate cu * sunt obligatorii.');
        wasValidated = false;
        let err = document.getElementById('UE');
        err?.classList.add('red');
      }

      if (UE === 1 && !validateTable(dateUE, null)) {
        setValidationError('Câmpurile marcate cu * sunt obligatorii.');
        wasValidated = false;
        let err = document.getElementById('UE');
        err?.classList.add('red');
      }

      if (UE === 1 && validateTable(dateUE, null)) {
        if (!areUEPowersValid(dateUE)) {
          setValidationError('Puterea maximă simultan absorbită a unui receptor trebuie să fie mai mică sau egală cu puterea instalată a acestuia.');
          wasValidated = false;
          let err = document.getElementById('UE');
          err?.classList.add('red');
        }
      }

      if (UE === 1 && validateTable(dateUE, null) && Number(tipLC === 1 ? dateTehnice.seKVA : dateTehnice.ssKVA) < getUEMaxPI(dateUE)) {
        console.log('UE 3');
        setValidationError(
          'Puterea totală maximă simultan absorbită trebuie să fie mai mare sau egală cu puterea instalată a celui mai mare receptor completat de tine.'
        );
        wasValidated = false;
        let err = document.getElementById('DT');
        err?.classList.add('red');
      }

      if (dezvoltare === null) {
        setValidationError('Câmpurile marcate cu * sunt obligatorii.');
        wasValidated = false;
        let err = document.getElementById('etape');
        err?.classList.add('red');
      }

      if (dezvoltare === true && etape === null) {
        setValidationError('Câmpurile marcate cu * sunt obligatorii.');
        wasValidated = false;
        let err = document.getElementById('etape');
        err?.classList.add('red');
      }

      if (dezvoltare === true && etape !== null) {
        for (let i = 0; i < etape; i++) {
          if (dateEtape[i].an === '' || isNaN(dateEtape[i].an)) {
            setValidationError('Câmpurile marcate cu * sunt obligatorii.');
            setEroriEtape('Câmpurile marcate cu * sunt obligatorii.');
            wasValidated = false;
          }

          if (dateEtape[i].puterea_totala === '' || isNaN(dateEtape[i].puterea_totala)) {
            setValidationError('Câmpurile marcate cu * sunt obligatorii.');
            setEroriEtape('Câmpurile marcate cu * sunt obligatorii.');
            wasValidated = false;
          }

          if (dateEtape[i].puterea_max_absorbita === '' || isNaN(dateEtape[i].puterea_max_absorbita)) {
            setValidationError('Câmpurile marcate cu * sunt obligatorii.');
            setEroriEtape('Câmpurile marcate cu * sunt obligatorii.');
            wasValidated = false;
          }
        }
      }

      if (wasValidated) {
        if (tipLC === 2 && ((Number(dateTehnice.ssKW) < Number(dateTehnice.seKW)) || (Number(dateTehnice.ssKVA) < Number(dateTehnice.seKVA)))) {
          setShowPowerModal(true);
        } else if ((tipLC === 1 && dateTehnice.seTipRacord === 2) || (tipLC === 2 && dateTehnice.ssTipRacord === 2)) {
          setShowModal(true);
        } else {
          stepFourApiCall(constructPayload());
        }
      }
    } else {
      setStep(currentStep + 1);
      currentStep === progress && setProgress(currentStep + 1);
    }
  };

  const renderPowerModal = () => {
    return (
      <Modal
        show={showPowerModal}
        centered
        onHide={() => {
          setShowPowerModal(false);
        }}
        onClose={() => {
          setShowPowerModal(false);
        }}
      >
        <Modal.Header closeButton>
          <span style={{ height: '30px' }}></span>
        </Modal.Header>
        <Modal.Body>
          <p>
            Puterea solicitată este mai mică decât cea existentă. Ești sigur că iți dorești să reduci puterea?
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button 
            onClick={() => {
              if ((tipLC === 1 && dateTehnice.seTipRacord === 2) || (tipLC === 2 && dateTehnice.ssTipRacord === 2)) {
                setShowPowerModal(false);
                setShowModal(true);
              } else {
                stepFourApiCall(constructPayload());
              }
            }}
            >
              Da
            </Button>
          <Button onClick={() => setShowPowerModal(false)}>Modific puterea</Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const renderModal = () => {
    return (
      <Modal
        show={showModal}
        centered
        onHide={() => {
          setShowModal(false);
        }}
        onClose={() => {
          setShowModal(false);
        }}
      >
        <Modal.Header closeButton>
          <span style={{ height: '30px' }}></span>
        </Modal.Header>
        <Modal.Body>
          <p>
            Atenție! Pentru puterea maximă simultan absorbită solicitată de tine se va executa un branșament de tip trifazat. Dacă dorești
            un branșament monofazat, puterea maximă simultan absorbită nu trebuie să depășească 9.35 kW.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => stepFourApiCall(constructPayload())}>Sunt de acord</Button>
          <Button onClick={() => setShowModal(false)}>Modific puterea</Button>
        </Modal.Footer>
      </Modal>
    );
  };

  return (
    <div className="iconnect-informatii-tehnice m-3 w-100 d-flex align-self-center flex-column">
      <h4 className="register-section-title mt-3">Date tehnice și energetice pentru locul de consum</h4>

      {tipLC === 2 && (
        <Row className="mt-4 mb-4">
          <Col sm={12} md={4}>
            <h5 id="DE">Data estimată solicitată pentru punerea în funcțiune:*</h5>
          </Col>
          <Col sm={12} md={3}>
            {/* <DateTime
              name={'data'}
              label={'Data'}
              itemTypeId={0}
              value={dataSolicitata}
              onChange={handleDataSolicitata}
              disabled={isEditing === false}
            /> */}
            <DatePicker
              label="Data"
              name="Data"
              selected={dataSolicitata === null ?  '' : dataSolicitata !== '' ? moment(dataSolicitata).toDate() : dataSolicitata}
              onChange={(e: any) => {
                  if (e !== null) {
                      let date = moment(e).format('YYYY-MM-DD');
                      handleDataSolicitata(date);
                  } else {
                    handleDataSolicitata('');
                  }
              }}
              disabled={isEditing === false}
              mbZero
            />
          </Col>
        </Row>
      )}

      {tipLC === 2 && (
        <Row className="mt-4 mb-4">
          <h5 id="ma">Descrie modul actual de alimentare a obiectivului și modificările de natură tehnică propuse:*</h5>
          <Col xs={12} sm={4} className="mt-2">
            <Input
              value={modAlimentare}
              label="Mod alimentare"
              onChange={(e) => handleModAlimentare(e.target.value)}
              disabled={isEditing === false}
              id="ma-input"
            />
          </Col>
        </Row>
      )}

      <ChoiceComponent
        title={'Factorul de putere:*'}
        options={[
          { title: '0,85', id: '0,85' },
          { title: 'Altă valoare', id: '' }
        ]}
        onChange={handleTipFactorPutere}
        value={tipFactorPutere}
        disabled={isEditing === false}
        id="FP"
      />

      {tipFactorPutere === '' && (
        <Row>
          <Col xs={12} sm={4}>
            <Input
              value={factorPutere}
              label="Factorul de putere"
              onChange={(e) => {
                const re = /^[,.0-9\b]+$/;

                if (e.target.value === '' || re.test(e.target.value)) {
                  handleFactorPutere(e.target.value);
                }
              }}
              disabled={isEditing === false}
              id="FP"
            />
          </Col>
          <Col xs={12} sm={4}>
            <Input
              value={detaliiFactorPutere}
              label="Informații privind instalațiile de compensare"
              onChange={(e) => handleDetaliiFactorPutere(e.target.value)}
              disabled={isEditing === false}
            />
          </Col>
        </Row>
      )}

      {TTOptions.length > 0 && (
        <ChoiceComponent
          title={'Tip de tarif solicitat:*'}
          options={TTOptions}
          onChange={handleTipTarif}
          value={tipTarif}
          disabled={isEditing === false}
          id="TT"
        />
      )}

      <ChoiceComponent
        title={'Tensiune de utilizare (kV):*'}
        options={[
          { title: '0,23', id: '0.23' },
          { title: '0,4', id: '0.4' },
          { title: '6', id: '6' },
          { title: '20', id: '20' },
          { title: '110', id: '110' },
          { title: 'Altă valoare', id: '' }
        ]}
        onChange={handleTipTensiune}
        value={tipTensiune}
        disabled={isEditing === false}
        id="TU"
      />
      {tipTensiune === '' && (
        <Col xs={12} sm={4}>
          <Input
            value={tensiune}
            label="Tensiune"
            onChange={(e) => {
              const re = /^[,.0-9\b]+$/;

              if (e.target.value === '' || re.test(e.target.value)) {
                handleTensiune(e.target.value);
              }
            }}
            disabled={isEditing === false}
          />
        </Col>
      )}

      <ChoiceComponent
        title={'Solicit racordarea în varianta de organizare de șantier*'}
        options={[
          { title: 'Da', id: true },
          { title: 'Nu', id: false }
        ]}
        onChange={handleRacordareSantier}
        value={racordareSantier}
        disabled={isEditing === false}
        id="RS"
      />

      {racordareSantier && (
        <Row>
          <Col sm={12} md={6}>
            <Input
              label="Puterea maxim simultan absorbită aprobată pentru organizare de şantier (kW)*"
              value={KWSantier}
              onChange={(e) => {
                const re = /^[0-9\b]+$/;

                if (e.target.value === '' || re.test(e.target.value)) {
                  handleKWSantier(e.target.value);
                }
              }}
              disabled={isEditing === false}
            />
          </Col>
          <Col sm={12} md={6}>
            <Input
              label="Puterea maxim simultan absorbită aprobată pentru organizare de şantier (kVA)*"
              value={KVASantier}
              onChange={(e) => {
                const re = /^[0-9\b]+$/;

                if (e.target.value === '' || re.test(e.target.value)) {
                  handleKVASantier(e.target.value);
                }
              }}
              disabled={isEditing === false}
            />
          </Col>
          <Col xs={12} sm={6}>
            {/* <DateTime
              name={'data'}
              label={'Data'}
              itemTypeId={0}
              value={dataSantier}
              onChange={handleDataSantier}
              disabled={isEditing === false}
            /> */}
            <DatePicker
              label="Data valabilitate org. santier"
              name="Data valabilitate org. santier"
              selected={dataSantier === null ?  '' : dataSantier !== '' ? moment(dataSantier).toDate() : dataSantier}
              onChange={(e: any) => {
                  if (e !== null) {
                      let date = moment(e).format('YYYY-MM-DD');
                      handleDataSantier(date);
                  } else {
                    handleDataSantier('');
                  }
              }}
              disabled={isEditing === false}
              mbZero
            />
          </Col>
        </Row>
      )}

      {tipInstalatii.length > 0 && renderTipInstalatii()}

      {specificulActivitatii.length > 0 && renderSpecificulActivitatii()}
      {specificulActivitatii.length > 0 && specificulActivitatii[5].active === true && (
        <Col xs={12} sm={4}>
          <Input
            label="Specificul activității"
            value={otherSA}
            onChange={(e) => handleOtherSA(e.target.value)}
            disabled={isEditing === false}
          />
        </Col>
      )}

      <h5>Regimul de lucru (dacă e cazul)</h5>
      <Row className="mt-2 mb-2">
        <Col xs={12} sm={4}>
          <Input
            label="Nr. de schimburi"
            value={regimLucru.nrSchimburi}
            onChange={(e) => {
              const re = /^[0-9\b]+$/;

              if (e.target.value === '' || re.test(e.target.value)) {
                handleRegimLucru(e.target.value, 'nrSchimburi');
              }
            }}
            disabled={isEditing === false}
          />
        </Col>
        <Col xs={12} sm={4}>
          <Input
            label="Interval orar"
            value={regimLucru.interval}
            onChange={(e) => handleRegimLucru(e.target.value, 'interval')}
            disabled={isEditing === false}
          />
        </Col>
        <Col xs={12} sm={4}>
          <Input
            label="Nr. zile lucrătoare pe saptămână"
            value={regimLucru.nrZile}
            onChange={(e) => {
              const re = /^[0-9\b]+$/;

              if (e.target.value === '' || re.test(e.target.value)) {
                handleRegimLucru(e.target.value, 'nrZile');
              }
            }}
            disabled={isEditing === false}
          />
        </Col>
      </Row>

      <ChoiceComponent
        title={'Numărul de căi de alimentare solicitate*'}
        options={[
          { title: '1', id: 1 },
          { title: '2', id: 2 },
          { title: 'Altă valoare', id: 3 }
        ]}
        onChange={handleCaiAlimentare}
        value={caiAlimentare}
        disabled={isEditing === false}
        id="CA"
      />

      {caiAlimentare === 3 && (
        <Row className="mb-3">
          <Col xs={12} sm={3}>
            <Input
              label="Altă valoare*"
              value={alteCaiAlimentare}
              onChange={(e) => {
                const re = /^[0-9\b]+$/;

                if (e.target.value === '' || re.test(e.target.value)) {
                  handleAlteCaiAlimentare(e.target.value);
                }
              }}
              disabled={isEditing === false}
            />
          </Col>
        </Row>
      )}

      <h5>Surse de alimentare (altele decât surse regenerabile)</h5>
      <Row className="mt-2 mb-5">
        <Col xs={12} sm={3}>
          <Input
            label="Tip"
            value={surseDeAlimentare.tip}
            onChange={(e) => handleSurseDeAlimentare(e.target.value, 'tip')}
            disabled={isEditing === false}
          />
        </Col>
        <Col xs={12} sm={3}>
          <Input
            label="Putere (kW)"
            value={surseDeAlimentare.putere}
            onChange={(e) => {
              const re = /^[,.0-9\b]+$/;

              if (e.target.value === '' || re.test(e.target.value)) {
                handleSurseDeAlimentare(e.target.value, 'putere');
              }
            }}
            disabled={isEditing === false}
          />
        </Col>
        <Col xs={12} sm={3}>
          <Input
            label="Timp de pornire (secunde)"
            value={surseDeAlimentare.timpPornire}
            onChange={(e) => handleSurseDeAlimentare(e.target.value, 'timpPornire')}
            disabled={isEditing === false}
          />
        </Col>
        <Col xs={12} sm={3}>
          <Input
            label="Durată max de funcționare (ore)"
            value={surseDeAlimentare.durataFunc}
            onChange={(e) => handleSurseDeAlimentare(e.target.value, 'durataFunc')}
            disabled={isEditing === false}
          />
        </Col>
      </Row>

      <h5>Timpul maxim de întrerupere acceptat de procesul tehnologic</h5>
      <h6>
        Se va completa în cazul în care se solicită un timp maxim de întrerupere mai mic decât cel prevăzut de standardul de performanță
        pentru serviciul de distribuție a energiei electrice.
      </h6>
      <Row className="mt-2 mb-5">
        <Col xs={12} sm={4}>
          <Input
            label="Timpul maxim de întrerupere (ore)"
            value={timpIntrerupere}
            onChange={(e) => {
              const re = /^[0-9\b]+$/;

              if (e.target.value === '' || re.test(e.target.value)) {
                handleTimpIntrerupere(e.target.value);
              }
            }}
            disabled={isEditing === false}
          />
        </Col>
      </Row>

      <h5 id="LR">Lista receptoarelor cu precizarea puterii și a tensiunii nominale:*</h5>
      <DynamicTable format={listaReceptoare} data={dateLR} handler={handleDateLR} isEditing={isEditing} />

      <ChoiceComponent
        title={'În instalația de utilizare vor fi montate stații de încărcare a mașinilor electrice?*'}
        options={[
          { title: 'Da', id: 1 },
          { title: 'Nu', id: 2 }
        ]}
        onChange={handleSI}
        value={SI}
        disabled={isEditing === false}
        id="SI"
      />

      {SI === 1 && <DynamicTable format={statiiIncarcare} data={dateSI} handler={handleDateSI} isEditing={isEditing} />}
      {SI === 1 && (
        <div className="mt-2 mb-2">
          <br></br>
        </div>
      )}

      <ChoiceComponent
        title={'Din instalația de utilizare se vor alimenta și alți utilizatori?*'}
        options={[
          { title: 'Da', id: 1 },
          { title: 'Nu', id: 2 }
        ]}
        onChange={handleUE}
        value={UE}
        disabled={isEditing === false}
        id="UE"
      />

      {UE === 1 && <DynamicTable format={utilizatoriExterni} data={dateUE} handler={handleDateUE} isEditing={isEditing} />}
      {UE === 1 && (
        <div className="mt-2 mb-2">
          <br></br>
        </div>
      )}

      <ChoiceComponent
        title={'Solicit o dezvoltare în etape a obiectivului*'}
        options={[
          { title: 'Da', id: true },
          { title: 'Nu', id: false }
        ]}
        onChange={handleDezvoltare}
        value={dezvoltare}
        disabled={isEditing === false}
        id="etape"
      />

      {dezvoltare === true && (
        <ChoiceComponent
          title={'Număr etape de dezvoltare*'}
          options={[
            { title: '1', id: 1 },
            { title: '2', id: 2 },
            { title: '3', id: 3 },
            { title: '4', id: 4 },
            { title: '5', id: 5 }
          ]}
          onChange={handleEtape}
          value={etape}
          disabled={isEditing === false}
        />
      )}

      {dezvoltare === true && etape !== null && (
        <div className="d-flex flex-column mb-4">
          {Array.apply(0, Array(etape)).map((item: any, index: number) => {
            return (
              <Row key={index}>
                <Col xs={12} md={3}>
                  <Dropdown
                    options={
                      years.map((option, index) => ({
                        id: option.id,
                        name: option.name
                      })) as DropdownOptionType[]
                    }
                    onChange={(value) => {
                      handleDateEtape('an', index, value);
                    }}
                    label={'An dezvoltare*'}
                    defaultPlaceholder={'An dezvoltare'}
                    displaySearch={true}
                    value={dateEtape[index].an}
                    disabled={isEditing === false}
                  />
                </Col>
                <Col xs={12} md={3}>
                  <Input 
                    value={dateEtape[index].puterea_totala} 
                    onChange={(e) => handleDateEtape('puterea_totala', index, e.target.value)} 
                    label="Puterea total instalată*"
                    disabled={isEditing === false}
                  />
                </Col>
                <Col xs={12} md={3}>
                  <Input 
                    value={dateEtape[index].puterea_max_absorbita} 
                    onChange={(e) => handleDateEtape('puterea_max_absorbita', index, e.target.value)} 
                    label="Puterea totală simultan absorbită*"
                    disabled={isEditing === false}
                  />
                </Col>
                <Col xs={12} md={9}>
                  <hr className='mt-0' style={{marginBottom: '24px'}} />
                </Col>
              </Row>
            );
          })}
        </div>
      )}

      {(dezvoltare === true && eroriEtape !== '') && <p className="red fw-bold">{eroriEtape}</p>}

      <h5>Detalii energetice privind receptoarele, caracteristicile acestora, regim de funcționare, puteri instalate şi absorbite</h5>
      <Col xs={12} sm={4}>
        <Button onClick={() => isEditing !== false && handleShowDER(!showDER)} disabled={isEditing === false}>Adaugă detalii</Button>
      </Col>
      <br></br>

      {showDER && <DetaliiReceptoare detalii={detaliiEnergeticeReceptoare} handleDetalii={handleDateDER} isEditing={isEditing} />}

      <h5>Alte informații</h5>
      <Col xs={12} sm={6}>
        <p>
          Alte informații privind consumatorul, activitatea acestuia, elemente energetice ale instalațiilor şi receptoarelor, condiții de
          funcționare etc., care se consideră necesare pentru definirea şi caracterizarea locului de consum şi care să permită o analiză
          completă din punct de vedere energetic.
        </p>
      </Col>
      <Row className="mt-2 mb-5">
        <Col xs={12} sm={4}>
          <Input
            label="Alte informații"
            value={alteInformatii}
            onChange={(e) => handleAlteInformatii(e.target.value)}
            disabled={isEditing === false}
          />
        </Col>
      </Row>

      <br></br>
      <h5>Valoare orientativă calculată pentru Puterea totală instalată (kW) pentru receptoarele selectate de tine:</h5>
      <Row className="mt-2">
        <Col xs={12} sm={4}>
          <Input label="Puterea totală instalată (kW)" value={KWTotalInstalat} disabled />
        </Col>
      </Row>

      <h5 id="DT">Completează în tabelul de mai jos puterile solicitate și tipul de racord:*</h5>
      {tipLC === 1 && (
        <TabelTipRacordEE isEditing={isEditing} tableData={dateTehnice} type={1} handleData={handleDateTehnice} tipPutere={1} id="DATE" />
      )}
      {tipLC === 2 && (
        <TabelTipRacordEE isEditing={isEditing} tableData={dateTehnice} type={2} handleData={handleDateTehnice} tipPutere={1} id="DATE" />
      )}

      {validationError !== '' && <p style={{ fontWeight: 'bold', color: 'red' }}>{validationError}</p>}
      {error !== '' && <p style={{ color: 'red', fontWeight: 'bold' }}>{error}</p>}
      <div className="d-flex flex-row justify-content-between">
        <Button className="d-flex" onClick={handlePrevStep}>
          Pasul anterior
        </Button>
        <Button
          className="d-flex"
          onClick={() => {
            handleNextStep();
          }}
        >
          Pasul următor
        </Button>
      </div>

      {renderPowerModal()}
      {renderModal()}
    </div>
  );
};

export default LCNoncasnic;
