import axios from 'axios';
import {store} from '@lib/store';
import { proxy, Proxy } from '@api/azureProxy';

export function getElectronicRegistryList(payload: any) {
    const state = store.getState();

    return axios.get(
        `${state.options.apiHost}/iconnect/ElectronicRegistry/list?${payload.join('&')}`,
        {
            headers: {
                'Ocp-Apim-Subscription-Key': state.options.subscriptionKey as string
            }
        }
    );
};

export function registryExport(payload: any) {
    const state = store.getState();
  
    const proxyData: Proxy = {
        method: 'POST',
        endPoint: `/iconnect/ElectronicRegistry/export?${payload.join('&')}`,
        withAuthBearer: true
    };
  
    return proxy(proxyData, {
        headers: {
            'Authorization': 'Bearer ' + state.auth.token,
            'Access-Control-Allow-Headers': 'Authorization',
            'Access-Control-Allow-Credentials': true,
            'access-control-allow-methods': 'POST',
        },
        responseType: 'arraybuffer'
    });
  }

export function getRequestDetails(payload: any) {
    const state = store.getState();

    return axios.get(
        `${state.options.apiHost}/iconnect/ElectronicRegistry/details?requestId=${payload.requestId}`,
        {
            headers: {
                'Ocp-Apim-Subscription-Key': state.options.subscriptionKey as string
            }
        }
    );
};