import React, { useState, useEffect } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { Input } from '@components/common/Input';
import { isObjectEmpty } from '../../helpers/object';
import { requestMappingGN } from '@api/iConnect/iConnectGaz';
import { CustomModal } from '@components/common/ModalConfirm';
import { toggleLoader } from '@actions/LoaderActions';
import ChoiceModal from '@components/common/Modal/ChoiceModal';

const numericRegex = /^[0-9]*$/;

const AlocareCerereClient = () => {
  const [numarCerere, setNumarCerere] = useState('');
  const [codAbonat, setCodAbonat] = useState('');
  const [numarCerereTouched, setNumarCerereTouched] = useState(false);
  const [codAbonatTouched, setCodAbonatTouched] = useState(false);

  //errors
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [error, setError] = useState<string>('');

  //modal
  const [showModalError, setShowModalError] = useState(false);
  const [showModalSucces, setShowModalSucces] = useState(false);
  const [showModalSubmit, setShowModalSubmit] = useState(false);

  const [successRequestId, setSuccessRequestId] = useState<number | null>(null);
  const [successRequestUtility, setSuccessRequestUtility] = useState<string | null>(null);

  const dispatch = useDispatch();

  useEffect(() => {
    if (numarCerereTouched) {
      validateValues('numarCerere');
    }
    // eslint-disable-next-line
  }, [numarCerere, numarCerereTouched]);

  useEffect(() => {
    if (codAbonatTouched) {
      validateValues('codAbonat');
    }
    // eslint-disable-next-line
  }, [codAbonat, codAbonatTouched]);

  const validateNumarCerere = (errors: { [key: string]: string }) => {
    delete errors['numarCerere'];
    if (!numarCerere) {
      errors['numarCerere'] = 'Această valoare nu ar trebui să fie goală.';
    } else {
      if (!numarCerere.startsWith('0') && !numarCerere.startsWith('1') && !numarCerere.startsWith('2')) {
        errors['numarCerere'] = 'Codul introdus trebuie să înceapă cu 0, 1 sau 2.';
      } else if (numarCerere.length !== 9 && numarCerere.length !== 10) {
        errors['numarCerere'] = 'Cod invalid. Codul trebuie să conțină 9 sau 10 cifre.';
      }
    }
  };

  const validateCodAbonat = (errors: { [key: string]: string }) => {
    delete errors['codAbonat'];
    if (!codAbonat) {
      errors['codAbonat'] = 'Această valoare nu ar trebui să fie goală.';
    } else {
      if (codAbonatTouched && !codAbonat.startsWith('1')) {
        errors['codAbonat'] = 'Codul introdus trebuie să înceapă cu 1.';
      } else if (codAbonat.length !== 10) {
        errors['codAbonat'] = 'Cod invalid. Codul trebuie să conțină 10 cifre.';
      }
    }
  };

  const validateValues = (field?: 'numarCerere' | 'codAbonat') => {
    let newErrors: { [key: string]: string } = { ...errors };

    if (field) {
      switch (field) {
        case 'numarCerere':
          validateNumarCerere(newErrors);
          break;
        case 'codAbonat':
          validateCodAbonat(newErrors);
          break;
      }
    } else {
      validateNumarCerere(newErrors);
      validateCodAbonat(newErrors);
    }

    setErrors(newErrors);
    return isObjectEmpty(newErrors);
  };

  const submit = () => {
    setError('');
    setSuccessRequestId(null);
    setSuccessRequestUtility(null);

    dispatch(toggleLoader(true));

    requestMappingGN({
      sapNumber: numarCerere,
      codPA: codAbonat
    })
      .then((response) => {
        setSuccessRequestId(response.data.requestId);
        setSuccessRequestUtility(response.data.utility);

        setShowModalSucces(true);
        dispatch(toggleLoader(false));
      })
      .catch((err) => {
        if (err.response.status === 409) {
          setError('Cererea este deja alocată pe un cont!');
        } else if (err.response.status === 412) {
          setError('Nu a fost găsită cerere pentru alocare automată!');
        } else {
          setError('A apărut o eroare tehnică. Vă rugam reveniți mai târziu.');
        }
        setShowModalError(true);
        dispatch(toggleLoader(false));
      });
  };

  const handleCloseModal = (isSucces: boolean) => {
    if (isSucces) {
      window.location.href = `/lista-cereri-racordare?tab=${successRequestUtility?.toUpperCase()}&id=${successRequestId}`;
    } else {
      setShowModalError(false);
    }
  };

  return (
    <div className="m-3">
      <Row>
        <Col xs={12} sm={6}>
          <Row>
            <Input
              mbZero
              label="Număr cerere"
              value={numarCerere}
              onChange={(e) => {
                setNumarCerereTouched(true);
                if (numericRegex.test(e.target.value)) {
                  setNumarCerere(e.target.value);
                }
              }}
              onBlur={() => {
                setNumarCerereTouched(true);
              }}
            />
            {errors['numarCerere'] && <div style={{ color: '#ea1c0a', fontWeight: 'bold' }}>{errors['numarCerere']}</div>}
          </Row>

          <Row className="mt-3 mb-3">
            <Input
              mbZero
              label="Cod abonat"
              value={codAbonat}
              onChange={(e) => {
                setCodAbonatTouched(true);
                if (numericRegex.test(e.target.value)) {
                  setCodAbonat(e.target.value);
                }
              }}
              onBlur={() => {
                setCodAbonatTouched(true);
              }}
            />
            {errors['codAbonat'] && <div style={{ color: '#ea1c0a', fontWeight: 'bold' }}>{errors['codAbonat']}</div>}
          </Row>
        </Col>

        <Col xs={12} sm={6} className="m-0">
          <p className="p-gray">
            Dacă cererea ta a fost înregistrată în Centrul de Relaţii Clienţi, completează câmpurile alăturate pentru a avea la dispoziţie
            toate informaţiile despre paşii parcurşi şi etapele următoare.
          </p>
        </Col>
      </Row>
      <Button
        onClick={() => {
          if (!validateValues()) return;
          setShowModalSubmit(true);
        }}
        className="btn-large"
      >
        Adaugă cerere
      </Button>
      <CustomModal showModal={showModalError} modalTitle={'Eroare'} onClose={() => handleCloseModal(false)}>
        <h6>{error}</h6>
      </CustomModal>
      <CustomModal showModal={showModalSucces} modalTitle={'Succes'} onClose={() => handleCloseModal(true)}>
        <h6>Alocarea a fost efectuată cu succes.</h6>
      </CustomModal>

      <ChoiceModal
        showModal={showModalSubmit}
        modalTitle={
          <p style={{ fontWeight: 'normal', color: '#000', fontSize: '17px' }} className="mb-0">
            Prin alocarea cererii în contul de client sunt de accord ca toate comunicările și răspunsurile pentru prezenta cerere, dar și
            pentru viitoare adresări și răspunsuri să se efectueze prin adresa de email alocată contului din portal. Pentru detalii privind
            prelucrare datelor poti consulta secţiunea{' '}
            <a className="red hover-red pointer" href="https://delgaz.ro/protectia-datelor" target="_blank" rel="noreferrer">
              Protecția Datelor
            </a>
            .
          </p>
        }
        options={[
          { id: 'DA', title: 'Da' },
          { id: 'RENUNTA', title: 'Renunță' }
        ]}
        onClose={(option: any) => {
          if (option.id == 'DA') {
            submit();
            setShowModalSubmit(false);
          } else if (option.id == 'RENUNTA') {
            setShowModalSubmit(false);
          }
        }}
        onHide={() => {
          setShowModalSubmit(false);
        }}
        btnWide
      />
    </div>
  );
};

export default AlocareCerereClient;
