
type InputNumberComplexTableProps = {
  value: number | string;
  setValue: (value: string) => void;
};

const InputNumberComplexTable = (props: InputNumberComplexTableProps) => {
  return (
    <input
      className="table-input"
      value={props.value}
      type="number"
      step="0.01"
      onChange={(e) => {
        let stringValue = e.target.value as string;
        if (stringValue.slice(stringValue.lastIndexOf('.')).length <= 3) {
          props.setValue(e.target.value);
        }
      }}
    />
  );
};

export default InputNumberComplexTable;
