import React from 'react';
import { DelgazWrapper } from '@components/global/DelgazWrapper';
import RegistruCereriRacordareGN from '@components/registruCereriRacordare/RegistruCereriRacordareGN';

export const RegistruCereriGN = () => {
    return (
        <DelgazWrapper>
            <RegistruCereriRacordareGN />
        </DelgazWrapper>
    );
};