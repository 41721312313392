import { MONTHS } from '@constants/Months';
import { useState } from 'react';

export type DataItem = {
  name: string;
  label: string;
};

type DateTimeProps = {
  months: { year: string; month: string }[];
  onChange: (month: any) => void;
  large?: boolean;
};

export type DateRangeInputs = { [name: string]: Date | null };

const MonthSelector = ({ months, onChange, large }: DateTimeProps) => {
  const [selectedMonth, setSelectedMonth] = useState<any>(null);


  const getMonthName = (monthNumber: string) => {
    return MONTHS.find((month) => month.id == monthNumber)?.value;
  };

  return (
    <>
      <div className="" style={{ display: 'flex' }}>
        {months.map((month, index) => {
          let selected = month == selectedMonth;
          return (
            <div
              key={index}
              className={`${selected ? 'month-selector__option-selected' : 'month-selector__option'} ${large ? 'large' : ''}`}
              onClick={() => {
                setSelectedMonth(month);
                onChange(month);
              }}
            >
              <span>
                {getMonthName(month.month)} {month.year}
              </span>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default MonthSelector;
