import { SET_COUNTIES, SET_LOCALITIES} from '@constants/ActionTypes/PostTypes';
import { County } from '@reducers/geo';

export function fetchCounties() {
  return {
    type: SET_COUNTIES,
    payload: {}
  };
}

export function fetchLocalities(county: County) {
  return {
    type: SET_LOCALITIES,
    payload: { countyCode: county.geoCode}
  };
}
