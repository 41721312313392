import React from 'react';
import { DelgazWrapper } from '@components/global/DelgazWrapper';
import TimelineComponent from '@components/timeline/TimelineComponent';

export const TimelineComp = (props: any): JSX.Element => {
  return (
    <DelgazWrapper>
      <TimelineComponent />
    </DelgazWrapper>
  );
};
