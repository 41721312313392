import React, { Fragment } from 'react';
import { ValueToSet } from '../PageWrapper';

type ParagraphProps = {
  name: string;
  label: string;
  value?: string;
  itemTypeId?: number;
  onChange?: (e: ValueToSet) => void;
  disabled?: boolean;
  hideIfEmtyValue?: boolean;
  color?: 'red';
  fontStyle?: 'bold';
};

const Paragraph = ({ name, label, onChange, itemTypeId, value, disabled, hideIfEmtyValue, color, fontStyle }: ParagraphProps) => {
  return (
    <Fragment>
      {hideIfEmtyValue && !value ? (
        <></>
      ) : (
        <>
          <strong>{label}</strong>
          <p className={`${color && color == 'red' ? 'red' : ''} ${fontStyle && fontStyle == 'bold' ? 'fw-bold' : ''}`}>{value}</p>
        </>
      )}
    </Fragment>
  );
};

export default Paragraph;
