import React, { useEffect, useState, Fragment } from 'react';
import { Modal, Row, Col, Button } from 'react-bootstrap';
import moment from 'moment';
import { Input } from '@components/common/Input';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { addDetailsModifyConvention, addModifyConvention } from '@api/conventions/conventions';
import { toggleLoader } from '@actions/LoaderActions';
import { useDispatch } from 'react-redux';
import * as notification from '@lib/Notification';

const ERROR_DEVIATION_MESSAGE =
  'Convenția de consum propusă de Dumnevoastră are o deviație mai mare de 10% astfel că rămâne valabilă convenția de consum propusă de către Delgaz Grid. Dacă totuși doriți să continuați, solicitarea va primi număr de inregistrare însă propunerea de convenție va fi anulată.';

const ModifyModal = (props: any) => {
  const [cValues, setValues] = useState<any>(null);
  const [total, setTotal] = useState('0');
  const [message, setMessage] = useState('');
  const [errorDeviation, setErrorDeviation] = useState(false);

  let currentDate = moment();
  const { handleShow, modalData, requestId, onAddDetailsOrModifyConvention } = props;
  const dispatch = useDispatch();

  useEffect(() => {
    setErrorDeviation(false);
  }, [props.modalData]);

  useEffect(() => {
    if (cValues) {
      calculateTotal(cValues);
    }
  }, [cValues]);

  useEffect(() => {
    if (props.modalData.convention) {
      let conventionToFixed3 = {
        APR: props.modalData.convention.APR.toFixed(3),
        AUG: props.modalData.convention.AUG.toFixed(3),
        DEC: props.modalData.convention.DEC.toFixed(3),
        FEB: props.modalData.convention.FEB.toFixed(3),
        IAN: props.modalData.convention.IAN.toFixed(3),
        IUL: props.modalData.convention.IUL.toFixed(3),
        IUN: props.modalData.convention.IUN.toFixed(3),
        MAI: props.modalData.convention.MAI.toFixed(3),
        MAR: props.modalData.convention.MAR.toFixed(3),
        NOI: props.modalData.convention.NOI.toFixed(3),
        OCT: props.modalData.convention.OCT.toFixed(3),
        SEP: props.modalData.convention.SEP.toFixed(3)
      };
      setValues(conventionToFixed3);
      calculateTotal(props.modalData.convention);
    }
  }, [props.modalData]);

  const getKey = (k: string) => {
    if (k === 'mai') {
      return k.toUpperCase();
    } else if (k === 'sept.' || k === 'mart.') {
      return k.slice(0, -2).toUpperCase();
    } else if (k === 'nov.') {
      return 'NOI';
    } else {
      return k.slice(0, -1).toUpperCase();
    }
  };

  const handleValues = (value: string, fieldKey: string) => {
    let valid = true;

    if (!/^\d*\.?\d*$/.test(value)) {
      valid = false;
    } else if (value.includes('.')) {
      let afterDotCount = value.slice(value.lastIndexOf('.')).length;
      if (afterDotCount > 4) {
        valid = false;
      }
    }

    if (valid) {
      let newData = { ...cValues };
      Object.keys(newData).map((key: string) => {
        if (key === fieldKey) {
          //@ts-ignore
          newData[key] = value;
        }
      });
      setValues(newData);
    }
  };

  const calculateTotal = (values: any) => {
    setErrorDeviation(false);

    let t: number = 0;
    Object.keys(values).map((key: string, index) => {
      let value;
      if (!values[key]) {
        value = 0;
      } else {
        value = parseFloat(values[key]);
      }

      t += value;
    });

    let totalConvetionSAP = modalData.totalConvetionSAP;
    // let totalConvetionSAP = 0.742;

    let conventionPercent = modalData.conventionPercent;

    const maxDiff = (conventionPercent / 100) * t;

    if (t < totalConvetionSAP && totalConvetionSAP - t > maxDiff) {
      setErrorDeviation(true);
    }

    // if (totalConvetionSAP - t > (conventionPercent / 100) * t) {
    //   //diferenta mai mare de 10%
    //   setErrorDeviation(true);
    // }

    let newTotal = t.toFixed(3);
    setTotal(newTotal);
  };

  const addModify = async (values: any) => {
    dispatch(toggleLoader(true));
    setMessage('');

    const payload = {
      ...values,
      pod: modalData.consumptionPlace.pod
    };
    if (requestId) {
      payload.requestId = requestId;
    }

    delete payload['conventionId'];
    if (modalData.isEdit) {
      if (modalData.requestDetailId) {
        payload.requestDetailId = modalData.requestDetailId;
      }
      await addDetailsModifyConvention(payload)
        .then((response) => {
          if (response && response.status === 200) {
            notification.success('Locul de consum a fost adăugat cu succes.');
            onAddDetailsOrModifyConvention('');
          } else {
            notification.error('A apărut o eroare. Vă rugăm să reveniți mai tărziu.');
            dispatch(toggleLoader(false));
          }
        })
        .catch((err) => {
          notification.error('A apărut o eroare. Vă rugăm să reveniți mai tărziu.');
          dispatch(toggleLoader(false));
        });
    } else {
      await addModifyConvention(payload)
        .then((response) => {
          if (response && response.status === 200) {
            onAddDetailsOrModifyConvention(response.data.requestId);
            notification.success('Locul de consum a fost adăugat cu succes.');
          } else {
            notification.error('A apărut o eroare. Vă rugăm să reveniți mai tărziu.');
            dispatch(toggleLoader(false));
          }
        })
        .catch((err) => {
          notification.error('A apărut o eroare. Vă rugăm să reveniți mai tărziu.');
          dispatch(toggleLoader(false));
        });
    }
  };

  const capitalizeFirstLetter = (str: string) => {
    return str[0].toUpperCase() + str.slice(1);
  };

  const renderField = (index: number, props: any) => {
    const monthKey = getKey(moment(currentDate).add(index, 'M').format('MMM'));
    const placeholder = moment(currentDate).add(index, 'M').format('MMMM YYYY');
    return (
      <Fragment>
        <Field label={monthKey} id={monthKey} name={monthKey} placeholder={placeholder}>
          {({
            //@ts-ignore
            meta: { touched, error }
          }) => (
            <Input
              label={capitalizeFirstLetter(placeholder)}
              onBlur={() => {
                handleValues(Number(props.values[monthKey]).toFixed(3), monthKey);
                props.setFieldValue(monthKey, Number(cValues[monthKey]).toFixed(3));
              }}
              onChange={(e) => {
                let value = e.target.value as string;
                value = value.replaceAll(',', '.');
                handleValues(value, monthKey);
                props.setFieldValue(monthKey, value);
              }}
              value={cValues[monthKey]}
              mbZero
            />
          )}
        </Field>
        <ErrorMessage name={monthKey} render={(msg) => <div style={{ color: '#ea1c0a', fontWeight: 'bold' }}>{msg}</div>} />
      </Fragment>
    );
  };

  return (
    <Modal className="rss-modal" backdrop={'static'} show={props.show} onHide={() => props.handleShow(false)}>
      {props.modalData && (
        <Formik initialValues={props.modalData.convention} enableReinitialize={true} onSubmit={(values) => addModify(values)}>
          {(props) => (
            <Form className="m-0">
              <Modal.Header closeButton>
                <div className="p-0">
                  <h5 className="m-0 modal-title">Detalii loc de consum</h5>
                </div>
              </Modal.Header>
              <Modal.Body>
                <Row className="mb-4">
                  <Col xs={12} sm={6} className="d-flex flex-column" style={{ fontSize: '18px' }}>
                    <span>
                      <span style={{ opacity: '0.5' }}>Cod loc consum:</span> <span>{modalData.consumptionPlace.consumptionPlaceCode}</span>
                    </span>
                    <span>
                      <span style={{ opacity: '0.5' }}>Cod punct de măsurare:</span> <span>{modalData.consumptionPlace.pod}</span>
                    </span>
                    <span>
                      <span style={{ opacity: '0.5' }}>Stare contract:</span>{' '}
                      <span>{modalData.consumptionPlace.contractStatus === true ? 'Active' : 'Inactiv'}</span>
                    </span>
                    <span>
                      <span style={{ opacity: '0.5' }}>Denumirea furnizorului:</span> <span>{modalData.consumptionPlace.supplierName}</span>
                    </span>
                    <span>
                      <span style={{ opacity: '0.5' }}>Adresa:</span> <span>{modalData.consumptionPlace.address}</span>
                    </span>
                  </Col>
                  <Col xs={12} sm={6} className="d-flex flex-column" style={{ fontSize: '18px' }}>
                    <span>
                      <span style={{ opacity: '0.5' }}>Stare contor:</span> <span>{modalData.consumptionPlace.meterStatusName}</span>
                    </span>
                    <span>
                      <span style={{ opacity: '0.5' }}>Stare instalație:</span>{' '}
                      <span>{modalData.consumptionPlace.installationStatusName}</span>
                    </span>
                    <span>
                      <span style={{ opacity: '0.5' }}>Denumire client:</span> <span>{modalData.consumptionPlace.clientName}</span>
                    </span>
                  </Col>
                </Row>
                &nbsp;
                <div className="mt-2">
                  <div className="section-red-border" />
                  <h5 className="mb-4">Convenție de consum</h5>

                  <Row>
                    <Col xs={12} sm={4} className='mb-3'>
                      {renderField(1, props)}
                    </Col>
                    <Col xs={12} sm={4} className='mb-3'>
                      {renderField(2, props)}
                    </Col>
                    <Col xs={12} sm={4} className='mb-3'>
                      {renderField(3, props)}
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} sm={4} className='mb-3'>
                      {renderField(4, props)}
                    </Col>
                    <Col xs={12} sm={4} className='mb-3'>
                      {renderField(5, props)}
                    </Col>
                    <Col xs={12} sm={4} className='mb-3'>
                      {renderField(6, props)}
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} sm={4} className='mb-3'>
                      {renderField(7, props)}
                    </Col>
                    <Col xs={12} sm={4} className='mb-3'>
                      {renderField(8, props)}
                    </Col>
                    <Col xs={12} sm={4} className='mb-3'>
                      {renderField(9, props)}
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} sm={4} className='mb-3'>
                      {renderField(10, props)}
                    </Col>
                    <Col xs={12} sm={4} className='mb-3'>
                      {renderField(11, props)}
                    </Col>
                    <Col xs={12} sm={4} className='mb-3'>
                      {renderField(12, props)}
                    </Col>
                  </Row>

                  {message !== '' && <p className="red fw-bold">{message}</p>}
                  {errorDeviation && <p className="red fw-bold">{ERROR_DEVIATION_MESSAGE}</p>}
                </div>
              </Modal.Body>

              <Modal.Footer>
                <div className="d-flex justify-content-between w-100 align-items-center">
                  <div>
                    <h5>Total convenție: {isNaN(parseFloat(total)) ? 'Valoare invalida.' : total}</h5>
                  </div>
                  <div>
                    <Button className='mb-2' variant="secondary" style={{ marginRight: '8px' }} onClick={() => handleShow(false)}>
                      Renunță
                    </Button>
                    <Button className='mb-2' type="submit" disabled={!props.isValid}>
                      Salvează
                    </Button>
                  </div>
                </div>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      )}
    </Modal>
  );
};

export default ModifyModal;
