import React, { useRef, useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { defaultEvent } from '@constants/Event';
import { ChangeStatus } from '@constants/Utils';
import { ChangeStatusType } from '@type/input';
import { isTouchDevice, ToLowerCase, ToString } from '@lib/Utils';
import DropdownOption from './DropdownOption';

export enum DropdownKeyCodes {
  ENTER = 13,
  TAB = 9,
  SPACE = 32,
  LEFT = 37,
  UP = 38,
  RIGHT = 39,
  DOWN = 40
}

export type DropdownOptionType = {
  id: string | number;
  name: string;
  disabled?: boolean;
  extraPropertyName?: string;
  extraPropertyValue?: string | number;
};

export type DropdownProps = {
  label?: string; // Sets the label for the dropdown element.
  name?: string; // The name Attribute is a string that refers to the native name attribute of an HtmlInputElement.
  value?: string | number | string[]; // Reflects the native html value attribute.
  placeholder?: string; // Custom placeholder
  defaultPlaceholder?: string; // Custom placeholder
  error?: string; // Error message
  disabled?: string | boolean; // Wether or not the dropdown is disabled.
  style?: any; // Custom CSS style
  containerClassName?: string; // Container CSS class
  height?: string; // Defines the height of the drop down option list. E.g. "200px", "100px", "auto". If not set, height sticks to the max-height which is calc(100vh - 96px)
  options?: DropdownOptionType[]; // Dropdown options
  status?: ChangeStatusType; // Custom status
  statusKey?: ChangeStatus; // Custom statusKey
  displaySearch?: boolean; //Check is dropdown contain search
  isUsedInBackOffice?: boolean; //Is dropdown displayed in BO section
  onChange?: (value: string) => void; // Raised when the input has changed. Will emit the current value of the Input field.
  mbZero?: boolean;
  externalSearch?: string;
  externalUpdateSearch?: any;
};

type DropdownStateType = {
  collapsed: boolean;
  disabled: boolean;
  invalid: boolean;
  options: DropdownOptionType[];
  selectedOption: DropdownOptionType;
  selectedOptionIndex: number;
  value: any;
  search: string;
  filteredOptions: DropdownOptionType[];
};

const Dropdown = forwardRef((props: DropdownProps, ref) => {
  const dropdownButtonRef = useRef<HTMLDivElement>(null);
  const searchInputRef = useRef<HTMLInputElement>(null);

  const [state, setState] = useState<DropdownStateType>({
    collapsed: true,
    disabled: !!props.disabled,
    invalid: !!props.error,
    options: [...(props.options || [])],
    selectedOption: { id: '', name: '' },
    selectedOptionIndex: 0,
    value: props.value !== undefined ? props.value : '-1',
    search: '',
    filteredOptions: []
  });

  useEffect(() => {
    setState({ ...state, disabled: !!props.disabled });
  }, [props.disabled]);

  useImperativeHandle(ref, () => ({
    toggleOptionList
  }));

  useEffect(() => {
    setState({ ...state, value: props.value !== undefined ? props.value : '-1' });
  }, [props.value]);

  useEffect(() => {
    //convert value to type number if option id is of type number
    let typeOfOption = state.options[1] ? typeof state.options[1].id : state.options[0] ? typeof state.options[0]?.id : 'string';
    if (typeOfOption == 'number' && typeof state.value != 'number') {
      setState({ ...state, value: Number(state.value) });
    }
  }, [state.options, state.value]);

  useEffect(() => {
    let newFilteredOptions = state.options;
    if (!props.externalUpdateSearch) {
      newFilteredOptions = state.options.filter((option: any) => {
        return option.id !== '-1' && ToLowerCase(option.name).includes(ToLowerCase(state.search));
      });
    }

    if (props.externalUpdateSearch) {
      newFilteredOptions = state.options.filter((option: any) => {
        return option.id !== '-1' && ToLowerCase(option.name).includes(ToLowerCase(props.externalSearch));
      });
    }

    setState((prevState: any) => {
      return { ...prevState, filteredOptions: newFilteredOptions };
    });
  }, [state.search, state.options, props.externalSearch]);

  useEffect(() => {
    if (!isTouchDevice()) {
      document.addEventListener('click', handleOutsideClick);
    }

    return () => {
      if (!isTouchDevice()) {
        document.removeEventListener('click', handleOutsideClick);
      }
    };
  }, []);

  useEffect(() => {
    let options: any = [];
    if (props.placeholder) {
      options = [{ id: '-1', name: props.placeholder, disabled: true }, ...(props.options || [])];
    }
    if (props.defaultPlaceholder) {
      options = [{ id: '', name: props.defaultPlaceholder }, ...(props.options || [])];
    } else {
      options = [...(props.options || [])];
    }

    setState((prev) => ({ ...prev, options }));

    if (state.value !== undefined || state.value !== null) {
      let selectedOption = options.find((option: any) => option.id === state.value) || { id: '', name: '' };
      if (!selectedOption.id) {
        selectedOption = options.find((option: any) => option.id === `${state.value}`) || { id: '', name: props.placeholder || props.defaultPlaceholder || '' };
      }

      setState((prev) => ({ ...prev, selectedOption }));
      setSelectedOptionIndex();
    }
  }, [props.options, state.value]);

  const render = () => {
    const containerClass = ToString(props.containerClassName);
    const errorClass = props.error ? 'delgazui-dropdown-error' : '';
    const statusClass = ToString(props.status && props.statusKey).toLowerCase();

    return (
      <div
        className={`delgazui-dropdown-field-group field-group ${containerClass} ${errorClass} ${statusClass} ${props.mbZero && 'mb-0'}`}
        id="drop-down-delgaz"
        style={props.style}
      >
        {props.displaySearch ? (
          <>{isTouchDevice() ? renderTouchWithSearch() : renderNonTouchWithSearch()}</>
        ) : (
          <>{isTouchDevice() ? renderTouch() : renderNonTouch()}</>
        )}
        {props.error && <span className="label-error">{props.error}</span>}
        {props.status && props.statusKey && (
          <div className="status">
            <p>{props.status[props.statusKey]}</p>
          </div>
        )}
      </div>
    );
  };

  const renderTouch = () => {
    return (
      <div className={`delgazui-dropdown-wrapper ${!!state.selectedOption.name ? 'delgazui-touched' : ''}`}>
        <select
          onChange={handleSelectChange}
          className={`${state.disabled ? 'delgazui-disabled' : ''} ${state.invalid ? 'delgazui-invalid' : ''}`}
          disabled={state.disabled}
          name={props.name}
          value={state.value}
        >
          {state.options &&
            state.options.map((option, index) => (
              <option key={`${option.id}-${index}`} value={option.id} label={option.name} disabled={!!option.disabled}>
                {option.name}
              </option>
            ))}
        </select>
        <div
          role="button"
          className={`delgazui-dropdown-button ${state.disabled ? 'delgazui-disabled' : ''} ${state.invalid ? 'delgazui-invalid' : ''} ${
            state.collapsed ? 'delgazui-collapsed' : ''
          }`}
        >
          <div className="delgazui-option-label-wrapper">{state.selectedOption.name}</div>
          <div className="delgazui-icon-background">
            <svg className="delgazui-icon" width="16" height="32" viewBox="0 0 16 32" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M0 12a1 1 0 011.828-.561C4.046 14.712 6.12 17.212 8 18.875c1.88-1.665 3.956-4.165 6.172-7.436a1 1 0 011.656 1.122c-2.45 3.615-4.666 6.248-6.777 8.05-.605.52-1.5.52-2.104-.001-2.11-1.801-4.326-4.434-6.776-8.05a.998.998 0 01-.164-.44L0 12z"
                fillRule="evenodd"
              />
            </svg>
          </div>
        </div>
        <label htmlFor={props.name}>{props.label}</label>
      </div>
    );
  };

  const renderNonTouch = () => {
    return (
      <div className={`delgazui-dropdown-wrapper ${!!state.selectedOption.name ? 'delgazui-touched' : ''}`}>
        <div
          role="button"
          tabIndex={0}
          onKeyDown={handleKeyDown}
          onClick={toggleOptionList}
          ref={dropdownButtonRef}
          className={`delgazui-dropdown-button ${state.disabled ? 'delgazui-disabled' : ''} ${state.invalid ? 'delgazui-invalid' : ''} ${
            state.collapsed ? 'delgazui-collapsed' : ''
          }`}
        >
          <div className="delgazui-option-label-wrapper">{state.selectedOption.name}</div>
          <div className="delgazui-icon-background">
            <svg className="delgazui-icon" width="16" height="32" viewBox="0 0 16 32" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M0 12a1 1 0 011.828-.561C4.046 14.712 6.12 17.212 8 18.875c1.88-1.665 3.956-4.165 6.172-7.436a1 1 0 011.656 1.122c-2.45 3.615-4.666 6.248-6.777 8.05-.605.52-1.5.52-2.104-.001-2.11-1.801-4.326-4.434-6.776-8.05a.998.998 0 01-.164-.44L0 12z"
                fillRule="evenodd"
              />
            </svg>
          </div>
          <div className={`delgazui-option-list ${state.collapsed ? 'delgazui-collapsed' : ''}`} style={{ height: props.height }}>
            {state.options &&
              state.options.map((option, i) => (
                <DropdownOption
                  key={`${option.id}-${i}`}
                  value={option.id}
                  label={option.name}
                  disabled={!!option.disabled}
                  selected={state.selectedOption.name === option.name}
                  hasFocus={isFocused(i)}
                  onClick={(event) => {
                    event.preventDefault();
                    if (option.disabled) {
                      return event.stopPropagation();
                    } else {
                      return selectOption({ id: option.id, name: option.name });
                    }
                  }}
                />
              ))}
          </div>
        </div>
        <label htmlFor={props.name}>{props.label}</label>
      </div>
    );
  };

  const renderTouchWithSearch = () => {
    return (
      <div className={`delgazui-dropdown-wrapper ${!!state.selectedOption.name ? 'delgazui-touched' : ''}`}>
        <select
          onChange={handleSelectChange}
          className={`${state.disabled ? 'delgazui-disabled' : ''} ${state.invalid ? 'delgazui-invalid' : ''}`}
          disabled={state.disabled}
          name={props.name}
          value={state.value}
        >
          {state.filteredOptions &&
            state.filteredOptions.map((option: any, index) => (
              <option key={`${option.id}-${index}`} value={option.id} label={option.name} disabled={!!option.disabled}>
                {option.name}
              </option>
            ))}
        </select>
        <div
          role="button"
          className={`delgazui-dropdown-button ${state.disabled ? 'delgazui-disabled' : ''} ${state.invalid ? 'delgazui-invalid' : ''} ${
            state.collapsed ? 'delgazui-collapsed' : ''
          }`}
        >
          <div className="delgazui-option-label-wrapper">{state.selectedOption.name}</div>
          <div className="delgazui-icon-background">
            <svg className="delgazui-icon" width="16" height="32" viewBox="0 0 16 32" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M0 12a1 1 0 011.828-.561C4.046 14.712 6.12 17.212 8 18.875c1.88-1.665 3.956-4.165 6.172-7.436a1 1 0 011.656 1.122c-2.45 3.615-4.666 6.248-6.777 8.05-.605.52-1.5.52-2.104-.001-2.11-1.801-4.326-4.434-6.776-8.05a.998.998 0 01-.164-.44L0 12z"
                fillRule="evenodd"
              />
            </svg>
          </div>
        </div>
        <label htmlFor={props.name}>{props.label}</label>
      </div>
    );
  };

  const renderNonTouchWithSearch = () => {
    return (
      <div className={`delgazui-dropdown-wrapper ${!!state.selectedOption.name ? 'delgazui-touched' : ''}`}>
        <div
          role="button"
          tabIndex={0}
          onKeyDown={handleKeyDown}
          onClick={toggleOptionList}
          ref={dropdownButtonRef}
          className={`delgazui-dropdown-button ${state.disabled ? 'delgazui-disabled' : ''} ${state.invalid ? 'delgazui-invalid' : ''} ${
            state.collapsed ? 'delgazui-collapsed' : ''
          }`}
        >
          <div className="delgazui-option-label-wrapper">{state.selectedOption.name}</div>
          <div className="delgazui-icon-background">
            <svg className="delgazui-icon" width="16" height="32" viewBox="0 0 16 32" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M0 12a1 1 0 011.828-.561C4.046 14.712 6.12 17.212 8 18.875c1.88-1.665 3.956-4.165 6.172-7.436a1 1 0 011.656 1.122c-2.45 3.615-4.666 6.248-6.777 8.05-.605.52-1.5.52-2.104-.001-2.11-1.801-4.326-4.434-6.776-8.05a.998.998 0 01-.164-.44L0 12z"
                fillRule="evenodd"
              />
            </svg>
          </div>

          <div
            className={`delgazui-option-list search-list ${state.collapsed ? 'delgazui-collapsed' : ''}`}
            style={{ height: props.height }}
          >
            <div className="drp-search-cont" onClick={(event) => event?.stopPropagation()}>
              <div className="search-box">
                <div className="search-input">
                  {!props.externalUpdateSearch && <input type="search" ref={searchInputRef} autoFocus value={state.search} onChange={updateSearch} />}
                  {props.externalUpdateSearch && <input type="search" ref={searchInputRef} autoFocus value={props.externalSearch} onChange={props.externalUpdateSearch} />}
                  {!props.isUsedInBackOffice && (
                    <>
                      {/* <button type="button" className="close" onClick={() => setState({ search: '' })}>
                    <i className="icon close" />
                    </button> */}
                      {/* <button type="submit" className="search-icon">
                      <i className="icon search"></i>
                    </button> */}
                    </>
                  )}
                </div>
              </div>
              <p>
                <b>{props.placeholder}</b>
              </p>
            </div>
            <div className="scroll-content">
              {state.filteredOptions &&
                state.filteredOptions.map((option: any, i) => (
                  <DropdownOption
                    key={`${option.id}-${i}`}
                    value={option.id}
                    label={option.name}
                    disabled={!!option.disabled}
                    selected={state.selectedOption.name === option.name}
                    hasFocus={isFocused(i)}
                    onClick={(event) => {
                      event.preventDefault();
                      if (option.disabled) {
                        return event.stopPropagation();
                      } else {
                        return selectOption({ id: option.id, name: option.name });
                      }
                    }}
                  />
                ))}
            </div>
          </div>
        </div>
        <label htmlFor={props.name}>{props.label}</label>
      </div>
    );
  };

  const updateSearch = (event: any) => {
    setState({ ...state, search: event.target.value, selectedOptionIndex: -1 });
  };

  const isFocused = (index: any) => {
    return state.selectedOptionIndex === index + 1;
  };

  const handleKeyDown = (event: any) => {
    event = event || defaultEvent;

    if (state.disabled) {
      return;
    }

    if (props.displaySearch) {
      if (event.keyCode != DropdownKeyCodes.DOWN && event.keyCode != DropdownKeyCodes.UP) {
        searchInputRef.current?.focus();
      }

      switch (event.keyCode) {
        case DropdownKeyCodes.SPACE:
          if (state.collapsed) {
            toggleOptionList();
            event.preventDefault();
          } else {
            if (state.filteredOptions) {
              let option = state.filteredOptions[state.selectedOptionIndex - 1];
              if (option) {
                event.preventDefault();
                if (!option.disabled) {
                  selectOption({ id: option?.id, name: option?.name });
                  setState((prevState: any) => {
                    return { ...prevState, collapsed: true };
                  });
                } else {
                  return;
                }
              }
            }
          }
          break;

        case DropdownKeyCodes.DOWN:
          event.preventDefault();
          if (state.selectedOptionIndex === -1) {
            setState((prevState: any) => {
              return { ...prevState, selectedOptionIndex: 1 };
            });
          } else if (state.filteredOptions && state.selectedOptionIndex < state.filteredOptions.length) {
            setState((prevState: any) => {
              return { ...prevState, selectedOptionIndex: state.selectedOptionIndex + 1 };
            });
          }
          break;

        case DropdownKeyCodes.UP:
          event.preventDefault();
          if (state.selectedOptionIndex === -1) {
            setState((prevState: any) => {
              return { ...prevState, selectedOptionIndex: 1 };
            });
          } else if (state.selectedOptionIndex > 1) {
            setState((prevState: any) => {
              return { ...prevState, selectedOptionIndex: state.selectedOptionIndex - 1 };
            });
          }
          break;
      }
    } else {
      switch (event.keyCode) {
        case DropdownKeyCodes.SPACE:
          event.preventDefault();
          if (state.collapsed) {
            toggleOptionList();
          } else {
            if (state.options) {
              let option = state.options[state.selectedOptionIndex - 1];
              if (option.disabled) return;
              if (option) selectOption({ id: option?.id, name: option?.name });
              setState((prevState: any) => {
                return { ...prevState, collapsed: true };
              });
            }
          }
          break;

        case DropdownKeyCodes.DOWN:
          event.preventDefault();
          if (state.selectedOptionIndex === -1) {
            setState((prevState: any) => {
              return { ...prevState, selectedOptionIndex: 1 };
            });
          } else if (state.options && state.selectedOptionIndex < state.options.length) {
            setState((prevState: any) => {
              return { ...prevState, selectedOptionIndex: state.selectedOptionIndex + 1 };
            });
          }
          break;

        case DropdownKeyCodes.UP:
          event.preventDefault();
          if (state.selectedOptionIndex === -1) {
            setState((prevState: any) => {
              return { ...prevState, selectedOptionIndex: 1 };
            });
          } else if (state.selectedOptionIndex > 1) {
            setState((prevState: any) => {
              return { ...prevState, selectedOptionIndex: state.selectedOptionIndex - 1 };
            });
          }
          break;
      }
    }
  };

  const handleOutsideClick = (event: any) => {
    event = event || defaultEvent;

    if (dropdownButtonRef?.current?.contains(event?.target)) return;

    setState((prev) => ({ ...prev, collapsed: true }));
  };

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    event = event || defaultEvent;

    const changeEventTarget: HTMLSelectElement = event.target;

    selectOption({ id: changeEventTarget.value, name: changeEventTarget.options[changeEventTarget.selectedIndex].label });
  };

  const toggleOptionList = () => {
    if (state.disabled) {
      return;
    }
    setState((prev) => ({ ...prev, collapsed: !prev.collapsed, search: '' }));
    setSelectedOptionIndex();
    setTimeout(() => {
      searchInputRef.current?.focus();
    }, 100);
  };

  const selectOption = (option: any) => {
    setState((prev) => ({ ...prev, selectedOption: option }));
    changeValue(option.id);
  };

  const setSelectedOptionIndex = () => {
    if (state.options) {
      const selectedOptionIndex = state.options.findIndex((option) => option.id === state.selectedOption.id) + 1;
      setState((prev) => ({ ...prev, selectedOptionIndex }));
    }
  };

  const changeValue = async (value: string) => {
    await setState((prev) => ({ ...prev, value }));
    await setSelectedOptionIndex();
    await props.onChange?.(value);
  };

  return <div>{render()}</div>;
});

export default Dropdown;
