/* eslint-disable no-undef */
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { GoogleMapsKentico } from '@type/kentico-types';
import { useEffect, useState } from 'react';
import { Card, Form } from 'react-bootstrap';
import { GoogleMap, InfoWindow, Marker, withGoogleMap, withScriptjs } from 'react-google-maps';

// const useUnload = (fn: any) => {
//     const cb = useRef(fn); // init with fn, so that type checkers won't assume that current might be undefined
//
//     useEffect(() => {
//         cb.current = fn;
//     }, [fn]);
//
//     useEffect(() => {
//         const onUnload = (...args: any) => cb.current?.(...args);
//
//         window.addEventListener('beforeunload', onUnload);
//
//         return () => window.removeEventListener('beforeunload', onUnload);
//     }, []);
// };

interface LocationData {
    name: string;
    value: string,
}

interface Location extends Array<LocationData> { }
interface Locations extends Array<Location> { }

interface Coordinates {
    lat: number,
    lng: number,
}

export const GoogleMapsComponent = withScriptjs(withGoogleMap((props: { kentico: GoogleMapsKentico }) => {
    const [search, setSearch] = useState('');
    const [center, setCenter] = useState<Coordinates>({ lat: 45.6523093, lng: 25.6102746 });
    const [selectedLocation, setSelectedLocation] = useState<Location | null>(null);
    const [locations, setLocations] = useState<Locations>([]);
    const [isMobile, setIsMobile] = useState(false);

    // useUnload((e: any) => {
    //     e.preventDefault();
    //     e.returnValue = '';
    // });

    useEffect(() => {
        handleResize();
            Object.keys(props.kentico).map((name, i) => (
                setLocations(oldLocations => [...oldLocations, props.kentico[i].document])
            ));
    }, []);

    const handleResize = () => {
        if (window.innerWidth < 992) {
            setIsMobile(true);
        } else {
            setIsMobile(false);
        }
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
    });

    useEffect(() => {
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    });

    const findLatitude = (data: Location): number | undefined => {
        let value;

        data.map((locationData, index) => {
            if (locationData.name === 'Latitude') {
                value = Number(locationData.value);
            }
        });

        return value;
    };

    const findLongitude = (data: Location): number | undefined => {
        let value;

        data.map((locationData, index) => {
            if (locationData.name === 'Longitude') {
                value = Number(locationData.value);
            }
        });

        return value;
    };

    const attributesExists = (data: Location, attributes: string[]): boolean => {
        let found = 0;

        data.map((locationData, index) => {
            attributes.map((attribute, index) => {
                if (locationData.name === attribute) {
                    found += 1;
                }
            });
        });

        return found === attributes.length;
    };

    const isAttributeEmpty = (data: Location, attribute: string): boolean => {
        let empty = true;

        data.map((locationData, index) => {
            if (locationData.name === attribute && locationData.value !== '') {
                empty = false;
            }
        });

        return empty;
    };

    const showLocationAttribute = (data: Location, attribute: string): string | undefined => {
        let value;

        data.map((locationData, index) => {
            if (locationData.name === attribute) {
                value = locationData.value;
            }
        });

        return value;
    };

    const searchLocation = (data: Location, search: string) => {
        let found = false;
        data.map((attribute, index) => {
            if ((attribute.name === 'Localitate' || attribute.name === 'Oras' || attribute.name === 'Judet') && attribute.value.toLowerCase().includes(search.toLowerCase())) {
                found = true;
            }
        });

        return found;
    };

    return (
        <div className="m-5">
            {!isMobile && <Card className="p-2 locations-card">
                <Form.Control autoComplete="off" type="string" placeholder="Ce localitate cauți?" onChange={(e) => {
                    setSearch(e.target.value);
                }} />
                <div className="locations-container">
                    {locations && locations.filter((location, index) => {
                        if (search === '') {
                            return location;
                        } else if (searchLocation(location, search)) {
                            return location;
                        }
                    }).map((location, idx) => (
                        <div
                            key={idx}
                            className="d-flex p-2 location flex-column"
                            onClick={() => {
                                if (findLatitude(location) !== null && findLongitude(location) !== null) {
                                    setSelectedLocation(location);
                                    setCenter({ lat: findLatitude(location) as number, lng: findLongitude(location) as number + 2 });
                                }
                            }}
                        >
                            {location && location.map((attribute, i) => {
                                if (attribute.name === 'Localitate') {
                                    return (
                                        <span key={i}>
                                            <FontAwesomeIcon icon={faMapMarkerAlt} color="#ea1b0a" />
                                            <span style={{ marginLeft: '10px' }}>{attribute.value}</span>
                                        </span>);
                                }
                            })}
                        </div>
                    ))}
                </div>
            </Card>}
            {
                selectedLocation && findLatitude(selectedLocation) !== undefined && findLongitude(selectedLocation) !== undefined && (
                    //@ts-ignore
                    <InfoWindow
                        position={{ lat: findLatitude(selectedLocation as any) as number, lng: findLongitude(selectedLocation as any) as number }}
                        onCloseClick={() => setSelectedLocation(null)}
                    >
                        <div className="info-window" >
                            <>
                                {
                                    attributesExists(selectedLocation, ['Tip' || 'TipCentru', 'Localitate']) &&
                                    <h6 className="type">
                                        <span style={{ marginRight: '5px' }} dangerouslySetInnerHTML={{ __html: showLocationAttribute(selectedLocation, 'Tip' || 'TipCentru') || '' }} />
                                        <span dangerouslySetInnerHTML={{ __html: showLocationAttribute(selectedLocation, 'Localitate') || '' }} />
                                    </h6>
                                }
                                {
                                    attributesExists(selectedLocation, ['Program']) &&
                                    <p>
                                        <span dangerouslySetInnerHTML={{ __html: showLocationAttribute(selectedLocation, 'Program') || '' }} />
                                    </p>
                                }
                                {
                                    attributesExists(selectedLocation, ['Adresa']) ?
                                        <p>
                                            <span dangerouslySetInnerHTML={{ __html: showLocationAttribute(selectedLocation, 'Adresa') || '' }} />
                                        </p> :
                                        (attributesExists(selectedLocation, ['Adresă']) &&
                                            <p>
                                                <span dangerouslySetInnerHTML={{ __html: showLocationAttribute(selectedLocation, 'Adresă') || '' }} />
                                            </p>
                                        )
                                }
                                {
                                    attributesExists(selectedLocation, ['Telefon']) && !isAttributeEmpty(selectedLocation, 'Telefon') &&
                                    <p>
                                        <span>Telefon: &nbsp;</span> <span dangerouslySetInnerHTML={{ __html: showLocationAttribute(selectedLocation, 'Telefon') || '' }} />
                                    </p>
                                }
                                {
                                    attributesExists(selectedLocation, ['Fax']) && !isAttributeEmpty(selectedLocation, 'Fax') &&
                                    <p>
                                        <span>Fax: &nbsp;</span> <span dangerouslySetInnerHTML={{ __html: showLocationAttribute(selectedLocation, 'Fax') || '' }} />
                                    </p>
                                }
                                {
                                    attributesExists(selectedLocation, ['Email']) &&
                                    <p>
                                        <span dangerouslySetInnerHTML={{ __html: showLocationAttribute(selectedLocation, 'Email') || '' }} />
                                    </p>
                                }
                            </>
                        </div>
                    </InfoWindow>
                )
            }
            
            {
                //@ts-ignore 
                <GoogleMap
                defaultZoom={6.8}
                defaultCenter={center}
                center={center}
            >
                {
                    locations?.map((location, index) => (
                        findLatitude(location) !== null && findLongitude(location) !== null &&
                        <Marker
                            key={index}
                            position={{ lat: findLatitude(location as any) as number, lng: findLongitude(location as any) as number }}
                            onClick={() => {
                                setSelectedLocation(location);
                                setCenter({ lat: findLatitude(location) as number, lng: findLongitude(location) as number + 2 });
                            }}
                        />
                    )) ?? []
                }
            </GoogleMap>}
        </div>
    );
}
));