import { Dropdown, DropdownOptionType } from '@components/common/Dropdown';
import { Input } from '@components/common/Input';
import { ErrorMessage, Field, Form, Formik, FormikProps } from 'formik';
import React, { Fragment, useEffect, useState, useRef } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { IncarcareFisaNouaFormular } from '../../../helpers/schemas';
import DatePicker from '@components/common/Date/DatePicker';
import moment from 'moment';
import { UploadFile } from '@components/common/UploadFile';
import { File } from '@components/common/UploadFile/UploadFile';
import DynamicTable from '@components/common/DynamicTable/DynamicTable';
import { postIncarcareFisaNoua, updateBulkExportEntry } from '@api/revizii/revizii';
import { TYPE_REVIZIE } from './IncarcareFisaNoua';
import { useDispatch } from 'react-redux';
import { toggleLoader } from '@actions/LoaderActions';
import { CustomModal } from '@components/common/ModalConfirm';

type CpDataType = {
  address: string;
  building: string;
  clientFirstName: string;
  clientLastName: string;
  countyCode: string;
  countyName: string;
  houseNum1: string;
  houseNum2: string;
  isCompany: boolean;
  localityCode: number;
  localityName: string;
  pod: string;
  roomNumber: string;
  streetCode: number;
  streetName: string;
  utility: number;
  vstelle: string;
  companyName: string;
};

type BulkImportEntryDataType = {
  bulkImportId: number;
  bulkImportEntryId: number;
  nume_partener: string;
  adresa_loc_consum: string;
  societate: string;
  revisionTypeId: number;
  tip_lucrare: string;
  pod: string;
  clc: string;
  numar_contract: string;
  data_contract: string;
  numar_fisa: string;
  data_oe: string;
  data_lucrare: string;
  instalatii: number[];
  utilizare_conforma: boolean;
  utilizare_conforma_id: 1;
  utilizare_conforma_motiv: string;
  lista_sarcini: boolean;
  sistare_gaze: boolean;
  demontare_contor: boolean;
  nr_proces_verbal: string;
  sigiliu_nou: string;
  sigiliu_vechi: string;
  fileName: string;
  fileUrl: string;
  errors: FileErrorType[];
};

type FormularProps = {
  type: string;
  cpData: CpDataType;
  pod: string;
  clc: string;
  dictionare: any;
  bulkImportEntryData: BulkImportEntryDataType | null;
};

type FileErrorType = {
  propertyName: string;
  errorMessage: string;
};

const Formular = (props: FormularProps) => {
  const dispatch = useDispatch();
  const [name, setName] = useState('');
  const [adress, setAdress] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [tipLucrare, setTipLucrare] = useState<string | number>('');
  const [nrContract, setNrContract] = useState('');
  const [dataContract, setDataContract] = useState('');
  const [nrFisa, setNrFisa] = useState('');
  const [dataFisa, setDataFisa] = useState('');
  const [dataEfectuarii, setDataEfectuarii] = useState('');
  const [instalatieUtilizareComuna, setInstalatieUtilizareComuna] = useState(false);
  const [instalatieUtilizareIndividuala, setInstalatieUtilizareIndividuala] = useState(false);
  const [instalatieConforma, setInstalatieConforma] = useState<boolean | any>(null);
  const [tipNeconformitate, setTipNeconformitate] = useState<string | number>('');
  const [motiv, setMotiv] = useState('');
  const [listaModificata, setListaModificata] = useState<boolean | any>(null);
  const [sistareAlimentareCuGaze, setSistareAlimentareCuGaze] = useState<boolean | any>(null);
  const [demontareContor, setDemontareContor] = useState<boolean | any>(null);
  const [nrProcesVerbal, setNrProcesVerbal] = useState('');
  const [sigiliuVechi, setSigiliuVechi] = useState('');
  const [sigiliuNou, setSigiliuNou] = useState('');

  const [tipuriLucrare, setTipuriLucrare] = useState<DropdownOptionType[]>([]);
  const [tipuriNeconformitati, setTipuriNeconformitati] = useState<DropdownOptionType[]>([]);
  const [tipuriSarcini, setTipuriSarcini] = useState<DropdownOptionType[]>([]);
  const [tipuriInstalatie, setTipuriInstalatie] = useState<DropdownOptionType[]>([]);

  const [inintialFileUrl, setInintialFileUrl] = useState<any>('');

  //dynamic table values
  const [devices, setDevices] = useState<any[]>([]);

  //files
  const [fileList, setFileList] = useState<File[]>([] as File[]);

  //errors
  const formRef = useRef<FormikProps<any>>(null);

  const [error, setError] = useState('');
  const [fileErrors, setFileErrors] = useState<FileErrorType[]>([]);

  //date
  const [minDate, setMinDate] = useState<any>('');
  const [maxDate, setMaxDate] = useState<any>('');

  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showSuccessWithCommunicationErrorModal, setShowSuccessWithCommunicationErrorModal] = useState(false);
  const [showSuccessWithStatusCanceledModal, setShowSuccessWithStatusCanceledModal] = useState(false);

  const dynamicTableFormat = {
    columns: [
      { name: 'nrCrt', type: 'id', dataType: '', colName: 'Nr. CRT' },
      { name: 'tip', type: 'select', options: tipuriSarcini, dataType: 'numeric', colName: 'Tip' },
      { name: 'numar', type: 'input', dataType: 'string', colName: 'Numar' },
      { name: 'debit', type: 'input', dataType: 'numeric', floatPrecision: 2, colName: 'Debit' },
      { name: 'descriere', type: 'input', dataType: 'string', colName: 'Descriere' }
    ]
  };

  useEffect(() => {
    if (props.bulkImportEntryData) {
      initializeData();
      setFileErrors(props.bulkImportEntryData.errors);
    }
  }, [props.bulkImportEntryData]);

  useEffect(() => {
    if (instalatieConforma) {
      setTipNeconformitate('');
    }
  }, [instalatieConforma]);

  useEffect(() => {
    if (tipNeconformitate != '2') {
      setMotiv('');
    }
  }, [tipNeconformitate]);

  useEffect(() => {
    if (demontareContor == false) {
      setNrProcesVerbal('');
      setSigiliuVechi('');
      setSigiliuNou('');
    }
  }, [demontareContor]);

  useEffect(() => {
    if (props.dictionare) {
      setTipuriLucrare(props.dictionare.OptionType);
      setTipuriNeconformitati(props.dictionare.NonconformityType);
      setTipuriSarcini(props.dictionare.DeviceType);
      setTipuriInstalatie(props.dictionare.InstalationType);
    }
    if (!props.bulkImportEntryData) {
      setCompanyName(props.cpData.companyName ?? '');
      setName(getNameConcatenated() ?? '');
      setAdress(props.cpData.address ?? getAddressConcatenated());
    }
    setMinDate(moment().subtract(3, 'years').toDate());
    setMaxDate(moment().toDate());
  }, []);

  useEffect(() => {}, [devices, listaModificata]);

  useEffect(() => {
    let currentErrors = { ...formRef.current?.errors };

    if (instalatieUtilizareComuna || instalatieUtilizareIndividuala) {
      delete currentErrors.instalatieUtilizare;
    }
    if (fileList.length > 0) {
      delete currentErrors.fileEmpty;
    }
    if (tipNeconformitate) {
      delete currentErrors.tipNeconformitateFisaRvRules;
    }
    if (motiv) {
      delete currentErrors.motiv;
    }
    if (nrProcesVerbal) {
      delete currentErrors.nrProcesVerbal;
    }
    if (sigiliuVechi) {
      delete currentErrors.sigiliuVechi;
    }
    if (sigiliuNou) {
      delete currentErrors.sigiliuNou;
    }

    let valid = true;
    if (listaModificata) {
      devices.forEach((device) => {
        if (!device.tip || !device.debit || !device.descriere || !device.numar) {
          valid = false;
        }
      });
      if (valid) {
        delete currentErrors.devices;
      }
    }

    formRef.current?.setErrors({ ...currentErrors });
  }, [
    instalatieUtilizareComuna,
    instalatieUtilizareIndividuala,
    fileList,
    tipNeconformitate,
    motiv,
    nrProcesVerbal,
    sigiliuVechi,
    sigiliuNou,
    devices,
    listaModificata
  ]);

  const getNameConcatenated = () => {
    let name = '';
    if (props.cpData.clientLastName) {
      name += `${props.cpData.clientLastName}`;
    }
    if (props.cpData.clientFirstName) {
      name += ` ${props.cpData.clientFirstName}`;
    }
    return name;
  };

  const getAddressConcatenated = () => {
    let address = '';
    if (props.cpData.countyName) {
      address += `${props.cpData.countyName}`;
    }
    if (props.cpData.localityName) {
      address += `, ${props.cpData.localityName}`;
    }
    if (props.cpData.streetName) {
      address += `, ${props.cpData.streetName}`;
    }
    if (props.cpData.building) {
      address += `, ${props.cpData.building}`;
    }
    if (props.cpData.houseNum1) {
      address += `, ${props.cpData.houseNum1}`;
    }
    if (props.cpData.houseNum2) {
      address += `, ${props.cpData.houseNum2}`;
    }
    return address;
  };

  const validateValues = (values: any) => {
    let errors: any = {};

    if (!instalatieUtilizareComuna && !instalatieUtilizareIndividuala) {
      errors.instalatieUtilizare = 'Câmp obligatoriu.';
    }
    if (fileList.length == 0) {
      errors.fileEmpty = 'Documentul este obligatoriu.';
    }

    if (!instalatieConforma) {
      if (!tipNeconformitate) {
        errors.tipNeconformitateFisaRvRules = 'Câmp obligatoriu.';
      }
      if (tipNeconformitate == '2') {
        if (!motiv) {
          errors.motiv = 'Câmp obligatoriu.';
        }
      }
    }

    if (demontareContor) {
      if (!nrProcesVerbal) {
        errors.nrProcesVerbal = 'Câmpul Nr. proces verbal este obligatoriu.';
      }
      if (!sigiliuVechi) {
        errors.sigiliuVechi = 'Câmpul Sigiliu vechi este obligatoriu.';
      }
      if (!sigiliuNou) {
        errors.sigiliuNou = 'Câmpul Sigiliu nou este obligatoriu.';
      }
    }

    if (listaModificata) {
      devices.forEach((device) => {
        if (!device.tip || !device.debit || !device.descriere || !device.numar) {
          errors.devices = 'Vă rugăm să completați toate câmpurile din tabel!';
        }
      });
    }

    return errors;
  };

  const submit = (values: any) => {
    setFileErrors([]);

    dispatch(toggleLoader(true));

    let formData = new FormData();
    formData.append('ConsumptionPoint', props.pod ? props.pod : props.clc ? props.clc : '');
    formData.append('RevisionType', props.type == TYPE_REVIZIE ? 'REV' : 'INSP');
    formData.append('ContractNumber', nrContract);
    formData.append('ContractDate', dataContract);
    formData.append('OE', nrFisa);
    formData.append('OEDate', dataFisa);
    formData.append('ExecutionDate', dataEfectuarii);
    formData.append('OptionTypeId', tipLucrare as string);
    formData.append('IsConformity', instalatieConforma ? 'true' : 'false');
    formData.append('NonconformityTypeId', tipNeconformitate ? (tipNeconformitate as string) : '0');
    formData.append('NonconformityReason', motiv);
    formData.append('HasDeviceChanges', listaModificata ? 'true' : 'false');
    formData.append('GasInterruption', sistareAlimentareCuGaze ? 'true' : 'false');
    formData.append('RemoveMeter', demontareContor ? 'true' : 'false');
    formData.append('RemovalProtocolNumber', nrProcesVerbal);
    formData.append('OldSealNumber', sigiliuVechi);
    formData.append('NewSealNumber', sigiliuNou);

    //fileList size == 1
    fileList.map((file) => {
      if (inintialFileUrl && file.fileUrl && file.fileUrl == inintialFileUrl) {
        formData.append('InitialFileWasNotChanged', 'true');
      } else {
        formData.append('InitialFileWasNotChanged', 'false');
        formData.append('File', file as Blob);
      }
    });

    const instalatiiDeAdaugat = [];

    if (instalatieUtilizareComuna) {
      let idComuna = (tipuriInstalatie.find((ti) => ti.name == 'Comuna')?.id as string) ?? ('1' as string);
      instalatiiDeAdaugat.push(idComuna);
    }
    if (instalatieUtilizareIndividuala) {
      let idIndividuala = (tipuriInstalatie.find((ti) => ti.name == 'Individuala')?.id as string) ?? '2';
      instalatiiDeAdaugat.push(idIndividuala);
    }

    instalatiiDeAdaugat.forEach((intalatie, index) => {
      formData.append(`Instalations[${index}].InstalationTypeId`, intalatie);
    });

    if (listaModificata) {
      devices.forEach((device, index) => {
        formData.append(`Devices[${index}].DeviceTypeId`, (tipuriSarcini.find((ts) => ts.name == device.tip)?.id ?? '') as string);
        formData.append(`Devices[${index}].DeviceNumber`, device.numar);
        formData.append(`Devices[${index}].InstalledFlow`, device.debit);
        formData.append(`Devices[${index}].Description`, device.descriere);
      });
    }

    if (props.bulkImportEntryData) {
      formData.append('BulkImportEntryId', props.bulkImportEntryData.bulkImportEntryId as unknown as string);
      updateBulkExportEntry(formData)
        .then((res) => {
          dispatch(toggleLoader(false));
          setShowSuccessModal(true);
        })
        .catch((err) => {
          if (err.response.status == 506) {
            setShowSuccessWithCommunicationErrorModal(true);
          } else if (err.response.status == 507) {
            setShowSuccessWithStatusCanceledModal(true);
          } else if (err.response.status == 412) {
            setError('Intrarea din bulk a fost anulată deoarece nu mai este validă.');
          } else {
            setError('A intervenit o eroare tehnică. Vă rugăm reveniți mai târziu.');
          }
          dispatch(toggleLoader(false));
        });
    } else {
      postIncarcareFisaNoua(formData)
        .then((res) => {
          dispatch(toggleLoader(false));
          setShowSuccessModal(true);
        })
        .catch((err) => {
          if (err.response.status == 506) {
            setShowSuccessWithCommunicationErrorModal(true);
          } else if (err.response.status == 507) {
            setShowSuccessWithStatusCanceledModal(true);
          } else {
            setError('A intervenit o eroare tehnică. Vă rugăm reveniți mai târziu.');
          }
          dispatch(toggleLoader(false));
        });
    }
  };

  const handleCloseModalSuccess = () => {
    window.location.href = '/istoric-incarcari-fisa';
  };

  const initializeData = () => {
    if (props.bulkImportEntryData) {
      setNrContract(props.bulkImportEntryData.numar_contract);
      setDataContract(props.bulkImportEntryData.data_contract);
      setNrFisa(props.bulkImportEntryData.numar_fisa);
      setDataFisa(props.bulkImportEntryData.data_oe);
      setSigiliuVechi(props.bulkImportEntryData.sigiliu_vechi);
      setSigiliuNou(props.bulkImportEntryData.sigiliu_nou);
      setNrProcesVerbal(props.bulkImportEntryData.nr_proces_verbal);
      setSistareAlimentareCuGaze(props.bulkImportEntryData.sistare_gaze);
      setName(props.bulkImportEntryData.nume_partener);
      setAdress(props.bulkImportEntryData.adresa_loc_consum);
      setCompanyName(props.bulkImportEntryData.societate ?? '');
      setDataEfectuarii(props.bulkImportEntryData.data_lucrare);
      setTipLucrare(props.bulkImportEntryData.tip_lucrare ? Number(props.bulkImportEntryData.tip_lucrare) : 0);
      setInstalatieConforma(props.bulkImportEntryData.utilizare_conforma);
      setTipNeconformitate(props.bulkImportEntryData.utilizare_conforma_id);
      setMotiv(props.bulkImportEntryData.utilizare_conforma_motiv);
      setListaModificata(props.bulkImportEntryData.lista_sarcini);
      setSistareAlimentareCuGaze(props.bulkImportEntryData.sistare_gaze);
      setDemontareContor(props.bulkImportEntryData.demontare_contor);

      if (
        props.bulkImportEntryData.instalatii.find((inst) => {
          return inst == 1;
        })
      ) {
        setInstalatieUtilizareComuna(true);
      }
      if (
        props.bulkImportEntryData.instalatii.find((inst) => {
          return inst == 2;
        })
      ) {
        setInstalatieUtilizareIndividuala(true);
      }

      if (props.bulkImportEntryData.fileUrl) {
        setInintialFileUrl(props.bulkImportEntryData.fileUrl);
        setFileList([
          {
            fileUrl: props.bulkImportEntryData.fileUrl,
            name: props.bulkImportEntryData.fileName
          }
        ]);
      }
    }
  };

  const getFileError = (propertyName: string) => {
    return fileErrors.find((error: FileErrorType) => error.propertyName == propertyName)?.errorMessage ?? '';
  };

  return (
    <Fragment>
      <Formik
        enableReinitialize
        initialValues={{
          tipLucrare: props.bulkImportEntryData ? props.bulkImportEntryData.tip_lucrare : '',
          nrContract: props.bulkImportEntryData ? props.bulkImportEntryData.numar_contract : '',
          dataContract: props.bulkImportEntryData ? props.bulkImportEntryData.data_contract : '',
          nrFisa: props.bulkImportEntryData ? props.bulkImportEntryData.numar_fisa : '',
          dataFisa: props.bulkImportEntryData ? props.bulkImportEntryData.data_oe : '',
          dataEfectuarii: props.bulkImportEntryData ? props.bulkImportEntryData.data_lucrare : '',
          instalatieConforma: props.bulkImportEntryData ? props.bulkImportEntryData.utilizare_conforma : '',
          listaModificata: props.bulkImportEntryData ? props.bulkImportEntryData.lista_sarcini : '',
          sistareAlimentareCuGaze: props.bulkImportEntryData ? props.bulkImportEntryData.sistare_gaze : '',
          demontareContor: props.bulkImportEntryData ? props.bulkImportEntryData.demontare_contor : '',
          sigiliuNou: '',
          instalatieUtilizare: '',
          fileEmpty: '',
          tipNeconformitateFisaRvRules: '',
          motiv: '',
          nrProcesVerbal: '',
          sigiliuVechi: '',
          devices: ''
        }}
        onSubmit={(values) => {
          submit(values);
        }}
        validate={validateValues}
        validationSchema={IncarcareFisaNouaFormular}
        innerRef={formRef}
      >
        {(props) => {
          return (
            <Fragment>
              <Form>
                <Row>
                  <Col xs={12} md={6} className="mt-3">
                    <Field
                      id="name"
                      name="name"
                      render={() => <Input label="Nume și prenume/Denumire partener" mbZero value={name} disabled />}
                    />
                    <ErrorMessage name="name" render={(msg) => <div style={{ color: 'red', fontWeight: 'bold' }}>{msg}</div>} />
                  </Col>

                  <Col xs={12} md={6} className="mt-3">
                    <Field id="adress" name="adress" render={() => <Input label="Adresă loc de consum" mbZero value={adress} disabled />} />
                    <ErrorMessage name="adress" render={(msg) => <div style={{ color: 'red', fontWeight: 'bold' }}>{msg}</div>} />
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={6} className="mt-3">
                    <Field
                      id="companyName"
                      name="companyName"
                      render={() => <Input label="Denumire societate" mbZero value={companyName} disabled />}
                    />
                    <ErrorMessage name="companyName" render={(msg) => <div style={{ color: 'red', fontWeight: 'bold' }}>{msg}</div>} />
                  </Col>
                  <Col xs={12} md={6} className="mt-3">
                    <Dropdown
                      options={tipuriLucrare as DropdownOptionType[]}
                      label={'Tip lucrare'}
                      defaultPlaceholder={'Tip lucrare'}
                      displaySearch={true}
                      onChange={(value) => {
                        setTipLucrare(value);
                        value && props.setFieldValue('tipLucrare', value);
                        value.length === 0 && props.setFieldValue('tipLucrare', '');
                      }}
                      mbZero
                      value={tipLucrare}
                    />
                    {getFileError('tip_lucrare') ? (
                      <div style={{ color: 'red', fontWeight: 'bold' }}>{getFileError('tip_lucrare')}</div>
                    ) : (
                      <ErrorMessage name="tipLucrare" render={(msg) => <div style={{ color: 'red', fontWeight: 'bold' }}>{msg}</div>} />
                    )}
                  </Col>
                </Row>

                <Row>
                  <Col xs={12} md={6} className="mt-3">
                    <Field
                      id="nrContract"
                      name="nrContract"
                      render={() => (
                        <Input
                          label="Nr. contract prestări servicii"
                          mbZero
                          value={nrContract}
                          onBlur={() => {
                            props.setFieldTouched('nrContract');
                          }}
                          onChange={(e: any) => {
                            e.target.value.length !== 0 && props.setFieldValue('nrContract', e.target.value);
                            e.target.value.length === 0 && props.setFieldValue('nrContract', '');
                            setNrContract(e.target.value);
                          }}
                        />
                      )}
                    />
                    {getFileError('numar_contract') ? (
                      <div style={{ color: 'red', fontWeight: 'bold' }}>{getFileError('numar_contract')}</div>
                    ) : (
                      <ErrorMessage name="nrContract" render={(msg) => <div style={{ color: 'red', fontWeight: 'bold' }}>{msg}</div>} />
                    )}
                  </Col>
                  <Col xs={12} md={6} className="mt-3">
                    <Field
                      id="dataContract"
                      name="dataContract"
                      render={() => (
                        <DatePicker
                          acceptedDifferenceInYears={20}
                          mbZero
                          label="Dată contract prestări servicii"
                          name="Dată contract prestări servicii"
                          selected={dataContract ? moment(dataContract).toDate() : dataContract}
                          onChange={(e: any) => {
                            let date = e ? moment(e).format('YYYY-MM-DD') : '';
                            setDataContract(date);
                            props.setFieldValue('dataContract', date);
                          }}
                          minDate={minDate}
                          maxDate={maxDate}
                        />
                      )}
                    />
                    {getFileError('data_contract') ? (
                      <div style={{ color: 'red', fontWeight: 'bold' }}>{getFileError('data_contract')}</div>
                    ) : (
                      <ErrorMessage name="dataContract" render={(msg) => <div style={{ color: 'red', fontWeight: 'bold' }}>{msg}</div>} />
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={6} className="mt-3">
                    <Field
                      id="nrFisa"
                      name="nrFisa"
                      render={() => (
                        <Input
                          label="Nr.înregistrare fișa OE"
                          mbZero
                          value={nrFisa}
                          onBlur={() => {
                            props.setFieldTouched('nrFisa');
                          }}
                          onChange={(e: any) => {
                            e.target.value.length !== 0 && props.setFieldValue('nrFisa', e.target.value);
                            e.target.value.length === 0 && props.setFieldValue('nrFisa', '');
                            setNrFisa(e.target.value);
                          }}
                        />
                      )}
                    />
                    {getFileError('numar_fisa') ? (
                      <div style={{ color: 'red', fontWeight: 'bold' }}>{getFileError('numar_fisa')}</div>
                    ) : (
                      <ErrorMessage name="nrFisa" render={(msg) => <div style={{ color: 'red', fontWeight: 'bold' }}>{msg}</div>} />
                    )}
                  </Col>
                  <Col xs={12} md={6} className="mt-3">
                    <Field
                      id="dataFisa"
                      name="dataFisa"
                      render={() => (
                        <DatePicker
                          acceptedDifferenceInYears={20}
                          mbZero
                          label="Dată înregistrare fișa OE"
                          name="Dată înregistrare fișa OE"
                          selected={dataFisa ? moment(dataFisa).toDate() : dataFisa}
                          onChange={(e: any) => {
                            let date = e ? moment(e).format('YYYY-MM-DD') : '';
                            setDataFisa(date);
                            props.setFieldValue('dataFisa', date);
                          }}
                          minDate={minDate}
                          maxDate={maxDate}
                        />
                      )}
                    />
                    {getFileError('data_oe') ? (
                      <div style={{ color: 'red', fontWeight: 'bold' }}>{getFileError('data_oe')}</div>
                    ) : (
                      <ErrorMessage name="dataFisa" render={(msg) => <div style={{ color: 'red', fontWeight: 'bold' }}>{msg}</div>} />
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={6} className="mt-3">
                    <Field
                      id="dataEfectuarii"
                      name="dataEfectuarii"
                      render={() => (
                        <DatePicker
                          acceptedDifferenceInYears={20}
                          mbZero
                          label="Data efectuării verificării/reviziei"
                          name="Data efectuării verificării/reviziei"
                          selected={dataEfectuarii ? moment(dataEfectuarii).toDate() : dataEfectuarii}
                          onChange={(e: any) => {
                            let date = e ? moment(e).format('YYYY-MM-DD') : '';
                            setDataEfectuarii(date);
                            props.setFieldValue('dataEfectuarii', date);
                          }}
                          minDate={minDate}
                          maxDate={maxDate}
                        />
                      )}
                    />
                    {getFileError('data_lucrare') ? (
                      <div style={{ color: 'red', fontWeight: 'bold' }}>{getFileError('data_lucrare')}</div>
                    ) : (
                      <ErrorMessage name="dataEfectuarii" render={(msg) => <div style={{ color: 'red', fontWeight: 'bold' }}>{msg}</div>} />
                    )}
                  </Col>
                  <Col xs={12} md={6} className="mt-3">
                    <strong>Instalație utilizare</strong>

                    <div className="d-flex" style={{ flexDirection: 'row' }}>
                      <div onClick={() => setInstalatieUtilizareComuna(!instalatieUtilizareComuna)} className="checkbox-container pointer">
                        <input
                          className="create-account-checkbox pointer"
                          type="checkbox"
                          checked={instalatieUtilizareComuna}
                          onChange={() => setInstalatieUtilizareComuna(!instalatieUtilizareComuna)}
                        />
                        <span className="fw-bold checkbox-span" style={{ marginLeft: '5px' }}>
                          Comună
                        </span>
                      </div>

                      <div
                        onClick={() => setInstalatieUtilizareIndividuala(!instalatieUtilizareIndividuala)}
                        className="checkbox-container pointer"
                      >
                        <input
                          className="create-account-checkbox pointer"
                          type="checkbox"
                          checked={instalatieUtilizareIndividuala}
                          onChange={() => setInstalatieUtilizareIndividuala(!instalatieUtilizareIndividuala)}
                        />
                        <span className="fw-bold checkbox-span" style={{ marginLeft: '5px' }}>
                          Individuală
                        </span>
                      </div>
                    </div>

                    {getFileError('instalatii') ? (
                      <div style={{ color: 'red', fontWeight: 'bold' }}>{getFileError('instalatii')}</div>
                    ) : (
                      <ErrorMessage
                        name="instalatieUtilizare"
                        render={(msg) => <div style={{ color: 'red', fontWeight: 'bold' }}>{msg}</div>}
                      />
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={3} className="mt-3">
                    <strong>Instalație Utilizare Conformă</strong>
                    <div className="d-flex" style={{ flexDirection: 'row' }}>
                      <span
                        onClick={() => {
                          setInstalatieConforma(true);
                          props.setFieldValue('instalatieConforma', true);
                        }}
                        className="me-2"
                      >
                        <input
                          className="custom-radio-button pointer"
                          type="radio"
                          value={instalatieConforma}
                          checked={instalatieConforma == true}
                          onChange={() => {
                            setInstalatieConforma(true);
                            props.setFieldValue('instalatieConforma', true);
                          }}
                          style={{ marginRight: '6px' }}
                        />
                        <span className="fw-bold pointer">Da</span>
                      </span>
                      <span
                        onClick={() => {
                          setInstalatieConforma(false);
                          props.setFieldValue('instalatieConforma', false);
                        }}
                      >
                        <input
                          className="custom-radio-button pointer"
                          type="radio"
                          value={instalatieConforma}
                          checked={instalatieConforma == false}
                          onChange={() => {
                            setInstalatieConforma(false);
                            props.setFieldValue('instalatieConforma', false);
                          }}
                          style={{ marginRight: '6px' }}
                        />
                        <span className="fw-bold pointer">Nu</span>
                      </span>
                    </div>

                    {getFileError('utilizare_conforma') ? (
                      <div style={{ color: 'red', fontWeight: 'bold' }}>{getFileError('utilizare_conforma')}</div>
                    ) : (
                      <ErrorMessage
                        name="instalatieConforma"
                        render={(msg) => <div style={{ color: 'red', fontWeight: 'bold' }}>{msg}</div>}
                      />
                    )}
                  </Col>
                  {instalatieConforma == false && (
                    <Col xs={12} md={9}>
                      <Row>
                        <Col xs={12} md={6} className="mt-3">
                          <Dropdown
                            options={tipuriNeconformitati as DropdownOptionType[]}
                            label={'Tip neconformitate'}
                            defaultPlaceholder={'Tip neconformitate'}
                            displaySearch={true}
                            onChange={(value) => {
                              setTipNeconformitate(value);
                              value && props.setFieldValue('tipNeconformitate', value);
                              value.length === 0 && props.setFieldValue('tipNeconformitate', '');
                            }}
                            mbZero
                            value={tipNeconformitate}
                          />

                          {getFileError('utilizare_conforma_id') ? (
                            <div style={{ color: 'red', fontWeight: 'bold' }}>{getFileError('utilizare_conforma_id')}</div>
                          ) : (
                            <ErrorMessage
                              name="tipNeconformitateFisaRvRules"
                              render={(msg) => <div style={{ color: 'red', fontWeight: 'bold' }}>{msg}</div>}
                            />
                          )}
                        </Col>
                        <Col xs={12} md={6} className="mt-3">
                          {tipNeconformitate == '2' && (
                            <>
                              <Field
                                id="motiv"
                                name="motiv"
                                render={() => (
                                  <Input
                                    label="Motiv"
                                    mbZero
                                    value={motiv}
                                    onBlur={() => {
                                      props.setFieldTouched('motiv');
                                    }}
                                    onChange={(e: any) => {
                                      setMotiv(e.target.value);
                                    }}
                                  />
                                )}
                              />
                              {getFileError('utilizare_conforma_motiv') ? (
                                <div style={{ color: 'red', fontWeight: 'bold' }}>{getFileError('utilizare_conforma_motiv')}</div>
                              ) : (
                                <ErrorMessage
                                  name="motiv"
                                  render={(msg) => <div style={{ color: 'red', fontWeight: 'bold' }}>{msg}</div>}
                                />
                              )}
                            </>
                          )}
                        </Col>
                      </Row>
                    </Col>
                  )}
                </Row>

                <Row>
                  <Col xs={12} md={6} className="mt-3">
                    <strong>Lista de sarcini a suferit modificări</strong>
                    <div className="d-flex" style={{ flexDirection: 'row' }}>
                      <span
                        onClick={() => {
                          setListaModificata(true);
                          props.setFieldValue('listaModificata', true);
                        }}
                        className="me-2"
                      >
                        <input
                          className="custom-radio-button pointer"
                          type="radio"
                          value={listaModificata}
                          checked={listaModificata == true}
                          onChange={() => {
                            setListaModificata(true);
                            props.setFieldValue('listaModificata', true);
                          }}
                          style={{ marginRight: '6px' }}
                        />
                        <span className="fw-bold pointer">Da</span>
                      </span>
                      <span
                        onClick={() => {
                          setListaModificata(false);
                          props.setFieldValue('listaModificata', false);
                        }}
                      >
                        <input
                          className="custom-radio-button pointer"
                          type="radio"
                          value={listaModificata}
                          checked={listaModificata == false}
                          onChange={() => {
                            setListaModificata(false);
                            props.setFieldValue('listaModificata', false);
                          }}
                          style={{ marginRight: '6px' }}
                        />
                        <span className="fw-bold pointer">Nu</span>
                      </span>
                    </div>

                    {getFileError('lista_sarcini') ? (
                      <div style={{ color: 'red', fontWeight: 'bold' }}>{getFileError('lista_sarcini')}</div>
                    ) : (
                      <ErrorMessage
                        name="listaModificata"
                        render={(msg) => <div style={{ color: 'red', fontWeight: 'bold' }}>{msg}</div>}
                      />
                    )}
                  </Col>
                </Row>

                <Row className="mt-2 mb-0">
                  {listaModificata && (
                    <DynamicTable
                      format={dynamicTableFormat}
                      data={[]}
                      handler={(devices: any) => {
                        setDevices(devices);
                      }}
                      isEditing={true}
                      mbZero
                    />
                  )}

                  <ErrorMessage name="devices" render={(msg) => <div style={{ color: 'red', fontWeight: 'bold' }}>{msg}</div>} />
                </Row>

                <Row>
                  <Col xs={12} md={6} className="mt-3">
                    <strong>Sistare alimentare gaze</strong>
                    <div className="d-flex" style={{ flexDirection: 'row' }}>
                      <span
                        onClick={() => {
                          setSistareAlimentareCuGaze(true);
                          props.setFieldValue('sistareAlimentareCuGaze', true);
                        }}
                        className="me-2"
                      >
                        <input
                          className="custom-radio-button pointer"
                          type="radio"
                          value={sistareAlimentareCuGaze}
                          checked={sistareAlimentareCuGaze == true}
                          onChange={() => {
                            setSistareAlimentareCuGaze(true);
                            props.setFieldValue('sistareAlimentareCuGaze', true);
                          }}
                          style={{ marginRight: '6px' }}
                        />
                        <span className="fw-bold pointer">Da</span>
                      </span>
                      <span
                        onClick={() => {
                          setSistareAlimentareCuGaze(false);
                          props.setFieldValue('sistareAlimentareCuGaze', false);
                        }}
                      >
                        <input
                          className="custom-radio-button pointer"
                          type="radio"
                          value={sistareAlimentareCuGaze}
                          checked={sistareAlimentareCuGaze == false}
                          onChange={() => {
                            setSistareAlimentareCuGaze(false);
                            props.setFieldValue('sistareAlimentareCuGaze', false);
                          }}
                          style={{ marginRight: '6px' }}
                        />
                        <span className="fw-bold pointer">Nu</span>
                      </span>
                    </div>

                    {getFileError('sistare_gaze') ? (
                      <div style={{ color: 'red', fontWeight: 'bold' }}>{getFileError('sistare_gaze')}</div>
                    ) : (
                      <ErrorMessage
                        name="sistareAlimentareCuGaze"
                        render={(msg) => <div style={{ color: 'red', fontWeight: 'bold' }}>{msg}</div>}
                      />
                    )}
                  </Col>
                </Row>

                <Row>
                  <Col xs={12} md={6} className="mt-3">
                    <strong>Demontare contor</strong>
                    <div className="d-flex" style={{ flexDirection: 'row' }}>
                      <span
                        onClick={() => {
                          setDemontareContor(true);
                          props.setFieldValue('demontareContor', true);
                        }}
                        className="me-2"
                      >
                        <input
                          className="custom-radio-button pointer"
                          type="radio"
                          value={demontareContor}
                          checked={demontareContor == true}
                          onChange={() => {
                            setDemontareContor(true);
                            props.setFieldValue('demontareContor', true);
                          }}
                          style={{ marginRight: '6px' }}
                        />
                        <span className="fw-bold pointer">Da</span>
                      </span>
                      <span
                        onClick={() => {
                          setDemontareContor(false);
                          props.setFieldValue('demontareContor', false);
                        }}
                      >
                        <input
                          className="custom-radio-button pointer"
                          type="radio"
                          value={demontareContor}
                          checked={demontareContor == false}
                          onChange={() => {
                            setDemontareContor(false);
                            props.setFieldValue('demontareContor', false);
                          }}
                          style={{ marginRight: '6px' }}
                        />
                        <span className="fw-bold pointer">Nu</span>
                      </span>
                    </div>

                    {getFileError('demontare_contor') ? (
                      <div style={{ color: 'red', fontWeight: 'bold' }}>{getFileError('demontare_contor')}</div>
                    ) : (
                      <ErrorMessage
                        name="demontareContor"
                        render={(msg) => <div style={{ color: 'red', fontWeight: 'bold' }}>{msg}</div>}
                      />
                    )}
                  </Col>
                </Row>
                {demontareContor && (
                  <Row>
                    <Col xs={12} md={4} className="mt-3">
                      <Field
                        id="nrProcesVerbal"
                        name="nrProcesVerbal"
                        render={() => (
                          <Input
                            label="Nr. proces verbal"
                            mbZero
                            value={nrProcesVerbal}
                            onBlur={() => {
                              props.setFieldTouched('nrProcesVerbal');
                            }}
                            onChange={(e: any) => {
                              setNrProcesVerbal(e.target.value);
                            }}
                          />
                        )}
                      />
                      {getFileError('nr_proces_verbal') ? (
                        <div style={{ color: 'red', fontWeight: 'bold' }}>{getFileError('nr_proces_verbal')}</div>
                      ) : (
                        <ErrorMessage
                          name="nrProcesVerbal"
                          render={(msg) => <div style={{ color: 'red', fontWeight: 'bold' }}>{msg}</div>}
                        />
                      )}
                    </Col>
                    <Col xs={12} md={4} className="mt-3">
                      <Field
                        id="sigiliuVechi"
                        name="sigiliuVechi"
                        render={() => (
                          <Input
                            label="Sigiliu vechi"
                            mbZero
                            value={sigiliuVechi}
                            onBlur={() => {
                              props.setFieldTouched('sigiliuVechi');
                            }}
                            onChange={(e: any) => {
                              setSigiliuVechi(e.target.value);
                            }}
                          />
                        )}
                      />
                      {getFileError('sigiliu_vechi') ? (
                        <div style={{ color: 'red', fontWeight: 'bold' }}>{getFileError('sigiliu_vechi')}</div>
                      ) : (
                        <ErrorMessage name="sigiliuVechi" render={(msg) => <div style={{ color: 'red', fontWeight: 'bold' }}>{msg}</div>} />
                      )}
                    </Col>
                    <Col xs={12} md={4} className="mt-3">
                      <Field
                        id="sigiliuNou"
                        name="sigiliuNou"
                        render={() => (
                          <Input
                            label="Sigiliu nou"
                            mbZero
                            value={sigiliuNou}
                            onBlur={() => {
                              props.setFieldTouched('sigiliuNou');
                            }}
                            onChange={(e: any) => {
                              setSigiliuNou(e.target.value);
                            }}
                          />
                        )}
                      />

                      {getFileError('sigiliu_nou') ? (
                        <div style={{ color: 'red', fontWeight: 'bold' }}>{getFileError('sigiliu_nou')}</div>
                      ) : (
                        <ErrorMessage name="sigiliuNou" render={(msg) => <div style={{ color: 'red', fontWeight: 'bold' }}>{msg}</div>} />
                      )}
                    </Col>
                  </Row>
                )}

                <Row>
                  <Col xs={12} md={6} className="mb-2">
                    <p className="m-0">Incarcare fișier</p>
                    <UploadFile
                      onSetFileList={(list) => {
                        setFileList(list);
                      }}
                      onFileRemove={(item: any) => {
                        setFileList(fileList.filter((file) => file.name != item.name));
                      }}
                      fileList={fileList}
                      index={0}
                      uploadedFilesLimit={1}
                      acceptedExtensions={['.pdf']}
                    />

                    {getFileError('fileName') ? (
                      <div style={{ color: 'red', fontWeight: 'bold' }}>{getFileError('fileName')}</div>
                    ) : (
                      <ErrorMessage name="fileEmpty" render={(msg) => <div style={{ color: 'red', fontWeight: 'bold' }}>{msg}</div>} />
                    )}
                  </Col>
                </Row>

                <Row>
                  <div>
                    {error && <div style={{ color: 'red', fontWeight: 'bold' }}>{error}</div>}
                    <Button type={'submit'} className="btn-large mt-2 mb-2">
                      Trimite
                    </Button>
                  </div>
                </Row>
              </Form>
            </Fragment>
          );
        }}
      </Formik>
      <CustomModal showModal={showSuccessModal} modalTitle={'Succes!'} onClose={handleCloseModalSuccess}>
        <h5>Fișa a fost încărcată cu succes.</h5>
      </CustomModal>

      <CustomModal showModal={showSuccessWithCommunicationErrorModal} modalTitle={'Succes!'} onClose={handleCloseModalSuccess}>
        <h5>Fișa a fost încărcată cu succes însă a apărut o eroare de comunicare.</h5>
      </CustomModal>

      <CustomModal showModal={showSuccessWithStatusCanceledModal} modalTitle={'Succes!'} onClose={handleCloseModalSuccess}>
        <h5>Fișa a fost încărcată cu succes însă este cu status Anulata.</h5>
      </CustomModal>
    </Fragment>
  );
};

export default Formular;
