import React, { FunctionComponent } from 'react';
import { FigureItemKentico } from '../../../typescript/kentico-types';
import Figure from '@components/dev/Components/Figures/Figure';
import { DelgazWrapper } from '@components/global/DelgazWrapper';

export type FigureProps = { kentico: FigureItemKentico };

const FigureComponent: FunctionComponent<FigureProps> = (props) => {
  return (
    <DelgazWrapper>
      <Figure {...props} />
    </DelgazWrapper>
  );
};

export default FigureComponent;
