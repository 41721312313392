import * as types from '../constants/ActionTypes/PostTypes';

export function updateNotificationSummary(summary: any[]) {
    return {
        type: types.UPDATE_NOTIFICATIONS,
        payload: summary
    };
}

export function updateNotificationCount(itemCount: number) {
    return {
        type: types.UPDATE_NOTIFICATION_COUNT,
        payload: itemCount
    };
}

export function externalNotificationUpdate(count: number) {
    return {
        type: types.EXTERNAL_NOTIF_UPDATE,
        payload: count
    };
}