import { toggleLoader } from '@actions/LoaderActions';
import { deleteBulkImportEntry, getBulkImportDetails } from '@api/revizii/revizii';
import ChoiceModal from '@components/common/Modal/ChoiceModal';
import Pagination from '@components/common/Pagination/Pagination';
import { STATUS_ANULAT, STATUS_INVALID } from '@constants/revizii/Revizii';
import * as notification from '@lib/Notification';
import { Fragment, useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { getQueryParam } from '../../../helpers/urlUtils';

type RevisionType = {
  bulkImportId: number;
  bulkImportStatusnumber: number;
  bulkImportEntryId: number;
  clc: string;
  pod: string;
  county: string;
  locality: string;
  optionType: string;
  contactNumber: string;
  contactDate: string;
  sheetNumber: string;
  sheetRegisterDate: string;
  verifyDate: string;
  eraNumber: string;
  status: string;
  consumptionPoint: string;
  errors: {
    propertyName: string;
    errorMessage: string;
  }[];
  errorMessage: string;
};

const DetaliiIncarcareInMasa = () => {
  const dispatch = useDispatch();
  const [revisionList, setRevisionList] = useState<RevisionType[]>([]);
  const [RVToDeleteId, setRVToDeleteId] = useState<any>('');
  const [showDeleteRVModal, setShowDeleteRVModal] = useState<any>('');

  const [bulkId, setBulkId] = useState<any>('');

  //pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const paginate = (pageNumber: number) => setCurrentPage(pageNumber);
  const setElementsPerPage = (number: number) => setPerPage(number);
  const [totalItemsCount, setTotalItemsCount] = useState(0);

  const [error, setError] = useState('');

  useEffect(() => {
    const bulkIdParam = getQueryParam('bulkId');
    if (bulkIdParam) {
      setBulkId(bulkIdParam);
    }
  }, []);

  useEffect(() => {
    if (bulkId) {
      getBulkDetails();
    }
  }, [currentPage, perPage, bulkId]);

  const handleDeleteRV = async () => {
    setError('');
    dispatch(toggleLoader(true));
    deleteBulkImportEntry({ bulkImportEntryId: RVToDeleteId })
      .then(async (res) => {
        notification.success('Fișa a fost ștearsă cu succes.');
        await getBulkDetails();
        dispatch(toggleLoader(false));
      })
      .catch((err) => {
        if (err.status == 200) {
          notification.success('Fișa a fost ștearsă cu succes.');
          getBulkDetails();
          dispatch(toggleLoader(false));
        } else {
          setError('A apărut o eroare. Vă rugăm să reveniți mai tărziu.');
        }
        dispatch(toggleLoader(false));
      });
  };

  const getBulkDetails = async () => {
    dispatch(toggleLoader(true));

    let payload = {
      pageNumber: currentPage,
      pageSize: perPage,
      bulkImportId: bulkId
    };

    getBulkImportDetails(payload)
      .then((res) => {
        setRevisionList(res.items);
        setTotalItemsCount(res.metadata.totalItemsCount);
        dispatch(toggleLoader(false));
      })
      .catch((err) => {
        setError('A apărut o eroare. Vă rugăm să reveniți mai tărziu.');
        dispatch(toggleLoader(false));
      });
  };

  return (
    <Fragment>
      <Button className="mb-1" onClick={() => (window.location.href = '/istoric-incarcari-fise-in-masa')}>
        Înapoi
      </Button>
      {revisionList.length > 0 && (
        <div className="general-table-container">
          <table>
            <thead>
              <tr>
                <th>Identificator loc Consum</th>
                <th>Județ</th>
                <th>Localitate</th>
                <th>Tip lucrare</th>
                <th>Număr fișă</th>
                <th>Dată înregistrare fișă</th>
                <th>Data efectuării reviziei/verificării</th>
                <th>Nr. Inregistrare</th>
                <th>Status</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {revisionList.map((item: RevisionType, index: number) => (
                <tr key={index}>
                  <td>{item.consumptionPoint}</td>
                  <td>{item.county}</td>
                  <td>{item.locality}</td>
                  <td>{item.optionType}</td>
                  <td>{item.sheetNumber}</td>
                  <td>{item.sheetRegisterDate ? item.sheetRegisterDate : '-'}</td>
                  <td>{item.verifyDate ? item.verifyDate : '-'}</td>
                  <td>{item.eraNumber ? item.eraNumber : '-'}</td>
                  <td>
                    {item.status}
                    {(item.status == STATUS_INVALID || item.status == STATUS_ANULAT) && item.errorMessage && (
                      <div className="p-gray" style={{ fontSize: '12px' }}>
                        {item.errorMessage}
                      </div>
                    )}
                  </td>
                  <td>
                    <div className="align-items-center d-flex">
                      {item.status == 'Partial valid' && (
                        <>
                          <Button
                            onClick={() => {
                              setRVToDeleteId(item.bulkImportEntryId);
                              setShowDeleteRVModal(true);
                            }}
                            className="ms-2 btn-wide"
                          >
                            Șterge
                          </Button>
                          <Button
                            onClick={() => {
                              window.location.href = `/incarcare-fisa-noua?bulkImportEntryId=${item.bulkImportEntryId}&bulkId=${bulkId}`;
                            }}
                            className="ms-2 btn-wide"
                          >
                            Editează
                          </Button>
                        </>
                      )}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      {error && <div style={{ color: 'red', fontWeight: 'bold' }}>{error}</div>}

      <Pagination
        currentPage={currentPage}
        totalElements={totalItemsCount}
        perPage={perPage}
        paginate={paginate}
        setElementsPerPage={setElementsPerPage}
      />

      <ChoiceModal
        showModal={showDeleteRVModal}
        modalTitle={'Sunteți sigur că doriți să ștergeți fișa?'}
        options={[
          { id: 'DA', title: 'Da' },
          { id: 'NU', title: 'Nu' }
        ]}
        onClose={(option: any) => {
          if (option.id == 'DA') {
            handleDeleteRV();
            setShowDeleteRVModal(false);
          } else if (option.id == 'NU') {
            setShowDeleteRVModal(false);
          }
        }}
        onHide={() => {
          setShowDeleteRVModal(false);
        }}
        btnWide
      />
    </Fragment>
  );
};

export default DetaliiIncarcareInMasa;
