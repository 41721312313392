import React from 'react';
import { DelgazWrapper } from '@components/global/DelgazWrapper';
import LoginPopup from '@components/loginPopup/LoginPopup';

export const Popup = (): JSX.Element => {
    return (
        <DelgazWrapper>
            <LoginPopup />
        </DelgazWrapper>
    );
};