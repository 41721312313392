import { toggleLoader } from '@actions/LoaderActions';
import { getRequestsList, getSubjectListFurnizor } from '@api/rss/rss';
import RequestList from '@components/rss/requests/RequestList';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

const RSSFurnizor = () => {
    const [list, setList] = useState<any[]>([]);
    const [totalItems, setTotalItems] = useState(0);
    const [pageIndex, setPageIndex] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [summary, setSummary] = useState(null);
    const [filterStatus, setFilterStatus] = useState<number | null>(null);
    const [searchText, setSearchText] = useState<string>('');
    const [sortColumn, setSortColumn] = useState('ID');
    const [sortAscending, setSortAscending] = useState(false);
    //eslint-disable-next-line
    const [categories, setCategories] = useState<any[]>([]);

    const dispatch = useDispatch();

    useEffect(() => {
        mountValues();
    }, []);

    useEffect(() => {
        getList({ sortBy: sortColumn, sortType: sortAscending ? 'asc' : 'desc', pageNumber: 1, pageSize: pageSize, text: searchText, status: filterStatus });
    }, [filterStatus, sortColumn, sortAscending]);

    useEffect(() => {
        if (searchText === '') {
            getList({ sortBy: sortColumn, sortType: sortAscending ? 'asc' : 'desc', pageNumber: 1, pageSize: pageSize, text: searchText, status: filterStatus });
        }
    }, [searchText]);

    const onSort = (column: string) => {
        setSortColumn(column);
        if (sortColumn == column) {
            setSortAscending(!sortAscending);
        } else {
            setSortAscending(true);
        }
    };

    const getList = async (payload: any) => {
        dispatch(toggleLoader(true));

        await getRequestsList(payload).then((res) => {
            if (res && res.data && res.data.items) {
                setList(res.data.items);
                setTotalItems(res.data.metadata.totalItemsCount);
                setPageIndex(res.data.metadata.pageIndex);
                setPageSize(res.data.metadata.pageSize);
                setSummary(res.data.summary);
            }
        }).catch((err) => console.log(err));

        dispatch(toggleLoader(false));
    };

    const handleFilterStatus = (status: number) => {
        if (status !== filterStatus) {
            setFilterStatus(status);
        } else {
            setFilterStatus(null);
        }
    };

    const handleSearchText = (text: string) => {
        setSearchText(text);
    };

    const handleSortColumn = (column: string) => {
        setSortColumn(column);
    };

    const handleSortAscending = (type: boolean) => {
        setSortAscending(type);
    };

    const getValues = (data: any) => {
        let arr: any[] = [];

        data.map((item: any) => {
            Object.keys(item).map((k) => {
                if (k === 'category') {
                    let already = false;

                    for (let i = 0; i < arr.length; ++i) {
                        if (arr[i].category === item[k]) {
                            already = true;
                        }
                    }

                    if (!already) {
                        arr.push({ category: item[k], categorySapValue: item.categorySapValue, subjects: [] });
                    }
                }
            });
        });

        arr.map((item: any) => {
            data.map((i: any) => {
                Object.keys(i).map((k) => {
                    if (k === 'subject') {
                        let already = false;

                        for (let j = 0; j <= item.subjects.length; ++j) {
                            if (item.subjects[j] && item.subjects[j].subject && item.subjects[j].subject === i[k]) {
                                already = true;
                            }
                        }

                        if (!already && (item.category === i['category'])) {
                            item.subjects.push({ subject: i[k], subjectSapValue: i['subjectSapValue'], subSubjects: [] });
                        }
                    }
                });
            });
        });

        arr.map((item: any) => {
            item.subjects.map((subject: any) => {
                data.map((i: any) => {
                    Object.keys(i).map((k) => {
                        if (k === 'subSubject') {
                            let already = false;

                            for (let j = 0; j <= subject.subSubjects.length; ++j) {
                                if (subject.subSubjects[j] && subject.subSubjects[j].subSubject && subject.subSubjects[j].subSubject === i[k]) {
                                    already = true;
                                }
                            }

                            if (!already && (subject.subject === i['subject']) && (item.category === i['category'])) {
                                subject.subSubjects.push({ subSubject: i[k], subSubjectSapValue: i['subSubjectSapValue'] });
                            }
                        }
                    });
                });
            });
        });

        setCategories(arr);
    };

    const mountValues = async () => {
        dispatch(toggleLoader(true));

        await getSubjectListFurnizor().then((res) => {
            getValues(res.data);
        });

        dispatch(toggleLoader(false));
    };

    return (
        <div className="d-flex justify-content-center flex-column m-3">
            <RequestList
                        list={list}
                        totalItems={totalItems}
                        pageIndex={pageIndex}
                        pageSize={pageSize}
                        getList={getList}
                        summary={summary}
                        filterStatus={filterStatus}
                        setFilterStatus={handleFilterStatus}
                        searchText={searchText}
                        handleSearchText={handleSearchText}
                        sortColumn={sortColumn}
                        sortAscending={sortAscending}
                        onSort={onSort}
                        handleSortColumn={handleSortColumn}
                        handleSortAscending={handleSortAscending}
                    />
            {(list.length === 0) && <p className="align-self-center">Nu există solicitări in acest moment.</p>}
            {/* { state === 2 &&
                <Edit
                    item={currentElement}
                    handleSetState={handleState}
                    handleCurrentElement={handleCurrentElement}
                    getList={getList}
                    wasSent={wasSent}
                    handleWasSent={handleWasSent}
                    pageIndex={pageIndex}
                    pageSize={pageSize}
                    filterStatus={filterStatus}
                    searchText={searchText}
                    sortColumn={sortColumn}
                    sortAscending={sortAscending}
                    categories={categories}
                    getDetails={getDetails}
                />
            } */}
        </div>
    );
};

export default RSSFurnizor;