import { Multiselect } from '@components/common/Multiselect';
import React, { useState, useEffect, useRef } from 'react';
import { DropdownOptionType } from '@components/common/Dropdown';
import { FormField, ValueToSet } from '../PageWrapper';
import { Item } from '../FiltersForm';
import { OptionsForField } from './DropdownString';
import { MultiselectCheckBox } from '@components/common/MultiselectCheckBox';

type MultiSelectDropdownIntProps = {
  item: Item | FormField;
  options?: DropdownOptionType[];
  name?: string;
  label?: string;
  value?: string | number | string[] | any;
  itemTypeId: number;
  isUsedInFilters?: boolean;
  displaySearch?: boolean;
  onChange?: (e: ValueToSet) => void;
  valueWrapper?: (value: string | number) => number | string;
  renderChild?: (field: FormField, index: number, groupNumberOfColumnsPerRow: number, parent?: any) => void;
  renderChildFilter?: (filter: Item, parent?: any) => void;
  values?: any;
  groupNumberOfColumnsPerRow?: number;
  optionsForField?: OptionsForField[];
  parent?: Item | FormField;
  colspanValue?: number;
  filters?: any;
  hideLabel?: boolean;
  mbZero?: boolean;
  toggled?: boolean;
};

const MultiSelectDropdownInt = ({ onChange, itemTypeId, value, item, name, hideLabel, mbZero, toggled }: MultiSelectDropdownIntProps) => {
  const [dropdownOptions, setDropdownOptions] = useState<DropdownOptionType[]>([]);
  const [initialSelectedOptions, setInitialSelectedOptions] = useState<any>(null);
  const multiselectRef = useRef();

  useEffect(() => {
    if (toggled && !item.itemsConfiguration?.showAsRadioButtons) {
      setTimeout(() => {
        //@ts-ignore
        multiselectRef.current?.toggleOptionList();
      }, 50);
    }
  }, [toggled]);

  useEffect(() => {
    if (value && dropdownOptions) {
      const valueArray = JSON.parse(value);
      setInitialSelectedOptions(
        dropdownOptions.filter((dropdownOption: DropdownOptionType) => {
          return valueArray.includes(dropdownOption.id);
        })
      );
    } else {
      setInitialSelectedOptions([]);
    }
  }, [value, dropdownOptions]);

  useEffect(() => {
    setDropdownOptions(getStaticOptions(item));
  }, [item]);

  const getStaticOptions = (filterItem: Item | any) => {
    let options;
    const keyFieldName: string =
      filterItem?.itemsConfiguration?.keyFieldName?.charAt(0).toLowerCase() + filterItem?.itemsConfiguration?.keyFieldName?.slice(1);
    const valueFieldName: string =
      filterItem?.itemsConfiguration?.valueFieldName?.charAt(0).toLowerCase() + filterItem?.itemsConfiguration?.valueFieldName?.slice(1);
    options = filterItem.items
      ? filterItem.items.map((item: any) => {
          return {
            id: item[keyFieldName],
            name: item[valueFieldName],
            disabled: false
          };
        })
      : [];
    return options;
  };

  return (
    <>
      {item.itemsConfiguration?.showAsRadioButtons ? (
        <MultiselectCheckBox
          options={dropdownOptions}
          initialSelectedOptions={initialSelectedOptions}
          onChange={(selectedOptions: any) => {
            const selectedOptionsMaped = selectedOptions.map((so: any) => so.id);
            const selectedOptionsString = JSON.stringify(selectedOptionsMaped);
            onChange && onChange({ name, value: selectedOptionsString, itemTypeId } as ValueToSet);
          }}
          label={item.displayName}
          name={item.name}
        />
      ) : (
        <Multiselect
          ref={multiselectRef}
          options={dropdownOptions}
          initialSelectedOptions={initialSelectedOptions}
          onChange={(selectedOptions: any) => {
            const selectedOptionsMaped = selectedOptions.map((so: any) => so.id);
            const selectedOptionsString = JSON.stringify(selectedOptionsMaped);
            onChange && onChange({ name, value: selectedOptionsString, itemTypeId } as ValueToSet);
          }}
          label={hideLabel ? '' : item.displayName}
          name={item.name}
          mbZero={mbZero}
        />
      )}
    </>
  );
};

export default MultiSelectDropdownInt;
