import React, {useState, useEffect} from 'react';
import RequestGrid from './RequestGrid';
import { getConventionList } from '@api/conventions/conventions';
import { toggleLoader } from '@actions/LoaderActions';
import { useDispatch } from 'react-redux';

const Conventions = () => {
    const [list, setList] = useState<any[]>([]);
    const [totalItems, setTotalItems] = useState(0);
    const [pageIndex, setPageIndex] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [filterStatus, setFilterStatus] = useState<number | null>(null);
    const [searchText, setSearchText] = useState<string>('');
    const [sortColumn, setSortColumn] = useState('RequestId');
    const [sortAscending, setSortAscending] = useState(false);
    const [summary, setSummary] = useState(null);

    const dispatch = useDispatch();

    useEffect(() => {
        getList({ sortBy: sortColumn, sortType: sortAscending ? 'asc' : 'desc', pageNumber: 1, pageSize: pageSize, text: searchText, status: filterStatus });
    }, [filterStatus, sortColumn, sortAscending]);

    useEffect(() => {
        if (searchText === '') {
            getList({ sortBy: sortColumn, sortType: sortAscending ? 'asc' : 'desc', pageNumber: 1, pageSize: pageSize, text: searchText, status: filterStatus });
        }
    }, [searchText]);

    const getList = async (payload: any) => {
        dispatch(toggleLoader(true));

        await getConventionList(payload).then((res) => {
            if (res && res.data && res.data.items) {
                setList(res.data.items);
                setTotalItems(res.data.metadata.totalItemsCount);
                setPageIndex(res.data.metadata.pageIndex);
                setPageSize(res.data.metadata.pageSize);
                setSummary(res.data.summary);
            }
        }).catch((err) => {});

        dispatch(toggleLoader(false));
    };

    const onSort = (column: string) => {
        setSortColumn(column);
        if (sortColumn == column) {
            setSortAscending(!sortAscending);
        } else {
            setSortAscending(true);
        }
    };

    const handleFilterStatus = (status: number) => {
        if (status !== filterStatus) {
            setFilterStatus(status);
        } else {
            setFilterStatus(null);
        }
    };

    const handleSearchText = (text: string) => {
        setSearchText(text);
    };

    const handleSortColumn = (column: string) => {
        setSortColumn(column);
    };

    const handleSortAscending = (type: boolean) => {
        setSortAscending(type);
    };

    return (
        <div>
            <RequestGrid 
                list={list}
                totalItems={totalItems}
                pageIndex={pageIndex}
                pageSize={pageSize}
                getList={getList}
                filterStatus={filterStatus}
                setFilterStatus={handleFilterStatus}
                searchText={searchText}
                handleSearchText={handleSearchText}
                sortColumn={sortColumn}
                sortAscending={sortAscending}
                onSort={onSort}
                handleSortColumn={handleSortColumn}
                handleSortAscending={handleSortAscending}
                summary={summary}
            />
            {(list.length === 0) && <p className="text-center">Nu există convenții in acest moment.</p>}
        </div>
    );
};

export default Conventions;