export const FETCH_POST_REQUEST = 'FETCH_POST_REQUEST';
export const FETCH_POST_SUCCESS = 'FETCH_POST_SUCCESS';
export const FETCH_POST_FAILURE = 'FETCH_POST_FAILURE';


/* Used for options */
export const SET_OPTIONS = 'SET_OPTIONS';
export const CLEANUP_OPTIONS = 'CLEANUP_OPTIONS';


/* Used for authentication */
export const AUTH_USER = 'AUTH_USER';
export const CLEANUP_AUTH = 'CLEANUP_AUTH';

/* Used in loader */
export const TOGGLE_LOADING_SCREEN = 'TOGGLE_LOADING_SCREEN';
export const SET_CONTENT_LOADING_SCREEN = 'SET_CONTENT_LOADING_SCREEN';

/* Used for settings */
export const SET_SETTINGS = 'SET_SETTINGS';
export const CLEANUP_SETTINGS = 'CLEANUP_SETTINGS';
export const FETCH_USER_STATE = 'FETCH_USER_STATE';

/* Used for counties */
export const SET_COUNTIES = 'SET_COUNTIES';
export const CLEANUP_COUNTIES = 'CLEANUP_TOASTR';

/* Used for localities */
export const SET_LOCALITIES = 'SET_LOCALITIES';
export const CLEANUP_LOCALITIES = 'CLEANUP_LOCALITIES';
export const FETCH_SUCCESS = 'FETCH_SUCCESS';

/* Used for putere calorifica */
export const SEARCH_PUTERE_CALORIFICA = 'SEARCH_PUTERE_CALORIFICA';
export const CLEANUP_PUTERE_CALORIFICA = 'CLEANUP_PUTERE_CALORIFICA';

/* Used for rss */
export const FETCH_CATEGORIES_AND_SUBJECTS = 'FETCH_CATEGORIES_AND_SUBJECTS';
export const CLEANUP_CATEGORIES_AND_SUBJECTS = 'CLEANUP_CATEGORIES_AND_SUBJECTS';

/* Used for backoffice section */
export const SET_BO_OPTIONS = 'SET_BO_OPTIONS';
export const CLEAN_BO_OPTIONS = 'CLEAN_BO_OPTIONS';

export const SET_RESPONSE_HEADERS = 'SET_RESPONSE_HEADERS';
export const CLEAN_RESPONSE_HEADERS = 'CLEAN_RESPONSE_HEADERS';

/* Used for cleaning old deviceId */
export const CLEANUP_DEVICE = 'CLEANUP_DEVICE';

/* Used for updating notification summary */
export const UPDATE_NOTIFICATIONS = 'UPDATE_NOTIFICATIONS';
export const UPDATE_NOTIFICATION_COUNT = 'UPDATE_NOTIFICATION_COUNT';
export const EXTERNAL_NOTIF_UPDATE = 'EXTERNAL_NOTIF_UPDATE';