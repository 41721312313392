import React from 'react';

import { DelgazWrapper } from '@components/global/DelgazWrapper';
import AgreementPVC from '@components/delgaz/AcordPVC/AgreementPVC';

export type AgreementPVAEComponentProps = {
  kentico: any;
};

export const AgreementPVCComponent = (props: AgreementPVAEComponentProps): JSX.Element => {
  return (
    <DelgazWrapper>
      <AgreementPVC {...props} />
    </DelgazWrapper>
  );
};
