import { toggleLoader } from '@actions/LoaderActions';
import { accountDetailsToken, createAccount } from '@api/user/user';
import ModalConfirm from '@components/common/ModalConfirm/ModalConfirm';
import { ROLE_CLIENT_FIZIC, ROLE_CLIENT_JURIDIC, ROLE_OPERATOR_ECONOMIC, ROLE_PARTENER_VALORI_MASURATE } from '@constants/Roles/Roles';
import { IState } from '@type/store';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const ActivateAccount = () => {
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState('');
  const [activateExecuted, setActivateExecuted] = useState(false);
  const [stopLoader, setStopLoader] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [modalTitle, setModalTitle] = useState('');
  const old_site: string | undefined = useSelector<IState>((state) => state.options.oldSite) as string;
  const dispatch = useDispatch();

  useEffect(() => {
    if (stopLoader) {
      dispatch(toggleLoader(false));
    }
  }, [stopLoader]);

  const setErrorMessage = (errorDesc: any) => {
    switch (errorDesc) {
      case 'UnknownError':
        return 'A aparut o eroare.';
      case 'ExistingUser':
        return 'Utilizator existent.';
      case 'InvalidToken':
        return 'Link-ul este invalid. Contul dumneavoastră nu a putut fi activat.';
      default:
        return 'A aparut o eroare.';
    }
  };
  const setErrorMessageForInregistrareSync = (errorDesc: any) => {
    switch (errorDesc) {
      case 'email':
        return 'Adresa de Email este deja folosită.';
      default:
        return errorDesc;
    }
  };

  const activate = async () => {
    const url = new URL(window.location.href);
    const params = url.search;

    dispatch(toggleLoader(true));

    // call cu informatii din token
    await accountDetailsToken({ value: params.substring(7, params.length) }).then((res: any) => {
      if (res.isInvalid === false && res.user) {
        let data = res.user;
        data = { ...data, emailConfirmation: data.email };
        data = { ...data, passwordConfirmation: data.password };
        data = { ...data, licence: Number(data.licence) };

        if (
          res.user.role == ROLE_PARTENER_VALORI_MASURATE ||
          res.user.role == ROLE_OPERATOR_ECONOMIC ||
          res.user.role == ROLE_CLIENT_FIZIC ||
          res.user.role == ROLE_CLIENT_JURIDIC
        ) {
          //se creaza cont doar pe site nou
          createAccount({ value: params.substring(7, params.length), userId: null })
            .then((response: any) => {
              if (response.success === true) {
                setSuccess(true);
                setStopLoader(true);
              } else if (response.success === false) {
                setError(setErrorMessage(res.errors[0].errorDescription));
                setStopLoader(true);
              }
            })
            .catch((e: any) => {
              setError('A intervenit o eroare tehnică. Vă rugăm reveniți mai târziu.');
              setStopLoader(true);
            });
        } else {
          //creare cont site nou si site vechi
          axios
            .post(`${old_site}/inregistrare-sync`, data)
            .then((r: any) => {
              let resData: any;
              if (r && r.data && r.data.data) {
                resData = r.data.data;
              } else {
                resData = r.data;
              }

              if (resData.id) {
                data = { ...data, id: resData.id };
                data = { ...data, legacyId: resData.id };

                // aici call nou de create account cu token + userId de la vechi
                createAccount({ value: params.substring(7, params.length), userId: resData.id })
                  .then((response: any) => {
                    if (response.success === true) {
                      setSuccess(true);
                      setStopLoader(true);
                      if (response.jwt.access_token) {
                        axios
                          .get(`${old_site}/activare-cont-sync/${response.jwt.access_token}`)
                          .then((resp: any) => {})
                          .catch((e: any) => {});
                      }
                    } else if (response.success === false) {
                      setError(setErrorMessage(res.errors[0].errorDescription));
                      setStopLoader(true);
                    }
                  })
                  .catch((e: any) => {
                    //se sterge userul creat la apelul "inregistrare-sync" (pe vechi)
                    //@ts-ignore
                    data = { ...data, token: resData.token ?? '' };
                    axios
                      .post(`${old_site}/remove-user`, data)
                      .then((r: any) => {
                        setError('Contul dumneavoastră nu a putut fi activat! Vă rugăm să reluați procesul de creare cont.');
                        setStopLoader(true);
                      })
                      .catch((e: any) => {
                        setError('Contul dumneavoastră nu a putut fi activat! Vă rugăm să reluați procesul de creare cont.');
                        setStopLoader(true);
                      });
                  });
              } else if (r && r.data && r.data.errors) {
                setError(setErrorMessageForInregistrareSync(r.data.errors[0]));
                setStopLoader(true);
              }
            })
            .catch((e: any) => {
              setError('A intervenit o eroare tehnică. Vă rugăm reveniți mai târziu.');
              setStopLoader(true);
            });
        }
      } else {
        setError('Link-ul de activare este greșit sau a expirat!');
        setStopLoader(true);
      }
    });
  };

  useEffect(() => {
    if (old_site && !activateExecuted) {
      setActivateExecuted(true);
      activate();
    }
  }, [old_site]);

  useEffect(() => {
    if (success) {
      setModalMessage('Contul dumneavoastră a fost activat cu succes.');
      setModalTitle('Mulțumim!');
      setShowModal(true);
    }
  }, [success]);

  //eslint-disable-next-line
  const renderSuccess = () => {
    return (
      <div className="m-3">
        <h3 style={{ color: '#ea1c0a' }}>Mulțumim!</h3>
        <p>Contul dumneavoastră a fost activat cu succes.</p>
      </div>
    );
  };

  const renderError = () => {
    if (!error && !success) {
      return (
        <div className="m-3">
          <p style={{ color: '#ea1c0a', fontWeight: 'bold' }}>A intervenit o eroare tehnică. Vă rugăm reveniți mai târziu.</p>
        </div>
      );
    }
    return (
      <div className="m-3">
        <p style={{ color: '#ea1c0a', fontWeight: 'bold' }}>{error}</p>
      </div>
    );
  };

  const handleCloseModal = () => {
    setShowModal(false);
    window.location.href = '/login';
  };

  return (
    <div>
      {/* {success && renderSuccess()} */}
      {error !== '' && renderError()}
      <ModalConfirm modalTitle={modalTitle} showModal={showModal} onClose={handleCloseModal}>
        <h5>{modalMessage}</h5>
      </ModalConfirm>
    </div>
  );
};

export default ActivateAccount;
