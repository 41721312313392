import React, { useEffect, useState } from 'react';
import { deleteRequest, getRequestsList } from '@api/prosumatori/prosumatori';
import { deleteRequestGN, getRequestsListGN } from '@api/iConnect/iConnectGaz';
import { deleteRequestEE } from '@api/iConnect/iConnectEE';
import { toggleLoader } from '@actions/LoaderActions';
import { useDispatch } from 'react-redux';
import { Tabs, Tab, Row } from 'react-bootstrap';
import ShowRequests from '@components/prosumatori/components/requestsList/ShowRequests';
import { getTypes } from '@api/prosumatori/prosumatori';
import { setQueryParam } from '../../helpers/urlUtils';

const Cereri = () => {
    const [EEList, setEEList] = useState<any[]>([]);
    const [GNList, setGNList] = useState<any[]>([]);

    const [searchText, setSearchText] = useState('');
    const [searchStatus, setSearchStatus] = useState('');
    const [searchType, setSearchType] = useState('');
    const [types, setTypes] = useState<any[]>([]);
    const [isForProsumer, setIsForProsumer] = useState<boolean>(false);
    const [requestStatuses, setRequestStatuses] = useState<any[]>([]);
    const [wasTabChanged, setWasTabChanged] = useState<boolean>(false);

    const [error, setError] = useState('');

    const handleSearchText = (text: string) => {
        setSearchText(text);
    };

    const handleSearchStatus = (status: string) => {
        setSearchStatus(status);
    };

    const handleSearchType = (type: string) => {
        types.forEach((item: any, index: number) => {
            if (item.id === type) {
                setIsForProsumer(item.isForProsumer);
            }
        });

        setSearchType(type);
    };

    const dispatch = useDispatch();

    const getList = async (requestType: string, client: string, status: string, type: string) => {
        setError('');

        dispatch(toggleLoader(true));

        let payload = [
            `requestType=${requestType}`,
            client !== '' ? `client=${client}` : '',
            status !== '' ? `processStatusTypeId=${status}` : '',
            type !== '' ? `tip=${type}` : '',
            isForProsumer !== undefined && `isForProsumer=${isForProsumer}`
        ];

        if (requestType === 'EE') {
            await getRequestsList(payload)
                .then((response: any) => {
                    if (response) {
                        let elements = [...response];
                        elements.sort((a: any, b: any) => a.requestId < b.requestId ? 1 : -1);
                        setEEList(elements);
                    }
                })
                .catch((error) => {
                    if (error && error.StatusCode === 500) {
                        setError('A intervenit o eroare tehnică. Vă rugăm reveniți mai târziu.');
                    }
                });
        } else if (requestType === 'GN') {
            await getRequestsListGN(payload)
                .then((response: any) => {
                    if (response) {
                        let elements = [...response];
                        elements.sort((a: any, b: any) => a.requestId < b.requestId ? 1 : -1);
                        setGNList(elements);
                    }
                })
                .catch((error) => {
                    if (error && error.StatusCode === 500) {
                        setError('A intervenit o eroare tehnică. Vă rugăm reveniți mai târziu.');
                    }
                });
        }

        dispatch(toggleLoader(false));
    };

    const setDefaultActiveTab = () => {
        const url = new URL(window.location.href);

        const params = url.search;
        const searchParams = new URLSearchParams(params);

        if (searchParams.has('tab')) {
            if (searchParams.get('tab') === 'GN' || searchParams.get('tab') === 'gn') {
                return 2;
            }
        }
        return 1;
    };

    useEffect(() => {
        getList(setDefaultActiveTab() === 1 ? 'EE' : 'GN', '', '', '');
        getTypes(setDefaultActiveTab() === 1 ? ['GetGridConnectionTypesEE'] : ['ConnectingPipeType']).then((res) => {
            let data = setDefaultActiveTab() === 1 ? res.GetGridConnectionTypesEE : res.ConnectingPipeType;

            data.map((item: any) => {
                item.id = item.id + '';
            });

            setTypes(data);
        });
        getTypes(['ProcessStatusFO']).then((res) => {
            let data = res.ProcessStatusFO;

            data.map((item: any) => {
                item.id = item.id + '';
            });

            setRequestStatuses(data);
        });
    }, []);

    //eslint-disable-next-line
    const getOptionTypes = async (keys: string[], utility?: string) => {
        return await getTypes(keys, utility).then((res: any) => {
            return res[keys[0]];
        });
    };

    const handleSelect = (e: any) => {
        setWasTabChanged(true);
        setSearchText('');
        setSearchStatus('');
        setSearchType('');
        setQueryParam('tab', e == 1 ? 'EE' : 'GN');

        if (e === '1') {
            getList('EE', '', '', '');
            getTypes(['GetGridConnectionTypesEE'], 'EE').then((res) => {
                console.log(res);
                let data = res.GetGridConnectionTypesEE;
    
                data.map((item: any) => {
                    item.id = item.id + '';
                });
    
                setTypes(data);
            });
        } else if (e === '2') {
            getList('GN', '', '', '');
            getTypes(['ConnectingPipeType'], 'GN').then((res) => {
                console.log(res);
                let data = res.ConnectingPipeType;
    
                data.map((item: any) => {
                    item.id = item.id + '';
                });
    
                setTypes(data);
            });
        }
    };

    const deleteEntry = async (requestId: number, utility: string, requestType?: string) => {
        dispatch(toggleLoader(true));

        if (utility === 'EE') {
            if (requestType === 'Casnic' || requestType === 'Noncasnic') {
                await deleteRequestEE({ requestId })
                .then((res) => console.log(res))
                .catch((err) => {
                    if (err && err.status === 200) {
                        getList('EE', '', '', '');
                    }
                });
            } else {
                await deleteRequest({ requestId })
                .then((res) => console.log(res))
                .catch((err) => {
                    if (err && err.status === 200) {
                        getList('EE', '', '', '');
                    }
                });
            }
        } else if (utility === 'GN') {
            await deleteRequestGN({ requestId })
                .then((res) => console.log(res))
                .catch((err) => {
                    if (err && err.status === 200) {
                        getList('GN', '', '', '');
                    }
                });
        }


        dispatch(toggleLoader(false));
    };

    return (
        <div className="m-3">
            {/* <ShowRequests
                    list={EEList}
                    delete={deleteEntry}
                    get={getList}
                    searchText={searchText}
                    searchStatus={searchStatus}
                    searchType={searchType}
                    handleSearchText={handleSearchText}
                    handleSearchStatus={handleSearchStatus}
                    handleSearchType={handleSearchType}
                    requestStatuses={requestStatuses}
                    requestTypes={types}
                    error={error}
                /> */}
            {error !== '' && <Row className="m-3 text-center"><p>{error}</p></Row>}
            <Tabs defaultActiveKey={setDefaultActiveTab()} className="my-account-tabs" mountOnEnter={true} unmountOnExit={true} onSelect={handleSelect}>
                <Tab eventKey={1} title="Energie Electrică" mountOnEnter={true} unmountOnExit={true}>
                    {
                        <ShowRequests
                            list={EEList}
                            delete={deleteEntry}
                            get={getList}
                            searchText={searchText}
                            searchStatus={searchStatus}
                            searchType={searchType}
                            handleSearchText={handleSearchText}
                            handleSearchStatus={handleSearchStatus}
                            handleSearchType={handleSearchType}
                            requestStatuses={requestStatuses}
                            requestTypes={types}
                            error={error}
                            utility={'EE'}
                            wasTabChanged={wasTabChanged}
                        />
                    }
                    {/* {(EEList.length === 0 && error === '') && <Row className="m-3 text-center"><p>Momentan nu există cereri pentru această utilitate.</p></Row>} */}
                    {error !== '' && <Row className="m-3 text-center"><p>{error}</p></Row>}
                </Tab>
                <Tab eventKey={2} title="Gaze Naturale" mountOnEnter={true} unmountOnExit={true}>
                    {
                        <ShowRequests
                            list={GNList}
                            delete={deleteEntry}
                            get={getList}
                            searchText={searchText}
                            searchStatus={searchStatus}
                            searchType={searchType}
                            handleSearchText={handleSearchText}
                            handleSearchStatus={handleSearchStatus}
                            handleSearchType={handleSearchType}
                            requestStatuses={requestStatuses}
                            requestTypes={types}
                            error={error}
                            utility={'GN'}
                            wasTabChanged={wasTabChanged}
                        />
                    }
                    {/* {(GNList.length === 0 && error === '') && <Row className="m-3 text-center"><p>Momentan nu există cereri pentru această utilitate.</p></Row>} */}
                    {error !== '' && <Row className="m-3 text-center"><p>{error}</p></Row>}
                </Tab>
            </Tabs>
        </div>
    );
};

export default Cereri;