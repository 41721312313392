import React from 'react';
import { DelgazWrapper } from '@components/global/DelgazWrapper';
import CerereProsumatori from '@components/prosumatori/CerereProsumatori';

export const Cerere = (): JSX.Element => {
    return (
        <DelgazWrapper>
            <CerereProsumatori />
        </DelgazWrapper>
    );
};