import { toggleLoader } from '@actions/LoaderActions';
import { reqLogin } from '@actions/user/user';
import { getCaptcha } from '@api/captcha';
import { login } from '@api/user/user';
import { Input } from '@components/common/Input';
import {
  IDENTITY_ROLE_CLIENT_FIZIC,
  IDENTITY_ROLE_CLIENT_JURIDIC,
  IDENTITY_ROLE_OPERATOR_ECONOMIC,
  IDENTITY_ROLE_PARTENER_VALORI_MASURATE
} from '@constants/Roles/RolesIdentity';
import { faArrowRotateRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IState } from '@type/store';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { jwtDecode } from 'jwt-decode';
import { Fragment, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import { useDispatch, useSelector } from 'react-redux';
import { LoginSchema } from '../../../helpers/schemas';

const LoginFormComponent = (props: any) => {
  const [errorMessage, setErrorMessage] = useState('');
  const [failCount, setFailCount] = useState(0);
  const [wasCaptchaLoaded, setWasCaptchaLoaded] = useState(false);
  const [captchaError, setCaptchaError] = useState('');
  // const [wasLocked, setWasLocked] = useState(false);
  const [createAccountFormType, setCreateAccountFormType] = useState('');
  const [acord, setAcord] = useState(false);
  const [errorCreateAccountAcord, setErrorCreateAccountAcord] = useState('');
  const [errorCreateAccountEmptyFormType, setErrorCreateAccountAcordEmptyFormType] = useState('');

  //captcha
  const [captchaImage, setCaptchaImage] = useState<string | null>(null);
  const [captcha, setCaptcha] = useState<string>('');
  const [instanceId, setInstanceId] = useState<string>('');

  const deviceId: string | undefined = useSelector<IState>((state) => state?.device?.deviceId) as string;
  const OLD_SITE_URL: string | undefined = useSelector<IState>((state) => state.options.webApiHost) as string;
  const redirect_url: string | undefined = useSelector<IState>((state) => state.options.oldSite) as string;
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      setErrorMessage('');
    };
  }, []);

  useEffect(() => {
    if (acord) {
      setErrorCreateAccountAcord('');
    }
  }, [acord]);

  useEffect(() => {
    if (createAccountFormType) {
      setErrorCreateAccountAcordEmptyFormType('');
    }
  }, [createAccountFormType]);

  useEffect(() => {
    if (failCount >= 3) {
      setWasCaptchaLoaded(true);
    }
  }, [failCount]);

  useEffect(() => {
    if (wasCaptchaLoaded) {
      apiGetCaptcha();
    }
  }, [wasCaptchaLoaded]);

  const apiGetCaptcha = async () => {
    dispatch(toggleLoader(true));

    await getCaptcha().then((response) => {
      setCaptchaImage(response.image);
      setInstanceId(response.imageInstanceId);
    });

    dispatch(toggleLoader(false));
  };

  const submit = async (values: any, validateCaptcha: boolean) => {
    if (validateCaptcha && !captcha) {
      setCaptchaError('Codul de securitate este obligatoriu.');
      return;
    }

    dispatch(toggleLoader(true));

    let data: any = {
      deviceId: deviceId,
      userEmail: values.email.trim(),
      password: values.password
    };

    if (validateCaptcha) {
      data = {
        ...data,
        captchaText: captcha,
        captchaInstance: instanceId
      };
    }

    await login(data)
      .then((response: any) => {
        if (response && !response.success) {
          if (response.errorCode === 9) {
            window.location.href = `${OLD_SITE_URL}/resetare-parola`;
          } else {
            setErrorMessage(getErrorMessage(response.errorCode));
            setFailCount((prev) => prev + 1);

            if (validateCaptcha) {
              apiGetCaptcha();
              setCaptcha('');
              if (response.errorCode == 12) {
                setCaptchaError('Codul de securitate nu este valid.');
                setErrorMessage('');
              } else {
                setCaptchaError('');
              }
            }
          }
        }

        if (
          response &&
          !response.changePasswordRequired &&
          !response.otpRequired &&
          response.success &&
          response.jwt &&
          response.jwt.access_token
        ) {
          dispatch(reqLogin(values.email, response.jwt.access_token));
          document.cookie = 'token=' + response.jwt.access_token;

          //redirect pe vechi doar pentru rol diferit de PARTENER_VALORI_MASURATE
          const token = response.jwt.access_token;
          const decoded = jwtDecode(token);
          //@ts-ignore
          const roles = decoded?.groups || [];
          if (typeof localStorage !== 'undefined') {
            if (localStorage.getItem('ReturnUrl')) {
              localStorage.setItem('ReturnUrlActive', 'true');
            }
          }
          if (
            roles.includes(IDENTITY_ROLE_PARTENER_VALORI_MASURATE) ||
            roles.includes(IDENTITY_ROLE_CLIENT_FIZIC) ||
            roles.includes(IDENTITY_ROLE_CLIENT_JURIDIC)
          ) {
            window.location.href = '/solicitari';
          } else if (roles.includes(IDENTITY_ROLE_OPERATOR_ECONOMIC)) {
            window.location.href = '/solicitari-operatori-economici';
          } else {
            window.location.href = `${redirect_url}/login?token=${response.jwt.access_token}`;
          }
        } else {
          if (response.success && response.changePasswordRequired) {
            props.setTId(response.otpTransactionId);
            props.setUserEmail(data.userEmail);
            props.setUserPassword(data.password);
            props.switchStates('change_password');
          } else if (response.success && response.otpRequired) {
            props.setTId(response.otpTransactionId);
            props.setUserEmail(data.userEmail);
            props.setUserPassword(data.password);
            props.switchStates('validate_otp');
          }
        }
      })
      .catch(function (error: any) {
        if (error.response) {
          console.log(error.response);
        } else if (error.request) {
          console.log(error.request);
        } else {
          setErrorMessage('A intervenit o eroare tehnică. Vă rugăm reveniți mai târziu.');
        }
      });

    dispatch(toggleLoader(false));
  };

  const getErrorMessage = (errorCode: number) => {
    switch (errorCode) {
      case 1:
        return 'E-mail greșit.';
      case 2:
        return 'Email și/sau parolă greșită.';
      case 5:
        return 'OTP-ul este invalid.';
      case 10:
        return 'Contul tău este blocat pentru 15 minute în urma a 5 tentative eșuate de logare în Portalul Delgaz. Te rugăm să accesezi linkul primit pe mail pentru deblocarea contului tău.';
      default:
        return 'A apărut o eroare. Vă rugăm încercați mai tărziu.';
    }
  };

  const hanleRedirectToCreateAccount = () => {
    if (!acord) {
      setErrorCreateAccountAcord('Pentru a continua trebuie să acceptați termenii și condițiile.');
    } else {
      setErrorCreateAccountAcord('');
    }
    if (!createAccountFormType) {
      setErrorCreateAccountAcordEmptyFormType('Vă rugăm să selectați o opțiune.');
    } else {
      setErrorCreateAccountAcordEmptyFormType('');
    }

    if (acord && createAccountFormType) {
      window.location.href = `${OLD_SITE_URL}/creare-cont?formType=${createAccountFormType}`;
    }
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}>
      <div className="login-form-container with-border">
        <div className="d-flex justify-content-center">
          <h3 className="mb-4 red">Accesează contul tău</h3>
        </div>
        <Fragment>
          <Formik
            initialValues={{ email: '', password: '' }}
            validationSchema={LoginSchema}
            validateOnChange={false}
            onSubmit={(values) => {
              submit(values, failCount >= 3);
            }}
          >
            {(props) => (
              <Fragment>
                <Form>
                  <div className="form-container" style={{ display: 'flex', marginTop: '5rem' }}>
                    <div className="mb-4">
                      <Field
                        label="E-mail"
                        id="email"
                        name="email"
                        placeholder="E-mail"
                        render={() => (
                          <Input
                            label="E-mail"
                            mbZero
                            onBlur={() => {
                              props.setFieldTouched('email');
                            }}
                            onChange={(e: any) => {
                              e.target.value.length !== 0 && props.setFieldValue('email', e.target.value);
                              e.target.value.length === 0 && props.setFieldValue('email', '');
                            }}
                          />
                        )}
                      />
                      <ErrorMessage name="email" render={(msg) => <div style={{ color: 'red', fontWeight: 'bold' }}>{msg}</div>} />
                    </div>
                    <div className="mb-2">
                      <Field
                        label="Parola"
                        id="password"
                        name="password"
                        placeholder="Parola"
                        render={() => (
                          <Input
                            label="Parola"
                            mbZero
                            type="password"
                            autoComplete="new-password"
                            onBlur={() => {
                              props.setFieldTouched('password');
                            }}
                            onChange={(e: any) => {
                              e.target.value.length !== 0 && props.setFieldValue('password', e.target.value);
                              e.target.value.length === 0 && props.setFieldValue('password', '');
                            }}
                          />
                        )}
                      />
                      <ErrorMessage name="password" render={(msg) => <div style={{ color: 'red', fontWeight: 'bold' }}>{msg}</div>} />
                      {errorMessage !== '' && <div style={{ color: 'red', fontWeight: 'bold' }}>{errorMessage}</div>}
                    </div>

                    {wasCaptchaLoaded && (
                      <>
                        <Row className="mt-3 mb-3">
                          <Col xs={12} className="flex-row">
                            {captchaImage !== null && <img src={`data:image/jpeg;base64, ${captchaImage}`} alt="captcha" />}
                            <FontAwesomeIcon
                              icon={faArrowRotateRight}
                              color="red"
                              className="mt-3 pointer"
                              style={{ width: '20px', height: '20px', marginLeft: '8px' }}
                              onClick={() => apiGetCaptcha()}
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={12}>
                            <Input
                              id="user_captcha_input"
                              name="user_captcha_input"
                              type="text"
                              label="Cod securitate"
                              value={captcha}
                              onChange={(e) => setCaptcha(e.target.value)}
                              mbZero
                              className="mb-1"
                            />
                          </Col>
                          {captchaError !== '' && <div style={{ color: 'red', fontWeight: 'bold' }}>{captchaError}</div>}
                        </Row>
                      </>
                    )}

                    <div
                      className="buttons-container d-flex justify-content-end"
                      style={{ marginBottom: `${wasCaptchaLoaded ? '1rem' : '6rem'}` }}
                    >
                      <a className="forgot-password-text" href={`${OLD_SITE_URL}/resetare-parola`}>
                        Ai uitat parola?
                      </a>
                    </div>

                    <hr className="" />
                    <div className="buttons-container d-flex justify-content-end">
                      <Button className="mb-2 btn-large" type="submit" onClick={() => setErrorMessage('')}>
                        Login
                      </Button>
                    </div>
                  </div>
                </Form>
              </Fragment>
            )}
          </Formik>
        </Fragment>
      </div>

      <div className="login-form-container with-border">
        <div className="d-flex justify-content-center">
          <h3 className="mb-4 red">Creează cont nou</h3>
        </div>
        <Fragment>
          <div className="form-container" style={{ marginTop: '1rem' }}>
            <div>
              <div className="section-red-border" />
              <h5 className="m-0 mb-3">Tip utilizator</h5>

              <div className="m-2 d-flex justify-content-between" style={{ flexDirection: 'column' }}>
                <span className="mb-2 ms-1" onClick={() => setCreateAccountFormType('cpf')}>
                  <input
                    className="custom-radio-button pointer"
                    type="radio"
                    value={1}
                    checked={createAccountFormType == 'cpf'}
                    onChange={() => setCreateAccountFormType('cpf')}
                    style={{ marginRight: '6px' }}
                  />
                  <span className="fw-bold pointer">Client persoană fizică</span>
                </span>

                <span className="mb-2 ms-1" onClick={() => setCreateAccountFormType('cpj')}>
                  <input
                    className="custom-radio-button pointer"
                    type="radio"
                    value={1}
                    checked={createAccountFormType == 'cpj'}
                    onChange={() => setCreateAccountFormType('cpj')}
                    style={{ marginRight: '6px' }}
                  />
                  <span className="fw-bold pointer">Client persoană juridică</span>
                </span>

                <span className="mb-2 ms-1" onClick={() => setCreateAccountFormType('pvm')}>
                  <input
                    className="custom-radio-button pointer"
                    type="radio"
                    value={1}
                    checked={createAccountFormType == 'pvm'}
                    onChange={() => setCreateAccountFormType('pvm')}
                    style={{ marginRight: '6px' }}
                  />
                  <span className="fw-bold pointer">Partener valori măsurate</span>
                </span>

                <span className="mb-2 ms-1" onClick={() => setCreateAccountFormType('fgn')}>
                  <input
                    className="custom-radio-button pointer"
                    type="radio"
                    value={1}
                    checked={createAccountFormType == 'fgn'}
                    onChange={() => setCreateAccountFormType('fgn')}
                    style={{ marginRight: '6px' }}
                  />
                  <span className="fw-bold pointer">Furnizor Gaze Naturale</span>
                </span>

                <span className="mb-2 ms-1" onClick={() => setCreateAccountFormType('fen')}>
                  <input
                    className="custom-radio-button pointer"
                    type="radio"
                    value={1}
                    checked={createAccountFormType == 'fen'}
                    onChange={() => setCreateAccountFormType('fen')}
                    style={{ marginRight: '6px' }}
                  />
                  <span className="fw-bold pointer">Furnizor Energie Electrică</span>
                </span>

                <span className="mb-1 ms-1" onClick={() => setCreateAccountFormType('oevr')}>
                  <input
                    className="custom-radio-button pointer"
                    type="radio"
                    value={1}
                    checked={createAccountFormType == 'oevr'}
                    onChange={() => setCreateAccountFormType('oevr')}
                    style={{ marginRight: '6px' }}
                  />
                  <span className="fw-bold pointer">Operator Economic Verificări/Revizii</span>
                </span>
                {errorCreateAccountEmptyFormType && (
                  <div style={{ color: 'red', fontWeight: 'bold' }}>{errorCreateAccountEmptyFormType}</div>
                )}
              </div>

              <div onClick={() => setAcord(!acord)} className="checkbox-container pointer" style={{ marginTop: '3rem' }}>
                <input className="create-account-checkbox" type="checkbox" checked={acord} onChange={() => setAcord(!acord)} />
                <span className="fw-bold checkbox-span" style={{ marginLeft: '5px' }}>
                  Sunt de acord cu{' '}
                  <a href={`${OLD_SITE_URL}/conditii-de-utilizare`} target="_blank" className="red download-link-text" rel="noreferrer">
                    termenii și condițiile.
                  </a>
                </span>
              </div>
              {errorCreateAccountAcord && <div style={{ color: 'red', fontWeight: 'bold' }}>{errorCreateAccountAcord}</div>}
            </div>

            <div>
              <hr className="" />
              <div className="buttons-container d-flex justify-content-end">
                <Button className="mb-2 btn-large" type="submit" onClick={hanleRedirectToCreateAccount}>
                  Creează cont
                </Button>
              </div>
            </div>
          </div>
        </Fragment>
      </div>
    </div>
  );
};

export default LoginFormComponent;
