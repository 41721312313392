import { faX } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ToLowerCase } from '@lib/Utils';
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import DropdownOption from '../Dropdown/DropdownOption';
import useWindowDimensions from '../../../helpers/useWindowDimensions';

type DropdownOptionType = {
  id: string | number;
  name: string;
  disabled?: boolean;
  extraPropertyName?: string;
  extraPropertyValue?: string | number;
};

type MultiselectProps = {
  options: DropdownOptionType[];
  disabled?: boolean;
  height?: number;
  placeholder?: string;
  name?: string;
  label?: string;
  onChange: (option: DropdownOptionType[]) => void;
  error?: string;
  mbZero?: boolean;
  initialSelectedOptions?: DropdownOptionType[];
};

const Multiselect = forwardRef(({ options, disabled, height, placeholder, onChange, ...props }: MultiselectProps, ref) => {
  const [selectedOptions, setSelectedOptions] = useState<DropdownOptionType[]>([]);
  const [search, setSearch] = useState<string>('');
  const [collapsed, setCollapsed] = useState<boolean>(true);
  //eslint-disable-next-line
  const [invalid, setInvalid] = useState<boolean>(false);

  const dropdownButtonRef = useRef<HTMLDivElement>(null);
  const searchInputRef = useRef<HTMLInputElement>(null);
  const selectedOptionsRef = useRef<HTMLDivElement>(null);
  const delgazuiOptionListRef = useRef<HTMLDivElement>(null);
  const { width } = useWindowDimensions();

  useImperativeHandle(ref, () => ({
    toggleOptionList
  }));

  useEffect(() => {
    if (props.initialSelectedOptions) {
      setSelectedOptions(props.initialSelectedOptions);
    }
  }, [props.initialSelectedOptions]);

  useEffect(() => {
    document.addEventListener('click', handleOutsideClick);
    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, []);

  useEffect(() => {
    adjustHeight();
  }, [selectedOptions, width]);

  const handleSelect = (option: DropdownOptionType) => {
    let updatedOptions;
    if (!selectedOptions.find((selected) => selected.id === option.id)) {
      updatedOptions = [...selectedOptions, option];
    } else {
      updatedOptions = selectedOptions.filter((selected) => selected.id !== option.id);
    }
    setSelectedOptions(updatedOptions);
    onChange(updatedOptions);
  };

  const handleRemoveOption = (option: DropdownOptionType) => {
    const updatedOptions = selectedOptions.filter((selected) => selected.id !== option.id);
    setSelectedOptions(updatedOptions);
    onChange(updatedOptions);
  };

  const toggleOptionList = () => {
    if (disabled) return;
    setSearch('');
    setCollapsed(!collapsed);
    setTimeout(() => {
      searchInputRef.current?.focus();
    }, 100);
  };

  const handleOutsideClick = (event: MouseEvent) => {
    if (dropdownButtonRef.current && !dropdownButtonRef.current.contains(event.target as Node)) {
      setCollapsed(true);
    }
  };

  const adjustHeight = () => {
    if (selectedOptionsRef.current && delgazuiOptionListRef.current) {
      const contentHeight = selectedOptionsRef.current.scrollHeight;
      delgazuiOptionListRef.current.style.top = `${Math.max(48, contentHeight) + 6}px`;
    }
  };

  const renderNonTouchWithSearch = () => {
    if (!options) return;
    let filteredOptions = options.filter((option) => option.id !== '-1');
    filteredOptions = filteredOptions.filter((option) => ToLowerCase(option.name).includes(ToLowerCase(search)));

    return (
      <div className={`multiselect-dropdown delgazui-dropdown-field-group field-group ${props.mbZero ? 'mb-0' : ''}`} id="drop-down-delgaz">
        <div className={'delgazui-dropdown-wrapper delgazui-touched'}>
          <div
            role="button"
            tabIndex={0}
            onClick={toggleOptionList}
            ref={dropdownButtonRef}
            className={`delgazui-dropdown-button ${disabled ? 'delgazui-disabled' : ''} ${invalid ? 'delgazui-invalid' : ''} ${
              collapsed ? 'delgazui-collapsed' : ''
            }`}
          >
            <div className="delgazui-option-label-wrapper" ref={selectedOptionsRef}>
              {selectedOptions.map((option) => (
                <span key={option.id} className="selected-option">
                  {option.name}
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      handleRemoveOption(option);
                    }}
                    className="remove-option"
                  >
                    <FontAwesomeIcon icon={faX} size="xs" />
                  </button>
                </span>
              ))}
            </div>
            <div className="delgazui-icon-background">
              <svg className="delgazui-icon" width="16" height="32" viewBox="0 0 16 32" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M0 12a1 1 0 011.828-.561C4.046 14.712 6.12 17.212 8 18.875c1.88-1.665 3.956-4.165 6.172-7.436a1 1 0 011.656 1.122c-2.45 3.615-4.666 6.248-6.777 8.05-.605.52-1.5.52-2.104-.001-2.11-1.801-4.326-4.434-6.776-8.05a.998.998 0 01-.164-.44L0 12z"
                  fillRule="evenodd"
                />
              </svg>
            </div>

            <div
              ref={delgazuiOptionListRef}
              className={`delgazui-option-list search-list ${collapsed ? 'delgazui-collapsed' : ''}`}
              style={{ height: height }}
            >
              <div className="drp-search-cont" onClick={(event) => event.stopPropagation()}>
                <div className="search-box">
                  <div className="search-input">
                    <input
                      ref={searchInputRef}
                      type="search"
                      value={search as string}
                      onChange={(e) => {
                        setSearch(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <p>
                  <b>{placeholder}</b>
                </p>
              </div>
              <div className="scroll-content">
                {filteredOptions.map((option, i) => (
                  <DropdownOption
                    key={`${option.id}-${i}`}
                    value={option.id}
                    label={option.name}
                    disabled={!!option.disabled}
                    selected={!!selectedOptions.find((op) => op.name === option.name)}
                    onClick={(event) => {
                      event.preventDefault();
                      if (option.disabled) {
                        return event.stopPropagation();
                      } else {
                        event.stopPropagation();
                        handleSelect(option);
                      }
                    }}
                  />
                ))}
              </div>
            </div>
          </div>
          <label htmlFor={props.name}>{props.label}</label>

          {props.error && <span className="label-error">{props.error}</span>}
        </div>
      </div>
    );
  };

  return <>{renderNonTouchWithSearch()}</>;
});

export default Multiselect;
