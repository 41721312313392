import { proxy, Proxy } from '@api/azureProxy';

import { geoBaseUrl } from '@constants/ApiUrl';
import { CountyTemplate, LocalityTemplate } from '@type/geo';


export async function GetCounties(utility?: string) {
  const endpoint = utility ? (utility === 'EE' ? `${geoBaseUrl}counties?utility=EE` : `${geoBaseUrl}counties?utility=GN` ) : `${geoBaseUrl}counties/`;
  
  const proxyData: Proxy = {
    method: 'GET',
    endPoint: endpoint,
    withAuthBearer: false
  };

  return proxy<CountyTemplate[]>(proxyData);
}

export async function GetLocalities(countyCode: string) {
  const proxyData: Proxy = {
    method: 'GET',
    endPoint: `${geoBaseUrl}localities_by_county/?countyCode=${countyCode}`,
    withAuthBearer: false
  };

  return proxy<LocalityTemplate[]>(proxyData);
}

export async function GetStreets(localityId: string) {
  const proxyData: Proxy = {
    method: 'GET',
    endPoint: `${geoBaseUrl}streets_by_locality/?localityId=${localityId}`,
    withAuthBearer: false
  };

  return proxy<LocalityTemplate[]>(proxyData);
}


