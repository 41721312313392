import Boxes from '@components/dev/Components/Carousel/Boxes';
import Item from '@components/dev/Components/Carousel/Item';
import { AuthenticationContext, AuthenticationContextType } from '@contexts/authentication-context';
import { useContext, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import Slider from 'react-slick';
import { LoginIcons, SliderKentico } from '../../../typescript/kentico-types';
import { IState } from '../../../typescript/store';

export const CarouselSlider = (props: { kentico: SliderKentico }) => {
    //eslint-disable-next-line
    const value: AuthenticationContextType = useContext(AuthenticationContext);
    const kentico = props.kentico;
    const icons: LoginIcons | null = kentico.loginicons;
    const [isMobile, setIsMobile] = useState(false);
    const loggedInUser: string | undefined = useSelector<IState>((state) => state.auth.token) as string;

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 10000,
    };

    const handleResize = () => {
        if (window.innerWidth < 768) {
            setIsMobile(true);
        } else {
            setIsMobile(false);
        }
    };

    useEffect(() => {
        handleResize();
    }, []);

    useEffect(() => {
        window.addEventListener('resize', handleResize);
    });

    useEffect(() => {
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    });

    return (
        <>
            <div className="delgaz-carousel slider-container mb-3 position-relative">
                {!loggedInUser &&
                <div className="login-box position-absolute">
                    {/* <div className="login-box-content d-md-flex flex-column justify-content-around h-100"> */}
                        {/* <h4 className="brand-subtitle text-center">Contul meu online</h4>
                        <a href={`${OLD_SITE_URL}/login`} className="btn btn-sm btn-primary" type="button">
                            Accesează contul
                        </a>
                        <p style={{margin: '0 auto'}}>sau</p>
                        <p id="register" style={{margin: '0 auto'}}><a href={`${OLD_SITE_URL}/creare-cont`} className="link red">Creează un cont nou</a></p> */}
                    {/* </div> */}
                    {icons && !isMobile && <Boxes icons={icons} isMobile={false} loggedInUser={loggedInUser}/>}
                </div>
                }
                <Row>
                    <Col md={12}>
                        <Slider {...settings}>
                            {kentico.items.map((cardItem: any, key: number) => {
                                return (<Item
                                    key={key}
                                    title={cardItem.title}
                                    description={cardItem.description}
                                    linkText={cardItem.linkText ?? 'Read full aritcle'}
                                    linkUrl={cardItem.linkUrl ?? null}
                                    img={cardItem.image}
                                    textColor={cardItem.textColor ?? null}
                                />);
                            })}
                        </Slider>
                    </Col>
                </Row >
            </div>
            {(isMobile || loggedInUser) && <Row className='boxes'>
                <Boxes icons={icons} isMobile={true} loggedInUser={loggedInUser}/>
            </Row> }
        </>
    );
};

