export const DISPLAY_MODE_NONE = { id: 0, name: 'None' };
export const DISPLAY_MODE_DOWNLOAD_LINK = { id: 1, name: 'DonwnloadLink' };
export const DISPLAY_MODE_DETAILS_AND_EXPORT = { id: 2, name: 'DetailsAndExport' };
export const DISPLAY_MODE_DETAILS = { id: 3, name: 'Details' };

export const REPORT_DATE_RANGE_TYPE_NONE = { id: 0, name: 'None' };
export const REPORT_DATE_RANGE_TYPE_DATE_RANGE = { id: 1, name: 'DateRange' };
export const REPORT_DATE_RANGE_TYPE_CURRENT_OR_PREVIOUS_MONTH = { id: 2, name: 'CurrentOrPreviousMonth' };
export const REPORT_DATE_RANGE_TYPE_PREVIOUS_MONTH = { id: 3, name: 'PreviousMonth' };
export const REPORT_DATE_RANGE_TYPE_MONTH_RANGE = { id: 4, name: 'MonthRange' };

export const CONSUMPTION_POINTS_TYPE_NONE = { id: 0, name: 'None' };
export const CONSUMPTION_POINTS_TYPE_ALL = { id: 1, name: 'All' };
export const CONSUMPTION_POINTS_TYPE_BY_METTER_TYPES = { id: 2, name: 'ByMetterTypes' };
