import * as types from '../../constants/ActionTypes/PostTypes';

export function reqLogin(email: string, token: string) {
    return {
        type: types.AUTH_USER,
        payload: {
            email: email || null,
            // password: password || null,
            token: token || null,
        }
    };
}

export function logout() {
    return {
        type: types.CLEANUP_AUTH
    };
}