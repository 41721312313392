import { toggleLoader } from '@actions/LoaderActions';
import { postBulkImport } from '@api/revizii/revizii';
import { UploadFile } from '@components/common/UploadFile';
import { File } from '@components/common/UploadFile/UploadFile';
import { Fragment, useEffect, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { getQueryParam, removeQueryParam, setQueryParam } from '../../../helpers/urlUtils';
import Confirmare from './Confirmare';

const IncarcareInMasa = () => {
  const [fileListFise, setFileListFise] = useState<File[]>([] as File[]);
  const [fileListRV, setFileListRV] = useState<File[]>([] as File[]);
  const [RVUploadFileType, setRVUploadFileType] = useState('');
  const [step, setStep] = useState(1);

  const [error, setError] = useState('');
  const [errorFileListFise, setErrorFileListFise] = useState<any>('');
  const [errorfileListRV, setErrorfileListRV] = useState<any>('');

  const [bulkId, setBulkId] = useState<any>('');

  const dispatch = useDispatch();

  useEffect(() => {
    const bulkIdParam = getQueryParam('bulkId');
    if (bulkIdParam) {
      setBulkId(bulkIdParam);
      setStep(2);
    }
  }, []);

  useEffect(() => {
    if (fileListFise.length > 0) {
      setErrorFileListFise('');
    }
  }, [fileListFise]);

  useEffect(() => {
    if (fileListRV.length > 0) {
      setErrorfileListRV('');
    }
  }, [fileListRV]);

  useEffect(() => {
    setFileListRV([]);
    setError('');
    setErrorfileListRV('');
  }, [RVUploadFileType]);

  const handleFileListFiseChange = (list: File[]) => {
    setFileListFise(list);
    setError('');
  };

  const handleFileListRVChange = (list: File[]) => {
    setFileListRV(list);
    setError('');
  };

  const submit = () => {
    let valid = true;
    if (fileListFise.length == 0) {
      setErrorFileListFise('Câmp obligatoriu');
      valid = false;
    }
    if (fileListRV.length == 0) {
      setErrorfileListRV('Câmp obligatoriu');
      valid = false;
    }
    if (!valid) return;

    dispatch(toggleLoader(true));

    let formData = new FormData();
    if (fileListFise.length > 0) {
      fileListFise.map((file) => {
        formData.append('ExcelFile', file as Blob);
      });
    }

    if (RVUploadFileType == 'PDF') {
      fileListRV.forEach((fileRV, index) => {
        formData.append('PDFFiles', fileRV as Blob);
      });
    } else {
      formData.append('ZipFile', fileListRV[0] as Blob);
    }

    postBulkImport(formData)
      .then((res) => {
        setBulkId(res.data);
        setQueryParam('bulkId', res.data);
        setStep(2);
      })
      .catch((err) => {
        if (err?.response?.data && err.response.data == 'Excelul nu contine date') {
          setError('Fișierul incărcat nu conține date.');
        } else {
          setError('A apărut o eroare. Vă rugăm să reveniți mai tărziu.');
        }
        dispatch(toggleLoader(false));
      });
  };

  const resetComponentState = () => {
    setStep(1);
    removeQueryParam('bulkId');
    setBulkId('');
    setFileListFise([]);
    setFileListRV([]);
    setRVUploadFileType('');
    setError('');
  };

  const handleOnClickOnDisabledUploader = () => {
    setErrorfileListRV('Vă rugăm să selectați tipul fișierului');
  };

  return (
    <Fragment>
      {step == 1 && (
        <Fragment>
          <div className="m-3">
            {/* <h5>Template</h5>
            <a href="/download-template" className="link link--download download-link red pointer hover-red">
              Descarcă
            </a> */}

            <Row>
              <Col className="mt-3" xs={12} md={6}>
                <div className="justify-content-between d-flex">
                  <span>Listă fișe (XLS, XLSX)</span>
                </div>
                <UploadFile
                  onSetFileList={handleFileListFiseChange}
                  fileList={fileListFise}
                  index={0}
                  uploadedFilesLimit={1}
                  acceptedExtensions={['.xls', '.xlsx']}
                  fileSizeLimit={50}
                />

                <div className="justify-content-between d-flex">
                  <div>
                    {errorFileListFise && (
                      <div className="mb-3" style={{ color: 'red', fontWeight: 'bold' }}>
                        {errorFileListFise}
                      </div>
                    )}
                  </div>

                  <span className={'p-gray'}>Marime maxima fisier: 50 MB</span>
                </div>
              </Col>

              <Col className="mt-3" xs={12} md={6}>
                <div className="justify-content-between d-flex">
                  <span>Încarcă fișe R/V (ZIP) sau PDF</span>

                  <span className={`${errorfileListRV && errorfileListRV == 'Vă rugăm să selectați tipul fișierului' ? 'red' : ''}`}>
                    <span onClick={() => setRVUploadFileType('ZIP')} className="me-2">
                      <input
                        className="custom-radio-button pointer"
                        type="radio"
                        value={1}
                        checked={RVUploadFileType === 'ZIP'}
                        onChange={() => setRVUploadFileType('ZIP')}
                        style={{ marginRight: '6px' }}
                      />
                      <span className="fw-bold pointer">Arhivă zip</span>
                    </span>

                    <span onClick={() => setRVUploadFileType('PDF')}>
                      <input
                        className="custom-radio-button pointer"
                        type="radio"
                        value={1}
                        checked={RVUploadFileType === 'PDF'}
                        onChange={() => setRVUploadFileType('PDF')}
                        style={{ marginRight: '6px' }}
                      />
                      <span className="fw-bold pointer">Fișiere pdf</span>
                    </span>
                  </span>
                </div>
                <UploadFile
                  onSetFileList={handleFileListRVChange}
                  fileList={fileListRV}
                  index={0}
                  uploadedFilesLimit={RVUploadFileType == 'ZIP' ? 1 : 100}
                  acceptedExtensions={RVUploadFileType == 'ZIP' ? ['.zip'] : ['.pdf']}
                  disabled={!RVUploadFileType}
                  fileSizeLimit={50}
                  onClick={() => {
                    if (!RVUploadFileType) {
                      handleOnClickOnDisabledUploader();
                    }
                  }}
                />
                <div className="justify-content-between d-flex">
                  <div>
                    {errorfileListRV && (
                      <div className="mb-3" style={{ color: 'red', fontWeight: 'bold' }}>
                        {errorfileListRV}
                      </div>
                    )}
                  </div>

                  <span className={'p-gray'}>Marime maxima fisier: 50 MB</span>
                </div>
              </Col>
            </Row>

            {error && <div style={{ color: 'red', fontWeight: 'bold' }}>{error}</div>}

            <Button className="btn-large" onClick={submit}>
              Trimite
            </Button>
          </div>
        </Fragment>
      )}

      {step == 2 && <Confirmare bulkId={bulkId} resetComponentState={resetComponentState} />}
    </Fragment>
  );
};

export default IncarcareInMasa;
