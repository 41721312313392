import React, { useState, useEffect, Fragment } from 'react';
import UploadFile, { File } from '@components/common/UploadFile/UploadFile';
import { useDispatch } from 'react-redux';
import { getQueryParam } from '../../../helpers/urlUtils';
import { isObjectEmpty } from '../../../helpers/object';
import Confirmare from './components/Confirmare';
import { Button, Col, Row } from 'react-bootstrap';
import { CustomModal } from '@components/common/ModalConfirm';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { UTILITY_EE } from '@constants/Utility';
import { postBulkImportUpload } from '@api/decrec/decrec';
import { toggleLoader } from '@actions/LoaderActions';
import { PostBulkImportUploadResponseLocConsum, PostBulkImportUploadResponseType } from '@type/decrec';

const DecRecIncarcareInMasa = () => {
  const [utility, setUtility] = useState<any>(0);

  const [fileList, setFileList] = useState<File[]>([] as File[]);

  const [step, setStep] = useState(1);
  //eslint-disable-next-line
  const [type, setType] = useState<string | any>('');

  const [error, setError] = useState('');
  const [fatalError, setFatalError] = useState('');
  const [showModalError, setShowModalError] = useState(false);
  //eslint-disable-next-line
  const [modalErrorMessage, setModalErrorMessage] = useState('');

  const [submited, setSubmited] = useState(false);
  const [errors, setErrors] = useState<{ [key: string]: string }>({});

  const [cpList, setCpList] = useState<PostBulkImportUploadResponseLocConsum[]>([]);
  const [bulkImportId, setBulkImportId] = useState('');

  //eslint-disable-next-line
  const dispatch = useDispatch();

  useEffect(() => {
    const type = getQueryParam('type');
    setType(type);

    const utility = getQueryParam('utility');
    if (utility) {
      setUtility(utility);
    }

    if (!utility || !type) {
      setFatalError('A apărut o eroare tehnică. Vă rugăm să accesați pagina din secțiunea principală.');
    }
  }, []);

  useEffect(() => {
    submited && validateValues();
  }, [submited, fileList]);

  const handleFileListChange = (list: File[]) => {
    setFileList(list);
    setError('');
  };

  const validateValues = () => {
    let errors: { [key: string]: string } = {};

    if (fileList.length == 0) {
      errors['fileList'] = 'Câmp obligatoriu!';
    }

    setErrors(errors);
    return isObjectEmpty(errors);
  };

  const submit = () => {
    setSubmited(true);
    if (!validateValues()) return;

    dispatch(toggleLoader(true));

    let formData = new FormData();
    formData.append('ExcelFile', fileList[0] as Blob);
    formData.append('RequestType', type);
    formData.append('Utility', utility);

    postBulkImportUpload(formData)
      .then((res: PostBulkImportUploadResponseType | any) => {
        if (res.status != 200) {
          setModalErrorMessage('A apărut o eroare. Vă rugăm să reveniți mai tărziu.');
          setShowModalError(true);
        } else if (res.data.errorMessage) {
          setModalErrorMessage(res.data.errorMessage);
          setShowModalError(true);
        } else {
          setBulkImportId(res.data.bulkImportId);
          setCpList(res.data.locuriDeConsum);
          setStep(2);
        }
        dispatch(toggleLoader(false));
      })
      .catch((err) => {
        setModalErrorMessage('A apărut o eroare. Vă rugăm să reveniți mai tărziu.');
        setShowModalError(true);
        dispatch(toggleLoader(false));
      });
  };

  return (
    <Fragment>
      <Row>
        <div className="mb-3">
          <a href={'/lista-deconectare-reconectare-' + (utility == UTILITY_EE ? 'ee' : 'gn')}>
            <Button>
              <FontAwesomeIcon icon={faChevronLeft} style={{ marginRight: '6px' }} />
              <span>Înapoi</span>
            </Button>
          </a>
        </div>
      </Row>

      {!fatalError && (
        <>
          {step === 1 && (
            <div className="m-3">
              <Row className="mt-3">
                <Col xs={12} md={12}>
                  <div className="justify-content-between d-flex">
                    <span>Listă locuri consum (XLS, XLSX)</span>
                    <span className={'p-gray'}>Mărime maximă fișier: 20 MB</span>
                  </div>

                  <UploadFile
                    onSetFileList={handleFileListChange}
                    fileList={fileList}
                    index={0}
                    uploadedFilesLimit={1}
                    acceptedExtensions={['.xls', '.xlsx']}
                    fileSizeLimit={20}
                  />

                  {errors['fileList'] && <div style={{ color: 'red', fontWeight: 'bold' }}>{errors['fileList']}</div>}
                </Col>
              </Row>

              {error && <div style={{ color: 'red', fontWeight: 'bold' }}>{error}</div>}

              <Button className="btn-large mt-3" onClick={submit}>
                Trimite
              </Button>
            </div>
          )}

          {step == 2 && <Confirmare cpList={cpList} bulkImportId={bulkImportId} utility={utility} />}

          <CustomModal
            modalTitle={'Eroare'}
            showModal={showModalError}
            onClose={() => {
              setShowModalError(false);
            }}
          >
            <h5>{modalErrorMessage}</h5>
          </CustomModal>
        </>
      )}

      {fatalError && <p className="text-center fw-bold red mt-3">{fatalError} </p>}
    </Fragment>
  );
};

export default DecRecIncarcareInMasa;
