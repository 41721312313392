import { toggleLoader } from '@actions/LoaderActions';
import { postBulkImportContinue } from '@api/decrec/decrec';
import ChoiceModal from '@components/common/Modal/ChoiceModal';
import { UTILITY_EE } from '@constants/Utility';
import { PostBulkImportUploadResponseLocConsum } from '@type/decrec';
import { Fragment, useEffect, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';

type ConfirmareProps = {
  cpList: PostBulkImportUploadResponseLocConsum[];
  bulkImportId: string | any;
  utility: number;
};

const Confirmare = (props: ConfirmareProps) => {
  const { cpList } = props;
  const [error, setError] = useState('');

  //modals
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showModalCancel, setShowModalCancel] = useState(false);

  const [showButtonContinueWithValids, setShowButtonContinueWithValids] = useState(false);
  const [summary, setSummary] = useState<any>(null);
  const [filterStatus, setFilterStatus] = useState<any>('');

  const dispatch = useDispatch();

  useEffect(() => {
    if (cpList && cpList.length) {
      let summary = { Valid: 0, Invalid: 0 };

      cpList.forEach((cp) => {
        if (cp.valid) {
          setShowButtonContinueWithValids(true);
          summary['Valid'] = summary['Valid'] + 1;
        } else {
          summary['Invalid'] = summary['Invalid'] + 1;
        }
      });
      setSummary(summary);
    } else {
      setShowButtonContinueWithValids(false);
    }
  }, [cpList]);

  const handleModalConfirm = (option: any) => {
    if (option.id == 'DA') {
      dispatch(toggleLoader(true));
      postBulkImportContinue({ bulkImportId: props.bulkImportId })
        .then((res: { errorMessage: string; requestIds: string; success: boolean }) => {
          if (res.success) {
            setTimeout(() => {
              window.location.href =
                '/lista-deconectare-reconectare-' +
                (props.utility == UTILITY_EE ? 'ee' : 'gn') +
                '?FlashNotificationSucces=Importul a fost realizat cu succes.';
            }, 100);
          } else if (res.errorMessage) {
            setError(res.errorMessage);
          } else {
            setError('A apărut o eroare tehnică. Vă rugam reveniți mai târziu.');
          }
          dispatch(toggleLoader(false));
        })
        .catch((err) => {
          dispatch(toggleLoader(false));
          setError('A apărut o eroare tehnică. Vă rugam reveniți mai târziu.');
        });
    }
    if (option.id == 'NU') {
      setShowConfirmModal(false);
    }
  };

  const handleCancelModal = (option: any) => {
    if (option.id == 'DA') {
      window.location.href = '/lista-deconectare-reconectare-' + (props.utility == UTILITY_EE ? 'ee' : 'gn');
    } else {
      setShowModalCancel(false);
    }
  };

  return (
    <Fragment>
      <Row className="d-flex justify-content-between align-items-center">
        <Col xs={12} md={5} className="d-flex align-items-center gray-filters-list mb-2">
          {summary && (
            <span className={!filterStatus ? 'active' : 'not-active'} onClick={() => setFilterStatus('')}>
              <span className="m-2">{'Total'}</span>
              <span className="bg-eon-lightgrey fw-bold" style={{ marginRight: '10px', borderRadius: '4px', padding: '2px 6px' }}>
                {cpList.length}
              </span>
            </span>
          )}

          {summary &&
            Object.keys(summary).map((key: string, index) => {
              let active = filterStatus === key;
              return (
                <span
                  className={active ? 'active' : 'not-active'}
                  key={index}
                  onClick={() => (!active ? setFilterStatus(key) : setFilterStatus(''))}
                >
                  <span className="m-2">{key}</span>
                  <span className="bg-eon-lightgrey fw-bold" style={{ marginRight: '10px', borderRadius: '4px', padding: '2px 6px' }}>
                    {summary[key]}
                  </span>
                </span>
              );
            })}
        </Col>

        <Col xs={12} md={7} className={'d-flex justify-content-end mb-2'}>
          {showButtonContinueWithValids && (
            <Button
              className="me-2"
              onClick={() => {
                setShowConfirmModal(true);
              }}
            >
              Continuă cu locurile valide
            </Button>
          )}

          <Button className="btn-wide" onClick={() => setShowModalCancel(true)}>
            Anulează
          </Button>
        </Col>
      </Row>

      <div className="mt-3">
        <div className="general-table-container">
          <table>
            <thead>
              <tr>
                <th>Nr. Crt.</th>
                <th>Cod punct de măsurare</th>
                <th>Erori</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {cpList &&
                cpList
                  .filter((cp) => {
                    if (!filterStatus) {
                      return true;
                    } else if (filterStatus == 'Valid') {
                      return cp.valid;
                    } else {
                      return !cp.valid;
                    }
                  })
                  .map((item: PostBulkImportUploadResponseLocConsum, index: number) => {
                    return (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{item.pod}</td>
                        <td>
                          {item.errorMessages &&
                            item?.errorMessages.map((error: string, index: number) => {
                              return <p key={index}>{error}</p>;
                            })}
                        </td>

                        <td className={item.valid ? 'approved fw-bold' : ''}>{item.valid ? 'Valid' : 'Invalid'}</td>
                      </tr>
                    );
                  })}
            </tbody>
          </table>
        </div>

        {error && <div style={{ color: 'red', fontWeight: 'bold' }}>{error}</div>}
      </div>

      <ChoiceModal
        showModal={showConfirmModal}
        modalTitle={'Doriți continuarea solicitării doar cu locurile de consum valide?'}
        options={[
          { title: 'Da', id: 'DA' },
          { title: 'Nu', id: 'NU' }
        ]}
        onClose={handleModalConfirm}
        onHide={() => setShowConfirmModal(false)}
        btnWide
      />

      <ChoiceModal
        showModal={showModalCancel}
        modalTitle={'Doriți anularea acestei solicitări?'}
        options={[
          { title: 'Da', id: 'DA' },
          { title: 'Nu', id: 'NU' }
        ]}
        onClose={handleCancelModal}
        onHide={() => setShowModalCancel(false)}
        btnWide
      />
    </Fragment>
  );
};

export default Confirmare;
