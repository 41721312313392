import React from 'react';

import { DelgazWrapper } from '@components/global/DelgazWrapper';
import AgreementPVAE from '@components/delgaz/AcordPVAE/AgreementPVAE';

export type AgreementPVAEComponentProps = {
  kentico: any;
};

export const AgreementPVAEComponent = (props: AgreementPVAEComponentProps): JSX.Element => {
  return (
    <DelgazWrapper>
      <AgreementPVAE {...props} />
    </DelgazWrapper>
  );
};
