import React, { useEffect, useState } from 'react';
import Filters from './Filters';
import Table from './Table';
import { getElectronicRegistryList, registryExport, getRequestDetails } from '@api/registruElectronicCereri';
import { useDispatch } from 'react-redux';
import { toggleLoader } from '@actions/LoaderActions';
import { Button, Modal } from 'react-bootstrap';
import moment from 'moment';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FILE_EXPORT_LIMIT } from '@constants/Constants';
import DetailsModal from './DetailsModal';

const RegistruCereriRacordareGN = () => {
  const [list, setList] = useState<any[]>([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalItems, setTotalItems] = useState(0);
  const [sortBy, setSortBy] = useState('RequestDate');
  const [sortType, setSortType] = useState('DESC');
  const [county, setCounty] = useState('');
  const [locality, setLocality] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [requestNo, setRequestNo] = useState('');
  const [error, setError] = useState('');
  const [mountType, setMountType] = useState('mount');
  const [showExportErrorModal, setShowExportErrorModal] = useState(false);
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [details, setDetails] = useState(null);
  const [initialized, setInitialized] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    (mountType !== 'reset' && initialized) && getList(constructPayload());
  }, [sortBy, sortType, pageNumber, pageSize]);

  const constructPayload = () => {
    return [
      `sortBy=${sortBy}`,
      `sortType=${sortType}`,
      `pageNumber=${pageNumber}`,
      `pageSize=${pageSize}`,
      county !== '' ? `countyCode=${county}` : '',
      locality !== '' ? `localityCode=${locality}` : '',
      startDate !== '' ? `startDate=${startDate}` : '',
      endDate !== '' ? `endDate=${endDate}` : '',
      requestNo !== '' ? `requestNumber=${requestNo}` : ''
    ];
  };

  const getList = async (payload: any) => {
    dispatch(toggleLoader(true));

    await getElectronicRegistryList(payload)
      .then((response) => {
        if (response && response.data) {
          setList(response.data.items);
          setTotalItems(response.data.metadata.totalItemsCount);
          setPageNumber(response.data.metadata.pageIndex);
          setPageSize(response.data.metadata.pageSize);

          !initialized && setInitialized(true);
        }
      })
      .catch((err) => console.log(err));

    dispatch(toggleLoader(false));
  };

  const submit = () => {
    setError('');

    if ((county !== '' && locality !== '') || requestNo !== '') {
      setMountType('mount');
      setPageNumber(1);
      setPageSize(10);
      setSortBy('requestId');
      setSortType('DESC');
  
      let payload = [
        `sortBy=${'requestId'}`,
        `sortType=${'DESC'}`,
        `pageNumber=${1}`,
        `pageSize=${10}`,
        county !== '' ? `countyCode=${county}` : '',
        locality !== '' ? `localityCode=${locality}` : '',
        startDate !== '' ? `startDate=${startDate}` : '',
        endDate !== '' ? `endDate=${endDate}` : '',
        requestNo !== '' ? `requestNumber=${requestNo}` : ''
      ];
  
      getList(payload);
    } else {
      setError('Te rugăm să completezi fie Numărul cererii, fie perechea Județ/Localitate.');
    }
  };

  const reset = () => {
    setMountType('reset');
    setCounty('');
    setLocality('');
    setStartDate('');
    setEndDate('');
    setRequestNo('');

    setList([]);
    // setPageNumber(1);
    // setPageSize(10);
    // setSortBy('requestId');
    // setSortType('DESC');

    // let payload = [`sortBy=${sortBy}`, `sortType=${sortType}`, `pageNumber=${1}`, `pageSize=${10}`];

    // getList(payload);
  };

  const setElementsPerPage = (elements: number) => {
    setMountType('mount');
    setPageSize(elements);
  };

  const paginate = (pageNumber: number) => {
    setMountType('mount');
    setPageNumber(pageNumber);
  };

  const fileExport = async () => {
    dispatch(toggleLoader(true));

    await registryExport(constructPayload()).then((response) => {
      const blob: Blob = new Blob([response]);

      const fileName: string = `export-registru-electronic-cereri-racordare-${moment().format('YYMMDDHms')}.xlsx`;
      const objectUrl: string = URL.createObjectURL(blob);
      const a: HTMLAnchorElement = document.createElement('a') as HTMLAnchorElement;

      a.href = objectUrl;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();

      document.body.removeChild(a);
      URL.revokeObjectURL(objectUrl);
      dispatch(toggleLoader(false));
    }).catch((err) => {
      console.log(err);
    });

    dispatch(toggleLoader(false));
  };

  const getDetails = async (requestId: number) => {
    dispatch(toggleLoader(true));

    await getRequestDetails({ requestId })
      .then((response) => {
        if (response && response.status === 200) {
          setDetails(response.data);
          setShowDetailsModal(true);
        }
      }).catch((err) => {
        console.log(err);
      });

    dispatch(toggleLoader(false));
  };

  const renderExportLimitErrorModal = () => {
    return <Modal centered show={showExportErrorModal} onHide={() => setShowExportErrorModal(false)}>
      <Modal.Header closeButton>
        <h5 className="red fw-bold">Eroare!</h5>
      </Modal.Header>
      <Modal.Body>
        <p>Limita pentru export a fost depășită. Te rugăm să aplici filtre suplimentare.</p>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => setShowExportErrorModal(false)}>OK</Button>
      </Modal.Footer>
    </Modal>;
  };

  return (
    <div className="registru-cereri-racordare m-3">
      <Filters
        county={county}
        handleCounty={(value: string) => setCounty(value)}
        locality={locality}
        handleLocality={(value: string) => setLocality(value)}
        startDate={startDate}
        handleStartDate={(value: string) => setStartDate(value)}
        endDate={endDate}
        handleEndDate={(value: string) => setEndDate(value)}
        requestNo={requestNo}
        handleRequestNo={(value: string) => setRequestNo(value)}
        submit={submit}
        reset={reset}
      />
      { error !== '' && <p className="red fw-bold">{error}</p> }
      {list.length > 0 && <div className="d-flex justify-content-end mt-4">
        <Button onClick={() => {
          if (totalItems <= FILE_EXPORT_LIMIT) {
            fileExport();
          } else {
            setShowExportErrorModal(true);
          }
        }}>
          <FontAwesomeIcon icon={faDownload} style={{ marginRight: '6px' }} />
          Export
        </Button>
      </div>}
      {list.length > 0 && <Table
        list={list}
        currentPage={pageNumber}
        perPage={pageSize}
        paginate={paginate}
        setElementsPerPage={setElementsPerPage}
        totalItems={totalItems}
        sortBy={sortBy}
        handleSortBy={(value: string) => {setMountType('mount'); setSortBy(value);}}
        sortType={sortType}
        handleSortType={(value: string) => {setMountType('mount'); setSortType(value);}}
        getDetails={getDetails}
      />}

      {renderExportLimitErrorModal()}
      {details !== null && 
        <DetailsModal 
          show={showDetailsModal} 
          setShow={(value: boolean) => setShowDetailsModal(value)} 
          details={details} 
          clearDetails={() => setDetails(null)} 
        />
      }
    </div>
  );
};

export default RegistruCereriRacordareGN;