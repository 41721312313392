import { GetCounties } from '@api/geoApi';
import { Dropdown, DropdownOptionType } from '@components/common/Dropdown';
import RomaniaMapByCounties from '@components/dev/Components/RomaniaMap/RomaniaMapByCounties';
import { faMapLocation, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CountyTemplate } from '@type/geo';
import { useEffect, useState } from 'react';

interface Props {
  kentico: Kentico;
}

interface Kentico {
  ExpandableText: null;
  TextHeader: null;
  TextCountyClick: null;
  ShowItem: null;
  Items: Item[];
}

interface Item {
  document: Document[];
}

interface Document {
  name: string;
  value: string;
}

const SHOW_ITEM_TYPE_PROGRAM = 1;
const SHOW_ITEM_TYPE_TEL_FAX = 2;

const PublicRelationsCenterMap = (props: Props) => {
  let { kentico } = props;
  const [show, setShow] = useState(false);
  const [counties, setCounties] = useState<CountyTemplate[]>([]);
  const [countiesWithCenters, setCountiesWithCenters] = useState<any[]>([]);
  const [centersCount, setCentersCount] = useState<any>(null);
  const [county, setCounty] = useState('');
  const [locality, setLocality] = useState('');

  //eslint-disable-next-line
  const [error, setError] = useState('');

  useEffect(() => {
    if (counties) {
      let countiesWithCenters: any[] = [];
      let centersCount: any = {};

      kentico.Items.map((item: Item) => {
        let countyWithCenters: any = {};

        item.document.forEach((o: Document) => {
          switch (o.name) {
            case 'Judet':
            case 'Județ':
              let county = counties.find((c) => c.countyName == o.value);
              if (county) {
                centersCount[county?.countyCode] = centersCount[county?.countyCode] ? centersCount[county?.countyCode] + 1 : 1;
              }
              countyWithCenters.countyName = o.value;
              countyWithCenters.countyCode = county?.countyCode;
              break;
            case 'Telefon':
              countyWithCenters.phone = o.value;
              break;
            case 'Fax':
              countyWithCenters.fax = o.value;
              break;
            case 'Localitate':
              countyWithCenters.locality = o.value;
              break;
            case 'TipCentru':
              countyWithCenters.type = o.value;
              break;
            case 'Adresă':
              countyWithCenters.address = o.value;
              break;
            case 'Email':
              countyWithCenters.email = o.value;
              break;
            case 'Program':
              countyWithCenters.program = o.value;
              break;
          }
        });

        countiesWithCenters.push(countyWithCenters);
      });
      setCountiesWithCenters(countiesWithCenters);
      setCentersCount(centersCount);
    }
  }, [counties]);

  useEffect(() => {
    GetCounties().then((res) => setCounties(res));
  }, []);

  const handleCountySelect = (code: string) => {
    setCounty(code);
    setLocality('');
  };

  return (
    <>
      {show ? (
        <div className="public-relations-center-map">
          <h3 className="fw-bold">{kentico.TextHeader}</h3>
          <h5>Dă click pe județ sau selectează o localitate din listă.</h5>

          <div className="d-flex flex-wrap justify-content-center">
            <div className="overflow-auto p-4">
              <RomaniaMapByCounties
                onCountySelect={handleCountySelect}
                countiesWithDetails={countiesWithCenters.map((c: CountyTemplate, i: number) => {
                  return { copuntyDetails: centersCount[c.countyCode], countyCode: c.countyCode };
                })}
              />
            </div>

            <div className="d-flex flex-column justify-content-start public-relations-center-map_side">
              <div className="me-1 ms-1 mb-3">
                <Dropdown
                  options={
                    countiesWithCenters.map((option, index) => ({
                      id: option.locality,
                      name: option.locality
                    })) as DropdownOptionType[]
                  }
                  defaultPlaceholder={'Ce localitate cauti?'}
                  onChange={(value) => {
                    setLocality(value);
                  }}
                  value={locality}
                  displaySearch
                  mbZero
                />
              </div>

              {county && !locality && (
                <h5 className="red mb-3 me-1 ms-1">
                  {kentico.TextCountyClick}
                  {!locality && counties.find((c) => c.countyCode == county)?.countyName}
                </h5>
              )}

              <div className="overflow-auto" style={{ maxHeight: '330px' }}>
                {countiesWithCenters
                  .filter((c) => {
                    if (locality) {
                      return c.locality == locality;
                    } else if (county) {
                      return c.countyCode == county;
                    }
                  })
                  .map((c: any, index: number) => {
                    return (
                      <div key={index} className={`me-1 ms-1 ${index > 0 ? 'mt-3 ' : ''}`}>
                        <div className="d-flex flex-column p-3 details-block">
                          <div className="d-flex flex-row align-items-center">
                            <span className="utility-square me-2 mb-1">
                              <FontAwesomeIcon icon={faMapLocation} size="lg" />
                            </span>
                            <h5 className="red m-0 mb-1 text-center">{c.locality}</h5>
                          </div>

                          <div className="public-relations-center-map_content">
                            <div dangerouslySetInnerHTML={{ __html: c.address }} />

                            {kentico.ShowItem == SHOW_ITEM_TYPE_PROGRAM && (
                              <>
                                <div dangerouslySetInnerHTML={{ __html: c.program }} />
                              </>
                            )}

                            {kentico.ShowItem == SHOW_ITEM_TYPE_TEL_FAX && (
                              <>
                                <div dangerouslySetInnerHTML={{ __html: c.phone }} />
                                <div dangerouslySetInnerHTML={{ __html: c.fax }} />
                              </>
                            )}

                            <div dangerouslySetInnerHTML={{ __html: c.email }} />
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>

          {error && <div style={{ color: 'red', fontWeight: 'bold' }}>{error}</div>}
        </div>
      ) : (
        <div
          onClick={() => setShow(true)}
          style={{
            background: '#f6f6f7',
            maxWidth: '1200px',
            width: '90%',
            display: 'flex',
            margin: '0 auto',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '10px',
            cursor: 'pointer'
          }}
        >
          <h4 style={{ margin: '0', padding: '10px', fontWeight: 'bold' }}>{kentico.ExpandableText}</h4>
          <FontAwesomeIcon icon={faMapMarkerAlt} size={'2x'} color="#ea1b0a" />
        </div>
      )}
    </>
  );
};

export default PublicRelationsCenterMap;
