import { faAngleLeft, faAngleRight, faAnglesLeft, faAnglesRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PaginationKentico } from '@type/kentico-types';
import { Col, Dropdown, Row } from 'react-bootstrap';

const PaginationComponent = (props: { kentico: PaginationKentico, paginate: any, setElementsPerPage: any }) => {
    const pageNumbers = [];
    const {paginate, setElementsPerPage} = props;

    for(let i = 1; i <= Math.ceil(Number(props.kentico.totalElements)/Number(props.kentico.perPage)); i++) {
        pageNumbers.push(i);
    }

    const renderPages = (prev: number, current: number, next: number) => {
        return (
            <>
                <li  onClick={() => paginate(prev)} className={prev === Number(props.kentico.currentPage) ? 'page-link-current' : 'page-link-not-current'}>
                    <a className={prev === Number(props.kentico.currentPage) ? 'current' : 'not-current'} href="#!">{prev}</a>
                </li>
                <li onClick={() => paginate(current)} className={current === Number(props.kentico.currentPage) ? 'page-link-current' : 'page-link-not-current'}>
                    <a className={current === Number(props.kentico.currentPage) ? 'current' : 'not-current'} href="#!">{current}</a>
                </li>
                <li onClick={() => paginate(next)} className={next === Number(props.kentico.currentPage) ? 'page-link-current' : 'page-link-not-current'}>
                    <a className={next === Number(props.kentico.currentPage) ? 'current' : 'not-current'} href="#!">{next}</a>
                </li>
            </>
        );
    };

    const pageCases = (pages: number[]) => {
        if (pages.length > 10) {
            return (
                <>
                    <li onClick={() => {paginate(1);}} className={1 === Number(props.kentico.currentPage) ? 'page-link-current' : 'page-link-not-current'}>
                        <a className={1 === Number(props.kentico.currentPage) ? 'current' : 'not-current'} href="#!">1</a>
                    </li>
                    {Number(props.kentico.currentPage) < 4 && <>
                        {renderPages(2,3,4)}
                        <li className="page-link-not-current">
                            ...
                        </li>
                    </>}
                    {Number(props.kentico.currentPage) >= 4 && Number(props.kentico.currentPage) <= pageNumbers.length-3 && <>
                        <li className="page-link-not-current">
                            ...
                        </li>
                        {renderPages(Number(props.kentico.currentPage)-1, Number(props.kentico.currentPage), Number(props.kentico.currentPage)+1)}
                        <li className="page-link-not-current">
                            ...
                        </li>
                    </>}
                    {Number(props.kentico.currentPage) > pageNumbers.length-3 && <>
                        <li className="page-link-not-current">
                            ...
                        </li>
                        {renderPages(pageNumbers.length-3, pageNumbers.length-2, pageNumbers.length-1)}
                    </>
                    }
                    <li onClick={() => {paginate(pageNumbers.length);}} className={pageNumbers.length === Number(props.kentico.currentPage) ? 'page-link-current' : 'page-link-not-current'}>
                        <a className={pageNumbers.length === Number(props.kentico.currentPage) ? 'current' : 'not-current'} href="#!">{pageNumbers.length}</a>
                    </li>
                </>
            );
        }
        else {
            return (
                <>
                    {pages.map(number => (
                        <li key={number}
                            className={number === Number(props.kentico.currentPage) ? 'page-link-current' : 'page-link-not-current'}
                            onClick={() => {paginate(number);}}
                        >
                            <a className={number === Number(props.kentico.currentPage) ? 'current' : 'not-current'} href="#!">{number}</a>
                        </li>
                    ))}
                </>
            );
        }
    };

    return (
        <>
            <p>Număr înregistrări pe pagină</p>
            <Row className="d-flex justify-content-between">
                <Col xs={12} md={2} className="mb-2">
                    <Dropdown>
                        <Dropdown.Toggle variant="light" id="dropdown-basic" style={{border: '1px solid #dee2e6', height: '42px', width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                            {Number(props.kentico.perPage)}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item onClick={() => {setElementsPerPage(10); paginate(1);}}>10</Dropdown.Item>
                            <Dropdown.Item onClick={() => {setElementsPerPage(20); paginate(1);}}>20</Dropdown.Item>
                            <Dropdown.Item onClick={() => {setElementsPerPage(50); paginate(1);}}>50</Dropdown.Item>
                            <Dropdown.Item onClick={() => {setElementsPerPage(100); paginate(1);}}>100</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </Col>
                <Col xs={12} md={6}>
                    <nav>
                        <ul className="pagination">{Number(props.kentico.currentPage) === 1 ? <FontAwesomeIcon className="nav-icon-disabled" icon={faAnglesLeft}/> :
                            <FontAwesomeIcon className="nav-icon" onClick={() => paginate(1)} icon={faAnglesLeft}/>}
                            {Number(props.kentico.currentPage) === 1 ? <FontAwesomeIcon className="nav-icon-disabled" icon={faAngleLeft}/> :
                                <FontAwesomeIcon className="nav-icon" onClick={() => paginate(Number(props.kentico.currentPage)-1)} icon={faAngleLeft}/>}
                            {pageCases(pageNumbers)}
                            {Number(props.kentico.currentPage) === pageNumbers.length ? <FontAwesomeIcon className="nav-icon-disabled" icon={faAngleRight}/> :
                                <FontAwesomeIcon className="nav-icon" onClick={() => paginate(Number(props.kentico.currentPage)+1)} icon={faAngleRight}/>}
                            {Number(props.kentico.currentPage) === pageNumbers.length ? <FontAwesomeIcon className="nav-icon-disabled" icon={faAnglesRight}/> :
                                <FontAwesomeIcon className="nav-icon" onClick={() => paginate(pageNumbers.length)} icon={faAnglesRight}/>}
                        </ul>
                    </nav>
                </Col>
            </Row>
        </>
    );
};

export default PaginationComponent;