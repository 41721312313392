import { toggleLoader } from '@actions/LoaderActions';
import {
    getStepFive,
    getStepFour,
    getStepOne,
    getStepSeven,
    getStepSix,
    getStepThree,
    getStepTwo,
    getUserDetails
} from '@api/iConnect/iConnectEE';
import { getTypes } from '@api/prosumatori/prosumatori';
import ChoiceModal from '@components/common/Modal/ChoiceModal';
import { File } from '@components/common/UploadFile/UploadFile';
import Steps from '@components/prosumatori/components/Steps';
import { stepsArrayIConnectEE } from '@constants/iConnect/StepsEE';
import { IState } from '@type/store';
import { jwtDecode } from 'jwt-decode';
import { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CanaleDeComunicare from './steps/CanaleDeComunicare';
import ClientFinal from './steps/ClientFinal';
import DocumenteAnexate from './steps/DocumenteAnexate';
import InformareFinala from './steps/InformareFinala';
import LCCasnic from './steps/LCCasnic';
import LCNoncasnic from './steps/LCNoncasnic';
import ObiectivulDeRacordat from './steps/ObiectivulDeRacordat';
import ReprezentantLegal from './steps/ReprezentantLegal';
import { Button } from 'react-bootstrap';

type JWT = {
    aud: string;
    aut: string;
    azp: string;
    deviceid: string;
    exp: number;
    given_name: string;
    groups: string[];
    iat: number;
    iss: string;
    jti: string;
    nbf: number;
    scope: string;
    sub: string;
    userid: string;
}

const modalData = {
    title: 'Pentru cine dorești să depui cererea?',
    options: [
        { title: 'Vreau să depun o cerere în numele meu', id: 1 },
        { title: 'Vreau să depun o cerere în numele altcuiva', id: 2 }
    ]
};

const dateIde = {
    Nume: '',
    Prenume: '',
    CNP: '',
    Denumire: '',
    CUI: '',
    NrReg: '',
    Telefon: '',
    Email: '',
    Judet: '',
    Localitate: '',
    Comuna: '',
    Strada: '',
    Numar: '',
    Bloc: '',
    Etaj: '',
    Scara: '',
    Ap: '',
    Cont: '',
    Banca: '',
    Reprezentant: '',
    InCalitateDe: '',
    SerieCI: '',
    NumarCI: '',
    CIEliberatDe: '',
    CIData: '',
    CodCAEN: ''
};

const date_consultant = {
    prenume_consultant: '',
    nume_consultant: '',
    email_consultant: '',
    telefon_consultant: ''
};

const adr = {
    Judet: '',
    Localitate: '',
    Comuna: '',
    Strada: '',
    Numar: '',
    Bloc: '',
    Etaj: '',
    Scara: '',
    Ap: '',
    Email: '',
    Telefon: ''
};

const imp = {
    Nume: '',
    Prenume: '',
    CNP: '',
    Denumire: '',
    CUI: '',
    Telefon: '',
    Email: '',
    Judet: '',
    Localitate: '',
    Comuna: '',
    Strada: '',
    Numar: '',
    Bloc: '',
    Etaj: '',
    Scara: '',
    Ap: '',
    SerieCI: '',
    NumarCI: '',
    CIEliberatDe: '',
    CIData: '',
    NrAutorizare: '',
    ActEmisDe: '',
    Reprezentant: '',
    NrReg: ''
};

const adresaObiectivului = {
    POD: '',
    Judet: '',
    Localitate: '',
    Comuna: '',
    Strada: '',
    Numar: '',
    Bloc: '',
    Etaj: '',
    Scara: '',
    Ap: '',
    nrCF: '',
    nrCad: '',
    cod_loc_consum: ''
};

const instalatii = [
    {
        id: 1,
        tip_instalatie: 'Instalații stocare',
        active: false,
    },
    {
        id: 2,
        tip_instalatie: 'Sisteme HVDC',
        active: false,
    },
    {
        id: 3,
        tip_instalatie: 'Instalații distribuție',
        active: false,
    },
    {
        id: 4,
        tip_instalatie: 'Receptoare electrice',
        active: false,
    },
];

const dateTehniceLC = {
    seKW: '0.00',
    seKVA: '0.00',
    seTipRacord: 0,
    ssKW: '0.00',
    ssKVA: '0.00',
    ssTipRacord: 0,
};

const statiiInc = {
    columns: [
        {
            name: 'Denumire statie de incarcare masini electrice',
            type: 'input',
            dataType: 'string',
            colName: 'Denumire stație de încărcare mașini electrice',
        },
        {
            name: 'Nr. statii',
            type: 'input',
            dataType: 'integer',
            colName: 'Nr. stații',
        },
        {
            name: 'Putere maxima instalata (kW)',
            type: 'input',
            dataType: 'numeric',
            colName: 'Puterea maximă instalată pentru toate stațiile de același tip (kW)',
        },
        {
            name: 'Putere maxima simultan absorbita (kW)',
            type: 'input',
            dataType: 'numeric',
            colName: 'Putere maximă simultan absorbită (kW)',
        },
        {
            name: 'Tip statie (lenta/rapida)',
            type: 'select',
            options: [{ name: 'lenta', id: 1 }, { name: 'rapida', id: 2 }],
            colName: 'Tip stație (lentă/rapidă)',
        },
        {
            name: 'Acces statie (public / privat)',
            type: 'select',
            options: [{ name: 'privat', id: 1 }, { name: 'public', id: 2 }],
            colName: 'Acces stație (public / privat)',
        },
        {
            name: 'Sursa de finantare',
            type: 'select',
            options: [{ name: 'FORTE PROPRII', id: 1 }, { name: 'AFM2021-UAT SI INSTIT.PUBLICE', id: 2 }, { name: 'ELECTRIC UP', id: 2 }],
            colName: 'Sursa de finanțare',
            width: '300px'
        },
        {
            name: 'Observatii',
            type: 'input',
            dataType: 'string',
            colName: 'Observații'
        }
    ],
    totalCol: [1, 2, 3],
    handledCols: [2],
};

const listaRec = {
    columns: [
        {
            name: 'Denumire',
            type: 'input',
            dataType: 'string',
            colName: 'Denumire receptor',
            width: '150px'
        },
        {
            name: 'Putere_instalata',
            type: 'input',
            dataType: 'numeric',
            colName: 'Puterea instalată a unui receptor',
            width: '150px'
        },
        // {
        //     name: 'putere_maxim_absorbita',
        //     type: 'input',
        //     dataType: 'numeric',
        //     colName: 'Puterea maxim simultan absorbită a unui receptor',
        //     width: '150px'
        // },
        {
            name: 'numar_receptoare',
            type: 'input',
            dataType: 'numeric',
            colName: 'Numărul de receptoare',
            width: '150px'
        },
        {
            name: 'TotalConsumptionPower',
            type: 'readOnly',
            dataType: 'numeric',
            colName: 'Puterea instalată totală (kW)',
            width: '320px'
        },
        {
            name: 'Observatii',
            type: 'input',
            dataType: 'string',
            colName: 'Observații',
            width: '150px'
        }
    ],
    totalCol: [1, 2, 3, 4],
    handledCols: [1, 2, 3, 4]
};

const utilizatori = {
    columns: [
        {
            name: 'nume_utilizator_extern',
            type: 'input',
            dataType: 'string',
            colName: 'Subconsumator',
            width: '150px'
        },
        {
            name: 'kw_total_instalata_utilizator_extern',
            type: 'input',
            dataType: 'numeric',
            colName: 'Putere instalată (kW)',
            width: '150px'
        },
        {
            name: 'kw_maxim_absorbita_utilizator_extern',
            type: 'input',
            dataType: 'numeric',
            colName: 'Puterea maxim simultan absorbită (kW)',
            width: '150px'
        }
    ],
    totalCol: [1, 2],
    handledCols: [1, 2]
};

//eslint-disable-next-line
const detalii_energetice_receptoare = {
    columns: [
        {
            name: 'Nr. crt.',
            type: 'readOnly',
            dataType: 'string',
            colName: 'nr_crt',
            width: '50px'
        },
        {
            name: 'Date energetice ale consumatorului',
            type: 'readOnly',
            dataType: 'string',
            colName: 'Date energetice ale consumatorului',
            width: '450px'
        },
        {
            name: 'UM',
            type: 'readOnly',
            dataType: 'string',
            colName: 'U.M.',
            width: '150px',
        },
        {
            name: 'situatie curenta',
            type: 'input',
            dataType: 'string',
            colName: 'Situație existentă',
            width: '150px'
        },
        {
            name: 'an1',
            type: 'input',
            dataType: 'string',
            colName: 'An 1',
            width: '150px'
        },
        {
            name: 'an2',
            type: 'input',
            dataType: 'string',
            colName: 'An 2',
            width: '150px'
        },
        {
            name: 'an3',
            type: 'input',
            dataType: 'string',
            colName: 'An 3',
            width: '150px'
        },
        {
            name: 'an4',
            type: 'input',
            dataType: 'string',
            colName: 'An 4',
            width: '150px'
        },
        {
            name: 'an5',
            type: 'input',
            dataType: 'string',
            colName: 'An 5',
            width: '150px'
        },
        {
            name: 'situatie finala',
            type: 'input',
            dataType: 'string',
            colName: 'Situație finală',
            width: '150px'
        },
        {
            name: 'observatii',
            type: 'input',
            dataType: 'string',
            colName: 'Observații',
            width: '150px'
        }
    ]
};

const aparate_frecvente = {
    columns: [
        {
            name: 'Enabled',
            type: 'checkbox',
            dataType: 'checkbox',
            colName: '',
            width: '50px'
        },
        {
            name: 'DomesticConsumerName',
            type: 'readOnly',
            dataType: 'string',
            colName: 'Aparate frecvente',
            width: '375px'
        },
        {
            name: 'ConsumptionPower',
            type: 'input',
            dataType: 'numeric',
            colName: 'Puterea unui receptor (kW)*',
            width: '150px'
        },
        {
            name: 'DomesticConsumerCount',
            type: 'input',
            dataType: 'integer',
            colName: 'Numărul de receptoare*',
            width: '150px'
        },
        {
            name: 'TotalConsumptionPower',
            type: 'readOnly',
            dataType: 'numeric',
            colName: 'Puterea instalată totală (kW)',
            width: '150px'
        }
    ],
    totalCol: [4],
    totalColSpan: [4],
    totalColName: 'Putere instalată totală'
};

const dateAparateFrecvente = [
    {
        Enabled: false,
        DomesticConsumerName: 'Iluminat',
        ConsumptionPower: '0.1',
        DomesticConsumerCount: '0',
        TotalConsumptionPower: '0.00'
    },
    {
        Enabled: false,
        DomesticConsumerName: 'Televizor',
        ConsumptionPower: '0.1',
        DomesticConsumerCount: '0',
        TotalConsumptionPower: '0.00'
    },
    {
        Enabled: false,
        DomesticConsumerName: 'DVD/Video',
        ConsumptionPower: '0.1',
        DomesticConsumerCount: '0',
        TotalConsumptionPower: '0.00'
    },
    {
        Enabled: false,
        DomesticConsumerName: 'Sistem audio',
        ConsumptionPower: '0.4',
        DomesticConsumerCount: '0',
        TotalConsumptionPower: '0.00'
    },
    {
        Enabled: false,
        DomesticConsumerName: 'Calculator',
        ConsumptionPower: '0.5',
        DomesticConsumerCount: '0',
        TotalConsumptionPower: '0.00'
    },
    {
        Enabled: false,
        DomesticConsumerName: 'Fier de călcat',
        ConsumptionPower: '1.8',
        DomesticConsumerCount: '0',
        TotalConsumptionPower: '0.00'
    },
    {
        Enabled: false,
        DomesticConsumerName: 'Uscător de păr',
        ConsumptionPower: '0.8',
        DomesticConsumerCount: '0',
        TotalConsumptionPower: '0.00'
    },
    {
        Enabled: false,
        DomesticConsumerName: 'Mașină de spălat vase',
        ConsumptionPower: '0.8',
        DomesticConsumerCount: '0',
        TotalConsumptionPower: '0.00'
    },
    {
        Enabled: false,
        DomesticConsumerName: 'Mașină de spălat rufe',
        ConsumptionPower: '2.0',
        DomesticConsumerCount: '0',
        TotalConsumptionPower: '0.00'
    },
    {
        Enabled: false,
        DomesticConsumerName: 'Uscător de rufe',
        ConsumptionPower: '2.0',
        DomesticConsumerCount: '0',
        TotalConsumptionPower: '0.00'
    },
    {
        Enabled: false,
        DomesticConsumerName: 'Robot bucătărie',
        ConsumptionPower: '0.7',
        DomesticConsumerCount: '0',
        TotalConsumptionPower: '0.00'
    },
    {
        Enabled: false,
        DomesticConsumerName: 'Filtru cafea',
        ConsumptionPower: '0.8',
        DomesticConsumerCount: '0',
        TotalConsumptionPower: '0.00'
    },
    {
        Enabled: false,
        DomesticConsumerName: 'Prăjitor pâine',
        ConsumptionPower: '0.7',
        DomesticConsumerCount: '0',
        TotalConsumptionPower: '0.00'
    },
    {
        Enabled: false,
        DomesticConsumerName: 'Cuptor cu microunde',
        ConsumptionPower: '0.7',
        DomesticConsumerCount: '0',
        TotalConsumptionPower: '0.00'
    },
    {
        Enabled: false,
        DomesticConsumerName: 'Cuptor electric',
        ConsumptionPower: '2.0',
        DomesticConsumerCount: '0',
        TotalConsumptionPower: '0.00'
    },
    {
        Enabled: false,
        DomesticConsumerName: 'Plită electrică',
        ConsumptionPower: '2.0',
        DomesticConsumerCount: '0',
        TotalConsumptionPower: '0.00'
    },
    {
        Enabled: false,
        DomesticConsumerName: 'Aspirator',
        ConsumptionPower: '2.0',
        DomesticConsumerCount: '0',
        TotalConsumptionPower: '0.00'
    },
    {
        Enabled: false,
        DomesticConsumerName: 'Frigider',
        ConsumptionPower: '0.4',
        DomesticConsumerCount: '0',
        TotalConsumptionPower: '0.00'
    },
    {
        Enabled: false,
        DomesticConsumerName: 'Storcător fructe',
        ConsumptionPower: '0.5',
        DomesticConsumerCount: '0',
        TotalConsumptionPower: '0.00'
    },
    {
        Enabled: false,
        DomesticConsumerName: 'Centrală gaz',
        ConsumptionPower: '0.1',
        DomesticConsumerCount: '0',
        TotalConsumptionPower: '0.00'
    },
    {
        Enabled: false,
        DomesticConsumerName: 'Aer condiționat',
        ConsumptionPower: '2.0',
        DomesticConsumerCount: '0',
        TotalConsumptionPower: '0.00'
    },
    {
        Enabled: false,
        DomesticConsumerName: 'Cadă hidromasaj',
        ConsumptionPower: '0.9',
        DomesticConsumerCount: '0',
        TotalConsumptionPower: '0.00'
    },
    {
        Enabled: false,
        DomesticConsumerName: 'Utilități garaj',
        ConsumptionPower: '1.0',
        DomesticConsumerCount: '0',
        TotalConsumptionPower: '0.00'
    },
    {
        Enabled: false,
        DomesticConsumerName: 'Pompă irigații grădina',
        ConsumptionPower: '1.0',
        DomesticConsumerCount: '0',
        TotalConsumptionPower: '0.00'
    },
    {
        Enabled: false,
        DomesticConsumerName: 'Centrală electrică (de la 4 kW, până la 96 kW)',
        ConsumptionPower: '0.0',
        DomesticConsumerCount: '0',
        TotalConsumptionPower: '0.00'
    },
];

const alte_aparate = {
    columns: [
        {
            name: 'DomesticConsumerName',
            type: 'input',
            dataType: 'string',
            colName: 'Denumire receptor',
            width: '150px'
        },
        {
            name: 'ConsumptionPower',
            type: 'input',
            dataType: 'numeric',
            colName: 'Puterea unui receptor (kW)',
            width: '150px',
            defaultValue: ''
        },
        {
            name: 'DomesticConsumerCount',
            type: 'input',
            dataType: 'numeric',
            colName: 'Numărul de receptoare',
            width: '150px',
            defaultValue: '1'
        },
        {
            name: 'tip receptor',
            type: 'select',
            options: [{ name: 'Monofazat', id: 1 }, { name: 'Trifazat', id: 2 }],
            colName: 'Tip receptor',
            width: '150px'
        },
        {
            name: 'TotalConsumptionPower',
            type: 'readOnly',
            dataType: 'integer',
            colName: 'Puterea instalată totală (kW)',
            width: '150px',
            defaultValue: '0.00'
        }
    ],
    totalCol: [1, 4],
    handledCols: [1, 4]
};

const date_etape = [
    {
        an: '',
        puterea_totala: '',
        puterea_max_absorbita: ''
    },
    {
        an: '',
        puterea_totala: '',
        puterea_max_absorbita: ''
    },
    {
        an: '',
        puterea_totala: '',
        puterea_max_absorbita: ''
    },
    {
        an: '',
        puterea_totala: '',
        puterea_max_absorbita: ''
    },
    {
        an: '',
        puterea_totala: '',
        puterea_max_absorbita: ''
    }
];

const CerereIConnectEE = (props: any) => {
    const [requestId, setRequestId] = useState<number | null>(null);
    const [isEditing, setIsEditing] = useState<boolean | null>(null);
    const [initiated, setInitiated] = useState<boolean>(false);
    // If request does not exist
    const [noRequest, setNoRequest] = useState(false);
    const [isFinalized, setIsFinalized] = useState(false);

    const OLD_SITE_URL: string | undefined = useSelector<IState>((state) => state.options.webApiHost) as string;
    const token: string | undefined = useSelector<IState>((state) => state?.auth?.token) as string;
    let jwt: JWT = jwtDecode(token);

    const dispatch = useDispatch();

    const handleRequestId = (id: number) => {
        setRequestId(id);
    };

    const [showStartingModal, setShowStartingModal] = useState(true);
    const [startingModalOption, setStartingModalOption] = useState<number | null>(null);
    const [steps, setSteps] = useState<any[]>([]);
    const [currentStep, setCurrentStep] = useState(0);
    const [progress, setProgress] = useState(0);

    const setStepOneFormData = async () => {
        dispatch(toggleLoader(true));

        await getUserDetails()
            .then((response) => {
                if (jwt.groups.includes('ClientFizic')) {
                    handleDateIdentificare(response.contactFirstName, 'Prenume');
                    handleDateIdentificare(response.contactLastName, 'Nume');
                    handleDateIdentificare(response.email, 'Email');
                } else if (jwt.groups.includes('ClientJuridic')) {
                    handleDateIdentificare(response.companyName, 'Denumire');
                    handleDateIdentificare(response.cui, 'CUI');
                    handleDateIdentificare(response.orc, 'NrReg');
                    handleDateIdentificare(response.email, 'Email');
                }

                setStartingModalOption(1);
                stepsArrayIConnectEE.splice(1, 1);
                setSteps(stepsArrayIConnectEE);
                setCurrentStep(0);
                currentStep === progress && setProgress(0);

                setShowStartingModal(false);
            })
            .catch((err) => console.log(err));

        dispatch(toggleLoader(false));
    };

    const setStepTwoFormData = async () => {
        dispatch(toggleLoader(true));

        await getUserDetails()
            .then((response) => {
                if (jwt.groups.includes('ClientFizic')) {
                    handleImputernicit(response.contactFirstName, 'Prenume');
                    handleImputernicit(response.contactLastName, 'Nume');
                    handleImputernicit(response.email, 'Email');
                } else if (jwt.groups.includes('ClientJuridic')) {
                    handleImputernicit(response.companyName, 'Denumire');
                    handleImputernicit(response.cui, 'CUI');
                    handleImputernicit(response.orc, 'NrReg');
                    handleImputernicit(response.email, 'Email');
                }

                setStartingModalOption(2);
                setSteps(stepsArrayIConnectEE);
                setCurrentStep(0);
                currentStep === progress && setProgress(0);

                setShowStartingModal(false);
            })
            .catch((err) => console.log(err));

        dispatch(toggleLoader(false));
    };

    const handleCloseModal = (option: any) => {
        if (option.id === 1) {
            setStepOneFormData();
        } else {
            setStepTwoFormData();
        }
    };

    const handleStepChange = (step: number) => {
        setCurrentStep(step);
    };

    const handleProgress = (step: number) => {
        setProgress(step);
    };

    // 1 - Client final
    const [clientType, setClientType] = useState('');
    const [dateIdentificare, setDateIdentificare] = useState<any>(dateIde);
    const [dateConsultant, setDateConsultant] = useState<any>(date_consultant);
    const [adressType, setAdressType] = useState('');
    const [adress, setAdress] = useState(adr);

    const handleClientType = (type: string) => {
        setClientType(type);
    };

    const handleAdressType = (type: string) => {
        setAdressType(type);
    };

    const handleDateIdentificare = (data: any, field: any) => {
        let newData = dateIdentificare;

        Object.keys(newData).map((key: string, index) => {
            if (key === field) {
                //@ts-ignore
                newData[key] = data;
            }
        });

        setDateIdentificare(newData);
    };

    const handleDateConsultant = (data: any, field: any) => {
        let newData = dateConsultant;

        Object.keys(newData).map((key: string, index) => {
            if (key === field) {
                //@ts-ignore
                newData[key] = data;
            }
        });

        setDateConsultant(newData);
    };

    const handleAdressData = (data: any, field: any) => {
        let newData = adress;

        Object.keys(newData).map((key: string, index) => {
            if (key === field) {
                //@ts-ignore
                newData[key] = data;
            }
        });

        setAdress(newData);
    };

    // 2 - Reprezentant legal
    const [tipImputernicit, setTipImputernicit] = useState('');
    const [imputernicit, setImputernicit] = useState<object | null>(imp);

    const handleTipImputernicit = (type: string) => {
        setTipImputernicit(type);
    };

    const handleImputernicit = (data: any, field: any) => {
        let newData = imputernicit;

        newData && Object.keys(newData).map((key: string, index) => {
            if (key === field) {
                //@ts-ignore
                newData[key] = data;
            }
        });

        setImputernicit(newData);
    };

    // 3 - Obiectivul de racordat
    const [casnic, setCasnic] = useState<boolean | null>(null);
    const [tipLC, setTipLC] = useState<number | null>(null);
    const [wasDataVerified, setWasDataVerified] = useState(false);
    const [numeLC, setNumeLC] = useState<string>('');
    const [tipAdresa, setTipAdresa] = useState<string>('');
    const [adresaOb, setAdresaOb] = useState(adresaObiectivului);
    const [nrAviz, setNrAviz] = useState('');
    const [dataAviz, setDataAviz] = useState('');
    const [nrCamere, setNrCamere] = useState<number | string>('');
    const [constructieFinalizata, setConstructieFinalizata] = useState<boolean | null>(null);
    const [santier, setSantier] = useState<boolean | null>(null);
    const [precizari, setPrecizari] = useState('');
    const [purposeType, setPurposeType] = useState<any[]>([]);

    const handlePurposeType = (data: any) => {
        setPurposeType([...data]);
    };

    const handleCasnic = (type: boolean) => {
        setCasnic(type);
    };

    const handleTipLC = (type: number) => {
        setTipLC(type);

        let newData = adresaOb;

        if (type === 2) {
            setTipAdresa('');
            
            Object.keys(newData).forEach(k => {
                // @ts-ignore
                newData[k] = '';
            });

            setAdresaOb(newData);
        } else if (type === 1) {
            handleTipAdresa(tipAdresa);
        }

        setWasDataVerified(false);
    };

    const handleNumeLC = (name: string) => {
        setNumeLC(name);
    };

    const handleTipAdresa = (type: string, fromStep?: false) => {
        if (type === 'AU') {
            setAdresaOb({
                Judet: dateIdentificare.Judet,
                Localitate: dateIdentificare.Localitate,
                Comuna: dateIdentificare.Comuna,
                Strada: dateIdentificare.Strada,
                Numar: dateIdentificare.Numar,
                Bloc: dateIdentificare.Bloc,
                Etaj: dateIdentificare.Etaj,
                Scara: dateIdentificare.Scara,
                Ap: dateIdentificare.Ap,
                POD: '',
                cod_loc_consum: '',
                nrCF: adresaOb.nrCF,
                nrCad: adresaOb.nrCad
            });

        } else if (type === 'AA' && fromStep) {
            let adresa = adresaOb;

            Object.keys(adresa).forEach(k => {
                // @ts-ignore
                adresa[k] = '';
            });

            setAdresaOb(adresa);
        }

        setTipAdresa(type);
    };

    const handleNrAviz = (value: string) => {
        setNrAviz(value);
    };

    const handleDataAviz = (value: any, fromResponse?: boolean) => {
        setDataAviz(value);
    };

    const handleWasDataVerified = (value: boolean) => {
        setWasDataVerified(value);
    };

    const handleAdresaOb = (data: any, field: any) => {
        let newData = adresaOb;

        Object.keys(newData).map((key: string, index) => {
            if (key === field) {
                //@ts-ignore
                newData[key] = data;
            }
        });

        setAdresaOb(newData);
    };

    const handleNrCamere = (nr: number) => {
        setNrCamere(nr);
    };

    const handleConstructieFinalizata = (type: boolean) => {
        setConstructieFinalizata(type);
    };

    const handleSantier = (type: boolean) => {
        setSantier(type);
    };

    const handlePrecizari = (data: string) => {
        setPrecizari(data);
    };

    // 4 - Informatii tehnice Loc de Consum
    // casnic
    const [dateTehnice, setDateTehnice] = useState(dateTehniceLC);
    //eslint-disable-next-line
    const [statiiIncarcare, setStatiiIncarcare] = useState(statiiInc);
    const [dateSI, setDateSI] = useState<any>([]);
    const [SI, setSI] = useState<number | null>(null);
    //eslint-disable-next-line
    const [aparateFrecvente, setAparateFrecvente] = useState(aparate_frecvente);
    //eslint-disable-next-line
    const [alteAparate, setAlteAparate] = useState(alte_aparate);
    const [dateAF, setDateAF] = useState<any>(dateAparateFrecvente);
    const [dateAA, setDateAA] = useState<any>([]);
    const [showAF, setShowAF] = useState(false);
    const [showAA, setShowAA] = useState(false);
    const [showDER, setShowDER] = useState(false);

    //noncasnic
    const [modAlimentare, setModAlimentare] = useState('');
    const [dataSolicitata, setDataSolicitata] = useState<string>('');
    const [tipTarif, setTipTarif] = useState<number | null>(null);
    const [tipFactorPutere, setTipFactorPutere] = useState<string | null>('0,85');
    const [factorPutere, setFactorPutere] = useState<string>('0,85');
    const [detaliiFactorPutere, setDetaliiFactorPutere] = useState<string>('');
    const [tipTensiune, setTipTensiune] = useState<string | null>(null);
    const [tensiune, setTensiune] = useState<string>('0');
    const [racordareSantier, setRacordareSantier] = useState<boolean | null>(null);
    const [KWSantier, setKWSantier] = useState<string>('');
    const [KVASantier, setKVASantier] = useState<string>('');
    const [dataSantier, setDataSantier] = useState<string>('');
    const [tipInstalatii, setTipInstalatii] = useState<any[]>(instalatii);
    const [specificulActivitatii, setSpecitficulActivitatii] = useState<any[]>([]);
    const [otherSA, setOtherSA] = useState<string>('');
    const [regimLucru, setRegimLucru] = useState({
        nrSchimburi: '',
        interval: '',
        nrZile: ''
    });
    const [caiAlimentare, setCaiAlimentare] = useState<number | null>(null);
    const [alteCaiAlimentare, setAlteCaiAlimentare] = useState<string>('');
    const [surseDeAlimentare, setSurseDeAlimentare] = useState({
        tip: '',
        putere: '',
        timpPornire: '',
        durataFunc: '',
    });
    const [timpIntrerupere, setTimpIntrerupere] = useState<string>('');
    const [dateLR, setDateLR] = useState<any>([]);
    //eslint-disable-next-line
    const [listaReceptoare, setListaReceptoare] = useState(listaRec);
    const [UE, setUE] = useState<number | null>(null);
    const [dateUE, setDateUE] = useState([]);
    //eslint-disable-next-line
    const [utilizatoriExterni, setUtilizatoriExterni] = useState(utilizatori);
    const [dezvoltare, setDezvoltare] = useState<boolean | null>(null);
    const [etape, setEtape] = useState<number | null>(null);
    //eslint-disable-next-line
    const [detaliiEnergeticeReceptoare, setDetaliiEnergeticeReceptoare] = useState([]);
    const [alteInformatii, setAlteInformatii] = useState('');
    const [KWTotalInstalat, setKWTotalInstalat] = useState(0);
    const [KWAbsorbita, setKWAbsorbita] = useState(0);
    const [AFPower, setAFPower] = useState(0);
    const [dateEtape, setDateEtape] = useState<any>(date_etape);

    const handleRacordareSantier = (type: boolean) => {
        setRacordareSantier(type);
    };

    const handleKWSantier = (kw: string) => {
        setKWSantier(kw);
    };

    const handleKVASantier = (kva: string) => {
        setKVASantier(kva);
    };

    const handleDataSantier = (date: any) => {
        setDataSantier(date);
    };

    const handleDateTehnice = (data: any, field: string, fromAbs?: boolean) => {
        let newData = dateTehnice;

        Object.keys(newData).map((key: string, index) => {
            if (key === field) {
                if (field !== 'seTipRacord' && field !== 'ssTipRacord') {
                    if (data.includes(',')) {
                        data = data.replaceAll(',', '.');
                    }
                }
                //@ts-ignore
                newData[key] = data;
            }
        }); 

        if (Number(newData['seKVA']) > 9.35 && field !== 'seTipRacord' && field !== 'ssTipRacord') {
            newData['seTipRacord'] = 2;
        }

        if (Number(newData['seKVA']) <= 9.35 && field !== 'seTipRacord' && field !== 'ssTipRacord') {
            newData['seTipRacord'] = 1;
        }

        if (tipLC === 2 && Number(newData['ssKVA']) > 9.35 && field !== 'seTipRacord' && field !== 'ssTipRacord') {
            newData['ssTipRacord'] = 2;
        }

        if (tipLC === 2 && Number(newData['ssKVA']) <= 9.35 && field !== 'seTipRacord' && field !== 'ssTipRacord') {
            newData['ssTipRacord'] = 1;
        }

        setDateTehnice({ ...newData });
    };

    const handleSI = (value: any) => {
        setSI(value);
    };

    const handleDateSI = (data: any) => {
        setDateSI(data);
    };

    const handleDateAF = (data: any) => {
        let date = data;

        let power = 0;

        Object.keys(date).map((row: any, idx: number) => {
            date[row]['TotalConsumptionPower'] = (Number(date[row]['ConsumptionPower']) * Number(date[row]['DomesticConsumerCount'])).toFixed(2);

            if (date[row]['Enabled'] === true) {
                power += (Number(date[row]['ConsumptionPower']) * Number(date[row]['DomesticConsumerCount']));
            }
        });

        setAFPower(power);

        setDateAF(data);
    };

    const handleDateAA = (data: any) => {
        let date = data;

        Object.keys(date).map((row: any, idx: number) => {
            date[row]['TotalConsumptionPower'] = Number(date[row]['ConsumptionPower']) * Number(date[row]['DomesticConsumerCount']);
        });

        setDateAA(data);
    };

    useEffect(() => {
        let total = 0;
        let kwabs = 0;

        if (casnic === true) {
            Object.keys(dateAA).map((row: any, idx: number) => {
                total += Number(dateAA[row]['ConsumptionPower']);
            });

            total += AFPower;
            kwabs = 0.25 * total;

            if (SI === 1) {
                Object.keys(dateSI).map((row: any, idx: number) => {
                    total += Number(dateSI[row]['Putere_maxima instalata (kW)']);
                    kwabs += Number(dateSI[row]['Putere_maxima simultan absorbita (kW)']);
                });
            }
        } else if (casnic === false) {
            if (SI === 1) {
                Object.keys(dateSI).map((row: any, idx: number) => {
                    total += Number(dateSI[row]['Putere_maxima instalata (kW)']);
                });
            }

            Object.keys(dateLR).map((row: any, idx: number) => {
                total += Number(dateLR[row]['TotalConsumptionPower']);
            });

            if (UE === 1) {
                Object.keys(dateUE).map((row: any, idx: number) => {
                    total += Number(dateUE[row]['kw_total_instalata_utilizator_extern']);
                });
            }
        }

        let roundedTotal = Math.round((total + Number.EPSILON) * 100) / 100;

        if (isNaN(roundedTotal)) {
            setKWTotalInstalat(total);
        } else {
            setKWTotalInstalat(roundedTotal);
        }

        if (casnic === true) {
            let maxPI = 0;

            dateAF.map((row: any, index: number) => {
                Object.keys(row).map((column: any, idx: number) => {
                  if (row['Enabled'] === true) {
                    if (Number(row['ConsumptionPower']) > maxPI) {
                      maxPI = row['ConsumptionPower'];
                    }
                  }
                });
            });

            dateAA.map((row: any, index: number) => {
                Object.keys(row).map((column: any, idx: number) => {

                  if (Number(row['ConsumptionPower']) > maxPI) {
                    maxPI = row['ConsumptionPower'];
                  }
                });
            });

            if (SI === 1) {
                dateSI.map((row: any, index: number) => {
                    Object.keys(row).map((column: any, idx: number) => {

                    if (Number(row['Putere_maxima instalata (kW)']) > maxPI) {
                        maxPI = row['Putere_maxima instalata (kW)'];
                    }
                    });
                });
            }

            if (kwabs >= maxPI) {
                let roundedTotalAbs = Math.round((kwabs + Number.EPSILON) * 100) / 100;
                setKWAbsorbita(roundedTotalAbs);

                if (isNaN(roundedTotalAbs)) {
                    setKWAbsorbita(kwabs);
                } else {
                    setKWAbsorbita(roundedTotalAbs);
                }
            } else {
                let roundedTotalAbs = Math.round((maxPI + Number.EPSILON) * 100) / 100;

                if (isNaN(roundedTotalAbs) || roundedTotalAbs === 0) {
                    setKWAbsorbita(maxPI);
                } else {
                    setKWAbsorbita(roundedTotalAbs);
                }
            }
        }
    }, [casnic, dateAA, AFPower, SI, dateSI, dateLR, dateUE, UE]);

    const handleKWTotalInstalat = (number: number) => {
    };

    const handleKWAbsorbit = (number: number) => {
    };

    const handleModAlimentare = (value: string) => {
        setModAlimentare(value);
    };

    const handleDataSolicitata = (value: any) => {
        setDataSolicitata(value);
    };

    const handleTipTarif = (value: number) => {
        setTipTarif(value);
    };

    const handleFactorPutere = (value: string) => {
        setFactorPutere(value);
    };

    const handleTipFactorPutere = (value: string) => {
        value !== '' && handleFactorPutere(value);
        value === '' && handleFactorPutere('0');
        value === '0,9' && handleFactorPutere('0,9');

        setTipFactorPutere(value);
    };

    const handleDetaliiFactorPutere = (data: string) => {
        setDetaliiFactorPutere(data);
    };

    const handleTensiune = (value: string) => {
        setTensiune(value);
    };

    const handleTipTensiune = (value: string) => {
        value !== '' && setTensiune(value);
        value === '' && setTensiune('0');
        setTipTensiune(value);
    };

    const handleTipInstalatii = (data: any) => {
        setTipInstalatii([...data]);
    };

    const handleSpecificulActivitatii = (data: any) => {
        setSpecitficulActivitatii([...data]);
    };

    const handleOtherSA = (value: string) => {
        setOtherSA(value);
    };

    const handleRegimLucru = (data: string, field: string) => {
        let newData = regimLucru;

        Object.keys(newData).map((key: string, index) => {
            if (key === field) {
                //@ts-ignore
                newData[key] = data;
            }
        });

        setRegimLucru({ ...newData });
    };

    const handleCaiAlimentare = (type: number) => {
        setCaiAlimentare(type);
    };

    const handleAlteCaiAlimentare = (data: string) => {
        setAlteCaiAlimentare(data);
    };

    const handleSurseDeAlimentare = (data: string, field: string) => {
        let newData = surseDeAlimentare;

        Object.keys(newData).map((key: string, index) => {
            if (key === field) {
                //@ts-ignore
                newData[key] = data;
            }
        });

        setSurseDeAlimentare({ ...newData });
    };

    const handleTimpIntrerupere = (value: string) => {
        setTimpIntrerupere(value);
    };

    const handleDateLR = (data: any) => {
        let date = data;

        Object.keys(date).map((row: any, idx: number) => {
            date[row]['TotalConsumptionPower'] = (Number(date[row]['Putere_instalata']) * Number(date[row]['numar_receptoare'])).toFixed(2);
        });

        setDateLR(date);
    };

    const handleUE = (value: any) => {
        setUE(value);
    };

    const handleDateUE = (data: any) => {
        setDateUE(data);
    };

    const handleDezvoltare = (type: boolean) => {
        setDezvoltare(type);
    };

    const handleEtape = (number: number) => {
        setEtape(number);
    };

    const handleDateEtape = (field: string, index: number, data: any) => {
        let newData = dateEtape;

        newData.map((item: string, idx: number) => {
            if (idx === index) {
                //@ts-ignore
                item[field] = data;
            }
        });

        setDateEtape([...newData]);
    };

    const handleDateDER = (index: number, key: string, data: any) => {
        let newData = detaliiEnergeticeReceptoare;

        newData.map((item: any, idx: number) => {
            if (idx === index) {
                //@ts-ignore
                item[key] = data;
            }
        });

        setDetaliiEnergeticeReceptoare([...newData]);
    };

    const handleAlteInformatii = (data: string) => {
        setAlteInformatii(data);
    };

    const renderInformatiiTehnice = () => {
        if (casnic) {
            return renderCasnic();
        } else {
            return renderNoncasnic();
        }
    };

    const renderCasnic = () => {
        return <LCCasnic
            isEditing={isEditing}
            requestId={requestId}
            setStep={handleStepChange}
            currentStep={currentStep}
            progress={progress}
            setProgress={handleProgress}
            tipLC={tipLC}
            dateTehnice={dateTehnice}
            handleDateTehnice={handleDateTehnice}
            SI={SI}
            handleSI={handleSI}
            statiiIncarcare={statiiIncarcare}
            handleDateSI={handleDateSI}
            dateSI={dateSI}
            aparateFrecvente={aparateFrecvente}
            alteAparate={alteAparate}
            dateAF={dateAF}
            dateAA={dateAA}
            handleDateAF={handleDateAF}
            handleDateAA={handleDateAA}
            KWTotalInstalat={KWTotalInstalat}
            handleKWTotalInstalat={handleKWTotalInstalat}
            KWAbsorbit={KWAbsorbita}
            showAF={showAF}
            showAA={showAA}
            setShowAF={(value: boolean) => setShowAF(value)}
            setShowAA={(value: boolean) => setShowAA(value)}
            handleKWAbsorbit={handleKWAbsorbit}
            santier={santier}
        />;
    };

    const renderNoncasnic = () => {
        return <LCNoncasnic
            isEditing={isEditing}
            requestId={requestId}
            setStep={handleStepChange}
            currentStep={currentStep}
            progress={progress}
            setProgress={handleProgress}
            casnic={casnic}
            tipLC={tipLC}
            dateTehnice={dateTehnice}
            handleDateTehnice={handleDateTehnice}
            SI={SI}
            handleSI={handleSI}
            statiiIncarcare={statiiIncarcare}
            handleDateSI={handleDateSI}
            dateSI={dateSI}
            modAlimentare={modAlimentare}
            handleModAlimentare={handleModAlimentare}
            dataSolicitata={dataSolicitata}
            handleDataSolicitata={handleDataSolicitata}
            tipFactorPutere={tipFactorPutere}
            handleTipFactorPutere={handleTipFactorPutere}
            factorPutere={factorPutere}
            handleFactorPutere={handleFactorPutere}
            detaliiFactorPutere={detaliiFactorPutere}
            handleDetaliiFactorPutere={handleDetaliiFactorPutere}
            tensiune={tensiune}
            handleTensiune={handleTensiune}
            tipTensiune={tipTensiune}
            handleTipTensiune={handleTipTensiune}
            tipInstalatii={tipInstalatii}
            handleTipInstalatii={handleTipInstalatii}
            specificulActivitatii={specificulActivitatii}
            handleSpecificulActivitatii={handleSpecificulActivitatii}
            otherSA={otherSA}
            handleOtherSA={handleOtherSA}
            regimLucru={regimLucru}
            handleRegimLucru={handleRegimLucru}
            surseDeAlimentare={surseDeAlimentare}
            handleSurseDeAlimentare={handleSurseDeAlimentare}
            timpIntrerupere={timpIntrerupere}
            handleTimpIntrerupere={handleTimpIntrerupere}
            listaReceptoare={listaReceptoare}
            handleDateLR={handleDateLR}
            dateLR={dateLR}
            UE={UE}
            handleUE={handleUE}
            dateUE={dateUE}
            utilizatoriExterni={utilizatoriExterni}
            handleDateUE={handleDateUE}
            dezvoltare={dezvoltare}
            handleDezvoltare={handleDezvoltare}
            etape={etape}
            handleEtape={handleEtape}
            detaliiEnergeticeReceptoare={detaliiEnergeticeReceptoare}
            handleDateDER={handleDateDER}
            alteInformatii={alteInformatii}
            handleAlteInformatii={handleAlteInformatii}
            aparateFrecvente={aparateFrecvente}
            alteAparate={alteAparate}
            dateAF={dateAF}
            dateAA={dateAA}
            handleDateAF={handleDateAF}
            handleDateAA={handleDateAA}
            tipTarif={tipTarif}
            handleTipTarif={handleTipTarif}
            caiAlimentare={caiAlimentare}
            handleCaiAlimentare={handleCaiAlimentare}
            alteCaiAlimentare={alteCaiAlimentare}
            handleAlteCaiAlimentare={handleAlteCaiAlimentare}
            racordareSantier={racordareSantier}
            KWSantier={KWSantier}
            KVASantier={KVASantier}
            dataSantier={dataSantier}
            handleRacordareSantier={handleRacordareSantier}
            handleKWSantier={handleKWSantier}
            handleKVASantier={handleKVASantier}
            handleDataSantier={handleDataSantier}
            KWTotalInstalat={KWTotalInstalat}
            handleKWTotalInstalat={handleKWTotalInstalat}
            KWAbsorbit={KWAbsorbita}
            handleKWAbsorbit={handleKWAbsorbit}
            dateEtape={dateEtape}
            handleDateEtape={handleDateEtape}
            showDER={showDER}
            handleShowDER={(value: boolean) => setShowDER(value)}
        />;
    };

    // 5 - Canale de comunicare
    const [raspuns, setRaspuns] = useState<any[]>([]);

    const handleRaspuns = (data: any) => {
        setRaspuns([...data]);
    };

    // 6 - Documente Anexate
    const [files, setFiles] = useState<any[]>([]);
    const [wereFilesMounted, setWereFilesMounted] = useState<boolean>(false);
    const [uploadedFiles, setUploadedFiles] = useState<File[]>([] as File[]);
    const [alreadyUploaded, setAlreadyUploaded] = useState<any[]>([]);

    const handleSetFiles = (files: File[]) => {
        let addEmptyFiles: any = [];

        files.map((f: any, i: number) => {
            addEmptyFiles.push([]);
        });

        setUploadedFiles(addEmptyFiles);
        setFiles(files);
    };

    const handleShowExtraFiles = (fileName: string) => {
        let newArray: any = [];
        let newItem: any = null;
        let index = null;

        files.map((f: any, i: number) => {
            let item = f;

            if (item.name === fileName) {
                newItem = item;
                index = i;
            }

            newArray.push(item);
        });

        newItem.show = true;
        newArray.splice(index, 1, newItem);

        setFiles(newArray);
    };

    const handleWereFilesMounted = (value: boolean) => {
        setWereFilesMounted(value);
    };

    const handleInitalFilesToUpload = (files: File[]) => {
        setUploadedFiles(files);
    };

    const uploadFile = (fileArr: File[], index: number, isMounting: boolean) => {
        console.log('fileArr(upload): ', fileArr);
        let newData = uploadedFiles;

        newData.map((f: File, idx: number) => {
            if (idx === index) {
                newData[idx] = fileArr;
            }
        });
        
        setUploadedFiles([...newData]);
    };

    // 7 - Informare finala
    const [checkedOne, setCheckedOne] = useState(false);
    const [checkedTwo, setCheckedTwo] = useState(false);
    const [checkedThree, setCheckedThree] = useState(false);
    const [checkedFour, setCheckedFour] = useState(false);
    const [checkedFive, setCheckedFive] = useState(false);

    const handleCheckedOne = (value: boolean) => {
        setCheckedOne(value);
    };

    const handleCheckedTwo = (value: boolean) => {
        setCheckedTwo(value);
    };

    const handleCheckedThree = (value: boolean) => {
        setCheckedThree(value);
    };

    const handleCheckedFour = (value: boolean) => {
        setCheckedFour(value);
    };

    const handleCheckedFive = (value: boolean) => {
        setCheckedFive(value);
    };

    const stepOneGET = async (payload: any, step: number) => {
        dispatch(toggleLoader(true));

        await getStepOne(payload)
            .then((response) => {
                if (response.cerere_finalizata === true) {
                    setIsFinalized(true);
                }
                
                let date = response.DateIdentificare;
                let adresa = response.DateIdentificare.Adresa;

                if (response.reprezentant === false) {
                    stepsArrayIConnectEE.splice(1, 1);
                    setSteps(stepsArrayIConnectEE);

                    if (step === 8) {
                        setCurrentStep(0);
                        setProgress(step - 2);
                    } else if (step !== 1) {
                        setCurrentStep(step - 2);
                        setProgress(step - 2);
                    } else if (step === 1) {
                        setCurrentStep(0);
                        setProgress(0);
                    }

                    setStartingModalOption(1);
                } else {
                    getUserDetails()
                        .then((response) => {
                            const url = new URL(window.location.href);

                            const params = url.search;
                            const searchParams = new URLSearchParams(params);

                            if (jwt.groups.includes('ClientFizic') && searchParams.has('step') && searchParams.get('step') !== 'Pas8') {
                                handleImputernicit(response.contactFirstName, 'Prenume');
                                handleImputernicit(response.contactLastName, 'Nume');
                                handleImputernicit(response.email, 'Email');
                            } else if (jwt.groups.includes('ClientJuridic') && searchParams.has('step') && searchParams.get('step') !== 'Pas8') {
                                handleImputernicit(response.companyName, 'Denumire');
                                handleImputernicit(response.cui, 'CUI');
                                handleImputernicit(response.orc, 'NrReg');
                                handleImputernicit(response.email, 'Email');
                            }
                        })
                        .catch((err) => console.log(err));

                    setSteps(stepsArrayIConnectEE);

                    if (step === 8) {
                        setCurrentStep(0);
                        setProgress(step - 1);
                    } else if (step !== 1) {
                        setCurrentStep(step - 1);
                        setProgress(step - 1);
                    } else if (step === 1) {
                        setCurrentStep(0);
                        setProgress(0);
                    }

                    setStartingModalOption(2);
                }

                delete date['Adresa'];

                if (response.AdresaCorespondenta !== null) {
                    if (response.TipCorespondenta === 'AA') {
                        setAdress(response.AdresaCorespondenta);
                    }
                }

                setAdressType(response.TipCorespondenta);

                let intl_phone = '';

                if (date.Telefon[0] === '0' && date.Telefon[1] === '7') {
                    intl_phone = '+4' + date.Telefon;
                } else {
                    intl_phone = date.Telefon;
                }

                let dateID = {
                    Nume: date.Nume !== null ? date.Nume : '',
                    Prenume: date.Prenume !== null ? date.Prenume : '',
                    CNP: date.CNP !== null ? date.CNP : '',
                    CUI: date.CUI !== null ? date.CUI : '',
                    Denumire: date.Denumire !== null ? date.Denumire : '',
                    NrReg: date.NrReg !== null ? date.NrReg : '',
                    Telefon: date.Telefon !== null ? intl_phone : '',
                    Email: date.Email !== null ? date.Email : '',
                    Cont: date.Cont !== null ? date.Cont : '',
                    Banca: date.Banca !== null ? date.Banca : '',
                    Reprezentant: date.Reprezentant !== null ? date.Reprezentant : '',
                    InCalitateDe: date.InCalitateDe !== null ? date.InCalitateDe : '',
                    SerieCI: date.SerieCI !== null ? date.SerieCI : '',
                    NumarCI: date.NumarCI !== null ? date.NumarCI : '',
                    CIEliberatDe: date.CIEliberatDe !== null ? date.CIEliberatDe : '',
                    CIData: date.CIData !== null ? date.CIData : '',
                    CodCAEN: date.CodCAEN !== null ? date.CodCAEN : ''
                };

                Object.assign(dateID, adresa);
                setDateIdentificare(dateID);
                setClientType(response.TipClient === 'PF' ? '1' : '2');
                setDateConsultant(response.consultant);
            })
            .catch((error) => {
                if (error === 'Request does not exists') {
                    setNoRequest(true);
                }
            });

        if (step === 1) {
            setShowStartingModal(false);
        }

        dispatch(toggleLoader(false));
    };

    const stepTwoGET = async (payload: any, step: number) => {
        dispatch(toggleLoader(true));

        await getStepTwo(payload)
            .then((response) => {
                let date = response.Imputernicit;
                let intl_phone = '';
                
                if (date.Telefon[0] === '0' && date.Telefon[1] === '7') {
                    intl_phone = '+4' + date.Telefon;
                } else {
                    intl_phone = date.Telefon;
                }

                date = { ...date, Telefon: intl_phone };
                let adresa = response.Imputernicit.Adresa;

                delete date['Adresa'];
                Object.assign(date, adresa);

                if (date.CIData === null) {
                    date.CIData = '';
                };

                setImputernicit(date);
                setAdressType(response.TipCorespondenta);
                setTipImputernicit(response.TipImputernicit);
            })
            .catch((error) => {
                if ((error && error.StatusCode && error.StatusCode === 500) || (error && error.status && error.status === 204)) {
                    setImputernicit(null);
                }
            });

        if (step === 2) {
            setShowStartingModal(false);
        }

        dispatch(toggleLoader(false));
    };

    const stepThreeGET = async (payload: any, step: any) => {
        dispatch(toggleLoader(true));

        await getStepThree(payload)
            .then((response) => {
                if (response.Adresa !== null) {
                    let adr = response.Adresa;
                    adr = { ...adr, nrCF: response.nrCF };
                    adr = { ...adr, nrCad: response.nrCad };
                    adr = { ...adr, POD: response.POD === null ? '' : response.POD };
                    adr = { ...adr, cod_loc_consum: response.cod_loc_consum };

                    setAdresaOb(adr);
                    setSantier(response.santier);
                } else {
                    getStepOne(payload).then((res) => {
                        let adresaObiectiv = {
                            Judet: res.DateIdentificare.Adresa.Judet,
                            Localitate: res.DateIdentificare.Adresa.Localitate,
                            Comuna: res.DateIdentificare.Adresa.Comuna,
                            Strada: res.DateIdentificare.Adresa.Strada,
                            Numar: res.DateIdentificare.Adresa.Numar,
                            Bloc: res.DateIdentificare.Adresa.Bloc,
                            Etaj: res.DateIdentificare.Adresa.Etaj,
                            Scara: res.DateIdentificare.Adresa.Scara,
                            Ap: res.DateIdentificare.Adresa.Ap,
                            nrCF: response.nrCF,
                            nrCad: response.nrCad,
                            POD: '',
                            cod_loc_consum: '',
                        };

                        setAdresaOb(adresaObiectiv);
                    });
                }

                if (response.POD !== null) {
                    setWasDataVerified(true);
                    setNrAviz(response.nr_aviz);
                    setDataAviz(response.data_aviz !== null ? response.data_aviz : '');
                }

                setNumeLC(response.nume_loc_consum);
                setTipLC(response.TipLC);
                setCasnic(response.consum_casnic);
                setTipAdresa(response.TipAdresa);
                setNrCamere(response.nr_camere);
                setConstructieFinalizata(response.constructie_finalizata);
                setSantier(response.santier);
                setPrecizari(response.alte_precizari);

                getTypes(['PurposeType'], 'EE').then((res) => {
                    let data = res.PurposeType;

                    data.map((el: any, i: number) => {
                      el.active = false;

                      response.scopul_cererii.map((type: any, idx: number) => {
                        if (el.id === type.purposeTypeId) {
                          el.active = true;
    
                          if (type.purposeTypeId === 5) {
                            setPrecizari(type.purposeType);
                          }
                        }
                      });
                    });

                    setPurposeType(data);
    
                    if (step === 3) {
                        setShowStartingModal(false);
                    }
                  });
            })
            .catch((error) => console.log(error));

        dispatch(toggleLoader(false));
    };

    const checkRecDetails = (details: any) => {
        let hasData = false;

        details.map((item: any) => {
            if (item.an1 !== '' || item.an2 !== '' || item.an3 !== '' || item.an4 || '' || item.an5 !== '' || 
            item.situatie_curenta !== '' || item.situatie_finala !== '') {
                hasData = true;
            }
        });

        return hasData;
    };

    const stepFourGET = async (payload: any, step: any) => {
        dispatch(toggleLoader(true));

        await getStepFour(payload)
            .then((response) => {
              let date = {
                seKW: response.date_tehnice_old.KW !== null ? '' + response.date_tehnice_old.KW : '',
                seKVA: response.date_tehnice_old.KVA !== null ? '' + response.date_tehnice_old.KVA : '',
                ssKW: response.date_tehnice_new.KW !== null ? '' + response.date_tehnice_new.KW : '',
                ssKVA: response.date_tehnice_new.KVA !== null ? '' + response.date_tehnice_new.KVA : '',
                seTipRacord: response.date_tehnice_old.TipRacord,
                ssTipRacord: response.date_tehnice_new.TipRacord
              };

              setDataSolicitata(response.data_solicitata);
              setModAlimentare(response.mod_alimentare || '');

              setDateTehnice(date);
              setTipTarif(response.tip_tarif);

              let fp = response.factor_putere !== null ? response.factor_putere : null;

              fp = fp.toString();

              if (response.factor_putere === 0.85) {
                setTipFactorPutere('0,85');
              } else {
                setTipFactorPutere('');
                setDetaliiFactorPutere(response.detalii_factor_putere);
              }

              setFactorPutere(fp);

              if (
                response.tensiune === 0.23 ||
                response.tensiune === 0.4 ||
                response.tensiune === 6 ||
                response.tensiune === 20 ||
                response.tensiune === 110
              ) {
                setTipTensiune('' + response.tensiune);
                setTensiune('' + response.tensiune);
              } else {
                setTipTensiune('');
                setTensiune('' + response.tensiune);
              }

              setRacordareSantier(response.santier);
              setKVASantier(response.kw_maxim_absorbita_santier || '');
              setKWSantier(response.kw_total_instalata_santier || '');

              let regim = {
                nrSchimburi: response.regim_lucru.nr_schimburi === null ? '' : '' + response.regim_lucru.nr_schimburi,
                interval: response.regim_lucru.interval_orar === null ? '' : '' + response.regim_lucru.interval_orar,
                nrZile: response.regim_lucru.nr_zile === null ? '' : '' + response.regim_lucru.nr_zile
              };
              setRegimLucru(regim);

              setDataSantier(response.santier_data_valabilitate);
              setTimpIntrerupere(response.timp_max_intrerupere);
              setCaiAlimentare(response.nr_cai_alimentare);

              if (response.nr_cai_alimentare !== 1 && response.nr_cai_alimentare !== 2) {
                setCaiAlimentare(3);
                setAlteCaiAlimentare(response.nr_cai_alimentare);
              }

              setSI(response.statie_masina_electrica === false ? 2 : 1);

              let count = 0;
              if (response.consumatori_predefiniti && response.consumatori_predefiniti.length > 0) {
                dateAparateFrecvente.map((aparat: any, index: number) => {
                  response.consumatori_predefiniti.map((cons: any, idx: number) => {
                    if (aparat.DomesticConsumerName === cons.DomesticConsumerName) {
                      aparat.Enabled = true;
                      aparat.ConsumptionPower = cons.ConsumptionPower;
                      aparat.DomesticConsumerCount = cons.DomesticConsumerCount;
                      aparat.TotalConsumptionPower = cons.TotalConsumptionPower;
                      count += 1;
                    }
                  });
                });
              }

              count > 0 && setShowAF(true);

              let cons: any[] = [];
              if (response.consumatori && response.consumatori.length > 0) {
                response.consumatori.map((item: any, index: number) => {
                  if (item.DomesticConsumerName !== null) {
                    let add = {
                      DomesticConsumerName: item.DomesticConsumerName,
                      ConsumptionPower: item.ConsumptionPower,
                      DomesticConsumerCount: item.DomesticConsumerCount,
                      tip_receptor: item.tip_receptor === 1 ? 'Monofazat' : 'Trifazat',
                      TotalConsumptionPower: item.TotalConsumptionPower
                    };

                    cons.push(add);
                  }
                });
              }

              setDateAA(cons);
              cons.length > 0 && setShowAA(true);

              let statii_inc = response.statii_incarcare;
              let delete_at_index = null;

              statii_inc.map((row: any, index: number) => {
                Object.keys(row).map((key, idx) => {
                  if (row[key] === null) {
                    row[key] = '';
                  } else if (key === 'IsTotalLine') {
                    if (row[key] === true) {
                      delete_at_index = index;
                    }
                  } else if (key === 'Sursa_de finantare') {
                    if (row[key] === 1) {
                      row[key] = 'FORTE PROPRII';
                    } else if (row[key] === 2) {
                      row[key] = 'AFM2021-UAT SI INSTIT.PUBLICE';
                    } else if (row[key] === 3) {
                      row[key] = 'ELECTRIC UP';
                    }
                  } else if (key === 'Acces_statie (public / privat)') {
                    if (row[key] === 1) {
                      row[key] = 'privat';
                    } else if (row[key] === 2) {
                      row[key] = 'public';
                    }
                  } else if (key === 'Tip_statie (lenta/rapida)') {
                    if (row[key] === 1) {
                      row[key] = 'lenta';
                    } else if (row[key] === 2) {
                      row[key] = 'rapida';
                    }
                  }
                });
              });

              statii_inc.splice(delete_at_index, 1);
              statii_inc.forEach(function (v: any) {
                delete v.requestChargingStationId;
              });
              statii_inc.forEach(function (v: any) {
                delete v.IsTotalLine;
              });
              setDateSI(statii_inc);

              if (casnic) {
                let lista_rec = response.lista_receptoare;
                let delete_at_index_lr = null;

                lista_rec.map((row: any, index: number) => {
                  Object.keys(row).map((key, idx) => {
                    if (key === 'IsTotalLine') {
                      if (row[key] === true) {
                        delete_at_index_lr = index;
                      }
                    }
                  });
                });

                lista_rec.splice(delete_at_index_lr, 1);
                lista_rec.forEach(function (v: any) {
                  delete v.requestReceiverId;
                });
                lista_rec.forEach(function (v: any) {
                  delete v.IsTotalLine;
                });

                setDateLR(lista_rec);
              } else {
                let lista: any[] = [];

                response.lista_receptoare.map((item: any, index: number) => {
                  if (item.IsTotalLine === false) {
                    let newItem = {
                      Denumire: item.Denumire,
                      Putere_instalata: item.Putere_instalata,
                      numar_receptoare: item.numar_receptoare,
                      TotalConsumptionPower: Number(item['Putere_instalata']) * Number(item['numar_receptoare']),
                      Observatii: item.Observatii
                    };

                    lista.push(newItem);
                  }
                });

                setDateLR(lista);
              }

              let surse = {
                tip: response.surse_alimentare.tip === null ? '' : '' + response.surse_alimentare.tip,
                putere: response.surse_alimentare.putere === null ? '' : '' + response.surse_alimentare.putere,
                timpPornire: response.surse_alimentare.timp === null ? '' : '' + response.surse_alimentare.timp,
                durataFunc: response.surse_alimentare.durata === null ? '' : '' + response.surse_alimentare.durata
              };

              setUE(response.alimentare_utilizatori_externi === true ? 1 : 2);

              let externi = response.utilizatori_externi;
              externi.forEach(function (v: any) {
                delete v.Id;
              });
              setDateUE(externi);

              setDezvoltare(response.dezvoltare_in_etape);
              setEtape(response.numar_etape || '');

              if (response.lista_etape && response.lista_etape.length > 0) {
                let newData = dateEtape;

                for (let i = 0; i < response.lista_etape.length; i++) {
                  let updated = {
                    an: response.lista_etape[i].an || '',
                    puterea_totala: response.lista_etape[i].puterea_totala || '',
                    puterea_max_absorbita: response.lista_etape[i].puterea_max_absorbita || ''
                  };

                  newData[i] = updated;
                }

                setDateEtape(newData);
              }

              setSurseDeAlimentare(surse);
              setAlteInformatii(response.cerinte_specifice);

              let tipInst = instalatii;

              instalatii.map((tip: any, index: number) => {
                let found = false;

                response.tip_instalatii.map((type: any, idx: number) => {
                  if (tip.id === type.tip_instalatie) {
                    found = true;
                  }
                });

                tip.active = found;
              });

              setTipInstalatii(tipInst);

              let receptoare = response.detalii_energetice_receptoare;
              receptoare.forEach(function (v: any) {
                delete v.Id;
              });

              setDetaliiEnergeticeReceptoare(receptoare);
              setShowDER(checkRecDetails(receptoare));

              getTypes(['ActivityType']).then((res) => {
                let data = res.ActivityType;
                data.map((el: any, i: number) => {
                  el.active = false;
                  response.specificul_activitatii.map((type: any, idx: number) => {
                    if (el.id === type.activityTypeId) {
                      el.active = true;

                      if (type.activityTypeId === 6) {
                        setOtherSA(type.activityType);
                      }
                    }
                  });
                });
                setSpecitficulActivitatii(data);

                if (step === 4) {
                  setShowStartingModal(false);
                }
              });
            })
            .catch((err) => console.log(err));

        dispatch(toggleLoader(false));
    };

    const stepFiveGET = async (payload: any, step: any) => {
        dispatch(toggleLoader(true));

        await getStepFive(payload)
            .then((response) => {
                getTypes(['ClientNotificationType']).then((res) => {
                    let data = res.clientnotificationtype;

                    data.map((el: any, i: number) => {
                        el.active = false;
                        response.tip_comunicare.map((type: any, idx: number) => {
                            if (el.id === type.id_comunicare) {
                                el.active = true;
                            }
                        });
                    });
                    setRaspuns(data);

                    if (step === 5) {
                        setShowStartingModal(false);
                    }
            });
        })
        .catch((err) => console.log(err));

        dispatch(toggleLoader(false));
    };

    const stepSixGET = async (payload: any, step: any) => {
        dispatch(toggleLoader(true));

        await getStepSix(payload)
            .then((response) => {
                setAlreadyUploaded(response.requestDocuments);
            });

        if (step === 6) {
            setShowStartingModal(false);
        }

        dispatch(toggleLoader(false));
    };

    const stepSevenGET = async (payload: any, step: any) => {
        dispatch(toggleLoader(true));

        await getStepSeven(payload)
            .then((response) => {
                setCheckedOne(response.Acord1);
                setCheckedTwo(response.Acord2);
                setCheckedThree(response.Acord3);
                setCheckedFour(response.Acord_GDPR);
                setCheckedFive(response.Acord_DateValide);
            });

        if (step === 7) {
            setShowStartingModal(false);
        }

        dispatch(toggleLoader(false));
    };

    const reloadAlreadyUploadedDocuments = async () => {
        dispatch(toggleLoader(true));

        await getStepSix({ requestId: requestId })
            .then((response) => {
                setAlreadyUploaded(response.requestDocuments);
            });

        dispatch(toggleLoader(false));
    };

    const initiateRequest = () => {
        // const url = new URL('http://localhost:3000/cerere-prosumatori?requestId=3053&step=Pas7&edit=true');
        const url = new URL(window.location.href);
        const params = url.search;
        const searchParams = new URLSearchParams(params);

        if (searchParams.has('requestId')) {
            let req_Id = searchParams.get('requestId');
            let stepProgress = searchParams.get('step');
            let edit = searchParams.get('edit');

            if (stepProgress) {
                stepProgress = stepProgress.slice(3);
            }

            let p = Number(stepProgress);
            let reqId = Number(req_Id);

            setIsEditing(edit === 'true');
            setRequestId(reqId);

            p >= 1 && stepOneGET({ requestId: reqId }, p);

            setCurrentStep(0);
            setProgress(0);

            p >= 2 && stepTwoGET({ requestId: reqId }, p);
            p >= 3 && stepThreeGET({ requestId: reqId }, p);
            p >= 4 && stepFourGET({ requestId: reqId }, p);
            p >= 5 && stepFiveGET({ requestId: reqId }, p);
            p >= 6 && stepSixGET({ requestId: reqId }, p);
            p >= 7 && stepSevenGET({ requestId: reqId }, p);

            if (p === 8) {
                setShowStartingModal(false);
            }

            setRequestId(reqId);

            // setShowStartingModal(false);
        }

        setInitiated(true);
    };

    useEffect(() => {
        if (initiated === false) {
            let detaliiRec: any = detaliiEnergeticeReceptoare;

            for (let i = 1; i <= 23; i++) {
                let item = {
                    detaliu_energetic_id: i,
                    situatie_curenta: '',
                    an1: '',
                    an2: '',
                    an3: '',
                    an4: '',
                    an5: '',
                    situatie_finala: '',
                    observatii: '',
                    putere_instalata_absorbita: 0
                };
                
                detaliiRec.push(item);
            }
    
            setDetaliiEnergeticeReceptoare(detaliiRec);
            
            initiateRequest();
        }
    }, []);

    const renderSteps = () => {
        return <Fragment>
            <Steps steps={steps} currentStep={currentStep} changeStep={handleStepChange} progress={progress} isEditing={isEditing} />
            {currentStep === 0 &&
                <ClientFinal
                    modalOption={startingModalOption}
                    isEditing={isEditing}
                    requestId={requestId}
                    handleRequestId={handleRequestId}
                    startingModalOption={startingModalOption}
                    handleFormData={handleDateIdentificare}
                    formData={dateIdentificare}
                    setStep={handleStepChange}
                    currentStep={currentStep}
                    progress={progress}
                    setProgress={handleProgress}
                    clientType={clientType}
                    handleClientType={handleClientType}
                    adressType={adressType}
                    handleAdressType={handleAdressType}
                    adressData={adress}
                    handleAdressData={handleAdressData}
                    dateConsultant={dateConsultant}
                    handleDateConsultant={handleDateConsultant}
                />
            }
            {(currentStep === 1 && startingModalOption === 2) &&
                <ReprezentantLegal
                    isEditing={isEditing}
                    requestId={requestId}
                    startingModalOption={startingModalOption}
                    tipImputernicit={tipImputernicit}
                    handleTipImputernicit={handleTipImputernicit}
                    handleFormData={handleImputernicit}
                    formData={imputernicit}
                    setStep={handleStepChange}
                    currentStep={currentStep}
                    progress={progress}
                    setProgress={handleProgress}
                    adressData={adress}
                    handleAdressData={handleAdressData}
                    adressType={adressType}
                    handleAdressType={handleAdressType}
                    dateId={dateIdentificare}
                />
            }
            {((currentStep === 2 && startingModalOption === 2) || (currentStep === 1 && startingModalOption === 1)) &&
                <ObiectivulDeRacordat
                    isEditing={isEditing}
                    requestId={requestId}
                    setStep={handleStepChange}
                    currentStep={currentStep}
                    progress={progress}
                    setProgress={handleProgress}
                    casnic={casnic}
                    handleCasnic={handleCasnic}
                    tipLC={tipLC}
                    handleTipLC={handleTipLC}
                    wasDataVerified={wasDataVerified}
                    handleWasDataVerified={handleWasDataVerified}
                    numeLC={numeLC}
                    handleNumeLC={handleNumeLC}
                    tipAdresa={tipAdresa}
                    handleTipAdresa={handleTipAdresa}
                    adresaOb={adresaOb}
                    handleAdresaOb={handleAdresaOb}
                    nrAviz={nrAviz}
                    handleNrAviz={handleNrAviz}
                    dataAviz={dataAviz}
                    handleDataAviz={handleDataAviz}
                    nrCamere={nrCamere}
                    handleNrCamere={handleNrCamere}
                    constructieFinalizata={constructieFinalizata}
                    handleConstructieFinalizata={handleConstructieFinalizata}
                    santier={santier}
                    handleSantier={handleSantier}
                    dateIdentificare={dateIdentificare}
                    precizari={precizari}
                    handlePrecizari={handlePrecizari}
                    purposeType={purposeType}
                    handlePurposeType={handlePurposeType}
                />
            }
            {((currentStep === 3 && startingModalOption === 2) || (currentStep === 2 && startingModalOption === 1)) && renderInformatiiTehnice()}

            {((currentStep === 4 && startingModalOption === 2) || (currentStep === 3 && startingModalOption === 1)) &&
                <CanaleDeComunicare
                    isEditing={isEditing}
                    requestId={requestId}
                    setStep={handleStepChange}
                    currentStep={currentStep}
                    progress={progress}
                    setProgress={handleProgress}
                    raspuns={raspuns}
                    handleRaspuns={handleRaspuns}
                    dateIdentificare={dateIdentificare}
                    imputernicit={imputernicit}
                />
            }

            {((currentStep === 5 && startingModalOption === 2) || (currentStep === 4 && startingModalOption === 1)) &&
                <DocumenteAnexate
                    isEditing={isEditing}
                    requestId={requestId}
                    setStep={handleStepChange}
                    currentStep={currentStep}
                    progress={progress}
                    setProgress={handleProgress}
                    files={files}
                    handleFiles={handleSetFiles}
                    wereFilesMounted={wereFilesMounted}
                    handleWereFilesMounted={handleWereFilesMounted}
                    uploadedFiles={uploadedFiles}
                    handleInitalFilesToUpload={handleInitalFilesToUpload}
                    uploadFile={uploadFile}
                    alreadyUploaded={alreadyUploaded}
                    handleShowExtraFiles={handleShowExtraFiles}
                    reloadAlreadyUploadedDocuments={reloadAlreadyUploadedDocuments}
                />
            }

            {((currentStep === 6 && startingModalOption === 2) || (currentStep === 5 && startingModalOption === 1)) &&
                <InformareFinala
                    isEditing={isEditing}
                    requestId={requestId}
                    checkedOne={checkedOne}
                    checkedTwo={checkedTwo}
                    checkedThree={checkedThree}
                    checkedFour={checkedFour}
                    checkedFive={checkedFive}
                    handleCheckOne={handleCheckedOne}
                    handleCheckTwo={handleCheckedTwo}
                    handleCheckThree={handleCheckedThree}
                    handleCheckFour={handleCheckedFour}
                    handleCheckFive={handleCheckedFive}
                    setStep={handleStepChange}
                    currentStep={currentStep}
                    casnic={casnic}
                />
            }
        </Fragment>;
    };

    const renderModal = () => {
        return <ChoiceModal
            showModal={showStartingModal}
            modalTitle={modalData.title}
            options={modalData.options}
            onClose={handleCloseModal}
            onHide={() => window.location.href = `${OLD_SITE_URL}/solicitari`}
        >
            <h5>IConnect EE</h5>
        </ChoiceModal>;
    };

    return (
        <div className="d-flex justify-content-center flex-column">
            { !noRequest ? (showStartingModal ? renderModal() : (!isFinalized || !isEditing) && renderSteps()) : <p className="red d-flex justify-content-center fw-bold">A intervenit o eroare tehnică. Vă rugăm reveniți mai târziu.</p>}
            { (isFinalized && isEditing) && <Fragment>
                <p className="red d-flex justify-content-center fw-bold mb-3">Cererea a fost deja finalizată.</p> 
                <Button style={{margin: '0 auto'}} onClick={() => window.location.href = `${OLD_SITE_URL}/lista-cereri-racordare`}>Listă cereri racordare</Button>
            </Fragment>}
        </div>
    );
};

export default CerereIConnectEE;