import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { CheckBox } from '@components/common/CheckBox';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleMinus, faCirclePlus } from '@fortawesome/free-solid-svg-icons';
import { Col, Row } from 'react-bootstrap';
import UserIcon from '../../../../assets/resources/img/events-icons/user.svg';

const CPTableRow = (props: any) => {
  const checked = props.checked();
  //eslint-disable-next-line
  const [dataAdaugare, setDataAdaugare] = useState('-');

  useEffect(() => {
    if (props.item) {
      getDate();
    }
  }, [props]);

  const getDate = () => {
    const isISOFormat = moment(props.item['dataAdaugare'], moment.ISO_8601, true).isValid();
    let formattedDate = '-';
    if (isISOFormat) {
      // If it's in ISO format
      formattedDate = moment(props.item['dataAdaugare']).format('DD/MM/YYYY');
    } else if (props.item['dataAdaugare']) {
      // If it's in "DD.MM.YYYY" format
      formattedDate = moment(props.item['dataAdaugare'], 'DD.MM.YYYY').format('DD/MM/YYYY');
    }
    setDataAdaugare(formattedDate);
  };

  return (
    <tr>
      <td key={-1} style={{ verticalAlign: 'middle' }}>
        <div className="d-flex flex-row align-items-center justify-content-center">
          <Row>
            <Col style={{ width: '35px' }}>
              {props.groupByPartner && (
                <span
                  style={{ cursor: 'pointer', color: `${!props.partner.toggled ? '#ea1b0a' : '#8f9192'}` }}
                  onClick={() => props.setPartnerToggled()}
                >
                  {props.partner.toggled ? (
                    <FontAwesomeIcon icon={faCircleMinus} size="lg" />
                  ) : (
                    <FontAwesomeIcon icon={faCirclePlus} size="lg" />
                  )}
                </span>
              )}
            </Col>
            <Col>
              <CheckBox
                checked={checked}
                onChange={() => {
                  props.setChecked && props.setChecked();
                }}
              />
            </Col>
          </Row>
        </div>
      </td>

      {props.groupByPartner ? (
        <>
          <td key={'pc1'}>
            <div className="d-flex align-items-center">
              <span className="red">
                <img width={30} height={30} src={UserIcon} alt="UserIcon" />
                <strong> {props.partner.partnerCode}</strong>
              </span>
            </div>
          </td>

          <td key={'pc2'}>
            <div className="d-flex align-items-center">
              <span className="red">
                <strong>{props.partner.partnerName}</strong>
              </span>
            </div>
          </td>
          <td key={'pc3'}></td>
          <td key={'pc4'}></td>
          <td key={'pc5'}></td>
        </>
      ) : (
        <>
          {
            <>
              {/* <td key={1}>
                <div className="d-flex align-items-center">
                  <span>{props.item['denumireLocConsum']}</span>
                </div>
              </td> */}
              <td></td>
              <td></td>
              <td key={2}>
                <div className="d-flex align-items-center">
                  <span>{props.item['consumptionCode']}</span>
                </div>
              </td>
              <td key={3}>
                <div className="d-flex align-items-center">
                  <span>{props.item['pod']}</span>
                </div>
              </td>
              <td key={4}>
                <div className="d-flex align-items-center">
                  <span>{props.item['address']}</span>
                </div>
              </td>
              {/* <td key={5}>
                <div className="d-flex align-items-center">
                  <span>{dataAdaugare}</span>
                </div>
              </td> */}
            </>
          }
        </>
      )}
    </tr>
  );
};

export default CPTableRow;
