import React from 'react';
import { DelgazWrapper } from '@components/global/DelgazWrapper';
import CreateAccountForm from '@components/auth/createAccount/CreateAccountForm';

export const CreateAccount = () => {
    return (
        <DelgazWrapper>
            <CreateAccountForm />
        </DelgazWrapper>
    );
};