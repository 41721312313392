import { toggleLoader } from '@actions/LoaderActions';
import { postStepFour } from '@api/iConnect/iConnectGaz';
import { getTypes } from '@api/prosumatori/prosumatori';
import casa1 from '@components/assets/resources/img/iConnect/casa-front-campuri.png';
import casa2 from '@components/assets/resources/img/iConnect/casa-iso-2-lungimi-1.png';
import { Input } from '@components/common/Input';
import ChoiceComponent from '@components/prosumatori/components/ChoiceComponent';
import DynamicTable from '@components/prosumatori/components/table/DynamicTable';
import { Fragment, useEffect, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tippy from '@tippyjs/react';

const InformatiiTehnice = (props: {
  isEditing: any;
  requestId: any;
  setStep: any;
  currentStep: any;
  progress: any;
  setProgress: any;
  connectingPipe: any;
  lucrari: any;
  handleLucrari: any;
  aparate: any;
  conducta: any;
  handleConducta: any;
  alimentareGaz: any;
  handleAlimentareGaz: any;
  dimensiuni: any;
  handleDimensiuni: any;
  tipDrum: any;
  handleTipDrum: any;
  tipTrotuar: any;
  handleTipTrotuar: any;
  pozFirida: any;
  handlePozFirida: any;
  distanta: any;
  handleDistanta: any;
  drum: any;
  handleDrum: any;
  asfaltat: any;
  handleAsfaltat: any;
  infoAsfaltare: any;
  handleInfoAsfaltare: any;
  consumatori: any;
  dateConsumatori: any;
  handleDateConsumatori: any;
  cerinteSpecifice: any;
  handleCerinteSpecifice: any;
  debitTotal: any;
  handleDebitTotal: any;
  pozProprAlimentate: any;
  handlePozProprAlimentate: any;
}) => {
  const {
    isEditing,
    requestId,
    setStep,
    currentStep,
    progress,
    setProgress,
    connectingPipe,
    lucrari,
    handleLucrari,
    // aparate,
    conducta,
    handleConducta,
    alimentareGaz,
    handleAlimentareGaz,
    dimensiuni,
    handleDimensiuni,
    tipDrum,
    handleTipDrum,
    tipTrotuar,
    handleTipTrotuar,
    pozFirida,
    handlePozFirida,
    distanta,
    handleDistanta,
    drum,
    handleDrum,
    asfaltat,
    handleAsfaltat,
    infoAsfaltare,
    handleInfoAsfaltare,
    consumatori,
    dateConsumatori,
    handleDateConsumatori,
    cerinteSpecifice,
    handleCerinteSpecifice,
    debitTotal,
    handleDebitTotal,
    pozProprAlimentate,
    handlePozProprAlimentate
  } = props;

  const [EOptions, setEOptions] = useState([]);
  const [RoadOptions, setRoadOptions] = useState([]);
  const [FOptions, setFOptions] = useState([]);
  const [SidewalkOptions, setSidewalkOptions] = useState([]);
  const [DOptions, setDOptions] = useState([]);
  const [validationError, setValidationError] = useState('');
  // const [fStanga, setFStanga] = useState('0');
  // const [fDreapta, setFDreapta] = useState('0');
  const [GOptions, setGOptions] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo(0, 0);

    getOptionTypes(['PropertyGroundType']).then((res) => setRoadOptions(res));
    getOptionTypes(['PropertyGroundType']).then((res) => setSidewalkOptions(res));
    getOptionTypes(['ServiceBoxPositionType']).then((res) => setFOptions(res));
    getOptionTypes(['PropertyRoadType']).then((res) => setDOptions(res));
    getOptionTypes(['ExecutingCompanyType']).then((res) => setEOptions(res));
    getOptionTypes(['GasPipesPositionType']).then((res) => setGOptions(res));

    let total = 0;
    dateConsumatori.map((row: any, index: number) => {
      total += Number(row['TotalConsumptionPower']);
    });

    handleDebitTotal(total.toFixed(2));
  }, []);

  const getOptionTypes = async (keys: string[]) => {
    return await getTypes(keys, 'GN').then((res: any) => {
      return res[keys[0]];
    });
  };

  const validateTable = (table: any) => {
    let validated = true;

    table.map((row: any, index: number) => {
      Object.keys(row).map((column: any, idx: number) => {
        console.log(row['DomesticConsumerName']);
        if (row['DomesticConsumerName'] === '' || row['DomesticConsumerCount'] === '' || row['ConsumptionPower'] === '') {
          validated = false;
        }

        // check if Consumer Count is integer
        if (/^\d+$/.test(row['DomesticConsumerCount']) === false) {
          validated = false;
        }
      });
    });

    return validated;
  };

  const constructPayload = () => {
    return {
      requestId: requestId,
      TipBransament: Number(connectingPipe),
      executant: connectingPipe == 6 || connectingPipe == 7 ? null : lucrari,
      consumatori: connectingPipe == 6 || connectingPipe == 7 ? [] : dateConsumatori,
      cerinte_specifice: cerinteSpecifice,
      consum_total: connectingPipe == 6 || connectingPipe == 7 ? null : Number(debitTotal),
      ConductaGaze: connectingPipe == 1 || connectingPipe == 2 || connectingPipe == 3 ? conducta : null,
      ProprietatiAlimentate: connectingPipe == 1 || connectingPipe == 2 || connectingPipe == 3 ? alimentareGaz === 1 : null,
      PozitieProprietatiAlimentate:
        (connectingPipe == 1 || connectingPipe == 2 || connectingPipe == 3) && alimentareGaz === 1
          ? [
              {
                Id: pozProprAlimentate,
                Pozitie: pozProprAlimentate
              }
            ]
          : [],
      L1: connectingPipe == 1 || connectingPipe == 2 || connectingPipe == 3 ? dimensiuni.L1 : null,
      L2: connectingPipe == 1 || connectingPipe == 2 || connectingPipe == 3 ? dimensiuni.L2 : null,
      L3: connectingPipe == 1 || connectingPipe == 2 || connectingPipe == 3 ? dimensiuni.L3 : null,
      L4: connectingPipe == 1 || connectingPipe == 2 || connectingPipe == 3 ? dimensiuni.L4 : null,
      L5: connectingPipe == 1 || connectingPipe == 2 || connectingPipe == 3 ? dimensiuni.L5 : null,
      TipTeren: {
        Drum: connectingPipe == 1 || connectingPipe == 2 || connectingPipe == 3 ? tipDrum : 0,
        Trotuar: connectingPipe == 1 || connectingPipe == 2 || connectingPipe == 3 ? tipTrotuar : 0
      },
      Firida: connectingPipe == 1 || connectingPipe == 2 || connectingPipe == 3 ? pozFirida : null,
      Distanta: connectingPipe == 1 || connectingPipe == 2 || connectingPipe == 3 ? Number(distanta) : null,
      Drum: connectingPipe == 1 || connectingPipe == 2 || connectingPipe == 3 ? drum : 0,
      Asfaltat: connectingPipe == 1 || connectingPipe == 2 || connectingPipe == 3 ? asfaltat : null,
      Lucrari_asfalt: connectingPipe == 1 || connectingPipe == 2 || connectingPipe == 3 ? infoAsfaltare : null
    };
  };

  const stepFourApiCall = async (payload: any) => {
    dispatch(toggleLoader(true));

    await postStepFour(payload)
      .then((response) => {
        if (response && response.status === 200) {
          setStep(currentStep + 1);
          currentStep === progress && setProgress(currentStep + 1);
        }
      })
      .catch((error: any) => {
        if (error && error.status === 200) {
          setStep(currentStep + 1);
          currentStep === progress && setProgress(currentStep + 1);
        } else if (error === 'Request is completed') {
          setValidationError('Cererea a fost deja finalizată.');
        } else if (error && error.StatusCode && error.StatusCode === 500) {
          setValidationError('A intervenit o eroare tehnică. Vă rugăm reveniți mai târziu.');
        } else {
          setValidationError('A intervenit o eroare tehnică. Vă rugăm reveniți mai târziu.');
        }
      });

    dispatch(toggleLoader(false));
  };

  const handlePrevStep = () => {
    setStep(currentStep - 1);
  };

  const handleNextStep = () => {
    if (isEditing !== false) {
      setValidationError('');
      let luc = document.getElementById('lucrari');
      luc?.classList.remove('red');
      let fir = document.getElementById('PF');
      fir?.classList.remove('red');
      let con = document.getElementById('conducta');
      con?.classList.remove('red');
      let propr = document.getElementById('proprietati');
      propr?.classList.remove('red');
      let df = document.getElementById('dist');
      df?.classList.remove('forced-focus');
      let d = document.getElementById('drum');
      d?.classList.remove('red');
      let lng = document.getElementById('lungimi');
      lng?.classList.remove('red');
      let da = document.getElementById('drumasfaltat');
      da?.classList.remove('red');
      let tipd = document.getElementById('tip-drum');
      tipd?.classList.remove('red');
      let info = document.getElementById('info-asf');
      info?.classList.remove('red');
      let wasValidated = true;

      if (connectingPipe == 6 || connectingPipe == 7) {
        stepFourApiCall(constructPayload());
      } else {
        if ((connectingPipe == 1 || connectingPipe == 2 || connectingPipe == 3) && lucrari === null) {
          setValidationError('Câmpurile marcate cu * sunt obligatorii.');
          let err = document.getElementById('lucrari');
          err?.classList.add('red');
          wasValidated = false;
        }

        if (!validateTable(dateConsumatori)) {
          setValidationError('Te rugăm să revizuiești informațiile din tabel.');
          wasValidated = false;
        }

        if ((connectingPipe == 1 || connectingPipe == 2 || connectingPipe == 3) && pozFirida === null) {
          setValidationError('Câmpurile marcate cu * sunt obligatorii.');
          let err = document.getElementById('PF');
          err?.classList.add('red');
          wasValidated = false;
        }

        if ((connectingPipe == 1 || connectingPipe == 2 || connectingPipe == 3) && distanta === '') {
          setValidationError('Câmpurile marcate cu * sunt obligatorii.');
          let err = document.getElementById('dist');
          err?.classList.add('forced-focus');
          wasValidated = false;
        }

        if ((connectingPipe == 1 || connectingPipe == 2 || connectingPipe == 3) && drum === null) {
          setValidationError('Câmpurile marcate cu * sunt obligatorii.');
          let err = document.getElementById('drum');
          err?.classList.add('red');
          wasValidated = false;
        }

        if ((connectingPipe == 1 || connectingPipe == 2 || connectingPipe == 3) && asfaltat === null) {
          setValidationError('Câmpurile marcate cu * sunt obligatorii.');
          let err = document.getElementById('drumasfaltat');
          err?.classList.add('red');
          wasValidated = false;
        }

        if ((connectingPipe == 1 || connectingPipe == 2 || connectingPipe == 3) && conducta === null) {
          setValidationError('Câmpurile marcate cu * sunt obligatorii.');
          let err = document.getElementById('conducta');
          err?.classList.add('red');
          wasValidated = false;
        }

        if ((connectingPipe == 1 || connectingPipe == 2 || connectingPipe == 3) && alimentareGaz === null) {
          setValidationError('Câmpurile marcate cu * sunt obligatorii.');
          let err = document.getElementById('proprietati');
          err?.classList.add('red');
          wasValidated = false;
        }

        if ((connectingPipe == 1 || connectingPipe == 2 || connectingPipe == 3) && tipDrum === null) {
          setValidationError('Câmpurile marcate cu * sunt obligatorii.');
          let err = document.getElementById('tip-drum');
          err?.classList.add('red');
          wasValidated = false;
        }

        if ((connectingPipe == 1 || connectingPipe == 2 || connectingPipe == 3) && tipTrotuar === null) {
          setValidationError('Câmpurile marcate cu * sunt obligatorii.');
          let err = document.getElementById('tip-drum');
          err?.classList.add('red');
          wasValidated = false;
        }

        if (
          (connectingPipe == 1 || connectingPipe == 2 || connectingPipe == 3) &&
          (dimensiuni.L1 === '' || dimensiuni.L2 === '' || dimensiuni.L3 === '' || dimensiuni.L4 === '' || dimensiuni.L5 === '')
        ) {
          setValidationError('Câmpurile marcate cu * sunt obligatorii.');
          let err = document.getElementById('lungimi');
          err?.classList.add('red');
          wasValidated = false;
        }

        if (connectingPipe == 1 || connectingPipe == 2 || connectingPipe == 3) {
          if (infoAsfaltare === null) {
            setValidationError('Câmpurile marcate cu * sunt obligatorii.');
            let err = document.getElementById('info-asf');
            err?.classList.add('red');
            wasValidated = false;
          }

          if (alimentareGaz === 1 && pozProprAlimentate === null) {
            setValidationError('Câmpurile marcate cu * sunt obligatorii.');
            wasValidated = false;
          }
        }

        if (wasValidated) {
          stepFourApiCall(constructPayload());
        }
      }
    } else {
      setStep(currentStep + 1);
      currentStep === progress && setProgress(currentStep + 1);
    }
  };

  return (
    <div className="iconnect-informatii-tehnice m-3 w-100 d-flex align-self-center flex-column">
      <h4 className="register-section-title mt-3">Informații tehnice</h4>

      {(connectingPipe == 6 || connectingPipe == 7) && (
        <p>
          Pentru acest tip de cerere nu este necesară completarea de informații suplimenatare despre locul de consum. Te rugăm să continui
          cu completarea pasului următor.
        </p>
      )}

      {connectingPipe != 6 && connectingPipe != 7 && (
        <Fragment>
          {EOptions.length > 0 && (
            <ChoiceComponent
              title={`Lucrările necesare racordării la sistemul de distribuție a gazelor naturale a imobilului se vor executa de către:${(connectingPipe == 1 || connectingPipe == 2 || connectingPipe == 3) ? '*' : ''}`}
              options={EOptions}
              onChange={handleLucrari}
              value={lucrari}
              colAlign
              id="lucrari"
              disabled={isEditing === false}
            />
          )}

          <div className="d-flex">
            <h5>Aparate de utilizare</h5>
            <Tippy
              content={
                <div className="p-3" style={{ background: 'rgba(0,0,0,0.8)', color: 'white', borderRadius: '6px' }}>
                  Poți adăuga sau elimina aparate în lista de mai jos, prin utilizarea butoanelor + și - . De asemenea, poți modifica numărul de aparate și debitul nominal afișate în tabel.
                </div>
              }
              duration={0}
              placement="bottom"
            >
              <div className="icon-div" style={{marginLeft: '6px', marginTop: '2px'}}>
                <FontAwesomeIcon icon={faCircleInfo} className="icon" color="red" />
              </div>
            </Tippy>
          </div>
          <DynamicTable handler={handleDateConsumatori} data={dateConsumatori} format={consumatori} isEditing={isEditing} />

          <br></br>
          <Col xs={12} sm={6}>
            <Input
              value={cerinteSpecifice}
              onChange={(e) => handleCerinteSpecifice(e.target.value)}
              label={'Alte cerințe specifice'}
              disabled={isEditing === false}
            />
          </Col>

          <br></br>
          <Col xs={12} sm={6}>
            <Input
              value={debitTotal}
              onChange={(e) => handleDebitTotal(e.target.value)}
              label={'Debitul total de gaze naturale solicitat (mc/h)*'}
              disabled={isEditing === false}
            />
          </Col>
        </Fragment>
      )}

      {(connectingPipe == '1' || connectingPipe == '2' || connectingPipe == '3') && (
        <Fragment>
          <ChoiceComponent
            title={'Există conductă de gaze naturale prin fața proprietății tale?*'}
            options={[
              { title: 'Da', id: 1 },
              { title: 'Nu', id: 2 },
              { title: 'Nu stiu', id: 3 }
            ]}
            onChange={handleConducta}
            value={conducta}
            id="conducta"
            disabled={isEditing === false}
          />

          <ChoiceComponent
            title={'Proprietățile învecinate sunt alimentate cu gaz?*'}
            options={[
              { title: 'Da', id: 1 },
              { title: 'Nu', id: 2 }
            ]}
            onChange={handleAlimentareGaz}
            value={alimentareGaz}
            id="proprietati"
            disabled={isEditing === false}
          />

          {alimentareGaz === 1 && (
            <div style={{ marginTop: '-40px' }} className="mb-4">
              <ChoiceComponent
                options={GOptions}
                onChange={handlePozProprAlimentate}
                value={pozProprAlimentate}
                disabled={isEditing === false}
              />
            </div>
          )}

          <h5 id="lungimi">
            Te rugăm să treci lungimile aproximative pentru cele trei categorii de teren, indicate în imaginea de mai jos*
          </h5>
          <Row className="mb-3">
            <Col sm={12} md={6}>
              <Col xs={12} md={10}>
                <p>Dimensiune stradă (L1):</p>
                <Input
                  placeholder={'Dimensiune stradă (m)'}
                  value={dimensiuni.L1}
                  onChange={(e) => handleDimensiuni(e.target.value.trim(), 'L1')}
                  disabled={isEditing === false}
                />
                <p>Dimensiune trotuar:</p>
                <Row>
                  <Col xs={12} sm={6}>
                    <Input
                      label="L2"
                      placeholder={'Lungime L2 (m)'}
                      value={dimensiuni.L2}
                      onChange={(e) => handleDimensiuni(e.target.value.trim(), 'L2')}
                      disabled={isEditing === false}
                    />
                  </Col>
                  <Col xs={12} sm={6}>
                    <Input
                      label="L4"
                      placeholder={'Lungime L4 (m)'}
                      value={dimensiuni.L4}
                      onChange={(e) => handleDimensiuni(e.target.value.trim(), 'L4')}
                      disabled={isEditing === false}
                    />
                  </Col>
                </Row>
                <p>Dimensiune spațiu verde</p>
                <Row>
                  <Col xs={12} sm={6}>
                    <Input
                      label="L3"
                      placeholder={'Lungime L3 (m)'}
                      value={dimensiuni.L3}
                      onChange={(e) => handleDimensiuni(e.target.value.trim(), 'L3')}
                      disabled={isEditing === false}
                    />
                  </Col>
                  <Col xs={12} sm={6}>
                    <Input
                      label="L5"
                      placeholder={'Lungime L5 (m)'}
                      value={dimensiuni.L5}
                      onChange={(e) => handleDimensiuni(e.target.value.trim(), 'L5')}
                      disabled={isEditing === false}
                    />
                  </Col>
                </Row>
              </Col>
            </Col>
            <Col sm={12} md={6}>
              <img src={casa2} alt="Casa" className="w-100" />
            </Col>
          </Row>

          {RoadOptions.length > 0 && (
            <ChoiceComponent
              title={'Care este tipul de teren aferent drumului (străzii), respectiv trotuarului din fața proprietății tale?*'}
              subtitle={'Drum (strada):'}
              options={RoadOptions}
              onChange={handleTipDrum}
              value={tipDrum}
              id="tip-drum"
              disabled={isEditing === false}
            />
          )}

          {SidewalkOptions.length > 0 && (
            <ChoiceComponent
              subtitle={'Trotuar:'}
              options={SidewalkOptions}
              onChange={handleTipTrotuar}
              value={tipTrotuar}
              id="tip-trotuar"
              disabled={isEditing === false}
            />
          )}

          <h5 className="mt-3">
            Te rugăm să ne indici conform cu imaginea de mai jos, unde dorești poziționarea firideri (post de reglare) și să ne spui care
            este distanța între firida și limita laterală a proprietății tale.
          </h5>
          <Row>
            <span>
              <span className="red fw-bold">IMPORTANT:</span> Firida nu se poziționează sub ferestre, în dreptul căminelor altor utilități,
              stâlpilor, copacilor sau a altor obstacole.
            </span>
          </Row>
          <div className="iconnect-gn-image-container position-relative">
            {/* <input 
                        value={fStanga} 
                        onChange={(e) => {
                            if (!isNaN(Number(e.target.value))) {
                                setFStanga(e.target.value)
                            }
                        }}
                        style={{position: 'absolute', width: '50px', top: '52%', left: '24.4%', border: 'none'}}
                    />
                    <input 
                        value={fDreapta} 
                        onChange={(e) => {
                            if (!isNaN(Number(e.target.value))) {
                                setFDreapta(e.target.value)
                            }
                        }} 
                        style={{position: 'absolute', width: '50px', top: '52%', right: '22.9%', border: 'none'}}
                    /> */}
            <img src={casa1} alt="img" className="w-100 mb-3" />
          </div>

          {FOptions.length > 0 && (
            <ChoiceComponent
              title={'Poziționare firidă*'}
              options={FOptions}
              onChange={handlePozFirida}
              value={pozFirida}
              id="PF"
              disabled={isEditing === false}
            />
          )}

          <p>Distanța între firidă și limita laterală a proprietății tale (metri)*</p>
          <Col xs={12} sm={6}>
            <Input
              label={'Distanța*'}
              value={distanta}
              onChange={(e) => {
                if (!isNaN(Number(e.target.value))) {
                  handleDistanta(e.target.value.trim());
                }
              }}
              id="dist"
              disabled={isEditing === false}
            />
          </Col>

          {DOptions.length > 0 && (
            <ChoiceComponent
              title={'Drumul (strada) din care ai acces la proprietatea ta este public sau este un drum privat?*'}
              options={DOptions}
              onChange={handleDrum}
              value={drum}
              id="drum"
              disabled={isEditing === false}
            />
          )}

          <ChoiceComponent
            title={'Drumul (strada) din fața proprietății tale a fost asfaltat în ultimii 5 ani?*'}
            options={[
              { title: 'Da', id: 1 },
              { title: 'Nu', id: 2 },
              { title: 'Nu stiu', id: 3 }
            ]}
            onChange={handleAsfaltat}
            value={asfaltat}
            id="drumasfaltat"
            disabled={isEditing === false}
          />

          <ChoiceComponent
            title={'Există informații referitoare la lucrări de asfaltare propuse pentru drumul din fața proprietății tale?*'}
            options={[
              { title: 'Da', id: 1 },
              { title: 'Nu', id: 2 },
              { title: 'Nu stiu', id: 3 }
            ]}
            onChange={handleInfoAsfaltare}
            value={infoAsfaltare}
            id="info-asf"
            disabled={isEditing === false}
          />
        </Fragment>
      )}

      {/*<h5>Care este tipul de teren aferent drumului (strazii), respectiv trotuarului din fata proprietatii tale?</h5>*/}

      {validationError !== '' && <p style={{ fontWeight: 'bold', color: 'red' }}>{validationError}</p>}
      <div className="d-flex flex-row justify-content-between mt-3 mb-3">
        <Button className="d-flex" onClick={handlePrevStep}>
          Pasul anterior
        </Button>
        <Button className="d-flex" onClick={handleNextStep}>
          Pasul următor
        </Button>
      </div>
    </div>
  );
};

export default InformatiiTehnice;