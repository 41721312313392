import React from 'react';
import FilterDescIcon from '../../assets/resources/img/events-icons/filter-desc.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';

type ButtonFiltersProps = {
  onClick: () => void;
  label?: string;
  toggled: boolean;
  className?: string;
};

const ButtonFilters = (props: ButtonFiltersProps) => {
  return (
    <button
      className={`btn-white ${props.className ? props.className : ''}`}
      style={{
        minWidth: '172px',
        minHeight: '36px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingLeft: '10px',
        paddingRight: '10px'
      }}
      onClick={() => props.onClick()}
    >
      <span style={{ marginRight: '6px' }}>{props.label ? props.label : 'Filtrare'}</span>

      {props.toggled ? (
        <FontAwesomeIcon icon={faXmark} width={24} style={{ color: '#EA1C09' }} />
      ) : (
        <img src={FilterDescIcon} alt="FilterDescIcon" />
      )}
    </button>
  );
};

export default ButtonFilters;
