import {store} from '@lib/store';
import axios from 'axios';
import { GET_PARTNERS, GET_PARTNER_CP, DELETE_PARTNER, VERIFY_PARTNER, GET_PARTNERS_FILTERED, GET_PARTNER_CP_FILTERED, GET_PAGINATED_PARTNERS } from '@constants/endpoints/partners';

export function getPartners() {
    const state = store.getState();

    return axios.get(
        `${GET_PARTNERS}`,
        {
            headers: {
                'Authorization': 'Bearer ' + state.auth.token,
                'Ocp-Apim-Subscription-Key': state.options.subscriptionKey as string
            }
        }
    );
}

export function getPaginatedPartners(payload: any) {
    const state = store.getState();

    return axios.get(
        `${GET_PAGINATED_PARTNERS}?${payload.join('&')}`,
        {
            headers: {
                'Authorization': 'Bearer ' + state.auth.token,
                'Ocp-Apim-Subscription-Key': state.options.subscriptionKey as string
            }
        }
    );
}

export function getPartnersFiltered(utility: number, meterTypes: string, currentPage: number, perPage: number, mmrOnly?: boolean) {
    const state = store.getState();

    return axios.get(
        `${GET_PARTNERS_FILTERED}?pageNumber=${currentPage}&pageSize=${perPage}&utility=${utility}${meterTypes ? `&meterTypes=${meterTypes}` : ''}${mmrOnly != null ? `&mmrOnly=${!!mmrOnly}` : ''}`,
        {
            headers: {
                'Authorization': 'Bearer ' + state.auth.token,
                'Ocp-Apim-Subscription-Key': state.options.subscriptionKey as string
            }
        }
    );
}

export function getPartnerConsumptionPoints(payload: any) {
    const state = store.getState();

    return axios.get(
        `${GET_PARTNER_CP}?${payload.join('&')}`,
        {
            headers: {
                'Authorization': 'Bearer ' + state.auth.token,
                'Ocp-Apim-Subscription-Key': state.options.subscriptionKey as string
            }
        }
    );
}

export function getPartnerConsumptionPointsFiltered(partnerCode: string, utility: number, meterTypes: string, mmrOnly?: boolean) {
    const state = store.getState();

    return axios.get(
        `${GET_PARTNER_CP_FILTERED}?partnerCode=${partnerCode}&utility=${utility}${meterTypes ? `&meterTypes=${meterTypes}` : ''}${mmrOnly != null ? `&mmrOnly=${!!mmrOnly}` : ''}`,
        {
            headers: {
                'Authorization': 'Bearer ' + state.auth.token,
                'Ocp-Apim-Subscription-Key': state.options.subscriptionKey as string
            }
        }
    );
}

export function deletePartner(partnerCode: string) {
    const state = store.getState();

    return axios.delete(
        `${DELETE_PARTNER}?partnerCode=${partnerCode}`,
        {
            headers: {
                'Authorization': 'Bearer ' + state.auth.token,
                'Ocp-Apim-Subscription-Key': state.options.subscriptionKey as string
            }
        }
    );
}

export function clientVerifyPartner(pod: string, countyId: number, localityId: string, savePartner: boolean) {
    const state = store.getState();

    return axios.get(
        `${VERIFY_PARTNER}?pod=${pod}&countyId=${countyId}&localityId=${localityId}&savePartner=${savePartner}`,
        {
            headers: {
                'Authorization': 'Bearer ' + state.auth.token,
                'Ocp-Apim-Subscription-Key': state.options.subscriptionKey as string
            }
        }
    );
}