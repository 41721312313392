import { toggleLoader } from '@actions/LoaderActions';
import { deleteRequest, getDecrecList, getDownloadDocument } from '@api/decrec/decrec';
import ButtonFilters from '@components/common/Button/ButtonFilters';
import DatePicker from '@components/common/Date/DatePicker';
import { Dropdown, DropdownOptionType } from '@components/common/Dropdown';
import ChoiceModal from '@components/common/Modal/ChoiceModal';
import Pagination from '@components/common/Pagination/Pagination';
import SortAscDesc from '@components/common/Sort/SortAscDesc';
import {
  DECREC_STATUS__ANULATA,
  DECREC_STATUS__IN_ANALIZA,
  DECREC_STATUS__NEINREGISTRATA,
  DECREC_STATUS__NETRIMISA,
  DECREC_STATUS__PROCESATA,
  TYPE_CANCEL_DISCONNECT,
  TYPE_DISCONNECT,
  TYPE_RECONNECT
} from '@constants/decRec/decRecConstants';
import { faPen, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import { Fragment, useEffect, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import * as notification from '@lib/Notification';
import MaskedInput from '@components/common/Input/MaskedInput';
import { Input } from '@components/common/Input';
import { downloadBlobWithFilename } from '../../../helpers/download';
import { UTILITY_EE, UTILITY_GN } from '@constants/Utility';

type DecRecListOptionType = {
  id: number;
  requestTypeId: number;
  requestTypeName: string;
  requestStatusId: number;
  requestStatusName: string;
  pod: string;
  vstelle: string;
  requestNo: string;
  requestDate: string;
  utility: string;
  sentDate: string;
  entryDate: string;
  isB2b: boolean;
  document: {
    id: number;
    fileName: string;
    documentLink: string;
  };
};

type Props = {
  utility: number;
};

const DecRecList = (props: Props) => {
  const { utility } = props;
  const [decrecList, setDecrecList] = useState([]);
  const [showModalBulkUpload, setShowModalBulkUpload] = useState(false);
  const [showModalNewRequest, setShowModalNewRequest] = useState(false);
  const [summary, setSummary] = useState<any>([]);
  const [summaryTotalCount, setSummaryTotalCount] = useState<any>(0);

  //paginare
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const paginate = (pageNumber: number) => setCurrentPage(pageNumber);
  const setElementsPerPage = (number: number) => setPerPage(number);
  const [totalItemsCount, setTotalItemsCount] = useState(0);

  //filters
  const [toggleSearch, setToggleSearch] = useState(false);
  const [sortColumn, setSortColumn] = useState('ID');
  const [sortAscending, setSortAscending] = useState(false);
  const [filterStatus, setFilterStatus] = useState<any>('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [requestTypeId, setRequestTypeId] = useState('');
  const [filterPodClc, setFilterPodClc] = useState<any>('');
  const [filterPodClcTouched, setFilterPodClcTouched] = useState<any>(false);
  const [requestNo, setRequestNo] = useState('');

  //delete
  const [showDeleteRequestModal, setShowDeleteRequestModal] = useState(false);
  const [requestToDelete, setRequestToDelete] = useState(0);

  const [error, setError] = useState('');

  const dispatch = useDispatch();

  useEffect(() => {
    if (filterPodClc == '__________') {
      setFilterPodClc('');
    }
  }, [filterPodClc]);

  useEffect(() => {
    if (decrecList.length == 0) {
      if (summaryTotalCount > 0) {
        setError('Nu au fost găsite solicitări conform criteriilor aplicate');
      } else {
        setError('Nu aveți solicitări');
      }
    } else {
      setError('');
    }
  }, [totalItemsCount, decrecList]);

  useEffect(() => {
    if (summary && summary.length) {
      let total = 0;
      summary[0].values.forEach((item: any) => {
        total = total + item.count;
      });
      setSummaryTotalCount(total);
    }
  }, [summary]);

  useEffect(() => {
    search();
  }, [currentPage, perPage, sortColumn, sortAscending, filterStatus]);

  const handleFilterStatus = (status: number) => {
    if (status !== filterStatus) {
      setFilterStatus(status);
    } else {
      setFilterStatus(null);
    }
  };

  const search = (firstPage?: boolean, isReset?: boolean) => {
    setError('');
    dispatch(toggleLoader(true));
    let payload = isReset
      ? {
          pageNumber: 1,
          pageSize: perPage,
          sortBy: 'ID',
          sortType: 'desc',
          utility: utility
        }
      : {
          pageNumber: firstPage ? 1 : currentPage,
          pageSize: perPage,
          sortBy: sortColumn,
          sortType: sortAscending ? 'asc' : 'desc',
          utility: utility,
          statusId: filterStatus,
          startDate: startDate,
          endDate: endDate,
          requestTypeId: requestTypeId,
          cp: filterPodClc,
          requestNo: requestNo
        };
    //get data
    getDecrecList(payload)
      .then((res) => {
        setDecrecList(res.items);
        setTotalItemsCount(res.metadata.totalItemsCount);
        setSummary(res.summary);
        dispatch(toggleLoader(false));
      })
      .catch((err) => {
        setError('A intervenit o eroare tehnică. Vă rugăm reveniți mai târziu.');
        dispatch(toggleLoader(false));
      });
  };

  const deleteDecRecEntry = (id: number) => {
    dispatch(toggleLoader(true));

    deleteRequest({ id: requestToDelete, utility: utility })
      .then((res) => {
        dispatch(toggleLoader(false));
        if (!res.succes) {
          if (res.descriereEroare) {
            notification.error(res.descriereEroare);
          } else notification.error('A intervenit o eroare tehnică. Vă rugăm reveniți mai târziu.');
        } else {
          notification.success('Cererea a fost ștearsă cu succes.');
          search();
        }
      })
      .catch((err) => {
        notification.error('A intervenit o eroare tehnică. Vă rugăm reveniți mai târziu.');
        dispatch(toggleLoader(false));
      });
  };

  const editDecRecEntry = (id: number) => {
    window.location.href = `/solicitare-noua?id=${id}&utility=${utility}`;
  };

  const handleSelectBulkType = (option: { title: string; id: number }) => {
    if (option.id == TYPE_DISCONNECT) {
      window.location.href = `/incarcare-in-masa?type=${TYPE_DISCONNECT}&utility=${utility}`;
    } else if (option.id == TYPE_RECONNECT) {
      window.location.href = `/incarcare-in-masa?type=${TYPE_RECONNECT}&utility=${utility}`;
    }
  };

  const handleSelectRequestType = (option: { title: string; id: number }) => {
    if (option.id == TYPE_DISCONNECT) {
      window.location.href = `/solicitare-noua?type=${TYPE_DISCONNECT}&utility=${utility}`;
    } else if (option.id == TYPE_RECONNECT) {
      window.location.href = `/solicitare-noua?type=${TYPE_RECONNECT}&utility=${utility}`;
    } else if (option.id == TYPE_CANCEL_DISCONNECT) {
      window.location.href = `/solicitare-noua?type=${TYPE_CANCEL_DISCONNECT}&utility=${utility}`;
    }
  };

  const onSort = (column: string) => {
    setSortColumn(column);
    if (sortColumn == column) {
      setSortAscending(!sortAscending);
    } else {
      setSortAscending(true);
    }
  };

  const downloadDocument = (lo: { id: number; fileName: string; documentLink: string }) => {
    dispatch(toggleLoader(true));
    getDownloadDocument({ utility: Number(utility), documentId: lo.id })
      .then((response: BlobPart) => {
        downloadBlobWithFilename(
          response,
          null,
          null,
          null,
          null,
          (err: any) => notification.error('A apărut o eroare. Vă rugăm să reveniți mai tărziu.'),
          lo.fileName
        );
        dispatch(toggleLoader(false));
      })
      .catch((error: any) => {
        notification.error('A apărut o eroare. Vă rugăm să reveniți mai tărziu.');
        dispatch(toggleLoader(false));
      });
  };

  return (
    <Fragment>
      <div className="d-flex justify-content-center justify-content-md-end flex-row flex-wrap">
        <div className="btn-wide ms-2 mb-3">
          <ButtonFilters
            toggled={toggleSearch}
            onClick={() => {
              setToggleSearch((prev) => !prev);
              setFilterPodClcTouched(false);
            }}
          />
        </div>
        <Button className="btn-wide ms-2 mb-3" onClick={() => setShowModalBulkUpload(true)}>
          Solicitare nouă încărcare în masă
        </Button>

        <Button className="btn-wide ms-2 me-1 mb-3" onClick={() => setShowModalNewRequest(true)}>
          Solicitare nouă
        </Button>
      </div>

      {toggleSearch && (
        <>
          <Row className="d-flex flex-row justify-content-center mt-2">
            <Col className="mb-3" xs={12} sm={6} md={2}>
              <DatePicker
                label="Dată început"
                name="Dată început"
                mbZero
                selected={startDate !== '' ? moment(startDate).toDate() : startDate}
                onChange={(e: any) => {
                  if (e) {
                    setStartDate(moment(e).format('YYYY-MM-DD'));
                  } else {
                    setStartDate('');
                  }
                }}
              />
            </Col>

            <Col className="mb-3" xs={12} sm={6} md={2}>
              <DatePicker
                label="Dată sfârșit"
                name="Dată sfârșit"
                mbZero
                selected={endDate !== '' ? moment(endDate).toDate() : endDate}
                onChange={(e: any) => {
                  if (e) {
                    setEndDate(moment(e).format('YYYY-MM-DD'));
                  } else {
                    setEndDate('');
                  }
                }}
              />
            </Col>

            <Col className="mb-3" xs={12} sm={6} md={2}>
              <Dropdown
                options={
                  [
                    { id: TYPE_DISCONNECT, name: 'Deconectare' },
                    { id: TYPE_RECONNECT, name: 'Reconectare' },
                    { id: TYPE_CANCEL_DISCONNECT, name: 'Anulare deconectare' }
                  ] as DropdownOptionType[]
                }
                onChange={(value) => {
                  setRequestTypeId(value);
                }}
                label={'Tip'}
                defaultPlaceholder={'Alege tipul'}
                value={requestTypeId}
                mbZero
              />
            </Col>

            <Col className="mb-3" xs={12} sm={6} md={2}>
              <Dropdown
                options={
                  [
                    { id: DECREC_STATUS__NETRIMISA, name: 'Netrimise' },
                    { id: DECREC_STATUS__NEINREGISTRATA, name: 'Neînregistrate' },
                    { id: DECREC_STATUS__IN_ANALIZA, name: 'În procesare' },
                    { id: DECREC_STATUS__PROCESATA, name: 'Procesate' },
                    { id: DECREC_STATUS__ANULATA, name: 'Anulate' }
                  ] as DropdownOptionType[]
                }
                onChange={(value) => {
                  setFilterStatus(value);
                }}
                label={'Status'}
                defaultPlaceholder={'Alege status'}
                value={filterStatus}
                mbZero
              />
            </Col>

            <Col className="mb-3" xs={12} sm={6} md={2}>
              {filterPodClc && (filterPodClc[0] == 'D' || filterPodClc[0] == 'E' || filterPodClc[0] == '5') ? (
                <MaskedInput
                  value={filterPodClc}
                  mbZero
                  autoFocus={filterPodClcTouched}
                  preloadedStartDisabled
                  onChange={(e: any) => {
                    const currentValue = (e.target.value ? e.target.value.toLocaleUpperCase() : '') as string;
                    setFilterPodClc(currentValue);
                  }}
                  utility={utility == UTILITY_EE ? 'EE' : 'GN'}
                  isClc={filterPodClc[0] == '5'}
                  label="POD / CLC"
                  mask={'0123333333'}
                />
              ) : (
                <Input
                  label="POD / CLC"
                  mbZero
                  value={filterPodClc}
                  autoFocus={filterPodClcTouched}
                  onChange={(e: any) => {
                    const currentValue = (e.target.value ? e.target.value.toLocaleUpperCase() : '') as string;
                    if (
                      (utility == UTILITY_GN && (currentValue == 'D' || currentValue.startsWith('DEG'))) ||
                      (utility == UTILITY_EE && (currentValue == 'E' || currentValue.startsWith('EMO'))) ||
                      currentValue == '5' ||
                      currentValue.startsWith('500')
                    ) {
                      setFilterPodClcTouched(true);
                      setFilterPodClc(currentValue);
                    }
                  }}
                />
              )}
            </Col>

            <Col className="mb-3" xs={12} sm={6} md={2}>
              <Input
                label="Număr de înregistrare"
                mbZero
                value={requestNo}
                onChange={(e: any) => {
                  setRequestNo(e.target.value);
                }}
              />
            </Col>
          </Row>

          <Row className="justify-content-center mb-3">
            <Col xs={12}>
              <div className="triplet-separator"></div>
            </Col>
          </Row>

          <Row>
            <Col xs={12} className="m-0 mb-2 p-1 justify-content-center">
              <div className="d-flex justify-content-center w-100">
                <Button
                  style={{ marginRight: '5px' }}
                  onClick={() => {
                    setCurrentPage(1);
                    search(true);
                  }}
                >
                  Caută
                </Button>

                <Button
                  onClick={() => {
                    search(true, true);
                    setError('');
                    setStartDate('');
                    setEndDate('');
                    setRequestTypeId('');
                    setFilterPodClc('');
                    setRequestNo('');
                    setCurrentPage(1);
                    setSortColumn('ID');
                    setSortAscending(false);
                    setFilterStatus('');
                  }}
                >
                  Resetează
                </Button>
              </div>
            </Col>
          </Row>
        </>
      )}

      <div className="d-flex justify-content-center justify-content-sm-between flex-row flex-wrap">
        <div className="d-flex gray-filters-list flex-row flex-wrap mb-1 align-items-center">
          {summary && summaryTotalCount ? (
            <span className={`${!filterStatus ? 'active' : 'not-active'} mb-1`} onClick={() => setFilterStatus(null)}>
              <span className="m-2">Toate</span>
              <span className="bg-eon-lightgrey fw-bold" style={{ marginRight: '10px', borderRadius: '4px', padding: '2px 6px' }}>
                {summaryTotalCount}
              </span>
            </span>
          ) : (
            ''
          )}
          {summary &&
            summary.length > 0 &&
            summary[0].values.map((item: any, index: number) => {
              return (
                <span
                  className={`${filterStatus === item.id ? 'active' : 'not-active'} mb-1`}
                  key={index}
                  onClick={() => handleFilterStatus(item.id)}
                >
                  <span className="m-2">{item.name}</span>
                  <span className="bg-eon-lightgrey fw-bold" style={{ marginRight: '10px', borderRadius: '4px', padding: '2px 6px' }}>
                    {item.count}
                  </span>
                </span>
              );
            })}
        </div>
      </div>

      <div className="mt-3">
        <div className="general-table-container mb-1">
          <table>
            <thead>
              <tr>
                <th>
                  <SortAscDesc
                    sortColumn={sortColumn}
                    sortAscending={sortAscending}
                    onSort={onSort}
                    fieldId="RequestNo"
                    fieldName="Număr şi dată înregistrare"
                  />
                </th>
                <th>
                  <SortAscDesc
                    sortColumn={sortColumn}
                    sortAscending={sortAscending}
                    onSort={onSort}
                    fieldId="EntryDate"
                    fieldName="Data depunerii"
                  />
                </th>
                <th>
                  <SortAscDesc
                    sortColumn={sortColumn}
                    sortAscending={sortAscending}
                    onSort={onSort}
                    fieldId="RequestTypeId"
                    fieldName="Tip"
                  />
                </th>
                <th>
                  <SortAscDesc
                    sortColumn={sortColumn}
                    sortAscending={sortAscending}
                    onSort={onSort}
                    fieldId="RequestStatusName"
                    fieldName="Status"
                  />
                </th>
                <th>Documente</th>
                <th>Actiuni</th>
              </tr>
            </thead>
            <tbody>
              {decrecList &&
                decrecList.map((lo: DecRecListOptionType, index: number) => {
                  return (
                    <tr key={index}>
                      <td>
                        {lo.requestStatusId == DECREC_STATUS__NETRIMISA ? (
                          <span>Indisponibil</span>
                        ) : lo.requestStatusId == DECREC_STATUS__NEINREGISTRATA ? (
                          <span>Nr. de inregistrare va fi disponibil in scurt timp</span>
                        ) : (
                          <span>
                            {lo.requestNo}
                            {lo.requestDate ? `/${moment.utc(lo.requestDate).local().format('DD.MM.YYYY HH:mm')}` : ''}
                          </span>
                        )}
                      </td>
                      <td>{lo.entryDate ? moment.utc(lo.entryDate).local().format('DD.MM.YYYY HH:mm') : '-'}</td>
                      <td>{lo.requestTypeName}</td>
                      <td>{lo.requestStatusName}</td>
                      <td>
                        {lo.document?.documentLink == '' ? (
                          <a
                            className="download-link-text red hover-black link--download"
                            onClick={() => {
                              downloadDocument(lo.document);
                            }}
                          >
                            Descarcă cerere
                          </a>
                        ) : (
                          lo.document?.documentLink && (
                            <a className="download-link-text red hover-black link--download" href={lo.document?.documentLink}>
                              Descarcă cerere
                            </a>
                          )
                        )}
                      </td>

                      <td>
                        {(lo.requestStatusId == DECREC_STATUS__NEINREGISTRATA || lo.requestStatusId == DECREC_STATUS__NETRIMISA) && (
                          <Button
                            className="mt-1 me-2"
                            onClick={() => {
                              setRequestToDelete(lo.id);
                              setShowDeleteRequestModal(true);
                            }}
                          >
                            <FontAwesomeIcon icon={faTrash} /> Șterge cerere
                          </Button>
                        )}

                        {lo.requestStatusId == DECREC_STATUS__NETRIMISA && (
                          <Button className="mt-1 me-2" onClick={() => editDecRecEntry(lo.id)}>
                            <FontAwesomeIcon icon={faPen} /> Editează
                          </Button>
                        )}

                        {lo.requestStatusId != DECREC_STATUS__NETRIMISA && (
                          <Button
                            className="mt-1"
                            onClick={() => (window.location.href = `/detalii-deconectari-reconectari?id=${lo.id}&utility=${utility}`)}
                          >
                            Detalii
                          </Button>
                        )}
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>

        <Pagination
          currentPage={currentPage}
          totalElements={totalItemsCount}
          perPage={perPage}
          paginate={paginate}
          setElementsPerPage={setElementsPerPage}
        />
      </div>

      {error !== '' && <p className="text-center fw-bold red mt-3">{error}</p>}

      <ChoiceModal
        showModal={showModalBulkUpload}
        modalTitle={'Solicitare nouă încărcare în masă'}
        options={[
          { title: 'Deconectare loc consum', id: TYPE_DISCONNECT },
          { title: 'Reconectare loc consum', id: TYPE_RECONNECT }
        ]}
        onClose={handleSelectBulkType}
        onHide={() => setShowModalBulkUpload(false)}
        btnWide
      ></ChoiceModal>

      <ChoiceModal
        showModal={showModalNewRequest}
        modalTitle={'Alege tipul de solicitare'}
        options={[
          { title: 'Deconectare loc consum', id: TYPE_DISCONNECT },
          { title: 'Reconectare loc consum', id: TYPE_RECONNECT },
          { title: 'Anulare deconectare', id: TYPE_CANCEL_DISCONNECT }
        ]}
        onClose={handleSelectRequestType}
        onHide={() => setShowModalNewRequest(false)}
        btnWide
        justifyContentStart
      ></ChoiceModal>

      <ChoiceModal
        showModal={showDeleteRequestModal}
        modalTitle={'Sunteți sigur că doriți să ștergeți cererea?'}
        options={[
          { id: 'DA', title: 'Da' },
          { id: 'NU', title: 'Nu' }
        ]}
        onClose={(option: any) => {
          if (option.id == 'DA') {
            deleteDecRecEntry(requestToDelete);
            setShowDeleteRequestModal(false);
          } else if (option.id == 'NU') {
            setShowDeleteRequestModal(false);
          }
        }}
        onHide={() => {
          setShowDeleteRequestModal(false);
        }}
        btnWide
      />
    </Fragment>
  );
};

export default DecRecList;
