import { useEffect, useState } from 'react';

import { CheckDisturbanceComponentProps } from '@kentico/energieElectrica';
import { Tab, Tabs } from 'react-bootstrap';
import { v4 as uuidv4 } from 'uuid';
import AnuntaDeranjament from './tabs/AnuntaDeranjament';
import VerificaStareaUnuiDeranjament from './tabs/VerificaStareaUnuiDeranjament';

const CheckDisturbance = (props: CheckDisturbanceComponentProps) => {
  //eslint-disable-next-line
  const [error, setError] = useState('');
  //eslint-disable-next-line
  const [success, setSuccess] = useState('');
  //eslint-disable-next-line
  const [syncErrors, setSyncErrors] = useState<string[]>([]);
  const [uuid, setUuid] = useState('');
  const [activeTabKey, setActiveTabKey] = useState<any>('cpf');

  //reset component
  const [anuntaDeranjamentKey, setAnuntaDeranjamentKey] = useState(0);

  const submit = (values: any) => {};
  const verify = (values: any) => {};

  useEffect(() => {
    setUuid(uuidv4());
  }, []);

  const resetAnuntaDeranjamentComponent = () => {
    setAnuntaDeranjamentKey(anuntaDeranjamentKey + 1);
  };

  const resetUuid = () => {
    setUuid(uuidv4());
  };

  return (
    <div>
      <Tabs
        className="create-account-tabs d-flex flex-wrap flex-md-nowrap justify-content-center justify-content-md-start"
        mountOnEnter={true}
        unmountOnExit={true}
        activeKey={activeTabKey}
        onSelect={(key) => {
          setActiveTabKey(key);
          setUuid(uuidv4());
          resetAnuntaDeranjamentComponent();
        }}
      >
        <Tab eventKey="cpf" title={'Anunță deranjament'} mountOnEnter={true} unmountOnExit={true}>
          <AnuntaDeranjament
            key={anuntaDeranjamentKey}
            submit={submit}
            error={error}
            success={success}
            syncErrors={syncErrors}
            verify={verify}
            reset={resetAnuntaDeranjamentComponent}
            uuid={uuid}
            resetUuid={resetUuid}
          />
        </Tab>
        <Tab eventKey="cpj" title={'Verifică starea unui deranjament'} mountOnEnter={true} unmountOnExit={true}>
          <VerificaStareaUnuiDeranjament
            submit={submit}
            error={error}
            success={success}
            syncErrors={syncErrors}
            verify={verify}
            uuid={uuid}
            setActiveTabKey={setActiveTabKey}
            resetUuid={resetUuid}
          />
        </Tab>
      </Tabs>
    </div>
  );
};

export default CheckDisturbance;
