import { toggleLoader } from '@actions/LoaderActions';
import { resetPassword } from '@api/user/user';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { Fragment, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { ResetPasswordSchema } from '../../../helpers/schemas';
import { Input } from '@components/common/Input';

const ResetPasswordForm = () => {
  const [msg, setMsg] = useState('');
  const dispatch = useDispatch();

  return (
    <div>
      <Formik
        initialValues={{
          email: ''
        }}
        onSubmit={async (values) => {
          dispatch(toggleLoader(true));
          setMsg('');
          await resetPassword(values).then((response: any) => {
            if (response && response.success === false && response.errorCode === 1) {
              setMsg('Contul asociat adresei de email nu există sau nu este activat.');
            } else {
              if (response && response.success === true) {
                setMsg('Un mail a fost trimis pentru următorul pas din resetarea parolei.');
              } else if (response && response.success === false && response.errorCode === -1) {
                setMsg('Contul este blocat sau nu există.');
              }
            }
          });
          dispatch(toggleLoader(false));
        }}
        validationSchema={ResetPasswordSchema}
      >
        {(props) => (
          <Fragment>
            <h4 className="mb-2 mt-2">Resetare parolă</h4>
            <p className="m-0">Introdu adresa de mail cu care ai creat contul</p>
            <Form>
              <div className="form-container">
                <Row className="mb-3 mt-1">
                  <Col xs={12} md={6}>
                    <Field
                      className="input-field-register"
                      id="email"
                      name="email"
                      placeholder="Email"
                      render={() => (
                        <Input
                          label="Email"
                          mbZero
                          onBlur={() => {
                            props.setFieldTouched('email');
                          }}
                          onChange={(e: any) => {
                            e.target.value.length !== 0 && props.setFieldValue('email', e.target.value);
                            e.target.value.length === 0 && props.setFieldValue('email', '');
                          }}
                        />
                      )}
                    />
                    <ErrorMessage name="email" render={(msg) => <div style={{ color: 'red', fontWeight: 'bold' }}>{msg}</div>} />
                    {msg !== '' && <div style={{ color: 'red', fontWeight: 'bold' }}>{msg}</div>}
                  </Col>
                </Row>
                <div className="mb-4 buttons-container d-flex flex-row align-items-center">
                  <Button type="submit">Continuă</Button>
                </div>
              </div>
            </Form>
          </Fragment>
        )}
      </Formik>
    </div>
  );
};

export default ResetPasswordForm;
