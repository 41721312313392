import React, {Fragment, useEffect, useState} from 'react';
import {Button, Col, Row} from 'react-bootstrap';
import {
    Form,
    Formik,
    Field,
    ErrorMessage
} from 'formik';
import { FGNSchema } from '../../../../helpers/schemas';
import PasswordStrengthBar from 'react-password-strength-bar';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faRotate} from '@fortawesome/free-solid-svg-icons';
import { loadCaptchaEngine, LoadCanvasTemplateNoReload, validateCaptcha } from '../CaptchaLib';
import {useSelector} from 'react-redux';
import {IState} from '@type/store';
import { Input } from '@components/common/Input';

const FurnizorGazeNaturale = (props: any) => {
    const [terms, setTerms] = useState(false);
    const [error, setError] = useState('');
    const resError = props.error;
    const resSuccess = props.success;
    const OLD_SITE_URL: string | undefined = useSelector<IState>((state) => state.options.webApiHost) as string;
    const syncErrors = props.syncErrors;

    useEffect(() => {
        loadCaptchaEngine('6', 'white', 'black', '', 'fgn');
    }, []);

    const submit = (values: any) => {
        setError('');
        let user_captcha = (document.getElementById('user_captcha_input') as HTMLInputElement).value;

        if (validateCaptcha(user_captcha, true, 'fgn')===true) {
            loadCaptchaEngine('6', 'white', 'black', '', 'fgn');
            (document.getElementById('user_captcha_input') as HTMLInputElement).value = '';
            // values = { ...values, mobilePhone: values.contactMobilePhone };
            // values = { ...values, contactPhone: values.contactMobilePhone };
            // values = { ...values, companyName: values.operatorName };
            values = { ...values, utility: '2' };
            values = { ...values, role: 0 };

            const v = {
                utility: '2',
                role: 0,
                email: values.email,
                emailConfirmation: values.emailConfirmation,
                password: values.password,
                passwordConfirmation: values.passwordConfirmation,
                supplierCode: values.supplierCode,
                licence: values.licence,
                mobilePhone: values.phone,
                tfaPhone: values.tfaPhone
            };

            props.submit(v);
        }

        else {
            setError('Codul de securitate nu este valid.');
            (document.getElementById('user_captcha_input') as HTMLInputElement).value = '';
        }
    };

    return (
        <Fragment>
            <Formik
                enableReinitialize
                initialValues={props.supplier}
                onSubmit={(values) => submit(values)}
                validationSchema={ FGNSchema }
            >
                {(props) => (
                    <Fragment>
                        <Form>
                            <div className="form-container">
                                <div className="mt-4 mb-4 d-flex flex-column">
                                    <h5 className="register-section-title">Date furnizare gaz natural</h5>
                                    <Row className="mt-3">
                                        <Col className="mb-3" xs={12} md={6}>
                                            <Field id="licence" name="licence" placeholder="Număr licență" disabled render = {() => <Input label='Număr licență' mbZero placeholder={props.values['licence']} disabled />}/>
                                            <ErrorMessage name="licence"  render={msg => <div style={{color: 'red', fontWeight: 'bold'}}>{msg}</div>}/>
                                        </Col>
                                        <Col className="mb-3" xs={12} md={6}>
                                            <Field id="supplierCode" name="supplierCode" placeholder="Cod client" disabled render = {() => <Input label='Cod client' mbZero placeholder={props.values['supplierCode']} disabled />}/>
                                            <ErrorMessage name="supplierCode" render={msg => <div style={{color: 'red', fontWeight: 'bold'}}>{msg}</div>}/>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="mb-3" sm={12} md={6}>
                                            <Field id="email" name="email" placeholder="Email" disabled render = {() => <Input label='Email' mbZero placeholder={props.values['email']} disabled />}/>
                                            <ErrorMessage name="email" render={msg => <div style={{color: '#ea1c0a', fontWeight: 'bold'}}>{msg}</div>}/>
                                        </Col>
                                        <Col className="mb-3" sm={12} md={6}>
                                            <Field id="emailConfirmation" name="emailConfirmation" placeholder="Confirmă Email" disabled render = {() => <Input label='Confirmă Email' mbZero placeholder={props.values['emailConfirmation']} disabled />}/>
                                            <ErrorMessage name="emailConfirmation" render={msg => <div style={{color: '#ea1c0a', fontWeight: 'bold'}}>{msg}</div>}/>
                                        </Col>
                                    </Row>
                                </div>



                                <div className="mt-4 mb-4 d-flex flex-column">
                                    <h5 className="register-section-title">Date de identificare</h5>
                                    <Row className="mt-3">
                                        <Col className="mb-3" xs={12} md={6}>
                                            <Field id="name" name="name" placeholder="Denumire" disabled render = {() => <Input label='Denumire' mbZero placeholder={props.values['name']} disabled />}/>
                                            <ErrorMessage name="name" render={msg => <div style={{color: 'red', fontWeight: 'bold'}}>{msg}</div>}/>
                                        </Col>
                                        <Col className="mb-3" xs={12} md={6}>
                                            <Field id="fixPhone" name="fixPhone" placeholder="Telefon fix" disabled render = {() => <Input label='Telefon fix' mbZero placeholder={props.values['fixPhone']} disabled />}/>
                                            <ErrorMessage name="fixPhone" render={msg => <div style={{color: 'red', fontWeight: 'bold'}}>{msg}</div>}/>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="mb-3" xs={12} md={6}>
                                            <Field id="phone" name="phone" placeholder="Telefon mobil" disabled render = {() => <Input label='Telefon mobil' mbZero placeholder={props.values['phone']} disabled />}/>
                                            <ErrorMessage name="phone" render={msg => <div style={{color: 'red', fontWeight: 'bold'}}>{msg}</div>}/>
                                        </Col>
                                        <Col className="mb-3" xs={12} md={6}>
                                            <Field id="regComertului" name="regComertului" placeholder="Registrul Comertului" disabled render = {() => <Input label='Registrul Comertului' mbZero placeholder={props.values['regComertului']} disabled />}/>
                                            <ErrorMessage name="regComertului" render={msg => <div style={{color: 'red', fontWeight: 'bold'}}>{msg}</div>}/>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="mb-3" xs={12} md={6}>
                                            <Field id="cui" name="cui" placeholder="CIF/CUI" disabled render = {() => <Input label='CIF/CUI' mbZero placeholder={props.values['cui']} disabled />}/>
                                            <ErrorMessage name="cui" render={msg => <div style={{color: 'red', fontWeight: 'bold'}}>{msg}</div>}/>
                                        </Col>
                                        <Col className="mb-3" xs={12} md={6}>
                                            <Field id="iban" name="iban" placeholder="IBAN" disabled render = {() => <Input label='IBAN' mbZero placeholder={props.values['iban']} disabled />}/>
                                            <ErrorMessage name="iban" render={msg => <div style={{color: 'red', fontWeight: 'bold'}}>{msg}</div>}/>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="mb-3" xs={12} md={6}>
                                            <Field id="bank" name="bank" placeholder="Banca" disabled render = {() => <Input label='Banca' mbZero placeholder={props.values['bank']} disabled />}/>
                                            <ErrorMessage name="bank" render={msg => <div style={{color: 'red', fontWeight: 'bold'}}>{msg}</div>}/>
                                        </Col>
                                    </Row>
                                </div>

                                <div className="mt-4 mb-4 d-flex flex-column">
                                    <h5 className="register-section-title">Reprezentant legal</h5>
                                    <Row className="mt-3">
                                        <Col className="mb-3" xs={12} md={6}>
                                            <Field id="lastName" name="lastName" placeholder="Nume" disabled render = {() => <Input label='Nume' mbZero placeholder={props.values['lastName']} disabled />}/>
                                            <ErrorMessage name="lastName" render={msg => <div style={{color: 'red', fontWeight: 'bold'}}>{msg}</div>}/>
                                        </Col>
                                        <Col className="mb-3" xs={12} md={6}>
                                            <Field id="firstName" name="firstName" placeholder="Prenume" disabled render = {() => <Input label='Prenume' mbZero placeholder={props.values['firstName']} disabled />}/>
                                            <ErrorMessage name="firstName" render={msg => <div style={{color: 'red', fontWeight: 'bold'}}>{msg}</div>}/>
                                        </Col>
                                    </Row>
                                </div>

                                <div className="mt-4 mb-4 d-flex flex-column">
                                    <h5 className="register-section-title">Adresa sediu social</h5>
                                    <Row className="mt-3">
                                        <Col className="mb-3" xs={12} md={6}>
                                            <Field id="idCounty" name="idCounty" placeholder="Judet" disabled render = {() => <Input label='Judet' mbZero placeholder={props.values['idCounty']} disabled />}/>
                                            <ErrorMessage name="idCounty" render={msg => <div style={{color: 'red', fontWeight: 'bold'}}>{msg}</div>}/>
                                        </Col>
                                        <Col className="mb-3" xs={12} md={6}>
                                            <Field id="idLocalitate" name="idLocalitate" placeholder="Localitate" disabled render = {() => <Input label='Localitate' mbZero placeholder={props.values['idLocalitate']} disabled />}/> 
                                            <ErrorMessage name="idLocalitate" render={msg => <div style={{color: 'red', fontWeight: 'bold'}}>{msg}</div>}/>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="mb-3" xs={12} md={6}>
                                            <Field id="street" name="street" placeholder="Strada" disabled render = {() => <Input label='Strada' mbZero placeholder={props.values['street']} disabled />}/>
                                            <ErrorMessage name="street" render={msg => <div style={{color: 'red', fontWeight: 'bold'}}>{msg}</div>}/>
                                        </Col>
                                        <Col className="mb-3" xs={12} md={6}>
                                            <Field id="streetNo" name="streetNo" placeholder="Nr." disabled render = {() => <Input label='Nr.' mbZero placeholder={props.values['streetNo']} disabled />}/>
                                            <ErrorMessage name="streetNo" render={msg => <div style={{color: 'red', fontWeight: 'bold'}}>{msg}</div>}/>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="mb-3" xs={12} md={6}>
                                            <Field id="apartment" name="apartment" placeholder="Apartament" disabled render = {() => <Input label='Apartament' mbZero placeholder={props.values['apartment']} disabled />}/>
                                            <ErrorMessage name="apartment" render={msg => <div style={{color: 'red', fontWeight: 'bold'}}>{msg}</div>}/>
                                        </Col>
                                        <Col className="mb-3" xs={12} md={6}>
                                            <Field id="floor" name="floor" placeholder="Etaj" disabled render = {() => <Input label='Etaj' mbZero placeholder={props.values['floor']} disabled />}/>
                                            <ErrorMessage name="floor" render={msg => <div style={{color: 'red', fontWeight: 'bold'}}>{msg}</div>}/>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="mb-3" xs={12} md={6}>
                                            <Field id="postalCode" name="postalCode" placeholder="Cod postal" disabled render = {() => <Input label='Cod postal' mbZero placeholder={props.values['postalCode']} disabled />}/>
                                            <ErrorMessage name="postalCode" render={msg => <div style={{color: 'red', fontWeight: 'bold'}}>{msg}</div>}/>
                                        </Col>
                                        <Col className="mb-3" xs={12} md={6}>
                                            <Field id="fax" name="fax" placeholder="Fax" disabled render = {() => <Input label='Fax' mbZero placeholder={props.values['fax']} disabled />}/>
                                            <ErrorMessage name="fax" render={msg => <div style={{color: 'red', fontWeight: 'bold'}}>{msg}</div>}/>
                                        </Col>
                                    </Row>
                                </div>



                                <div className="mt-4 mb-4 d-flex flex-column">
                                    <h5 className="register-section-title">Adresa de corespondenta</h5>
                                    <Row className="mt-3">
                                        <Col className="mb-3" xs={12} md={6}>
                                            <Field id="idCorespCounty" name="idCorespCounty" placeholder="Judet" disabled render = {() => <Input label='Judet' mbZero placeholder={props.values['idCorespCounty']} disabled />}/>
                                            <ErrorMessage name="idCorespCounty" render={msg => <div style={{color: 'red', fontWeight: 'bold'}}>{msg}</div>}/>
                                        </Col>
                                        <Col className="mb-3" xs={12} md={6}>
                                            <Field id="idCorespLocalitate" name="idCorespLocalitate" placeholder="Localitate" disabled render = {() => <Input label='Localitate' mbZero placeholder={props.values['idCorespLocalitate']} disabled />}/>
                                            <ErrorMessage name="idCorespLocalitate" render={msg => <div style={{color: 'red', fontWeight: 'bold'}}>{msg}</div>}/>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="mb-3" xs={12} md={6}>
                                            <Field id="corespStreet" name="corespStreet" placeholder="Strada" disabled render = {() => <Input label='Strada' mbZero placeholder={props.values['corespStreet']} disabled />}/>
                                            <ErrorMessage name="corespStreet" render={msg => <div style={{color: 'red', fontWeight: 'bold'}}>{msg}</div>}/>
                                        </Col>
                                        <Col className="mb-3" xs={12} md={6}>
                                            <Field id="corespStreetNo" name="corespStreetNo" placeholder="Nr." disabled render = {() => <Input label='Nr.' mbZero placeholder={props.values['corespStreetNo']} disabled />}/>
                                            <ErrorMessage name="corespStreetNo" render={msg => <div style={{color: 'red', fontWeight: 'bold'}}>{msg}</div>}/>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="mb-3" xs={12} md={6}>
                                            <Field id="corespApartment" name="corespApartment" placeholder="Apartament" disabled render = {() => <Input label='Apartament' mbZero placeholder={props.values['corespApartment']} disabled />}/>
                                            <ErrorMessage name="apartment" render={msg => <div style={{color: 'red', fontWeight: 'bold'}}>{msg}</div>}/>
                                        </Col>
                                        <Col className="mb-3" xs={12} md={6}>
                                            <Field id="corespFloor" name="corespFloor" placeholder="Etaj" disabled render = {() => <Input label='Etaj' mbZero placeholder={props.values['corespFloor']} disabled />}/>
                                            <ErrorMessage name="corespFloor" render={msg => <div style={{color: 'red', fontWeight: 'bold'}}>{msg}</div>}/>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="mb-3" xs={12} md={6}>
                                            <Field id="corespPostalCode" name="corespPostalCode" placeholder="Cod postal" disabled render = {() => <Input label='Cod postal' mbZero placeholder={props.values['corespPostalCode']} disabled />}/>
                                            <ErrorMessage name="corespPostalCode" render={msg => <div style={{color: 'red', fontWeight: 'bold'}}>{msg}</div>}/>
                                        </Col>
                                        <Col className="mb-3" xs={12} md={6}>
                                            <Field id="fax" name="fax" placeholder="Fax" disabled render = {() => <Input label='Fax' mbZero placeholder={props.values['fax']} disabled />}/>
                                            <ErrorMessage name="fax" render={msg => <div style={{color: 'red', fontWeight: 'bold'}}>{msg}</div>}/>
                                        </Col>
                                    </Row>
                                </div>




                                {/*<div className="mt-4 mb-4 d-flex flex-column">*/}
                                {/*    <h5 className="register-section-title">Informatii persoană contact</h5>*/}
                                {/*    <Row className="mb-2 mt-3">*/}
                                {/*        <Col xs={12} md={6}>*/}
                                {/*            <Field id="contactFirstName" name="contactFirstName" placeholder="Prenume" />*/}
                                {/*            <ErrorMessage name="contactFirstName" render={msg => <div style={{color: 'red', fontWeight: 'bold'}}>{msg}</div>}/>*/}
                                {/*        </Col>*/}
                                {/*        <Col xs={12} md={6}>*/}
                                {/*            <Field id="contactLastName" name="contactLastName" placeholder="Nume" />*/}
                                {/*            <ErrorMessage name="contactLastName" render={msg => <div style={{color: 'red', fontWeight: 'bold'}}>{msg}</div>}/>*/}
                                {/*        </Col>*/}
                                {/*    </Row>*/}
                                {/*    <Row className="">*/}
                                {/*        <Col xs={12} md={6}>*/}
                                {/*            <Field id="contactMobilePhone" name="contactMobilePhone" placeholder="Telefon" />*/}
                                {/*            <ErrorMessage name="contactMobilePhone" render={msg => <div style={{color: 'red', fontWeight: 'bold'}}>{msg}</div>}/>*/}
                                {/*        </Col>*/}
                                {/*        <Col xs={12} md={6}>*/}
                                {/*            <Field id="fax" name="fax" placeholder="Fax" />*/}
                                {/*            <ErrorMessage name="fax" render={msg => <div style={{color: 'red', fontWeight: 'bold'}}>{msg}</div>}/>*/}
                                {/*        </Col>*/}
                                {/*    </Row>*/}
                                {/*</div>*/}

                                <div className="d-flex flex-column">
                                    <h5 className="register-section-title">Canale de comunicare pentru modificările de pe cont</h5>
                                    <Row className="mb-2">
                                        <Row sm={12} className="d-flex">
                                            <Col xs={4} className="d-flex align-items-center checkbox-container">
                                                <Field type="checkbox" name="emailNotifications" className="create-account-checkbox"/>
                                                <span className="checkbox-span">Email</span>
                                            </Col>
                                            <Col xs={4} className="d-flex align-items-center checkbox-container">
                                                <Field type="checkbox" name="smsNotifications" className="create-account-checkbox"/>
                                                <span className="checkbox-span">SMS</span>
                                            </Col>
                                            {/*<ErrorMessage name="email" render={msg => <div style={{color: 'red', fontWeight: 'bold'}}>{msg}</div>}/>*/}
                                        </Row>
                                        <Col className="mb-2 mt-3" sm={12} md={6}>
                                            <Field id="tfaPhone" name="tfaPhone" placeholder="Număr telefon" type="text" autoComplete="new-password"  render = {() => 
                                            <Input label='Număr telefon' mbZero onBlur={()=> {props.setFieldTouched('tfaPhone');}}
                                              onChange={(e:any) => {e.target.value.length !== 0 && props.setFieldValue('tfaPhone', e.target.value); 
                                              e.target.value.length === 0 && props.setFieldValue('tfaPhone', '');}}/>}/>
                                            <ErrorMessage name="tfaPhone" render={msg => <div style={{color: '#ea1c0a', fontWeight: 'bold'}}>{msg}</div>}/>
                                        </Col>
                                    </Row>
                                </div>

                                <div className="mt-4 mb-4 d-flex flex-column">
                                    <h5 className="register-section-title">Date cont</h5>
                                    <Row className="mt-3">
                                        <Col className="mb-3" sm={12} md={6}>
                                            <Field id="password" name="password" placeholder="Parolă" type="password" autoComplete="new-password" render = {() => 
                                            <Input label='Parolă' mbZero type='password' onBlur={()=> {props.setFieldTouched('password');}}
                                              onChange={(e:any) => {e.target.value.length !== 0 && props.setFieldValue('password', e.target.value); 
                                              e.target.value.length === 0 && props.setFieldValue('password', '');}}/>}/>
                                            <ErrorMessage name="password" render={msg => <div style={{color: '#ea1c0a', fontWeight: 'bold'}}>{msg}</div>}/>
                                            <PasswordStrengthBar password={props.values.password} scoreWords={['Puterea parolei: Slabă', 'Puterea parolei: Slabă', 'Puterea parolei: Medie', 'Puterea parolei: Bună', 'Puterea parolei: Puternică']} shortScoreWord={'Puterea parolei: Slabă'}/>
                                        </Col>
                                        <Col className="mb-3" sm={12} md={6}>
                                            <Field id="passwordConfirmation" name="passwordConfirmation" placeholder="Confirmă parola" type="password" autoComplete="new-password" render = {() => 
                                            <Input label='Confirmă parola' mbZero type='password' onBlur={()=> {props.setFieldTouched('passwordConfirmation');}}
                                              onChange={(e:any) => {e.target.value.length !== 0 && props.setFieldValue('passwordConfirmation', e.target.value); 
                                              e.target.value.length === 0 && props.setFieldValue('passwordConfirmation', '');}}/>}/>
                                            <ErrorMessage name="passwordConfirmation" render={msg => <div style={{color: '#ea1c0a', fontWeight: 'bold'}}>{msg}</div>}/>
                                        </Col>
                                    </Row>
                                </div>

                                <div className="d-flex flex-column">
                                    <Row className="mb-2-mt-3">
                                        <Col sm={12} md={6}>
                                            <div className="d-flex">
                                                <LoadCanvasTemplateNoReload id='fgn' />
                                                <FontAwesomeIcon
                                                    icon={faRotate}
                                                    style={{width: '18px', height: '18px', cursor: 'pointer', color: '#ea1c0a'}}
                                                    onClick={() => loadCaptchaEngine('6', 'white', 'black', '', 'fgn')}
                                                />
                                            </div>
                                            <div>
                                                {/* <input placeholder="Cod securitate" id="user_captcha_input" name="user_captcha_input" type="text" /> */}
                                                <Input label='Cod securitate' mbZero id="user_captcha_input" name="user_captcha_input" type="text" autoComplete="false" />
                                                </div>
                                            {error !== '' && <div style={{color: '#ea1c0a', fontWeight: 'bold'}}>{error}</div>}
                                        </Col>
                                    </Row>
                                </div>

                            </div>

                            <div className="d-flex align-items-center checkbox-container mt-2">
                                <Field type="checkbox" checked={terms} onChange={() => setTerms(prev => !prev)} className="create-account-checkbox"/>
                                <span className="checkbox-span">Sunt de acord cu <a href={`${OLD_SITE_URL}/conditii-de-utilizare`} target="_blank" className="link link--download red download-link-text" rel="noreferrer">termenii și condițiile.</a></span>
                            </div>

                            <Row className="mt-2 mb-2">
                                {resError !== '' && <div style={{color: '#ea1c0a', fontWeight: 'bold'}}>{resError}</div>}
                                {
                                    syncErrors && syncErrors.map((error: string, index: number) => (
                                        <div key={index} style={{color: '#ea1c0a', fontWeight: 'bold'}}>{error}</div>
                                    ))
                                }
                                {resSuccess === 'success' && <div style={{color: '#ea1c0a', fontWeight: 'bold'}}>Un mail a fost trimis pentru activare!</div>}
                            </Row>

                            <div className="mb-4 buttons-container d-flex flex-row align-items-center">
                                {terms ? <Button type="submit">Creare Cont</Button> : <Button disabled type="submit">Creare Cont</Button>}
                            </div>

                        </Form>
                    </Fragment>
                )}
            </Formik>
        </Fragment>
    );
};

export default FurnizorGazeNaturale;