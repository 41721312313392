import React from 'react';
import { DelgazWrapper } from '@components/global/DelgazWrapper';
import ListaFiseDraft from '@components/revizii/ListaFiseDraft/ListaFiseDraft';

export const ListaFiseDraftComponent = () => {
  return (
    <DelgazWrapper>
      <ListaFiseDraft />
    </DelgazWrapper>
  );
};
