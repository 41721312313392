import { toggleLoader } from '@actions/LoaderActions';
import { GetLocalities } from '@api/geoApi';
import { registerRSS } from '@api/rss/rss';
import { Dropdown, DropdownOptionType } from '@components/common/Dropdown';
import InputTextArea from '@components/common/Input/InputTextArea';
import { UploadFile } from '@components/common/UploadFile';
import { File } from '@components/common/UploadFile/UploadFile';
import PFForm from '@components/rss/inregistrareRSS/forms/PFForm';
import PJForm from '@components/rss/inregistrareRSS/forms/PJForm';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tippy from '@tippyjs/react';
import { Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { RSSPFSchema, RSSPFSchemaInPortfolio, RSSPJSchema, RSSPJSchemaInPortfolio } from '../../../helpers/schemas';

let PFValues = {
    cnp: '',
    nume: '',
    prenume: '',
    telefon: '',
    email: '',
    judet: 'true',
    localitate: 0,
    strada: '',
    numar: '',
    codPostal: ''
};

let PJValues = {
    cui: '',
    numeCompanie: '',
    reprezentant: '',
    telefon: '',
    email: '',
    judet: 'true',
    localitate: 0,
    strada: '',
    numar: '',
    codPostal: ''
};

const RSSModal = (props: {
    show: boolean,
    handleShow: any,
    type: number | null,
    categories: any,
    pod: string,
    modalData: any,
    LClocality: any,
    LCCounty: any,
    clearModalData: any,
    handleSuccessModal: any,
}) => {
    const [PFFormData, setPFFormData] = useState(JSON.parse(JSON.stringify(PFValues)));
    const [PJFormData, setPJFormData] = useState(JSON.parse(JSON.stringify(PJValues)));
    const [clientType, setClientType] = useState<number>(1);
    const [requestType, setRequestType] = useState<string | null>(null);
    const [category, setCategory] = useState('');
    const [subjects, setSubjects] = useState([]);
    const [subject, setSubject] = useState('');
    const [subSubjects, setSubSubjects] = useState<any[]>([]);
    const [subSubject, setSubSubject] = useState('');
    const [message, setMessage] = useState('');
    const [acord, setAcord] = useState(false);
    const [fileList, setFileList] = useState<File[]>([] as File[]);
    const [response, setResponse] = useState('');
    const [localityCode, setLocalityCode] = useState(0);
    // const [disabled, setDisabled] = useState(false);

    const dispatch = useDispatch();

    const { show, handleShow, type, categories, pod, modalData, LClocality, LCCounty, clearModalData, handleSuccessModal } = props;

    useEffect(() => {
        setSubject('');
        setSubSubject('');
    },[category]);
    useEffect(() => {
        setSubSubject('');
    },[subject]);

    const concatCompanyName = (data: any) => {
        if (data.firstName !== null && data.lastName === null) {
            return data.firstName;
        }
        if (data.firstName === null && data.lastName !== null) {
            return data.lastName;
        }
        if (data.firstName !== null && data.lastName !== null) {
            return data.firstName.concat(' ', data.lastName);
        }

        return '';
    };

    useEffect(() => {
        if (modalData) {
            if (modalData.isCompany) {
                setClientType(2);
            } else {
                setClientType(1);
            }

            PFValues = {
                cnp: modalData.clientCnp,
                nume: !modalData.firstName && !modalData.lastName ? modalData.companyName : modalData.lastName,
                prenume: modalData.firstName,
                telefon: '',
                email: '',
                judet: modalData.countyCode ? modalData.countyCode : LCCounty ?? '',
                localitate: localityCode ? localityCode : LClocality,
                strada: modalData.street !== null ? modalData.street : '',
                numar: modalData.streetNo !== null ? modalData.streetNo : '',
                codPostal: modalData.postalCode !== null ? modalData.postalCode : '',
            };
            setPFFormData(PFValues);

            PJValues = {
                cui: modalData.clientCui,
                numeCompanie: concatCompanyName(modalData),
                reprezentant: '',
                telefon: '',
                email: '',
                judet: modalData.countyCode ? modalData.countyCode : LCCounty ?? '',
                localitate: localityCode ? localityCode : LClocality ?? '',
                strada: modalData.street !== null ? modalData.street : '',
                numar: modalData.streetNo !== null ? modalData.streetNo : '',
                codPostal: modalData.postalCode !== null ? modalData.postalCode : '',
            };
            setPJFormData(PJValues);
        }
    }, [localityCode, modalData]);

    useEffect(() => {
        if (LCCounty && LClocality) {
            setPFFormData({...PFFormData, judet: LCCounty,localitate: LClocality,});
            setPJFormData({...PJFormData, judet: LCCounty,localitate: LClocality,});
        }
    }, [LCCounty, LClocality]);

    const getLocalityCode = () => {
        if (modalData) {
            GetLocalities(modalData.countyCode).then((res) => {
                res.map((c: any, i: number) => {
                    if (modalData.localityCode === c.localityCode) {
                        setLocalityCode(Number(c.localityCode));
                    }
                });
            });
        }
    };

    useEffect(() => {
        getLocalityCode();
    }, [modalData]);

    //eslint-disable-next-line
    const handleClientType = (type: number) => {
        setPFFormData(JSON.parse(JSON.stringify(PFValues)));
        setPJFormData(JSON.parse(JSON.stringify(PJValues)));
        setClientType(type);
    };

    const handlePFForm = (data: any, field: any) => {
        let newData = PFFormData;

        Object.keys(newData).map((key: string, index) => {
            if (key === field) {
                //@ts-ignore
                newData[key] = data;
            }
        });

        setPFFormData(newData);
    };

    const handlePJForm = (data: any, field: any) => {
        let newData = PJFormData;

        Object.keys(newData).map((key: string, index) => {
            if (key === field) {
                //@ts-ignore
                newData[key] = data;
            }
        });

        setPJFormData(newData);
    };

    const handleRequestType = (type: string) => {
        setRequestType(type);
    };

    const handleFileListChange = (list: File[]) => {
        setFileList(list);
    };

    const mountSubjects = () => {
        let subjects = [];

        subjects = categories.filter((i: any) => i.categorySapValue === category).map((c: any) => (
            c.subjects.map((item: any) => ({
                id: item.subjectSapValue,
                name: item.subject
            }))
        ));

        return subjects;
    };

    const mountSubSubjects = () => {
        let subSubjects: any[] = [];

        subSubjects = categories.filter((i: any) => i.categorySapValue === category);
        subSubjects = subSubjects.map((c: any) => c.subjects.filter((s: any) => s.subjectSapValue === subject).map((subj: any) => subj.subSubjects.map((subSubj: any) => ({
            id: subSubj.subSubjectSapValue,
            name: subSubj.subSubject
        }))));

        return subSubjects.flat(2);
    };

    const filePayload = () => {
        const files: any[] = [];

        fileList.map((file: File) => {
            let add = {
                NumeDocument: file.name,
                ContinutDocument: file.base64.split(',')[1]
            };

            files.push(add);
        });

        return files;
    };

    const delay = (ms: any) => new Promise(res => setTimeout(res, ms));

    const wait = async (time: number) => {
        await delay(time);
    };

    const apiCall = async (payload: any) => {
        dispatch(toggleLoader(true));

        await registerRSS(payload, 'SupplierAccount')
            .then((response) => {
                if (response) {
                    if (response.data && response.data.status && response.data.status === 'Succes' && response.data.rssId && response.data.rssId !== 0) {
                        setResponse('Solicitarea a fost trimisă cu succes.');
                        handleShow(false);
                        handleSuccessModal(true);
                        wait(3000).then(() => window.location.href = `/editare-petitie?id=${response.data.rssId}`);
                    } else {
                        setResponse('A apărut o eroare. Vă rugăm să revizuiți informațiile sau să reveniți mai târziu.');
                    }
                }
            })
            .catch((error) => console.log(error));

        dispatch(toggleLoader(false));
    };

    const submit = (values: any) => {
        setResponse('');

        const atributeGenerice = {
            IdExtern: uuidv4(),
            Scenariu: type === 1 ? 'RSS_LOCCONSUM' : 'RSS_LOCCONSUM_ALTFURNIZOR',
            DocumenteSolicitare: filePayload()
        };

        const atributeInPortofolio = {
            POD: pod,
        };

        const atributeNotInPortofolio = {
            ImobilRacordat: false,
            POD: pod,
            Strada: values.strada,
            Numar: values.numar,
            CodPostal: values.codPostal,
            Nume: values.nume || '',
            Prenume: values.prenume || '',
            NumeCompanie: values.numeCompanie || '',
            NumeReprezentantLegal: values.reprezentant || ''
        };

        const atributeSpecificeScenariului = {
            Judet: '',
            Localitate: '',
            CodJudet: LCCounty,
            CodLocalitate: LClocality,
            TipAdresa: requestType,
            CategorieSubiect: category,
            Subiect: subject,
            SubSubiect: subSubject,
            CNP: values.cnp || '',
            CUI: values.cui || '',
            Telefon: values.telefon,
            Descriere: message,
            Email: values.email,
            AcordPrelucrareDateClient: acord ? 'DA' : 'NU',
            IsCompany: clientType == 2 ? true : false,
            ...(type === 1 && atributeInPortofolio),
            ...(type === 2 && atributeNotInPortofolio)
        };

        const payload = {
            atributeGenerice,
            atributeSpecificeScenariului
        };

        if (requestType === null || category === '' || subject === '' || subSubject === '' || message === '') {
            setResponse('Câmpurile marcate cu * sunt obligatorii.');
        } else {
            apiCall(payload);
        }
    };

    useEffect(() => {
        setSubjects(mountSubjects);
        setSubSubjects([]);
    }, [category]);

    useEffect(() => {
        setSubSubjects(mountSubSubjects);
    }, [subject]);

    const clearData = () => {
        clearModalData();
        setPFFormData(JSON.parse(JSON.stringify(PFValues)));
        setPJFormData(JSON.parse(JSON.stringify(PJValues)));
        setClientType(1);
        setRequestType(null);
        setCategory('');
        setSubject('');
        setSubSubject('');
        setMessage('');
        setAcord(false);
        setFileList([]);
        setLocalityCode(0);
        setResponse('');
        // setDisabled(false);
    };

    return (
        <Modal show={show} backdrop={'static'} onHide={() => {
            handleShow(false);
            clearData();
        }} className="rss-modal">
            <Formik
                initialValues={clientType === 1 ? PFValues : PJValues}
                enableReinitialize={true}
                onSubmit={(values) => submit(values)}
                validationSchema={clientType === 1 ? type == 1 ? RSSPFSchemaInPortfolio : RSSPFSchema : type == 1 ? RSSPJSchemaInPortfolio : RSSPJSchema}
            >
                {(props) => (
                    <Form className="w-100">
                        <Modal.Header closeButton>
                            <div className="p-0">
                                <h5 className="m-0 modal-title">Detalii loc de consum</h5>
                            </div>
                        </Modal.Header>
                        <Modal.Body>
                            {(type === 1 && modalData) && <div className="consumption-point-section border-bottom mb-0">
                                <Col xs={12} sm={4}>
                                    <div className="section-red-border" />
                                    <h5 className="m-0">Detalii loc consum</h5>
                                </Col>
                                <Row className="p-3">
                                    <Col xs={12} sm={6} className="flex-column d-flex mb-1">
                                        <span>
                                            <span className="property-label">Cod loc de consum:&nbsp;</span>
                                            <span className="clc">{modalData.consumptionPointCode !== null ? modalData.consumptionPointCode : '-'}</span>
                                        </span>
                                    </Col>
                                    <Col xs={12} sm={6} className="flex-column d-flex mb-1">
                                        <span>
                                            <span className="property-label">Cod punct de masurare:&nbsp;</span>
                                            <span className="clc">{modalData.pod !== null ? modalData.pod : '-'}</span>
                                        </span>
                                        <span>
                                            <span className="property-label">Denumirea furnizorului:&nbsp;</span>
                                            <span>{modalData.supplierName !== null ? modalData.supplierName : '-'}</span>
                                        </span>
                                    </Col>
                                </Row>
                            </div>}
                            <div className="client-type-section">
                                <Row className="d-flex flex-row align-items-center justify-content-between mb-0 client-type-section-header">
                                    <Col xs={12} sm={4}>
                                        <div className="section-red-border" />
                                        <h5 className="m-0">Date identificare client</h5>
                                    </Col>
                                    <Col xs={12} sm={6} className="d-flex align-items-center justify-content-between flex-row">
                                        <h6 className="m-0">Tip client</h6>
                                        <span onClick={() => setClientType(1)} >
                                            <input
                                                className="custom-radio-button pointer"
                                                type="radio"
                                                value={1} checked={clientType === 1}
                                                onChange={() => setClientType(1)}
                                                style={{ marginRight: '6px' }}
                                            />
                                            <span className="fw-bold pointer">Persoană fizică</span>
                                        </span>
                                        <span onClick={() => setClientType(2)} >
                                            <input
                                                className="custom-radio-button pointer"
                                                type="radio"
                                                value={2} checked={clientType === 2}
                                                onChange={() => setClientType(2)}
                                                style={{ marginRight: '6px' }}
                                            />
                                            <span className="fw-bold pointer">Persoană juridică</span>
                                        </span>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12}>
                                        <div className="separator" />
                                    </Col>
                                </Row>
                            </div>

                            {clientType === 1 ?
                                <PFForm props={props} formData={PFFormData} handleData={handlePFForm} type={type} disabled={type == 1} /> :
                                <PJForm props={props} formData={PJFormData} handleData={handlePJForm} type={type} disabled={type == 1} />
                            }

                            <div className="client-section">
                                <Row className="d-flex flex-row align-items-center justify-content-between mb-2 client-section-header">
                                    <Col xs={12} sm={4}>
                                        <div className="section-red-border" />
                                        <h5 className="m-0">Date solicitare</h5>
                                    </Col>
                                    <Col xs={12} sm={7} className="d-flex align-items-center justify-content-between flex-row flex-wrap">
                                        <h6 className="m-0">Tipul interacțiunii*</h6>
                                        <span onClick={() => handleRequestType('Solicitare')} className="d-flex">
                                            <input
                                                className="custom-radio-button pointer"
                                                type="radio"
                                                value={1} checked={requestType === 'Solicitare'}
                                                onChange={() => handleRequestType('Solicitare')}
                                                style={{ marginRight: '6px' }}
                                            />
                                            <span className="fw-bold pointer">Solicitare</span>
                                            <Tippy
                                                content={
                                                    <div className="p-3" style={{ background: 'rgba(0,0,0,0.8)', color: 'white', borderRadius: '6px' }}>
                                                        <b>Solicitare</b> - petiție prin intermediul căreia soliciți informații și/sau prestarea unor servicii ce țin de activitatea noastră, a operatorului de distribuție.
                                                    </div>
                                                }
                                                duration={0}
                                                placement="bottom"
                                            >
                                                <div className="icon-div" style={{ marginLeft: '5px' }}>
                                                    <FontAwesomeIcon icon={faCircleInfo} className="icon" style={{color: '#dbdbdb'}} />
                                                </div>
                                            </Tippy>
                                        </span>

                                        <span onClick={() => handleRequestType('Sesizare')} className="d-flex">
                                            <input
                                                className="custom-radio-button pointer"
                                                type="radio"
                                                value={1} checked={requestType === 'Sesizare'}
                                                onChange={() => handleRequestType('Sesizare')}
                                                style={{ marginRight: '6px' }}
                                            />
                                            <span className="fw-bold pointer">Sesizare</span>
                                            <Tippy
                                                content={
                                                    <div className="p-3" style={{ background: 'rgba(0,0,0,0.8)', color: 'white', borderRadius: '6px' }}>
                                                        <b>Sesizare</b> - petiție prin intermediul căreia sesizezi diferite aspecte, nereguli legate de activitatea operatorului de distribuție.
                                                    </div>
                                                }
                                                duration={0}
                                                placement="bottom"
                                            >
                                                <div className="icon-div" style={{ marginLeft: '5px' }}>
                                                    <FontAwesomeIcon icon={faCircleInfo} className="icon" style={{color: '#dbdbdb'}} />
                                                </div>
                                            </Tippy>
                                        </span>

                                        <span onClick={() => handleRequestType('Reclamatie')} className="d-flex">
                                            <input
                                                className="custom-radio-button pointer"
                                                type="radio"
                                                value={1} checked={requestType === 'Reclamatie'}
                                                onChange={() => handleRequestType('Reclamatie')}
                                                style={{ marginRight: '6px' }}
                                            />
                                            <span className="fw-bold pointer">Reclamație</span>
                                            <Tippy
                                                content={
                                                    <div className="p-3" style={{ background: 'rgba(0,0,0,0.8)', color: 'white', borderRadius: '6px' }}>
                                                        <b>Reclamație</b> - petiție prin intermediul căreia îți manifești nemulțumirea față de serviciul prestat și soliciți o explicație sau remedierea situației.
                                                    </div>
                                                }
                                                duration={0}
                                                placement="bottom"
                                            >
                                                <div className="icon-div" style={{ marginLeft: '5px' }}>
                                                    <FontAwesomeIcon icon={faCircleInfo} className="icon" style={{color: '#dbdbdb'}} />
                                                </div>
                                            </Tippy>
                                        </span>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12}>
                                        <div className="separator" />
                                    </Col>
                                </Row>

                                <Row className="client justyify-content-center">
                                    <Col xs={12} sm={6} className="d-flex flex-column">
                                        <Dropdown
                                            label="Categorie subiect*"
                                            defaultPlaceholder="Alege o opțiune"
                                            options={categories.map((item: any) => ({
                                                id: item.categorySapValue,
                                                name: item.category
                                            })) as DropdownOptionType[]}
                                            onChange={(value) => {
                                                setCategory(value);
                                            }}
                                            value={category}
                                        />

                                        <Dropdown
                                            label="Subiect*"
                                            defaultPlaceholder="Alege subiectul despre care vrei să afli mai multe"
                                            options={subjects[0] as DropdownOptionType[]}
                                            onChange={(value) => setSubject(value)}
                                            value={subject}
                                        />

                                        <Dropdown
                                            label="Motivul interacțiunii (subiectul detaliat)*"
                                            defaultPlaceholder="Alege motivul pentru care dorești să ne contactezi"
                                            options={subSubjects as DropdownOptionType[]}
                                            onChange={(value) => setSubSubject(value)}
                                            value={subSubject}
                                        />
                                    </Col>

                                    <Col xs={12} sm={6}>
                                        <InputTextArea label="Mesaj*" value={message} onChange={(e) => setMessage(e.target.value)} mbZero textAreaClass='rss-message-textarea' />
                                        <span className="property-label mb-3" style={{ fontSize: '14px' }}>Maxim 2000 de caractere.</span>

                                        {/* <span>În câmpul de mai jos atașează documentele pe care dorești să ni le transmiți:</span> */}
                                    </Col>
                                    <Col xs={12} className="mb-2">
                                        <div>
                                            <UploadFile onSetFileList={handleFileListChange} fileList={fileList} index={0} acceptedExtensionsError={'Sunt acceptate următoarele formate de fișiere: '} />
                                            <span className="property-label" style={{ fontSize: '14px' }}>Se acceptă documente în format BMP, GIF, JPG și PNG, fișiere .doc., pdf. si xls. Documentele nu trebuie să depășească limita maximă de 10MB/fisier.</span>
                                        </div>
                                    </Col>

                                    {response !== '' && <Row><p className="red fw-bold mb-0">{response}</p></Row>}
                                </Row>
                                <div className="separator mt-4" />
                            </div>
                        </Modal.Body>
                        <Modal.Footer className="flex-row d-flex justify-content-between">
                            <Col xs={12} sm={8}>
                                {type === 2 &&
                                    <div>
                                        <Col onClick={() => setAcord(!acord)} className="checkbox-container pointer">
                                            <input className="create-account-checkbox" type="checkbox" checked={acord} onChange={() => setAcord(!acord)} />
                                            <span className="fw-bold checkbox-span" style={{ marginLeft: '5px' }}>Sunt de acord cu prelucrarea datelor.</span>
                                        </Col>
                                    </div>
                                }
                                <div>
                                    <span className="property-label">Pentru a putea adăuga locul de consum pe solicitare, este necesară completarea tuturor câmpurilor obligatorii, cu date valide.</span>
                                </div>
                            </Col>
                            <div>
                                <Button className='mb-1' variant="secondary" onClick={() => handleShow(false)} style={{ marginRight: '8px', height: '36px' }}>Renunță</Button>
                                <Button className='mb-1' type="submit" disabled={type === 2 && !acord}>Salvează</Button>
                            </div>
                        </Modal.Footer>
                    </Form>
                )}
            </Formik>
        </Modal>
    );
};

export default RSSModal;