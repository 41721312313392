import React from 'react';
import {Tabs, Tab} from 'react-bootstrap';
import Devices from '@components/account/Security/tabs/Devices';
import TwoFactorAuth from '@components/account/Security/tabs/TwoFactorAuth';

const Security = (props: any) => {
    return (
        <div>
            <Tabs className="security-tabs" mountOnEnter={true} unmountOnExit={true}>
                <Tab eventKey="2fa" title="Autentificare in doi pasi" mountOnEnter={true} unmountOnExit={true}>
                    <TwoFactorAuth />
                </Tab>
                <Tab eventKey="devices" title="Dispozitive" mountOnEnter={true} unmountOnExit={true}>
                    <Devices />
                </Tab>
            </Tabs>
        </div>
    );
};

export default Security;