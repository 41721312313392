import React, { useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

export type SearchInputProps = {
  setSearchInputValue: (value: any) => void;
  searchInputValue: string;
  className?: string;
  placeholder?: string;
  isMobile?: boolean;
  onSearch?: () => void;
  collapsed?: boolean;
  width?: number;
};

const SearchInput = (props: SearchInputProps) => {
  const [expanded, setExpanded] = useState(false);
  const containerRef = useRef(null);

  //on input blur
  useEffect(() => {
    const handleClickOutside = (event: any) => {
      //@ts-ignore
      if (containerRef.current && !containerRef?.current?.contains(event.target)) {
        if (expanded) {
          props.setSearchInputValue('');
          setExpanded(false);
        }
      }
    };
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [expanded]);

  if (props.collapsed && !expanded) {
    return (
      <div
        style={{
          position: 'relative',
          display: 'flex',
          alignItems: 'center',
          width: '42px',
          height: '36px',
          borderStyle: 'solid',
          borderColor: '#ea1c0a',
          borderWidth: '1px',
          justifyContent: 'center',
          cursor: 'pointer',
          transition: 'width 0.2s ease'
        }}
        className={`border-rounded ${props.className}`}
        onClick={() => {
          setExpanded(true);
        }}
      >
        <div className="red">
          <FontAwesomeIcon icon={faSearch} size={'1x'} className="login-icon" />
        </div>
      </div>
    );
  } else if (props.collapsed && expanded) {
    return (
      <div
        style={{
          position: 'relative',
          display: 'flex',
          alignItems: 'center',
          width: props.width ? props.width : props.isMobile ? '70px' : '125px',
          height: '36px',
          transition: '0.3s ease'
        }}
        ref={containerRef}
        className={props.className}
      >
        <input
          style={{
            paddingRight: '25px',
            width: props.width ? props.width - 3 : props.isMobile ? '67' : '122px',
            height: '36px',
            fontSize: props.isMobile ? '12px' : '16px',
            borderStyle: 'solid',
            borderColor: '#ea1c0a',
            borderWidth: '1px',
            color: '#EA1B0A'
          }}
          className="plceholder-red border-rounded"
          placeholder={props.placeholder}
          type="text"
          name="searchtext"
          autoComplete="off"
          onChange={(e: any) => {
            props.setSearchInputValue(e.target.value);
          }}
          onKeyPress={(event: any) => {
            if (event.key === 'Enter') {
              //Enter key press
              props.onSearch && props.onSearch();
            }
          }}
          autoFocus={true}
          value={props.searchInputValue}
        />
        <div
          className="red"
          style={{ position: 'absolute', right: '10px', cursor: 'pointer', zIndex: 5 }}
          onClick={() => {
            props.onSearch && props.onSearch();
          }}
        >
          <FontAwesomeIcon icon={faSearch} size={'1x'} className="login-icon ml-1" />
        </div>
      </div>
    );
  } else {
    return (
      <div
        style={{
          position: 'relative',
          display: 'flex',
          alignItems: 'center',
          width: props.width ? props.width : props.isMobile ? '70px' : '125px',
          height: '36px'
        }}
        className={props.className}
      >
        <input
          style={{
            paddingRight: '25px',
            width: props.width ? props.width - 3 : props.isMobile ? '67' : '122px',
            height: '36px',
            fontSize: props.isMobile ? '12px' : '16px',
            borderStyle: 'solid',
            borderColor: '#ea1c0a',
            borderWidth: '1px',
            color: '#EA1B0A'
          }}
          className="plceholder-red border-rounded"
          placeholder={props.placeholder}
          type="text"
          name="searchtext"
          autoComplete="off"
          onChange={(e: any) => {
            props.setSearchInputValue(e.target.value);
          }}
          onKeyPress={(event: any) => {
            if (event.key === 'Enter') {
              //Enter key press
              props.onSearch && props.onSearch();
            }
          }}
          value={props.searchInputValue}
        />
        <div
          className="red"
          style={{ position: 'absolute', right: '10px', cursor: 'pointer', zIndex: 5 }}
          onClick={() => {
            props.onSearch && props.onSearch();
          }}
        >
          <FontAwesomeIcon icon={faSearch} size={'1x'} className="login-icon ml-1" />
        </div>
      </div>
    );
  }
};

export default SearchInput;
