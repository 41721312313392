import React from 'react';
import { DelgazWrapper } from '@components/global/DelgazWrapper';
import { ResourcesComponent } from '@components/dev/Components/Resources/ResourcesComponent';
import { ResourcesKentico } from '@type/kentico-types';

export type ResourcesProps = { kentico: ResourcesKentico };

export const Resources = (props: ResourcesProps): JSX.Element => {
    return (
        <DelgazWrapper>
            <ResourcesComponent {...props}/>
        </DelgazWrapper>
    );
};