import { FETCH_POST_FAILURE, FETCH_POST_SUCCESS } from '@constants/ActionTypes/PostTypes';
import { Action } from './index';


export class PostsState {
    pending?: boolean;
    posts?: Array<any>;
    error?: string | null;
}

const initialState: PostsState = {
    pending: false,
    posts: [],
    error: null
};


//eslint-disable-next-line
export default (state = initialState, action: Action) => {
    const { type, payload } = action;

    switch (type) {
        case FETCH_POST_FAILURE: {
            return { ...state, ...payload };
        }
        case FETCH_POST_SUCCESS: {
            return { ...state, ...initialState };
        }
        default:
            return state;
    }
};
