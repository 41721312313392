import React from 'react';
import { DelgazWrapper } from '@components/global/DelgazWrapper';
import { BusinessCardComponent } from '@components/dev/Components/BusinessCard/BusinessCardComponent';
import { BusinessCardKentico } from '@type/kentico-types';

export type BusinessCardProps = { kentico: BusinessCardKentico };

export const BusinessCard = (props: BusinessCardProps): JSX.Element => {
    return (
        <DelgazWrapper>
            <BusinessCardComponent {...props}/>
        </DelgazWrapper>
    );
};