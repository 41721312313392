
const FooterMenu = (props: any) => {
    const kentico = props.kentico;

    return (
        <div className="row">
            {kentico.items.map((item: any, key: number) => {
                return (
                    <div key={key} className="col-md-4 mb-md-0 mb-3">
                        <h5>{item.title}</h5>
                        <ul className="list-unstyled">
                            {item.items.map((link: any, index: number) => {
                                return (
                                    <li key={index} ><a href={link.link} style={{display: 'block'}}>{link.title}</a></li>
                                );
                            })}
                        </ul>
                    </div>
                );
            })}
        </div>

    );
};

export default FooterMenu;
