import React from 'react';
import { DelgazWrapper } from '@components/global/DelgazWrapper';
import RequestView from '@components/conventions/view/RequestView';

export const CView = () => {
    return (
        <DelgazWrapper>
            <RequestView />
        </DelgazWrapper>
    );
};