import {
    isAndroid,
    isChrome,
    isEdge,
    isFirefox,
    isIE,
    isIOS,
    isMacOs,
    isOpera,
    isSafari,
    isWindows
} from 'react-device-detect';
import Moment from 'moment';

const detectOS = () => {
    if (isWindows) return 'W';
    if (isAndroid) return 'A';
    if (isIOS) return 'I';
    if (isMacOs) return 'M';

    return '';
};

const detectBrowser = () => {
    if (isChrome) return 'C';
    if (isEdge) return 'E';
    if (isFirefox) return 'F';
    if (isSafari) return 'S';
    if (isOpera) return 'O';
    if (isIE) return 'I';

    return '';
};

const returnOS = (os: string) => {
    switch (os) {
        case 'W': return 'Windows';
        case 'A': return 'Android';
        case 'I': return 'IOS';
        case 'M': return 'Mac OS';
        default: return 'Unknown';
    }
};

const returnBrowser = (browser: string) => {
    switch (browser) {
        case 'C': return 'Google Chrome';
        case 'E': return 'Microsoft Edge';
        case 'F': return 'Mozilla Firefox';
        case 'S': return 'Safari';
        case 'O': return 'Opera';
        case 'I': return 'Internet Explorer';
        default: return 'Unknown';
    }
};

const returnDate = (date: string) => {
    return Moment.unix(Number(date)).format('DD.MM.YYYY');
};

const DeviceDetect = (id: string) => {
    return detectOS() + detectBrowser() + Moment().format('X') + id;
};

const GetDeviceDetails = (deviceId: string) => {
    return {
        os: returnOS(deviceId[0]),
        browser: returnBrowser(deviceId[1]),
        date: returnDate(deviceId.substring(2, 12)),
        id: deviceId.substring(12, deviceId.length)
    };
};

const DetectOS = () => {
    let os = detectOS();
    return returnOS(os);
};

export { DeviceDetect, GetDeviceDetails, DetectOS };