import { proxy, Proxy } from '@api/azureProxy';
import moment from 'moment';

import { calorificValuesBaseUrl } from '@constants/ApiUrl';

import { County, Locality } from '@reducers/geo';
import { SuperiorCalorificTemplate, SuperiorCalorificTemplateByPodOrClc } from '@type/superiorCalorificPower';
import { SuperiorCalorificValues } from '@components/delgaz/Account/SuperiorCaloricValue/SearchByLocality';
import { SuperiorCalorificValuesByClc } from '@components/delgaz/Account/SuperiorCaloricValue/SearchByClc';


export async function searchSuperiorCalorificPower(input: SuperiorCalorificValues) {
  let county: County = input.county[0] as County;
  let locality: Locality = input.locality[0] as Locality;

  const queryParams = [
    input.county && input.county.length ? `Region=${county.geoCode}` : '',
    locality !== undefined ? `CityCode=${locality.geoCode}` : '',
    input.startDate ? `StartDate=${moment(input.startDate).format('YYYYMMDD')}` : '',
    input.endDate ? `EndDate=${moment(input.endDate).format('YYYYMMDD')}` : ''
  ].filter(Boolean);

  const proxyData: Proxy = {
    method: 'GET',
    endPoint: `${calorificValuesBaseUrl}average_caloric_values_by_city/?${queryParams.join('&')}`,
    withAuthBearer: false
  };

  return proxy<SuperiorCalorificTemplate[]>(proxyData);
}

export async function searchSuperiorCalorificPowerByClcOrPod(type: 'pod' | 'clc', input: SuperiorCalorificValuesByClc) {
  const queryParams = [
    type === 'pod' ? `Pod=${input.clc}` : `Clc=${input.clc}`,
    input.startDate ? `StartDate=${moment(input.startDate).format('YYYYMMDD')}` : '',
    input.endDate ? `EndDate=${moment(input.endDate).format('YYYYMMDD')}` : ''
  ].filter(Boolean);

  const proxyData: Proxy = {
    method: 'GET',
    endPoint: `${calorificValuesBaseUrl}average_caloric_values_by_clc/?${queryParams.join('&')}`,
    withAuthBearer: false
  };

  return proxy<SuperiorCalorificTemplateByPodOrClc[]>(proxyData);
}