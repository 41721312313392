import * as PostTypes from '@constants/ActionTypes/PostTypes';
import axios from 'axios';
import { all, call, takeLatest } from 'redux-saga/effects';

const userState = async (): Promise<{}> => {
    return await axios.get<{}>(
        'https://delgaz.eonsn.local/user-state-action',
        {
          headers: {
            Accept: 'application/json',
          },
        },
      );
};

function* fetchUserState(): Generator<any> {
    try {
        const response = yield call(userState);

        yield response;
    } catch (e) {
        console.error(e);
    }
}

function* authSaga() {
    yield all([takeLatest(PostTypes.FETCH_USER_STATE, fetchUserState)]);
}

export default authSaga;