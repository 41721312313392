import React from 'react';

import { DelgazWrapper } from '@components/global/DelgazWrapper';
import Requests from '@components/delgaz/Requests/Requests';

export type RequestsComponentProps = {
  kentico: {
    baseUrl: string;
  };
};

export const RequestsComponent = (props: RequestsComponentProps): JSX.Element => {
  return (
    <DelgazWrapper>
      <Requests {...props} />
    </DelgazWrapper>
  );
};
