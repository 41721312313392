import { proxy, Proxy } from '@api/azureProxy';
import { BackOfficeSearch } from '@components/delgaz/BackOffice/PageWrapper';
import { ONE_MINUTE } from '@constants/Utils';
import { InputValues, ResponseType } from '@contexts/backoffice-context';
import { store } from '@lib/store';
import axios from 'axios';

export type QueryParams = {
  name: string | any;
  value: string | any;
};

export async function GetResults(searchModel: BackOfficeSearch) {
  const state = store.getState();

  const proxyData: Proxy = {
    method: 'POST',
    endPoint: `/${state.backOffice.basePath}/list/`,
    withAuthBearer: false,
    data: searchModel
  };

  return proxy<ResponseType>(proxyData, {
    headers: {
      BackOfficeSecurityContext: state.backOffice.securityContext ?? ''
    }
  });
}

export async function GetConfig() {
  const state = store.getState();
  const proxyData: Proxy = {
    method: 'GET',
    endPoint: `/${state.backOffice.basePath}/config/`,
    withAuthBearer: false
  };

  return proxy(proxyData, {
    headers: {
      BackOfficeSecurityContext: state.backOffice.securityContext ?? ''
    }
  });
}

export async function Export(searchModel: BackOfficeSearch) {
  const state = store.getState();

  return axios.post(
    `${state.options.apiHost}/${state.backOffice.basePath}/export/`,
    (({ PageIndex, PageSize, ...others }) => ({ ...others }))(searchModel),
    {
      headers: {
        Authorization: 'Bearer ' + state.auth.token,
        'Ocp-Apim-Subscription-Key': state.options.subscriptionKey as string,
        BackOfficeSecurityContext: state.backOffice.securityContext ?? ''
      },
      responseType: 'arraybuffer',
      timeout: state.options.timeoutInMilSec || 15 * ONE_MINUTE
    }
  );
}

export async function ActionSave(values: InputValues, actionId: string) {
  const state = store.getState();

  const proxyData: Proxy = {
    method: 'POST',
    endPoint: `/${state.backOffice.basePath}/action?actionId=${actionId}`,
    withAuthBearer: false,
    data: values
  };

  return proxy(proxyData, {
    headers: {
      BackOfficeSecurityContext: state.backOffice.securityContext ?? ''
    }
  });
}

export async function CustomGet(url: string, urlParams?: QueryParams[]) {
  const state = store.getState();
  if (urlParams) {
    url = url + urlParams[0].value;
  }
  if (url.charAt(0) === '/') {
    url = url.slice(1);
  }
  const proxyData: Proxy = {
    method: 'GET',
    endPoint: `/${url}`,
    withAuthBearer: false
  };

  return proxy(proxyData, {
    headers: {
      BackOfficeSecurityContext: state.backOffice.securityContext ?? ''
    }
  });
}

export async function CustomPost(url: string, payload: any, urlParams?: QueryParams[]) {
  const state = store.getState();
  if (urlParams) {
    url = url + urlParams[0].value;
  }
  if (url.charAt(0) === '/') {
    url = url.slice(1);
  }
  const proxyData: Proxy = {
    method: 'POST',
    endPoint: `/${url}`,
    withAuthBearer: false,
    data: payload
  };

  return proxy(proxyData, {
    headers: {
      BackOfficeSecurityContext: state.backOffice.securityContext ?? ''
    }
  });
}

export async function GetRowDetails(payload: any) {
  const state = store.getState();

  const proxyData: Proxy = {
    method: 'POST',
    endPoint: `/${state.backOffice.basePath}/row_details/`,
    withAuthBearer: false,
    data: payload
  };

  return proxy<ResponseType>(proxyData, {
    headers: {
      BackOfficeSecurityContext: state.backOffice.securityContext ?? ''
    }
  });
}

export async function DownloadFile(url: string, isInternal: boolean, disableBoBasePath?: boolean) {
  const state = store.getState();
  const proxyData: Proxy = {
    method: 'GET',
    endPoint: disableBoBasePath ? `/${url}` : `/${state.backOffice.basePath}/${url}`,
    withAuthBearer: false
  };

  return proxy(proxyData, {
    headers: {
      BackOfficeSecurityContext: isInternal ? state.backOffice.securityContext ?? '' : ''
    },
    responseType: 'arraybuffer'
  });
}
