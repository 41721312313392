import React from 'react';
import { DelgazWrapper } from '@components/global/DelgazWrapper';
import RSSClient from '@components/rssClient/RSSClient';

export const InregistrareRSSClient = () => {
    return (
        <DelgazWrapper>
            <RSSClient />
        </DelgazWrapper>
    );
};