import { GetCounties, GetLocalities } from '@api/geoApi';
import { LoadCanvasTemplateNoReload, loadCaptchaEngine, validateCaptcha } from '@components/auth/createAccount/CaptchaLib';
import { Dropdown, DropdownOptionType } from '@components/common/Dropdown';
import { Input } from '@components/common/Input';
import { faRotate } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IState } from '@type/store';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { Fragment, useEffect, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import PasswordStrengthBar from 'react-password-strength-bar';
import { useSelector } from 'react-redux';
import { CPJSchema } from '../../../../helpers/schemas';
import { validateRomanianCIF } from '../validations/Cui';
import 'react-phone-number-input/style.css';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';

type CountyTemplate = {
  countyId: number;
  countyName: string;
  countyCode: string;
};

export type LocalityTemplate = {
  localityId: number;
  localityName: string;
  localityCode: string;
};

const ClientPersoanaJuridica = (props: any) => {
  const [terms, setTerms] = useState(false);
  const [error, setError] = useState('');
  const [counties, setCounties] = useState<CountyTemplate[]>([]);
  const [county, setCounty] = useState('');
  const [localities, setLocalities] = useState<LocalityTemplate[]>();
  //eslint-disable-next-line
  const [locality, setLocality] = useState('');
  const resError = props.error;
  const resSuccess = props.success;
  const OLD_SITE_URL: string | undefined = useSelector<IState>((state) => state.options.webApiHost) as string;
  const syncErrors = props.syncErrors;
  const [validationError, setValidationError] = useState('');
  const [phoneErr, setPhoneErr] = useState('');
  const [country, setCountry] = useState<any>('RO');

  useEffect(() => {
    GetCounties().then((res) => setCounties(res));
    loadCaptchaEngine('6', 'white', 'black', '', 'cpj');
  }, []);

  useEffect(() => {
    county !== '' && GetLocalities(county).then((res) => setLocalities(res));
  }, [county]);

  const getCountyId = (code: string) => {
    let id = null;

    counties.map((county) => {
      if (county.countyCode === code) {
        id = county.countyId;
      }
    });

    return id;
  };

  const submit = (values: any) => {
    setError('');
    setPhoneErr('');
    let user_captcha = (document.getElementById('user_captcha_input') as HTMLInputElement).value;

    if (!isValidPhoneNumber(values.contactMobilePhone)) {
      setPhoneErr('Numărul de telefon are formatul greșit.');
    } else if (validateCaptcha(user_captcha, true, 'cpj') === true && isValidPhoneNumber(values.contactMobilePhone)) {
      loadCaptchaEngine('6', 'white', 'black', '', 'cpj');
      (document.getElementById('user_captcha_input') as HTMLInputElement).value = '';

      //validare cui
      let cui = values.cui ?? ('' as string);
      const valid = validateRomanianCIF(cui);
      if (!valid) {
        setValidationError('Cui invalid.');
        return;
      } else {
        setValidationError('');
      }

      let intl_phone = values.contactMobilePhone;

      if (intl_phone.startsWith('+40')) {
          intl_phone = intl_phone.slice(2);
      }

      values = { ...values, mobilePhone: intl_phone.includes('-') ? intl_phone.replaceAll('-', '') : intl_phone };
      values = { ...values, contactPhone: intl_phone.includes('-') ? intl_phone.replaceAll('-', '') : intl_phone };
      values = { ...values, contactMobilePhone: intl_phone.includes('-') ? intl_phone.replaceAll('-', '') : intl_phone };
      values = { ...values, countyId: getCountyId(values.countyId) !== null && getCountyId(values.countyId) };
      values = { ...values, localityId: Number(values.localityId) };

      props.submit(values);
      console.log(values);
    } else {
      setError('Codul de securitate nu este valid.');
      (document.getElementById('user_captcha_input') as HTMLInputElement).value = '';
    }
  };

  return (
    <Fragment>
      <Formik
        initialValues={{
          role: 2,
          companyName: '',
          orc: '',
          cui: '',
          localityId: '',
          countyId: '',
          contactFirstName: '',
          contactLastName: '',
          contactMobilePhone: '',
          fax: '',
          emailNotifications: true,
          smsNotifications: false,
          email: '',
          password: '',
          emailConfirmation: '',
          passwordConfirmation: ''
        }}
        onSubmit={(values) => submit(values)}
        validationSchema={CPJSchema}
      >
        {(props) => (
          <Fragment>
            <Form>
              <div className="form-container">
                <div className="mt-4 mb-4 d-flex flex-column">
                  <h5 className="register-section-title">Date de identificare</h5>
                  <Row className="mt-3">
                    <Col className="mb-3" xs={12} md={6}>
                      <Field id="companyName" name="companyName" placeholder="Companie" render = {() => 
                      <Input label='Companie' mbZero onBlur={()=> {props.setFieldTouched('companyName');}}
                        onChange={(e:any) => {e.target.value.length !== 0 && props.setFieldValue('companyName', e.target.value); 
                        e.target.value.length === 0 && props.setFieldValue('companyName', '');}}/>}/>
                      <ErrorMessage name="companyName" render={(msg) => <div style={{ color: 'red', fontWeight: 'bold' }}>{msg}</div>} />
                    </Col>
                    <Col className="mb-3" xs={12} md={3}>
                      <Field id="orc" name="orc" placeholder="Registrul Comerțului" render = {() => 
                      <Input label='Registrul Comerțului' mbZero onBlur={()=> {props.setFieldTouched('orc');}}
                        onChange={(e:any) => {e.target.value.length !== 0 && props.setFieldValue('orc', e.target.value); 
                        e.target.value.length === 0 && props.setFieldValue('orc', '');}}/>}/>
                      <ErrorMessage name="orc" render={(msg) => <div style={{ color: 'red', fontWeight: 'bold' }}>{msg}</div>} />
                    </Col>
                    <Col className="mb-3" xs={12} md={3}>
                      <Field id="cui" name="cui" placeholder="CIF/CUI" render = {() => 
                      <Input label='CIF/CUI' mbZero onBlur={()=> {props.setFieldTouched('cui');}}
                        onChange={(e:any) => {e.target.value.length !== 0 && props.setFieldValue('cui', e.target.value); 
                        e.target.value.length === 0 && props.setFieldValue('cui', '');}}/>}/>
                      <ErrorMessage name="cui" render={(msg) => <div style={{ color: 'red', fontWeight: 'bold' }}>{msg}</div>} />
                    </Col>
                    <Col className="mb-3" xs={12} md={6}>
                        <Dropdown
                          options={counties.map((option, index) => (
                           {
                               id: option.countyCode,
                               name: option.countyName
                           }
                           )) as  DropdownOptionType[]}
                           label={'Județ'}
                          defaultPlaceholder={'Alege județul'}
                          displaySearch={true}
                          onChange={(value) => {
                            value && props.setFieldValue('countyId', value);
                            value.length === 0 && props.setFieldValue('countyId', '');
                            setCounty(value);
                          }}
                          mbZero
                        />
                      <ErrorMessage name="countyId" render={(msg) => <div style={{ color: 'red', fontWeight: 'bold' }}>{msg}</div>} />
                    </Col>
                    {localities && localities.length > 0 && (
                      <Col className="mb-3" xs={12} md={6}>
                        <Dropdown
                          options={localities.map((option, index) => (
                           {
                               id: option.localityId,
                               name: option.localityName
                           }
                           )) as  DropdownOptionType[]}
                           label={'Localitate'}
                          defaultPlaceholder={'Alege localitatea'}
                          displaySearch={true}
                          onChange={(value) => {
                            value && props.setFieldValue('localityId', value);
                            value.length === 0 && props.setFieldValue('localityId', '');
                            setLocality(value);
                          }}
                          mbZero
                        />
                        <ErrorMessage name="localityId" render={(msg) => <div style={{ color: 'red', fontWeight: 'bold' }}>{msg}</div>} />
                      </Col>
                    )}
                  </Row>
                </div>

                <div className="mb-4 d-flex flex-column">
                  <h5 className="register-section-title">Informatii persoană contact</h5>
                  <Row className="mt-3">
                    <Col className="mb-3" xs={12} md={6}>
                      <Field id="contactFirstName" name="contactFirstName" placeholder="Prenume" render = {() => 
                      <Input label='Prenume' mbZero onBlur={()=> {props.setFieldTouched('contactFirstName');}}
                        onChange={(e:any) => {e.target.value.length !== 0 && props.setFieldValue('contactFirstName', e.target.value); 
                        e.target.value.length === 0 && props.setFieldValue('contactFirstName', '');}}/>}/>
                      <ErrorMessage
                        name="contactFirstName"
                        render={(msg) => <div style={{ color: 'red', fontWeight: 'bold' }}>{msg}</div>}
                      />
                    </Col>
                    <Col className="mb-3" xs={12} md={6}>
                      <Field id="contactLastName" name="contactLastName" placeholder="Nume" render = {() => 
                      <Input label='Nume' mbZero onBlur={()=> {props.setFieldTouched('contactLastName');}}
                        onChange={(e:any) => {e.target.value.length !== 0 && props.setFieldValue('contactLastName', e.target.value); 
                        e.target.value.length === 0 && props.setFieldValue('contactLastName', '');}}/>}/>
                      <ErrorMessage
                        name="contactLastName"
                        render={(msg) => <div style={{ color: 'red', fontWeight: 'bold' }}>{msg}</div>}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col className="mb-3" xs={12} md={6}>
                      <PhoneInput 
                        international
                        countryCallingCodeEditable={false}
                        defaultCountry='RO'
                        value={props.values.contactMobilePhone}
                        onCountryChange={(e) => {
                          setCountry(e);
                          
                          if (e !== 'RO') {
                              props.setFieldValue('emailNotifications', true);
                              props.setFieldValue('smsNotifications', false);
                          }
                      }}
                      onChange={(e) => {
                          props.setFieldValue('contactMobilePhone', e);

                          if (e && !isValidPhoneNumber(e)) {
                              setPhoneErr('Numărul de telefon are formatul greșit.');
                          } else if (e && isValidPhoneNumber(e)) {
                              setPhoneErr('');
                          }
                      }}
                      />
                      { phoneErr !== '' && <p className="red fw-bold">{phoneErr}</p> }
                    </Col>
                    <Col className="mb-3" xs={12} md={6}>
                      <Field id="fax" name="fax" placeholder="Fax" render = {() => 
                      <Input label='Fax' mbZero onBlur={()=> {props.setFieldTouched('fax');}}
                        onChange={(e:any) => {e.target.value.length !== 0 && props.setFieldValue('fax', e.target.value); 
                        e.target.value.length === 0 && props.setFieldValue('fax', '');}}/>}/>
                      <ErrorMessage name="fax" render={(msg) => <div style={{ color: 'red', fontWeight: 'bold' }}>{msg}</div>} />
                    </Col>
                  </Row>
                </div>

                <div className="d-flex flex-column">
                  <h5 className="register-section-title">Canale de comunicare pentru modificările de pe cont</h5>
                  <Row className="mb-2">
                    <Row sm={12} className="d-flex">
                      <Col xs={4} className="d-flex align-items-center checkbox-container">
                        <Field type="checkbox" name="emailNotifications" className="create-account-checkbox" disabled={country !== 'RO'} />
                        <span className="checkbox-span">Email</span>
                      </Col>
                      <Col xs={4} className="d-flex align-items-center checkbox-container">
                        <Field type="checkbox" name="smsNotifications" className="create-account-checkbox" disabled={country !== 'RO'} />
                        <span className="checkbox-span">SMS</span>
                      </Col>
                    </Row>
                  </Row>
                </div>

                <div className="d-flex flex-column mt-4">
                  <h5 className="register-section-title">Date cont</h5>
                  <Row className="mt-3">
                    <Col className="mb-3" sm={12} md={6}>
                      <Field id="email" name="email" placeholder="Email" render = {() => 
                      <Input label='Email' mbZero onBlur={()=> {props.setFieldTouched('email');}}
                        onChange={(e:any) => {e.target.value.length !== 0 && props.setFieldValue('email', e.target.value); 
                        e.target.value.length === 0 && props.setFieldValue('email', '');}}/>}/>
                      <ErrorMessage name="email" render={(msg) => <div style={{ color: '#ea1c0a', fontWeight: 'bold' }}>{msg}</div>} />
                    </Col>
                    <Col className="mb-3" sm={12} md={6}>
                      <Field id="emailConfirmation" name="emailConfirmation" placeholder="Confirmă Email" render = {() => 
                      <Input label='Confirmă Email' mbZero onBlur={()=> {props.setFieldTouched('emailConfirmation');}}
                        onChange={(e:any) => {e.target.value.length !== 0 && props.setFieldValue('emailConfirmation', e.target.value); 
                        e.target.value.length === 0 && props.setFieldValue('emailConfirmation', '');}}/>}/>
                      <ErrorMessage
                        name="emailConfirmation"
                        render={(msg) => <div style={{ color: '#ea1c0a', fontWeight: 'bold' }}>{msg}</div>}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col className="mb-3" sm={12} md={6}>
                      <Field id="password" name="password" placeholder="Parolă" type="password" autoComplete="new-password" render = {() => 
                      <Input label='Parolă' mbZero type='password' onBlur={()=> {props.setFieldTouched('password');}}
                        onChange={(e:any) => {e.target.value.length !== 0 && props.setFieldValue('password', e.target.value); 
                        e.target.value.length === 0 && props.setFieldValue('password', '');}}/>}/>
                      <ErrorMessage name="password" render={(msg) => <div style={{ color: '#ea1c0a', fontWeight: 'bold' }}>{msg}</div>} />
                      <PasswordStrengthBar
                        password={props.values.password}
                        scoreWords={[
                          'Puterea parolei: Slabă',
                          'Puterea parolei: Slabă',
                          'Puterea parolei: Medie',
                          'Puterea parolei: Bună',
                          'Puterea parolei: Puternică'
                        ]}
                        shortScoreWord={'Puterea parolei: Slabă'}
                      />
                    </Col>
                    <Col className="mb-3" sm={12} md={6}>
                      <Field id="passwordConfirmation" name="passwordConfirmation" placeholder="Confirmă parola" type="password" autoComplete="new-password" render = {() => 
                      <Input label='Confirmă parola' mbZero type='password' onBlur={()=> {props.setFieldTouched('passwordConfirmation');}}
                        onChange={(e:any) => {e.target.value.length !== 0 && props.setFieldValue('passwordConfirmation', e.target.value); 
                        e.target.value.length === 0 && props.setFieldValue('passwordConfirmation', '');}}/>}/>
                      <ErrorMessage
                        name="passwordConfirmation"
                        render={(msg) => <div style={{ color: '#ea1c0a', fontWeight: 'bold' }}>{msg}</div>}
                      />
                    </Col>
                  </Row>
                </div>

                <div className="d-flex flex-column">
                  <Row className="mb-2-mt-3">
                    <Col sm={12} md={6}>
                      <div className="d-flex">
                        <LoadCanvasTemplateNoReload id="cpj" />
                        <FontAwesomeIcon
                          icon={faRotate}
                          style={{ width: '18px', height: '18px', cursor: 'pointer', color: '#ea1c0a' }}
                          onClick={() => loadCaptchaEngine('6', 'white', 'black', '', 'cpj')}
                        />
                      </div>
                      <div>
                        {/* <input placeholder="Cod securitate" id="user_captcha_input" name="user_captcha_input" type="text" /> */}
                        <Input label='Cod securitate' mbZero id="user_captcha_input" name="user_captcha_input" type="text" autoComplete="false" />
                      </div>
                      {error !== '' && <div style={{ color: '#ea1c0a', fontWeight: 'bold' }}>{error}</div>}
                    </Col>
                  </Row>
                </div>
              </div>

              <div className="d-flex align-items-center checkbox-container mt-2">
                <Field type="checkbox" checked={terms} onChange={() => setTerms((prev) => !prev)} className="create-account-checkbox" />
                <span className="checkbox-span">
                  Sunt de acord cu{' '}
                  <a href={`${OLD_SITE_URL}/conditii-de-utilizare`} target="_blank" className="link link--download red download-link-text" rel="noreferrer">
                    termenii și condițiile.
                  </a>
                </span>
              </div>

              <Row className="mt-2 mb-2">
                {resError !== '' && <div style={{ color: '#ea1c0a', fontWeight: 'bold' }}>{resError}</div>}
                {syncErrors.map((error: string, index: number) => (
                  <div key={index} style={{ color: '#ea1c0a', fontWeight: 'bold' }}>
                    {error}
                  </div>
                ))}
                {validationError && <div style={{ color: '#ea1c0a', fontWeight: 'bold' }}>{validationError}</div>}
                {resSuccess === 'success' && (
                  <div style={{ color: '#ea1c0a', fontWeight: 'bold' }}>Un mail a fost trimis pentru activare!</div>
                )}
              </Row>

              <div className="mb-4 buttons-container d-flex flex-row align-items-center">
                {terms ? (
                  <Button type="submit">Creare Cont</Button>
                ) : (
                  <Button disabled type="submit">
                    Creare Cont
                  </Button>
                )}
              </div>
            </Form>
          </Fragment>
        )}
      </Formik>
    </Fragment>
  );
};

export default ClientPersoanaJuridica;