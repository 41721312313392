import React, { Fragment, useState, useEffect } from 'react';
import Search from './Search';
import CPTableHead from './table/CPTableHead';
import CPTableRow from './table/CPTableRow';
import ButtonFilters from '@components/common/Button/ButtonFilters';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import ReportDateRange from '../DateSelector/ReportDateRange';
import { CountyTemplate, LocalityTemplate } from '@type/geo';
import { GetCounties, GetLocalities } from '@api/geoApi';
import { useDispatch } from 'react-redux';
import { toggleLoader } from '@actions/LoaderActions';
import { getPartnerConsumptionPointsFiltered, getPartnersFiltered } from '@api/user/partners';
import { getClientPortofolio, getSupplierEEPortofolio, getSupplierGNPortofolio } from '@api/user/user';
import { REPORT_DATE_RANGE_TYPE_NONE } from '@constants/requests/RequestsConstants';
import Pagination from '@components/common/Pagination/Pagination';
import { UserJwtType } from '@type/userJwt';
import { jwtDecode } from 'jwt-decode';
import { IState } from '@type/store';
import { useSelector } from 'react-redux';

const CPS_SELECT_LIMIT = 50;

type ConsumptionPoint = {
  clientCode: string;
  clientName: string;
  consumptionCode: string;
  pod: string;
  address: string;
  contractStatus: string;
  meterNumber: string;
  connectionStatus: string;
  tariffCategory: string;
  srmp: string;
  minPressure: string;
  maxPressure: string;
  technicalMinPressure: string;
  contractCapacity: number;
  contractDuration: string;
  supplierCode: string;
  partnerId: string;
  invoiceClass: string;
  tariff: string;
  contractNo: string;
  codLocConsum: string;
  codClient: string;
  adresa: string;
  denumireLocConsum: string;
  clcName: string;
  dataAdaugare: string;
  contractStartDate: string;
  denumireClient: string;
};

type Partner = {
  partnerCode: string;
  partnerName: string;
  cps: ConsumptionPoint[];
  checked: boolean;
  toggled: boolean;
  hide: boolean;
};

const LocuriConsum = (props: {
  utility: number;
  metterTypes: string[] | any;
  mmrOnly: boolean | any;
  supplierId: string;
  handleChangeConsumptionPointsChecked: (cpc: any) => void;
  reportDateRangeType: any;
  setDate: (date: any) => void;
  isClient: boolean;
  baseUrl: any;
}) => {
  //table data
  const [partners, setPartners] = useState<Partner[]>([]);
  const [consumptionPoints, setConsumptionPoints] = useState<ConsumptionPoint[]>([]);
  const [consumptionPointsChecked, setConsumptionPointsChecked] = useState<ConsumptionPoint[]>([]);
  const [partnersFetched, setPartnersFetched] = useState<Partner[]>([]);

  //search
  const [searchCLC, setSearchCLC] = useState('');
  const [searchPod, setSearchPod] = useState('');
  const [searchPartnerCode, setSearchPartnerCode] = useState('');
  const [counties, setCounties] = useState<CountyTemplate[]>([]);
  const [county, setCounty] = useState('');
  const [localities, setLocalities] = useState<LocalityTemplate[]>();
  const [locality, setLocality] = useState('');
  const [didSearch, setDidSearch] = useState(false);
  const [toggleSearch, setToggleSearch] = useState(false);
  const [errorNoResultsForSearch, setErrorNoResultsForSearch] = useState(false);

  //paginare
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const paginate = (pageNumber: number) => setCurrentPage(pageNumber);
  const setElementsPerPage = (number: number) => setPerPage(number);
  const [totalItemsCount, setTotalItemsCount] = useState(0);
  const [searchAtUseEffectDisabled, setSearchAtUseEffectDisabled] = useState(false);

  const [errorCPSSelectLimit, setErrorCPSSelectLimit] = useState(false);
  const [showClientInfoModal, setShowClientInfoModal] = useState(false);

  const [isFetchingConsumptionPoints, setIsFetchingConsumptionPoints] = useState(false);

  const token: string | undefined = useSelector<IState>((state) => state?.auth?.token) as string;
  let jwt: UserJwtType = jwtDecode(token);

  const dispatch = useDispatch();

  useEffect(() => {
    props.handleChangeConsumptionPointsChecked(consumptionPointsChecked);
  }, [consumptionPointsChecked]);

  useEffect(() => {
    if (didSearch) {
      if (!searchAtUseEffectDisabled) {
        searchConsumptionPoints(props.utility, county, locality, searchPod, searchCLC, searchPartnerCode);
      }
    } else getUserPartners();
  }, [currentPage, perPage, searchAtUseEffectDisabled, didSearch]);

  useEffect(() => {
    let newPartners: any = [...partners];
    let newPartnersFetched: any = [...partnersFetched];
    let newPartnersUpdated: boolean = false;
    let newPartnersFetchedUpdated: boolean = false;

    newPartners.forEach((partner: Partner, index: number) => {
      let partnerFetched: any = partnersFetched.find((pf) => pf.partnerCode == partner.partnerCode);
      if (partner.cps.length > 0) {
        newPartnersFetchedUpdated = true;
        if (!partnerFetched) {
          newPartnersFetched.push(partner);
        } else {
          const index = newPartnersFetched.findIndex((existingPartner: Partner) => existingPartner.partnerCode === partner.partnerCode);
          newPartnersFetched[index] = partner;
        }
      } else if (partnerFetched && partnerFetched.partnerCode == partner.partnerCode) {
        newPartnersUpdated = true;
        newPartners = [...newPartners.slice(0, index), partnerFetched, ...partners.slice(index + 1)];
      }
    });
    if (newPartnersUpdated) {
      setPartners(newPartners);
    }
    if (newPartnersFetchedUpdated) {
      setPartnersFetched(newPartnersFetched);
    }
  }, [partners]);

  useEffect(() => {
    if (didSearch && consumptionPoints.length > 0) {
      let partnersForfilteredCps: Partner[] = [];
      consumptionPoints.forEach((cp: ConsumptionPoint) => {
        let partner: Partner = {
          partnerName: cp.denumireClient ? cp.denumireClient : cp.clientName,
          partnerCode: cp.clientCode ? cp.clientCode : cp.codClient,
          cps: [] as ConsumptionPoint[],
          checked: false
        } as Partner;
        if (!partnersForfilteredCps.find((p) => p.partnerCode == partner.partnerCode)) {
          partnersForfilteredCps.push(partner);
        }
      });

      partnersForfilteredCps = partnersForfilteredCps.map((partner: any) => {
        let partnerCps = consumptionPoints.filter((cp: any) => {
          if (cp.clientCode) {
            return cp.clientCode == partner.partnerCode;
          } else if (cp.codClient) {
            return cp.codClient == partner.partnerCode;
          }
        });
        partner.cps = partnerCps;
        return partner;
      });
      setPartners(partnersForfilteredCps);
    }
  }, [didSearch, consumptionPoints]);

  useEffect(() => {
    if (props.baseUrl.includes('EE')) {
      GetCounties('EE').then((res) => setCounties(res));
    } else {
      GetCounties('GN').then((res) => setCounties(res));
    }
    
    renderClientInfoMessage();
  }, []);

  useEffect(() => {
    county !== '' && GetLocalities(county).then((res) => setLocalities(res));
  }, [county]);

  const getUserPartners = () => {
    dispatch(toggleLoader(true));
    let metterTypesString = '';
    if (props.metterTypes) {
      metterTypesString = props.metterTypes.join(',');
    }
    getPartnersFiltered(props.utility, metterTypesString, currentPage, perPage, props.mmrOnly)
      .then((res: any) => {
        dispatch(toggleLoader(false));
        if (res.status == 200) {
          let newPartners = res.data.items.map((partner: Partner) => {
            return { ...partner, checked: false, toggled: false, cps: [] };
          });
          setPartners(newPartners);
          setTotalItemsCount(res.data.metadata.totalItemsCount);
        }
      })
      .catch((error) => {
        dispatch(toggleLoader(false));
      });
  };

  const getCpsForPartner = async (partner: Partner, index: number) => {
    dispatch(toggleLoader(true));
    let cps: any = [];
    let metterTypesString = '';
    if (props.metterTypes) {
      metterTypesString = props.metterTypes.join(',');
    }
    await getPartnerConsumptionPointsFiltered(partner.partnerCode, props.utility, metterTypesString, props.mmrOnly)
      .then((res) => {
        dispatch(toggleLoader(false));
        if (res.status == 200) {
          let partnerCps = res.data.data.items;
          cps = res.data.data.items;
          const newPartner = { ...partner, cps: partnerCps, toggled: true };
          setPartners([...partners.slice(0, index), newPartner, ...partners.slice(index + 1)]);
        }
      })
      .catch((error) => {
        dispatch(toggleLoader(false));
      });
    return cps;
  };

  const localityChange = (locality: any) => {
    setLocality(locality);
  };

  const CLCChange = (clc: any) => {
    setSearchCLC(clc);
  };

  const PodChange = (pod: any) => {
    setSearchPod(pod);
  };

  const PartnerCodeChange = (partnerCode: any) => {
    setSearchPartnerCode(partnerCode);
  };

  const countyChange = (county: any) => {
    setCounty(county);
  };

  const submitSearch = () => {
    setSearchAtUseEffectDisabled(true);
    setCurrentPage(1);
    setSearchAtUseEffectDisabled(false);
    setDidSearch(true);
    searchConsumptionPoints(props.utility, county, locality, searchPod, searchCLC, searchPartnerCode, true);
  };

  const resetSearch = () => {
    setSearchAtUseEffectDisabled(true);
    setCurrentPage(1);
    setSearchAtUseEffectDisabled(false);
    setErrorNoResultsForSearch(false);
    setCounty('');
    setLocality('');
    setSearchPod('');
    setSearchCLC('');
    setSearchPartnerCode('');
    setDidSearch(false);
  };

  const searchConsumptionPoints = async (
    utility?: any,
    county?: any,
    locality?: any,
    pod?: any,
    clc?: any,
    partnerCode?: any,
    firstPage?: boolean
  ) => {
    if (isFetchingConsumptionPoints) return;
    setIsFetchingConsumptionPoints(true);
    dispatch(toggleLoader(true));
    setErrorNoResultsForSearch(false);
    //SEARCH
    let payload: any = {
      supplierId: props.supplierId,
      utility: utility,
      pod: pod,
      clc: clc,
      partnerCode: partnerCode,
      pageSize: 10000000,
      pageNumber: firstPage ? 1 : currentPage
    };

    if (props.metterTypes) {
      payload.meterTypes = props.metterTypes.join(',');
    }
    if (props.mmrOnly != null) {
      payload.mmrOnly = !!props.mmrOnly;
    }
    if (county) {
      let id = 0;
      counties.map((item) => {
        if (item.countyCode === county) {
          id = item.countyId;
        }
      });
      payload.countyId = id;
    }
    if (locality) {
      payload.localityId = Number(locality);
    }

    let newList: any[] = [];

    if (props.isClient) {
      await getClientPortofolio(payload)
        .then((res) => {
          res &&
            res.items &&
            res.items.map((item: ConsumptionPoint) => {
              item.denumireLocConsum = item.denumireLocConsum ? item.denumireLocConsum : item.clcName ?? '';
              item.dataAdaugare = item.dataAdaugare ? item.dataAdaugare : item.contractStartDate ?? '';
              newList.push(item);
            });
          setTotalItemsCount(res.metadata.totalItemsCount);
        })
        .catch((e: any) => {});
    } else if (utility == 1) {
      await getSupplierEEPortofolio(payload)
        .then((res) => {
          res &&
            res.items &&
            res.items.map((item: ConsumptionPoint) => {
              item.denumireLocConsum = item.denumireLocConsum ? item.denumireLocConsum : item.clcName ?? '';
              item.consumptionCode = item.consumptionCode ? item.consumptionCode : item.codLocConsum ?? '';
              item.address = item.address ? item.address : item.adresa ?? '';
              newList.push(item);
            });
          setTotalItemsCount(res.metadata.totalItemsCount);
        })
        .catch((e: any) => console.log(e));
    } else {
      await getSupplierGNPortofolio(payload)
        .then((res) => {
          res &&
            res.items &&
            res.items.map((item: ConsumptionPoint) => {
              item.dataAdaugare = item.dataAdaugare ? item.dataAdaugare : item.contractStartDate ?? '';
              item.denumireLocConsum = item.denumireLocConsum ? item.denumireLocConsum : item.clcName ?? '';
              newList.push(item);
            });
          setTotalItemsCount(res.metadata.totalItemsCount);
        })
        .catch((e: any) => console.log(e));
    }

    if (newList.length == 0) {
      setErrorNoResultsForSearch(true);
    }
    setConsumptionPoints(newList);
    dispatch(toggleLoader(false));
    setIsFetchingConsumptionPoints(false);
  };

  const getIsChecked = (consumptionPoint: any) => {
    if (!consumptionPoint.consumptionCode) {
      consumptionPoint.consumptionCode = consumptionPoint.codLocConsum;
    }
    return !!consumptionPointsChecked.find((cp) => cp.consumptionCode == consumptionPoint.consumptionCode);
  };

  const setConsumptionPointChecked = (consumptionPoint: any) => {
    if (!consumptionPoint.consumptionCode) {
      consumptionPoint.consumptionCode = consumptionPoint.codLocConsum;
    }

    let exists = !!consumptionPointsChecked.find((cp) => cp.consumptionCode == consumptionPoint.consumptionCode);
    if (exists) {
      setConsumptionPointsChecked([...consumptionPointsChecked.filter((cp) => cp.consumptionCode !== consumptionPoint.consumptionCode)]);
    } else {
      if (consumptionPointsChecked.length == CPS_SELECT_LIMIT) {
        setErrorCPSSelectLimit(true);
        return;
      }
      setConsumptionPointsChecked([...consumptionPointsChecked, consumptionPoint]);
    }
  };

  const getIsPartnerChecked = (partner: any) => {
    let partnerCps = partner.cps;

    if (partnerCps.length == 0) {
      return false;
    }
    let partnerChecked = true;
    partnerCps.forEach((partnerCp: ConsumptionPoint) => {
      let isCpChecked = consumptionPointsChecked.find((cpChecked) => {
        return cpChecked.consumptionCode == partnerCp.consumptionCode;
      });
      if (!isCpChecked) {
        partnerChecked = false;
        return;
      }
    });
    return partnerChecked;
  };

  const setPartnerChecked = async (partner: any, index: number) => {
    let partnerToggled;
    let partnerCps = partner.cps;
    if (partnerCps.length == 0) {
      //to fetch partner cps
      let fetchedPartnerCps = await getCpsForPartner(partner, index);
      partnerCps = fetchedPartnerCps;
      partner.cps = fetchedPartnerCps;
    }
    let partnerChecked = getIsPartnerChecked(partner);
    if (partnerChecked) {
      setConsumptionPointsChecked([
        ...consumptionPointsChecked.filter((cpChecked) => {
          let exists = partnerCps.find((prCp: ConsumptionPoint) => {
            return cpChecked.consumptionCode == prCp.consumptionCode;
          });
          return !exists;
        })
      ]);
      partnerToggled = { ...partner, toggled: false };
    } else {
      let partnerCpsNotAddedYet = partnerCps.filter((partnercp: ConsumptionPoint) => {
        return !consumptionPointsChecked.find((cpChecked) => {
          return cpChecked.consumptionCode == partnercp.consumptionCode;
        });
      });
      let newConsumptionPointsChecked = [...consumptionPointsChecked, ...partnerCpsNotAddedYet];
      if (newConsumptionPointsChecked.length > CPS_SELECT_LIMIT) {
        newConsumptionPointsChecked = newConsumptionPointsChecked.slice(0, CPS_SELECT_LIMIT);
      }
      setConsumptionPointsChecked(newConsumptionPointsChecked);
      partnerToggled = { ...partner, toggled: true };
    }
    setPartners([...partners.slice(0, index), partnerToggled, ...partners.slice(index + 1)]);
  };

  const renderConfigInfoMessage = () => {
    if (props.baseUrl.includes('OrdersConsumuriFacturateLunarEE')) {
      return 'Configurarea raportului este disponbilă pentru datele din ultimele 36 de luni.';
    } else if (props.baseUrl.includes('OrdersRapoarteIndecsiEE')) {
      return 'Configurarea raportului este disponbilă pentru datele din ultimele 36 de luni.';
    } else if (props.baseUrl.includes('OrdersRapoarteDateMasuraEE')) {
      return 'Configurarea raportului este disponbilă pentru datele din luna în curs și luna anterioară.';
    } else if (props.baseUrl.includes('OrdersRapoarteDateTehniceEE')) {
      return '';
    } else if (props.baseUrl.includes('OrdersConsumuriFacturateLunarGN')) {
      return 'Configurarea raportului este disponbilă pentru datele din ultimele 36 de luni.';
    } else if (props.baseUrl.includes('OrdersConsumuriZilniceGN')) {
      return 'Configurarea raportului este disponbilă pentru datele din ultimele 36 de luni.';
    } else return '';
  };

  const renderClientInfoMessage = () => {
    if (
      (jwt.groups.includes('ClientFizic') || jwt.groups.includes('ClientJuridic')) &&
      props.baseUrl.includes('OrdersRapoarteDateMasuraEE')
    ) {
      setShowClientInfoModal(true);
    }
  };

  const renderClientInfoModal = () => {
    return (
      <Modal centered show={showClientInfoModal} onHide={() => setShowClientInfoModal(false)} backdrop={'static'}>
        <Modal.Header closeButton style={{ paddingTop: '35px' }}></Modal.Header>
        <Modal.Body>
          <p>
            În această secțiune sunt disponibile datele de măsură pentru locurile de consum echipate cu sisteme de măsurare inteligentă
            (SMI).
          </p>
          <p>Valorile afișate sunt măsurate de către Delgaz Grid, în calitate de operator de distribuție.</p>
          <p>Pentru orice întrebări legate de valorile facturate, te rugăm să contactezi furnizorul de energie electrică.</p>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setShowClientInfoModal(false)}>Continuă</Button>
        </Modal.Footer>
      </Modal>
    );
  };

  return (
    <div>
      {partners.length === 0 && didSearch === false && (
        <div className="mt-3 d-flex justify-content-center">
          <span>Momentan nu aveți niciun punct de consum!</span>
        </div>
      )}

      <Row className="mt-2">
        <Col xs={12} sm={5} md={4}>
          <div
            style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%', flexDirection: 'column' }}
            className="m-1"
          >
            <div>
              <p className="p-gray m-0">
                Alege locurile de consum{props.reportDateRangeType != REPORT_DATE_RANGE_TYPE_NONE.id ? ' și perioada de raportare' : ''}.
              </p>
              <p className="p-gray m-0">Poți selecta maxim 50 de locuri de consum.</p>
              <p className="p-gray">{renderConfigInfoMessage()}</p>
            </div>
          </div>
        </Col>
        <Col xs={12} sm={7} md={5} className="mt-3">
          <ReportDateRange reportDateRangeType={props.reportDateRangeType} setDate={props.setDate} />
        </Col>
        <Col>
          {(partners.length > 0 || didSearch) && (
            <div className="m-3 d-flex justify-content-end">
              <ButtonFilters toggled={toggleSearch} onClick={() => setToggleSearch((prev) => !prev)} />
            </div>
          )}
        </Col>
      </Row>

      {toggleSearch && (
        <Search
          counties={counties}
          localities={localities}
          handleLocalityChange={localityChange}
          handleCountyChange={countyChange}
          submitSearch={submitSearch}
          locality={locality}
          county={county}
          clcChange={CLCChange}
          podChange={PodChange}
          partnerCodeChange={PartnerCodeChange}
          reset={resetSearch}
          pod={searchPod}
          clc={searchCLC}
          partnerCode={searchPartnerCode}
          utility={props.utility}
        />
      )}
      {partners.length > 0 && (
        <div className="m-3">
          <p>Total locuri de consum selectate: {consumptionPointsChecked.length}</p>
        </div>
      )}

      {partners.length > 0 && !errorNoResultsForSearch && (
        <Fragment>
          {errorCPSSelectLimit && (
            <div className="m-3 mt-0 mb-1" style={{ color: 'red', fontWeight: 'bold' }}>
              Se pot adăuga cel mult {CPS_SELECT_LIMIT} locuri de consum pe o solicitare.
            </div>
          )}
          <div className="m-3 mt-0 general-table-container">
            <table>
              <CPTableHead utility={props.utility} />
              <tbody>
                <>
                  {partners.map((partner: any, index: number) => {
                    if (partner.hide) {
                      return;
                    }
                    let partnerCps = partner.cps;
                    return (
                      <Fragment key={index}>
                        <CPTableRow
                          key={`partner${index}`}
                          utility={props.utility}
                          isClient={props.isClient}
                          groupByPartner={true}
                          partner={partner}
                          checked={() => getIsPartnerChecked(partner)}
                          setChecked={() => setPartnerChecked(partner, index)}
                          setPartnerToggled={() => {
                            let partnerToggled = { ...partner, toggled: partner.toggled ? false : true };
                            setPartners([...partners.slice(0, index), partnerToggled, ...partners.slice(index + 1)]);
                            if (partnerToggled.toggled && partner.cps.length == 0) {
                              getCpsForPartner(partner, index);
                            }
                          }}
                        />
                        {partner.toggled && (
                          <>
                            {partnerCps.map((item: ConsumptionPoint, index: number) => (
                              <CPTableRow
                                item={item}
                                key={index}
                                utility={props.utility}
                                isClient={props.isClient}
                                checked={() => getIsChecked(item)}
                                setChecked={() => setConsumptionPointChecked(item)}
                              />
                            ))}
                          </>
                        )}
                      </Fragment>
                    );
                  })}
                </>
              </tbody>
            </table>
          </div>
          {!didSearch && (
            <Pagination
              currentPage={currentPage}
              totalElements={totalItemsCount}
              perPage={perPage}
              paginate={paginate}
              setElementsPerPage={setElementsPerPage}
            />
          )}
        </Fragment>
      )}
      {didSearch && errorNoResultsForSearch && (
        <div className="m-3" style={{ fontWeight: 'bold', color: 'red' }}>
          Nu există locuri de consum care să corespundă filtrelor dvs.
        </div>
      )}

      {renderClientInfoModal()}
    </div>
  );
};

export default LocuriConsum;
