import React, { useEffect, useState } from 'react';

import { BackOfficeContext, BackOfficeContextType, ResponseType } from '@contexts/backoffice-context';
import { faCircleArrowRight, faSort, faSortDown, faSortUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ColumnValue from './ColumnValue';
import { BackOfficeSearch } from './PageWrapper';
import RowAction from './RowAction';

export type ValueToSet = {
  name: string;
  value: string;
  itemTypeId: number;
};

type ResponseListingProps = {
  response?: ResponseType | null;
  onSort: (column: string) => void;
  searchModel: BackOfficeSearch;
};

export const defaultEvent = { preventDefault: () => undefined, persist: () => undefined } as React.SyntheticEvent;

const ResponseListing = ({ response, onSort, searchModel }: ResponseListingProps) => {
  const { config, isFullWidth, updateDetailedElement, detailedElement } = React.useContext(BackOfficeContext) as BackOfficeContextType;
  const [existsRowActions, setExistsRowActions] = useState(false);
  const [tableMinWidth, setTableMinWidth] = useState('');

  useEffect(() => {
    if (config?.rowActions?.length) {
      setExistsRowActions(true);
    }

    //@ts-ignore
    if (config.tableMinWidth) {
      //@ts-ignore
      setTableMinWidth(config.tableMinWidth);
    }
  }, [config]);

  useEffect(() => {
    if (response?.items && response.items.length > 0) {
      updateDetailedElement(response.items[0]);
    }
  }, [response]);

  return (
    <div>
      {config && (
        <div className="bo-table-container">
          <table style={{ minWidth: tableMinWidth ? tableMinWidth + 'px' : '' }}>
            <thead>
              <tr>
                {config &&
                  config.columns
                    .filter(
                      (column: {
                        displayName: string;
                        name: string;
                        dataTypeId: number;
                        showInTable: boolean;
                        columnSize: string | any;
                        headerCustomDisplayName: string | any;
                      }) => column.showInTable
                    )
                    .map(
                      (
                        column: {
                          displayName: string;
                          name: string;
                          sorting: boolean;
                          dataTypeId: number;
                          columnSize: string | any;
                          headerCustomDisplayName: string | any;
                        },
                        index: number
                      ) => {
                        return column.sorting === true ? (
                          <th key={index}>
                            <span
                              onClick={(event: React.MouseEvent<HTMLElement>) => {
                                onSort(column.name);
                              }}
                              className={'pointer fw-bold'}
                              style={{
                                fontSize: 14,
                                display: 'flex',
                                alignItems: 'center',
                                gap: '4px'
                              }}
                            >
                              <span>{column.headerCustomDisplayName ? column.headerCustomDisplayName : column.displayName}</span>
                              {searchModel.SortColumn == column.name ? (
                                searchModel.SortAscending ? (
                                  <FontAwesomeIcon icon={faSortUp} size="sm" color="#bfbfbf" />
                                ) : (
                                  <FontAwesomeIcon icon={faSortDown} size="sm" color="#bfbfbf" />
                                )
                              ) : (
                                <FontAwesomeIcon icon={faSort} size="sm" />
                              )}
                            </span>
                          </th>
                        ) : (
                          <th key={index}>
                            <span className="fw-bold" style={{ fontSize: 14 }}>
                              {column.headerCustomDisplayName ? column.headerCustomDisplayName : column.displayName}
                            </span>
                          </th>
                        );
                      }
                    )}
                <th className="fw-bold" style={{ fontSize: 14 }}>
                  {isFullWidth && existsRowActions ? 'Acțiuni' : ''}
                </th>
              </tr>
            </thead>

            <tbody>
              {response?.items.map((item: { [key: string]: string | any }, index: number) => (
                <tr
                  key={index}
                  onClick={() => {
                    updateDetailedElement(item);
                  }}
                  className={`${
                    detailedElement &&
                    item[config.identityColumnName.charAt(0).toLowerCase() + config.identityColumnName.slice(1)] ===
                      detailedElement[config.identityColumnName.charAt(0).toLowerCase() + config.identityColumnName.slice(1)]
                      ? 'selected'
                      : ''
                  }`}
                >
                  {config.columns
                    .filter(
                      (column: { displayName: string; name: string; dataTypeId: number; showInTable: boolean; columnSize: string | any }) =>
                        column.showInTable
                    )
                    .map(
                      (
                        column: { displayName: string; name: string; dataTypeId: number; showInTable: boolean; columnSize: string | any },
                        columnNumber: number
                      ) => {
                        const colSize = column.columnSize ? column.columnSize : 'Medium';
                        const colSizeUnits = colSize === 'Large' ? 4 : colSize === 'Small' ? 1 : 2;
                        const colSizeInPixels = 50 * colSizeUnits;

                        return (
                          <td key={columnNumber} style={{ minWidth: colSizeInPixels + 'px', fontSize: 14, maxHeight: '21px' }}>
                            <ColumnValue column={column} item={item} />
                          </td>
                        );
                      }
                    )}

                  {isFullWidth ? (
                    <td style={{ paddingLeft: '5px' }}>
                      <RowAction item={item} />
                    </td>
                  ) : (
                    <td className="text-end" style={{ maxWidth: '60px', minWidth: '60px' }}>
                      <FontAwesomeIcon
                        icon={faCircleArrowRight}
                        size={'1x'}
                        className="expand-collapse pt-1 pe-2 pointer"
                        color="#bebebe"
                      />
                    </td>
                  )}
                </tr>
              ))}

              {!response?.items.length && (
                <div className="p-2 fw-bold red">
                  <p>Nu există date pentru filtrele aplicate!</p>
                </div>
              )}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default ResponseListing;
