import React from 'react';
import { DelgazWrapper } from '@components/global/DelgazWrapper';
import CerereIConnectGaz from '@components/iConnect/CerereIConnectGaz';

export const CerereGaz = (): JSX.Element => {
    return (
        <DelgazWrapper>
            <CerereIConnectGaz />
        </DelgazWrapper>
    );
};