import { toggleLoader } from '@actions/LoaderActions';
import { adressByPOD } from '@api/consumptionPoint';
import { deleteRequestPodFromConvention, finalSendRequest, getConventionDetails, verifyModify } from '@api/conventions/conventions';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as notification from '@lib/Notification';
import { IState } from '@type/store';
import { Fragment, useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Verify from '../Verify';
import ModifyModal from './ModifyModal';

const RequestModify = () => {
  const [error, setError] = useState('');
  const [pod, setPod] = useState('');
  const [county, setCounty] = useState('');
  const [locality, setLocality] = useState('');
  const [wasValidated, setWasValidated] = useState(false);
  const [verifyMessage, setVerifyMessage] = useState<any>('');
  const [showVerifyModal, setShowVerifyModal] = useState(false);
  const [modalData, setModalData] = useState<any>(null);
  const [showModal, setShowModal] = useState(false);
  const [requestId, setRequestId] = useState('');
  const [currentRequestDetails, setCurrentRequestDetails] = useState<any>(null);
  const [showFinalModal, setShowFinalModal] = useState(false);
  //eslint-disable-next-line
  const [isEdit, setIsEdit] = useState(false);
  const [verifyKey, setVerifyKey] = useState(1); //used to reset Verify POD component

  const OLD_SITE_URL: string | undefined = useSelector<IState>((state) => state.options.webApiHost) as string;

  const dispatch = useDispatch();

  useEffect(() => {
    initializeConventionDetailsForEdit();
  }, []);

  const initializeConventionDetailsForEdit = async () => {
    const url = new URL(window.location.href);
    const params = url.search;
    const searchParams = new URLSearchParams(params);
    if (searchParams.has('requestId')) {
      //EDIT CASE
      let currentRequestId: any = searchParams.get('requestId');
      dispatch(toggleLoader(true));
      setRequestId(currentRequestId);
      await getConventionDetails(currentRequestId)
        .then((res) => {
          setCurrentRequestDetails(res.data);
        })
        .catch((error) => {});
    }
    dispatch(toggleLoader(false));
  };

  const handlePod = (value: string) => {
    setPod(value);
  };

  const handleCounty = (value: string) => {
    setCounty(value);
  };

  const handleLocality = (value: string) => {
    setLocality(value);
  };

  // Step 1 - validate
  const validate = async () => {
    dispatch(toggleLoader(true));

    setWasValidated(false);
    setError('');

    const payload = {
      pod,
      countyCode: county,
      localityCode: locality
    };

    await adressByPOD(payload)
      .then((response) => {
        if (response && response.pod) {
          setWasValidated(true);
        } else {
          setPod('');
          setCounty('');
          setLocality('');
          setError('Date de identificare client greșite!');
          dispatch(toggleLoader(false));
        }
      })
      .catch((error) => {
        try {
          if (error.status == 204) {
            setPod('');
            setCounty('');
            setLocality('');
            setError('Date de identificare client greșite!');
          } else {
            notification.error('A apărut o eroare. Vă rugăm să reveniți mai tărziu.');
          }
        } catch (error) {
          notification.error('A apărut o eroare. Vă rugăm să reveniți mai tărziu.');
        }
        dispatch(toggleLoader(false));
      });
  };

  useEffect(() => {
    wasValidated === true && verify();
  }, [wasValidated]);

  // Step 2 - verify
  const verify = async () => {
    setError('');
    setVerifyMessage('');

    await verifyModify({ pod })
      .then((response) => {
        if (response && response.data) {
          if (response.data.statusCode === 'NotInPortfolio') {
            setVerifyMessage('Locul de consum nu face parte din portofoliul propriu!');
            setShowVerifyModal(true);
          } else if (response.data.statusCode === 'NoConvention') {
            setVerifyMessage('Pentru acest loc de consum nu există convenție de consum.');
            setShowVerifyModal(true);
          } else if (response.data.statusCode === 'NoConventionContact') {
            setVerifyMessage(
              <p>
                Nu s-a regăsit o convenție de consum pentru acest loc de consum. Vă rugăm să vă adresați echipei Delgaz Grid pe adresa de
                mail{' '}
                <a className="red" href={'mailto:contractedistributie.el@delgaz-grid.ro'}>
                  contractedistributie.el@delgaz-grid.ro
                </a>
              </p>
            );
            setShowVerifyModal(true);
          } else if (response.data.statusCode === 'OK') {
            if (
              !currentRequestDetails ||
              !currentRequestDetails.consumptionPoints.find((cp: any) => cp.pod == response.data.consumptionPlace.pod)
            ) {
              let data = response.data;
              setModalData(data);
              setShowModal(true);
            } else {
              notification.error('Locul de consum a fost deja adăugat!');
              dispatch(toggleLoader(false));
            }
          }
        } else {
          setError('A apărut o eroare. Vă rugăm să reveniți mai tărziu.');
        }
      })
      .catch((err) => {
        setError('A apărut o eroare. Vă rugăm să reveniți mai tărziu.');
      });

    dispatch(toggleLoader(false));
  };

  const onAddDetailsOrModifyConvention = async (newRequestId: string) => {
    dispatch(toggleLoader(true));

    if (newRequestId) {
      setRequestId(newRequestId);
    }
    await getConventionDetails(newRequestId ? newRequestId : requestId)
      .then((res) => {
        setCurrentRequestDetails(res.data);
        dispatch(toggleLoader(false));
      })
      .catch((error) => {
        dispatch(toggleLoader(false));
      });
    setPod('');
    setCounty('');
    setLocality('');
    setVerifyKey(verifyKey + 1);
    setShowModal(false);
  };

  const deletePod = async (requestDetailId: string) => {
    dispatch(toggleLoader(true));
    await deleteRequestPodFromConvention(requestDetailId)
      .then(async (res: any) => {
        if (res.status == 200) {
          notification.success('Locul de consum a fost ștears cu succes!');
          await getConventionDetails(requestId)
            .then((res) => {
              setCurrentRequestDetails(res.data);
            })
            .catch((error) => {});
        } else {
          notification.error('A apărut o eroare la ștergere!');
        }
      })
      .catch((error) => {
        notification.error('A apărut o eroare la ștergere!');
      });
    dispatch(toggleLoader(false));
  };

  const editPod = async (pod: string, requestDetailId: string) => {
    dispatch(toggleLoader(true));
    let payload: any = { pod, requestDetailId };
    await verifyModify(payload)
      .then((response) => {
        if (response && response.data) {
          if (response.data.statusCode === 'NotInPortfolio') {
            setVerifyMessage('Locul de consum nu face parte din portofoliul propriu!');
            setShowVerifyModal(true);
          } else if (response.data.statusCode === 'NoConvention') {
            setVerifyMessage('Pentru acest loc de consum nu există convenție de consum.');
            setShowVerifyModal(true);
          } else if (response.data.statusCode === 'NoConventionContact') {
            setVerifyMessage(
              <p>
                Nu s-a regăsit o convenție de consum pentru acest loc de consum. Vă rugăm să vă adresați echipei Delgaz Grid pe adresa de
                mail{' '}
                <a className="red" href={'mailto:contractedistributie.el@delgaz-grid.ro'}>
                  contractedistributie.el@delgaz-grid.ro
                </a>
              </p>
            );
            setShowVerifyModal(true);
          } else if (response.data.statusCode === 'OK') {
            let data = response.data;
            setModalData({ ...data, isEdit: true, requestDetailId: requestDetailId });
            setShowModal(true);
            dispatch(toggleLoader(false));
          }
        } else {
          setError('A apărut o eroare. Vă rugăm să reveniți mai tărziu.');
        }
      })
      .catch((err) => {
        setError('A apărut o eroare. Vă rugăm să reveniți mai tărziu.');
      });
  };

  const renderVerifyModal = () => {
    return (
      <Modal show={showVerifyModal} onHide={() => setShowVerifyModal(false)} centered backdrop={'static'}>
        <Modal.Header closeButton>
          <div>
            <h5 className="red">Eroare</h5>
          </div>
        </Modal.Header>
        <Modal.Body>{verifyMessage}</Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => {
              setPod('');
              setCounty('');
              setLocality('');
              setCurrentRequestDetails(null);
              setShowVerifyModal(false);
            }}
          >
            OK
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };
  const renderFinalSendModal = () => {
    return (
      <Modal
        show={showFinalModal}
        onHide={() => {
          window.location.href = '/conventii-de-consum';
        }}
        centered
        backdrop={'static'}
      >
        <Modal.Header closeButton>
          <div>
            <h5 className="red">Succes</h5>
          </div>
        </Modal.Header>
        <Modal.Body>Solicitarea dumneavoastră a fost trimisă cu succes.</Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => {
              window.location.href = '/conventii-de-consum';
            }}
          >
            OK
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const handleShowModal = (show: boolean) => {
    setShowModal(show);
  };

  return (
    <Fragment>
      <div className="mb-3">
        <a href={`${OLD_SITE_URL}/conventii-de-consum`}>
          <Button>
            <FontAwesomeIcon icon={faChevronLeft} style={{ marginRight: '6px' }} />
            <span>Înapoi</span>
          </Button>
        </a>
      </div>
      <Verify
        key={`verify_key_${verifyKey}`}
        onClick={() => validate()}
        pod={pod}
        handlePod={handlePod}
        county={county}
        handleCounty={handleCounty}
        locality={locality}
        handleLocality={handleLocality}
        withLocalityCode
      />
      {error !== '' && <p className="text-center fw-bold red mt-3">{error}</p>}

      {
        <div>
          <div className="general-table-container">
            <table>
              <thead>
                <tr>
                  <th>POD</th>
                  <th>Client</th>
                  <th>Adresă</th>
                  <th>Status</th>
                  <th>Acțiuni</th>
                </tr>
              </thead>
              <tbody>
                {currentRequestDetails &&
                  currentRequestDetails?.consumptionPoints.map((cp: any, index: number) => {
                    return (
                      <tr key={index}>
                        <td>{cp.pod}</td>
                        <td>{cp.client}</td>
                        <td>{cp.address}</td>
                        <td>{cp.statusTypeName}</td>
                        <td>
                          <Button className="mt-1 me-2" onClick={() => editPod(cp.pod, cp.requestDetailId)}>
                            Editează
                          </Button>
                          <Button className="mt-1" onClick={() => deletePod(cp.requestDetailId)}>
                            Sterge
                          </Button>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
          <div className="d-flex justify-content-center">
            <Button
              className="btn-wide mt-3 mb-1"
              disabled={!currentRequestDetails}
              onClick={async () => {
                dispatch(toggleLoader(true));
                await finalSendRequest(requestId)
                  .then((res) => {
                    if (res.status == 200) {
                      setShowFinalModal(true);
                    }
                  })
                  .catch((error) => {
                    try {
                      if (error.response.status == 400) {
                        notification.error('Solicitarea a fost deja trimisă!');
                      } else {
                        notification.error('A apărut o eroare. Vă rugăm să reveniți mai tărziu.');
                      }
                    } catch (err) {
                      notification.error('A apărut o eroare. Vă rugăm să reveniți mai tărziu.');
                    }
                  });
                dispatch(toggleLoader(false));
              }}
            >
              Trimite solicitare
            </Button>
          </div>
        </div>
      }

      {renderVerifyModal()}
      {renderFinalSendModal()}
      {modalData !== null && (
        <ModifyModal
          show={showModal}
          handleShow={handleShowModal}
          modalData={modalData}
          requestId={requestId}
          setRequestId={setRequestId}
          onAddDetailsOrModifyConvention={onAddDetailsOrModifyConvention}
        />
      )}
    </Fragment>
  );
};

export default RequestModify;
