import React from 'react';
import { Bar } from 'react-chartjs-2';
// import Chart from 'chart.js/auto';
import { Chart, registerables } from 'chart.js';
Chart.register(...registerables);

const BarChart = (props: { chartData: any; options: any }) => {
  return <Bar data={props.chartData} options={props.options} />;
};

export default BarChart;