import React from 'react';
import { DelgazWrapper } from '@components/global/DelgazWrapper';
import IstoricIncarcariFise from '@components/revizii/IstoricIncarcariFise/IstoricIncarcariFise';

export const IstoricIncarcariFiseComponent = () => {
  return (
    <DelgazWrapper>
      <IstoricIncarcariFise />
    </DelgazWrapper>
  );
};
