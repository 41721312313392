import { toggleLoader } from '@actions/LoaderActions';
import { getAnexaStepFour, postAnexaStepFour } from '@api/revizii/revizii';
import InputNumberComplexTable from '@components/common/Input/InputNumberComplexTable';
import StepsNavigationButtons from '@components/common/Steps/StepsNavigationButtons';
import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

type RVStepFourProps = {
  type: string;
  cpData: any;
  pod: string;
  clc: string;
  county: string;
  locality: string;
  dictionare: any;
  currentStep: any;
  setStep: (step: any) => void;
  progress: any;
  setProgress: (step: any) => void;
  idAnexa: string;
};

type StepFourType = {
  id_anexa: number;
  resistanceResult: boolean;
  sealingResult: boolean;
  testsExecutionConfirmation: boolean;
  resistanceOverOLLowPressure: number;
  resistanceOverOLMediumPressure: number;
  resistanceOverOLReducedPressure: number;
  resistanceUnderOLLowPressure: number;
  resistanceUnderOLMediumPressure: number;
  resistanceUnderOLReducedPressure: number;
  resistanceUnderPE100LowPressure: number;
  resistanceUnderPE100MediumPressure: number;
  resistanceUnderPE100ReducedPressure: number;
  resistanceUnderPESOLowPressure: number;
  resistanceUnderPESOMediumPressure: number;
  resistanceUnderPESOReducedPressure: number;
  sealingOverOLLowPressure: number;
  sealingOverOLMediumPressure: number;
  sealingOverOLReducedPressure: number;
  sealingUnderOLLowPressure: number;
  sealingUnderOLMediumPressure: number;
  sealingUnderOLReducedPressure: number;
  sealingUnderPE100LowPressure: number;
  sealingUnderPE100MediumPressure: number;
  sealingUnderPE100ReducedPressure: number;
  sealingUnderPESOLowPressure: number;
  sealingUnderPESOMediumPressure: number;
  sealingUnderPESOReducedPressure: number;
};

const RVStepFour = (props: RVStepFourProps) => {
  const dispatch = useDispatch();
  const { setStep, currentStep, progress, setProgress } = props;

  //Proba de rezistenta
  const [prPresiuneMedieOlSubteran, setPrPresiuneMedieOlSubteran] = useState<any>('');
  const [prPresiuneMedieOlSuprateran, setPrPresiuneMedieOlSuprateran] = useState<any>('');
  const [prPresiuneMediePe100Subteran, setPrPresiuneMediePe100Subteran] = useState<any>('');
  const [prPresiuneMediePe80Subteran, setPrPresiuneMediePe80Subteran] = useState<any>('');

  const [prPresiuneRedusaOlSubteran, setPrPresiuneRedusaOlSubteran] = useState<any>('');
  const [prPresiuneRedusaOlSuprateran, setPrPresiuneRedusaOlSuprateran] = useState<any>('');
  const [prPresiuneRedusaPe100Subteran, setPrPresiuneRedusaPe100Subteran] = useState<any>('');
  const [prPresiuneRedusaPe80Subteran, setPrPresiuneRedusaPe80Subteran] = useState<any>('');

  const [prPresiuneJoasaOlSubteran, setPrPresiuneJoasaOlSubteran] = useState<any>('');
  const [prPresiuneJoasaOlSuprateran, setPrPresiuneJoasaOlSuprateran] = useState<any>('');
  const [prPresiuneJoasaPe100Subteran, setPrPresiuneJoasaPe100Subteran] = useState<any>('');
  const [prPresiuneJoasaPe80Subteran, setPrPresiuneJoasaPe80Subteran] = useState<any>('');

  const [prRezultatulProbei, setPrRezultatulProbei] = useState<any>(null);

  //Proba de etanseitate
  const [pePresiuneMedieOlSubteran, setPePresiuneMedieOlSubteran] = useState<any>('');
  const [pePresiuneMedieOlSuprateran, setPePresiuneMedieOlSuprateran] = useState<any>('');
  const [pePresiuneMediePe100Subteran, setPePresiuneMediePe100Subteran] = useState<any>('');
  const [pePresiuneMediePe80Subteran, setPePresiuneMediePe80Subteran] = useState<any>('');

  const [pePresiuneRedusaOlSubteran, setPePresiuneRedusaOlSubteran] = useState<any>('');
  const [pePresiuneRedusaOlSuprateran, setPePresiuneRedusaOlSuprateran] = useState<any>('');
  const [pePresiuneRedusaPe100Subteran, setPePresiuneRedusaPe100Subteran] = useState<any>('');
  const [pePresiuneRedusaPe80Subteran, setPePresiuneRedusaPe80Subteran] = useState<any>('');

  const [pePresiuneJoasaOlSubteran, setPePresiuneJoasaOlSubteran] = useState<any>('');
  const [pePresiuneJoasaOlSuprateran, setPePresiuneJoasaOlSuprateran] = useState<any>('');
  const [pePresiuneJoasaPe100Subteran, setPePresiuneJoasaPe100Subteran] = useState<any>('');
  const [pePresiuneJoasaPe80Subteran, setPePresiuneJoasaPe80Subteran] = useState<any>('');

  const [peRezultatulProbei, setPeRezultatulProbei] = useState<any>(null);

  const [confirmareEfectuareProbe, setConfirmareEfectuareProbe] = useState(false);

  //errors
  const [error, setError] = useState('');
  const [errorProbaDeRezistenta, setErrorProbaDeRezistenta] = useState(false);
  const [errorProbaDeEtanseitate, setErrorProbaDeEtanseitate] = useState(false);
  const [errorPrRezultatulProbei, setErrorPrRezultatulProbei] = useState<any>(false);
  const [errorPeRezultatulProbei, setErrorPeRezultatulProbei] = useState<any>(false);
  const [errorConfirmareEfectuareProbe, setErrorConfirmareEfectuareProbe] = useState<any>(false);

  useEffect(() => {
    if (props.idAnexa) {
      dispatch(toggleLoader(true));
      getAnexaStepFour(props.idAnexa)
        .then((res) => {
          dispatch(toggleLoader(false));
          initializeData(res);
        })
        .catch((err) => {
          dispatch(toggleLoader(false));
        });
    }
  }, []);

  useEffect(() => {
    if (prRezultatulProbei != null) {
      setErrorPrRezultatulProbei(false);
    }
    if (peRezultatulProbei != null) {
      setErrorPeRezultatulProbei(false);
    }
    if (confirmareEfectuareProbe) {
      setErrorConfirmareEfectuareProbe(false);
    }
  }, [prRezultatulProbei, peRezultatulProbei, confirmareEfectuareProbe]);

  useEffect(() => {
    let nonEmptyValuesCounter = 0;
    const arrayProbaDeRezistenta = [
      prPresiuneMedieOlSubteran,
      prPresiuneMedieOlSuprateran,
      prPresiuneMediePe100Subteran,
      prPresiuneMediePe80Subteran,
      prPresiuneRedusaOlSubteran,
      prPresiuneRedusaOlSuprateran,
      prPresiuneRedusaPe100Subteran,
      prPresiuneRedusaPe80Subteran,
      prPresiuneJoasaOlSubteran,
      prPresiuneJoasaOlSuprateran,
      prPresiuneJoasaPe100Subteran,
      prPresiuneJoasaPe80Subteran
    ];
    arrayProbaDeRezistenta.forEach((val) => {
      if (val) {
        nonEmptyValuesCounter = nonEmptyValuesCounter + 1;
      }
    });
    if (nonEmptyValuesCounter != 1) {
      setErrorProbaDeRezistenta(true);
    } else {
      setErrorProbaDeRezistenta(false);
    }
  }, [
    prPresiuneMedieOlSubteran,
    prPresiuneMedieOlSuprateran,
    prPresiuneMediePe100Subteran,
    prPresiuneMediePe80Subteran,
    prPresiuneRedusaOlSubteran,
    prPresiuneRedusaOlSuprateran,
    prPresiuneRedusaPe100Subteran,
    prPresiuneRedusaPe80Subteran,
    prPresiuneJoasaOlSubteran,
    prPresiuneJoasaOlSuprateran,
    prPresiuneJoasaPe100Subteran,
    prPresiuneJoasaPe80Subteran
  ]);

  useEffect(() => {
    let nonEmptyValuesCounter = 0;
    const arrayProbaDeEtanseitate = [
      pePresiuneMedieOlSubteran,
      pePresiuneMedieOlSuprateran,
      pePresiuneMediePe100Subteran,
      pePresiuneMediePe80Subteran,
      pePresiuneRedusaOlSubteran,
      pePresiuneRedusaOlSuprateran,
      pePresiuneRedusaPe100Subteran,
      pePresiuneRedusaPe80Subteran,
      pePresiuneJoasaOlSubteran,
      pePresiuneJoasaOlSuprateran,
      pePresiuneJoasaPe100Subteran,
      pePresiuneJoasaPe80Subteran
    ];
    arrayProbaDeEtanseitate.forEach((val) => {
      if (val) {
        nonEmptyValuesCounter = nonEmptyValuesCounter + 1;
      }
    });
    if (nonEmptyValuesCounter != 1) {
      setErrorProbaDeEtanseitate(true);
    } else {
      setErrorProbaDeEtanseitate(false);
    }
  }, [
    pePresiuneMedieOlSubteran,
    pePresiuneMedieOlSuprateran,
    pePresiuneMediePe100Subteran,
    pePresiuneMediePe80Subteran,
    pePresiuneRedusaOlSubteran,
    pePresiuneRedusaOlSuprateran,
    pePresiuneRedusaPe100Subteran,
    pePresiuneRedusaPe80Subteran,
    pePresiuneJoasaOlSubteran,
    pePresiuneJoasaOlSuprateran,
    pePresiuneJoasaPe100Subteran,
    pePresiuneJoasaPe80Subteran
  ]);

  const initializeData = (values: StepFourType) => {
    setPrPresiuneMedieOlSubteran(values.resistanceUnderOLMediumPressure);
    setPrPresiuneMedieOlSuprateran(values.resistanceOverOLMediumPressure);
    setPrPresiuneMediePe100Subteran(values.resistanceUnderPE100MediumPressure);
    setPrPresiuneMediePe80Subteran(values.resistanceUnderPESOMediumPressure);
    setPrPresiuneRedusaOlSubteran(values.resistanceUnderOLReducedPressure);
    setPrPresiuneRedusaOlSuprateran(values.resistanceOverOLReducedPressure);
    setPrPresiuneRedusaPe100Subteran(values.resistanceUnderPE100ReducedPressure);
    setPrPresiuneRedusaPe80Subteran(values.resistanceUnderPESOReducedPressure);
    setPrPresiuneJoasaOlSubteran(values.resistanceUnderOLLowPressure);
    setPrPresiuneJoasaOlSuprateran(values.resistanceOverOLLowPressure);
    setPrPresiuneJoasaPe100Subteran(values.resistanceUnderPE100LowPressure);
    setPrPresiuneJoasaPe80Subteran(values.resistanceUnderPESOLowPressure);

    setPePresiuneMedieOlSubteran(values.sealingUnderOLMediumPressure);
    setPePresiuneMedieOlSuprateran(values.sealingOverOLMediumPressure);
    setPePresiuneMediePe100Subteran(values.sealingUnderPE100MediumPressure);
    setPePresiuneMediePe80Subteran(values.sealingUnderPESOMediumPressure);
    setPePresiuneRedusaOlSubteran(values.sealingUnderOLReducedPressure);
    setPePresiuneRedusaOlSuprateran(values.sealingOverOLReducedPressure);
    setPePresiuneRedusaPe100Subteran(values.sealingUnderPE100ReducedPressure);
    setPePresiuneRedusaPe80Subteran(values.sealingUnderPESOReducedPressure);
    setPePresiuneJoasaOlSubteran(values.sealingUnderOLLowPressure);
    setPePresiuneJoasaOlSuprateran(values.sealingOverOLLowPressure);
    setPePresiuneJoasaPe100Subteran(values.sealingUnderPE100LowPressure);
    setPePresiuneJoasaPe80Subteran(values.sealingUnderPESOLowPressure);

    setPrRezultatulProbei(values.resistanceResult);
    setPeRezultatulProbei(values.sealingResult);
    // setConfirmareEfectuareProbe(values.testsExecutionConfirmation);
  };

  const submit = () => {
    if (validateValues()) {
      dispatch(toggleLoader(true));
      let payload: any = {
        resistanceUnderOLMediumPressure: prPresiuneMedieOlSubteran ? prPresiuneMedieOlSubteran : null,
        resistanceOverOLMediumPressure: prPresiuneMedieOlSuprateran ? prPresiuneMedieOlSuprateran : null,
        resistanceUnderPE100MediumPressure: prPresiuneMediePe100Subteran ? prPresiuneMediePe100Subteran : null,
        resistanceUnderPESOMediumPressure: prPresiuneMediePe80Subteran ? prPresiuneMediePe80Subteran : null,
        resistanceUnderOLReducedPressure: prPresiuneRedusaOlSubteran ? prPresiuneRedusaOlSubteran : null,
        resistanceOverOLReducedPressure: prPresiuneRedusaOlSuprateran ? prPresiuneRedusaOlSuprateran : null,
        resistanceUnderPE100ReducedPressure: prPresiuneRedusaPe100Subteran ? prPresiuneRedusaPe100Subteran : null,
        resistanceUnderPESOReducedPressure: prPresiuneRedusaPe80Subteran ? prPresiuneRedusaPe80Subteran : null,
        resistanceUnderOLLowPressure: prPresiuneJoasaOlSubteran ? prPresiuneJoasaOlSubteran : null,
        resistanceOverOLLowPressure: prPresiuneJoasaOlSuprateran ? prPresiuneJoasaOlSuprateran : null,
        resistanceUnderPE100LowPressure: prPresiuneJoasaPe100Subteran ? prPresiuneJoasaPe100Subteran : null,
        resistanceUnderPESOLowPressure: prPresiuneJoasaPe80Subteran ? prPresiuneJoasaPe80Subteran : null,
        resistanceResult: prRezultatulProbei,
        sealingUnderOLMediumPressure: pePresiuneMedieOlSubteran ? pePresiuneMedieOlSubteran : null,
        sealingOverOLMediumPressure: pePresiuneMedieOlSuprateran ? pePresiuneMedieOlSuprateran : null,
        sealingUnderPE100MediumPressure: pePresiuneMediePe100Subteran ? pePresiuneMediePe100Subteran : null,
        sealingUnderPESOMediumPressure: pePresiuneMediePe80Subteran ? pePresiuneMediePe80Subteran : null,
        sealingUnderOLReducedPressure: pePresiuneRedusaOlSubteran ? pePresiuneRedusaOlSubteran : null,
        sealingOverOLReducedPressure: pePresiuneRedusaOlSuprateran ? pePresiuneRedusaOlSuprateran : null,
        sealingUnderPE100ReducedPressure: pePresiuneRedusaPe100Subteran ? pePresiuneRedusaPe100Subteran : null,
        sealingUnderPESOReducedPressure: pePresiuneRedusaPe80Subteran ? pePresiuneRedusaPe80Subteran : null,
        sealingUnderOLLowPressure: pePresiuneJoasaOlSubteran ? pePresiuneJoasaOlSubteran : null,
        sealingOverOLLowPressure: pePresiuneJoasaOlSuprateran ? pePresiuneJoasaOlSuprateran : null,
        sealingUnderPE100LowPressure: pePresiuneJoasaPe100Subteran ? pePresiuneJoasaPe100Subteran : null,
        sealingUnderPESOLowPressure: pePresiuneJoasaPe80Subteran ? pePresiuneJoasaPe80Subteran : null,
        sealingResult: peRezultatulProbei,
        testsExecutionConfirmation: confirmareEfectuareProbe
      };
      if (props.idAnexa) {
        payload.id_anexa = props.idAnexa;
      }

      postAnexaStepFour(payload)
        .then((res) => {
          props.setStep(props.currentStep + 1);
          props.currentStep === props.progress && props.setProgress(props.currentStep + 1);
          dispatch(toggleLoader(false));
        })
        .catch((err) => {
          if (err.status == 200) {
            props.setStep(props.currentStep + 1);
            props.currentStep === props.progress && props.setProgress(props.currentStep + 1);
          } else {
            setError('A intervenit o eroare tehnică. Vă rugăm reveniți mai târziu.');
          }
          dispatch(toggleLoader(false));
        });
    }
  };

  const validateValues = () => {
    let valid = true;
    if (errorProbaDeEtanseitate || errorProbaDeRezistenta) {
      valid = false;
    }
    if (prRezultatulProbei == null) {
      setErrorPrRezultatulProbei(true);
      valid = false;
    }
    if (peRezultatulProbei == null) {
      setErrorPeRezultatulProbei(true);
      valid = false;
    }
    if (!confirmareEfectuareProbe) {
      setErrorConfirmareEfectuareProbe(true);
      valid = false;
    }
    return valid;
  };

  return (
    <Fragment>
      <h4 className="red mt-2 mb-2">Probele de etanseitate si de rezistenta la presiune</h4>

      <div className="complex-table-container">
        <table>
          <tbody>
            {/* head */}
            <tr>
              <td rowSpan={2}>
                <strong>Nr crt</strong>
              </td>
              <td rowSpan={2} colSpan={3}></td>
              <td colSpan={4} className="text-center">
                <strong>Instalație de utilizare a gazelor naturale</strong>
              </td>
            </tr>
            <tr>
              <td colSpan={2} className="text-center">
                OL
              </td>
              <td className="text-center">PE100</td>
              <td className="text-center">PE80</td>
            </tr>
            <tr>
              <td></td>
              <td colSpan={2}></td>
              <td>Amplasare instalaţie de utilizare</td>
              <td className="text-center">subteran</td>
              <td className="text-center">suprateran</td>
              <td className="text-center">subteran</td>
              <td className="text-center">suprateran</td>
            </tr>
            {/* row 1 */}
            <tr>
              <td rowSpan={7}>1</td>
              <td rowSpan={7} className="red">
                Proba de rezistenţă
              </td>
              <td rowSpan={4}>Regim de presiune</td>
              <td rowSpan={2}>medie presiune (bar)</td>
              <td rowSpan={2}>
                <InputNumberComplexTable value={prPresiuneMedieOlSubteran} setValue={setPrPresiuneMedieOlSubteran} />
              </td>
              <td rowSpan={2}>
                <InputNumberComplexTable value={prPresiuneMedieOlSuprateran} setValue={setPrPresiuneMedieOlSuprateran} />
              </td>
              <td rowSpan={2}>
                <InputNumberComplexTable value={prPresiuneMediePe100Subteran} setValue={setPrPresiuneMediePe100Subteran} />
              </td>
              <td rowSpan={2}>
                <InputNumberComplexTable value={prPresiuneMediePe80Subteran} setValue={setPrPresiuneMediePe80Subteran} />
              </td>
            </tr>
            <tr></tr>
            <tr>
              <td>redusă presiune (bar)</td>
              <td>
                <InputNumberComplexTable value={prPresiuneRedusaOlSubteran} setValue={setPrPresiuneRedusaOlSubteran} />
              </td>
              <td>
                <InputNumberComplexTable value={prPresiuneRedusaOlSuprateran} setValue={setPrPresiuneRedusaOlSuprateran} />
              </td>
              <td>
                <InputNumberComplexTable value={prPresiuneRedusaPe100Subteran} setValue={setPrPresiuneRedusaPe100Subteran} />
              </td>
              <td>
                <InputNumberComplexTable value={prPresiuneRedusaPe80Subteran} setValue={setPrPresiuneRedusaPe80Subteran} />
              </td>
            </tr>
            <tr>
              <td>joasă presiune (bar)</td>
              <td>
                <InputNumberComplexTable value={prPresiuneJoasaOlSubteran} setValue={setPrPresiuneJoasaOlSubteran} />
              </td>
              <td>
                <InputNumberComplexTable value={prPresiuneJoasaOlSuprateran} setValue={setPrPresiuneJoasaOlSuprateran} />
              </td>
              <td>
                <InputNumberComplexTable value={prPresiuneJoasaPe100Subteran} setValue={setPrPresiuneJoasaPe100Subteran} />
              </td>
              <td>
                <InputNumberComplexTable value={prPresiuneJoasaPe80Subteran} setValue={setPrPresiuneJoasaPe80Subteran} />
              </td>
            </tr>
            <tr>
              <td>Timpul probei (h)</td>
              <td>1 h</td>
              <td colSpan={4} className="red text-center">
                {errorProbaDeRezistenta && 'Atenție: A se completa o singură informație în secțiunea Proba de rezistență!'}
              </td>
            </tr>
            <tr>
              <td rowSpan={2}>Rezultatul probei</td>
              <td>admis</td>
              <td rowSpan={2} colSpan={4}>
                <div className="d-flex justify-content-center">
                  <span onClick={() => setPrRezultatulProbei(true)} className="me-3">
                    <input
                      className="custom-radio-button pointer"
                      type="radio"
                      checked={prRezultatulProbei == true}
                      onChange={() => setPrRezultatulProbei(true)}
                      style={{ marginRight: '6px' }}
                    />
                    <span className="fw-bold pointer">Admis</span>
                  </span>

                  <span onClick={() => setPrRezultatulProbei(false)}>
                    <input
                      className="custom-radio-button pointer"
                      type="radio"
                      checked={prRezultatulProbei == false}
                      onChange={() => setPrRezultatulProbei(false)}
                      style={{ marginRight: '6px' }}
                    />
                    <span className="fw-bold pointer">Respins</span>
                  </span>
                </div>
                <div className="d-flex justify-content-center">
                  {errorPrRezultatulProbei && <div style={{ color: 'red', fontWeight: 'bold' }}>Câmp obligaroriu!</div>}
                </div>
              </td>
            </tr>
            <tr>
              <td>respins</td>
            </tr>
            {/* row 2 */}
            <tr>
              <td rowSpan={7}>2</td>
              <td rowSpan={7} className="red">
                Proba de etanșeitate
              </td>
              <td rowSpan={4}>Regim de presiune</td>
              <td rowSpan={2}>medie presiune (bar)</td>
              <td rowSpan={2}>
                <InputNumberComplexTable value={pePresiuneMedieOlSubteran} setValue={setPePresiuneMedieOlSubteran} />
              </td>
              <td rowSpan={2}>
                <InputNumberComplexTable value={pePresiuneMedieOlSuprateran} setValue={setPePresiuneMedieOlSuprateran} />
              </td>
              <td rowSpan={2}>
                <InputNumberComplexTable value={pePresiuneMediePe100Subteran} setValue={setPePresiuneMediePe100Subteran} />
              </td>
              <td rowSpan={2}>
                <InputNumberComplexTable value={pePresiuneMediePe80Subteran} setValue={setPePresiuneMediePe80Subteran} />
              </td>
            </tr>
            <tr></tr>
            <tr>
              <td>redusă presiune (bar)</td>
              <td>
                <InputNumberComplexTable value={pePresiuneRedusaOlSubteran} setValue={setPePresiuneRedusaOlSubteran} />
              </td>
              <td>
                <InputNumberComplexTable value={pePresiuneRedusaOlSuprateran} setValue={setPePresiuneRedusaOlSuprateran} />
              </td>
              <td>
                <InputNumberComplexTable value={pePresiuneRedusaPe100Subteran} setValue={setPePresiuneRedusaPe100Subteran} />
              </td>
              <td>
                <InputNumberComplexTable value={pePresiuneRedusaPe80Subteran} setValue={setPePresiuneRedusaPe80Subteran} />
              </td>
            </tr>
            <tr>
              <td>joasă presiune (bar)</td>
              <td>
                <InputNumberComplexTable value={pePresiuneJoasaOlSubteran} setValue={setPePresiuneJoasaOlSubteran} />
              </td>
              <td>
                <InputNumberComplexTable value={pePresiuneJoasaOlSuprateran} setValue={setPePresiuneJoasaOlSuprateran} />
              </td>
              <td>
                <InputNumberComplexTable value={pePresiuneJoasaPe100Subteran} setValue={setPePresiuneJoasaPe100Subteran} />
              </td>
              <td>
                <InputNumberComplexTable value={pePresiuneJoasaPe80Subteran} setValue={setPePresiuneJoasaPe80Subteran} />
              </td>
            </tr>
            <tr>
              <td>Timpul probei (h)</td>
              <td>24 h</td>
              <td colSpan={4} className="red text-center">
                {errorProbaDeEtanseitate && 'Atenție: A se completa o singură informație în secțiunea Proba de etanșeitate!'}
              </td>
            </tr>
            <tr>
              <td rowSpan={2}>Rezultatul probei</td>
              <td>admis</td>
              <td rowSpan={2} colSpan={4}>
                <div className="d-flex justify-content-center">
                  <span onClick={() => setPeRezultatulProbei(true)} className="me-3">
                    <input
                      className="custom-radio-button pointer"
                      type="radio"
                      checked={peRezultatulProbei == true}
                      onChange={() => setPeRezultatulProbei(true)}
                      style={{ marginRight: '6px' }}
                    />
                    <span className="fw-bold pointer">Admis</span>
                  </span>

                  <span onClick={() => setPeRezultatulProbei(false)}>
                    <input
                      className="custom-radio-button pointer"
                      type="radio"
                      checked={peRezultatulProbei == false}
                      onChange={() => setPeRezultatulProbei(false)}
                      style={{ marginRight: '6px' }}
                    />
                    <span className="fw-bold pointer">Respins</span>
                  </span>
                </div>
                <div className="d-flex justify-content-center">
                  {errorPeRezultatulProbei && <div style={{ color: 'red', fontWeight: 'bold' }}>Câmp obligaroriu!</div>}
                </div>
              </td>
            </tr>
            <tr>
              <td>respins</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div
        onClick={() => setConfirmareEfectuareProbe(!confirmareEfectuareProbe)}
        className="checkbox-container pointer"
        style={{ marginTop: '1rem' }}
      >
        <input
          className="create-account-checkbox"
          type="checkbox"
          checked={confirmareEfectuareProbe}
          onChange={() => setConfirmareEfectuareProbe(!confirmareEfectuareProbe)}
        />
        <span className="fw-bold checkbox-span" style={{ marginLeft: '5px' }}>
          Confirm că au fost efectuate probele enumerate mai sus
        </span>
        {errorConfirmareEfectuareProbe && <div style={{ color: 'red', fontWeight: 'bold' }}>Câmp obligaroriu!</div>}
      </div>

      {error && <div style={{ color: 'red', fontWeight: 'bold' }}>{error}</div>}

      <StepsNavigationButtons
        className="mb-3"
        onClickPrevious={() => {
          setStep(currentStep - 1);
          currentStep === progress && setProgress(currentStep - 1);
        }}
        onClickNext={() => {
          submit();
        }}
      />
    </Fragment>
  );
};

export default RVStepFour;
