import { toggleLoader } from '@actions/LoaderActions';
import { GetCounties, GetLocalities } from '@api/geoApi';
import { getInterruptionsEE } from '@api/intreruperiProgramateEE/intreruperiProgramateEE';
import { GENERAL_ERROR_MESSAGE } from '@constants/Errors';
import { CountyTemplate, LocalityTemplate } from '@type/geo';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getQueryParam } from '../../helpers/urlUtils';
import moment from 'moment';
import { Button, Col, Row } from 'react-bootstrap';
import SearchInput from '@components/common/ActionSearch/SearchInput';
import { Dropdown, DropdownOptionType } from '@components/common/Dropdown';
import DatePicker from '@components/common/Date/DatePicker';
import Pagination from '@components/common/Pagination/Pagination';
import { PLANNED_INTERRUPTION_STATUS_ID_CANCELED } from './IntreruperiProgramateEE';

interface Interruption {
  id: number;
  creRequestId: number;
  statusId: number;
  countyCode: string;
  countyName: string;
  description: string;
  startDate: string;
  endDate: string;
  startHour: string;
  endHour: string;
  utility: string;
  installation: string;
  scop: string;
  kidEntryUser: string;
  kidUpdateUser: string;
  entryDate: string;
  updateDate: string;
  localities: Locality[];
  streets: Street[];
}

interface Street {
  id: number;
  plannedInterruptionId: number;
  localityCode: string;
  streetCode: string;
  streetName: string;
  active: boolean;
  entryDate: string;
}

interface Locality {
  id: number;
  plannedInterruptionId: number;
  localityCode: string;
  localityName: string;
  active: boolean;
  entryDate: string;
  hasStreets: boolean;
}

const IntreruperiFinalizateEE = (props: any) => {
  const [county, setCounty] = useState('');
  const [locality, setLocality] = useState('');
  const [counties, setCounties] = useState<CountyTemplate[]>([]);
  const [localities, setLocalities] = useState<LocalityTemplate[]>([]);

  const [interruptions, setInterruptions] = useState<Interruption[]>([]);

  const [keywords, setKeywords] = useState('');
  const [startDate, setStartDate] = useState<any>('');
  const [endDate, setEndDate] = useState<any>('');
  const [fetchRequested, setFetchRequested] = useState<any>('');

  //pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const paginate = (pageNumber: number) => setCurrentPage(pageNumber);
  const setElementsPerPage = (number: number) => setPerPage(number);
  const [totalItemsCount, setTotalItemsCount] = useState(0);

  const [error, setError] = useState('');
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(toggleLoader(true, 'GetCounties'));
    GetCounties('EE').then((counties) => {
      setCounties(counties);
      dispatch(toggleLoader(false, 'GetCounties'));
      fetchInterruptionsEE();
    });
    const countyCodeParam = getQueryParam('countyCode');
    setCounty(countyCodeParam ?? '');
  }, []);

  useEffect(() => {
    if (county) {
      GetLocalities(county).then((res) => setLocalities(res));
      setLocality('');
    }
  }, [county]);

  useEffect(() => {
    fetchInterruptionsEE();
  }, [currentPage, perPage]);

  useEffect(() => {
    setCurrentPage(1);
    fetchInterruptionsEE();
  }, [county, locality, startDate, endDate]);

  useEffect(() => {
    if (fetchRequested) {
      setCurrentPage(1);
      fetchInterruptionsEE();
      setFetchRequested(false);
    }
  }, [fetchRequested]);

  const fetchInterruptionsEE = () => {
    dispatch(toggleLoader(true, 'fetchInterruptionsEE'));
    setError('');

    let payload: any = {
      pageNumber: currentPage,
      pageSize: perPage,
      countyCode: county,
      localityCode: locality,
      keywords: keywords,
      startDate: startDate,
      endDate: endDate,
      active: false
    };

    getInterruptionsEE(payload)
      .then((res) => {
        setInterruptions([...res.items]);
        setTotalItemsCount(res.metadata.totalItemsCount);
        dispatch(toggleLoader(false, 'fetchInterruptionsEE'));
      })
      .catch((err) => {
        setError(GENERAL_ERROR_MESSAGE);
        dispatch(toggleLoader(false, 'fetchInterruptionsEE'));
      });
  };

  return (
    <div className="p-3">
      <Row className="d-flex justify-content-end">
        <Col md={3} sm={6} xs={12} className="mb-3">
          <Dropdown
            options={
              counties.map((option, index) => ({
                id: option.countyCode,
                name: option.countyName
              })) as DropdownOptionType[]
            }
            label={'Județ'}
            defaultPlaceholder={'Alege județul'}
            onChange={(value) => {
              setCounty(value);
            }}
            value={county}
            mbZero
          />
        </Col>

        <Col md={3} sm={6} xs={12} className="mb-3">
          <Dropdown
            options={
              localities.map((option, index) => ({
                id: option.localityCode,
                name: option.localityName
              })) as DropdownOptionType[]
            }
            label={'Localitate'}
            onChange={(value) => {
              setLocality(value);
            }}
            defaultPlaceholder={'Alege localitatea'}
            displaySearch={true}
            value={locality}
            disabled={!county}
            mbZero
          />
        </Col>

        <Col md={3} sm={6} xs={12} className="mb-3">
          <DatePicker
            label="Data început"
            name="Data început"
            selected={startDate ? moment(startDate).toDate() : null}
            onChange={(d) => {
              let selectedDate = d ? moment(d).format('YYYY-MM-DD') : '';
              setStartDate(selectedDate);
            }}
            mbZero
          />
        </Col>

        <Col md={3} sm={6} xs={12} className="mb-3">
          <DatePicker
            label="Data sfârșit"
            name="Data sfârșit"
            selected={endDate ? moment(endDate).toDate() : null}
            onChange={(d) => {
              let selectedDate = d ? moment(d).format('YYYY-MM-DD') : '';
              setEndDate(selectedDate);
            }}
            mbZero
          />
        </Col>
      </Row>

      <Row className="d-flex justify-content-between">
        <Col xs={12} md={6}></Col>
        <Col xs={12} md={6} className="justify-content-end d-flex align-items-center">
          <div className="d-flex flex-row flex-wrap justify-content-center justify-content-sm-end">
            <SearchInput
              className="ms-2 mb-2"
              onSearch={() => fetchInterruptionsEE()}
              placeholder="Caută"
              searchInputValue={keywords}
              setSearchInputValue={(value) => setKeywords(value)}
              width={175}
            />
            <div className="d-flex align-items-center">
              <Button
                className="btn-wide ms-2 mb-2"
                style={{ minWidth: '172px' }}
                onClick={() => {
                  setKeywords('');
                  setCounty('');
                  setLocality('');
                  setEndDate('');
                  setStartDate('');
                  setFetchRequested(true);
                }}
              >
                Resetează
              </Button>
            </div>
          </div>
        </Col>
      </Row>

      <div className="d-flex flex-row"></div>
      {interruptions.length > 0 && (
        <>
          <div className="general-table-container">
            <table>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Județ</th>
                  <th>Zone afectate</th>
                  <th>Perioada</th>
                  <th>Mențiuni</th>
                </tr>
              </thead>
              <tbody>
                {interruptions.map((interruption: Interruption, idx: number) => (
                  <tr key={idx}>
                    <td>
                      <strong className="black">{interruption.id}</strong>
                    </td>
                    <td>
                      <strong className="black">{interruption.countyName ?? '-'}</strong>
                    </td>
                    <td>
                      <div className="d-flex flex-column">
                        {interruption.localities.map((l, index) => (
                          <span key={index}>
                            <strong className="black">
                              {l.localityName} {interruption.streets.filter((s) => s.localityCode == l.localityCode).length ? '-' : ''}{' '}
                            </strong>
                            {interruption.streets.filter((s) => s.localityCode == l.localityCode).length
                              ? interruption.streets.filter((s) => s.localityCode == l.localityCode).length == 1
                                ? 'Strada: '
                                : 'Străzile: '
                              : ''}{' '}
                            {interruption.streets
                              .filter((s) => s.localityCode == l.localityCode)
                              .map((s) => s.streetName)
                              .join(', ')}
                          </span>
                        ))}
                      </div>
                    </td>
                    <td>
                      <div className="d-flex flex-column">
                        <span>
                          Data de începere: <strong className="black">{moment(interruption.startDate).format('DD.MM.YYYY - HH:mm')}</strong>
                        </span>
                        <span>
                          Data de finalizare: <strong className="black">{moment(interruption.endDate).format('DD.MM.YYYY - HH:mm')}</strong>
                        </span>
                      </div>
                    </td>
                    <td>
                      {interruption.statusId == PLANNED_INTERRUPTION_STATUS_ID_CANCELED && (
                        <span className="rounded-white-box text-center mb-1" style={{ whiteSpace: 'nowrap' }}>
                          LUCRARE ANULATĂ
                        </span>
                      )}

                      <p
                        style={{
                          textDecoration: interruption.statusId == PLANNED_INTERRUPTION_STATUS_ID_CANCELED ? 'line-through' : ''
                        }}
                        dangerouslySetInnerHTML={{ __html: interruption.description }}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <Pagination
            currentPage={currentPage}
            totalElements={totalItemsCount}
            perPage={perPage}
            paginate={paginate}
            setElementsPerPage={setElementsPerPage}
          />
        </>
      )}

      {error && <div style={{ color: 'red', fontWeight: 'bold' }}>{error}</div>}

      {!error && !interruptions.length && (
        <div style={{ color: 'red', fontWeight: 'bold' }}>
          Nu există evenimente. Criteriile de căutare/filtrare nu au returnat niciun rezultat.
        </div>
      )}
    </div>
  );
};

export default IntreruperiFinalizateEE;
