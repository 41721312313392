import * as geoUrl from '@kentico-json/global/widgets/geoUrl.json';
import { GroupMapKentico } from '@type/kentico-types';
import { useEffect } from 'react';
import {
    ComposableMap,
    Geographies,
    Geography,
    Graticule
} from 'react-simple-maps';

const GroupMapComponent = (props: { kentico: GroupMapKentico }) => {
    const isPartOfGroup = (country: any) => {
        return country.properties.NAME === 'Sweden' || country.properties.NAME === 'Norway' || country.properties.NAME === 'Denmark' ||
            country.properties.NAME === 'Poland' || country.properties.NAME === 'Romania' || country.properties.NAME === 'Turkey' ||
            country.properties.NAME === 'Hungary' || country.properties.NAME === 'Slovakia' || country.properties.NAME === 'Czech Republic' ||
            country.properties.NAME === 'Germany' || country.properties.NAME === 'France' || country.properties.NAME === 'United Kingdom' ||
            country.properties.NAME === 'Belgium' || country.properties.NAME === 'Netherlands' || country.properties.NAME === 'Italy' ||
            country.properties.NAME === 'WalloonRegion' || country.properties.NAME === 'FlemishRegion' ||
            country.properties.NAME === 'Austria' || country.properties.NAME === 'Croatia' || country.properties.NAME === 'Slovenia' ||
            country.properties.NAME === 'Turkey';
    };

    useEffect(() => {
        let map = document.getElementById('group-map');
        map?.scrollTo(0,450);
    }, []);

    const isRomania = (country: any) => {
        return country.properties.NAME === 'Romania';
    };

    return (
        <div className="group-map d-flex justify-content-center">
            <div className="group-map-container p-2" id="group-map">
                <ComposableMap
                    width={800}
                    height={800}
                    projection="geoAzimuthalEqualArea"
                    projectionConfig={{
                        rotate: [-10.0, -53.0, 0],
                        scale: 1200
                    }}
                >
                    <Graticule stroke="white" />
                    <Geographies geography={geoUrl}>
                        {({ geographies }) =>
                            geographies.map((geo) => {
                                return <Geography
                                    key={geo.rsmKey}
                                    geography={geo}
                                    fill={isPartOfGroup(geo) ? 'rgb(176, 4, 2)' : 'rgb(86, 185, 197)'}
                                    stroke="#EAEAEC"
                                    className={`${isRomania(geo) ? 'romania' : 'country'}`}
                                    onClick={() => {
                                        if (geo.properties.NAME === 'Romania') {
                                            window.open(props.kentico.url, '_blank');
                                        }
                                    }
                                    }
                                >
                                </Geography>;
                            })
                        }
                    </Geographies>
                </ComposableMap>
            </div>
        </div>
    );
};

export default GroupMapComponent;