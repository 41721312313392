import React from 'react';
// import { AemWrapper } from '@components/global';
import MenuUser from '@components/delgaz/Menu/MenuUser';
import { MenuUserKentico } from '../../typescript/kentico-types';
import { DelgazWrapper } from '@components/global/DelgazWrapper';

export type MylineMenuUserCompProps = { kentico: MenuUserKentico };

export class DelgazMenuUserComp extends React.Component<MylineMenuUserCompProps> {
  render() {
    return (
      <DelgazWrapper>
        <MenuUser {...this.props} />
        <div style={{paddingBottom: '96px'}}></div>
      </DelgazWrapper>
    );
  }
}
