import {Proxy, proxy} from '@api/azureProxy';
import {store} from '@lib/store';
import axios from 'axios';

export function getDocumentTypes(payload: any) {
    const state = store.getState();

    const proxyData: Proxy = {
        method: 'GET',
        endPoint: `/iconnect/RequestGN/document_types?requestId=${payload.requestId}`,
        data: payload,
        withAuthBearer: false
    };

    return proxy(proxyData, {
        headers: {
            'Authorization': 'Bearer ' + state.auth.token,
            'Access-Control-Allow-Headers': 'Authorization',
            'Access-Control-Allow-Credentials': true,
            'access-control-allow-methods': 'POST',
        }
    });
}

export function postStepOne(payload: any) {
    const state = store.getState();

    const proxyData: Proxy = {
        method: 'POST',
        endPoint: '/iconnect/RequestGN/step1',
        data: payload,
        withAuthBearer: true,
    };

    return proxy(proxyData, {
        headers: {
            'Authorization': 'Bearer ' + state.auth.token,
            'Access-Control-Allow-Headers': 'Authorization',
            'Access-Control-Allow-Credentials': true,
            'access-control-allow-methods': 'POST',
        }
    });
}

export function getStepOne(payload: any) {
    const state = store.getState();

    const proxyData: Proxy = {
        method: 'GET',
        endPoint: `/iconnect/RequestGN/step1?requestId=${payload.requestId}`,
        data: payload,
        withAuthBearer: false
    };

    return proxy(proxyData, {
        headers: {
            'Authorization': 'Bearer ' + state.auth.token,
            'Access-Control-Allow-Headers': 'Authorization',
            'Access-Control-Allow-Credentials': true,
            'access-control-allow-methods': 'POST',
        }
    });
}

export function postStepTwo(payload: any) {
    const state = store.getState();

    return axios.post(
        'https://msapi.delgaz.ro/iconnect/RequestGN/step2',
        payload,
        {
            headers: {
                'Authorization': 'Bearer ' + state.auth.token,
                'Ocp-Apim-Subscription-Key': state.options.subscriptionKey as string
            }
        }
    );
}

export function getStepTwo(payload: any) {
    const state = store.getState();

    const proxyData: Proxy = {
        method: 'GET',
        endPoint: `/iconnect/RequestGN/step2?requestId=${payload.requestId}`,
        data: payload,
        withAuthBearer: false
    };

    return proxy(proxyData, {
        headers: {
            'Authorization': 'Bearer ' + state.auth.token,
            'Access-Control-Allow-Headers': 'Authorization',
            'Access-Control-Allow-Credentials': true,
            'access-control-allow-methods': 'POST',
        }
    });
}

export function postStepThree(payload: any) {
    const state = store.getState();

    return axios.post(
        'https://msapi.delgaz.ro/iconnect/RequestGN/step3',
        payload,
        {
            headers: {
                'Authorization': 'Bearer ' + state.auth.token,
                'Ocp-Apim-Subscription-Key': state.options.subscriptionKey as string
            }
        }
    );
}

export function getStepThree(payload: any) {
    const state = store.getState();

    const proxyData: Proxy = {
        method: 'GET',
        endPoint: `/iconnect/RequestGN/step3?requestId=${payload.requestId}`,
        data: payload,
        withAuthBearer: false
    };

    return proxy(proxyData, {
        headers: {
            'Authorization': 'Bearer ' + state.auth.token,
            'Access-Control-Allow-Headers': 'Authorization',
            'Access-Control-Allow-Credentials': true,
            'access-control-allow-methods': 'POST',
        }
    });
}

export function postStepFour(payload: any) {
    const state = store.getState();

    return axios.post(
        'https://msapi.delgaz.ro/iconnect/RequestGN/step4',
        payload,
        {
            headers: {
                'Authorization': 'Bearer ' + state.auth.token,
                'Ocp-Apim-Subscription-Key': state.options.subscriptionKey as string
            }
        }
    );
}

export function getStepFour(payload: any) {
    const state = store.getState();

    const proxyData: Proxy = {
        method: 'GET',
        endPoint: `/iconnect/RequestGN/step4?requestId=${payload.requestId}`,
        data: payload,
        withAuthBearer: false
    };

    return proxy(proxyData, {
        headers: {
            'Authorization': 'Bearer ' + state.auth.token,
            'Access-Control-Allow-Headers': 'Authorization',
            'Access-Control-Allow-Credentials': true,
            'access-control-allow-methods': 'POST',
        }
    });
}

export function postStepFive(payload: any) {
    const state = store.getState();

    return axios.post(
        'https://msapi.delgaz.ro/iconnect/RequestGN/step5',
        payload,
        {
            headers: {
                'Authorization': 'Bearer ' + state.auth.token,
                'Ocp-Apim-Subscription-Key': state.options.subscriptionKey as string
            }
        }
    );
}

export function getStepFive(payload: any) {
    const state = store.getState();

    const proxyData: Proxy = {
        method: 'GET',
        endPoint: `/iconnect/RequestGN/step5?requestId=${payload.requestId}`,
        data: payload,
        withAuthBearer: false
    };

    return proxy(proxyData, {
        headers: {
            'Authorization': 'Bearer ' + state.auth.token,
            'Access-Control-Allow-Headers': 'Authorization',
            'Access-Control-Allow-Credentials': true,
            'access-control-allow-methods': 'POST',
        }
    });
}

export function postStepSix(payload: any) {
    const state = store.getState();

    return axios.post(
        'https://msapi.delgaz.ro/iconnect/RequestGN/step6',
        payload,
        {
            headers: {
                'Authorization': 'Bearer ' + state.auth.token,
                'Ocp-Apim-Subscription-Key': state.options.subscriptionKey as string
            }
        }
    );
}

export function getStepSix(payload: any) {
    const state = store.getState();

    const proxyData: Proxy = {
        method: 'GET',
        endPoint: `/iconnect/RequestGN/step6?requestId=${payload.requestId}`,
        data: payload,
        withAuthBearer: false
    };

    return proxy(proxyData, {
        headers: {
            'Authorization': 'Bearer ' + state.auth.token,
            'Access-Control-Allow-Headers': 'Authorization',
            'Access-Control-Allow-Credentials': true,
            'access-control-allow-methods': 'POST',
        }
    });
}

export function postStepSeven(payload: any) {
    const state = store.getState();

    return axios.post(
        'https://msapi.delgaz.ro/iconnect/RequestGN/step7',
        payload,
        {
            headers: {
                'Authorization': 'Bearer ' + state.auth.token,
                'Ocp-Apim-Subscription-Key': state.options.subscriptionKey as string
            }
        }
    );
}

export function getStepSeven(payload: any) {
    const state = store.getState();

    const proxyData: Proxy = {
        method: 'GET',
        endPoint: `/iconnect/RequestGN/step7?requestId=${payload.requestId}`,
        data: payload,
        withAuthBearer: false
    };

    return proxy(proxyData, {
        headers: {
            'Authorization': 'Bearer ' + state.auth.token,
            'Access-Control-Allow-Headers': 'Authorization',
            'Access-Control-Allow-Credentials': true,
            'access-control-allow-methods': 'POST',
        }
    });
}

export function sendSMS(payload: any) {
    const state = store.getState();

    return axios.post(
        'https://msapi.delgaz.ro/iconnect/RequestGN/send-sms',
        payload,
        {
            headers: {
                'Authorization': 'Bearer ' + state.auth.token,
                'Ocp-Apim-Subscription-Key': state.options.subscriptionKey as string
            }
        }
    );
}

export function checkSMS(payload: any) {
    const state = store.getState();

    return axios.post(
        'https://msapi.delgaz.ro/iconnect/RequestGN/check-sms',
        payload,
        {
            headers: {
                'Authorization': 'Bearer ' + state.auth.token,
                'Ocp-Apim-Subscription-Key': state.options.subscriptionKey as string
            }
        }
    );
}

export function getUserDetails() {
    const state = store.getState();

    const proxyData: Proxy = {
        method: 'GET',
        endPoint: '/authentication/User/account_details',
        withAuthBearer: true,
    };

    return proxy(proxyData, {
        headers: {
            'Authorization': 'Bearer ' + state.auth.token,
            'Access-Control-Allow-Headers': 'Authorization',
            'Access-Control-Allow-Credentials': true,
            'access-control-allow-methods': 'POST',
        }
    });
}

export function getRequestsListGN(payload: any) {
    const state = store.getState();

    const proxyData: Proxy = {
        method: 'GET',
        endPoint: `/iconnect/RequestGN/list?${payload.join('&')}`,
        data: payload,
        withAuthBearer: true
    };

    return proxy(proxyData, {
        headers: {
            'Authorization': 'Bearer ' + state.auth.token,
            'Access-Control-Allow-Headers': 'Authorization',
            'Access-Control-Allow-Credentials': true,
            'access-control-allow-methods': 'POST',
        }
    });
}

export function exportPDFGN(payload: any) {
    const state = store.getState();

    const proxyData: Proxy = {
        method: 'GET',
        endPoint: `/iconnect/RequestGN/export-pdf?requestId=${payload}`,
        data: payload,
        withAuthBearer: true
    };

    return proxy(proxyData, {
        headers: {
            'Authorization': 'Bearer ' + state.auth.token,
            'Access-Control-Allow-Headers': 'Authorization',
            'Access-Control-Allow-Credentials': true,
            'access-control-allow-methods': 'POST',
        },
        responseType: 'arraybuffer'
    });
}

export function deleteRequestGN(payload: any) {
    const state = store.getState();

    const proxyData: Proxy = {
        method: 'DELETE',
        endPoint: `/iconnect/RequestGN/delete?requestId=${payload.requestId}`,
        data: payload,
        withAuthBearer: true,
    };

    return proxy(proxyData, {
        headers: {
            'Authorization': 'Bearer ' + state.auth.token,
            'Access-Control-Allow-Headers': 'Authorization',
            'Access-Control-Allow-Credentials': true,
            'access-control-allow-methods': 'DELETE',
        }
    });
}

export function requestMappingGN(payload: any) {
    const state = store.getState();

    return axios.post(
        'https://msapi.delgaz.ro/iconnect/RequestGN/request_mapping',
        payload,
        {
            headers: {
                'Authorization': 'Bearer ' + state.auth.token,
                'Ocp-Apim-Subscription-Key': state.options.subscriptionKey as string
            }
        }
    );
}