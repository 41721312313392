import moment from 'moment';
import React from 'react';
import { ValueToSet } from '../PageWrapper';
import DatePicker from '@components/common/Date/DatePicker';

type DateTimeProps = {
  name: string;
  label: string;
  value?: string;
  itemTypeId: number;
  onChange?: (e: ValueToSet) => void;
  isInModal?: boolean;
  disabled?: boolean;
  hideLabel?: boolean;
  mbZero?: boolean;
  minValue?: string;
  maxValue?: string;
};

const DateTime = ({
  name,
  label,
  onChange,
  itemTypeId,
  value,
  isInModal,
  disabled,
  hideLabel,
  mbZero,
  minValue,
  maxValue
}: DateTimeProps) => {
  let selectedValue = value ? moment(value).toDate() : null;

  return (
    <DatePicker
      label={label}
      placeholderText={hideLabel ? label : ''}
      hideLabel={hideLabel}
      mbZero={mbZero}
      name={name}
      selected={selectedValue}
      minDate={minValue}
      maxDate={maxValue}
      dateFormat="dd.MM.yyyy"
      onChange={(date) => {
        let selectedDate = moment(date);
        onChange &&
          onChange({ name: name, value: selectedDate.isValid() ? selectedDate.format('YYYY-MM-DD') : null, itemTypeId } as ValueToSet);
      }}
      disabled={disabled}
    />
  );
};

export default DateTime;
