import React from 'react';
import { DelgazWrapper } from '@components/global/DelgazWrapper';
import DecRecList from '@components/decRec/listaSolicitari/DecRecList';
import { UTILITY_EE } from '@constants/Utility';

export const DecRecEEListComponent = () => {
  return (
    <DelgazWrapper>
      <DecRecList utility={UTILITY_EE} />
    </DelgazWrapper>
  );
};
