import { CLEANUP_SETTINGS, SET_SETTINGS } from '@constants/ActionTypes/PostTypes';
import { SettingsPayload } from '@reducers/settings';

export function setSettings(payload?: Partial<SettingsPayload>) {
  return {
    type: SET_SETTINGS,
    payload: { ...payload } as SettingsPayload
  };
}

export function cleanUpSettings() {
  return { type: CLEANUP_SETTINGS, payload: {} as SettingsPayload };
}