import React, { useEffect, useState } from 'react';

import { BackOffice, BackOfficeContext, BackOfficeContextType, DetailedElement } from '@contexts/backoffice-context';
import { Card, Col, Row } from 'react-bootstrap';
import ColumnValue from './ColumnValue';
import RowActions from './RowAction';
import { DisplayGroup } from '@type/backOfficeModule';
import { GetRowDetails } from '@api/backOffice/fetchResults';
import useBoHelpers from './useBoHelpers';
import * as notification from '@lib/Notification';

export type Item = BackOffice['filters'][number] | BackOffice['advancedFilters'][number];

type DetailedItemProps = {
  detailedElement: DetailedElement;
};

const DetailedItem = ({ detailedElement }: DetailedItemProps) => {
  const { config } = React.useContext(BackOfficeContext) as BackOfficeContextType;
  const [details, setDetails] = useState<any>([]);
  const [isDetailedDynamic, setIsDetailedDynamic] = useState<boolean>(false);
  const [detailedElementDinamic, setDetailedElementDinamic] = useState<any>(null);
  const { getDetailsValueForItemByType } = useBoHelpers();

  useEffect(() => {
    getRowDetails();
  }, [detailedElement]);

  const getRowDetails = async () => {
    setDetails([]);
    if (
      config?.displayModeName === 'DetailedDynamic' &&
      config?.dynamicRowDetailsColumnNames &&
      config?.dynamicRowDetailsColumnNames.length > 0
    ) {
      setIsDetailedDynamic(true);
      setDetailedElementDinamic({ ...detailedElement });
      let payload: any = {};
      config?.dynamicRowDetailsColumnNames.forEach((rd: string) => {
        const key = rd.charAt(0).toLocaleLowerCase() + rd.slice(1);
        payload[rd] = detailedElement[key];
      });
      const details = await GetRowDetails(payload).catch(error => {
        if (error && error.status && error.status == 401) {
          notification.error('Sesiunea a expirat. Pagina se va reâncărca automat');
          setTimeout(() => {
            window.location.reload();
          }, 5000);
        } else {
          notification.error('A intervenit o eroare tehnică. Vă rugăm reveniți mai târziu.');
        }
      });
      setDetails(details);
    }
  };

  const renderColumns = (
    columns: Array<{
      displayName: string;
      name: string;
      dataTypeId: number;
      showInTable: boolean;
      displayGroupId: number | string;
      detailsColspan: number;
      columnSize: string | any;
    }>,
    numberOfColumnsPerRow: number = 1
  ) => {
    return (
      <Row className="mb-1">
        {columns.map(
          (
            column: {
              displayName: string;
              name: string;
              dataTypeId: number;
              showInTable: boolean;
              detailsColspan: number;
              columnSize: string | any;
            },
            columnNumber: number
          ) => {
            let detailsColspan = column.detailsColspan ? column.detailsColspan : numberOfColumnsPerRow ? numberOfColumnsPerRow : 1;
            if (numberOfColumnsPerRow && detailsColspan > numberOfColumnsPerRow) {
              detailsColspan = numberOfColumnsPerRow;
            }
            let oneColSize = 12 / numberOfColumnsPerRow;
            let colspanValue = detailsColspan * oneColSize;

            //@ts-ignore
            if (column.hideIfEmptyValue) {
              const value = getDetailsValueForItemByType(column, isDetailedDynamic ? detailedElementDinamic : detailedElement);
              if (!value) return;
            }

            return (
              <Col sm={colspanValue} key={columnNumber} className="mb-1">
                <p className="item-value">{column.displayName}</p>
                <div className="subtitle-label">
                  <ColumnValue key={columnNumber} column={column} item={isDetailedDynamic ? detailedElementDinamic : detailedElement} />
                </div>
              </Col>
            );
          }
        )}
      </Row>
    );
  };

  return (
    <Card className="details-card">
      <div className="mt-2 mb-2">
        <RowActions item={detailedElement} />
      </div>
      <div className="p-4 pt-2">
        {config && (
          <React.Fragment>
            {isDetailedDynamic && details.length > 0 ? (
              details.map((group: any, index: number) => {
                return (
                  <div key={index}>
                    <h3 className="brand-subtitle red">{group.displayName}</h3>
                    {renderColumns(
                      group.fields.map((field: any) => {
                        const key = field.name.charAt(0).toLowerCase() + field.name.slice(1);
                        detailedElementDinamic[key] = field.value;
                        return { ...field, detailsColspan: field.colspan };
                      }),
                      group.numberOfColumnsPerRow
                    )}
                  </div>
                );
              })
            ) : (
              <>
                {config.displayGroups.length > 0
                  ? config.displayGroups.map((group: DisplayGroup, index: number) => {
                      const columns = config.columns.filter(
                        (column: {
                          displayName: string;
                          name: string;
                          dataTypeId: number;
                          showInTable: boolean;
                          displayGroupId: number | string;
                        }) => column.displayGroupId === group.id
                      );
                      //@ts-ignore
                      if (group.visibilityControlFieldName) {
                        let key =
                          //@ts-ignore
                          group.visibilityControlFieldName.charAt(0).toLocaleLowerCase() + group.visibilityControlFieldName.slice(1);
                        if (!detailedElement[key]) {
                          return;
                        }
                      }
                      return (
                        <div key={index}>
                          <h3 className="brand-subtitle red">{group.displayName}</h3>
                          {renderColumns(columns, group.numberOfColumnsPerRow)}
                        </div>
                      );
                    })
                  : renderColumns(config.columns)}
              </>
            )}
          </React.Fragment>
        )}
      </div>
    </Card>
  );
};

export default React.memo(DetailedItem);
