import React from 'react';
import { DelgazWrapper } from '@components/global/DelgazWrapper';
import ChangePasswordForm from '@components/auth/changePassword/ChangePasswordForm';

export const ChangePassword = () => {
    return (
        <DelgazWrapper>
            <ChangePasswordForm />
        </DelgazWrapper>
    );
};