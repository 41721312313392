import React from 'react';
import InputMask from 'react-input-mask';

const MaskedInput = (props: {
    value: string,
    onChange: (e: any)=> any,
    utility: string,
    mask: string,
    disabled?: boolean,
    label?: string
    onBlur?: (e: any) => any,
    mbZero?: boolean,
    isClc?: boolean,
    autoFocus?: boolean,
    preloadedStartDisabled?: boolean
    onFocus?: (e: any) => any,
}) => {

    const {value, onChange, mask, utility, disabled, label, onBlur, onFocus, mbZero, isClc, autoFocus, preloadedStartDisabled} = props;

    let eeFormat = {
        '0': '[eE]',
        '1': '[mM]',
        '2': '[oO]',
        '3': '[0123456789]',
    };

    let gnFormat = {
        '0': '[dD]',
        '1': '[eE]',
        '2': '[gG]',
        '3': '[0123456789]',
    };

    let clcFormat = {
        '0': '[5]',
        '1': '[0]',
        '2': '[0]',
        '3': '[0123456789]',
    };

    return (
        <div className={`field-group ${mbZero && 'mb-0'}`}>
            <span className="input">
                <InputMask
                    className={'input-with-value'}
                    mask={mask}
                    placeholder={isClc ? '500_______' : utility === 'EE' ? 'EMO_______' : 'DEG_______'}
                    formatChars={isClc ? clcFormat : utility === 'EE' ? eeFormat : gnFormat}
                    onChange={onChange}
                    onBlur={onBlur}
                    value={!preloadedStartDisabled ? isClc ? (!value ? '500' + value : value) : utility === 'EE' ? 'EMO' + value : 'DEG' + value : value}
                    disabled={disabled}
                    autoFocus={autoFocus}
                    onFocus={onFocus}
                />
                <label>{label}</label>
            </span>
        </div>
    );
};

export default MaskedInput;