import DateRange from '@components/common/Date/DateRange';
import MonthSelector from '@components/common/Date/MonthSelector';
import {
  REPORT_DATE_RANGE_TYPE_CURRENT_OR_PREVIOUS_MONTH,
  REPORT_DATE_RANGE_TYPE_DATE_RANGE,
  REPORT_DATE_RANGE_TYPE_MONTH_RANGE,
  REPORT_DATE_RANGE_TYPE_NONE,
  REPORT_DATE_RANGE_TYPE_PREVIOUS_MONTH
} from '@constants/requests/RequestsConstants';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Row } from 'react-bootstrap';

export type ReportDateRangeProps = {
  reportDateRangeType: number;
  setDate: (date: any) => void;
  large?: boolean;
};

export const ReportDateRange = (props: ReportDateRangeProps) => {
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  //limits
  const [minDate, setMinDate] = useState<any>('');
  const [maxDate, setMaxDate] = useState<any>('');

  //months used for REPORT_DATE_RANGE_TYPE_CURRENT_OR_PREVIOUS_MONTH
  const [months, setMonths] = useState<any>([]);

  useEffect(() => {
    setMinDate(moment().subtract(3, 'years').add(1, 'day').toDate());
    setMaxDate(moment().toDate());

    if (props.reportDateRangeType == REPORT_DATE_RANGE_TYPE_PREVIOUS_MONTH.id) {
      let prevMonthStartDay = moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD');
      setStartDate(prevMonthStartDay);
      let prevMonthEndDay = moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD');
      setEndDate(prevMonthEndDay);
    } else if (props.reportDateRangeType == REPORT_DATE_RANGE_TYPE_CURRENT_OR_PREVIOUS_MONTH.id) {
      const currentMonth = moment().format('MM');
      const prevMonth = moment().subtract(1, 'month').format('MM');

      const currentYear = moment().format('YYYY');
      const prevYear = moment().subtract(1, 'month').format('YYYY');

      let months = [
        { year: prevYear, month: prevMonth },
        { year: currentYear, month: currentMonth }
      ];
      setMonths(months);
    }
  }, []);

  useEffect(() => {
    props.setDate({ startDate: startDate, endDate: endDate });
  }, [startDate, endDate]);

  //eslint-disable-next-line
  const [items, setItems] = useState([
    { name: 'Dată început', label: 'Selectează dată început' },
    { name: 'Dată sfârșit', label: 'Selectează dată sfârșit' }
  ]);

  const onChange = (date: any) => {
    if (props.reportDateRangeType == REPORT_DATE_RANGE_TYPE_CURRENT_OR_PREVIOUS_MONTH.id) {
      const startDate = moment(`${date.month}-01-${date.year}`).format('YYYY-MM-DD');
      const endDate = moment(`${date.month}-01-${date.year}`).endOf('month').format('YYYY-MM-DD');
      setStartDate(startDate);
      setEndDate(endDate);
    } else {
      if (date.name === 'Dată început') {
        setStartDate(date.value);
      } else {
        setEndDate(date.value);
      }
    }
  };

  const getDateRange = () => {
    if (props.reportDateRangeType == REPORT_DATE_RANGE_TYPE_NONE.id) {
      return <></>;
    } else if (props.reportDateRangeType == REPORT_DATE_RANGE_TYPE_DATE_RANGE.id) {
      return (
        <Row className="d-flex justify-content-center">
          <DateRange items={items} col={6} onChange={onChange} minDate={minDate} endDate={maxDate} heightSmall={true} />
        </Row>
      );
    } else if (props.reportDateRangeType == REPORT_DATE_RANGE_TYPE_CURRENT_OR_PREVIOUS_MONTH.id) {
      return (
        <div>
          <MonthSelector months={months} onChange={onChange} large={props.large} />
        </div>
      );
    } else if (props.reportDateRangeType == REPORT_DATE_RANGE_TYPE_PREVIOUS_MONTH.id) {
      return <></>;
    } else if (props.reportDateRangeType == REPORT_DATE_RANGE_TYPE_MONTH_RANGE.id) {
      return (
        <Row className="d-flex justify-content-center">
          <DateRange
            items={items}
            col={6}
            onChange={onChange}
            minDate={minDate}
            endDate={maxDate}
            showMonthYearPicker={true}
            dateFormat="dd.MM.yyyy"
            heightSmall={true}
            setLastDayOfMonthForEndDateChange
          />
        </Row>
      );
    }
  };

  return <>{getDateRange()}</>;
};

export default ReportDateRange;
