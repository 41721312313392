import { toggleLoader } from '@actions/LoaderActions';
import { requestResetPassword } from '@api/user/user';
import { IState } from '@type/store';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import PasswordStrengthBar from 'react-password-strength-bar';
import { useDispatch, useSelector } from 'react-redux';
import { RequestResetPasswordSchema } from '../../../helpers/schemas';

import { CustomModal } from '@components/common/ModalConfirm';
import { Input } from '@components/common/Input';

const RequestResetPassword = () => {
  const [msg, setMsg] = useState('');
  const deviceId: string | undefined = useSelector<IState>((state) => state?.device?.deviceId) as string;
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [modalTitle, setModalTitle] = useState('');

  const handleCloseModal = () => {
    setShowModal(false);
    window.location.href = '/login';
  };

  const submit = async (values: any) => {
    const url = new URL(window.location.href);
    const params = url.search;
    setMsg('');

    const data = {
      deviceId: values.deviceId,
      resetToken: params.substring(7, params.length),
      newPassword: values.password
    };

    dispatch(toggleLoader(true));

    await requestResetPassword(data)
      .then((response: any) => {
        if (response && response.success === true) {
          setModalMessage('Parola dumneavoastră a fost resetată cu succes.');
          setModalTitle('Succes!');
          setShowModal(true);
        } else if (response && response.success === false && response.errorCode === 7) {
          setMsg('Link-ul folosit este invalid.');
        } else if (response && response.success === false && response.errorCodeDescription === 'RepeatingPassword') {
          setMsg('Noua parolă trebuie să fie diferită de cea veche.');
        }
      })
      .catch((e: any) => console.log(e));

    dispatch(toggleLoader(false));
  };

  return (
    <div>
      <Formik
        initialValues={{
          deviceId: deviceId,
          resetToken: '',
          password: '',
          confirmNewPassword: ''
        }}
        validateOnMount={true}
        onSubmit={(values) => submit(values)}
        validationSchema={RequestResetPasswordSchema}
      >
        {(props) => (
          <Form>
            <div className="form-container">
              <Row className="mt-3">
                <Col className="mb-3" xs={12} md={6}>
                  <Field
                    className="input-field-register"
                    id="password"
                    name="password"
                    placeholder="Parola nouă"
                    type="password"
                    autoComplete="new-password"
                    render={() => (
                      <Input
                        label="Parola nouă"
                        type="password"
                        autoComplete="new-password"
                        mbZero
                        onBlur={() => {
                          props.setFieldTouched('password');
                        }}
                        onChange={(e: any) => {
                          e.target.value.length !== 0 && props.setFieldValue('password', e.target.value);
                          e.target.value.length === 0 && props.setFieldValue('password', '');
                        }}
                      />
                    )}
                  />
                  <PasswordStrengthBar
                    password={props.values.password}
                    scoreWords={[
                      'Puterea parolei: Slabă',
                      'Puterea parolei: Slabă',
                      'Puterea parolei: Medie',
                      'Puterea parolei: Bună',
                      'Puterea parolei: Puternică'
                    ]}
                    shortScoreWord={'Puterea parolei: Slabă'}
                  />
                  <ErrorMessage name="password" render={(msg) => <div style={{ color: 'red', fontWeight: 'bold' }}>{msg}</div>} />
                </Col>
                <Col className="mb-3" xs={12} md={6}>
                  <Field
                    className="input-field-register"
                    id="confirmNewPassword"
                    name="confirmNewPassword"
                    placeholder="Confirmă noua parolă"
                    type="password"
                    autoComplete="new-password"
                    render={() => (
                      <Input
                        label="Confirmă noua parolă"
                        type="password"
                        autoComplete="new-password"
                        mbZero
                        onBlur={() => {
                          props.setFieldTouched('confirmNewPassword');
                        }}
                        onChange={(e: any) => {
                          e.target.value.length !== 0 && props.setFieldValue('confirmNewPassword', e.target.value);
                          e.target.value.length === 0 && props.setFieldValue('confirmNewPassword', '');
                        }}
                      />
                    )}
                  />
                  <ErrorMessage name="confirmNewPassword" render={(msg) => <div style={{ color: 'red', fontWeight: 'bold' }}>{msg}</div>} />
                </Col>
              </Row>
              {msg !== '' && (
                <div className="mt-2 mb-2">
                  <p style={{ color: '#ea1c0a', fontWeight: 'bold' }}>{msg}</p>
                </div>
              )}
              <div className="mb-4 buttons-container d-flex flex-row align-items-center">
                <Button type="submit" disabled={!props.isValid}>
                  Schimbă parola
                </Button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
      <CustomModal showModal={showModal} modalTitle={modalTitle} onClose={handleCloseModal}>
        <h5>{modalMessage}</h5>
      </CustomModal>
    </div>
  );
};

export default RequestResetPassword;
